import { api2 } from './api';

const conversationApi = {
  sendMessage: (conversationUid: string, text: string) =>
    api2(`POST`, `conversations/${conversationUid}/messages`, { text }),
  uploads: (conversationUid: string) =>
    api2(`GET`, `conversations/${conversationUid}/uploads`),
  upload: (conversationUid: string, data: any) =>
    api2(`POST`, `conversations/${conversationUid}/uploads`, data),
  viewMessage: (userUid: string, messageUid: string) =>
    api2(`PUT`, `users/${userUid}/messages/${messageUid}/view`),
};

export default conversationApi;

import * as companyActions from '../../actions/companyGroup/companies';
import { ActionType, getType } from 'typesafe-actions';
import { Company } from '../../types';

export type CompanyGroupCompanyAction = ActionType<typeof companyActions>;

export interface CompanyGroupCompanyState {
  data: Company[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyGroupUid: string;
}

const DEFAULT_STATE: CompanyGroupCompanyState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  companyGroupUid: '',
};

export default function companyGroupCompaniesReducer(
  state: CompanyGroupCompanyState = DEFAULT_STATE,
  action: CompanyGroupCompanyAction,
) {
  switch (action.type) {
    case getType(companyActions.companyGroupCompaniesFetchRequest):
      return {
        ...state,
        loading: true,
        companyGroupUid: action.payload,
      };
    case getType(companyActions.companyGroupCompaniesFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...action.payload],
      };
    case getType(companyActions.companyGroupCompaniesFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
      };
    case getType(companyActions.companyGroupAddCompany):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(companyActions.companyGroupRemoveCompany):
      return {
        ...state,
        data: state.data.filter(user => user.uid !== action.payload),
      };
    default:
      return state;
  }
}

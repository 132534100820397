import * as React from 'react';
import { ResponseComment } from '../../types';
import { getFullName } from '../../helpers';
import CommentCreate from './CommentCreate';
import moment from 'moment';
import { orderBy } from 'natural-orderby';

interface Props {
  comments: ResponseComment[];
  widgetUid: string;
  instanceRevisionUid: string;
  onCreate: (comment: ResponseComment) => void;
  editable: boolean;
}

function formatDate(date: number) {
  return moment.unix(date).format('MMM Do, YYYY - hh:mm:a');
}

function Comments({
  comments,
  widgetUid,
  instanceRevisionUid,
  onCreate,
  editable,
}: Props) {
  return (
    <div>
      <CommentCreate
        onCreate={onCreate}
        widgetUid={widgetUid}
        instanceRevisionUid={instanceRevisionUid}
        editable={editable}
      />

      {orderBy(comments, [c => c.comment.created_at], ['asc']).map(c => (
        <div className={'mb-4'} key={c.comment.uid}>
          <p>
            <span className="font-bold">{getFullName(c.creator)}</span> -{' '}
            <span className="text-grey-darker">
              {formatDate(c.comment.updated_at || c.comment.created_at)}
            </span>
          </p>
          <p className="my-1" style={{ color: '#666' }}>
            {c.comment.text}
          </p>
        </div>
      ))}
    </div>
  );
}

export default Comments;

import * as React from 'react';
import { useEffect } from 'react';

interface ChildrenProps {
  getCanvasProps: () => {
    ref: React.RefObject<HTMLCanvasElement>;
    style: Partial<React.CSSProperties>;
  };

  clear: () => void;
}

interface Props {
  size?: number;
  color?: string;
  onMouseUp?: (uri: string) => void;
  background?: string;
  children: (state: ChildrenProps) => JSX.Element;
}

const FreeDrawCanvas = ({
  size = 1,
  color = '#000000',
  onMouseUp,
  background,
  children,
}: Props) => {
  const canvasRef = React.useRef<HTMLCanvasElement>(null);

  useEffect(() => {
    if (canvasRef.current) {
      // @ts-ignore
      window.$(canvasRef.current).sketch({
        size,
        color,
        onStop: (c: any) => {
          const imgData = c.data('sketch').toDataURL();
          if (onMouseUp) {
            onMouseUp(imgData);
          }
        },
        img: background,
        scale: 1,
      });
    }
  }, []);

  useEffect(
    () => {
      if (canvasRef.current) {
        // @ts-ignore
        window.$(canvasRef.current).sketch('option', 'size', size);
        // @ts-ignore
        window.$(canvasRef.current).sketch('option', 'color', color);
      }
    },
    [size, color],
  );

  const onClear = () => {
    // @ts-ignore
    window.$(canvasRef.current).sketch('erase');
    try {
      canvasRef!
        .current!.getContext('2d')!
        .clearRect(0, 0, canvasRef!.current!.width, canvasRef!.current!.height);
    } catch {
      //
    }
  };

  const getCanvasProps = () => {
    return {
      ref: canvasRef,
      style: { cursor: 'crosshair' },
    };
  };

  return children({
    getCanvasProps,
    clear: onClear,
  });
};

export default FreeDrawCanvas;

import * as React from 'react';
import {
  useTextInput,
  useRequiredSelectInput,
  useToggle,
} from '../../../helpers/hooks';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import InlineInput from '../../../components/InlineInput';
import InlineSelect from '../../../components/InlineSelect';
import Suitcase from '../../../svg/Suitcase';
import Download from '../../../svg/Download';
import InlineToggle from '../../../components/InlineToggle';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import { Company, CompanyGroup, User } from '../../../types';
import companyGroupApi, { CreateCompany } from '../../../api/companyGroup.api';
import { toast } from 'react-toastify';
import Cloud from '../../../svg/Cloud';
import DropUpload from '../../../components/DropUpload';
import companyApi from '../../../api/company.api';
import { getDivisionLabel } from '../../../helpers';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  company?: Company;
  companyGroup: CompanyGroup;
  onCreate?: () => void;
  onUpdate?: () => void;
  users: User[];
}

const CompanyCreate = ({
  isOpen,
  handleClose,
  company,
  companyGroup,
  onCreate,
  onUpdate,
}: Props) => {
  const csvPlans = () => [
    { label: 'On', value: '1' },
    { label: 'Off', value: '2' },
    { label: 'Trial', value: '3' },
  ];

  const tempUnits = () => [
    { label: 'Fahrenheit', value: 'F' },
    { label: 'Celcius', value: 'C' },
  ];

  const name = useTextInput();
  const csvPlan = useRequiredSelectInput(csvPlans()[0]);
  const formReminders = useToggle(false);
  const tempUnit = useRequiredSelectInput(tempUnits()[0]);
  const auditForms = useToggle(false);
  const incidents = useToggle(false);
  const spence = useToggle(false);
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const [path, setPath] = React.useState('');
  const [avatar, setAvatar] = React.useState<any>(null);

  const editing = !!company;
  const titleAction = editing ? 'Edit' : 'Create';

  const getAvatar = async (c: Company) => {
    const { data } = await companyApi.avatar(c.uid);
    if (data) {
      setPath(data.path);
    }
  };

  const onFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    if (files && files.length > 0) {
      if (company) {
        const data = new FormData();
        data.append('avatar', files[0]);
        const res = await companyApi.setAvatar(company.uid, data);
        if (res.data) {
          toast.success('Image updated');
          setPath(res.data.path);
        }
      } else {
        setAvatar(files[0]);
      }
    }
  };

  React.useEffect(
    () => {
      if (company) {
        name.setValue(company.name);
        csvPlan.onChange(
          csvPlans().find(v => v.value === `${company.csv_plan}`) ||
            csvPlans()[0],
        );
        formReminders.setValue(!!company.reminders);
        tempUnit.onChange(
          tempUnits().find(c => c.value === company.temp_unit) ||
            tempUnits()[0],
        );
        auditForms.setValue(!!company.audit_forms);
        incidents.setValue(!!company.incident_module);
        spence.setValue(!!company.spence);
        getAvatar(company);
      }
    },
    [company],
  );

  const submit = async () => {
    const submitData = {
      name: name.value,
      csvPlan: +csvPlan.selected,
      tempUnit: tempUnit.selected,
      reminders: formReminders.checked,
      auditForms: auditForms.checked,
      incidents: incidents.checked,
      spence: spence.checked,
    };

    if (editing) {
      update(submitData);
    } else {
      create(submitData);
    }
  };

  const create = async (submitData: CreateCompany) => {
    if (!companyGroup) {
      return;
    }

    setLoading(true);

    const { data, errors: err } = await companyGroupApi.createCompany(
      companyGroup.uid,
      submitData,
    );

    if (err) {
      setErrors(err);
    }
    if (data) {
      if (avatar) {
        const formData = new FormData();
        formData.append('avatar', avatar);
        await companyApi.setAvatar(data.company.uid, formData);
      }
      toast.success('Company created');
      if (onCreate) {
        onCreate();
      }
    }
    setLoading(false);
  };

  const update = async (submitData: CreateCompany) => {
    if (!company) {
      return;
    }

    setLoading(true);

    const { data, errors: err } = await companyGroupApi.updateCompany(
      companyGroup.uid,
      company.uid,
      submitData,
    );

    if (err) {
      setErrors(err);
    }

    if (data) {
      setErrors({});
      toast.success('Company updated');
      if (onUpdate) {
        onUpdate();
      }
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`${titleAction} ${getDivisionLabel()}`}
      handleClose={handleClose}
    >
      <ModalHeader handleClose={handleClose}>
        {titleAction} {getDivisionLabel()}
      </ModalHeader>

      <ModalBody>
        {path && (
          <img
            src={path}
            className={'mx-auto my-4 block'}
            style={{ maxWidth: '150px' }}
          />
        )}
        <div className="flex justify-center mb-4">
          <input type={'file'} onChange={onFileChange} accept={'image/*'} />
        </div>
        <InlineInput
          icon={<Suitcase />}
          label={'Name'}
          {...name}
          errorMessage={errors.name}
        />
        <InlineSelect
          icon={<Download />}
          label={'CSV Plan'}
          options={csvPlans()}
          {...csvPlan}
          errorMessage={errors.csvPlan}
        />
        <InlineSelect
          label={'Temp Unit'}
          options={tempUnits()}
          {...tempUnit}
          errorMessage={errors.tempUnit}
          icon={<Cloud />}
        />
        <InlineToggle label={'Form Reminders?'} {...formReminders} />
        <InlineToggle label={'Audit Forms?'} {...auditForms} />
        <InlineToggle label={'Incident Module?'} {...incidents} />
        <InlineToggle label={'Spence?'} {...spence} />
      </ModalBody>

      <ModalFooter right={true}>
        <Button type={'default'} className={'mr-4'} onClick={handleClose}>
          Cancel
        </Button>
        <Button type={'primary'} onClick={submit} loading={loading}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CompanyCreate;

import Axios, { AxiosResponse } from 'axios';
import { statusCodes } from '../constants/index';
import formService from './forms.service';
import { toast } from 'react-toastify';

const cloudinaryService = {
  async ensureImg({
    userUid,
    file,
  }: {
    userUid: string;
    file: File;
  }): Promise<File | null> {
    if (file.type.includes('image')) {
      return file;
    }
    if (file.type !== 'application/pdf') {
      toast.error('Unknown file format! Please upload an image or PDF!');
      return null;
    }
    const { data, errors } = await formService.getSignatureFile(userUid);
    if (!data || errors) {
      return null;
    }
    const { folder, signature, timestamp } = data;
    const uploadResult = await cloudinaryService.uploadSigned(file, {
      signature,
      folder,
      timestamp,
    });
    if (!uploadResult) {
      return null;
    }
    return new File(
      [
        (await Axios.get(
          `${uploadResult.url
            .split('.')
            .filter((_, i, arr) => i !== arr.length - 1)
            .concat('jpg')
            .join('.')}`,
          { responseType: 'blob' },
        )).data,
      ],
      `${uploadResult.name}.jpg`,
      { type: 'image/jpeg' },
    );
  },

  async uploadSigned(
    fileData: string | File,
    {
      signature,
      folder,
      timestamp,
    }: {
      signature: string;
      folder: string;
      timestamp: number;
    },
  ) {
    try {
      const formData = new FormData();
      formData.append('api_key', process.env.CLOUDINARY_API_KEY!);
      formData.append('signature', signature);
      formData.append('file', fileData);
      formData.append('timestamp', `${timestamp}`);
      formData.append('folder', folder);
      const result = await Axios.post<
        any,
        AxiosResponse<{
          secure_url: string;
          public_id: string;
          original_filename: string;
          format: string;
        }>
      >(
        `https://api.cloudinary.com/v1_1/${
          process.env.CLOUDINARY_CLOUD_NAME
        }/auto/upload`,
        formData,
      );
      if (result.status === statusCodes.SUCCESS && result.data) {
        return {
          id: result.data.public_id,
          url: result.data.secure_url,
          name: result.data.original_filename,
          extension: result.data.format,
        };
      }
      return null;
    } catch (e) {
      return null;
    }
  },
};
export default cloudinaryService;

import createAuth0Client from '@auth0/auth0-spa-js';
import Auth0Client from '@auth0/auth0-spa-js/dist/typings/Auth0Client';

let auth0Client: Auth0Client | null = null;

async function getAuth0Client(username?: string) {
  if (!auth0Client) {
    auth0Client = await createAuth0Client({
      client_id: process.env.AUTH0_CLIENT_ID!,
      domain: process.env.AUTH0_DOMAIN!,
      redirect_uri: `${process.env.PUBLIC_URL}/auth0-callback`,
      audience: process.env.AUTH0_AUDIENCE,
      login_hint: username,
    });
  }
  return auth0Client;
}

export default getAuth0Client;

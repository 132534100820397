import Dropzone from 'react-dropzone';
import { cssVars } from '../constants/index';
import * as React from 'react';
import Upload from '../svg/Upload';

interface Props {
  onDrop?: (files: File[]) => void;
  block?: boolean;
  margin?: boolean;
  disabled?: boolean;
  text?: string;
  accept?: string;
}

const DropUpload = ({
  onDrop,
  block,
  margin = true,
  disabled,
  text = 'files here',
  accept,
}: Props) => {
  const handleDrop = (files: File[]) => {
    if (onDrop) {
      onDrop(files);
    }
  };

  return (
    <Dropzone onDrop={handleDrop} disabled={disabled} accept={accept}>
      {({
        getRootProps,
        getInputProps,
        isDragActive,
        isDragAccept,
        isDragReject,
        acceptedFiles,
        rejectedFiles,
      }) => {
        const baseStyle = {
          width: block ? '100%' : 184,
          borderWidth: 2,
          borderColor: cssVars.colors.grey,
          borderStyle: 'solid',
          borderRadius: 4,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          margin: margin ? cssVars.margin[2] : '0px',
          padding: cssVars.padding[4],
          backgroundColor: 'rgba(255,255,255,0.5)',
        };
        const activeStyle = {
          borderStyle: 'solid',
          borderColor: '#6c6',
          backgroundColor: '#eee',
        };
        const rejectStyle = {
          borderStyle: 'solid',
          borderColor: '#c66',
          backgroundColor: '#eee',
        };

        let styles = { ...baseStyle };
        styles = isDragActive ? { ...styles, ...activeStyle } : styles;
        styles = isDragReject ? { ...styles, ...rejectStyle } : styles;

        return (
          <div {...getRootProps()} style={styles}>
            <input {...getInputProps()} />
            <div className="flex flex-col items-center justify-center">
              <Upload className={'w-8 h-8 text-grey-darker'} />
              <p className={'mt-2 text-grey-darker'}>
                {isDragActive ? 'Drop' : 'Drag'} {text}
              </p>
            </div>
            {isDragReject && <div>Unsupported file type...</div>}
          </div>
        );
      }}
    </Dropzone>
  );
};

export default DropUpload;

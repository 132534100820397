import { baseApi } from '../api/api';
import { get } from 'lodash';
import { NotificationPreferences } from '../types';

const notificationsApi = async <T>(
  method: string,
  url: string,
  data: any = {},
  options: any = {},
): Promise<{ data: T | undefined; errors: any | undefined }> => {
  try {
    const res = await baseApi(
      method,
      `${process.env.NOTIFICATIONS_URL}/${url}`,
      data,
      options,
    );
    return { data: res, errors: undefined };
  } catch (e) {
    return { data: undefined, errors: get(e, 'errors', e) };
  }
};

const notificationsService = {
  updateUserNotifPrefs: (
    notifPrefUid: string,
    notifPrefs: NotificationPreferences,
  ) =>
    notificationsApi(
      'PATCH',
      `notification-preferences/${notifPrefUid}`,
      notifPrefs,
    ),
  getNotifPrefs: (userUid: string) =>
    notificationsApi<{ notificationPreferences: NotificationPreferences }>(
      'GET',
      `users/${userUid}/notification-preferences`,
    ),
};

export default notificationsService;

import * as submDataActions from '../actions/submData';
import { ActionType, getType } from 'typesafe-actions';
import { ParsedSubmData } from '../types';
import * as R from 'ramda';

export type SubmDataAction = ActionType<typeof submDataActions>;

export interface SubmDataState {
  byOptionId: {
    [key: number]: ParsedSubmData;
  };
  allOptionIds: number[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  formUid: string;
}

const DEFAULT_STATE: SubmDataState = {
  byOptionId: {},
  allOptionIds: [],
  loading: false,
  loaded: false,
  errors: {},
  formUid: '',
};

export default function submDataReducer(
  state: SubmDataState = DEFAULT_STATE,
  action: SubmDataAction,
) {
  switch (action.type) {
    case getType(submDataActions.setSubmData):
      return {
        ...state,
        byOptionId: R.reduce(
          (curr, o) => ({ ...curr, [o.option_id]: o }),
          {},
          action.payload,
        ),
        allOptionIds: action.payload.map(o => o.option_id),
      };
    case getType(submDataActions.addSubmData):
      return {
        ...state,
        byOptionId: {
          ...state.byOptionId,
          [action.payload.option_id]: { ...action.payload },
        },
        allOptionIds: [...state.allOptionIds, action.payload.option_id],
      };
    case getType(submDataActions.updateSubmData):
      return {
        ...state,
        byOptionId: {
          ...state.byOptionId,
          [action.payload.option_id]: { ...action.payload },
        },
      };
    default:
      return state;
  }
}

import { baseApi } from '../api/api';
import { get } from 'lodash';
import {
  Widget,
  Form,
  User,
  FormRevision,
  Instance,
  Response,
  SignOff,
  InstanceMeta,
  ResponseFile,
  Feed,
  ResponseComment,
  Comment,
  Geostamp,
  SubmissionFilters,
  InstanceRevision,
  Submissions,
  SubmissionV2,
} from '../types';

const formsApi = async <T>(
  method: string,
  url: string,
  data: any = {},
  options: any = {},
): Promise<{ data: T | undefined; errors: any | undefined }> => {
  try {
    const res = await baseApi(
      method,
      `${process.env.FORMS_URL}/${url}`,
      data,
      options,
    );
    return { data: res, errors: undefined };
  } catch (e) {
    return { data: undefined, errors: get(e, 'errors', e) };
  }
};

const submissionsApi = async <T>(
  method: string,
  url: string,
  data: any = {},
  options: any = {},
): Promise<{ data: T | undefined; errors: any | undefined }> => {
  try {
    const res = await baseApi(
      method,
      `${process.env.SUBMISSIONS_URL}/${url}`,
      data,
      options,
    );
    return { data: res, errors: undefined };
  } catch (e) {
    return { data: undefined, errors: get(e, 'errors', e) };
  }
};

// tslint:disable-next-line: class-name
export interface v2FormOnSite<T> {
  deployee: T;
  deps: ResponseFile;
}

export interface WidgetsAndResponse {
  response: Response;
  widget: Widget;
  comments: ResponseComment[];
  files: ResponseFile[];
}

export interface TopForms {
  favorites: Array<Form & { times_accessed: number }>;
  forms: Array<Form & { times_submitted: number }>;
}

/**
 * Only for forms 2.0
 */
const formService = {
  updateProfilePicture: (
    userUid: string,
    data: { profile_url: string; profile_id: string },
  ) =>
    formsApi<{ user: User }>('PUT', `users/${userUid}/profile-picture`, data),
  getV2Forms: (companyUid: string) =>
    formsApi<{
      formDownload: Array<{ form: Form; form_revision: FormRevision }>;
    }>('GET', `companies/${companyUid}/forms-download`),
  convertV2: (formUid: string) =>
    formsApi<{ newForm: Form }>('POST', `forms/${formUid}/convert-v2`),
  copyDeployments: (srcSiteUid: string, dstSiteUid: string) =>
    formsApi<void>(
      'POST',
      `sites/${srcSiteUid}/copy-deployments-to/${dstSiteUid}`,
    ),
  formWorkingCopy: (formUid: string) =>
    formsApi<{ workingCopy: { uid: string; created_at: number } }>(
      'GET',
      `forms/${formUid}/working-copy`,
    ),
  createForm: (companyUid: string, data: any) =>
    formsApi<{ form: Form }>('POST', `companies/${companyUid}/forms`, data),
  widgets: (formRevisionsUid: string) =>
    formsApi<{ status: boolean; widgets: Widget[] }>(
      'GET',
      `form-revisions/${formRevisionsUid}/widgets`,
    ),
  createWidget: (formRevisionsUid: string, data: any) =>
    formsApi<{ status: boolean; widget: Widget }>(
      'POST',
      `form-revisions/${formRevisionsUid}/widgets`,
      data,
    ),
  deleteWidget: (formRevisionUid: string, widgetUid: string) =>
    formsApi<{ status: boolean }>(
      'DELETE',
      `form-revisions/${formRevisionUid}/widgets/${widgetUid}`,
    ),
  updateWidget: (
    formRevisionUid: string,
    widgetUid: string,
    data: {
      title?: string;
      order?: number;
      negative_label?: string;
      neutral_label?: string;
      positive_label?: string;
      mandatory?: boolean;
      options?: string[];
    },
  ) =>
    formsApi<{ status: boolean; widget: Widget }>(
      'PATCH',
      `form-revisions/${formRevisionUid}/widgets/${widgetUid}`,
      data,
    ),
  publish: (formUid: string) =>
    formsApi<{ revision: FormRevision }>('POST', `forms/${formUid}/publish`),
  getPublished: (formUid: string) =>
    formsApi<{ published?: FormRevision }>('GET', `forms/${formUid}/published`),
  addFormToSite: (
    siteUid: string,
    formUid: string,
    data: {
      type: string;
      all: boolean;
      deployeeUids?: string[];
    },
  ) => formsApi('POST', `sites/${siteUid}/forms/${formUid}`, data),
  getFormDraft: (uids: {
    formUids: string;
    siteUids?: string;
    deployeeUids?: string;
  }) =>
    formsApi<{
      drafts: Array<{
        creator: User;
        instance: Instance;
      }>;
    }>('GET', 'drafts', {
      formUids: uids.formUids,
      siteUids: uids.siteUids,
      deployeeUids: uids.deployeeUids,
    }),
  createFormDraft: (siteUid: string, formUid: string, deployeeUid: string) =>
    formsApi<{
      draft: Instance;
    }>('POST', `sites/${siteUid}/drafts`, { formUid, deployeeUid }),
  deleteDraft: (draftUid: string) =>
    submissionsApi('DELETE', `drafts/${draftUid}`),
  instanceWorkingCopy: (instanceUid: string) =>
    submissionsApi<{ workingCopy: Instance }>(
      'GET',
      `instances/${instanceUid}/working-copy`,
    ),
  widgetsAndResponses: (instanceRevisionUid: string) =>
    submissionsApi<{ responses: WidgetsAndResponse[] }>(
      'GET',
      `instance-revisions/${instanceRevisionUid}/responses`,
    ),
  setWidgetResponse: (
    instanceRevUid: string,
    widgetUid: string,
    data: {
      inspection_answer?: string;
      question_answer?: string;
      selections?: string[];
      user_selections?: string[];
    },
  ) =>
    submissionsApi(
      'PUT',
      `instance-revisions/${instanceRevUid}/widgets/${widgetUid}/responses`,
      data,
    ),
  uploadWidgetFile: (
    instanceRevUid: string,
    widgetUid: string,
    data: { file_id: string; file_url: string },
  ) =>
    submissionsApi<{ file: ResponseFile }>(
      'POST',
      `instance-revisions/${instanceRevUid}/widgets/${widgetUid}/files`,
      data,
    ),
  deleteWidgetFile: (fileUid: string) =>
    submissionsApi('DELETE', `response-files/${fileUid}`),
  formSubmit: (instanceRevisionUid: string) =>
    submissionsApi('POST', `instance-revisions/${instanceRevisionUid}/submit`),
  thirdPartySignOff: (
    instanceRevisionUid: string,
    data: {
      full_name: string;
      email: string;
      signature_id: string;
      signature_url: string;
    },
  ) =>
    submissionsApi<{
      created_at: number;
      email: string;
      full_name: string;
      signature_id: string;
      signature_url: string;
      uid: string;
    }>(
      'POST',
      `instance-revisions/${instanceRevisionUid}/third-party-sign-offs`,
      data,
    ),
  userSignOff: (
    instanceRevisionUid: string,
    data: { pin: string; username: string },
  ) =>
    submissionsApi<{
      created_at: number;
      email: string;
      full_name: string;
      signature_id: string;
      signature_url: string;
      uid: string;
    }>(
      'POST',
      `instance-revisions/${instanceRevisionUid}/user-sign-offs`,
      data,
    ),
  getUserMetaData: (userUid: string) =>
    formsApi<{ userMetadata: { pin: number; uid: string } }>(
      'GET',
      `users/${userUid}/metadata`,
    ),
  updateUserMetaData: (
    userMetaDataUid: string,
    data: { signature_id?: string; signature_url?: string; pin?: number },
  ) =>
    formsApi<{ signature_id: string; siganture_url: string; pin: number }>(
      'PATCH',
      `user-metadata/${userMetaDataUid}`,
      data,
    ),
  getInstanceRevisionFileSignature: (instanceRevisionUid: string) =>
    submissionsApi<{
      folder: string;
      ok: boolean;
      signature: string;
      timestamp: number;
    }>('POST', `instance-revisions/${instanceRevisionUid}/file-signature`),
  getSignatureFile: (userUid: string) =>
    formsApi<{
      signature: string;
      timestamp: number;
      folder: string;
    }>('GET', `users/${userUid}/file-signature`),
  getSignOffs: (instanceRevisionUid: string) =>
    submissionsApi<{ signOffs: SignOff[] }>(
      'GET',
      `instance-revisions/${instanceRevisionUid}/sign-offs`,
    ),
  instanceMeta: (instanceRevisionUid: string) =>
    submissionsApi<{ meta: InstanceMeta }>(
      'GET',
      `instance-revisions/${instanceRevisionUid}/meta`,
    ),
  newsfeed: (companyUid: string, userUid: string) =>
    formsApi<{ feed: Feed[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/feed`,
    ),
  getDeployeeHistory: ({
    siteUid,
    formUid,
    deployeeUid,
  }: {
    siteUid: string;
    formUid: string;
    deployeeUid: string;
  }) =>
    submissionsApi<{
      history: Array<{
        instance: Instance;
        instance_revisions: Array<{
          instance_revision: InstanceRevision;
          submitter: User;
        }>;
      }>;
    }>('GET', `submission-history`, {
      formUids: [formUid],
      siteUids: [siteUid],
      deployeeUids: [deployeeUid],
    }),
  createComment: (
    instanceRevisionUid: string,
    widgetUid: string,
    comment: string,
  ) =>
    submissionsApi<{ comment: Comment; creator: User }>(
      'POST',
      `instance-revisions/${instanceRevisionUid}/widgets/${widgetUid}/comments`,
      { text: comment },
    ),
  getGeostamps: (instanceRevisionUid: string) =>
    submissionsApi<{ geostamps: [{ geostamp: Geostamp; user: User }] }>(
      'GET',
      `instance-revisions/${instanceRevisionUid}/geostamps`,
    ),
  geostampInstanceRev: (
    instanceRevisionUid: string,
    data: { lat: number; lng: number },
  ) =>
    submissionsApi(
      'POST',
      `instance-revisions/${instanceRevisionUid}/geostamps`,
      data,
    ),
  getSubmissions: (data: SubmissionFilters) =>
    formsApi<{
      submissions: SubmissionV2[];
    }>('GET', `submissions`, data),
  getSubmissionsByForm: (data: SubmissionFilters) =>
    formsApi<{
      submissions: {
        [key: string]: {
          allSubmissions: number;
          earliest: number;
          average: number;
          latest: number;
          submFrequency: number;
        };
      };
    }>('GET', `submissions-by-form`, data),
  getSubmissionsForForm: (formUid: string, data: SubmissionFilters) =>
    formsApi<{
      stats: any;
    }>('GET', `submissions-for-form/${formUid}`, data),
  widgetPutAfter: (widgetUid: string, referenceWidgetUid?: string) =>
    formsApi<{}>(
      'PUT',
      `widgets/${widgetUid}/put-after/${referenceWidgetUid || ''}`,
    ),
  favoriteForm: (userUid: string, formUid: string) =>
    formsApi('PUT', `users/${userUid}/favorites/${formUid}`),
  removefavoriteForm: (userUid: string, formUid: string) =>
    formsApi('DELETE', `users/${userUid}/favorites/${formUid}`),
  getTopForms: (companyUid: string, userUid: string) =>
    formsApi<{
      favorites: Array<Form & { times_accessed: number }>;
      forms: Array<Form & { times_accessed: number }>;
    }>('GET', `companies/${companyUid}/users/${userUid}/top-forms`),
};

export default formService;

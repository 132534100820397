import api, { api2 } from './api';

const certApi = {
  delete: (certUid: string) => api('DELETE', `certs/${certUid}`),
  removeImage: (certUid: string) => api2('DELETE', `certs/${certUid}/image`),
  changeImage: (certUid: string, data: any) =>
    api2('post', `certs/${certUid}/image`, data),
  update: (certUid: string, data: { name?: string; expiry?: number | null }) =>
    api('PATCH', `certs/${certUid}`, data),
};

export default certApi;

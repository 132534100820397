import * as React from 'react';
import Modal from '../components/Modal';
import ModalBody from '../components/modal/ModalBody';
import ModalHeader from '../components/modal/ModalHeader';
import ModalFooter from '../components/modal/ModalFooter';
import Button from '../components/Button';
import InlineSelect from '../components/InlineSelect';
import Toggle from 'react-toggle';
import Apartment from '../svg/Apartment';
import Location from '../svg/Location';
import User from '../svg/User';
import InlineDatePicker from '../components/InlineDatePicker';
import { Site, User as IUser, Action } from '../types';
import {
  useTextInput,
  useOptionalDateInput,
  useOptionalSelectInput,
  useToggle,
} from '../helpers/hooks';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import companyApi from '../api/company.api';
import ErrorMessage from '../components/ErrorMessage';
import { getDivisionLabel, getSiteLabel } from '../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  sites: Site[];
  users: IUser[];
  companyUid?: string;
  incidentUid?: string;
  onCreate?: (action: Action) => void;
  submissionUid?: string;
  formOptionId?: number;
  deployee?: any;
  currentSite?: Site;
}

const ActionCreate = ({
  isOpen,
  handleClose,
  sites,
  users,
  companyUid,
  t,
  incidentUid,
  onCreate,
  submissionUid,
  formOptionId,
  currentSite,
}: Props) => {
  const defaultValue = currentSite
    ? { label: currentSite!.name, value: currentSite!.uid }
    : null;
  const description = useTextInput();
  const correctiveAction = useTextInput();
  const urgent = useToggle();
  const assignedTo = useOptionalSelectInput();
  const site = useOptionalSelectInput(defaultValue);
  const dateDue = useOptionalDateInput();
  const [submitting, setSubmitting] = React.useState(false);
  const [errors, setErrors] = React.useState<any>({});

  const siteOptions = React.useMemo(
    () => sites.map(s => ({ label: s.name, value: s.uid })),
    [sites],
  );

  const userOptions = React.useMemo(
    () => users.map(user => ({ label: user.username, value: user.uid })),
    [users],
  );

  const submit = async () => {
    if (!companyUid) {
      return;
    }
    setSubmitting(true);
    const { data, errors: err } = await companyApi.createAction(companyUid, {
      userUid: assignedTo.value ? assignedTo.value.value : '',
      siteUid: site.value ? site.value.value : '',
      description: description.value,
      correctiveAction: correctiveAction.value,
      urgent: urgent.checked,
      dueDate: dateDue.value ? dateDue.value.unix() : undefined!,
      incidentUid,
      submissionUid,
      formOptionId,
    });

    if (err) {
      setErrors(err);
    }

    setSubmitting(false);

    if (data && onCreate) {
      onCreate(data.action);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={'Create an Incident'}
      handleClose={handleClose}
    >
      <ModalHeader handleClose={handleClose}>New Action</ModalHeader>
      <ModalBody>
        <form>
          {!companyUid && (
            <InlineSelect
              icon={<Apartment />}
              options={[]}
              label={getDivisionLabel()}
            />
          )}
          <InlineSelect
            icon={<Location />}
            options={siteOptions}
            defaultValue={defaultValue}
            label={getSiteLabel()}
            errorMessage={errors.siteUid}
            {...site}
          />
          <InlineSelect
            icon={<User />}
            options={userOptions}
            label={'Assigned To'}
            errorMessage={errors.userUid}
            {...assignedTo}
          />
          <InlineDatePicker
            label="Date Due"
            {...dateDue}
            errorMessage={errors.dueDate}
          />
          <hr />
          <div className="flex flex-col">
            <label htmlFor="description" className={'my-2'}>
              Description
            </label>
            <textarea className={'w-full p-4'} {...description} />
            {errors.description && (
              <ErrorMessage>{errors.description}</ErrorMessage>
            )}
          </div>
          <div className="flex flex-col">
            <label htmlFor="description" className={'my-2'}>
              Corrective Action
            </label>
            <textarea className={'w-full p-4'} {...correctiveAction} />
          </div>
          <div className={'flex flex-row justify-between items-center my-2'}>
            <label htmlFor={'urgent'}>Is the Action urgent?</label>
            <Toggle id={'urgent'} icons={false} {...urgent} />
          </div>
        </form>
      </ModalBody>
      <ModalFooter right={true}>
        <Button type={'primary'} onClick={submit} loading={submitting}>
          {t('common:submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withNamespaces()(ActionCreate);

import * as React from 'react';
import {
  Company,
  Crew,
  Form,
  Site,
  User as IUser,
  CompanyGroup,
} from '../../../types';
import AuditDashboardContainer from '../../../containers/AuditDashboard';

interface Props {
  companyGroup: CompanyGroup;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
  companies: Company[];
}

const AuditDashboard = ({
  companyGroup,
  users,
  crews,
  forms,
  sites,
  companies,
}: Props) => {
  return (
    <div>
      <AuditDashboardContainer
        users={users}
        crews={crews}
        companies={companies}
        companyGroup={companyGroup}
        forms={forms}
        sites={sites}
      />
    </div>
  );
};

export default AuditDashboard;

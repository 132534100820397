import * as React from 'react';
import * as R from 'ramda';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import InlineInput from '../../../components/InlineInput';
import UserGroup from '../../../svg/UserGroup';
import Button from '../../../components/Button';
import crewApi from '../../../api/crew.api';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Company, Crew } from '../../../types';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getCrewLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  onCreate: (crew: Crew) => void;
}

interface State {
  name: string;
  loading: boolean;
  errors: {
    name?: string;
  };
}

class CrewCreate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      loading: false,
      errors: {
        name: '',
      },
    };
  }

  private submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    this.setState({
      loading: true,
    });

    try {
      const { crew } = await crewApi.create(this.props.company.uid, {
        name: this.state.name,
      });

      this.props.onCreate(crew);

      this.setState({
        errors: {},
        name: '',
      });
    } catch (e) {
      this.setState({
        errors: e.response.data.errors,
      });
    }

    this.setState({
      loading: false,
    });
  };

  private onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      name: e.currentTarget.value,
    });
  };

  public render() {
    const { isOpen, handleClose, t } = this.props;
    const { name, errors, loading } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={`Create ${getCrewLabel()}`}
        handleClose={handleClose}
      >
        <ModalHeader handleClose={handleClose}>
          {`Create ${getCrewLabel()}`}
        </ModalHeader>

        <form onSubmit={this.submit}>
          <ModalBody>
            <InlineInput
              label={`${getCrewLabel()} Name`}
              icon={<UserGroup />}
              inputProps={{
                placeholder: `${getCrewLabel()} Name`,
              }}
              value={name}
              onChange={this.onNameChange}
              errorMessage={errors.name}
            />
          </ModalBody>
          <ModalFooter right={true}>
            <Button
              type={'primary'}
              disabled={R.trim(name) === ''}
              loading={loading}
            >
              {t('common:submit')}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
  };
}

export default withNamespaces()(connect(mapStateToProps)(CrewCreate));

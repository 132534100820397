import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const CloseOutline = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm1.41-1.41A8 8 0 1 0 15.66 4.34 8 8 0 0 0 4.34 15.66zm9.9-8.49L11.41 10l2.83 2.83-1.41 1.41L10 11.41l-2.83 2.83-1.41-1.41L8.59 10 5.76 7.17l1.41-1.41L10 8.59l2.83-2.83 1.41 1.41z" />
  </svg>
);

export default BaseSVG(CloseOutline);

import * as React from 'react';
import Radio from './Radio';

interface Props {
  inputs: Array<{ label: string; value: string }>;
  checked: string;
  onChange: (value: string) => void | any;
}

const Radios = ({ inputs, checked, onChange }: Props) => {
  const handleChange = (state: { checked: boolean; value: string }) => {
    if (state.checked) {
      onChange(state.value);
    }
  };

  return (
    <div>
      {inputs.map(({ label, value }) => (
        <Radio
          id={label}
          label={label}
          checked={value === checked}
          onChange={handleChange}
          value={value}
          className={'mr-2'}
        />
      ))}
    </div>
  );
};

export default Radios;

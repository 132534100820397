import api, { api2 } from './api';
import { localStorageKeys } from '../constants/index';

const authApi = {
  login: (username: string, password: string) =>
    api('POST', 'auth/login', { username, password }),
  refreshToken: () => {
    const refreshToken = localStorage.getItem(localStorageKeys.REFRESH_TOKEN);
    return api2<{ token: string }>('POST', 'refresh', {
      refreshToken,
    });
  },
};

export default authApi;

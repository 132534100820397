import * as React from 'react';
import SideNavigation from '../components/SideNavigation';
import styled from 'styled-components';
import { biggerThanMD } from '../helpers/style';
import Desktop from '../svg/Desktop';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { StoreState } from '../store';
import { Company as ICompany, Site as ISite, AuthPermissions } from '../types';
import { withNamespaces } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import Loading from '../components/Loading';
import Location from '../svg/Location';
import File from '../svg/File';
import Book from '../svg/Book';
import CheckCircle from '../svg/CheckCircle';
import UserGroup from '../svg/UserGroup';
import SiteManager from './worker/SiteManager';
import { Fab, FabAction } from '../components/Fab';
import Menu from '../svg/Menu';
import { cssVars } from '../constants/index';
import { History } from 'history';
import contains from 'ramda/es/contains';
import Dashboard from './worker/Dashboard';
import CrewManager from './worker/CrewManager';
import Sites from './worker/Sites';
import Site from './worker/Site';
import Form from './worker/Form';
import Files from './worker/Files';
import Actions from './worker/Actions';
import Action from './worker/Action';
import Profile from './worker/Profile';
import Checklists from './worker/Checklists';
import Checklist from './worker/Checklist';
import Documents from './worker/Documents';
import DocumentDetail from './worker/DocumentDetail';
import ChecklistEdit from './worker/checklist/ChecklistEdit';
import Announcement from '../svg/Announcement';
import FillableDocument from './worker/FillableDocument';
import { useRef } from 'react';
import {
  getSiteLabelPlural,
  getCrewLabel,
  getSiteLabel,
  unidashUrl,
} from '../helpers';
import FolderOutline from '../svg/FolderOutline';
import Forms from './worker/Forms';

const DashboardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${biggerThanMD(`
    flex-direction: row;
  `)};
`;

const DashboardScrollingContent = styled.div`
  flex: 1;
  position: relative;
  overflow-y: scroll;
`;

interface Props {
  company: ICompany;
  sites: ISite;
  t: TranslationFunction;
  history: History;
  permissions: AuthPermissions;
}

const Worker = ({ t, permissions, company, sites, history }: Props) => {
  const scrollContent = useRef<HTMLDivElement>(null);

  if (!company.uid) {
    return null;
  }

  const companyPerms = permissions.companies.find(
    x => x.companyUid === company.uid,
  );
  const isCrewManager = companyPerms
    ? contains('crewManager', companyPerms.roles)
    : false;
  const isSiteManager = companyPerms
    ? contains('siteManager', companyPerms.roles)
    : false;

  const routes = [
    {
      label: t('common:dashboard'),
      icon: <Desktop />,
      path: '/dashboard/worker/dashboard',
    },
    {
      label: `${getSiteLabelPlural()}`,
      icon: <Location />,
      path: '/dashboard/worker/sites',
    },
    ...(company.forms2
      ? [
          {
            label: t('common:forms'),
            icon: <File />,
            path: unidashUrl(`/forms`) || '',
            badge: <span>V2</span>,
            external: true,
          },
        ]
      : []),
    ...(company.document_management
      ? [
          {
            label: t('common:documents'),
            icon: <Book />,
            path: unidashUrl(`/documents`) || '',
            badge: <span>New</span>,
            external: true,
          },
        ]
      : []),
    {
      label: t('common:files'),
      icon: <FolderOutline />,
      path: '/dashboard/worker/files',
    },
    {
      label: t('common:actions'),
      icon: <Announcement />,
      path: '/dashboard/worker/actions',
    },
    {
      label: t('common:checklists'),
      icon: <CheckCircle />,
      path: '/dashboard/worker/checklists',
    },
    // {
    //   label: t('common:documents'),
    //   icon: <Book />,
    //   path: '/dashboard/worker/documents',
    // },
    ...(isCrewManager
      ? [
          {
            label: `${getCrewLabel()} Manager`,
            icon: <UserGroup />,
            path: '/dashboard/worker/crew-manager',
          },
        ]
      : []),
    ...(false
      ? [
          {
            label: `${getSiteLabel()} Manager`,
            icon: <Location />,
            path: '/dashboard/worker/site-manager',
          },
        ]
      : []),
  ];

  return (
    <DashboardContainer>
      <SideNavigation routes={routes} />
      <DashboardScrollingContent ref={scrollContent} id="scrollContent">
        <React.Suspense fallback={<Loading loading={true} />}>
          <Route path={'/dashboard/worker/dashboard'} component={Dashboard} />
          <Route
            path={'/dashboard/worker/sites'}
            component={Sites}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/forms'}
            component={Forms}
            exact={true}
          />
          <Route path={'/dashboard/worker/sites/:siteUid'} component={Site} />
          <Route
            path={'/dashboard/worker/forms/:fcUid'}
            component={Form}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/forms/:fcUid/submission/:submissionUid'}
            component={Form}
            exact={true}
          />
          <Route
            path={
              '/dashboard/worker/forms/:fcUid/submission/:submissionUid/revision/:revId'
            }
            component={Form}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/formsV2/:instanceUid'}
            render={matchProps => (
              <FillableDocument
                {...matchProps}
                company={company}
                scrollContent={scrollContent}
              />
            )}
            exact={true}
          />
          <Route
            path={
              '/dashboard/worker/formsV2/:instanceUid/revisions/:instanceRevisionUid'
            }
            render={matchProps => (
              <FillableDocument
                {...matchProps}
                company={company}
                scrollContent={scrollContent}
              />
            )}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/files'}
            exact={true}
            component={Files}
          />
          <Route path={'/dashboard/worker/files/*'} component={Files} />
          <Route
            path={'/dashboard/worker/actions'}
            component={Actions}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/actions/:actionUid'}
            component={Action}
          />
          <Route
            path={'/dashboard/worker/checklists'}
            component={Checklists}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/documents'}
            component={Documents}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/documents/details'}
            component={DocumentDetail}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/checklists/:checklistUid'}
            component={Checklist}
            exact={true}
          />
          <Route
            path={'/dashboard/worker/checklists/:checklistUid/edit'}
            component={ChecklistEdit}
          />
          <Route path={'/dashboard/worker/profile'} component={Profile} />
          <Route
            path={'/dashboard/worker/crew-manager'}
            component={CrewManager}
          />
          <Route
            path={'/dashboard/worker/site-manager'}
            component={SiteManager}
          />
        </React.Suspense>
        <div className="md:hidden">
          <Fab
            event={'click'}
            mainButtonStyles={{
              backgroundColor: cssVars.colors.red,
            }}
            position={{
              bottom: 0,
              right: 0,
            }}
            icon={<Menu className="w-6 h-6" />}
          >
            {routes.map(x => (
              <FabAction
                key={x.path}
                style={{
                  backgroundColor: cssVars.colors.grey,
                }}
                text={x.label}
                onClick={() => history.push(x.path)}
              >
                {React.cloneElement(x.icon, {
                  className: 'w-6 h-6 text-black',
                })}
              </FabAction>
            ))}
          </Fab>
        </div>
      </DashboardScrollingContent>
    </DashboardContainer>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    permissions: state.me.permissions,
  };
}

export default withNamespaces()<any>(connect(mapStateToProps)(Worker));

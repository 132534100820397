import * as React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { biggerThanSM } from '../helpers/style';

const Header = styled.header`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;

  ${biggerThanSM(`
    flex-direction: row;
    justify-content: space-between;
  `)};
`;

const Title = styled.h2``;
const Subtitle = styled.h4`
  font-weight: 400;
`;

const LeftWrapper = styled.div`
  width: 100%;
  margin-bottom: 12px;

  ${biggerThanSM(`
    width: auto;
    margin-bottom: 0;
  `)};
`;

const RightWrapper = styled.div`
  width: 100%;

  ${biggerThanSM(`
    width: auto;
  `)};
`;

interface Props {
  title: string;
  subtitle?: string;
  renderRight?: () => ReactNode;
}

export default class PageHeading extends React.Component<Props> {
  public render() {
    const { title, subtitle, renderRight } = this.props;
    return (
      <Header>
        <LeftWrapper>
          <Title>{title}</Title>
          {subtitle && <Subtitle>{subtitle}</Subtitle>}
        </LeftWrapper>

        {renderRight && <RightWrapper>{renderRight()}</RightWrapper>}
      </Header>
    );
  }
}

import * as React from 'react';
import styled from 'styled-components';
import { useState, useEffect, useCallback } from 'react';
import WidgetAdd from './formFabricator/WidgetAdd';
import Inspection from './formFabricator/Inspection';
import { formWidgetTypes, cssVars } from '../constants/index';
import Title from './formFabricator/Title';
import { WidgetType, Widget } from '../types';
import ConfirmationDialogue from './formFabricator/ConfirmationDialogue';
import { t } from 'i18next';
import formService from '../services/forms.service';
import { useSimpleFetch } from '../helpers/hooks';
import Loading from '../components/Loading';
import Question from './formFabricator/Question';
import FileUpload from './formFabricator/FileUpload';
import Content from './formFabricator/Content';
import Selection from './formFabricator/Selection';
import User from './formFabricator/User';
import InlineAlert from '../components/InlineAlert';
import ChevronUp from '../svg/ChevronUp';
import ChevronDown from '../svg/ChevronDown';
import { toast } from 'react-toastify';
import { debounce } from 'lodash';

const AddWidget = styled.button`
  border: solid 2px #ccc;
  border-radius: 3px;
  display: block;
  width: 100%;
  padding: 20px 15px;
  text-align: center;
  font-size: 16px;
  background-color: transarent;
  color: #ccc;
  transition-duration: 0.3s;

  &:hover {
    cursor: pointer;
    color: #fff;
    background-color: #494949;
    border-color: #333;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: ${cssVars.margin[4]};
  border: dashed 2px transparent;
  border-radius: 3px;
  transition-duration: 0.3s;

  &:hover {
    border: dashed 2px ${cssVars.colors.border};
  }
`;

interface Props {
  formUid: string;
  formRevUid: string;
  setEditErrors: React.Dispatch<React.SetStateAction<string[]>>;
  editErrors: string[];
}

const widgetLookup = {
  [formWidgetTypes.INSPECTION]: Inspection,
  [formWidgetTypes.TITLE]: Title,
  [formWidgetTypes.QUESTION]: Question,
  [formWidgetTypes.FILE]: FileUpload,
  [formWidgetTypes.CONTENT]: Content,
  [formWidgetTypes.SELECTION]: Selection,
  [formWidgetTypes.USER]: User,
};

const FabricatorView = ({
  formUid,
  formRevUid,
  setEditErrors,
  editErrors,
}: Props) => {
  const [addWidgetOpen, setAddWidgetOpen] = useState(false);
  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const [deleteWidgetId, setDeleteWidgetId] = useState('');
  const [published, setPublished] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const widgets = useSimpleFetch<Widget[]>({
    initial: [],
    change: [formRevUid],
    fetch: () => formService.widgets(formRevUid),
    accessor: 'widgets',
  });

  const updateWidget = (uid: string, data: Widget) => {
    widgets.setValue(
      widgets.value.map(widget => {
        if (widget.uid === uid) {
          return data;
        }
        return widget;
      }),
    );
  };

  async function handleOrderChange({
    curIndex,
    referenceIndex,
    selectedUid,
    referenceWidgetUid,
  }: {
    curIndex: number;
    referenceIndex: number;
    selectedUid: string;
    referenceWidgetUid?: string;
  }) {
    referenceIndex = Math.max(0, referenceIndex);
    const widgetArray = [...widgets.value];
    const tmp = widgetArray[curIndex];
    widgetArray[curIndex] = widgetArray[referenceIndex];
    widgetArray[referenceIndex] = tmp;
    widgets.setValue(widgetArray);
    updatePosition(selectedUid, referenceWidgetUid);
  }

  const updatePosition = useCallback(
    debounce(async (selectedUid: string, referenceWidgetUid?: string) => {
      const { data, errors } = await formService.widgetPutAfter(
        selectedUid,
        referenceWidgetUid,
      );
      if (errors || !data) {
        toast.error('Failed to update the position pf the widget!');
      }
    }, 800),
    [],
  );

  const closeAddWidget = () => {
    setAddWidgetOpen(false);
  };

  async function getPublishedStatus() {
    if (!formUid) {
      return;
    }
    const { data } = await formService.getPublished(formUid);
    if (data && data.published) {
      setPublished(true);
    }
  }

  async function fetchInitialData() {
    setLoading(true);
    await getPublishedStatus();
    setLoading(false);
  }

  useEffect(() => {
    fetchInitialData();
  }, []);

  const onWidgetAdded = async (type: WidgetType) => {
    closeAddWidget();
    const order = widgets.value.reduce(
      (highest, next) => (highest < next.order ? next.order : highest),
      0,
    );
    const { data } = await formService.createWidget(formRevUid, {
      type,
      order: order + 1,
    });
    if (data) {
      widgets.setValue([...widgets.value, data.widget]);
    }
  };

  const closeDeleteModal = () => {
    setDeleteIsOpen(false);
  };

  const openDeleteModal = (id: string) => {
    setDeleteIsOpen(true);
    setDeleteWidgetId(id);
  };

  const handleDelete = async (uid: string) => {
    closeDeleteModal();
    const { data } = await formService.deleteWidget(formRevUid, uid);
    if (data) {
      const filteredWidgets = widgets.value.filter(el => el.uid !== uid);
      widgets.setValue(filteredWidgets);
    }
  };

  return (
    <React.Fragment>
      {!published &&
        !loading && (
          <InlineAlert
            type={'warning'}
            text={
              'In order for your workers to see this form, you must publish it first'
            }
          />
        )}
      <div
        style={{
          backgroundColor: '#fff',
          borderRadius: '5px',
          padding: '10px',
          position: 'relative',
        }}
      >
        <Loading loading={widgets.loading} />
        {widgets.value.map((widget: Widget, index) => (
          <Wrapper key={widget.uid} id={widget.uid} className={`${index}`}>
            <div className="ml-2" style={{ width: '30px' }}>
              <div className="flex flex-col justify-center items-center h-full">
                {index !== 0 && (
                  <ChevronUp
                    className={'w-10 h-10 cursor-pointer'}
                    color={cssVars.colors['grey-darker']}
                    onClick={() => {
                      handleOrderChange({
                        selectedUid: widget.uid,
                        referenceWidgetUid:
                          widgets.value[index - 2] &&
                          widgets.value[index - 2].uid,
                        curIndex: index,
                        referenceIndex: index - 1,
                      });
                    }}
                  />
                )}
                {index + 1 !== widgets.value.length && (
                  <ChevronDown
                    className={'w-10 h-10 cursor-pointer'}
                    color={cssVars.colors['grey-darker']}
                    onClick={() => {
                      if (index > widgets.value.length) {
                        return;
                      }
                      handleOrderChange({
                        selectedUid: widget.uid,
                        referenceWidgetUid: widgets.value[index + 1].uid,
                        curIndex: index,
                        referenceIndex: index + 1,
                      });
                    }}
                  />
                )}
              </div>
            </div>
            <div className="w-full">
              {(() => {
                const WidgetComponent = widgetLookup[widget.type];
                if (!WidgetComponent) {
                  return null;
                }
                return (
                  <WidgetComponent
                    formRevisionUid={formRevUid}
                    widget={widget}
                    formUid={formUid}
                    setWidgets={updateWidget}
                    mandatory={widget.mandatory}
                    openDelete={openDeleteModal}
                    setEditErrors={setEditErrors}
                    editErrors={editErrors}
                  />
                );
              })()}
            </div>
          </Wrapper>
        ))}
        <AddWidget onClick={() => setAddWidgetOpen(true)}>
          Add Widget +
        </AddWidget>
      </div>
      <ConfirmationDialogue
        title={t('forms:deleteOptionModalTitle')}
        body={t('forms:deleteOptionModalBody')}
        contentLabel={t('forms:deleteOptionModalTitle')}
        handleClose={closeDeleteModal}
        onConfirm={handleDelete}
        isOpen={deleteIsOpen}
        id={deleteWidgetId}
      />
      <WidgetAdd
        isOpen={addWidgetOpen}
        handleClose={closeAddWidget}
        onCreate={onWidgetAdded}
      />
    </React.Fragment>
  );
};

export default FabricatorView;

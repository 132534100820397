import * as React from 'react';
import InlineLocationInput, {
  LatLng,
} from '../../../components/InlineLocationInput';
import UserGroup from '../../../svg/UserGroup';
import Location from '../../../svg/Location';
import InlineInput from '../../../components/InlineInput';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getSiteLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  name: string;
  location: string;
  onNameChange: (name: string) => void;
  onLocationChange: (location: string) => void;
  onLatLngChange: (latLng: LatLng) => void;
  errors: {
    name?: string;
  };
}

class SiteModifyForm extends React.Component<Props> {
  private onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.props.onNameChange(e.currentTarget.value);
  };

  public render() {
    const {
      name,
      location,
      errors,
      onLocationChange,
      onLatLngChange,
      t,
    } = this.props;

    return (
      <React.Fragment>
        <InlineInput
          label={`${getSiteLabel()} Name`}
          icon={<UserGroup />}
          inputProps={{
            placeholder: `${getSiteLabel()} Name`,
          }}
          value={name}
          onChange={this.onNameChange}
          errorMessage={errors.name}
        />

        <InlineLocationInput
          label={t('super:screens.sites.locationInputLabel')}
          icon={<Location />}
          value={location}
          onChange={onLocationChange}
          onLatLng={onLatLngChange}
        />
      </React.Fragment>
    );
  }
}

export default withNamespaces()(SiteModifyForm);

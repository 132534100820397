import * as React from 'react';
import InlineInputBase from './InlineInputBase';
import DatePicker from './DatePicker';
import { Moment } from 'moment';
import Calendar from '../svg/Calendar';
import styled from 'styled-components';
import { cssVars } from '../constants';

const TextInput = styled.input`
  &:disabled {
    background: ${cssVars.colors['grey-light']};
  }
`;

interface Props {
  label: string;
  className?: string;
  margin?: boolean;
  value?: Moment | null;
  onChange?: (date: Moment) => void;
  onClear?: () => void;
  errorMessage?: string | string[];
  disabled?: boolean;
}

export default class InlineDatePicker extends React.Component<Props> {
  private onKeyDown = (e: React.KeyboardEvent) => {
    if (e.keyCode === 8 && this.props.onClear) {
      this.props.onClear();
    }
  };

  public render() {
    const {
      label,
      className,
      margin,
      value,
      onChange,
      errorMessage,
      disabled,
    } = this.props;
    return (
      <InlineInputBase
        label={label}
        className={className}
        margin={margin}
        icon={<Calendar />}
        errorMessage={errorMessage}
      >
        <DatePicker
          wrapperClassName={'flex-1'}
          value={value}
          onChange={onChange}
          disabled={disabled}
        >
          {({ getInputProps, ref }) => (
            <TextInput
              ref={ref}
              {...getInputProps()}
              className={'flex-1 w-full h-full border-0 p-3'}
              onKeyDown={this.onKeyDown}
              disabled={disabled}
            />
          )}
        </DatePicker>
      </InlineInputBase>
    );
  }
}

import * as roleCertificateMatrixActions from '../actions/roleCertificateMatrix';
import { ActionType, getType } from 'typesafe-actions';
import { PermissionsError } from '../types';

export type RoleCertificateMatrixAction = ActionType<
  typeof roleCertificateMatrixActions
>;

export interface RoleCertificateMatrixState {
  loading: boolean;
  loaded: boolean;
  errors: PermissionsError;
  data: {
    [key: string]: string[];
  };
  companyUid: string;
}

const DEFAULT_STATE: RoleCertificateMatrixState = {
  loading: false,
  loaded: false,
  errors: {
    token: [],
  },
  data: {},
  companyUid: '',
};

export default function roleCertificateMatrixReducer(
  state: RoleCertificateMatrixState = DEFAULT_STATE,
  action: RoleCertificateMatrixAction,
) {
  switch (action.type) {
    case getType(
      roleCertificateMatrixActions.roleCertificateMatrixFetchRequest,
    ):
      return {
        ...state,
        loading: true,
        companyUid: action.payload,
      };
    case getType(
      roleCertificateMatrixActions.roleCertificateMatrixFetchSuccess,
    ):
      return {
        ...state,
        loading: false,
        loaded: true,
        data: { ...action.payload },
      };
    case getType(
      roleCertificateMatrixActions.roleCertificateMatrixFetchFailure,
    ):
      return {
        ...state,
        loaded: false,
        loading: false,
        data: {},
        errors: { ...action.payload },
      };
    case getType(roleCertificateMatrixActions.setRoleCertificateMatrix):
      return {
        ...state,
        data: { ...action.payload },
      };
    case getType(roleCertificateMatrixActions.addRequiredCertificateToRole):
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.roleUid]: [
            ...(state.data[action.payload.roleUid] || []),
            action.payload.certTypeUid,
          ],
        },
      };
    case getType(roleCertificateMatrixActions.removeReuiredCertificateFromRole):
      return {
        ...state,
        data: {
          ...state.data,
          [action.payload.roleUid]: (
            state.data[action.payload.roleUid] || []
          ).filter(certUid => certUid !== action.payload.certTypeUid),
        },
      };
    default:
      return state;
  }
}

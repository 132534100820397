import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption } from '../../types';
import OptionTitle from './OptionTitle';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import Button from '../../components/Button';
import WidgetContainer from './WidgetContainer';
import WidgetContent from './WidgetContent';
import submissionApi from '../../api/submission.api';
import { useMountVisibility } from '../../helpers/hooks';
import { Dispatch } from 'redux';
import { FormState } from '../../reducers/form';
import { setFormMeta } from '../../actions/form';
import ActionPreview from './ActionPreview';
import FormActionCreate from './FormActionCreate';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  live?: boolean;
  submUid?: string;
  setFormMeta: (meta: Partial<FormState>) => any;
}

const Actions = ({ option, t, live, submUid, ...props }: Props) => {
  if (!option) {
    return null;
  }

  const createState = useMountVisibility();

  const title = getOptionTitle(option, { live }) || '';

  const onActionCreate = async () => {
    createState.close();
    if (!submUid) {
      return;
    }
    const { data } = await submissionApi.actions(submUid);
    if (data) {
      props.setFormMeta({ actions: data.actions });
    }
  };

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent>
        <div className="p-4 bg-form-option-row mb-4">
          <Button type={'primary'} onClick={createState.open}>
            {t('forms:addActionButton')}
          </Button>
        </div>
        <ActionPreview optionId={option.id} />
        {createState.mounted && (
          <FormActionCreate
            isOpen={createState.visible}
            handleClose={createState.close}
            onCreate={onActionCreate}
            formOptionId={option.id}
          />
        )}
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submUid: state.form.submissionUid,
  });
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setFormMeta: (meta: Partial<FormState>) => dispatch(setFormMeta(meta)),
  };
}

export default withNamespaces()(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  )(Actions),
);

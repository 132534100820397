import { createAction } from 'typesafe-actions';
import { PermissionsError, Company } from '../../types';
import {
  COMPANY_GROUP_COMPANIES_FETCH_SUCCESS,
  COMPANY_GROUP_COMPANIES_FETCH_REQUEST,
  COMPANY_GROUP_COMPANIES_FETCH_FAILURE,
  COMPANY_GROUP_COMPANIES_ADD,
  COMPANY_GROUP_COMPANIES_REMOVE,
} from '../../constants/actionTypes';

export const companyGroupCompaniesFetchRequest = createAction(
  COMPANY_GROUP_COMPANIES_FETCH_REQUEST,
  resolve => (companyGroupUid: string) => resolve(companyGroupUid),
);

export const companyGroupCompaniesFetchSuccess = createAction(
  COMPANY_GROUP_COMPANIES_FETCH_SUCCESS,
  resolve => (companies: Company[]) => resolve(companies),
);

export const companyGroupCompaniesFetchFailure = createAction(
  COMPANY_GROUP_COMPANIES_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const companyGroupAddCompany = createAction(
  COMPANY_GROUP_COMPANIES_ADD,
  resolve => (company: Company) => resolve(company),
);

export const companyGroupRemoveCompany = createAction(
  COMPANY_GROUP_COMPANIES_REMOVE,
  resolve => (companyUid: string) => resolve(companyUid),
);

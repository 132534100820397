import * as React from 'react';
import userApi from '../../../../api/user.api';
import crewApi from '../../../../api/crew.api';
import siteApi from '../../../../api/site.api';
import InlineSelect from '../../../../components/InlineSelect';
import { useOptionalSelectInput } from '../../../../helpers/hooks';
import { Company } from '../../../../types';
import ActualTrainingMatrix from '../../../../containers/TrainingMatrix';
import Suitcase from '../../../../svg/Suitcase';
import Loading from '../../../../components/Loading';
import InlineAlert from '../../../../components/InlineAlert';

interface Props {
  type: 'user' | 'crew' | 'site' | 'company';
  entityUid: string;
}

const companyEndpoints = {
  user: userApi.companies,
  crew: crewApi.companies,
};

export const TrainingMatrix = ({ type, entityUid }: Props) => {
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [singleCompany, setSingleCompany] = React.useState('');
  const company = useOptionalSelectInput();
  const [loadingCompanies, setLoadingCompanies] = React.useState(false);

  const fetchCompanies = async () => {
    if (type === 'company') {
      setSingleCompany(entityUid);
      return;
    }

    if (type === 'site') {
      setLoadingCompanies(true);
      const { data: siteRes } = await siteApi.company(entityUid);
      if (siteRes) {
        setSingleCompany(siteRes.company.uid);
      }
      setLoadingCompanies(false);
      return;
    }

    setLoadingCompanies(true);
    const { data } = await companyEndpoints[type](entityUid);

    if (data) {
      setCompanies(data.companies);
    }
    setLoadingCompanies(false);
  };

  React.useEffect(() => {
    setSingleCompany('');
    company.onChange(null);
  }, [type]);

  React.useEffect(() => {
    setCompanies([]);
    fetchCompanies();
  }, [type, entityUid]);

  const companyOptions = companies.map(c => ({ label: c.name, value: c.uid }));

  const companyUid =
    type === 'site' || type === 'company' ? singleCompany : company.selected;

  const showCompanySelect = type === 'crew' || type === 'user';

  return (
    <div className="bg-white p-4 relative">
      <Loading loading={loadingCompanies} />
      {showCompanySelect && (
        <InlineSelect
          icon={<Suitcase />}
          options={companyOptions}
          label="Division"
          {...company}
        />
      )}
      {companyUid && (
        <ActualTrainingMatrix
          companyUid={companyUid}
          view={type}
          entityUid={entityUid}
        />
      )}
    </div>
  );
};

export default function TrainingMatrixTemporarilyDisabled(_: any) {
  <div className="p-8 text-center">
    <InlineAlert
      type={'warning'}
      text={`This feature is currently disabled while we work to improve it. Thanks
    for understanding!`}
    />
  </div>;
}

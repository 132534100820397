import { createAction } from 'typesafe-actions';
import { PermissionsError, Form } from '../types';
import {
  FORMS_FETCH_REQUEST,
  FORMS_FETCH_SUCCESS,
  FORMS_FETCH_FAILURE,
  FORMS_ADD,
  FORMS_REMOVE,
  FORMS_INVALIDATE,
  FORMS_UPDATE,
} from '../constants/actionTypes';

export const formsFetchRequest = createAction(
  FORMS_FETCH_REQUEST,
  resolve => (companyUid: string) => resolve(companyUid),
);

export const formsFetchSuccess = createAction(
  FORMS_FETCH_SUCCESS,
  resolve => (forms: Form[]) => resolve(forms),
);

export const formsFetchFailure = createAction(
  FORMS_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const addForm = createAction(FORMS_ADD, resolve => (form: Form) =>
  resolve(form),
);

export const updateForm = createAction(FORMS_UPDATE, resolve => (form: Form) =>
  resolve(form),
);

export const removeForm = createAction(
  FORMS_REMOVE,
  resolve => (formUid: string) => resolve(formUid),
);

export const formsInvalidate = createAction(FORMS_INVALIDATE, resolve => () =>
  resolve(),
);

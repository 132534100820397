import * as siteManagerActions from '../../actions/super/siteManagers';
import { ActionType, getType } from 'typesafe-actions';
import { User } from '../../types';

export type SuperSiteManagers = ActionType<typeof siteManagerActions>;

export type SuperSiteManagerState = User[];

const DEFAULT_STATE: SuperSiteManagerState = [];

export default function superSiteManagersReducer(
  state: SuperSiteManagerState = DEFAULT_STATE,
  action: SuperSiteManagers,
) {
  switch (action.type) {
    case getType(siteManagerActions.setSiteManagers):
      return [...action.payload];
    case getType(siteManagerActions.addSiteManager):
      return [...state, { ...action.payload }];
    case getType(siteManagerActions.removeSiteManager):
      return state.filter(user => user.uid !== action.payload);
    default:
      return state;
  }
}

import * as React from 'react';
import Sidebox, { SideboxContent } from '../components/Sidebox';
import SideboxHeader from '../components/sidebox/SideboxHeader';
import SideboxSectionHeader from '../components/sidebox/SideboxSectionHeader';
import Tabs from '../components/Tabs';
import ShareUsers from './folderSidebox/ShareUsers';
import Share from '../svg/Share';
import ShareCrews from './folderSidebox/ShareCrews';
import Information from '../svg/Information';
import { Storage, User, Company } from '../types';
import SideboxSectionContent from '../components/sidebox/SideboxSectionContent';
import Trash from '../svg/Trash';
import Download from '../svg/Download';
import ConfirmationDialogue from '../components/ConfirmationDialogue';
import { useMountVisibility } from '../helpers/hooks';
import storageApi from '../api/storage.api';
import moment from 'moment';
import { StoreState } from '../store';
import { Dispatch } from 'redux';
import { openDownloads } from '../actions/ui';
import { connect } from 'react-redux';
import companyUserApi from '../api/companyUser.api';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  storageUid: string;
  onDelete?: () => void;
  user: User;
  company: Company;
  openDownloads: () => any;
}

const FolderSidebox = ({
  isOpen,
  handleClose,
  storageUid,
  onDelete: pOnDelete,
  openDownloads: pOpenDownloads,
  company,
  user,
}: Props) => {
  const deleteState = useMountVisibility();
  const [loading, setLoading] = React.useState(false);

  const [storage, setStorage] = React.useState<Storage | null>(null);
  const [path, setPath] = React.useState('');
  const [downloading, setDownloading] = React.useState(false);

  const getStorage = async () => {
    const { data } = await storageApi.show(storageUid);
    if (data) {
      setStorage(data.storage);
      setPath(data.path.map(f => f.name).join('/'));
    }
  };

  const onDelete = async () => {
    setLoading(true);

    const { data } = await storageApi.delete(storageUid);

    if (data) {
      if (pOnDelete) {
        pOnDelete();
      }
      deleteState.close();
      handleClose();
    }

    setLoading(false);
  };

  const onDownload = async () => {
    setDownloading(true);
    await companyUserApi.download(company.uid, user.uid, [storageUid]);
    pOpenDownloads();
    setDownloading(false);
    handleClose();
  };

  React.useEffect(
    () => {
      if (storageUid) {
        getStorage();
      }
    },
    [storageUid],
  );

  return (
    <Sidebox
      contentLabel="Folder detail"
      isOpen={isOpen}
      handleClose={handleClose}
    >
      <SideboxHeader
        handleClose={handleClose}
        buttons={[
          { label: 'Delete', Icon: Trash, onClick: deleteState.open },
          {
            label: 'Download',
            Icon: Download,
            onClick: onDownload,
            loading: downloading,
          },
        ]}
      >
        Folder
      </SideboxHeader>

      <SideboxContent>
        <SideboxSectionHeader title="Share Information" leftIcon={<Share />} />
        <Tabs
          tabs={[
            {
              label: 'Users',
              component: <ShareUsers storageUid={storageUid} />,
            },
            {
              label: 'Crews',
              component: <ShareCrews storageUid={storageUid} />,
            },
          ]}
        />
      </SideboxContent>

      <SideboxSectionHeader
        title={'File Information'}
        leftIcon={<Information />}
      />
      <SideboxSectionContent>
        <div className="flex flex-row">
          <div className="pr-8">
            <p className={'mb-2'}>
              <strong>Type:</strong>
            </p>
            <p className={'mb-2'}>
              <strong>Size:</strong>
            </p>
            <p className={'mb-2'}>
              <strong>Location:</strong>
            </p>
            <p className={'mb-2'}>
              <strong>Created:</strong>
            </p>
          </div>
          {storage && (
            <div>
              <p className={'mb-2'}>-</p>
              <p className={'mb-2'}>-</p>
              <p className={'mb-2'}>{path}</p>
              <p className={'mb-2'}>
                {moment.unix(storage.created_at).format('MMM Do, YYYY hh:mm a')}
              </p>
            </div>
          )}
        </div>
      </SideboxSectionContent>

      {deleteState.mounted && (
        <ConfirmationDialogue
          isOpen={deleteState.visible}
          handleClose={deleteState.close}
          onConfirm={onDelete}
          title="Delete this folder?"
          body="This cannot be undone"
          contentLabel="Delete folder"
          loading={loading}
        />
      )}
    </Sidebox>
  );
};

const mapStateToProps = (state: StoreState) => ({
  user: state.me.user,
  company: state.me.company,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openDownloads: () => dispatch(openDownloads()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(FolderSidebox);

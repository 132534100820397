import * as React from 'react';
import BaseWidget from './BaseWidget';
import { HeaderAction } from './optionParentStyles';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import formOptionApi from '../../api/formOption.api';
import { formFeatureIds } from '../../constants';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { addFormOption } from '../../actions/formOptions';
import { FormOption } from '../../types';

interface Props extends WithNamespaces {
  optionId: number;
  openDeleteOption?: (optionId: number) => any;
  formUid: string;
  addFormOption?: (option: FormOption) => any;
  index: number;
}

class QuestionAnswer extends React.Component<Props> {
  private addQuestion = async () => {
    const { formUid, optionId: parentId } = this.props;

    try {
      const { option } = await formOptionApi.create({
        formUid,
        parentId,
        typeId: formFeatureIds.QUESTION_ANSWER_ITEM,
      });

      if (this.props.addFormOption) {
        this.props.addFormOption(option);
      }
    } catch (e) {
      //
    }
  };

  public render() {
    const { optionId, t, openDeleteOption, index } = this.props;

    return (
      <BaseWidget
        optionId={optionId}
        defaultTitle={t('common:formWidgets.questionsAndAnswers')}
        openDeleteOption={openDeleteOption}
        index={index}
        extraActions={[
          <HeaderAction key={0} onClick={this.addQuestion}>
            {t('super:screens.form.addQuestion')}
          </HeaderAction>,
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addFormOption: (option: FormOption) => dispatch(addFormOption(option)),
});

export default withNamespaces()(
  connect(
    null,
    mapDispatchToProps,
  )(QuestionAnswer),
);

import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import {
  Company,
  FormOption,
  User as IUser,
  SubmData,
  ParsedSubmData,
} from '../../types';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import StackedSelect from '../../components/StackedSelect';
import OptionTitle from './OptionTitle';
import WidgetContent from './WidgetContent';
import WidgetContainer from './WidgetContainer';
import { Dispatch } from 'redux';
import { usersFetchRequest } from '../../actions/users';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { UsersState } from '../../reducers/users';
import { useEntityRefresh } from '../../helpers/hooks';
import Avatar from '../../components/Avatar';
import { SelectOption } from '../../components/Select';
import { updateSubmData } from '../../actions/submData';
import submissionApi from '../../api/submission.api';
import Trash from '../../svg/Trash';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  live?: boolean;
  fetchUsers: (companyUid: string) => any;
  company: Company;
  submData?: ParsedSubmData;
  users: UsersState;
  updateSubm?: (submData: ParsedSubmData) => any;
  submUid?: string;
  editable?: boolean;
}

const Users = ({
  option,
  t,
  live,
  users,
  fetchUsers,
  submData,
  company,
  updateSubm,
  submUid,
  editable,
}: Props) => {
  useEntityRefresh(company.uid, users, fetchUsers);

  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live });
  const data = submData ? submData.parsed : {};

  const options = users.data.map(u => ({
    label: u.username,
    value: u.uid,
  }));

  const selected = Object.keys(data).map(key => ({
    uid: key,
    name: data[key].name,
    certs: data[key].crt || [],
    colors: data[key].colors || [],
  }));

  const onChange = async (e: SelectOption) => {
    if (!e || !updateSubm) {
      return;
    }

    const res = await submissionApi.updateFormOption(
      submUid!,
      option.id,
      e.value,
    );
    if (res.data) {
      updateSubm(res.data.submData);
    }
  };

  const onRemove = async (uid: string) => {
    if (!submUid) {
      return;
    }
    const res = await submissionApi.removeFormOptionData(
      submUid,
      option.id,
      uid,
    );
    if (res.data && updateSubm) {
      updateSubm(res.data.submData);
    }
  };

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent>
        <StackedSelect
          id={`option-${option.id}`}
          label={t('common:users')}
          options={options}
          disabled={!editable}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          value={null}
          onChange={onChange}
        />
        {selected.map(u => (
          <div
            key={u.uid}
            className="flex flex-col p-4 border-solid border-grey border-1 mt-4"
          >
            <div className="flex flex-row justify-between items-center">
              <div className="flex items-center">
                <Avatar
                  fallbackName={u.name}
                  bgColor={u.colors[0]}
                  fgColor={u.colors[1]}
                  size={'MD'}
                />
                <h3 className={'font-normal'}>{u.name}</h3>
              </div>
              {editable && (
                <button
                  className="bg-transparent border-0 p-2 cursor-pointer"
                  onClick={() => onRemove(u.uid)}
                >
                  <Trash className="w-6 h-6 text-red" />
                </button>
              )}
            </div>
            {u.certs.length > 0 && (
              <p className={'text-sm text-grey-darker mt-2'}>
                {u.certs.join(', ')}
              </p>
            )}
          </div>
        ))}
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    users: state.users,
    company: state.me.company,
    editable: state.form.editable,
    submUid: state.form.submissionUid,
  });
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    fetchUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
    updateSubm: (submData: ParsedSubmData) =>
      dispatch(updateSubmData(submData)),
  };
}

export default withNamespaces()(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  )(Users),
);

import * as React from 'react';
import { SignOff } from '../../../types';
import Loading from '../../../components/Loading';
import { useMountVisibility } from '../../../helpers/hooks';
import SignedUsersModal from './SignedUsersModal';
import moment from 'moment';
import Shield from '../../../svg/Shield';
import styled from 'styled-components';

const SignOffItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
`;

interface Props {
  signOffs: SignOff[];
  loading: boolean;
}

const SignOffs = ({ signOffs, loading }: Props) => {
  const signedUsersModal = useMountVisibility();

  const showSignedUsers = () => {
    const signedUsersArray: any[] = [];
    for (let i = 0; i <= signOffs.length - 1; i++) {
      signedUsersArray.push(
        <SignOffItem
          className={i % 2 === 0 ? 'bg-grey-light' : 'bg-grey-dark'}
          onClick={signedUsersModal.open}
        >
          <div className="flex flex-col">
            <p className="mr-2">{signOffs[i].full_name}</p>
            <i className="text-xs">
              {moment
                .unix(signOffs[i].created_at)
                .format('MMM D, YYYY, h:mm:ss a')}
            </i>
          </div>
          {signOffs[i].signature_id.includes('users/') && (
            <Shield className={'w-8 h-8'} />
          )}
        </SignOffItem>,
      );
    }

    return signedUsersArray;
  };

  return (
    <div className="flex flex-row mb-4 items-center">
      {loading && <Loading loading={loading} />}
      <div
        className="flex flex-col w-full overflow-y-scroll"
        style={{ maxHeight: '150px' }}
      >
        <p className="mb-4">Sign Offs:</p>
        {showSignedUsers()}
      </div>
      {signOffs && (
        <SignedUsersModal
          handleClose={signedUsersModal.close}
          isOpen={signedUsersModal.visible}
          signOffs={signOffs}
        />
      )}
    </div>
  );
};
export default SignOffs;

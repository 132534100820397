import { FormOption, SubmData } from '../types';
import { TranslationFunction } from 'i18next';

export const getOptionTitle = (
  option?: FormOption,
  options: { live?: boolean } = {},
) => {
  if (!option) {
    return '';
  }

  if (option.title_rev && !options.live) {
    return option.title_rev;
  }

  return option.title || '';
};

export const getOptionText = (
  option?: FormOption,
  options: { live?: boolean } = {},
) => {
  if (!option) {
    return '';
  }

  if (option.text_rev && !options.live) {
    return option.text_rev;
  }

  return option.text || '';
};

export const getOptionMandatory = (
  option?: FormOption,
  options: { live?: boolean } = {},
) => {
  if (!option) {
    return '';
  }

  if (option.mandatory_rev !== option.mandatory && !options.live) {
    return option.mandatory_rev;
  }

  return option.mandatory || 0;
};

interface AuditWidgetText {
  label: 'safe_unsafe' | 'pass_fail' | 'sat_unsat';
  ranks: {
    low: number;
    medium: number;
    high: number;
    extreme: number;
  };
  rank_labels: {
    low: string;
    medium: string;
    high: string;
    extreme: string;
  };
}

export const parseAuditText = (
  text: string,
  t: TranslationFunction,
): AuditWidgetText => {
  if (!text.startsWith('{')) {
    return {
      label: 'safe_unsafe',
      ranks: {
        low: 0,
        medium: 0,
        high: 0,
        extreme: 0,
      },
      rank_labels: {
        low: t('forms:low'),
        medium: t('forms:medium'),
        high: t('forms:high'),
        extreme: t('forms:extreme'),
      },
    };
  }

  return JSON.parse(text);
};

export const filterChildrenByPublished = (children: FormOption[] = []) =>
  children.filter(o => o.published === 1);

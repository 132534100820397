import * as React from 'react';
import styled from 'styled-components';
import { biggerThanMD } from '../../helpers/style';
import { cssVars } from '../../constants';
import Comment from '../../svg/Comment';
import { useMountVisibility } from '../../helpers/hooks';
import { ResponseFile, Widget, ResponseComment } from '../../types';
import ActionFileModal from './WidgetFileModal';
import Comments from './Comments';
import { useState, useEffect } from 'react';
import File from '../../svg/File';

const ActionButton = styled.button<{ bg?: string }>`
  background: transparent;
  border: none;
  padding: 0px;
  padding-right: 5px;
  cursor: pointer;
  flex: 1 1 0%;
  position: relative;

  ${biggerThanMD(`
    flex: 0;
  `)} &:first-of-type {
    border-left: 0;
  }

  svg {
    padding: 1px;
    fill: ${props => (props.bg ? props.bg : cssVars.colors['grey-darker'])};
  }
`;

const CounterBadge = styled.div`
  background: ${cssVars.colors.red};
  border-radius: 50px;
  border-color: ${cssVars.colors.red};
  border-width: 2px;
  border-style: solid;
  color: #fff;
  width: auto;
  min-width: 15px;
  height: 15px;
  position: absolute;
  top: 0%;
  right: -13%;
  line-height: 12px;
`;

interface Props {
  editable?: boolean;
  files?: ResponseFile[];
  widget: Widget;
  instanceRevUid: string;
  comments: ResponseComment[];
  showFileButton?: boolean;
}

function WidgetActionButtons({
  editable,
  files,
  widget,
  instanceRevUid,
  comments,
  showFileButton = true,
}: Props) {
  const comment = useMountVisibility();
  const image = useMountVisibility();

  const [localComments, setLocalComments] = useState<ResponseComment[]>(
    comments || [],
  );

  function onCommentCreate(createdComment: ResponseComment) {
    setLocalComments(last => [...last, createdComment]);
  }
  const fileCount = files ? files.length : 0;

  const commentCount = localComments.length;

  useEffect(
    () => {
      if (comments.length === 0) {
        return;
      }
      setLocalComments(comments);
    },
    [comments],
  );

  return (
    <div>
      <div>
        <ActionButton
          onClick={comment.visible ? comment.close : comment.open}
          type={'transparent'}
          className="mb-2 border-l-0"
          bg={commentCount > 0 ? cssVars.colors.primary : ''}
        >
          <Comment className={'w-8 h-8'} />
          <CounterBadge className={commentCount !== 0 ? '' : 'hidden'}>
            {commentCount}
          </CounterBadge>
        </ActionButton>
        {/* <InspectionButton
            onClick={action.open}
            type={'transparent'}
            className="mb-2 border-l-0"
            disabled={true}
          >
            <Announcement className={'w-8 h-8'} />
          </InspectionButton> */}
        {showFileButton && (
          <ActionButton
            onClick={image.open}
            type={'transparent'}
            className="mb-2 border-l-0"
            bg={fileCount !== 0 ? cssVars.colors.red : ''}
          >
            <File className={'w-8 h-8'} />
            <CounterBadge className={fileCount !== 0 ? '' : 'hidden'}>
              {fileCount > 0 ? fileCount : ''}
            </CounterBadge>
          </ActionButton>
        )}
      </div>
      <div className="mt-2">
        {comment.visible && (
          <Comments
            comments={localComments}
            widgetUid={widget.uid}
            instanceRevisionUid={instanceRevUid}
            onCreate={onCommentCreate}
            editable={editable}
          />
        )}
        {image.mounted && (
          <ActionFileModal
            editable={!!editable}
            isOpen={image.visible}
            handleClose={image.close}
            contentLabel={'File Upload'}
            instanceRevUid={instanceRevUid}
            widgetUid={widget.uid}
            files={files}
          />
        )}
      </div>
    </div>
  );
}

export default WidgetActionButtons;

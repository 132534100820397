import * as formActions from '../actions/form';
import { ActionType, getType } from 'typesafe-actions';
import { SubmissionActionItem } from '../api/submission.api';
import { Site } from '../types';

export type FormAction = ActionType<typeof formActions>;

interface AuditCompliance {
  weights: number;
  marked: number;
  ranked: number;
  observations: number;
  unsafe: {
    all: number;
    ranks: Array<{ label: string; value: number }>;
  };
}

export interface ComplianceState {
  audits_all?: AuditCompliance;
  compliance_all?: number;
  audits?: AuditCompliance;
  compliance?: number;
}

export interface FormState {
  editable: boolean;
  submissionUid: string;
  compliance: ComplianceState;
  actions: SubmissionActionItem[];
  site: Site | null;
}

const DEFAULT_STATE: FormState = {
  editable: false,
  submissionUid: '',
  compliance: {},
  actions: [],
  site: null,
};

export default function formReducer(
  state: FormState = DEFAULT_STATE,
  action: FormAction,
) {
  switch (action.type) {
    case getType(formActions.setFormMeta):
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

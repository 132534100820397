import * as React from 'react';
import InlineInput from '../../../components/InlineInput';
import { useTextInput } from '../../../helpers/hooks';
import User from '../../../svg/User';
import Envelope from '../../../svg/Envelope';
import Key from '../../../svg/Key';
import { User as IUser, UserMetadata } from '../../../types';
import Button from '../../../components/Button';
import Save from '../../../svg/Save';
import userApi from '../../../api/user.api';
import { toast } from 'react-toastify';
import EyeCross from '../../../svg/EyeCross';
import Eye from '../../../svg/Eye';
import formService from '../../../services/forms.service';
import { useState, useEffect, useRef } from 'react';
import Loading from '../../../components/Loading';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import SignatureCanvas, {
  isCanvasBlank,
} from '../../../components/SignatureCanvas';
import cloudinaryService from '../../../services/cloudinary.service';
import { dataURItoBlob, hideForADFS } from '../../../helpers';
import ProfilePicture from '../../../components/ProfilePicture';

interface Props {
  user: IUser;
}

const Overview = ({ user }: Props) => {
  const firstName = useTextInput(user.fname);
  const lastName = useTextInput(user.lname);
  const username = useTextInput(user.username);
  const email = useTextInput(user.email);
  const [pin, setPin] = useState<string>('');
  const [metadata, setMetadata] = useState<UserMetadata | null>(null);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<any>({});
  const [pinRevealed, setPinRevealed] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const oldPass = useTextInput();
  const newPass = useTextInput();
  const newAgain = useTextInput();
  const [resetting, setResetting] = React.useState(false);
  const numberRegex = /^[0-9]+$/;

  const resetPassword = async () => {
    if (newPass.value !== newAgain.value) {
      setErrors({ newAgain: ['does not match'] });
      return;
    }
    setResetting(true);
    const { data, errors: resErrors } = await userApi.resetPassword(user.uid, {
      oldPassword: oldPass.value,
      newPassword: newPass.value,
    });
    if (resErrors) {
      setErrors(resErrors);
    }
    if (data) {
      newPass.setValue('');
      newAgain.setValue('');
      oldPass.setValue('');
      setErrors({});
      toast.success('Password updated');
    }
    setResetting(false);
  };

  const onProfileSave = async () => {
    setLoading(true);
    try {
      await userApi.update(user.uid, {
        fname: firstName.value,
        lname: lastName.value,
        username: username.value,
        email: email.value,
      });
    } catch (e) {
      setErrors({ ...errors, ...e.errors });
    }
    if (pin.length < 4) {
      setErrors({ pin: 'Must enter 4 character PIN.' });
      setLoading(false);
      return;
    }
    if (!metadata) {
      toast.error('Error. Failed to reset PIN!');
      return;
    }
    const userCloudinaryFileSignature = await formService.getSignatureFile(
      user.uid,
    );
    if (!userCloudinaryFileSignature.data) {
      toast.error('Error. Could not get user signature!');
      return;
    }
    const { signature, folder, timestamp } = userCloudinaryFileSignature.data;
    const fileSignature = {
      signature,
      folder,
      timestamp,
    };
    if (!fileSignature || !metadata) {
      toast.error('Error. Could not upload signature');
      return;
    }
    if (!canvasRef.current) {
      setLoading(false);
      toast.error('Failed to upload signature');
      return;
    }
    if (isCanvasBlank(canvasRef.current)) {
      setLoading(false);
      toast.error('You cannot leave signature blank!');
      return;
    }
    const dataUrl = canvasRef.current.toDataURL();
    const cloudinaryRes = await cloudinaryService.uploadSigned(
      dataUrl,
      fileSignature,
    );
    const blob = dataURItoBlob(dataUrl);
    const sigData = new FormData();
    sigData.append('uploads', blob);
    const { errors: legacySignatureErrors } = await userApi.uploadSignature(
      user.uid,
      sigData,
    );
    if (!cloudinaryRes || legacySignatureErrors) {
      toast.error('Error. Could not upload signature');
      return;
    }
    const { data } = await formService.updateUserMetaData(metadata!.uid, {
      pin,
      signature_id: cloudinaryRes.id,
      signature_url: cloudinaryRes.url,
    });
    setLoading(false);
    if (!data) {
      toast.error('Failed to save profile!');
      return;
    }
    toast.success('Profile successfully updated!');
  };

  const getUserMetadata = async (userUid: string) => {
    const { data } = await formService.getUserMetaData(user.uid);
    if (!data) {
      toast.error('Error. Could not get user metadata');
      return;
    }
    setPin(data.userMetadata.pin.toString());
    setMetadata(data.userMetadata);
  };

  function handlePinInput(e: any) {
    if (
      e.currentTarget.value.match(numberRegex) ||
      e.currentTarget.value === ''
    ) {
      setErrors({});
      setPin(e.currentTarget.value);
    } else {
      setErrors({ pin: 'Pin must only contain numbers.' });
    }
  }

  useEffect(
    () => {
      getUserMetadata(user.uid);
    },
    [user.uid],
  );
  if (!metadata) {
    return <Loading />;
  }
  return (
    <div className={'p-4 bg-white'}>
      <div className="flex flex-row justify-end">
        <Button
          type="success"
          onClick={onProfileSave}
          loading={loading}
          disabled={Object.keys(errors).length !== 0}
        >
          <Save className="w-4 h-4 mr-2" /> Save Profile & Signature
        </Button>
      </div>
      <div className="flex flex-col md:flex-row items-stretch">
        <div className={'flex flex-col justify-between '}>
          <ProfilePicture user={user} />

          <div className={'flex flex-col my-4'}>
            <SignatureCanvas
              canvasRef={canvasRef}
              defaultBg={metadata.signature_url}
            />
          </div>
        </div>
        <div
          className={
            'flex-1 border-0 md:border-l-1 border-solid border-grey md:pl-4 md:ml-4'
          }
        >
          <h3 className={'font-normal'}>General Settings</h3>
          <hr className={'my-4'} />
          <InlineInput
            label={'First Name'}
            icon={<User />}
            disabled={hideForADFS(user)}
            {...firstName}
            errorMessage={errors.fname}
          />
          <InlineInput
            label={'Last Name'}
            icon={<User />}
            disabled={hideForADFS(user)}
            {...lastName}
            errorMessage={errors.lname}
          />
          <InlineInput
            label={'Username'}
            icon={<User />}
            disabled={hideForADFS(user)}
            {...username}
            errorMessage={errors.username}
          />
          <InlineInput
            label={'email'}
            icon={<Envelope />}
            disabled={hideForADFS(user)}
            {...email}
            type="email"
            errorMessage={errors.email}
          />
          <div className="relative">
            <InlineInput
              label={'PIN'}
              icon={<Key />}
              value={pin || undefined}
              onChange={(e: any) => handlePinInput(e)}
              errorMessage={errors.pin}
              type={pinRevealed ? 'text' : 'password'}
              maxLength={4}
            />
            <div
              style={{
                top: 9,
                right: 15,
                position: 'absolute',
              }}
              className="pointer"
              onClick={() => setPinRevealed(!pinRevealed)}
            >
              {pinRevealed ? (
                <Eye className={'w-6 h-6'} />
              ) : (
                <EyeCross className={'w-6 h-6'} />
              )}
            </div>
          </div>
          <div className={hideForADFS(user) ? 'hidden' : ''}>
            <h3 className={'font-normal'}>Change Your Password</h3>
            <hr className={'my-4'} />
            <InlineInput
              label={'Old Password'}
              icon={<Key />}
              {...oldPass}
              errorMessage={errors.oldPassword}
              type={'password'}
            />
            <InlineInput
              label={'New Password'}
              icon={<Key />}
              {...newPass}
              errorMessage={errors.newPassword}
              type={'password'}
            />
            <InlineInput
              label={'New Password Again'}
              icon={<Key />}
              {...newAgain}
              errorMessage={errors.newAgain}
              type={'password'}
            />
            <div className="flex flex-row justify-end">
              <Button
                type={'default'}
                onClick={resetPassword}
                loading={resetting}
              >
                Reset Password
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default connect((state: StoreState) => ({
  user: state.me.user,
}))(Overview);

import * as React from 'react';
import { localStorageKeys } from '../constants';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { AUTH_USER_LOGOUT } from '../constants/actionTypes';
import getAuth0Client from '../auth0';
import { useEffect } from 'react';

interface Props {
  logout: () => any;
}

function Logout(props: Props) {
  useEffect(() => {
    getAuth0Client().then(client => {
      localStorage.removeItem(localStorageKeys.USER_TOKEN);
      localStorage.removeItem(localStorageKeys.REFRESH_TOKEN);
      localStorage.removeItem(localStorageKeys.CURRENT_COMPANY_UID);
      localStorage.removeItem(localStorageKeys.CURRENT_COMPANY_GROUP_UID);
      localStorage.removeItem(localStorageKeys.ORIGINAL_REFRESH_TOKEN);
      localStorage.removeItem(localStorageKeys.ORIGINAL_USER_TOKEN);
      props.logout();
      client.logout({ returnTo: `${process.env.PUBLIC_URL}/login` });
    });
  }, []);

  return null;
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    logout: () => dispatch({ type: AUTH_USER_LOGOUT }),
  };
}

export default connect(
  null,
  mapDispatchToProps,
)(Logout);

import api, { api2 } from './api';

interface CreateParams {
  formUid: string;
  typeId: number;
  parentId?: number;
}

interface UpdateParams {
  text?: string;
  title?: string;
  mandatory?: 0 | 1;
}

const formOptionApi = {
  create: ({ formUid, typeId, parentId }: CreateParams) =>
    api('POST', `forms/${formUid}/options`, { typeId, parentId }),
  delete: (optionId: number) => api('DELETE', `form-options/${optionId}`),
  patch: (optionId: number, params: UpdateParams) =>
    api('patch', `form-options/${optionId}`, params),
  duplicate: (optionId: number) =>
    api('POST', `form-options/${optionId}/duplicate`),
  reorder: (formUid: string, parentId: number, childIds: number[]) =>
    api2('POST', `forms/${formUid}/reorder-options`, {
      parentId,
      childIds,
    }),
};

export default formOptionApi;

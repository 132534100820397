import * as React from 'react';
import styled from 'styled-components';
import { colors, colorTypes, cssVars } from '../../constants';

const Wrapper = styled.footer<Props>`
  display: flex;
  flex-direction: row;
  padding: ${cssVars.padding[5]};
  justify-content: ${props => (props.right ? 'flex-end' : 'flex-start')};
  border-top: 1px solid ${colors[colorTypes.DEFAULT]};
  align-items: center;
`;

interface Props {
  right?: boolean;
}

export default class ModalFooter extends React.Component<Props> {
  public render() {
    return <Wrapper {...this.props}>{this.props.children}</Wrapper>;
  }
}

import styled from 'styled-components';
import { cssVars } from '../constants/index';
import React from 'react';

export const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  border: 1px solid ${cssVars.colors.grey};
  background: white;
`;

export const Td = styled.td`
  padding: ${cssVars.padding[4]};
  border-left: 1px solid ${cssVars.colors.grey};
  border-bottom: 1px solid ${cssVars.colors.grey};

  &:first-of-type {
    border-left: 0;
  }
`;

export const Th = styled.th`
  background: ${cssVars.colors.white};
  font-weight: bold;
  padding: ${cssVars.padding[4]};
  text-align: left;
  border-left: 1px solid ${cssVars.colors['grey-dark']};
  border-bottom: 1px solid ${cssVars.colors['grey-dark']};
  position: relative;
  cursor: pointer;

  &:first-of-type {
    border-left: 0;
  }

  &:hover {
    background: ${cssVars.colors['grey-light']};
  }

  & svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
`;

export const Tr = styled.tr<{ selected?: boolean }>`
  &:hover td {
    background: ${cssVars.colors['grey-lighter']};
    cursor: pointer;
  }

  ${props =>
    props.selected
      ? `
      & td {
        background: ${cssVars.colors['red-lighter']};
      }
      &:hover td {
        background: ${cssVars.colors['red-lightest']};
      }
    `
      : ''};
`;

const SortableTable = ({ data, columns }: { data: any[]; columns: any[] }) => {
  return (
    <Table>
      <thead>
        <tr>
          {columns.map(col => (
            <Th key={col.key}>{col.label}</Th>
          ))}
        </tr>
      </thead>
      <tbody>
        {data.map((item, row) => (
          <Tr key={row}>
            {columns.map(col => (
              <Td key={col.key}>{item[col.key]}</Td>
            ))}
          </Tr>
        ))}
      </tbody>
    </Table>
  );
};

export default SortableTable;

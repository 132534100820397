import * as React from 'react';
import PageToolbar from '../../components/PageToolbar';
import OptionTitle from '../../containers/form/OptionTitle';
import StackedInput from '../../components/StackedInput';
import TextArea from '../../components/TextArea';
import StackedSelect from '../../components/StackedSelect';
import Checkbox from '../../components/Checkbox';
import Radio from '../../components/Radio';

class DocumentDetail extends React.Component {
  public render() {
    return (
      <div>
        <PageToolbar title="" actions={[{ label: 'Submit', type: 'green' }]} />

        <div className="p-6 m-4 mb-2 bg-white">
          <div className="py-4">
            <OptionTitle title="Document name" />
            <p>Lorem ipsum dolor sit amet</p>
          </div>
          <div className="py-4">
            <OptionTitle title="Document description" />
            <p className="mb-2">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua.
            </p>
            <p className="mb-2">
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris
              nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in
              reprehenderit in voluptate velit esse cillum dolore eu fugiat
              nulla pariatur.
            </p>
          </div>
          <div className="py-4">
            <OptionTitle title="Attachments" />
            <p>Lorem ipsum dolor sit amet</p>
          </div>
        </div>

        <div className="p-6 m-4 mt-2 bg-white">
          <OptionTitle title="Questions" />
          <div className="my-4">
            <StackedInput
              label="Lorem ipsum dolor sit amet?"
              placeholder="Enter your answer"
            />
          </div>
          <div className="my-4">
            <label className="mb-2 block">Lorem ipsum dolor sit amet?</label>
            <TextArea />
          </div>
          <div className="my-4">
            <StackedSelect
              label="Lorem ipsum dolor sit amet?"
              placeholder="Select"
              options={[{ label: '1', value: '1' }, { label: '2', value: '2' }]}
            />
          </div>
          <div className="my-4">
            <label className="mb-4 block">Lorem ipsum dolor sit amet?</label>
            <div className="w-1/4 flex mb-2">
              <Checkbox id="sites" />
              <label htmlFor="sites" className={'ml-2'}>
                Sites
              </label>
            </div>
            <div className="w-1/4 flex mb-2">
              <Checkbox id="crews" />
              <label htmlFor="crews" className={'ml-2'}>
                Crews
              </label>
            </div>
          </div>
          <div className="my-4">
            <label className="mb-4 block">Lorem ipsum dolor sit amet?</label>
            <div className="w-1/4 flex mb-2">
              <Radio id="radio-1" label="Radio 1" checked={true} />
            </div>
            <div className="w-1/4 flex mb-2">
              <Radio id="radio-2" label="Radio 2" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DocumentDetail;

import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const Shield = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
    <path
      fill="transparent"
      stroke="#000"
      stroke-width="2px"
      d="M12,1L3,5V11C3,16.55 6.84,21.74 12,23C17.16,21.74 21,16.55 21,11V5L12,1Z"
    />
  </svg>
);

export default BaseSVG(Shield);

import { Moment } from 'moment';
import * as React from 'react';
import Chart from './Chart';
import DataReporting from './DataReporting';
import SubmissionsMap from './Map';
import {
  Company,
  CompanyGroup,
  Crew,
  Form,
  Site,
  User as IUser,
  SubmissionFilters,
} from '../../types';
import { SelectOption, SelectOptionObject } from '../../components/Select';
import Sidebox from '../../components/Sidebox';
import SideboxHeader from '../../components/sidebox/SideboxHeader';
import SubmissionsSidebox from './Sidebox';
import SubmissionsTable from './Table';
import FormSubmissionsTable from './FormSubmissionsTable';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { useMountVisibility } from '../../helpers/hooks';
import companyApi from '../../api/company.api';
import companyGroupApi from '../../api/companyGroup.api';
import EntityFilter, {
  getTypeOptions,
} from '../../components/dateFilterWidget/EntityFilter';
import Loading from '../../components/Loading';
import Download from '../../svg/Download';
import formApi from '../../api/form.api';
import { Dispatch } from 'redux';
import { openDownloads } from '../../actions/ui';
import { connect } from 'react-redux';
import { csvPlans } from '../../constants/index';
import { getFullName } from '../../helpers/index';
import formService from '../../services/forms.service';
import { toast } from 'react-toastify';
import InlineAlert from '../../components/InlineAlert';

interface Props extends WithNamespaces {
  start: Moment;
  end: Moment;
  tab: string;
  company?: Company;
  companyGroup?: CompanyGroup;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
  companies?: Company[];
  queryOverrides?: Partial<SubmissionFilters>;
  openDownloads: () => any;
}

type DateFilterType = 'all' | 'thisMonth' | 'last30Days' | 'today' | 'custom';

type ViewType = 'forms' | 'map' | 'dataReporting';

export interface SelectOptionSubmissionFilters {
  form: SelectOption;
  sites: SelectOptionObject[];
  users: SelectOptionObject[];
  crews: SelectOptionObject[];
  companies: SelectOptionObject[];
  companyGroups: SelectOptionObject[];
  type: SelectOptionObject;
}

interface FormsState {
  uid: string;
  name: string;
  allSubmissions?: number;
  earliest?: number;
  average?: number;
  latest?: number;
  avgRevisions?: number;
  submFrequency?: number;
  formName: string;
  site?: Site;
  deployee?: Site | IUser | Crew;
}

function selectOptionValues(selectionOptions?: SelectOptionObject[]) {
  return (selectionOptions || []).map(({ value }) => value);
}

function formatFilters({
  crews,
  form,
  sites,
  users,
  type,
  start,
  end,
  companies,
  companyGroups,
}: SelectOptionSubmissionFilters & {
  start: Moment;
  end: Moment;
}) {
  const userFilterKey = (() => {
    switch (type.value) {
      case 'signer':
        return 'signerUids';
      case 'submitter':
        return 'submitterUids';
      default:
        return 'userUids';
    }
  })();

  return {
    crewUids: selectOptionValues(crews),
    formUids: form ? [form.value] : [],
    siteUids: selectOptionValues(sites),
    [userFilterKey]: selectOptionValues(users),
    start: start.unix(),
    end: end.unix(),
    companyUids: selectOptionValues(companies),
    companyGroupUids: selectOptionValues(companyGroups),
  };
}

function SubmissionStatisticsMain({
  start,
  end,
  tab,
  t,
  company,
  companyGroup,
  queryOverrides,
  users,
  crews,
  sites,
  forms: allForms,
  companies,
  openDownloads: pOpenDownloads,
}: Props) {
  const [filters, setFilters] = React.useState<SelectOptionSubmissionFilters>({
    users: [],
    sites: [],
    crews: [],
    form: null,
    type: getTypeOptions()[0],
    companies: company ? [{ label: company.name, value: company.uid }] : [],
    companyGroups: companyGroup
      ? [{ label: companyGroup.name, value: companyGroup.uid }]
      : [],
  });
  const [forms, setForms] = React.useState<FormsState[]>([]);
  const sideboxState = useMountVisibility();
  const [formUid, setFormUid] = React.useState<string | null>(null);
  const [formV2, setFormV2] = React.useState<boolean>(false);
  const [deployeeUid, setDeployeeUid] = React.useState<
    string | null | undefined
  >(null);
  const [view, setView] = React.useState<ViewType>('forms');
  const [dateView, setDateView] = React.useState<DateFilterType>('all');
  const [loading, setLoading] = React.useState(false);

  const isCompany = companyGroup ? false : true;
  const parent = companyGroup || company;

  const getGenericStats = async () => {
    if (!parent) {
      return;
    }
    const endpoint = isCompany
      ? companyApi.submissionStats
      : companyGroupApi.submissionStats;
    try {
      const { submissions, errors } = await endpoint(parent.uid, {
        ...formatFilters({ ...filters, start, end }),
        ...queryOverrides,
      });
      if (errors) {
        toast.error('Failed to get submissions for version 1 forms!');
        return;
      }

      const v2FormSubmissions = await formService.getSubmissionsByForm({
        ...formatFilters({ ...filters, start, end }),
        ...queryOverrides,
      });
      if (!v2FormSubmissions.data) {
        toast.error('Failed to get submissions for version 2 forms!');
        return;
      }
      const aggregatedSubmissions = {
        ...v2FormSubmissions.data.submissions,
        ...submissions,
      };

      setForms(
        Object.keys(aggregatedSubmissions).map(formName => ({
          ...aggregatedSubmissions[formName],
          formName,
        })),
      );
    } catch (e) {
      //
    }
  };

  const getFormStats = async () => {
    if (!filters.form || !parent || !isCompany) {
      return;
    }
    const { data: v1Data } = await formApi.submissionStats(
      filters.form.value,
      parent.uid,
      {
        ...formatFilters({ ...filters, start, end }),
        ...queryOverrides,
      },
    );

    if (!v1Data) {
      toast.error('Failed to get stats for v1 forms!');
      return;
    }

    const { data: v2Data } = await formService.getSubmissionsForForm(
      filters.form.value,
      {
        ...formatFilters({ ...filters, start, end }),
        ...queryOverrides,
      },
    );

    if (!v2Data) {
      toast.error('Failed to get stats for v2 forms!');
      return;
    }

    setForms(
      [...v1Data.stats, ...v2Data.stats].map((formStats: any) => ({
        ...formStats,
        ...(filters.form
          ? { formName: filters.form.label, formUid: filters.form.value }
          : {}),
      })),
    );
  };

  const getStats = async () => {
    setLoading(true);

    if (isCompany && filters.form) {
      await getFormStats();
    } else {
      await getGenericStats();
    }

    setLoading(false);
  };

  React.useEffect(() => {
    setFilters({
      ...filters,
      users: [],
      crews: [],
      sites: [],
      ...(!isCompany ? { companies: [] } : {}),
      companies: company ? [{ label: company.name, value: company.uid }] : [],
      companyGroups: companyGroup
        ? [{ label: companyGroup.name, value: companyGroup.uid }]
        : [],
      form: null,
    });
  }, [parent ? parent.uid : '']);

  React.useEffect(() => {
    getStats();
  }, [filters, dateView, queryOverrides, start, end]);

  const getChartKey = (row: any) => {
    if (filters.form && row.deployee) {
      return row.deployee.name || getFullName(row.deployee);
    }
    return row.formName;
  };

  const data = forms.map(x => ({
    ...x,
    key: getChartKey(x),
  }));

  const openSidebox = (formData: {
    formUid: string;
    formV2: boolean;
    deployeeUid?: string;
  }) => {
    setFormUid(formData.formUid);
    setFormV2(formData.formV2);
    setDeployeeUid(formData.deployeeUid);
    sideboxState.open();
  };

  const onFilterChange = (updatedFilters: any) => {
    setFilters({ ...filters, ...updatedFilters });
  };

  const tabs = {
    forms: <Chart submissions={data} onClick={openSidebox} />,
    map:
      typeof google !== 'undefined' ? (
        <SubmissionsMap
          filters={formatFilters({ ...filters, start, end })}
          company={company}
          companyGroup={companyGroup}
          containerElement={<div style={{ height: `300px` }} />}
          mapElement={<div style={{ height: `100%` }} />}
        />
      ) : null,
    dataReporting: (
      <div className="p-8 text-center">
        <InlineAlert
          type={'warning'}
          text={`This feature is currently disabled while we work to improve it. Thanks
          for understanding!`}
        />
      </div>
    ),
    // filters.form ? (
    //   <DataReporting
    //     formattedFilters={formatFilters({ ...filters, start, end })}
    //   />
    // ) : (
    //   <div className="text-center p-8">
    //     <p className={'font-bold'}>Please select a form</p>
    //   </div>
    // ),
  };

  const onExport = async () => {
    if (company && formUid) {
      await formApi.exportSubmissions(
        formUid,
        company.uid,
        formatFilters({ ...filters, start, end }),
      );
      sideboxState.close();
      pOpenDownloads();
    }
  };

  const entities = {
    users,
    forms: allForms,
    sites,
    crews,
    companies,
  };

  const hasCsv = company
    ? +(company.csv_plan || csvPlans.OFF) === csvPlans.ON ||
      (+(company.csv_plan || csvPlans.OFF) === csvPlans.TRIAL &&
        +(company.csv_remaining || 0) > 0)
    : false;
  const headerButtons = hasCsv
    ? [{ Icon: Download, label: 'Export CSV', onClick: onExport }]
    : [];

  return (
    <div>
      <Loading loading={loading} />
      {!queryOverrides && (
        <EntityFilter
          onFilterChange={onFilterChange}
          entities={entities}
          filters={filters}
          filterV2={tab === 'dataReporting'}
        />
      )}

      {forms.length > 0 && tab !== 'dataReporting' ? (
        <h3 className="p-4">
          Total Submissions:{' '}
          {forms.reduce(
            (curr: any, next: any) => curr + (next.allSubmissions || 0),
            0,
          )}
        </h3>
      ) : null}

      {tabs[tab]}

      <div>
        {forms.length > 0 &&
        tab !== 'dataReporting' &&
        (!isCompany || (!filters.form && isCompany)) ? (
          <SubmissionsTable
            forms={forms}
            onRowClick={openSidebox}
            filters={filters}
            formattedFilters={formatFilters({ ...filters, start, end })}
          />
        ) : filters.form ? (
          <FormSubmissionsTable
            forms={forms}
            onRowClick={openSidebox}
            filters={filters}
            formattedFilters={formatFilters({ ...filters, start, end })}
          />
        ) : null}
      </div>
      {sideboxState.mounted && formUid && (
        <Sidebox
          isOpen={sideboxState.visible}
          contentLabel={t('widgets:submissions.formSubmissions')}
          handleClose={sideboxState.close}
        >
          <SideboxHeader
            handleClose={sideboxState.close}
            buttons={headerButtons}
          >
            Submissions
          </SideboxHeader>
          <SubmissionsSidebox
            formUid={formUid}
            formV2={formV2}
            formattedFilters={{
              ...formatFilters({
                ...filters,
                start,
                end,
              }),
              deployeeUids: deployeeUid ? [deployeeUid] : [],
            }}
          />
        </Sidebox>
      )}
    </div>
  );
}

const mapDispatch = (dispatch: Dispatch) => ({
  openDownloads: () => dispatch(openDownloads()),
});

export default withNamespaces()(
  connect(null, mapDispatch)(SubmissionStatisticsMain),
);

import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Form, CompanyGroup } from '../../types';
import moment from 'moment';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import ResourceTable from '../../components/ResourceTable';
import PageHeading from '../../components/PageHeading';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { useEntityRefresh, useMountVisibility } from '../../helpers/hooks';
import { Dispatch } from 'redux';
import { companyGroupFormsFetchRequest } from '../../actions/companyGroup/forms';
import { CompanyGroupFormsState } from '../../reducers/companyGroup/forms';
import FormSidebox from './forms/FormSidebox';
import { companyGroupCompaniesFetchRequest } from '../../actions/companyGroup/companies';
import { CompanyGroupCompanyState } from '../../reducers/companyGroup/companies';
import { getDivisionLabelPlural } from '../../helpers';

interface Props extends WithNamespaces {
  companyGroup: CompanyGroup;
  forms: CompanyGroupFormsState;
  companies: CompanyGroupCompanyState;
  fetchForms: (companyGroupUid: string) => any;
  fetchCompanies: (companyGroupUid: string) => any;
}

const Forms = ({
  companyGroup,
  t,
  forms,
  fetchForms,
  companies,
  fetchCompanies,
}: Props) => {
  const sideboxState = useMountVisibility();
  const [form, setForm] = React.useState<Form | null>(null);

  useEntityRefresh(companyGroup.uid, forms, fetchForms);
  useEntityRefresh(companyGroup.uid, companies, fetchCompanies);

  const selectForm = (f: Form) => {
    setForm(f);
    sideboxState.open();
  };

  const tableColumns = [
    {
      Header: t('super:name'),
      accessor: 'name',
    },
    {
      Header: t('super:created'),
      accessor: (p: Form) => moment(p.created_at * 1000).format('YYYY-MM-DD'),
      id: 'created_at',
    },
  ];

  return (
    <Page>
      <PageContent>
        <PageHeading
          title={`All ${getDivisionLabelPlural()} Forms`}
          subtitle={`${forms.data.length} available`}
        />

        <ResourceTable
          data={forms.data}
          columns={tableColumns}
          defaultSortKey={'name'}
          onRowClick={selectForm}
        />

        {sideboxState.mounted && (
          <FormSidebox
            isOpen={sideboxState.visible}
            handleClose={sideboxState.close}
            form={form!}
            companies={companies.data}
          />
        )}
      </PageContent>
    </Page>
  );
};

const mapStateToProps = (state: StoreState) => ({
  companyGroup: state.me.companyGroup,
  forms: state.companyGroup.forms,
  companies: state.companyGroup.companies,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchForms: (companyGroupUid: string) =>
    dispatch(companyGroupFormsFetchRequest(companyGroupUid)),
  fetchCompanies: (companyGroupUid: string) =>
    dispatch(companyGroupCompaniesFetchRequest(companyGroupUid)),
});

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Forms),
);

import * as React from 'react';
import Modal from '../components/Modal';
import ModalHeader from '../components/modal/ModalHeader';
import ModalBody from '../components/modal/ModalBody';
import InlineInput from '../components/InlineInput';
import Envelope from '../svg/Envelope';
import LockClosed from '../svg/LockClosed';
import InlineAlert from '../components/InlineAlert';
import ModalFooter from '../components/modal/ModalFooter';
import Button from '../components/Button';
import { connect } from 'react-redux';
import { StoreState } from '../store';
import { User } from '../types';
import { useTextInput } from '../helpers/hooks';
import authService from '../services/auth.service';
import meApi from '../api/me.api';
import { Dispatch } from 'redux';
import { setUser } from '../actions/me';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  user: User;
  setAuthUser: (user: User) => void;
}

enum FormState {
  SHOW_EMAIL = 'show-email',
  SHOW_VERIFICATION = 'show-verification',
}

interface Errors {
  password?: string;
  email?: string;
}

function getInitialStatus(user?: User) {
  if (user && !user.email) {
    return FormState.SHOW_EMAIL;
  }
  if (user && user.email && user.email_verification === 'pending') {
    return FormState.SHOW_VERIFICATION;
  } else if (user && user.email && user.email_verification === 'success') {
    return null;
  } else {
    return FormState.SHOW_EMAIL;
  }
}

function EmailChecker({ isOpen, handleClose, user, setAuthUser }: Props) {
  const email = useTextInput(user && user.email);
  const password = useTextInput();
  const [status, setStatus] = React.useState(getInitialStatus(user));
  const fetching = React.useRef(false);
  const [errors, setErrors] = React.useState<Errors>({});
  const [loading, setLoading] = React.useState(false);
  const [resending, setResending] = React.useState(false);

  async function submit() {
    setLoading(true);
    const { errors: err } = await authService.forceEmailCheck(
      email.value,
      password.value,
    );
    setLoading(false);
    if (err) {
      setErrors(err);
    }
    if (err === undefined) {
      setStatus(FormState.SHOW_VERIFICATION);
    }
  }

  async function checkVerifyStatus() {
    const { data } = await meApi.me();
    if (data && data.user.email && data.user.email_verification === 'success') {
      setAuthUser(data.user);
      setStatus(null);
      handleClose();
    }
  }

  async function resendVerification() {
    setResending(true);
    const { data } = await authService.resendVerification();
    if (data) {
      toast.success('Verification email sent');
    } else {
      toast.error('Verification emails can only be sent once every 5 minutes');
    }
    setResending(false);
  }

  React.useEffect(
    () => {
      if (status !== FormState.SHOW_VERIFICATION) {
        return;
      }
      const interval = setInterval(async () => {
        if (!fetching.current) {
          fetching.current = true;
          await checkVerifyStatus();
          fetching.current = false;
        }
      }, 5000);
      return () => clearInterval(interval);
    },
    [status],
  );

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel={'Verify Email'}
    >
      <ModalHeader handleClose={handleClose}>Verify Email</ModalHeader>
      <ModalBody>
        {status === FormState.SHOW_VERIFICATION && (
          <div className={'text-center'}>
            <h3 className={'mb-4'}>
              Please click the link sent in your email to verify your account.
            </h3>
            <p>This popup will dismiss automatically once verified.</p>
            <div className="mt-4">
              <Button
                type={'default'}
                className={'mr-4'}
                onClick={() => setStatus(FormState.SHOW_EMAIL)}
              >
                Change your email
              </Button>
              <Button
                type={'primary'}
                onClick={resendVerification}
                loading={resending}
              >
                Resend verification email
              </Button>
            </div>
          </div>
        )}
        {status === FormState.SHOW_EMAIL && (
          <>
            <InlineAlert
              type={'warning'}
              text={
                'SafetyTek will require ALL user accounts to log in with an Email starting the second week of March. Please enter yours now to avoid any service disruptions.'
              }
            />
            <InlineInput
              label={'Email'}
              icon={<Envelope />}
              errorMessage={errors.email}
              {...email}
            />
            <label htmlFor="password" className="block mb-2">
              Please enter your password in order to verify this change
            </label>
            <InlineInput
              label={'SafetyTek Password'}
              type={'password'}
              id={'password'}
              icon={<LockClosed />}
              {...password}
              errorMessage={errors.password}
            />
          </>
        )}
      </ModalBody>
      {status === FormState.SHOW_EMAIL && (
        <ModalFooter right={true}>
          <Button onClick={submit} loading={loading}>
            Save
          </Button>
        </ModalFooter>
      )}
    </Modal>
  );
}

export default connect(
  (state: StoreState) => ({
    user: state.me.user,
  }),
  (dispatch: Dispatch) => ({
    setAuthUser: (user: User) => dispatch(setUser(user)),
  }),
)(EmailChecker);

import { api2 } from './api';
import { User, Company, CompanyGroup, AuthPermissions } from '../types';

export interface LocationResponse {
  location: {
    lat: number;
    lng: number;
    address: string;
    country_code: string;
    city: string;
  };
  weather: {
    temp: number;
    main: string;
    description: string;
    icon: string;
    icon_class: string;
    unit: string;
  };
}

const meApi = {
  me: () => api2<{ user: User }>('GET', 'me'),
  permissions: () =>
    api2<{ permissions: AuthPermissions }>('GET', 'permissions'),
  companies: () => api2<{ companies: Company[] }>('GET', 'me/companies'),
  companyGroups: () =>
    api2<{ companyGroups: CompanyGroup[] }>('GET', 'me/company-groups'),
  location: (companyUid: string, latlng: { lat: number; lng: number }) =>
    api2<{ location: LocationResponse }>(
      'GET',
      `companies/${companyUid}/location`,
      latlng,
    ),
};

export default meApi;

import * as React from 'react';
import Button from '../../../components/Button';
import { Certificate } from '../../../types';
import SaveRemoveInput from './SaveRemoveInput';
import { Dispatch } from 'redux';
import {
  addCertificate,
  removeCertificate,
  updateCertificate,
} from '../../../actions/certificates';
import { connect } from 'react-redux';
import ConfirmationDialogue from '../../../components/ConfirmationDialogue';
import certificateApi from '../../../api/certificate.api';
import companyApi from '../../../api/company.api';
import { toast } from 'react-toastify';

interface Props {
  certificates: Certificate[];
  updateCertificate: (cert: Certificate) => any;
  removeCertificate: (certUid: string) => any;
  addCertificate: (cert: Certificate) => any;
  companyUid: string;
}

interface State {
  certificateUidToDelete: string | null;
  deleting: boolean;
  certificateName: string;
  adding: boolean;
}

class Certificates extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      certificateUidToDelete: null,
      deleting: false,
      certificateName: '',
      adding: false,
    };
  }

  private onCertificateNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      certificateName: e.currentTarget.value,
    });
  };

  private onCertificateAdd = async () => {
    this.setState({
      adding: true,
    });

    try {
      const { certificate } = await companyApi.createCertificate(
        this.props.companyUid,
        this.state.certificateName,
      );

      this.props.addCertificate(certificate);
      this.setState({
        certificateName: '',
      });
    } catch (e) {
      toast.success('Added certificate');
    }

    this.setState({
      adding: false,
    });
  };

  private onSave = async (cert: Certificate, name: string) => {
    try {
      const { certificate } = await certificateApi.update(cert.uid, { name });
      this.props.updateCertificate(certificate);
      toast.success('Saved certificate');
    } catch (e) {
      //
    }
  };

  private onDeleteClicked = (cert: Certificate) => {
    this.setState({
      certificateUidToDelete: cert.uid,
    });
  };

  private closeDeleteModal = () => {
    this.setState({
      certificateUidToDelete: null,
    });
  };

  private deleteCertificate = async () => {
    if (!this.state.certificateUidToDelete) {
      return;
    }

    this.setState({
      deleting: true,
    });

    try {
      await certificateApi.delete(this.state.certificateUidToDelete);
    } catch (e) {
      //
    }

    this.props.removeCertificate(this.state.certificateUidToDelete);

    this.setState({
      certificateUidToDelete: null,
      deleting: false,
    });
  };

  public render() {
    const { certificates } = this.props;
    const {
      certificateUidToDelete,
      deleting,
      certificateName,
      adding,
    } = this.state;

    return (
      <div>
        <div className="p-4 flex flex-col bg-grey-lighter">
          <label className={'mb-2 block'}>Add new Certificate</label>
          <div className={'flex flex-row'}>
            <input
              type="text"
              className={'w-full p-2'}
              placeholder={'Certificate name'}
              value={certificateName}
              onChange={this.onCertificateNameChange}
            />
            <Button
              type={'primary'}
              loading={adding}
              onClick={this.onCertificateAdd}
            >
              Add
            </Button>
          </div>
        </div>

        <ul className="bg-white list-reset">
          {certificates.map(cert => (
            <SaveRemoveInput
              key={cert.uid}
              value={cert.name}
              onSave={(value: string) => this.onSave(cert, value)}
              onRemove={() => this.onDeleteClicked(cert)}
            />
          ))}
        </ul>

        <ConfirmationDialogue
          title={'Delete Certificate?'}
          body={'Delete this certificate?'}
          contentLabel={'Delete Certificate?'}
          handleClose={this.closeDeleteModal}
          onConfirm={this.deleteCertificate}
          isOpen={!!certificateUidToDelete}
          loading={deleting}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateCertificate: (cert: Certificate) => dispatch(updateCertificate(cert)),
  removeCertificate: (certUid: string) => dispatch(removeCertificate(certUid)),
  addCertificate: (cert: Certificate) => dispatch(addCertificate(cert)),
});

export default connect(
  null,
  mapDispatchToProps,
)(Certificates);

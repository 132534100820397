import * as siteCrewActions from '../../actions/super/siteCrews';
import { ActionType, getType } from 'typesafe-actions';
import { Crew } from '../../types';

export type SuperSiteCrews = ActionType<typeof siteCrewActions>;

export type SuperSiteCrewState = Crew[];

const DEFAULT_STATE: SuperSiteCrewState = [];

export default function superSiteCrewsReducer(
  state: SuperSiteCrewState = DEFAULT_STATE,
  action: SuperSiteCrews,
) {
  switch (action.type) {
    case getType(siteCrewActions.setSiteCrews):
      return [...action.payload];
    case getType(siteCrewActions.addSiteCrew):
      return [...state, { ...action.payload }];
    case getType(siteCrewActions.removeSiteCrew):
      return state.filter(crew => crew.uid !== action.payload);
    default:
      return state;
  }
}

import * as React from 'react';
import InlineSelect from '../../../components/InlineSelect';
import UserIcon from '../../../svg/User';
import Location from '../../../svg/Location';
import { Company, Crew, Site, User } from '../../../types';
import { SelectOptionObject } from '../../../components/Select';
import TrainingMatrixComp from '../../../containers/TrainingMatrix';
import UserGroup from '../../../svg/UserGroup';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Dispatch } from 'redux';
import { roleCertificateMatrixFetchRequest } from '../../../actions/roleCertificateMatrix';
import { RoleCertificateMatrixState } from '../../../reducers/roleCertificateMatrix';
import { WorkRolesState } from '../../../reducers/workRoles';
import { CertificatesState } from '../../../reducers/certificates';
import { workRolesFetchRequest } from '../../../actions/workRoles';
import { certificatesFetchRequest } from '../../../actions/certificates';
import Loading from '../../../components/Loading';
import {
  useRequiredSelectInput,
  useOptionalSelectInput,
} from '../../../helpers/hooks';
import { getSiteLabel, getCrewLabel } from '../../../helpers';
import InlineAlert from '../../../components/InlineAlert';

interface Props {
  sites: Site[];
  crews: Crew[];
  users: User[];
  company: Company;
  user: User;
  roleCertificateMatrix: RoleCertificateMatrixState;
  workRoles: WorkRolesState;
  getWorkRoles: (companyUid: string) => any;
  certificates: CertificatesState;
  getCertificates: (companyUid: string) => any;
  getRoleCertificateMatrix: (companyUid: string) => any;
}

const TrainingMatrix = ({
  company,
  crews,
  users,
  sites,
  roleCertificateMatrix,
  certificates,
  getRoleCertificateMatrix,
}: Props) => {
  const getViewOptions = () => [
    { label: `${getSiteLabel()}`, value: 'site' },
    { label: `${getCrewLabel()}`, value: 'crew' },
    { label: 'User', value: 'user' },
  ];

  const view = useRequiredSelectInput(getViewOptions()[0]);
  const entity = useOptionalSelectInput();

  React.useEffect(() => {
    entity.onChange(null);
  }, [company.uid]);

  const getSiteOptions = () =>
    sites.map(s => ({
      label: s.name,
      value: s.uid,
    }));

  const getCrewOptions = () =>
    crews.map(c => ({
      label: c.name,
      value: c.uid,
    }));

  const getUserOptions = () =>
    users.map(u => ({
      label: u.username,
      value: u.uid,
    }));

  const onViewChange = (v: SelectOptionObject) => {
    view.onChange(v);
    entity.onChange(null);
  };

  const loading = () => certificates.loading || roleCertificateMatrix.loading;

  return (
    <div className={'relative'}>
      <Loading loading={loading()} />
      <div className="bg-white p-4 mb-4">
        <div className="flex flex-col md:flex-row">
          <div className="flex-1">
            <InlineSelect
              options={getViewOptions()}
              label={'View'}
              icon={<UserIcon />}
              value={view.value}
              onChange={onViewChange}
            />
          </div>
          <div className="flex-1 md:ml-4">
            {view.selected === 'site' && (
              <InlineSelect
                options={getSiteOptions()}
                label={getSiteLabel()}
                icon={<Location />}
                {...entity}
              />
            )}
            {view.selected === 'crew' && (
              <InlineSelect
                options={getCrewOptions()}
                label={getCrewLabel()}
                icon={<UserGroup />}
                {...entity}
              />
            )}
            {view.selected === 'user' && (
              <InlineSelect
                options={getUserOptions()}
                label={'Users'}
                icon={<UserIcon />}
                {...entity}
              />
            )}
          </div>
        </div>
      </div>
      {entity.selected && (
        <div className="bg-white p-4 relative">
          <TrainingMatrixComp
            companyUid={company.uid}
            view={view.selected as any}
            entityUid={entity.selected!}
          />
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  roleCertificateMatrix: state.roleCertificateMatrix,
  workRoles: state.workRoles,
  certificates: state.certificates,
  user: state.me.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getRoleCertificateMatrix: (companyUid: string) =>
    dispatch(roleCertificateMatrixFetchRequest(companyUid)),
  getWorkRoles: (companyUid: string) =>
    dispatch(workRolesFetchRequest(companyUid)),
  getCertificates: (companyUid: string) =>
    dispatch(certificatesFetchRequest(companyUid)),
});

export const _TrainingMatrix = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrainingMatrix);

export default function TrainingMatrixTemporarilyDisabled(_: any) {
  return (
    <div className="p-8 text-center">
      <InlineAlert
        type={'warning'}
        text={`This feature is currently disabled while we work to improve it. Thanks
    for understanding!`}
      />
    </div>
  );
}

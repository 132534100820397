import { createAction } from 'typesafe-actions';
import { FormOption } from '../types';
import {
  FORM_OPTIONS_ADD,
  FORM_OPTIONS_REMOVE,
  FORM_OPTIONS_SET,
  FORM_OPTIONS_UPDATE,
  FORM_OPTIONS_MOVE,
  FORM_OPTIONS_REPLACE,
  FORM_OPTIONS_DRAG_F_ID,
} from '../constants/actionTypes';

export const setFormOptions = createAction(
  FORM_OPTIONS_SET,
  resolve => (options: FormOption[]) => resolve(options),
);

export const addFormOption = createAction(
  FORM_OPTIONS_ADD,
  resolve => (option: FormOption) => resolve(option),
);

export const removeFormOption = createAction(
  FORM_OPTIONS_REMOVE,
  resolve => (optionId: number) => resolve(optionId),
);

export const updateFormOption = createAction(
  FORM_OPTIONS_UPDATE,
  resolve => (option: FormOption) => resolve(option),
);

export const moveFormOption = createAction(
  FORM_OPTIONS_MOVE,
  resolve => (data: { destination: number; source: number; index: number }) =>
    resolve(data),
);

export const replaceFormOptions = createAction(
  FORM_OPTIONS_REPLACE,
  resolve => (data: {
    byId: { [key: string]: FormOption };
    allIds: string[];
  }) => resolve(data),
);

export const setDragFid = createAction(
  FORM_OPTIONS_DRAG_F_ID,
  resolve => (fId: number | null) => resolve(fId),
);

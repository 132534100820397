import * as React from 'react';
import { IncidentProp, IncidentPropOption } from '../types';
import InlineSelect from '../components/InlineSelect';
import Cog from '../svg/Cog';
import InlineInput from '../components/InlineInput';

interface Props {
  onPropValuesChange: (propValues: any) => void;
  onOtherValuesChange: (otherValues: any) => void;
  props: IncidentProp[];
  propOptions: IncidentPropOption[];
  propValues: any;
  otherValues: any;
  disabled?: boolean;
}

const IncidentProps = ({
  props,
  propOptions,
  propValues,
  otherValues,
  disabled,
  onPropValuesChange,
  onOtherValuesChange,
}: Props) => {
  const getPropOptions = (id: any) =>
    propOptions
      .filter(i => i.incident_prop_id === id)
      .map(i => ({ label: i.name, value: i.slug }));

  return (
    <div className="flex flex-col">
      {props.map(i => (
        <div className="flex-1" key={i.id}>
          {i.multiple ? (
            <>
              <InlineSelect
                icon={<Cog />}
                options={getPropOptions(i.id)}
                label={i.name}
                disabled={disabled}
                isMulti={true}
                value={propValues[i.id]}
                onChange={arr =>
                  onPropValuesChange({
                    ...propValues,
                    [i.id]: arr,
                  })
                }
              />
              {propValues[i.id] &&
                propValues[i.id].map((x: any) => x.value).indexOf('other') !==
                  -1 && (
                  <InlineInput
                    icon={<Cog />}
                    label={`${i.name} Other`}
                    disabled={disabled}
                    value={otherValues[i.id]}
                    onChange={e =>
                      onOtherValuesChange({
                        ...otherValues,
                        [i.id]: e.currentTarget.value,
                      })
                    }
                  />
                )}
            </>
          ) : (
            <InlineInput
              icon={<Cog />}
              label={i.name}
              disabled={disabled}
              value={propValues[i.id]}
              onChange={e =>
                onPropValuesChange({
                  ...propValues,
                  [i.id]: e.currentTarget.value,
                })
              }
            />
          )}
        </div>
      ))}
    </div>
  );
};

export default IncidentProps;

import * as React from 'react';
import Checkbox from '../../../components/Checkbox';
import Button from '../../../components/Button';
import { NotificationPreferences, User } from '../../../types';
import notificationsService from '../../../services/notifications.service';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { useState, useEffect } from 'react';

function Notifications({ user }: { user: User }) {
  const [prefs, setPrefs] = useState<NotificationPreferences | null>(null);
  const [loading, setLoading] = useState(false);

  async function getPrefs() {
    setLoading(true);
    const { data } = await notificationsService.getNotifPrefs(user.uid);
    if (data) {
      setPrefs(data.notificationPreferences);
    }
    setLoading(false);
  }

  async function savePrefs() {
    if (!prefs) {
      return;
    }
    setLoading(true);
    const newPrefs = { ...prefs };
    delete newPrefs.uid;
    const { data } = await notificationsService.updateUserNotifPrefs(
      prefs.uid,
      newPrefs,
    );
    if (data) {
      toast.success('Updated preferences');
    }
    setLoading(false);
  }

  useEffect(() => {
    getPrefs();
  }, [user.uid]);

  const prefList = [
    {
      label: 'Form Reminders',
      id: 'form_reminders',
    },
    {
      label: 'New Submissions',
      id: 'new_submission',
    },
    {
      label: 'Messages',
      id: 'messages',
    },
    {
      label: 'Crew Invites',
      id: 'crew_invites',
    },
    {
      label: 'Actions',
      id: 'actions',
    },
    {
      label: 'Checklists',
      id: 'checklists',
    },
    {
      label: 'Documents',
      id: 'document',
    },
  ];

  if (!prefs) {
    return <Loading loading={true} />;
  }

  return (
    <div>
      <h2 className="mb-2">Notification Preferences</h2>
      <div className="bg-white p-4">
        <table className="mb-2">
          <thead>
            <tr>
              <th />
              <th className="p-2">Email</th>
              <th className="p-2">Push</th>
            </tr>
          </thead>
          <tbody>
            {prefList.map((x) => (
              <tr key={x.id}>
                <td className="p-2">{x.label}</td>
                <td className="p-2">
                  <Checkbox
                    id={`${x.id}_email`}
                    checked={!!prefs[`${x.id}_email`]}
                    onChange={() =>
                      setPrefs({
                        ...prefs,
                        [`${x.id}_email`]: !prefs[`${x.id}_email`],
                      })
                    }
                  />
                </td>
                <td className="p-2">
                  <Checkbox
                    id={`${x.id}_push`}
                    checked={!!prefs[`${x.id}_push`]}
                    onChange={() =>
                      setPrefs({
                        ...prefs,
                        [`${x.id}_push`]: !prefs[`${x.id}_push`],
                      })
                    }
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <Button type="success" onClick={savePrefs} loading={loading}>
          Save
        </Button>
      </div>
    </div>
  );
}

export default connect((state: StoreState) => ({
  user: state.me.user,
}))(Notifications);

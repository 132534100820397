import * as React from 'react';
import { connect } from 'react-redux';
import { CompanyGroup } from '../../types';
import { StoreState } from '../../store';
import PageContent from '../../components/PageContent';
import Page from '../../components/Page';
import { Dispatch } from 'redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { CompanyGroupUsersState } from '../../reducers/companyGroup/users';
import { companyGroupUsersFetchRequest } from '../../actions/companyGroup/users';
import Tabs from '../../components/Tabs';
import AllUsers from './users/AllUsers';
import Admins from './users/Admins';

interface Props extends WithNamespaces {
  companyGroup: CompanyGroup;
  getUsers: (companyGroupUid: string) => any;
  users: CompanyGroupUsersState;
}

const Users = ({ companyGroup, getUsers, users, t }: Props) => {
  return (
    <Page>
      <PageContent>
        <Tabs
          raised={true}
          tabs={[
            { label: 'Users', component: <AllUsers /> },
            { label: 'Admins', component: <Admins /> },
          ]}
        />
      </PageContent>
    </Page>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    companyGroup: state.me.companyGroup,
    users: state.companyGroup.users,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUsers: (companyGroupUid: string) =>
      dispatch(companyGroupUsersFetchRequest(companyGroupUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Users),
);

import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import StackedInput from '../../components/StackedInput';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { useParsedSubmDataLocal } from '../../helpers/hooks';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  live?: boolean;
  submData?: ParsedSubmData;
  editable?: boolean;
  submUid?: string;
}

const QuestionAnswerItem = ({
  option,
  t,
  live,
  submData,
  editable,
  submUid,
}: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live });

  const [value, setValue] = useParsedSubmDataLocal({
    submData,
    option,
    initial: '',
    submUid: submUid!,
  });

  return (
    <StackedInput
      id={`option-${option.id}`}
      label={title}
      placeholder={t('forms:emptyQuestionInputPlaceholder')}
      disabled={!editable}
      value={value}
      onChange={e => setValue(e.currentTarget.value)}
    />
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    editable: state.form.editable,
    submUid: state.form.submissionUid,
  });
}

export default withNamespaces()(
  connect(makeMapStateToProps)(QuestionAnswerItem),
);

import * as React from 'react';
import formApi from '../../api/form.api';
import styled from 'styled-components';
import { cssVars } from '../../constants';
import Loading from '../../components/Loading';
import formService from '../../services/forms.service';
import SubmissionList from './SubmissionsList';
import { Submissions } from '../../types';
import { toast } from 'react-toastify';

interface Props {
  formUid: string;
  formattedFilters: any;
  formV2: boolean;
}

export const Item = styled.a`
  display: block;
  color: inherit;
  text-decoration: none;
  background: white;
  padding: ${cssVars.padding[8]};

  &:nth-child(odd) {
    background: ${cssVars.colors['grey-lighter']};
  }

  &:hover {
    background: ${cssVars.colors['grey-dark']};
  }
`;

const SubmissionsSidebox = ({ formUid, formV2, formattedFilters }: Props) => {
  const [formSubmissions, setSubmissions] = React.useState<Submissions>([]);
  const [loading, setLoading] = React.useState(false);

  const getSubmissions = async () => {
    setLoading(true);
    try {
      if (formV2) {
        const { data } = await formService.getSubmissions({
          ...formattedFilters,
          formUids: [...(formattedFilters.formUids || []), formUid],
        });
        setSubmissions((data && data.submissions) || []);
      } else {
        const res = await formApi.submissions(formUid, formattedFilters);
        setSubmissions(res.submissions);
      }
    } catch (e) {
      toast.error('Could not fetch submissions!');
    }
    setLoading(false);
  };

  React.useEffect(() => {
    getSubmissions();
  }, []);

  return (
    <div className={'relative'}>
      <Loading loading={loading} />
      {formSubmissions && <SubmissionList submissions={formSubmissions} />}
    </div>
  );
};

export default SubmissionsSidebox;

import * as React from 'react';
import Toggle from 'react-toggle';

const DEFAULT_CLASSNAME =
  'flex flex-row justify-between items-center py-4 px-2 my-4 border-0 border-b-1 border-t-1 border-solid border-grey';

interface Props {
  label: string;
  checked?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  className?: string;
}

const InlineToggle = ({
  label,
  checked,
  onChange,
  className = DEFAULT_CLASSNAME,
}: Props) => {
  return (
    <div className={className}>
      <label className="mr-4">{label}</label>
      <Toggle icons={false} checked={checked} onChange={onChange} />
    </div>
  );
};

export default InlineToggle;

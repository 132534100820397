import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import InlineInput from '../../../components/InlineInput';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import File from '../../../svg/File';
import Cog from '../../../svg/Cog';
import { Form } from '../../../types';
import { ChromePicker, ColorResult } from 'react-color';
import formApi from '../../../api/form.api';
import InlineAlert from '../../../components/InlineAlert';
import { toast } from 'react-toastify';
import InlineSelect from '../../../components/InlineSelect';
import { SelectOptionObject } from '../../../components/Select';

interface Props extends WithNamespaces {
  isOpen: boolean;
  contentLabel: string;
  handleClose: () => void;
  title: string;
  form: Form;
  onUpdate: (form: Form) => void;
}

interface State {
  name: string;
  type: SelectOptionObject;
  color: string;
  loading: boolean;
  errors: any;
}

const DEFAULT_COLOR = '#e66161';

class FormEdit extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      name: '',
      type: {
        value: '',
        label: '',
      },
      color: '',
      loading: false,
      errors: {},
    };
  }

  public componentDidMount() {
    const { form } = this.props;
    const { name, color, type } = form;
    const currentType = this.getTypeOptions().find(
      option => option.value === type,
    );
    this.setState({
      name,
      color,
      type: currentType!,
    });
  }

  private getTypeOptions = () => [
    { label: this.props.t('forms:general'), value: 'general' },
    { label: this.props.t('forms:hazard'), value: 'hazard' },
    { label: this.props.t('forms:inspection'), value: 'inspection' },
    { label: this.props.t('forms:audit'), value: 'audit' },
    { label: this.props.t('forms:incident'), value: 'incident' },
  ];

  private onColorChange = (color: ColorResult) => {
    this.setState({
      color: color.hex,
    });
  };

  private onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      name: e.currentTarget.value,
    });
  };

  private onTypeChange = (type: SelectOptionObject) => {
    this.setState({
      type,
    });
  };

  private onSubmit = async () => {
    this.setState({
      loading: true,
    });

    const { data, errors } = await formApi.update(this.props.form.uid, {
      name: this.state.name,
      color: this.state.color || DEFAULT_COLOR,
      type: this.state.type.value,
    });
    if (data) {
      toast.success('Form updated');
      this.setState({
        errors: {},
      });
      this.props.onUpdate(data.form);
    }
    if (errors) {
      this.setState({
        errors,
      });
    }

    this.setState({
      loading: false,
    });
  };

  public render() {
    const { isOpen, contentLabel, handleClose, title, t } = this.props;
    const { name, color, errors, loading, type } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={contentLabel}
        handleClose={handleClose}
      >
        <ModalHeader handleClose={handleClose}>{title}</ModalHeader>
        <ModalBody>
          <form onSubmit={this.onSubmit}>
            <InlineInput
              label="Name"
              icon={<File />}
              value={name}
              errorMessage={errors.name}
              onChange={this.onNameChange}
            />
            <InlineSelect
              label="Type"
              icon={<Cog />}
              value={type}
              options={this.getTypeOptions()}
              errorMessage={errors.name}
              onChange={this.onTypeChange}
            />
            {errors.color && <InlineAlert type="danger" text={errors.color} />}
            <div className="flex justify-center">
              <ChromePicker
                disableAlpha={true}
                color={color}
                onChangeComplete={this.onColorChange}
              />
            </div>
          </form>
        </ModalBody>
        <ModalFooter right={true}>
          <Button type={'primary'} onClick={this.onSubmit} loading={loading}>
            {t('common:save')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withNamespaces()(FormEdit);

import { api2 } from './api';
import {
  Conversation,
  Message,
  User,
  Crew,
  Site,
  Download,
  FC,
  Form,
  Feed,
} from '../types';

export interface CompanyUserConversation {
  conversation: Conversation;
  participants: Array<User | Crew | Site> | null;
  latest_message: Message | null;
  sender: User;
  unread_messages: number;
}

export interface FavouriteFormResponse {
  fc: FC;
  form: Form;
  site: Site;
}

const companyUserApi = {
  sites: (companyUid: string, userUid: string) =>
    api2<{ sites: Site[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/sites`,
    ),
  download: (companyUid: string, userUid: string, storageUids: string[]) =>
    api2<{ download: Download; jobId: string }>(
      'POST',
      `companies/${companyUid}/users/${userUid}/downloads`,
      { storageUids },
    ),
  favouriteForms: (companyUid: string, userUid: string) =>
    api2<{ favouriteForms: FavouriteFormResponse[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/favourite-forms`,
    ),
  newsfeed: (companyUid: string, userUid: string) =>
    api2<{ feed: Feed[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/feed`,
    ),
  managedCrews: (companyUid: string, userUid: string) =>
    api2<{ crews: Crew[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/managed-crews`,
    ),
  managedSites: (companyUid: string, userUid: string) =>
    api2<{ sites: Site[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/managed-sites`,
    ),
  suspend: (companyUid: string, userUid: string) =>
    api2('POST', `companies/${companyUid}/users/${userUid}/suspend`),
  unsuspend: (companyUid: string, userUid: string) =>
    api2('POST', `companies/${companyUid}/users/${userUid}/unsuspend`),
  updateRole: (companyUid: string, userUid: string, role: string) =>
    api2('PUT', `companies/${companyUid}/users/${userUid}/role`, { role }),
};

export default companyUserApi;

import styled from 'styled-components';
import { semanticColors } from '../constants';

const Label = styled.label`
  font-weight: 600;
  display: block;
  color: ${semanticColors.LABEL_COLOR};
  margin-bottom: 5px;
`;

export default Label;

import * as React from 'react';
import Suitcase from '../../../svg/Suitcase';
import Location from '../../../svg/Location';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import formService from '../../../services/forms.service';
import styled from 'styled-components';
import { getFullName } from '../../../helpers';
import User from '../../../svg/User';
import Compass from '../../../svg/Compass';
import Clock from '../../../svg/Clock';
import moment from 'moment';
import Cloud from '../../../svg/Cloud';
import { Geostamp, User as IUser, InstanceMeta } from '../../../types';
import temperatureHelper from '../../../helpers/temperature.helper';
import { cssVars } from '../../../constants';
import SignOffs from './SignOffs';
import { Link } from 'react-router-dom';

const MetaActions = styled.button<{ type?: string }>`
  border: none;
  background: ${props => (props.type ? cssVars.colors[props.type] : 'white')};
  color: ${props => (props.type ? 'white' : 'black')};
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  &: hover {
    background: grey;
    color: white;
  }
`;

const GeostampItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  width: 100%;
  padding: 1rem;
  border-radius: 0.25rem;
  cursor: pointer;
`;

interface Props {
  editable: boolean;
  loading: boolean;
  signOffs: any;
  stickySidebar: any;
  actions: any[];
  stickySidebarWidth: any;
  instanceMeta: InstanceMeta;
  gettingSignOffs: any;
}

function FormMeta({
  signOffs,
  stickySidebar,
  actions,
  stickySidebarWidth,
  instanceMeta,
  gettingSignOffs,
}: Props) {
  const [geostamps, setGeostamps] = useState<
    Array<{ geostamp: Geostamp; user: IUser }>
  >([]);
  async function getLocationInfo() {
    if (!instanceMeta.form_revision.uid) {
      return;
    }
    const { data } = await formService.getGeostamps(
      instanceMeta.instance_revision.uid,
    );
    if (!data) {
      toast.error('Failed to get location info!');
      return;
    }
    setGeostamps(data.geostamps);
  }

  useEffect(() => {
    getLocationInfo();
  }, []);

  return (
    <div
      className="w-full lg:w-1/4 flex flex-col lg:pl-0 lg:pl-4"
      id="sidebarContainer"
    >
      <div
        className={
          stickySidebar ? 'lg:fixed rounded w-full' : 'bg-white rounded w-full'
        }
        style={stickySidebar ? { top: 110, width: stickySidebarWidth } : {}}
      >
        {stickySidebar && (
          <div className="hidden lg:flex flex-row w-full p-2 bg-white rounded mb-4">
            {actions.map(
              ({ label, icon, type, onClick, disabled, href, external }, i) => (
                <div className="p-2 w-1/4">
                  <MetaActions
                    key={i}
                    type={type}
                    onClick={onClick}
                    disabled={disabled}
                    className="w-full rounded"
                    title={label || ''}
                    {...(href
                      ? {
                          as: external ? 'a' : Link,
                          to: external ? undefined : href,
                          href,
                          target: external ? '_blank' : undefined,
                        }
                      : {})}
                  >
                    {icon}
                  </MetaActions>
                </div>
              ),
            )}
          </div>
        )}
        <div
          className={
            stickySidebar
              ? 'p-8 bg-white rounded pb-0'
              : 'p-8 bg-white rounded pb-4'
          }
        >
          <div className="flex flex-col">
            <div className="flex flex-row lg:flex-col pb-4">
              <div className="flex-1 flex flex-row items-center lg:mb-2">
                <Suitcase
                  className={'mr-4'}
                  style={{ width: '18px', height: '18px' }}
                />
                <div>
                  <p className={'text-grey-darker'}>
                    {instanceMeta.company.name}
                  </p>
                </div>
              </div>
              <div className="flex-1 flex flex-row items-center lg:mb-2">
                <Location
                  className={'mr-4'}
                  style={{ width: '18px', height: '18px' }}
                />
                <div>
                  <p className={'text-grey-darker'}>{instanceMeta.site.name}</p>
                </div>
              </div>
            </div>
            {geostamps.length > 0 && (
              <div>
                <hr className="mb-4" />
                <p className="mb-2">Opened by:</p>
                <div
                  className="flex flex-col overflow-y-scroll"
                  style={{ maxHeight: '150px' }}
                >
                  {geostamps.map(geo => (
                    <GeostampItem
                      className="bg-grey-light"
                      key={geo.geostamp.uid}
                    >
                      <div className="flex flex-row mb-1">
                        <User className={'w-4 h-4 mr-2'} />
                        <p style={{ maxWidth: '175px' }}>
                          {getFullName(geo.user)}
                        </p>
                      </div>
                      <div className="flex flex-row mb-1">
                        <Clock className={'w-4 h-4 mr-2'} />
                        <i className="text-xs" style={{ maxWidth: '175px' }}>
                          {moment
                            .unix(geo.geostamp.created_at)
                            .format('MMM D, YYYY, h:mm:ss a')}
                        </i>
                      </div>
                      <div className="flex flex-row mb-1">
                        <Compass className={'w-4 h-4 mr-2'} />
                        <i className="text-xs" style={{ maxWidth: '175px' }}>
                          {geo.geostamp.formatted_address}
                        </i>
                      </div>
                      {geo.geostamp.temperature ? (
                        <div className="flex flex-row mb-1">
                          <Cloud className={'w-4 h-4 mr-2'} />
                          <b className="text-xs" style={{ maxWidth: '175px' }}>
                            {Math.round(
                              instanceMeta.company.temp_unit === 'F'
                                ? temperatureHelper.cToF(
                                    geo.geostamp.temperature,
                                  )
                                : geo.geostamp.temperature,
                            )}
                            &deg;
                            {instanceMeta.company.temp_unit}
                          </b>
                          <i className="text-xs ml-2">
                            {geo.geostamp.weather_description}
                          </i>
                        </div>
                      ) : null}
                    </GeostampItem>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
        {signOffs.length !== 0 && (
          <div
            className={
              stickySidebar ? 'bg-white px-8 py-4' : 'bg-white px-8 pb-4 pt-0'
            }
          >
            <hr className="mb-4 mt-4" />
            <div>
              <SignOffs signOffs={signOffs} loading={gettingSignOffs} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default FormMeta;

import * as React from 'react';
import Statistics from './dashboard/Statistics';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { CompanyGroup } from '../../types';
import TabRoutes from '../../components/TabRoutes';
import AuditDashboard from './dashboard/AuditDashboard';
import { Route } from 'react-router';
import Incidents from '../../containers/Incidents';
import Incident from '../../containers/Incident';
import { companyGroupUsersFetchRequest } from '../../actions/companyGroup/users';
import { companyGroupCrewsFetchRequest } from '../../actions/companyGroup/crews';
import { companyGroupSitesFetchRequest } from '../../actions/companyGroup/sites';
import { companyGroupFormsFetchRequest } from '../../actions/companyGroup/forms';
import { CompanyGroupUsersState } from '../../reducers/companyGroup/users';
import { CompanyGroupCrewsState } from '../../reducers/companyGroup/crews';
import { CompanyGroupFormsState } from '../../reducers/companyGroup/forms';
import { CompanyGroupSitesState } from '../../reducers/companyGroup/sites';
import { useEntityRefresh } from '../../helpers/hooks';
import { CompanyGroupCompanyState } from '../../reducers/companyGroup/companies';
import { companyGroupCompaniesFetchRequest } from '../../actions/companyGroup/companies';
import DetailedReport from './dashboard/DetailedReport';

interface Props extends WithNamespaces {
  companyGroup: CompanyGroup;
  users: CompanyGroupUsersState;
  crews: CompanyGroupCrewsState;
  forms: CompanyGroupFormsState;
  sites: CompanyGroupSitesState;
  companies: CompanyGroupCompanyState;
  fetchUsers: (companyGroupUid: string) => any;
  fetchCrews: (companyGroupUid: string) => any;
  fetchSites: (companyGroupUid: string) => any;
  fetchForms: (companyGroupUid: string) => any;
  fetchCompanies: (companyGroupUid: string) => any;
}

const Dashboard = ({
  users,
  forms,
  crews,
  sites,
  companies,
  fetchUsers,
  fetchCrews,
  fetchForms,
  fetchSites,
  fetchCompanies,
  companyGroup,
}: Props) => {
  useEntityRefresh(companyGroup.uid, users, fetchUsers);
  useEntityRefresh(companyGroup.uid, sites, fetchSites);
  useEntityRefresh(companyGroup.uid, crews, fetchCrews);
  useEntityRefresh(companyGroup.uid, forms, fetchForms);
  useEntityRefresh(companyGroup.uid, companies, fetchCompanies);

  const tabs = [
    {
      label: 'Statistic',
      href: '/dashboard/company-admin/dashboard',
      exact: true,
      component: (
        <Statistics
          companyGroup={companyGroup}
          users={users.data}
          crews={crews.data}
          sites={sites.data}
          forms={forms.data}
          companies={companies.data}
        />
      ),
    },
    {
      label: 'Audit Dashboard',
      href: '/dashboard/company-admin/dashboard/audits',
      exact: true,
      component: (
        <AuditDashboard
          companyGroup={companyGroup}
          users={users.data}
          sites={sites.data}
          crews={crews.data}
          forms={forms.data}
          companies={companies.data}
        />
      ),
    },
    {
      label: 'Incidents',
      href: '/dashboard/company-admin/dashboard/incidents',
      exact: false,
      component: (
        <React.Fragment>
          <Route
            path={'/dashboard/company-admin/dashboard/incidents'}
            exact={true}
            render={props => (
              <Incidents
                {...props}
                companyGroup={companyGroup}
                companies={companies.data}
                users={users.data}
                sites={sites.data}
                forms={forms.data}
                crews={crews.data}
              />
            )}
          />
          <Route
            path={'/dashboard/company-admin/dashboard/incidents/:incidentUid'}
            render={props => (
              <Incident
                {...props}
                companyGroup={companyGroup}
                users={users.data}
                sites={sites.data}
                crews={crews.data}
                companies={companies.data}
              />
            )}
          />
        </React.Fragment>
      ),
    },
    {
      label: 'Detailed Report',
      href: '/dashboard/company-admin/dashboard/reports',
      exact: true,
      component: (
        <Route
          path={'/dashboard/company-admin/dashboard/reports'}
          render={props => (
            <DetailedReport
              {...props}
              companyGroup={companyGroup}
              users={users.data}
              sites={sites.data}
              crews={crews.data}
              forms={forms.data}
              companies={companies.data}
            />
          )}
        />
      ),
    },
  ];

  return (
    <Page>
      <PageContent>
        <TabRoutes tabs={tabs} />
      </PageContent>
    </Page>
  );
};

const mapStateToProps = (state: StoreState) => ({
  companyGroup: state.me.companyGroup,
  forms: state.companyGroup.forms,
  sites: state.companyGroup.sites,
  users: state.companyGroup.users,
  crews: state.companyGroup.crews,
  companies: state.companyGroup.companies,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUsers: (companyGroupUid: string) =>
    dispatch(companyGroupUsersFetchRequest(companyGroupUid)),
  fetchCrews: (companyGroupUid: string) =>
    dispatch(companyGroupCrewsFetchRequest(companyGroupUid)),
  fetchSites: (companyGroupUid: string) =>
    dispatch(companyGroupSitesFetchRequest(companyGroupUid)),
  fetchForms: (companyGroupUid: string) =>
    dispatch(companyGroupFormsFetchRequest(companyGroupUid)),
  fetchCompanies: (companyGroupUid: string) =>
    dispatch(companyGroupCompaniesFetchRequest(companyGroupUid)),
});

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Dashboard),
);

import * as React from 'react';
import { makeGetChildren, makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption } from '../../types';
import QuestionAnswerItem from './QuestionAnswerItem';
import OptionTitle from './OptionTitle';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import {
  filterChildrenByPublished,
  getOptionTitle,
} from '../../helpers/formOption';
import WidgetContent from './WidgetContent';
import WidgetContainer from './WidgetContainer';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
}

const QuestionAnswer = ({ option, childOptions, t, live }: Props) => {
  if (!option) {
    return null;
  }

  const filteredChildren = live
    ? filterChildrenByPublished(childOptions)
    : childOptions || [];

  const title = getOptionTitle(option, { live }) || '';

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent>
        {filteredChildren.map(o => (
          <QuestionAnswerItem key={o.id} optionId={o.id} live={live} />
        ))}
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getChildren = makeGetChildren();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    childOptions: getChildren(state, ownProps),
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(QuestionAnswer));

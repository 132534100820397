import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../constants';
import classNames from 'classnames';

const Wrapper = styled.div`
  & input {
    display: none;
  }

  & input[type='radio'] + label {
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${cssVars.colors['grey-darker']};
    border-radius: 50%;
    cursor: pointer;

    > div {
      opacity: 0;
      width 10px;
      height: 10px;
      border-radius: 50%;
    }

    &:hover > div {
      opacity: 1;
      background: ${cssVars.colors['grey-darker']};
    }
  }

  & input[type='radio']:checked + label {
    border: 2px solid ${cssVars.colors.red};
    border-radius: 50%;
    color: white;
  }
  & input[type='radio']:checked + label > div {
    opacity: 1;
    background: ${cssVars.colors.red};
  }
`;

interface Props {
  checked?: boolean;
  value?: string;
  id: string;
  onChange?: (state: { checked: boolean; value: string }) => void;
  label: string;
  className?: string;
}

const Radio = ({ onChange, id, checked, label, className, value }: Props) => {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange({
        checked: e.currentTarget.checked,
        value: e.currentTarget.value,
      });
    }
  };

  return (
    <div className={classNames('inline-flex items-center', className)}>
      <Wrapper>
        <input
          type={'radio'}
          id={id}
          checked={checked}
          onChange={handleChange}
          value={value}
        />
        <label htmlFor={id}>
          <div />
        </label>
      </Wrapper>
      <label htmlFor={id} className={'ml-2'}>
        {label}
      </label>
    </div>
  );
};

export default Radio;

import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import InlineInput from '../../../components/InlineInput';
import Folder from '../../../svg/Folder';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import storageApi from '../../../api/storage.api';
import { useTextInput } from '../../../helpers/hooks';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onCreate: () => void;
  storageUid: string;
}

const FolderCreate = ({ isOpen, handleClose, onCreate, storageUid }: Props) => {
  const name = useTextInput();
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);

  const submit = async () => {
    setLoading(true);
    const { data, errors: resErrors } = await storageApi.create(storageUid, {
      name: name.value,
    });

    if (data) {
      onCreate();
    }

    if (resErrors) {
      setErrors(resErrors);
    }

    setLoading(false);
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} contentLabel="New Folder">
      <ModalHeader handleClose={handleClose}>New Folder</ModalHeader>

      <ModalBody>
        <InlineInput
          label="Folder Name"
          icon={<Folder />}
          {...name}
          errorMessage={errors.name}
        />
      </ModalBody>

      <ModalFooter right={true}>
        <Button type="primary" onClick={submit} loading={loading}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default FolderCreate;

import * as React from 'react';
import Statistics from './dashboard/Statistics';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import { usersFetchRequest } from '../../actions/users';
import { crewsFetchRequest } from '../../actions/crews';
import { sitesFetchRequest } from '../../actions/sites';
import { formsFetchRequest } from '../../actions/forms';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { Company } from '../../types';
import { UsersState } from '../../reducers/users';
import { CrewsState } from '../../reducers/crews';
import { FormsState } from '../../reducers/forms';
import { SitesState } from '../../reducers/sites';
import TrainingMatrix from './dashboard/TrainingMatrix';
import AuditDashboard from './dashboard/AuditDashboard';
import Incidents from '../../containers/Incidents';
import { Route } from 'react-router';
import TabRoutes from '../../components/TabRoutes';
import Incident from '../../containers/Incident';
import { needsToBeFetched } from '../../helpers/globalState';

interface Props extends WithNamespaces {
  company: Company;
  users: UsersState;
  crews: CrewsState;
  forms: FormsState;
  sites: SitesState;
  fetchUsers: (companyUid: string) => any;
  fetchCrews: (companyUid: string) => any;
  fetchSites: (companyUid: string) => any;
  fetchForms: (companyUid: string) => any;
}

class Dashboard extends React.Component<Props> {
  public componentDidMount(): void {
    const {
      users,
      forms,
      crews,
      sites,
      fetchUsers,
      fetchCrews,
      fetchForms,
      fetchSites,
      company,
    } = this.props;

    if (needsToBeFetched(users)) {
      fetchUsers(company.uid);
    }

    if (needsToBeFetched(sites)) {
      fetchSites(company.uid);
    }

    if (needsToBeFetched(forms)) {
      fetchForms(company.uid);
    }

    if (needsToBeFetched(crews)) {
      fetchCrews(company.uid);
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    const {
      users,
      forms,
      crews,
      sites,
      fetchUsers,
      fetchCrews,
      fetchForms,
      fetchSites,
      company,
    } = this.props;

    if (needsToBeFetched(users)) {
      fetchUsers(company.uid);
    }

    if (needsToBeFetched(sites)) {
      fetchSites(company.uid);
    }

    if (needsToBeFetched(forms)) {
      fetchForms(company.uid);
    }

    if (needsToBeFetched(crews)) {
      fetchCrews(company.uid);
    }
  }

  public render() {
    const { users, forms, sites, crews, company } = this.props;

    const tabs = [
      {
        label: 'Statistic',
        href: '/dashboard/super/dashboard',
        exact: true,
        component: (
          <Statistics
            company={company}
            users={users.data}
            sites={sites.data}
            forms={forms.data}
            crews={crews.data}
          />
        ),
      },
      {
        label: 'Training Matrix',
        href: '/dashboard/super/dashboard/matrix',
        component: (
          <TrainingMatrix
            users={users.data}
            sites={sites.data}
            crews={crews.data}
            company={company}
          />
        ),
      },
      {
        label: 'Audit Dashboard',
        href: '/dashboard/super/dashboard/audits',
        exact: true,
        component: (
          <AuditDashboard
            company={company}
            users={users.data}
            sites={sites.data}
            forms={forms.data}
            crews={crews.data}
          />
        ),
      },
      {
        label: 'Incidents',
        href: '/dashboard/super/dashboard/incidents',
        exact: false,
        component: (
          <React.Fragment>
            <Route
              path={'/dashboard/super/dashboard/incidents'}
              exact={true}
              render={props => (
                <Incidents
                  {...props}
                  company={company}
                  users={users.data}
                  sites={sites.data}
                  forms={forms.data}
                  crews={crews.data}
                />
              )}
            />
            <Route
              path={'/dashboard/super/dashboard/incidents/:incidentUid'}
              render={props => (
                <Incident
                  {...props}
                  company={company}
                  sites={sites.data}
                  crews={crews.data}
                  users={users.data}
                  forms={forms.data}
                />
              )}
            />
          </React.Fragment>
        ),
      },
      // {
      //   label: 'Documents',
      //   href: '/dashboard/super/dashboard/documents',
      //   exact: false,
      //   component: (
      //     <React.Fragment>
      //       <Route
      //         path={'/dashboard/super/dashboard/documents'}
      //         exact={true}
      //         render={props => <Documents {...props} />}
      //       />
      //       <Route
      //         path={'/dashboard/super/dashboard/documents/create'}
      //         exact={true}
      //         render={props => <DocumentsCreate {...props} />}
      //       />
      //     </React.Fragment>
      //   ),
      // },
    ];

    return (
      <Page>
        <PageContent>
          <TabRoutes tabs={tabs} />
        </PageContent>
      </Page>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  company: state.me.company,
  forms: state.forms,
  sites: state.sites,
  users: state.users,
  crews: state.crews,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  fetchCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
  fetchSites: (companyUid: string) => dispatch(sitesFetchRequest(companyUid)),
  fetchForms: (companyUid: string) => dispatch(formsFetchRequest(companyUid)),
});

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Dashboard),
);

import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import formApi from '../../../api/form.api';

interface Props extends WithNamespaces {
  isOpen: boolean;
  contentLabel: string;
  handleClose: () => void;
  title: string;
  formUid: string;
  onImportQueued: (formUid: string) => void;
}

class FormImport extends React.Component<Props> {
  private onSubmit = async (
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
  ) => {
    e.preventDefault();
    const data = new FormData();
    data.append('uploads', document.getElementById('form-import')!.files[0]);
    const res = await formApi.import(this.props.formUid, data);
    if (res.data) {
      this.props.onImportQueued(res.data.job.id);
      this.props.handleClose();
    }
  };

  public render() {
    const { isOpen, contentLabel, handleClose, title } = this.props;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={contentLabel}
        handleClose={handleClose}
      >
        <ModalHeader handleClose={handleClose}>{title}</ModalHeader>
        <ModalBody>
          <form onSubmit={this.onSubmit}>
            <input type="file" id="form-import" accept={'.sfe'} />
          </form>
        </ModalBody>
        <ModalFooter right={true}>
          <Button type={'primary'} onClick={this.onSubmit}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withNamespaces()(FormImport);

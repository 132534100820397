import * as React from 'react';
import Button from '../../../components/Button';
import ResourceList from '../../../components/ResourceList';
import ResourceListItem from '../../../components/ResourceListItem';
import Checkmark from '../../../svg/Checkmark';
import { Checklist } from '../../../types';
import moment from 'moment';
import { History } from 'history';

interface Props {
  title: string;
  onCreateClick: () => void;
  checklists: Checklist[];
  history: History;
}

const ChecklistView = ({
  title,
  onCreateClick,
  checklists,
  history,
}: Props) => {
  return (
    <div>
      <div className="flex justify-end p-4">
        <Button
          type={'primary'}
          children={'Create Checklist'}
          onClick={onCreateClick}
        />
      </div>
      <ResourceList title={title} items={checklists}>
        {({ items }) => (
          <div>
            {items.map(item => (
              <ResourceListItem
                key={item.name}
                title={item.name}
                subtitle={`Created: ${moment
                  .unix(item.created_at)
                  .format('MMM Do, YYYY hh:mm a')}`}
                Icon={Checkmark}
                onClick={() =>
                  history.push(`/dashboard/worker/checklists/${item.uid}`)
                }
              />
            ))}
          </div>
        )}
      </ResourceList>
    </div>
  );
};

export default ChecklistView;

import * as React from 'react';
import InlineSelect from '../../../components/InlineSelect';
import Cog from '../../../svg/Cog';
import {
  useRequiredSelectInput,
  useOptionalSelectInput,
} from '../../../helpers/hooks';
import Location from '../../../svg/Location';
import UserGroup from '../../../svg/UserGroup';
import User from '../../../svg/User';
import Suitcase from '../../../svg/Suitcase';
import {
  Company,
  Crew,
  User as IUser,
  Site,
  CompanyGroup,
  Form,
} from '../../../types';
import Tabs from '../../../components/Tabs';
import SubmissionStatistics from '../../../containers/SubmissionStatistics';
import Incidents from '../../../containers/Incidents';
import { History } from 'history';
import Actions from './detailedReport/Actions';
import Users from './detailedReport/Users';
import TrainingMatrix from './detailedReport/TrainingMatrix';
import { getDivisionLabel, getSiteLabel, getCrewLabel } from '../../../helpers';

interface Props {
  companyGroup: CompanyGroup;
  companies: Company[];
  crews: Crew[];
  sites: Site[];
  users: IUser[];
  forms: Form[];
  history: History;
}

const getOptions = (arr: any[], accessor: string = 'name') =>
  arr.map(a => ({ label: a[accessor], value: a.uid }));

const DetailedReport = ({
  companies,
  crews,
  sites,
  users,
  companyGroup,
  forms,
  history,
}: Props) => {
  const typeOptions = () => [
    { label: getDivisionLabel(), value: 'company' },
    { label: getSiteLabel(), value: 'site' },
    { label: getCrewLabel(), value: 'crew' },
    { label: 'User', value: 'user' },
  ];

  const type = useRequiredSelectInput(typeOptions()[0]);
  const entity = useOptionalSelectInput();

  const userOptions = getOptions(users, 'username');
  const siteOptions = getOptions(sites);
  const crewOptions = getOptions(crews);
  const companyOptions = getOptions(companies);

  React.useEffect(
    () => {
      entity.onChange(null);
    },
    [type.selected],
  );

  const getFilterOverride = () => ({
    [`${type.selected}Uids`]: [entity.selected],
  });

  return (
    <div>
      <div className="bg-white p-4 flex flex-row">
        <div className="flex-1">
          <InlineSelect
            options={typeOptions()}
            {...type}
            label={'Type'}
            icon={<Cog />}
          />
        </div>
        <div className="flex-1 ml-4">
          {type.selected === 'site' && (
            <InlineSelect
              options={siteOptions}
              label={getSiteLabel()}
              icon={<Location />}
              {...entity}
            />
          )}
          {type.selected === 'crew' && (
            <InlineSelect
              options={crewOptions}
              label={getCrewLabel()}
              icon={<UserGroup />}
              {...entity}
            />
          )}
          {type.selected === 'user' && (
            <InlineSelect
              options={userOptions}
              label={'User'}
              icon={<User />}
              {...entity}
            />
          )}
          {type.selected === 'company' && (
            <InlineSelect
              options={companyOptions}
              label={'Company'}
              icon={<Suitcase />}
              {...entity}
            />
          )}
        </div>
      </div>

      {entity.selected && (
        <Tabs
          tabs={[
            {
              label: 'Submissions',
              component: (
                <SubmissionStatistics
                  companyGroup={companyGroup}
                  sites={sites}
                  users={users}
                  crews={crews}
                  forms={forms}
                  queryOverrides={getFilterOverride()}
                />
              ),
            },
            {
              label: 'Incidents',
              component: (
                <Incidents
                  companyGroup={companyGroup}
                  sites={sites}
                  crews={crews}
                  users={users}
                  forms={forms}
                  history={history}
                  queryOverrides={getFilterOverride()}
                />
              ),
            },
            {
              label: 'Training Matrix',
              component: (
                <TrainingMatrix
                  type={type.selected as any}
                  entityUid={entity.selected}
                />
              ),
            },
            {
              label: 'Actions',
              component: (
                <Actions
                  companyGroup={companyGroup}
                  entityUid={entity.selected}
                  type={type.selected as any}
                />
              ),
            },
            {
              label: 'Users',
              component: (
                <Users
                  companyGroup={companyGroup}
                  entityUid={entity.selected}
                  type={type.selected as any}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  );
};

export default DetailedReport;

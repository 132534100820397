import * as React from 'react';
import Column from './Column';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { makeGetChildren } from '../../selectors/formOptions';
import { FormOption } from '../../types';
import styled from 'styled-components';
import { filterChildrenByPublished } from '../../helpers/formOption';
import { biggerThanMD } from '../../helpers/style';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  ${biggerThanMD(`
    flex-direction: row;
  `)};
`;

interface Props {
  optionId: number;
  childOptions?: FormOption[];
  live?: boolean;
}

const Row = ({ childOptions, live }: Props) => {
  const filteredChildren = live
    ? filterChildrenByPublished(childOptions)
    : childOptions || [];

  return (
    <Container>
      {filteredChildren.map(c => (
        <Column key={c.id} optionId={c.id} live={live} />
      ))}
    </Container>
  );
};

function makeMapStateToProps() {
  const getChildren = makeGetChildren();
  return (state: StoreState, ownProps: Props) => ({
    childOptions: getChildren(state, ownProps),
  });
}

export default connect(makeMapStateToProps)(Row);

import * as React from 'react';
import Table from './Table';
import styled from 'styled-components';
import { Column } from 'react-table';
import EmptyListMessage from './EmptyListMessage';
import { compare } from 'natural-orderby';

const Wrapper = styled.div`
  background: white;
  margin-bottom: 15px;
`;

interface Props {
  data: any[];
  columns: Array<Column<any>>;
  defaultSortKey: string;
  defaultSortDesc?: boolean;
  loading?: boolean;
  fetched?: boolean;
  emptyTitle?: string;
  emptyMessage?: string;
  onRowClick?: (row: any) => any;
}

export default class ResourceTable extends React.Component<Props> {
  private defaultFilterMethod = (filter: any, row: any) =>
    row[filter.id]
      ? row[filter.id]
          .toString()
          .toLowerCase()
          .indexOf(filter.value.toString().toLowerCase()) !== -1
      : false;

  private getTdProps = () => ({
    style: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

  private getTrProps = (state: any, rowInfo: any) => {
    const { onRowClick } = this.props;

    if (rowInfo && rowInfo.row && onRowClick) {
      return {
        onClick: () => {
          onRowClick(rowInfo.original);
        },
        style: {
          cursor: 'pointer',
        },
      };
    }

    return {};
  };

  public render() {
    const {
      loading,
      fetched,
      data,
      defaultSortKey,
      columns,
      defaultSortDesc,
      emptyTitle,
      emptyMessage,
    } = this.props;

    return (
      <Wrapper>
        {data.length === 0 && fetched ? (
          <EmptyListMessage
            emptyTitle={emptyTitle}
            emptyMessage={emptyMessage}
          />
        ) : (
          <Table
            loading={loading}
            showPagination={false}
            data={data}
            className="-striped -highlight"
            filterable={data.length > 0}
            defaultSorted={[
              {
                id: defaultSortKey,
                ...(defaultSortDesc ? { desc: true } : { asc: true }),
              },
            ]}
            defaultFilterMethod={this.defaultFilterMethod}
            columns={columns.map(
              x =>
                x.sortMethod
                  ? x
                  : {
                      ...x,
                      sortMethod: compare(),
                    },
            )}
            getTdProps={this.getTdProps}
            getTrProps={this.getTrProps}
            pageSize={data.length}
            style={{ maxHeight: '400px' }}
          />
        )}
      </Wrapper>
    );
  }
}

import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import InlineInput from '../../../components/InlineInput';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import InlineSelect from '../../../components/InlineSelect';
import { ChromePicker } from 'react-color';
import Cog from '../../../svg/Cog';
import NewsPaper from '../../../svg/NewsPaper';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Form, Company, FormVersion } from '../../../types';
import formService from '../../../services/forms.service';
import { useRequiredSelectInput, useTextInput } from '../../../helpers/hooks';
import { useState } from 'react';
import formApi from '../../../api/form.api';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  onCreate: (form: Form) => void;
  company: Company;
  version: FormVersion;
}

const DEFAULT_COLOR = '#e66161';

const FormCreate = ({
  t,
  isOpen,
  handleClose,
  company,
  onCreate,
  version,
}: Props) => {
  const typeOptions = [
    { label: t('forms:general'), value: 'general' },
    { label: t('forms:hazard'), value: 'hazard' },
    { label: t('forms:inspection'), value: 'inspection' },
    { label: t('forms:audit'), value: 'audit' },
    { label: t('forms:incident'), value: 'incident' },
  ];

  const [color, setColor] = useState(DEFAULT_COLOR);
  const [loading, setLoading] = useState(false);
  const type = useRequiredSelectInput(typeOptions[0]);
  const name = useTextInput();
  const [errors, setErrors] = useState<{
    name?: string;
  }>({});

  const submit = async () => {
    setLoading(true);

    const endpoint = version === 'v2' ? formService.createForm : formApi.create;
    const { data, errors: err } = await endpoint(company.uid, {
      name: name.value,
      color: color || DEFAULT_COLOR,
      type: type.selected,
    });
    if (data) {
      onCreate(data.form);
    }
    if (err) {
      setErrors(err);
    }

    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={'Create a Form'}
      handleClose={handleClose}
    >
      <ModalHeader handleClose={handleClose}>
        Create
        {version === 'v1' ? ' ' : ' V2 '}
        Form
      </ModalHeader>

      <ModalBody>
        <InlineInput
          label={'Name'}
          icon={<NewsPaper />}
          errorMessage={errors.name}
          {...name}
        />
        <InlineSelect
          options={typeOptions}
          label={'Type'}
          icon={<Cog />}
          {...type}
        />
        <div className="flex justify-center">
          <ChromePicker
            disableAlpha={true}
            color={color}
            onChangeComplete={c => setColor(c.hex)}
          />
        </div>
      </ModalBody>

      <ModalFooter right={true}>
        <Button
          type={'default'}
          onClick={handleClose}
          className={'mr-4'}
          children={'Cancel'}
        />
        <Button
          type={'primary'}
          children={'Create'}
          loading={loading}
          onClick={submit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default withNamespaces()(FormCreate);

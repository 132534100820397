import api, { api2 } from './api';
import { Storage } from '../types';

interface UpdateData {
  description: string;
  caseNumber: string;
  siteUid?: string;
  crewUid?: string;
  occurred: number;
  reported: number;
  props?: {
    [id: string]: string | string[] | any;
  };
}

const incidentApi = {
  show: (incidentUid: string) => api('GET', `incidents/${incidentUid}`),
  update: (incidentUid: string, data: UpdateData) =>
    api2('PATCH', `incidents/${incidentUid}`, data),
  actions: (incidentUid: string) =>
    api2('GET', `incidents/${incidentUid}/actions`),
  submissions: (incidentUid: string) =>
    api2('GET', `incidents/${incidentUid}/submissions`),
  attachSubmission: (incidentUid: string, submissionUid: string) =>
    api2('PUT', `incidents/${incidentUid}/submissions/${submissionUid}`),
  detachSubmission: (incidentUid: string, submissionUid: string) =>
    api2('DELETE', `incidents/${incidentUid}/submissions/${submissionUid}`),
  uploads: (incidentUid: string) =>
    api2<{ uploads: Storage[] }>('GET', `incidents/${incidentUid}/uploads`),
  upload: (incidentUid: string, data: any) =>
    api2<{ uploads: Storage[] }>(
      'POST',
      `incidents/${incidentUid}/uploads`,
      data,
    ),
  complete: (incidentUid: string) =>
    api2('POST', `incidents/${incidentUid}/complete`),
  uncomplete: (incidentUid: string) =>
    api2('POST', `incidents/${incidentUid}/uncomplete`),
};

export default incidentApi;

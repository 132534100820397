import styled from 'styled-components';
import { colors, complimentaryTextColors } from '../constants';

const ErrorMessage = styled.div`
  padding: 4px 9px;
  font-size: 12px;
  color: ${complimentaryTextColors.PRIMARY}
  background: ${colors.PRIMARY};
`;

export default ErrorMessage;

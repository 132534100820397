import * as React from 'react';
import Page from '../../components/Page';
import PageToolbar from '../../components/PageToolbar';
import PageContent from '../../components/PageContent';
import { match } from 'react-router';
import { useMountVisibility, useSimpleFetch } from '../../helpers/hooks';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Trash from '../../svg/Trash';
import Pencil from '../../svg/Pencil';
import Save from '../../svg/Save';
import FormEdit from './form/FormEdit';
import FormDelete from './form/FormDelete';
import formApi from '../../api/form.api';
import { Form } from '../../types';
import { History } from 'history';
import { Dispatch } from 'redux';
import { formsInvalidate, updateForm } from '../../actions/forms';
import { connect } from 'react-redux';
import Loading from '../../components/Loading';
import formService from '../../services/forms.service';
import { useState, useEffect } from 'react';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import { toast } from 'react-toastify';
import FabricatorView from '../../containers/FabricatorView';

interface Props extends WithNamespaces {
  match: match<{ formUid: string }>;
  history: History;
  invalidateForms: () => void;
  updateForm: (form: Form) => void;
}

const FormFabricator = ({ t, history, invalidateForms, ...props }: Props) => {
  const { formUid } = props.match.params;
  const [formRevUid, setFormRevUid] = useState('');
  const deleteState = useMountVisibility();
  const editState = useMountVisibility();
  const confirmationDialogue = useMountVisibility();
  const [editError, setEditErrors] = useState<string[]>([]);

  const hasErrors = editError.length !== 0;

  const form = useSimpleFetch<null | Form>({
    initial: null,
    change: [formUid],
    fetch: () => formApi.show(formUid),
    accessor: 'form',
  });

  const getFormRevisionUid = async () => {
    const { data } = await formService.formWorkingCopy(formUid);
    if (data) {
      const formRevisionUid = data.workingCopy.uid;
      setFormRevUid(formRevisionUid);
    }
  };

  const publish = async () => {
    confirmationDialogue.close();
    const { data } = await formService.publish(formUid);
    if (!data) {
      toast.error('Form could not be published');
    }
    if (data) {
      setFormRevUid(data.revision.uid);
      toast.success('Form successfully published!');
    }
  };

  useEffect(() => {
    getFormRevisionUid();
  }, []);

  const onDelete = () => {
    deleteState.close();
    invalidateForms();
    history.push('/dashboard/super/forms');
  };

  const onUpdate = (updatedForm: Form) => {
    editState.close();
    props.updateForm(updatedForm);
    form.setValue(updatedForm);
  };

  const actions = [
    {
      label: 'Delete',
      onClick: deleteState.open,
      icon: <Trash />,
    },
    {
      label: t('super:screens.form.edit'),
      onClick: editState.open,
      icon: <Pencil />,
    },
    {
      label: t('super:screens.form.publish'),
      type: hasErrors ? ('default' as any) : ('success' as any),
      onClick: confirmationDialogue.open,
      icon: <Save />,
      disabled: hasErrors,
    },
  ];

  if (!form.value) {
    return <Loading loading={true} />;
  }

  return (
    <Page>
      <PageToolbar title={form.value.name} actions={actions} />

      <PageContent>
        {formRevUid && (
          <FabricatorView
            formUid={formUid}
            formRevUid={formRevUid}
            editErrors={editError}
            setEditErrors={setEditErrors}
          />
        )}

        <FormDelete
          isOpen={deleteState.visible}
          handleClose={deleteState.close}
          formUid={formUid}
          onDelete={onDelete}
        />

        {form.value && (
          <FormEdit
            isOpen={editState.visible}
            handleClose={editState.close}
            contentLabel={t('super:screens.form.editForm')}
            title={t('super:screens.form.editForm')}
            form={form.value}
            onUpdate={onUpdate}
          />
        )}
        <ConfirmationDialogue
          title={hasErrors ? 'Cannot Publish Form!' : 'Publish this form?'}
          body={
            hasErrors
              ? 'Cannot publish form! There are errors that need to be fixed.'
              : 'This will only affect new drafts.'
          }
          contentLabel={'Publish Confirmation'}
          handleClose={confirmationDialogue.close}
          onConfirm={hasErrors ? confirmationDialogue.close : publish}
          isOpen={confirmationDialogue.visible}
        />
      </PageContent>
    </Page>
  );
};

const mapDispatch = (dispatch: Dispatch) => ({
  invalidateForms: () => dispatch(formsInvalidate()),
  updateForm: (form: Form) => dispatch(updateForm(form)),
});

export default connect(
  null,
  mapDispatch,
)(withNamespaces()(FormFabricator));

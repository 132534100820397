import * as React from 'react';
import Page from '../../components/Page';
import PageToolbar from '../../components/PageToolbar';
import PageContent from '../../components/PageContent';
import companyApi from '../../api/company.api';
import storageApi, { ChildrenResponse } from '../../api/storage.api';
import { Company, Storage, User } from '../../types';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { match } from 'react-router';
import last from 'ramda/es/last';
import { History } from 'history';
import Loading from '../../components/Loading';
import { useMountVisibility, useMedia } from '../../helpers/hooks';
import FolderCreate from './files/FolderCreate';
import FolderSidebox from '../../containers/FolderSidebox';
import { trimEnd } from 'lodash';
import contains from 'ramda/es/contains';
import Download from '../../svg/Download';
import Upload from '../../svg/Upload';
import FolderPlus from '../../svg/FolderPlus';
import { Dispatch } from 'redux';
import { openDownloads } from '../../actions/ui';
import companyUserApi from '../../api/companyUser.api';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import Grid from '../../svg/Grid';
import Menu from '../../svg/Menu';
import { compare } from 'natural-orderby';
import ListView from './files/ListView';
import GridView from './files/GridView';
import { localStorageKeys, mediaQueries } from '../../constants/index';
import { DragDropContextProvider, DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { toast } from 'react-toastify';

interface Props {
  company: Company;
  user: User;
  match: match<any>;
  history: History;
  openDownloads: () => any;
}

const Files = ({
  company,
  match: pMatch,
  history,
  openDownloads: pOpenDownloads,
  user,
}: Props) => {
  const [storage, setStorage] = React.useState<ChildrenResponse[]>([]);
  const urlPath = pMatch.params[0];
  const [loading, setLoading] = React.useState(false);
  const newFolderState = useMountVisibility();
  const sideboxState = useMountVisibility();
  const deleteState = useMountVisibility();
  const [currentUid, setCurrentUid] = React.useState('');
  const [storageDetail, setStorageDetail] = React.useState('');
  const [breadcrumbs, setBreadcrumbs] = React.useState<Storage[]>([]);
  const [selected, setSelected] = React.useState<string[]>([]);
  const defaultView = React.useMemo(
    () => localStorage.getItem(localStorageKeys.FILE_VIEW),
    [],
  );
  const [view, setView] = React.useState(defaultView || 'list');
  const companyFetchedFor = React.useRef('');
  const isMobile = useMedia(
    [`(min-width: ${mediaQueries.LG}px)`],
    [false],
    true,
  );
  const [rootUid, setRootUid] = React.useState('');

  React.useEffect(
    () => {
      if (isMobile) {
        setView('list');
      }
    },
    [isMobile],
  );

  const fetchRoot = async () => {
    const { rootFolder } = await companyApi.rootStorageDir(company.uid);
    if (rootFolder) {
      setRootUid(rootFolder.uid);
      return rootFolder.uid;
    }
  };

  const fetchInitial = async () => {
    setLoading(true);
    companyFetchedFor.current = company.uid;
    const uid = rootUid || (await fetchRoot());
    if (uid) {
      const { data } = await storageApi.children(uid);
      if (data) {
        setStorage(data.children);
        setCurrentUid(uid);
        setBreadcrumbs(data.path);
      }
    }
    setLoading(false);
  };

  const fetchChildren = async () => {
    setLoading(true);
    companyFetchedFor.current = company.uid;
    const current = urlPath ? last<string>(urlPath.split('/'))! : currentUid;
    const { data } = await storageApi.children(current);
    if (data) {
      setStorage(data.children);
      setCurrentUid(current);
      setBreadcrumbs(data.path);
    }
    setLoading(false);
  };

  const onCreate = () => {
    fetchChildren();
    newFolderState.close();
  };

  React.useEffect(
    () => {
      setSelected([]);
      if (urlPath) {
        fetchChildren();
        if (!rootUid) {
          fetchRoot();
        }
      } else {
        fetchInitial();
      }
    },
    [urlPath],
  );

  React.useEffect(
    () => {
      if (companyFetchedFor.current !== company.uid) {
        setSelected([]);
        if (pMatch.path === '/dashboard/worker/files') {
          fetchInitial();
        } else {
          history.push('/dashboard/worker/files');
        }
      }
    },
    [company.uid],
  );

  const onMenuClick = (storageUid: string) => {
    setStorageDetail(storageUid);
    sideboxState.open();
  };

  const onDelete = () => {
    fetchChildren();
  };

  const onBcClick = (idx: number) => {
    history.push(
      trimEnd(
        `/dashboard/worker/files/${breadcrumbs
          .slice(0, idx)
          .map(x => x.uid)
          .join('/')}`,
        '/',
      ),
    );
  };

  const toggleSelected = (uid: string) => {
    if (contains(uid, selected)) {
      setSelected(selected.filter(x => x !== uid));
    } else {
      setSelected([...selected, uid]);
    }
  };

  const triggerDownload = async () => {
    await companyUserApi.download(company.uid, user.uid, selected);
    pOpenDownloads();
  };

  const onFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const { files: iFiles } = e.currentTarget;
    if (iFiles && iFiles.length > 0) {
      const data = new FormData();
      data.append('uploads', iFiles[0]);
      await storageApi.upload(currentUid, data);
      fetchChildren();
    }
  };

  const openFileSelect = () => {
    const el = document.getElementById(`files-img-upload`);
    if (el) {
      el.click();
    }
  };

  const openFolder = (uid: string) => {
    history.push(
      `/dashboard/worker/files/${urlPath ? `${urlPath}/${uid}` : uid}`,
    );
  };

  const openParent = () => {
    if (!urlPath) {
      return history.push(`/dashboard/worker/files`);
    }
    const broken = urlPath.split('/');
    if (broken.length === 1) {
      return history.push(`/dashboard/worker/files`);
    }

    return history.push(
      `/dashboard/worker/files/${broken.slice(0, broken.length - 1).join('/')}`,
    );
  };

  const deleteSelected = async () => {};

  const selectView = (key: string) => {
    if (isMobile && key === 'grid') {
      return;
    }
    localStorage.setItem(localStorageKeys.FILE_VIEW, key);
    setView(key);
  };

  const onDrop = (dragUid: string, dropUid: string) => {
    const toMove = selected.length > 0 ? selected : [dragUid];
    if (dragUid === dropUid || toMove.indexOf(dropUid) !== -1) {
      return;
    }
    setStorage(storage.filter(x => toMove.indexOf(x.storage.uid) === -1));
    toMove.forEach(uid => {
      storageApi.changeParent(uid, dropUid);
    });
  };

  const regeneratePdfs = async () => {
    const { data } = await storageApi.regenerate(selected);
    if (data) {
      toast.success(
        'This may take a few minutes. You will need to refresh the page',
      );
    } else {
      toast.error('Something went wrong');
    }
  };

  const sortedStorage = [...storage].sort((a, b) =>
    compare()(a.storage.name, b.storage.name),
  );
  const activeViewClass = 'text-red';
  const viewClass = 'w-6 h-6 cursor-pointer hover:text-grey-darker';

  const parentUid = (() => {
    const split = (urlPath || '').split('/');
    if (!urlPath || split.length === 1) {
      return rootUid;
    }
    return split[split.length - 2];
  })();

  return (
    <Page>
      <input
        type="file"
        accept="image/*,.pdf,.doc,.docx,.xls,.xlsx"
        style={{ display: 'none' }}
        id={`files-img-upload`}
        onChange={onFileChange}
        value={''}
      />
      <PageToolbar
        title={'Files'}
        actions={[
          ...(selected.length > 0
            ? [
                // { label: 'Delete', icon: <Trash /> },
                { label: 'Regenerate', onClick: regeneratePdfs },
                {
                  label: 'Download',
                  icon: <Download />,
                  onClick: triggerDownload,
                },
              ]
            : [
                {
                  label: 'My Downloads',
                  icon: <Download />,
                  onClick: pOpenDownloads,
                },
              ]),
          {
            label: 'New Folder',
            onClick: newFolderState.open,
            icon: <FolderPlus />,
          },
          {
            label: 'Upload',
            type: 'primary',
            icon: <Upload />,
            onClick: openFileSelect,
          },
        ]}
      />

      <PageContent>
        <Loading loading={loading} />
        <p className="mb-4">
          {breadcrumbs &&
            breadcrumbs.map((x, i, arr) => (
              <span
                onClick={() => onBcClick(i)}
                className={'hover:text-grey-darker cursor-pointer'}
                key={x.uid}
              >
                {x.name}
                {i !== arr.length - 1 ? ' > ' : ''}
              </span>
            ))}
        </p>
        <div className="p-4 bg-white">
          <Grid
            className={`${viewClass} ${
              view === 'grid' ? activeViewClass : ''
            } mr-2 ${isMobile ? 'hidden' : ''}`}
            onClick={() => selectView('grid')}
          />
          <Menu
            className={`${viewClass} ${view === 'list' ? activeViewClass : ''}`}
            onClick={() => selectView('list')}
          />
          <hr className={'my-4 '} />
          {view === 'grid' ? (
            <GridView
              storage={sortedStorage}
              toggleSelected={toggleSelected}
              selected={selected}
              onMenuClick={onMenuClick}
              openFolder={openFolder}
              openParent={urlPath ? openParent : undefined}
              onDrop={onDrop}
              parentUid={parentUid}
            />
          ) : (
            <ListView
              storage={sortedStorage}
              toggleSelected={toggleSelected}
              selected={selected}
              onMenuClick={onMenuClick}
              openFolder={openFolder}
              openParent={urlPath ? openParent : undefined}
              onDrop={onDrop}
              parentUid={parentUid}
            />
          )}
        </div>
      </PageContent>
      {newFolderState.mounted && (
        <FolderCreate
          isOpen={newFolderState.visible}
          handleClose={newFolderState.close}
          onCreate={onCreate}
          storageUid={currentUid}
        />
      )}
      {sideboxState.mounted && (
        <FolderSidebox
          isOpen={sideboxState.visible}
          handleClose={sideboxState.close}
          storageUid={storageDetail}
          onDelete={onDelete}
        />
      )}
      <ConfirmationDialogue
        isOpen={deleteState.visible}
        handleClose={deleteState.close}
        contentLabel="Delete selected files"
        title="Delete selected files"
        body="Are you sure you wish to delete these files?"
        onConfirm={deleteSelected}
      />
    </Page>
  );
};

const mapStateToProps = (state: StoreState) => ({
  company: state.me.company,
  user: state.me.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  openDownloads: () => dispatch(openDownloads()),
});

export default DragDropContext(HTML5Backend)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Files),
);

import * as React from 'react';
import ReactLoading from 'react-loading';
import styled from 'styled-components';
import { colors, colorTypes } from '../constants';

const Wrapper = styled.div`
  position: absolute;
  z-index: 99;
  background: rgba(255, 255, 255, 0.6);
  color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

interface Props {
  showText?: boolean;
  loadingText?: string;
  loading?: boolean;
}

export default class Loading extends React.Component<Props> {
  public static defaultProps = {
    showText: true,
    loading: true,
  };

  public render() {
    if (!this.props.loading) {
      return null;
    }

    return (
      <Wrapper>
        <ReactLoading type={'cylon'} color={colors[colorTypes.PRIMARY]} />
        {this.props.showText && (
          <h3>{this.props.loadingText || 'Loading...'}</h3>
        )}
      </Wrapper>
    );
  }
}

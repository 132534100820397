import * as React from 'react';
import styled from 'styled-components';
import { User } from '../types';
import { getFullName } from '../helpers';

interface PlaceholderAvatarProps {
  bgColor?: string;
  fgColor?: string;
  size: Sizes;
  margin?: boolean;
  bgImg?: string;
}

const sizes = {
  MD: '30px',
  LG: '40px',
};

type Sizes = 'MD' | 'LG';

const PlaceholderAvatar = styled.div<PlaceholderAvatarProps>`
  width: ${props => sizes[props.size]};
  height: ${props => sizes[props.size]};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: ${props => (props.margin ? `10px` : '0')};
  background: ${props =>
    props.bgColor ? `#${props.bgColor.replace('#', '')}` : 'black'};
  color: ${props => props.fgColor || 'white'};
`;

interface Props {
  imageUrl?: string;
  fallbackName?: string;
  fgColor?: string;
  bgColor?: string;
  size?: Sizes;
  margin?: boolean;
  user?: User;
}

export default class Avatar extends React.Component<Props> {
  public static defaultProps = {
    size: 'MD',
    margin: true,
  };

  public render() {
    const { fallbackName, bgColor, fgColor, size, margin, user } = this.props;

    if (user && user.profile_url && size) {
      const [beforeUpload, afterUpload] = user.profile_url.split('/upload');
      const injectStr = `/upload/c_fill,${
        size === 'MD' ? 'w_30,h_30' : 'w_40,h_40'
      },r_max/`;
      return (
        <div
          className="overflow-hidden"
          style={{
            width: sizes[size],
            height: sizes[size],
            borderRadius: '50%',
            marginRight: margin ? '10px' : '0px',
          }}
        >
          <img
            src={[beforeUpload, injectStr, afterUpload].join('')}
            alt={getFullName(user)}
          />
        </div>
      );
    }

    return (
      <PlaceholderAvatar
        bgColor={bgColor}
        fgColor={fgColor}
        size={size!}
        margin={margin}
        bgImg={(user && user.profile_url) || undefined}
      >
        {fallbackName ? fallbackName.substr(0, 1) : ''}
      </PlaceholderAvatar>
    );
  }
}

import * as siteFormActions from '../actions/siteForms';
import { ActionType, getType } from 'typesafe-actions';
import { FormsOnSite } from '../api/site.api';

export type SiteFormAction = ActionType<typeof siteFormActions>;

export interface SiteFormsState {
  data: FormsOnSite;
  loading: boolean;
  loaded: boolean;
  errors?: any;
  siteUid: string;
  userUid: string;
}

const DEFAULT_STATE: SiteFormsState = {
  data: {
    site: [],
    crews: [],
    users: [],
  },
  loading: false,
  loaded: false,
  errors: {},
  siteUid: '',
  userUid: '',
};

export default function siteFormsReducer(
  state: SiteFormsState = DEFAULT_STATE,
  action: SiteFormAction,
) {
  switch (action.type) {
    case getType(siteFormActions.siteFormsFetchRequest):
      return {
        ...state,
        loading: true,
        siteUid: action.payload.siteUid,
        userUid: action.payload.userUid,
      };
    case getType(siteFormActions.siteFormsFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: { ...action.payload },
      };
    case getType(siteFormActions.siteFormsFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
      };
    default:
      return state;
  }
}

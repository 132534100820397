import * as React from 'react';
import Modal from '../components/Modal';
import ModalBody from '../components/modal/ModalBody';
import InlineDatePicker from '../components/InlineDatePicker';
import InlineInput from '../components/InlineInput';
import Button from '../components/Button';
import ModalFooter from '../components/modal/ModalFooter';
import userApi from '../api/user.api';
import { useTextInput } from '../helpers/hooks';
import moment, { Moment } from 'moment';
import cloudinaryService from '../services/cloudinary.service';
import { toast } from 'react-toastify';
import { Certificate } from '../types';
import ModalHeader from '../components/modal/ModalHeader';
import InlineToggle from '../components/InlineToggle';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  userUid: string;
  onCreate?: (cert: Certificate) => void;
}

const CertificateCreate = ({
  isOpen,
  handleClose,
  userUid,
  onCreate,
}: Props) => {
  const [image, setImage] = React.useState<null | FileList>(null);
  const name = useTextInput();
  const [loading, setLoading] = React.useState<boolean>(false);
  const [expires, setExpires] = React.useState<boolean>(true);
  const [expiryDate, setExpiryDate] = React.useState<Moment | null>(moment());
  const onImageChange = (e: React.FormEvent<HTMLInputElement>) => {
    setImage(e.currentTarget.files);
  };

  const submit = async () => {
    if (!name.value) {
      toast.error('Could not create certificate! Please enter a name.');
      return;
    }
    setLoading(true);

    const data = new FormData();
    data.append('name', name.value);

    if (expiryDate) {
      data.append('expires', `${expiryDate.unix()}`);
    }

    if (image) {
      const img = await cloudinaryService.ensureImg({
        userUid,
        file: image[0],
      });
      if (!img) {
        toast.error('Failed to convert to image!');
        return;
      }
      data.append('image', img);
    }

    try {
      const { certificate } = await userApi.createCertificate(userUid, data);
      toast.success('Successfully uploaded certificate!');
      if (onCreate) {
        onCreate(certificate);
      }
    } catch (e) {
      toast.error('Failed to upload certificate!');
    }
    setLoading(false);
  };
  function toggleExpiry() {
    setExpires(!expires);
    setExpiryDate(!expires ? moment() : null);
  }

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={'Create Certificate'}
      handleClose={handleClose}
    >
      <ModalHeader handleClose={handleClose}>Create Certificate</ModalHeader>
      <ModalBody>
        <div className="flex flex-col justify-center">
          <div className={'text-center'}>
            <input type="file" accept="*" onChange={onImageChange} />
          </div>
          <div className="flex-1 flex flex-col mt-4">
            <InlineInput label={'Certificate Name'} {...name} />
            <InlineToggle
              checked={expires}
              label={'Certificate Expires?'}
              onChange={() => toggleExpiry()}
            />
            <InlineDatePicker
              disabled={!expires}
              onChange={event => setExpiryDate(event)}
              label={'Expires On'}
              value={expiryDate}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter right={true}>
        <Button
          type={'default'}
          children={'Cancel'}
          className={'mr-4'}
          onClick={handleClose}
        />
        <Button
          type={'primary'}
          children={'Create'}
          onClick={submit}
          loading={loading}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CertificateCreate;

import * as React from 'react';
import ResourceTable from '../../components/ResourceTable';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import {
  useSimpleFetch,
  useEntityRefresh,
  useMountVisibility,
} from '../../helpers/hooks';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { User, Company, Crew } from '../../types';
import CrewSidebox from '../../containers/CrewSidebox';
import { UsersState } from '../../reducers/users';
import { usersFetchRequest } from '../../actions/users';
import { Dispatch } from 'redux';
import { sitesFetchRequest } from '../../actions/sites';
import { SitesState } from '../../reducers/sites';
import SubmissionStatistics from '../../containers/SubmissionStatistics';

interface Props {
  user: User;
  company: Company;
  users: UsersState;
  sites: SitesState;
  getSites: (companyUid: string) => any;
  getUsers: (companyUid: string) => any;
}

const CrewManager = ({
  user,
  users,
  company,
  getSites,
  getUsers,
  sites,
}: Props) => {
  const [selectedCrew, setSelectedCrew] = React.useState<null | Crew>(null);
  const sideboxState = useMountVisibility();
  useEntityRefresh(company.uid, sites, getSites);
  useEntityRefresh(company.uid, users, getUsers);

  return (
    <Page>
      <PageContent>
        <SubmissionStatistics
          company={company}
          sites={sites.data}
          users={users.data}
          crews={[]}
          forms={[]}
        />
      </PageContent>
    </Page>
  );
};

const mapState = (state: StoreState) => ({
  user: state.me.user,
  company: state.me.company,
  users: state.users,
  sites: state.sites,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getSites: (companyUid: string) => dispatch(sitesFetchRequest(companyUid)),
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  };
}

export default connect(
  mapState,
  mapDispatchToProps,
)(CrewManager);

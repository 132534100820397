import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const VerticalMenu = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M10 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0-6a2 2 0 1 1 0-4 2 2 0 0 1 0 4zm0 12a2 2 0 1 1 0-4 2 2 0 0 1 0 4z" />
  </svg>
);

export default BaseSVG(VerticalMenu);

import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const FolderOpenLocked = (props: SVGProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="32"
    viewBox="0 0 32 32"
    {...props}
  >
    <path
      fill="#E0B03B"
      d="M26.914 7.346H9.544V6.56c0-.55-.45-1-1-1H2.802c-.55 0-1 .45-1 1v.786h-.3c-.825 0-1.5.675-1.5 1.5V24.94c0 .825.675 1.5 1.5 1.5h25.414c.825 0 1.5-.675 1.5-1.5V8.847c0-.825-.675-1.5-1.5-1.5z"
    />
    <path fill="#FFF" d="M2.14 8.784h24.134v16.22H2.14z" />
    <path
      fill="#FFC843"
      d="M28.77 24.984c-.195.8-1.03 1.457-1.856 1.457H1.5c-.825 0-1.34-.655-1.143-1.456l3.186-12.99c.197-.8 1.033-1.457 1.858-1.457h25.414c.826 0 1.34.656 1.144 1.457l-3.187 12.99z"
    />
    <path
      fill="#556080"
      d="M16.66 13.083s-1.857 2.23-6.13 2.23v5.32c0 2.776 1.205 5.456 3.39 7.173.772.607 1.685 1.13 2.74 1.443 1.058-.315 1.97-.837 2.743-1.444 2.184-1.717 3.39-4.396 3.39-7.174v-5.32c-4.273 0-6.132-2.23-6.132-2.23z"
    />
    <path
      fill="#FFC843"
      d="M16.66 27.482c-.59-.232-1.164-.564-1.707-.992-1.723-1.354-2.75-3.543-2.75-5.857V16.88c2.06-.26 3.522-.978 4.458-1.615.94.637 2.4 1.354 4.46 1.616v3.753c0 2.314-1.027 4.504-2.75 5.857-.545.428-1.117.76-1.71.992z"
    />
  </svg>
);

export default BaseSVG(FolderOpenLocked);

import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import PageHeading from '../../components/PageHeading';
import ResourceTable from '../../components/ResourceTable';
import { Company, User, Site } from '../../types';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import companyUserApi from '../../api/companyUser.api';
import { useSimpleFetch } from '../../helpers/hooks';
import Loading from '../../components/Loading';
import { History } from 'history';
import { getSiteLabel, getSiteLabelPlural } from '../../helpers';

const columns = [{ Header: 'Name', accessor: 'name' }];

interface Props {
  company: Company;
  user: User;
  history: History;
}

const Sites = ({ company, user, history }: Props) => {
  const sites = useSimpleFetch<Site[]>({
    initial: [],
    change: [company.uid, user.uid],
    accessor: 'sites',
    fetch: () => companyUserApi.sites(company.uid, user.uid),
  });

  return (
    <Page>
      <Loading loading={sites.loading} />
      <PageContent>
        <PageHeading title={`Select ${getSiteLabel()}`} />

        <ResourceTable
          data={sites.value}
          columns={columns}
          defaultSortKey={'name'}
          onRowClick={row => history.push(`/dashboard/worker/sites/${row.uid}`)}
          loading={sites.loading}
          fetched={sites.fetched}
          emptyTitle={`No ${getSiteLabelPlural().toLowerCase()} found!`}
          emptyMessage={`You don't appear to be assigned to any ${getSiteLabelPlural().toLowerCase()}. If you feel that this is an error please contact your supervisor.`}
        />
      </PageContent>
    </Page>
  );
};

export default connect((state: StoreState) => ({
  company: state.me.company,
  user: state.me.user,
}))(Sites);

import * as React from 'react';
import EntityFilter from '../../components/dateFilterWidget/EntityFilter';
import Loading from '../../components/Loading';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
} from 'recharts';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import {
  CompanyGroup,
  Company,
  Crew,
  Form,
  Site,
  User as IUser,
} from '../../types';
import moment, { Moment } from 'moment';
import { SelectOptionObject, SelectOption } from '../../components/Select';
import styled from 'styled-components';
import { cssVars } from '../../constants/index';
import { biggerThanMD } from '../../helpers/style';
import companyApi from '../../api/company.api';
import companyGroupApi from '../../api/companyGroup.api';
import SummaryCard from '../../components/SummaryCard';
import Alarm from '../../svg/Alarm';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { openDownloads } from '../../actions/ui';
import { getSiteLabel } from '../../helpers';

const CardWrapper = styled.div`
  flex: 1;
  margin-bottom: ${cssVars.margin[4]};

  ${biggerThanMD(`
    margin-bottom: 0;
    margin-right: ${cssVars.margin[4]};
    
    &:last-of-type {
      margin-right: 0;
    }
  `)};
`;

const getChartMonths = () => [
  { month: 'Jan', value: 0 },
  { month: 'Feb', value: 0 },
  { month: 'Mar', value: 0 },
  { month: 'Apr', value: 0 },
  { month: 'May', value: 0 },
  { month: 'Jun', value: 0 },
  { month: 'Jul', value: 0 },
  { month: 'Aug', value: 0 },
  { month: 'Sep', value: 0 },
  { month: 'Oct', value: 0 },
  { month: 'Nov', value: 0 },
  { month: 'Dec', value: 0 },
];

const calculateAuditCharts = (auditStats: any, entityAccessor: string) => {
  const auditsPerEntity = [];
  const compliancePerEntity = [];
  const auditsPerMonth = getChartMonths();
  const actionsPerMonth = getChartMonths();
  let totalAudits = 0;
  let totalUnsafe = 0;
  let totalActions = 0;
  const totalComp: any[] = [];

  for (const item of auditStats) {
    const {
      submissions,
      actions,
      averageCompliance,
      high,
      low,
      idlh,
      medium,
    } = item;
    const entity = item[entityAccessor];
    const submLength = submissions.length;
    const actionsLength = actions.length;

    totalAudits += submLength;
    totalUnsafe += high + idlh + low + medium;
    totalActions += actionsLength;

    auditsPerEntity.push({ [entityAccessor]: entity.name, value: submLength });
    compliancePerEntity.push({
      [entityAccessor]: entity.name,
      value: averageCompliance,
    });
    totalComp.push(submLength * averageCompliance);

    for (const subm of submissions) {
      const date = moment.unix(subm.created_at);
      if (!date.isValid()) {
        continue;
      }
      const month = date.format('M');
      auditsPerMonth[+month - 1].value += 1;
    }

    for (const action of actions) {
      const date = moment.unix(action.created_at);
      if (!date.isValid()) {
        continue;
      }
      const month = date.format('M');
      actionsPerMonth[+month - 1].value += 1;
    }
  }

  return {
    auditsPerEntity: auditsPerEntity.filter(i => i.value !== 0),
    compliancePerEntity: compliancePerEntity.filter(i => i.value !== null),
    auditsPerMonth,
    actionsPerMonth,
    totalAudits,
    totalActions,
    totalUnsafe,
    totalCompliance: totalComp.reduce((curr, x) => curr + x, 0) / totalAudits,
  };
};

interface Filters {
  users: SelectOptionObject[];
  form: SelectOption;
  companies: SelectOptionObject[];
  crews: SelectOptionObject[];
  sites: SelectOptionObject[];
}

interface Props extends WithNamespaces {
  companyGroup?: CompanyGroup;
  company?: Company;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
  companies?: Company[];
  Widget: React.ComponentType;
  getWidgetProps: any;
  start: Moment;
  end: Moment;
  showDownloads: () => any;
}

const AuditDashboardMain = ({
  Widget,
  getWidgetProps,
  companyGroup,
  company,
  users,
  crews,
  forms,
  sites,
  companies,
  start,
  end,
  showDownloads,
}: Props) => {
  const [filters, setFilters] = React.useState<Filters>({
    users: [],
    form: null,
    crews: [],
    sites: [],
    companies: [],
  });

  const [stats, setStats] = React.useState({
    auditsPerEntity: [],
    compliancePerEntity: [],
    auditsPerMonth: [],
    actionsPerMonth: [],
    totalAudits: 0,
    totalActions: 0,
    totalUnsafe: 0,
    totalCompliance: 0,
  });
  const [loading, setLoading] = React.useState(false);

  const updateStats = (newStats: any) =>
    setStats(prev => ({ ...prev, ...newStats }));

  const isCompany = company ? true : false;
  const scope = isCompany ? company : companyGroup;
  const entityAccessor = isCompany ? getSiteLabel() : 'company';

  if (!scope) {
    return null;
  }

  const formatFilters = () => ({
    formUids: filters.form ? [filters.form.value] : undefined,
    companyUids: filters.companies.map(v => v.value),
    userUids: filters.users.map(v => v.value),
    siteUids: filters.sites.map(v => v.value),
    crewUids: filters.crews.map(v => v.value),
    start: start ? start.unix() : undefined,
    end: end ? end.unix() : undefined,
  });

  const fetchStats = async () => {
    setLoading(true);

    const formattedFilters = formatFilters();
    const endpoint = isCompany
      ? companyApi.auditStats
      : companyGroupApi.auditStats;
    const { data } = await endpoint(scope.uid, formattedFilters);

    if (data) {
      const n = calculateAuditCharts(data.auditStats, entityAccessor);
      updateStats(n);
    }

    setLoading(false);
  };

  React.useEffect(
    () => {
      fetchStats();
    },
    [scope.uid, filters, start, end],
  );

  const onFilterChange = (newFilters: any) => {
    setFilters({
      ...filters,
      ...newFilters,
    });
  };

  const onExport = async () => {
    setLoading(true);
    const endpoint = isCompany
      ? companyApi.exportAudits
      : companyGroupApi.exportAudits;
    await endpoint(scope.uid, formatFilters());
    showDownloads();
    setLoading(false);
  };

  const entities = {
    users,
    forms,
    sites,
    crews,
    ...(isCompany ? {} : { companies }),
  };

  const widgetHeight = '500px';

  const {
    totalAudits,
    totalUnsafe,
    totalCompliance,
    totalActions,
    auditsPerEntity,
    auditsPerMonth,
    compliancePerEntity,
    actionsPerMonth,
  } = stats;

  return (
    <div>
      <div className="flex flex-col md:flex-row">
        <CardWrapper>
          <SummaryCard
            className={'bg-default'}
            icon={Alarm}
            title={'Audits'}
            stat={totalAudits}
          />
        </CardWrapper>
        <CardWrapper>
          <SummaryCard
            className={'bg-primary'}
            icon={Alarm}
            title={'Not Safe'}
            stat={totalUnsafe}
          />
        </CardWrapper>
        <CardWrapper>
          <SummaryCard
            className={'bg-default'}
            icon={Alarm}
            title={'Actions'}
            stat={totalActions}
          />
        </CardWrapper>
        <CardWrapper>
          <SummaryCard
            className={'bg-primary'}
            icon={Alarm}
            title={'Compliance'}
            stat={`${
              isNaN(totalCompliance) ? '-' : `${totalCompliance.toFixed(2)} %`
            }`}
          />
        </CardWrapper>
      </div>

      <Widget {...getWidgetProps()}>
        {() => (
          <div>
            <div className="flex row justify-end p-4">
              <Button type="default" onClick={onExport} loading={loading}>
                Export Data
              </Button>
            </div>

            <EntityFilter
              onFilterChange={onFilterChange}
              entities={entities}
              filters={filters}
            />

            <Loading loading={loading} />

            <div className="flex flex-col md:flex-row p-4">
              <div
                className="flex-1 border-1 border-border border-solid mb-4"
                style={{ height: widgetHeight }}
              >
                <h3 className="text-center mt-4">
                  Audits Per {entityAccessor}
                </h3>
                {auditsPerEntity.length === 0 ? (
                  <div className="flex h-full justify-center items-center">
                    <p className="text-4xl">NO DATA</p>
                  </div>
                ) : (
                  <ResponsiveContainer width={'99%'} height="89%">
                    <BarChart
                      data={auditsPerEntity}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey={entityAccessor}
                        tick={{ transform: 'rotate(90deg)' }}
                        angle={-45}
                        textAnchor="end"
                        interval={0}
                        height={150}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
              <div
                className="flex-1 border-1 border-border border-solid mb-4 md:ml-4"
                style={{ height: widgetHeight }}
              >
                <h3 className="text-center mt-4">Audits Per Month</h3>
                {totalAudits === 0 ? (
                  <div className="flex h-full justify-center items-center">
                    <p className="text-4xl">NO DATA</p>
                  </div>
                ) : (
                  <ResponsiveContainer width={'99%'} height="89%">
                    <BarChart
                      data={auditsPerMonth}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" height={150} />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>

            <div className="flex flex-col md:flex-row p-4">
              <div
                className="flex-1 border-1 border-border border-solid mb-4"
                style={{ height: widgetHeight }}
              >
                <h3 className="text-center mt-4">
                  Compliance Per {entityAccessor}
                </h3>
                {compliancePerEntity.length === 0 ? (
                  <div className="flex h-full justify-center items-center">
                    <p className="text-4xl">NO DATA</p>
                  </div>
                ) : (
                  <ResponsiveContainer width={'99%'} height="89%">
                    <BarChart
                      data={compliancePerEntity}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey={entityAccessor}
                        tick={{ transform: 'rotate(90deg)' }}
                        angle={-45}
                        textAnchor="end"
                        interval={0}
                        height={150}
                      />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
              <div
                className="flex-1 border-1 border-border border-solid mb-4 md:ml-4"
                style={{ height: widgetHeight }}
              >
                <h3 className="text-center mt-4">Actions Per Month</h3>
                {totalAudits === 0 ? (
                  <div className="flex h-full justify-center items-center">
                    <p className="text-4xl">NO DATA</p>
                  </div>
                ) : (
                  <ResponsiveContainer width={'99%'} height="89%">
                    <BarChart
                      data={actionsPerMonth}
                      margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis dataKey="month" height={150} />
                      <YAxis />
                      <Tooltip />
                      <Bar dataKey="value" fill="#8884d8" />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </div>
            </div>
          </div>
        )}
      </Widget>
    </div>
  );
};

export default withNamespaces()(
  connect(
    null,
    (dispatch: Dispatch) => ({
      showDownloads: () => dispatch(openDownloads()),
    }),
  )(AuditDashboardMain),
);

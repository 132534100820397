import * as React from 'react';
import { Widget, BaseResponse } from '../../types';

interface Props {
  widget: Widget;
  response: BaseResponse;
}

const Title = ({ widget }: Props) => {
  return (
    <div className="my-4 p-4 bg-grey-dark rounded">
      <h1>{widget.title}</h1>
    </div>
  );
};

export default Title;

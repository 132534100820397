import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../../constants';

const Section = styled.section`
  padding: ${cssVars.padding[5]};
`;

export default class SideboxSectionContent extends React.Component {
  public render() {
    return <Section>{this.props.children}</Section>;
  }
}

import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const ThumbsUp = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M11 0h1v3l3 7v8a2 2 0 0 1-2 2H5c-1.1 0-2.31-.84-2.7-1.88L0 12v-2a2 2 0 0 1 2-2h7V2a2 2 0 0 1 2-2zm6 10h3v10h-3V10z" />
  </svg>
);

export default BaseSVG(ThumbsUp);

import * as React from 'react';
import VerticalMenu from '../../../svg/VerticalMenu';
import { Storage } from '../../../types';
import FileIcon from '../../../svg/File';
import PrivateImage from '../../../components/PrivateImage';
import { ConnectDragSource, DragSource, DragSourceConnector } from 'react-dnd';
import { dragDropTypes } from '../../../constants/index';

interface Props {
  storage: Storage;
  onMenuClick: (storageUid: string) => void;
  selected?: boolean;
  onClick?: (uid: string) => void;
  drag?: boolean;
  connectDragSource?: ConnectDragSource;
}

class File extends React.Component<Props> {
  public render() {
    const {
      storage,
      onMenuClick,
      onClick,
      selected,
      drag,
      connectDragSource,
    } = this.props;

    const handleMenuClick = (e: React.FormEvent<HTMLButtonElement>) => {
      e.stopPropagation();

      onMenuClick(storage.uid);
    };

    const handleClick = () => {
      if (onClick) {
        onClick(storage.uid);
      }
    };

    const dragFunc = drag ? connectDragSource : (input: any) => input;

    return dragFunc(
      <div
        className="flex flex-col shadow-md"
        style={{ width: '184px' }}
        onClick={handleClick}
      >
        <div>
          <PrivateImage storage={storage} />
        </div>
        <div
          className={`p-4 flex flex-row items-center justify-between cursor-pointer ${
            selected
              ? 'hover:bg-grey-dark bg-grey-darker'
              : 'hover:bg-grey-light bg-grey-lighter'
          }`}
        >
          <FileIcon className={'w-6 h-6 mr-4'} />
          <div className={'mr-4'}>
            <p
              style={{
                maxWidth: '100px',
                textOverflow: 'ellipsis',
                overflow: 'hidden',
                whiteSpace: 'nowrap',
              }}
            >
              {storage.name}
            </p>
            <p className={'text-grey-darker'}>
              {((storage.size || 0) / 1024).toFixed(0)} KB
            </p>
          </div>
          <VerticalMenu
            className={'w-6 h-6 cursor-pointer hover:text-red'}
            onClick={handleMenuClick}
          />
        </div>
      </div>,
    );
  }
}

const fileSource = {
  beginDrag: (props: Props) => ({
    uid: props.storage.uid,
  }),
};

const dragCollect = (connect: DragSourceConnector) => ({
  connectDragSource: connect.dragSource(),
});

export default DragSource(dragDropTypes.FILE, fileSource, dragCollect)(File);

import * as React from 'react';
import { User } from '../types';
import Dropzone from 'react-dropzone';
import { useState } from 'react';
import { toast } from 'react-toastify';
import formService from '../services/forms.service';
import cloudinaryService from '../services/cloudinary.service';
import { getFullName } from '../helpers';
import Loading from './Loading';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setUser as setUserAction } from '../actions/me';
import { StoreState } from '../store';

interface Props {
  user: User;
  setUser: (user: User) => void;
}

function ProfilePicture({ user, setUser }: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const [imgSrc, setImgSrc] = useState<string>(
    user.profile_url || '/images/profile.jpg',
  );

  function onResult() {
    setLoading(false);
  }

  function onError() {
    toast.error('Error changing profile picture!');
    return onResult();
  }

  function onSuccess(updatedUser: User) {
    toast.success('Successfully updated profile picture!');
    setUser(updatedUser);
    return onResult();
  }

  async function handleDrop(files: File[]) {
    setLoading(true);
    const [file] = files;
    if (!file) {
      return onError();
    }
    const {
      data: signatureData,
      errors: signatureErrors,
    } = await formService.getSignatureFile(user.uid);
    if (!signatureData || signatureErrors) {
      return onError();
    }
    const cloudinaryImage = await cloudinaryService.uploadSigned(
      file,
      signatureData,
    );
    if (cloudinaryImage === null) {
      return onError();
    }
    const { url, id } = cloudinaryImage;
    const {
      data: updateProfileData,
      errors: updateProfileErrors,
    } = await formService.updateProfilePicture(user.uid, {
      profile_id: id,
      profile_url: url,
    });
    if (!updateProfileData || updateProfileErrors) {
      return onError();
    }
    setImgSrc(url);
    return onSuccess(updateProfileData.user);
  }

  return (
    <Dropzone
      onDrop={handleDrop}
      accept={'image/*'}
      children={({ getRootProps, getInputProps }) => {
        return (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            <div
              className="relative flex items-center justify-center"
              style={{ height: 300, width: 300 }}
            >
              <Loading loading={loading} />
              <img
                src={imgSrc}
                alt={getFullName(user)}
                style={{
                  maxHeight: '300px',
                  maxWidth: '300px',
                }}
              />
            </div>
          </div>
        );
      }}
    />
  );
}

function mapState(state: StoreState) {
  return {
    user: state.me.user,
  };
}

const mapDispatch = (dispatch: Dispatch) => ({
  setUser: (user: User) => dispatch(setUserAction(user)),
});

export default connect(
  mapState,
  mapDispatch,
)(ProfilePicture);

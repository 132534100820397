import * as React from 'react';
import styled from 'styled-components';
import { cssVars, MAX_NAME_LENGTH } from '../../constants/index';
import { biggerThanMD } from '../../helpers/style';
import {
  useContentEditable,
  handleContentLengthErrors,
} from '../../helpers/hooks';
import { useState, FormEvent, useEffect } from 'react';
import WidgetContent from '../form/WidgetContent';

import {
  Wrapper,
  ButtonWrapper,
  FormFabricatorProps,
  useWidgetMandatory,
  useWidgetChange,
  InspectionLabel,
} from './common';
import BuilderButtons from './BuilderButtons';
import { onContentEditablePaste } from '../../helpers';

const AnswerButton = styled.div<{ bg?: string; error?: boolean }>`
  background: ${props => (props.bg ? props.bg : cssVars.colors.border)};
  border: none;
  outline: ${props => (props.error ? 'solid 2px red' : 'none')}
  border-right: ${props =>
    props.error ? '2px solid red' : `1px solid ${cssVars.colors.default}`};
  padding: ${cssVars.padding[4]};
  cursor: pointer;
  flex: 1 1 0%;
  display: block;

  ${biggerThanMD(`
    flex: 1;
  `)} &:last-of-type {
    border-right: 0;
  }
`;

type Props = FormFabricatorProps;

const maxAnswerLength = 16;

const Inspection = ({
  widget,
  formRevisionUid,
  setWidgets,
  mandatory = false,
  openDelete,
  editErrors,
  setEditErrors,
}: Props) => {
  const errorStates = {
    tooShort: 'tooShort',
    correct: 'correct',
    tooLong: 'tooLong',
  };
  const [labelErrors, setLabelErrors] = useState({
    positive: errorStates.correct,
    negative: errorStates.correct,
    neutral: errorStates.correct,
  });
  const [inspectionLabelTooLong, setInspectionLabelTooLong] = useState<boolean>(
    false,
  );
  const [inspectionLabelTooShort, setInspectionLabelTooShort] = useState<
    boolean
  >(false);

  const isTooLong =
    labelErrors.positive === errorStates.tooLong ||
    labelErrors.negative === errorStates.tooLong ||
    labelErrors.neutral === errorStates.tooLong ||
    inspectionLabelTooLong;
  const isTooShort =
    labelErrors.positive === errorStates.tooShort ||
    labelErrors.negative === errorStates.tooShort ||
    labelErrors.neutral === errorStates.tooShort ||
    inspectionLabelTooShort;

  const title = widget.title;
  const positiveAnswer = widget.positive_label;
  const negativeAnswer = widget.negative_label;
  const neutralAnswer = widget.neutral_label;

  const { checked, handleMandatoryChange } = useWidgetMandatory({
    widget,
    formRevisionUid,
    mandatory,
  });

  const idLookup = {
    [`inspection-label-${widget.uid}`]: 'title',
    [`positive-answer-${widget.uid}`]: 'positive_label',
    [`negative-answer-${widget.uid}`]: 'negative_label',
    [`neutral-answer-${widget.uid}`]: 'neutral_label',
  };

  const { handleWidgetChange } = useWidgetChange({
    formRevisionUid,
    setWidgets,
    widget,
  });

  function handleInspectionLabelChange(id: string) {
    const el = document.getElementById(id);
    if (!el || !el.textContent) {
      return;
    }
    if (isTooShort || isTooLong) {
      return;
    }
    handleWidgetChange({ [idLookup[id]]: el.textContent });
  }

  const { handleDone, handleBlurEvent } = useContentEditable(
    'title',
    handleWidgetChange,
  );

  function handleAnswerChange(e: FormEvent<HTMLDivElement>, id: string) {
    const answerLabel = e.currentTarget;
    const tooLong = answerLabel.innerText.length >= maxAnswerLength;
    const tooShort = answerLabel.innerText.length === 0;
    let state = errorStates.correct;
    if (tooLong) {
      state = errorStates.tooLong;
    }
    if (tooShort) {
      state = errorStates.tooShort;
    }
    const newErrors = {
      ...labelErrors,
      [id]: state,
    };

    setLabelErrors(newErrors);
  }

  useEffect(
    () => {
      if (isTooShort || isTooLong) {
        setEditErrors([...editErrors, widget.uid]);
        return;
      }
      setEditErrors(editErrors.filter(uid => uid !== widget.uid));
    },
    [isTooLong, isTooShort],
  );

  return (
    <WidgetContent>
      <div className={'flex flex-col mb-4'}>
        <p className={isTooLong ? 'flex justify-end text-red mr-8' : 'hidden'}>
          Maximum {inspectionLabelTooLong ? MAX_NAME_LENGTH : maxAnswerLength}{' '}
          characters
        </p>
        <p className={isTooShort ? 'flex justify-end text-red mr-8' : 'hidden'}>
          Cannot leave labels empty!
        </p>
        <Wrapper>
          <InspectionLabel
            contentEditable={true}
            onInput={(event: React.KeyboardEvent<any>) =>
              handleContentLengthErrors(
                event,
                MAX_NAME_LENGTH,
                inspectionLabelTooLong,
                inspectionLabelTooShort,
                setInspectionLabelTooLong,
                setInspectionLabelTooShort,
                handleDone,
              )
            }
            error={inspectionLabelTooLong || inspectionLabelTooShort}
            onPaste={(e: any) => onContentEditablePaste(e)}
            onBlur={handleBlurEvent}
          >
            {title}
          </InspectionLabel>
          <ButtonWrapper className="flex flex-row">
            <AnswerButton
              id={`positive-answer-${widget.uid}`}
              bg={''}
              error={
                labelErrors.positive === errorStates.tooLong ||
                labelErrors.positive === errorStates.tooShort
              }
              onInput={e => handleAnswerChange(e, 'positive')}
              onPaste={(e: any) => onContentEditablePaste(e)}
              contentEditable={true}
              onBlur={() =>
                handleInspectionLabelChange(`positive-answer-${widget.uid}`)
              }
            >
              {positiveAnswer}
            </AnswerButton>
            <AnswerButton
              id={`negative-answer-${widget.uid}`}
              bg={''}
              error={
                labelErrors.negative === errorStates.tooLong ||
                labelErrors.negative === errorStates.tooShort
              }
              onInput={e => handleAnswerChange(e, 'negative')}
              onPaste={(e: any) => onContentEditablePaste(e)}
              contentEditable={true}
              onBlur={() =>
                handleInspectionLabelChange(`negative-answer-${widget.uid}`)
              }
            >
              {negativeAnswer}
            </AnswerButton>
            <AnswerButton
              id={`neutral-answer-${widget.uid}`}
              bg={''}
              error={
                labelErrors.neutral === errorStates.tooLong ||
                labelErrors.neutral === errorStates.tooShort
              }
              onInput={e => handleAnswerChange(e, 'neutral')}
              onPaste={(e: any) => onContentEditablePaste(e)}
              contentEditable={true}
              onBlur={() =>
                handleInspectionLabelChange(`neutral-answer-${widget.uid}`)
              }
            >
              {neutralAnswer}
            </AnswerButton>
          </ButtonWrapper>
        </Wrapper>
      </div>
      <BuilderButtons
        handleMandatoryChange={handleMandatoryChange}
        checked={checked}
        openDelete={openDelete}
        id={widget.uid}
      />
    </WidgetContent>
  );
};

export default Inspection;

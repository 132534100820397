import * as React from 'react';
import Button from '../../components/Button';
import { FormOption } from '../../types';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import {
  addFormOption,
  removeFormOption,
  updateFormOption,
} from '../../actions/formOptions';
import {
  Container,
  HeaderWrapper,
  DragWrapper,
  HeaderActions,
  HeaderAction,
  Header,
  Body,
  TitleInput,
  DragHandler,
} from './optionParentStyles';
import Row from './Row';
import { makeGetOption, makeGetChildren } from '../../selectors/formOptions';
import formOptionApi from '../../api/formOption.api';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { formFeatureIds, widgetsForDroppable } from '../../constants';
import { getOptionTitle } from '../../helpers/formOption';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import contains from 'ramda/es/contains';

interface State {
  editing: boolean;
  title: string;
}

interface Props extends WithNamespaces {
  optionId: number;
  updateFormOption?: (option: FormOption) => any;
  option?: FormOption;
  childOptions?: FormOption[];
  openAddOption?: (optionId: number) => void;
  removeFormOption?: (optionId: number) => any;
  addFormOption?: (option: FormOption) => any;
  openDeleteOption?: (optionId: number) => any;
  duplicateOption?: (optionId: number) => any;
  formUid: string;
  index: number;
  fid?: number;
}

class Section extends React.PureComponent<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
      title: '',
    };
  }

  private startEditing = () => {
    this.setState({
      editing: true,
      title: getOptionTitle(this.props.option),
    });
  };

  private stopEditing = async () => {
    this.setState({
      editing: false,
    });

    const { option } = this.props;

    if (!option) {
      return;
    }

    this.props.updateFormOption!({
      ...option,
      title_rev: this.state.title,
    });

    try {
      await formOptionApi.patch(this.props.optionId, {
        title: this.state.title,
      });
    } catch (e) {
      //
    }
  };

  private cancelEditing = () => {
    this.setState({
      editing: false,
    });
  };

  private onTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      title: e.currentTarget.value,
    });
  };

  private renderRows = () => {
    const {
      childOptions,
      openAddOption,
      openDeleteOption,
      duplicateOption,
      formUid,
    } = this.props;

    return (
      childOptions &&
      childOptions.map((o, i) => (
        <Row
          key={o.id}
          optionId={o.id}
          formUid={formUid}
          openAddOption={openAddOption}
          openDeleteOption={openDeleteOption}
          duplicateOption={duplicateOption}
          index={i}
        />
      ))
    );
  };

  private addRow = async () => {
    if (!this.props.addFormOption) {
      return;
    }

    try {
      const { option: row } = await formOptionApi.create({
        formUid: this.props.formUid,
        typeId: formFeatureIds.ROW,
        parentId: this.props.optionId,
      });

      const { option: column } = await formOptionApi.create({
        formUid: this.props.formUid,
        typeId: formFeatureIds.COL,
        parentId: row.id,
      });

      this.props.addFormOption(row);
      this.props.addFormOption(column);
    } catch (e) {
      //
    }
  };

  private openConfirmDelete = () => {
    if (this.props.openDeleteOption) {
      this.props.openDeleteOption(this.props.optionId);
    }
  };

  private duplicateOption = () => {
    if (this.props.duplicateOption) {
      this.props.duplicateOption(this.props.optionId);
    }
  };

  public render() {
    const { option, t, index, fid } = this.props;
    const { editing } = this.state;

    if (!option) {
      return null;
    }

    return (
      <Draggable draggableId={`${option.id}`} index={index}>
        {provided => (
          <Container
            className={'bg-white'}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <HeaderWrapper className={'bg-almost-black'}>
              <DragWrapper
                className={'text-white'}
                {...provided.dragHandleProps}
              >
                <DragHandler />
              </DragWrapper>
              <Header className={'text-white'}>
                {editing ? (
                  <React.Fragment>
                    <TitleInput
                      value={this.state.title}
                      onChange={this.onTitleChange}
                      placeholder={'Section name...'}
                    />
                    <HeaderActions>
                      <HeaderAction
                        onClick={this.cancelEditing}
                        className={'bg-default text-white'}
                      >
                        {t('super:screens.form.cancel')}
                      </HeaderAction>
                      <HeaderAction
                        onClick={this.stopEditing}
                        className={'bg-success text-white'}
                      >
                        {t('super:screens.form.done')}
                      </HeaderAction>
                    </HeaderActions>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getOptionTitle(option) || 'Section'}
                    <HeaderActions>
                      <HeaderAction
                        onClick={this.startEditing}
                        className={'text-white bg-transparent'}
                      >
                        {t('super:screens.form.edit')}
                      </HeaderAction>
                      <HeaderAction
                        className={'text-white bg-transparent'}
                        onClick={this.duplicateOption}
                      >
                        {t('super:screens.form.duplicate')}
                      </HeaderAction>
                      <HeaderAction
                        color="primary"
                        className={'text-white bg-primary'}
                        onClick={this.openConfirmDelete}
                      >
                        {t('super:screens.form.delete')}
                      </HeaderAction>
                    </HeaderActions>
                  </React.Fragment>
                )}
              </Header>
            </HeaderWrapper>
            <Droppable
              droppableId={`${option.id}`}
              isDropDisabled={!contains(fid, widgetsForDroppable[option.f_id])}
            >
              {drop => (
                <Body ref={drop.innerRef} {...drop.droppableProps}>
                  <div>{this.renderRows()}</div>
                  <hr />
                  <div className="flex justify-center mt-4">
                    <Button type="default" onClick={this.addRow}>
                      {t('super:screens.form.addRow')}
                    </Button>
                  </div>
                  {drop.placeholder}
                </Body>
              )}
            </Droppable>
          </Container>
        )}
      </Draggable>
    );
  }
}

const makeMapStateToProps = () => {
  const getOption = makeGetOption();
  const getChildren = makeGetChildren();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    childOptions: getChildren(state, ownProps),
    fid: state.formOptions.draggingFid,
  });
};

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateFormOption: (option: FormOption) =>
      dispatch(updateFormOption(option)),
    removeFormOption: (optionId: number) =>
      dispatch(removeFormOption(optionId)),
    addFormOption: (option: FormOption) => dispatch(addFormOption(option)),
  };
}

export default withNamespaces()(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  )(Section),
);

import * as React from 'react';
import BaseWidget, { RenderChildrenProps } from './BaseWidget';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import styled from 'styled-components';
import Select, { SelectOptionObject } from '../../components/Select';
import { cssVars, formFeatureIds, widgetsForDroppable } from '../../constants';
import { TranslationFunction } from 'i18next';
import AuditChild from './AuditChild';
import { parseAuditText } from '../../helpers/formOption';
import { HeaderAction } from './optionParentStyles';
import formOptionApi from '../../api/formOption.api';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormOption } from '../../types';
import { addFormOption } from '../../actions/formOptions';
import { Droppable } from 'react-beautiful-dnd';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { contains } from 'ramda';

const FilterRow = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 15px;
`;

const FilterColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0 6px;

  & > input {
    margin: 0 4px;
  }
`;

const Input = styled.input`
  padding: ${cssVars.padding[3]};
`;

interface Props extends WithNamespaces {
  optionId: number;
  openDeleteOption?: (optionId: number) => any;
  formUid: string;
  index: number;
  addFormOption: (option: FormOption) => any;
  fid?: number;
  option?: FormOption;
}

const makePointOptions = () =>
  Array.from({ length: 101 }).map((_, i) => ({ label: `${i}`, value: `${i}` }));

const makeLabelOptions = (t: TranslationFunction) => [
  { label: t('forms:safeUnsafe'), value: 'safe_unsafe' },
  { label: t('forms:passFail'), value: 'pass_fail' },
  { label: t('forms:satUnsat'), value: 'sat_unsat' },
];

class Audit extends React.Component<Props> {
  private renderChildren = ({
    optionText,
    onTextChange,
    text,
    editing,
    childOptions,
  }: RenderChildrenProps) => {
    const { t, option, fid } = this.props;
    if (!option) {
      return;
    }
    const pointOptions = makePointOptions();
    const labelOptions = makeLabelOptions(t);

    const parsedText = parseAuditText(editing ? text : optionText, t);
    const { ranks, rank_labels, label } = parsedText;

    const selectedLabel =
      labelOptions.find(i => i.value === label) || labelOptions[0];

    const lowSelected = pointOptions.find(i => i.value === `${ranks.low}`);
    const mediumSelected = pointOptions.find(
      i => i.value === `${ranks.medium}`,
    );
    const highSelected = pointOptions.find(i => i.value === `${ranks.high}`);
    const extremeSelected = pointOptions.find(
      i => i.value === `${ranks.extreme}`,
    );

    const disabled = !editing;

    const onRankChange = (key: string, value: any) => {
      onTextChange(
        JSON.stringify({
          ...parsedText,
          ranks: { ...parsedText.ranks, [key]: value },
        }),
      );
    };

    return (
      <div>
        <div className={'mb-4'}>
          <div className={'w-1/4 flex flex-row items-center'}>
            <label className={'mr-2'}>{t('forms:label')}:</label>
            <Select
              options={labelOptions}
              value={selectedLabel}
              disabled={disabled}
              onChange={(e: SelectOptionObject) =>
                onTextChange(
                  JSON.stringify({
                    ...parsedText,
                    label: e.value,
                  }),
                )
              }
            />
          </div>
        </div>
        <FilterRow>
          <FilterColumn>
            <label>{t('forms:low')}:</label>
            <Input
              placeholder={t('forms:low')}
              value={rank_labels.low}
              disabled={disabled}
              onChange={e =>
                onTextChange(
                  JSON.stringify({
                    ...parsedText,
                    rank_labels: {
                      ...parsedText.rank_labels,
                      low: e.currentTarget.value,
                    },
                  }),
                )
              }
            />
            <Select
              options={pointOptions}
              value={lowSelected}
              disabled={disabled}
              onChange={(e: any) => onRankChange('low', e.value)}
            />
          </FilterColumn>

          <FilterColumn>
            <label>{t('forms:medium')}:</label>
            <Input
              placeholder={t('forms:medium')}
              disabled={disabled}
              value={rank_labels.medium}
              onChange={e =>
                onTextChange(
                  JSON.stringify({
                    ...parsedText,
                    rank_labels: {
                      ...parsedText.rank_labels,
                      medium: e.currentTarget.value,
                    },
                  }),
                )
              }
            />
            <Select
              options={pointOptions}
              value={mediumSelected}
              disabled={disabled}
              onChange={(e: any) => onRankChange('medium', e.value)}
            />
          </FilterColumn>

          <FilterColumn>
            <label>{t('forms:high')}:</label>
            <Input
              placeholder={t('forms:high')}
              disabled={disabled}
              value={rank_labels.high}
              onChange={e =>
                onTextChange(
                  JSON.stringify({
                    ...parsedText,
                    rank_labels: {
                      ...parsedText.rank_labels,
                      high: e.currentTarget.value,
                    },
                  }),
                )
              }
            />
            <Select
              options={pointOptions}
              value={highSelected}
              disabled={disabled}
              onChange={(e: any) => onRankChange('high', e.value)}
            />
          </FilterColumn>

          <FilterColumn>
            <label>{t('forms:extreme')}:</label>
            <Input
              placeholder={'Extreme'}
              disabled={disabled}
              value={rank_labels.extreme}
              onChange={e =>
                onTextChange(
                  JSON.stringify({
                    ...parsedText,
                    rank_labels: {
                      ...parsedText.rank_labels,
                      extreme: e.currentTarget.value,
                    },
                  }),
                )
              }
            />
            <Select
              options={pointOptions}
              value={extremeSelected}
              disabled={disabled}
              onChange={(e: any) => onRankChange('extreme', e.value)}
            />
          </FilterColumn>
        </FilterRow>

        <hr />

        <Droppable
          droppableId={`${option.id}`}
          isDropDisabled={!contains(fid, widgetsForDroppable[option.f_id])}
        >
          {provided => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={
                childOptions ? (childOptions.length === 0 ? 'p-8' : '') : ''
              }
            >
              {childOptions &&
                childOptions.map((id, i) => (
                  <AuditChild
                    key={id}
                    optionId={id}
                    index={i}
                    openDeleteOption={this.props.openDeleteOption}
                  />
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </div>
    );
  };

  private addQuestion = async () => {
    const { formUid, optionId: parentId } = this.props;

    try {
      const { option } = await formOptionApi.create({
        formUid,
        parentId,
        typeId: formFeatureIds.AUDIT_ITEM,
      });

      if (this.props.addFormOption) {
        this.props.addFormOption(option);
      }
    } catch (e) {
      //
    }
  };

  public render() {
    const { optionId, t, openDeleteOption, index } = this.props;

    return (
      <BaseWidget
        optionId={optionId}
        defaultTitle={t('common:formWidgets.audit')}
        renderChildren={this.renderChildren}
        openDeleteOption={openDeleteOption}
        index={index}
        extraActions={[
          <HeaderAction key={0} onClick={this.addQuestion}>
            {t('super:screens.form.addQuestion')}
          </HeaderAction>,
        ]}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addFormOption: (option: FormOption) => dispatch(addFormOption(option)),
});

function makeMapStateToProps() {
  const getOption = makeGetOption();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    fid: state.formOptions.draggingFid,
  });
}

export default withNamespaces()(
  connect(makeMapStateToProps, mapDispatchToProps)(Audit),
);

import api from './api';

interface DeploymentUpdateData {
  storageUid?: string;
  reminder?: boolean;
  reminderData?: {
    type: string;
    days?: number[];
    weekdays?: string[];
    time: string;
    weekend: boolean;
  };
  alert?: boolean;
  alertData?: {
    userUids: string[];
    pdf: boolean;
  };
  deployment?: {
    approval: boolean;
    type: string;
    all: boolean;
    customUids?: string[];
  };
}

const deploymentApi = {
  update: (deploymentUid: string, data: DeploymentUpdateData) =>
    api('PATCH', `deployments/${deploymentUid}`, data),
};

export default deploymentApi;

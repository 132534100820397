import * as React from 'react';
import Modal from '../../components/Modal';
import ModalHeader from '../../components/modal/ModalHeader';
import ModalBody from '../../components/modal/ModalBody';
import ModalFooter from '../../components/modal/ModalFooter';
import Button from '../../components/Button';
import File from '../../svg/File';
import { useSimpleFetch } from '../../helpers/hooks';
import conversationApi from '../../api/conversation.api';
import { Storage } from '../../types';
import { getPrivateUrlFromUid } from '../../components/PrivateImage';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  conversationUid: string;
}

const MessageAttachments = ({
  handleClose,
  isOpen,
  conversationUid,
}: Props) => {
  const uploads = useSimpleFetch<Storage[]>({
    initial: [],
    change: [conversationUid],
    accessor: 'files',
    fetch: () => conversationApi.uploads(conversationUid),
  });

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Message Attachments"
    >
      <ModalHeader handleClose={handleClose}>Attachments</ModalHeader>
      <ModalBody>
        <div>
          {uploads.value.map(upload => (
            <a
              href={getPrivateUrlFromUid(upload.uid)}
              target={'_blank'}
              className="flex flex-row items-center text-black no-underline px-2 py-4 hover:underline"
            >
              <File className={'w-6 h-6 mr-4'} />
              <p>
                {upload.name}.{upload.ext}
              </p>
            </a>
          ))}
          {uploads.value.length === 0 && (
            <p>This conversation has no uploads</p>
          )}
        </div>
      </ModalBody>
      <ModalFooter right={true}>
        <Button type={'default'} onClick={handleClose}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default MessageAttachments;

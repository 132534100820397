import * as React from 'react';
import { useState, useEffect } from 'react';
import * as R from 'ramda';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import InlineSelect from '../../../components/InlineSelect';
import NewsPaper from '../../../svg/NewsPaper';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Company, Form, Storage, Site } from '../../../types';
import { toast } from 'react-toastify';
import SelectFolder from './forms/SelectFolder';
import Deployment from './forms/Deployment';
import SideboxSectionContent from '../../../components/sidebox/SideboxSectionContent';
import siteApi from '../../../api/site.api';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import storageApi from '../../../api/storage.api';
import {
  useOptionalSelectInput,
  useMultiSelectInput,
  useEntityRefresh,
} from '../../../helpers/hooks';
import { UsersState } from '../../../reducers/users';
import { CrewsState } from '../../../reducers/crews';
import { FormsState } from '../../../reducers/forms';
import { Dispatch } from 'redux';
import { formsFetchRequest } from '../../../actions/forms';
import { crewsFetchRequest } from '../../../actions/crews';
import { usersFetchRequest } from '../../../actions/users';
import { getSiteLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  site: Site;
  onComplete: (form: Form) => void;
  forms: FormsState;
  users: UsersState;
  crews: CrewsState;
  getForms: (companyUid: string) => void;
  getCrews: (companyUid: string) => void;
  getUsers: (companyUid: string) => void;
  siteForms: Form[];
}

const AddForms = ({
  company,
  forms,
  crews,
  users,
  isOpen,
  handleClose,
  t,
  getCrews,
  getForms,
  getUsers,
  site,
  onComplete,
  siteForms,
}: Props) => {
  useEntityRefresh(company.uid, forms, getForms);
  useEntityRefresh(company.uid, crews, getCrews);
  useEntityRefresh(company.uid, users, getUsers);

  const formData = forms.data.filter(({ v2 }) => !v2);

  const formSelect = useOptionalSelectInput();
  const deploymentType = useOptionalSelectInput();
  const deploymentAudience = useOptionalSelectInput();
  const deploymentCustom = useMultiSelectInput();
  const [folder, setFolder] = useState<Storage | null>(null);
  const [path, setPath] = useState<Storage[]>([]);
  const [errors, setErrors] = useState<{ form?: string }>({});
  const [loading, setLoading] = useState(false);

  const form = formData.find(x => x.uid === formSelect.selected);

  const getPath = async (folder: Storage) => {
    const { data } = await storageApi.show(folder.uid);
    if (data) {
      setPath(data.path);
    }
  };

  const onFolderSelect = (f: Storage) => {
    setFolder(f);
    getPath(f);
  };

  const isDeploymentValid = () => {
    if (
      !deploymentType.selected ||
      (deploymentType.selected !== 'site' && !deploymentAudience.selected) ||
      (deploymentType.selected !== 'site' &&
        (deploymentAudience
          ? deploymentAudience.selected === 'custom'
          : false) &&
        deploymentCustom.selected.length === 0)
    ) {
      return false;
    }

    return true;
  };

  const isFormValid = () => {
    return formSelect.selected && folder && isDeploymentValid();
  };

  const isFormInvalid = () => !isFormValid();

  const submitV1 = async () => {
    if (!form || !deploymentType.selected) {
      return { errors: {}, data: undefined };
    }
    return await siteApi.attachForm(site.uid, form.uid, {
      storageUid: folder!.uid,
      deployment: {
        approval: false,
        type: deploymentType.selected,
        all:
          deploymentType.selected === 'site'
            ? true
            : deploymentAudience
              ? deploymentAudience.selected === 'all'
              : false,
        customUids: R.map(R.prop('value'), deploymentCustom.selected),
      },
    });
  };

  const submit = async () => {
    if (!form) {
      return;
    }

    setLoading(true);

    const { data, errors: err } = await submitV1();

    if (data) {
      onComplete(form);
      formSelect.onChange(null);
      setFolder(null);
      deploymentType.onChange(null);
    }

    if (err) {
      setErrors(err);
      toast.error(`Failed to add form to ${getSiteLabel().toLowerCase()}`);
    }

    setLoading(false);
  };

  useEffect(
    () => {
      deploymentCustom.onChange([]);
    },
    [deploymentType.selected, deploymentAudience.selected],
  );

  const siteFormUids = siteForms.map(x => x.uid);

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={`Add Forms to ${getSiteLabel()}`}
      handleClose={handleClose}
    >
      <ModalHeader handleClose={handleClose}>
        {`Add Forms to ${getSiteLabel()}`}
      </ModalHeader>

      <ModalBody>
        <form onSubmit={submit}>
          <SideboxSectionContent>
            <InlineSelect
              label={t('common:form')}
              icon={<NewsPaper />}
              inputProps={{
                placeholder: t('super:screens.sites.formInputPlaceholder'),
              }}
              errorMessage={errors.form}
              options={R.map(
                f => ({ label: f.name, value: f.uid }),
                formData.filter(f => !R.contains(f.uid, siteFormUids)),
              )}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              {...formSelect}
            />
          </SideboxSectionContent>
          {form && !form.v2 ? (
            <SelectFolder
              folder={folder}
              onSelect={onFolderSelect}
              path={path.slice(1)}
            />
          ) : null}
          <Deployment
            type={deploymentType.value}
            audience={deploymentAudience.value}
            custom={deploymentCustom.value}
            onAudienceChange={deploymentAudience.onChange}
            onTypeChange={deploymentType.onChange}
            onCustomChange={deploymentCustom.onChange}
            crews={crews.data}
            users={users.data}
          />
        </form>
      </ModalBody>
      <ModalFooter right={true}>
        <Button
          type={'primary'}
          disabled={isFormInvalid()}
          loading={loading}
          onClick={submit}
        >
          {t('common:submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    users: state.users,
    forms: state.forms,
    crews: state.crews,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getForms: (companyUid: string) => dispatch(formsFetchRequest(companyUid)),
    getCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddForms),
);

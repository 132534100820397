import * as React from 'react';
import moment from 'moment';
import ResourceTable from '../../components/ResourceTable';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { SelectOptionSubmissionFilters } from './Main';
import { getFullName, getSiteLabel } from '../../helpers';

interface Props extends WithNamespaces {
  forms: any;
  onRowClick: (
    formData: { formUid: string; formV2?: boolean; deployeeUid?: string },
  ) => void;
  filters: SelectOptionSubmissionFilters;
  formattedFilters: any;
}

const getDeployeeName = (deployee: any) => {
  if (!deployee) {
    return '';
  }
  return deployee.name || getFullName(deployee);
};

const formatTime = (t: any) => {
  if (!t) {
    return '';
  }
  return moment
    .unix(t)
    .utc()
    .format('hh:mm a');
};

const FormSubmissionsTable = ({ forms, t, onRowClick }: Props) => {
  const data = forms.map((x: any) => ({
    ...x,
    key: `${x.site ? x.site.name : ''}-${getDeployeeName(x.deployee)}`,
  }));

  return (
    <ResourceTable
      defaultSortKey={'allSubmissions'}
      defaultSortDesc={true}
      data={data}
      columns={[
        {
          Header: 'Recipient',
          accessor: r => r,
          Cell: ({ original }) => getDeployeeName(original.deployee),
          id: 'deployee',
        },
        { Header: `${getSiteLabel()}`, accessor: 'site.name' },
        {
          Header: t('widgets:submissions.allSubmissions'),
          accessor: 'allSubmissions',
        },
        {
          Header: t('widgets:submissions.earliest'),
          accessor: (r: any) => r,
          id: 'earliest',
          Cell: (row: any) => formatTime(row.original.earliest),
        },
        {
          Header: t('widgets:submissions.average'),
          accessor: (r: any) => r,
          id: 'average',
          Cell: (row: any) => formatTime(row.original.average),
        },
        {
          Header: t('widgets:submissions.latest'),
          accessor: (r: any) => r,
          id: 'latest',
          Cell: (row: any) => formatTime(row.original.latest),
        },
        {
          Header: t('widgets:submissions.submFrequency'),
          accessor: (r: any) => r,
          id: 'submFrequency',
          Cell: (row: any) => {
            const val = (row.original.submFrequency / 86400).toFixed(1);
            return val !== '0.0' ? `${val} days` : '';
          },
        },
      ]}
      onRowClick={row =>
        onRowClick({
          formUid: row.formUid,
          deployeeUid: row.deployee ? row.deployee.uid : null,
        })
      }
    />
  );
};

export default withNamespaces()(FormSubmissionsTable);

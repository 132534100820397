import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const Compass = (props: SVGProps) => (
  <svg viewBox="0 0 24 24" {...props}>
    <path d="M12 22a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zM9.56 8.93l6.37-2.12a1 1 0 0 1 1.26 1.26l-2.12 6.37a1 1 0 0 1-.63.63l-6.37 2.12a1 1 0 0 1-1.26-1.26l2.12-6.37a1 1 0 0 1 .63-.63zm-.22 5.73l4-1.33 1.32-4-4 1.34-1.32 4z" />
  </svg>
);

export default BaseSVG(Compass);

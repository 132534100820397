import * as React from 'react';
import { useSimpleFetch } from '../helpers/hooks';
import { Feed } from '../types';
import Newsfeed from './worker/dashboard/Newsfeed';
import adminApi from '../api/admin.api';
import Apartment from '../svg/Apartment';
import Desktop from '../svg/Desktop';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { Fab, FabAction } from '../components/Fab';
import { cssVars } from '../constants/index';
import { Route } from 'react-router';
import Loading from '../components/Loading';
import SideNavigation from '../components/SideNavigation';
import Menu from '../svg/Menu';
import styled from 'styled-components';
import { biggerThanMD } from '../helpers/style';
import Companies from './admin/CompanyGroups';
import { History } from 'history';

const DashboardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${biggerThanMD(`
    flex-direction: row;
  `)};
`;

const DashboardScrollingContent = styled.div`
  flex: 1;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: visible;

  ${biggerThanMD(`
    overflow: scroll;
  `)};
`;

interface Props extends WithNamespaces {
  history: History;
}

const Admin = ({ t, history }: Props) => {
  const feed = useSimpleFetch<Feed[]>({
    initial: [],
    change: [],
    accessor: 'feed',
    fetch: () => adminApi.newsfeed(),
  });

  const routes = [
    {
      label: t('common:dashboard'),
      icon: <Desktop />,
      path: '/dashboard/admin/dashboard',
    },
    {
      label: 'Groups',
      icon: <Apartment />,
      path: '/dashboard/admin/company-groups',
    },
  ];

  return (
    <DashboardContainer>
      <SideNavigation routes={routes} />
      <DashboardScrollingContent>
        <React.Suspense fallback={<Loading loading={true} />}>
          <Route
            path={'/dashboard/admin/dashboard'}
            render={() => (
              <div className="flex flex-col p-4">
                <Newsfeed
                  className={'h-full overflow-scroll'}
                  feed={feed.value}
                />
              </div>
            )}
          />
          <Route
            path={'/dashboard/admin/company-groups'}
            component={Companies}
            exact={true}
          />
        </React.Suspense>
        <div className="md:hidden">
          <Fab
            event={'click'}
            mainButtonStyles={{
              backgroundColor: cssVars.colors.red,
            }}
            position={{
              bottom: 0,
              right: 0,
            }}
            icon={<Menu className="w-6 h-6" />}
          >
            {routes.map(x => (
              <FabAction
                key={x.path}
                style={{
                  backgroundColor: cssVars.colors.grey,
                }}
                text={x.label}
                onClick={() => history.push(x.path)}
              >
                {React.cloneElement(x.icon, {
                  className: 'w-6 h-6 text-black',
                })}
              </FabAction>
            ))}
          </Fab>
        </div>
      </DashboardScrollingContent>
    </DashboardContainer>
  );
};

export default withNamespaces()(Admin);

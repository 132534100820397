import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionText, getOptionTitle } from '../../helpers/formOption';
import StackedSelect from '../../components/StackedSelect';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { useParsedSubmDataLocal } from '../../helpers/hooks';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  live?: boolean;
  submData?: ParsedSubmData;
  submUid?: string;
  editable?: boolean;
}

const SelectionItem = ({
  option,
  t,
  live,
  submData,
  submUid,
  editable,
}: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live });
  const text = getOptionText(option, { live });

  const options = text.split(';').map(i => ({
    label: i,
    value: i,
  }));

  const [value, setValue] = useParsedSubmDataLocal({
    option,
    submData,
    initial: [],
    submUid: submUid!,
  });

  const selected = value.map((x: any) => ({ label: x, value: x }));

  return (
    <StackedSelect
      id={`option-${option.id}`}
      label={title}
      placeholder={t('forms:emptyQuestionInputPlaceholder')}
      options={options}
      multi={true}
      menuPosition={'fixed'}
      menuPortalTarget={document.body}
      value={selected}
      disabled={!editable}
      onChange={(e: any) => setValue(e.map((x: any) => x.value))}
    />
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    submUid: state.form.submissionUid,
    editable: state.form.editable,
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(SelectionItem));

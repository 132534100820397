import { api2 } from './api';
import { Task } from '../types';

const taskApi = {
  update: (taskUid: string, data?: { status?: 1 | 2; name?: string }) =>
    api2<{ task: Task }>('PATCH', `tasks/${taskUid}`, data),
  delete: (taskUid: string) => api2('DELETE', `tasks/${taskUid}`),
};

export default taskApi;

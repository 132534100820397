import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import styled from 'styled-components';
import { History } from 'history';
import InlineInput from '../components/InlineInput';
import User from '../svg/User';
import Button from '../components/Button';
import { localStorageKeys } from '../constants';
import { biggerThanLG, biggerThanSM } from '../helpers/style';
import authApi from '../api/auth.api';
import * as Sentry from '@sentry/browser';
import { get } from 'lodash';
import getAuth0Client from '../auth0';

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding: 20px 0;
`;

const LoginForm = styled.form`
  flex: 0.9;
  display: flex;
  flex-direction: column;

  ${biggerThanSM(`
    flex: 0.5;
  `)};

  ${biggerThanLG(`
    flex: 0.3;
  `)};
`;

const LogoImg = styled.img`
  max-width: 100%;
  height: auto;
  align-self: center;
  margin-bottom: 50px;
`;

interface State {
  username: string;
  password: string;
  errors: {
    username: string | string[];
    password: string | string[];
  };
  loading: boolean;
  userChecked: boolean;
}

interface Props extends WithNamespaces {
  history: History;
}

class Login extends React.Component<Props, State> {
  public state = {
    username: '',
    password: '',
    errors: {
      username: '',
      password: '',
    },
    loading: false,
    userChecked: false,
  };

  public componentDidMount() {
    if (localStorage.getItem(localStorageKeys.USER_TOKEN) != null) {
      this.props.history.push('/dashboard/worker');
    }
  }

  public onUsernameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      username: e.currentTarget.value,
    });
  };

  public onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      password: e.currentTarget.value,
    });
  };

  private submit = async (
    e: React.FormEvent<HTMLButtonElement | HTMLFormElement>,
  ) => {
    e.preventDefault();
    this.setState({
      loading: true,
    });

    try {
      const { token, refreshToken } = await authApi.login(
        this.state.username,
        this.state.password,
      );

      localStorage.setItem(localStorageKeys.USER_TOKEN, token);
      localStorage.setItem(localStorageKeys.REFRESH_TOKEN, refreshToken);
    } catch (e) {
      if (!get(e, 'response.data.errors', null)) {
        if (process.env.NODE_ENV === 'production') {
          Sentry.withScope(scope => {
            Sentry.captureException(e);
            scope.setUser({
              username: this.state.username,
            });
          });
        }
      }
      this.setState({
        errors: get(e, 'response.data.errors', {}),
        loading: false,
      });
      return;
    }

    this.setState({
      loading: false,
    });

    this.props.history.push('/dashboard/worker/dashboard'); // TODO: Change
  };

  private redirectAuth0 = async () => {
    (await getAuth0Client(this.state.username)).loginWithRedirect();
  };

  public render() {
    const { t } = this.props;
    const { username, password, loading } = this.state;

    return (
      <Container>
        <div className="w-5/6 sm:w-1/2 lg:w-1/4">
          <LoginForm onSubmit={this.submit}>
            <LogoImg src={'/images/minimal-logo-login.png'} />
            <InlineInput
              icon={<User />}
              label={t('login:username')}
              name={'username'}
              inputProps={{
                placeholder: t('login:username'),
                id: 'username',
              }}
              errorMessage={this.state.errors.username}
              onChange={this.onUsernameChange}
              value={username}
            />
            <InlineInput
              icon={<User />}
              label={'Password'}
              errorMessage={this.state.errors.password}
              onChange={this.onPasswordChange}
              value={password}
              inputProps={{
                placeholder: t('login:password'),
                id: 'password',
                type: 'password',
              }}
            />

            <Button
              type={'primary'}
              loading={loading}
              style={{ width: '100%' }}
              onClick={this.submit}
            >
              Login
            </Button>
          </LoginForm>
          <p className={'my-8 text-center'}>- or -</p>
          <Button
            type={'default'}
            loading={loading}
            style={{ width: '100%' }}
            onClick={this.redirectAuth0}
          >
            Login with SSO
          </Button>
        </div>
      </Container>
    );
  }
}

export default withNamespaces()<any>(Login);

import * as React from 'react';
import Save from '../../../svg/Save';
import Trash from '../../../svg/Trash';

interface Props {
  value: string;
  onRemove?: () => void;
  onSave?: (value: string) => void;
}

interface State {
  value: string;
}

export default class SaveRemoveInput extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      value: props.value,
    };
  }

  private onChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ value: e.currentTarget.value });
  };

  private onSave = () => {
    if (this.props.onSave) {
      this.props.onSave(this.state.value);
    }
  };

  public render() {
    const { onRemove } = this.props;
    const { value } = this.state;

    return (
      <div className="p-4 border-0 border-b-1 border-solid border-border flex flex-row items-center">
        <div className={'w-4/5'}>
          <input
            type="text"
            className={'w-full p-2'}
            value={value}
            onChange={this.onChange}
          />
        </div>
        <div className={'ml-4 flex flex-row'}>
          {this.props.onSave && (
            <button
              className={
                'bg-transparent p-2 border-0 mx-4 flex flex-col text-black items-center hover:text-red cursor-pointer'
              }
              onClick={this.onSave}
            >
              <Save className={'h-4 w-4 mb-2'} />
              Save
            </button>
          )}
          {this.props.onRemove && (
            <button
              className={
                'bg-transparent p-2 border-0 mx-4 flex flex-col text-black items-center hover:text-red cursor-pointer'
              }
              onClick={onRemove}
            >
              <Trash className={'h-4 w-4 mb-2'} />
              Remove
            </button>
          )}
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import { Action } from '../types';
import { Link, LinkProps } from 'react-router-dom';
import Alarm from '../svg/Alarm';
import ChevRightOutline from '../svg/ChevRightOutline';
import moment from 'moment';
import styled from 'styled-components';
import { cssVars } from '../constants/index';
import { action } from 'typesafe-actions';

interface StyleProps {
  urgent?: boolean;
  overdue?: boolean;
  completed?: number;
}

const Wrapper = styled<StyleProps & LinkProps>(Link)`
  text-decoration: none;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${cssVars.padding[2]} ${cssVars.padding[4]};
  cursor: pointer;
  margin-bottom: ${cssVars.margin[4]};
  color: black;
  transition-duration: 0.3s;

  &:visited {
    color: inherit;
  }

  ${props => {
    if (props.completed) {
      return `
        background: ${cssVars.colors.white};
        border: 1px solid ${cssVars.colors.green};

        p {
          color: ${cssVars.colors.black};
        }

        &:hover {
          background: ${cssVars.colors['green-lighter']};
        }
      `;
    }
    if (props.overdue) {
      return `
        background: ${cssVars.colors.white};
        border: 1px solid ${cssVars.colors.red};

        &:hover {
          background: ${cssVars.colors['red-lighter']};
        }
      `;
    }

    if (props.urgent) {
      return `
        background: ${cssVars.colors.white};
        border: 1px solid ${cssVars.colors.yellow};

        p {
          color: ${cssVars.colors.black};
        }

        &:hover {
          background: ${cssVars.colors['yellow-lighter']};
        }
      `;
    }

    return `
        background: ${cssVars.colors['grey']};
        border: 1px solid ${cssVars.colors['grey']};

        &:hover {
          background: ${cssVars.colors['grey-lighter']};
        }
      `;
  }};
`;

const DueText = styled.p<StyleProps>`
  margin-top: ${cssVars.margin[1]};
  font-size: ${cssVars.textSizes.sm};

  ${props => {
    if (props.overdue) {
      return `
        color: ${cssVars.colors.black};
      `;
    }

    if (props.urgent) {
      return `
      `;
    }

    return ``;
  }};
`;

const Description = styled.h3<StyleProps>`
  font-weight: normal;

  ${props => {
    if (props.overdue) {
      return ``;
    }

    if (props.urgent) {
      return `
        color: white;
      `;
    }

    return ``;
  }};
`;

interface Props {
  action: Action;
}

const isOverdue = (unix: number) => {
  return moment.unix(unix).isBefore(moment());
};

const ActionItem = ({ action }: Props) => {
  const overdue = isOverdue(action.date_due);

  return (
    <Wrapper
      to={`/dashboard/worker/actions/${action.uid}`}
      urgent={action.urgent}
      overdue={overdue}
      completed={action.completed}
    >
      <div className="flex flex-row items-center">
        <Alarm className={'w-6 h-6 mr-4'} />
        <div>
          <Description>{action.description}</Description>
          <DueText urgent={action.urgent} overdue={overdue}>
            Due: {moment.unix(action.date_due).format('MMM DD, YYYY')}
          </DueText>
        </div>
      </div>
      <ChevRightOutline className={'w-6 h-6'} />
    </Wrapper>
  );
};

export default ActionItem;

import * as React from 'react';
import FolderOpen from '../../../svg/FolderOpen';
import VerticalMenu from '../../../svg/VerticalMenu';
import { Storage } from '../../../types';
import FolderOpenLocked from '../../../svg/FolderOpenLocked';
import classNames from 'classnames';
import {
  DragSourceConnector,
  DragSourceMonitor,
  DragSource,
  ConnectDragSource,
  DropTargetConnector,
  DropTargetMonitor,
  DropTarget,
  ConnectDropTarget,
} from 'react-dnd';
import { dragDropTypes } from '../../../constants/index';
import { DragDropMonitor } from 'dnd-core';

interface Props {
  storage?: Storage;
  name?: string;
  locked?: boolean;
  onMenuClick?: (storageUid: string) => void;
  selected?: boolean;
  onClick?: (uid: string) => void;
  connectDragSource: ConnectDragSource;
  connectDropTarget: ConnectDropTarget;
  isOver: boolean;
  drag?: boolean;
  drop?: boolean;
  onDrop?: (dragUid: string, dropUid: string) => void;
}

const Folder = ({
  storage,
  onMenuClick: pOnMenuClick,
  selected,
  onClick,
  locked,
  connectDragSource,
  connectDropTarget,
  isOver,
  drag,
  drop,
  name,
}: Props) => {
  const onMenuClick = (e: React.FormEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (pOnMenuClick && storage) {
      pOnMenuClick(storage.uid);
    }
  };

  const onWrapperClick = () => {
    if (onClick && storage) {
      onClick(storage.uid);
    }
  };

  const wrapperClassname = classNames(
    'flex flex-row items-center justify-between p-4 shadow-md cursor-pointer',
    selected
      ? 'bg-grey-darker hover:bg-grey-dark'
      : 'bg-grey-lighter hover:bg-grey-light',
    isOver ? 'bg-red' : '',
  );

  const genericDragFunc = (input: any) => input;
  const dropFunc = drop ? connectDropTarget : genericDragFunc;
  const dragFunc = drag ? connectDragSource : genericDragFunc;

  return dropFunc(
    dragFunc(
      <div className={wrapperClassname} onClick={onWrapperClick}>
        <div className="flex flex-row items-center pr-8">
          {locked ? (
            <FolderOpenLocked className={'w-8 h-8 mr-4'} />
          ) : (
            <FolderOpen className={'w-8 h-8 mr-4'} />
          )}
          <h3
            className={'font-normal'}
            style={{
              width: '125px',
              textOverflow: 'ellipsis',
              overflow: 'hidden',
              whiteSpace: 'nowrap',
            }}
          >
            {storage ? storage.name : name}
          </h3>
        </div>
        {pOnMenuClick && (
          <button className={'bg-transparent border-0'} onClick={onMenuClick}>
            <VerticalMenu className={'w-6 h-6 cursor-pointer hover:text-red'} />
          </button>
        )}
      </div>,
    ),
  );
};

const folderSource = {
  beginDrag: (props: Props) => ({
    uid: props.storage ? props.storage.uid : '',
  }),
};

const dragCollect = (
  connect: DragSourceConnector,
  monitor: DragSourceMonitor,
) => ({
  connectDragSource: connect.dragSource(),
  isDragging: monitor.isDragging(),
});

const folderTarget = {
  drop: ({ onDrop, storage }: Props, monitor: DragDropMonitor) => {
    if (onDrop) {
      onDrop(monitor.getItem().uid, storage ? storage.uid : '');
    }
  },
};

const dropCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor,
) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

export default DropTarget(
  [dragDropTypes.FOLDER, dragDropTypes.FILE],
  folderTarget,
  dropCollect,
)(DragSource(dragDropTypes.FOLDER, folderSource, dragCollect)(Folder));

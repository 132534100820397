import * as React from 'react';
import Sidebox, { SideboxContent } from '../../components/Sidebox';
import SideboxHeader from '../../components/sidebox/SideboxHeader';
import styled from 'styled-components';
import { cssVars, formFeatureIds } from '../../constants';
import Checkmark from '../../svg/Checkmark';
import Upload from '../../svg/Upload';
import Clipboard from '../../svg/Clipboard';
import Quesiton from '../../svg/Question';
import PenTool from '../../svg/PenTool';
import Pencil from '../../svg/Pencil';
import UserGroup from '../../svg/UserGroup';
import Search from '../../svg/Search';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import formOptionApi from '../../api/formOption.api';
import { FormOption } from '../../types';
import Loading from '../../components/Loading';
import Announcement from '../../svg/Announcement';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${cssVars.padding[4]};
`;

const WidgetCard = styled.button`
  width: 45%;
  margin-left: 10%;
  margin-bottom: ${cssVars.margin[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${cssVars.padding[4]};
  border: 1px solid ${cssVars.colors.border};
  background: ${cssVars.colors['border-light']};
  cursor: pointer;
  &:hover {
    background: white;
  }
  & svg {
    width: ${cssVars.width[8]};
    height: ${cssVars.width[8]};
    margin-bottom: ${cssVars.margin[4]};
  }
  &:nth-child(odd) {
    margin-left: 0;
  }
`;

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  destination?: number | null;
  formUid?: string;
  formType?: string;
  onCreate?: (option: FormOption) => void;
}

interface State {
  loading: boolean;
}

class WidgetAdd extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  private onClick = async (featureId: number) => {
    const { destination, formUid } = this.props;

    if (!destination) {
      return;
    }

    this.setState({
      loading: true,
    });

    try {
      const { option } = await formOptionApi.create({
        formUid,
        parentId: destination,
        typeId: featureId,
      });

      this.props.onCreate(option);
    } catch (e) {
      //
    }

    this.setState({
      loading: false,
    });
  };

  public render() {
    const { isOpen, handleClose, t, formType } = this.props;
    const { loading } = this.state;

    return (
      <Sidebox
        isOpen={isOpen}
        contentLabel={'User detail'}
        handleClose={handleClose}
      >
        <SideboxHeader handleClose={handleClose}>
          <h3>{t('super:screens.form.addWidget')}</h3>
        </SideboxHeader>
        <SideboxContent>
          <Loading loading={loading} />
          <CardWrapper>
            {formType === 'audit' && (
              <WidgetCard onClick={() => this.onClick(formFeatureIds.AUDIT)}>
                <Announcement />
                {t('common:formWidgets.audit')}
              </WidgetCard>
            )}
            <WidgetCard onClick={() => this.onClick(formFeatureIds.ACTIONS)}>
              <Announcement />
              {t('common:formWidgets.action')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.IMAGE)}>
              <Upload />
              {t('common:formWidgets.importImage')}
            </WidgetCard>
            <WidgetCard
              onClick={() => this.onClick(formFeatureIds.INSTRUCTIONS)}
            >
              <Clipboard />
              {t('common:formWidgets.instructions')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.QUESTION)}>
              <Quesiton />
              {t('common:formWidgets.yesNo')}
            </WidgetCard>
            <WidgetCard
              onClick={() => this.onClick(formFeatureIds.QUESTION_ANSWER)}
            >
              <Quesiton />
              {t('common:formWidgets.questionsAndAnswers')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.SELECTION)}>
              <Checkmark />
              {t('common:formWidgets.selection')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.SIGN_OFF)}>
              <PenTool />
              {t('common:formWidgets.signOff')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.SIGNATURE)}>
              <PenTool />
              {t('common:formWidgets.signature')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.SKETCH)}>
              <Pencil />
              {t('common:formWidgets.sketch')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.TABLE)}>
              <Pencil />
              {t('common:formWidgets.table')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.USER_INPUT)}>
              <Pencil />
              {t('common:formWidgets.userInput')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.USERS)}>
              <UserGroup />
              {t('common:formWidgets.users')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick(formFeatureIds.INSPECTION)}>
              <Search />
              {t('common:formWidgets.inspection')}
            </WidgetCard>
          </CardWrapper>
        </SideboxContent>
      </Sidebox>
    );
  }
}

export default withNamespaces()(WidgetAdd);

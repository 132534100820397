import * as React from 'react';
import styled from 'styled-components';
import { colors, colorTypes, complimentaryTextColors } from '../constants';
import { ReactNode } from 'react';

const HeaderWrapper = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 12px;
`;

interface TabTriggerProps {
  active?: boolean;
}

const TabTriggerRaised = styled.button<TabTriggerProps>`
  background: ${props =>
    props.active ? colors[colorTypes.PRIMARY] : '#f8f8f8'};
  color: ${props =>
    props.active ? complimentaryTextColors[colorTypes.PRIMARY] : 'black'};
  border: none;
  border-bottom: 4px solid ${props => (props.active ? '#e2403f' : '#ededed')};
  border-right: 1px solid #ededed;
  padding: 16px;
  flex: 1;
  font-size: 1.3em;
  cursor: pointer;

  &:last-of-type {
    border-right: 1px solid transparent;
  }

  &:hover {
    opacity: 0.8;
  }
`;

const TabTrigger = styled.button<TabTriggerProps>`
  background: transparent;
  border: none;
  padding: 12px;
  cursor: pointer;
  opacity: 0.8;
  flex: 1;
  text-align: center;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  font-size: 1.25em;

  border-bottom: 2px solid
    ${props => (props.active ? colors[colorTypes.PRIMARY] : 'transparent')};

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const TabContentWrapper = styled.div``;

export interface Tab {
  label: string;
  component?: ReactNode;
  onClick?: (tabIndex: number) => void;
}

interface Props {
  tabs: Tab[];
  activeTab?: number;
  onTabChange?: (tabIndex: number) => void;
  raised?: boolean;
}

interface State {
  activeTab: number;
}

export default class Tabs extends React.Component<Props, State> {
  public static defaultProps = {
    activeTab: 0,
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: 0,
    };
  }

  private getActiveTab = () => {
    if (this.props.onTabChange && this.props.activeTab) {
      return this.props.activeTab;
    }

    return this.state.activeTab;
  };

  private onChange = (tab: Tab, i: number) => {
    if (tab.onClick) {
      tab.onClick(i);
    } else if (this.props.onTabChange) {
      this.props.onTabChange(i);
    } else {
      this.setState({
        activeTab: i,
      });
    }
  };

  public render() {
    const { tabs, raised } = this.props;

    const TabTriggerComponent = raised ? TabTriggerRaised : TabTrigger;

    const activeTab = this.getActiveTab();

    return (
      <div>
        <HeaderWrapper>
          {tabs.map(({ label, onClick }, i) => (
            <TabTriggerComponent
              key={i}
              active={activeTab === i}
              onClick={() => this.onChange({ label, onClick }, i)}
            >
              {label}
            </TabTriggerComponent>
          ))}
        </HeaderWrapper>
        <TabContentWrapper>
          {tabs[activeTab] && tabs[activeTab].component}
        </TabContentWrapper>
      </div>
    );
  }
}

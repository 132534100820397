import { call, takeLatest, put } from 'redux-saga/effects';
import companyApi from '../api/company.api';
import { CREWS_FETCH_REQUEST } from '../constants/actionTypes';
import { crewsFetchFailure, crewsFetchSuccess } from '../actions/crews';

export function* getCrews(params: any) {
  const { data, errors } = yield call(companyApi.crews, params.payload);
  if (data) {
    yield put(crewsFetchSuccess(data.crews));
  } else if (errors) {
    yield put(crewsFetchFailure(errors));
  }
}

export function* watchGetCrews() {
  yield takeLatest(CREWS_FETCH_REQUEST, getCrews);
}

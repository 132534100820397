import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import InlineInput from '../../../components/InlineInput';
import Envelope from '../../../svg/Envelope';
import Button from '../../../components/Button';
import Trash from '../../../svg/Trash';
import ModalFooter from '../../../components/modal/ModalFooter';
import submissionApi from '../../../api/submission.api';
import { toast } from 'react-toastify';
import InlineAlert from '../../../components/InlineAlert';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  submissionUid: string;
}

const SendForm = ({ isOpen, handleClose, submissionUid }: Props) => {
  const [emails, setEmails] = React.useState([{ value: '' }]);
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const onChange = (e: React.FormEvent<HTMLInputElement>, index: number) => {
    setEmails(
      emails.map(
        (email, i) => (i === index ? { value: e.currentTarget.value } : email),
      ),
    );
  };

  const onRemove = (index: number) => {
    setEmails(emails.filter((_, i) => index !== i));
  };

  const addEmail = () => {
    setEmails([...emails, { value: '' }]);
  };

  const send = async () => {
    setLoading(true);
    const { data, errors } = await submissionApi.send(submissionUid, {
      emails: emails.map(i => i.value),
    });
    setLoading(false);
    if (data) {
      toast.success('Emails sent');
      handleClose();
    }
    if (errors) {
      setHasError(true);
    }
  };

  return (
    <Modal isOpen={isOpen} handleClose={handleClose} contentLabel={'Send Form'}>
      <ModalHeader handleClose={handleClose}>Send Submission</ModalHeader>
      <ModalBody>
        <div className="p-4">
          {hasError && (
            <InlineAlert
              type="danger"
              text="One or more emails are misformatted"
            />
          )}
          {emails.map((e, i) => (
            <div className="flex justify-between items-center mb-4" key={i}>
              <div className="flex-1">
                <InlineInput
                  icon={<Envelope />}
                  label="Email"
                  value={e.value}
                  onChange={evt => onChange(evt, i)}
                  margin={false}
                />
              </div>
              {emails.length > 1 && (
                <Trash
                  className={'ml-4 w-6 h-6 hover:text-red cursor-pointer'}
                  onClick={() => onRemove(i)}
                />
              )}
            </div>
          ))}
          <Button type="primary" onClick={addEmail}>
            Add Email +
          </Button>
        </div>
      </ModalBody>
      <ModalFooter right={true}>
        <Button type="red" loading={loading} onClick={send}>
          Send
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SendForm;

import api, { api2 } from './api';
import { CreateCompany } from './companyGroup.api';
import {
  Action,
  IncidentProp,
  IncidentPropOption,
  Form,
  Crew,
  Site,
  Incident,
  Checklist,
  User,
  Storage,
  WorkRole,
} from '../types';

interface UserCreateData {
  username: string;
  password: string;
  email: string;
  role?: string;
}

export interface SubmissionStats {
  crewUids?: string[];
  formUids?: string[];
  siteUids?: string[];
  submitterUids?: string[];
  signerUids?: string[];
  start?: number;
  end?: number;
  companyUids?: string[];
}

export interface ActionsFilters {
  siteUids?: string[];
  userUids?: string[];
  createdStart?: number;
  createdEnd?: number;
  dueStart?: number;
  dueEnd?: number;
}

interface TrainingMatrixExportFilters {
  siteUids?: string[];
  crewUids?: string[];
  userUids?: string[];
  certTypeUids?: string[];
}

export interface CreateAction {
  userUid: string;
  dueDate: number;
  description: string;
  correctiveAction?: string;
  siteUid?: string;
  urgent?: boolean;
  incidentUid?: string;
  submissionUid?: string;
  formOptionId?: number;
}

const companyApi = {
  users: (companyUid: string) =>
    api2<{ users: User[] }>('GET', `companies/${companyUid}/users`),
  createUser: (companyUid: string, data: UserCreateData) =>
    api('POST', `companies/${companyUid}/users`, data),
  workRoles: (companyUid: string) =>
    api2<{ workRoles: WorkRole[] }>(
      'GET',
      `companies/${companyUid}/work-roles`,
    ),
  createWorkRole: (companyUid: string, name: string) =>
    api('post', `companies/${companyUid}/work-roles`, { name }),
  crews: (companyUid: string) =>
    api2<{ crews: Crew[] }>('GET', `companies/${companyUid}/crews`),
  sites: (companyUid: string) =>
    api2<{ sites: Site[] }>('GET', `companies/${companyUid}/sites`),
  forms: (companyUid: string) =>
    api2<{ forms: Form[] }>('GET', `companies/${companyUid}/forms`),
  rootStorageDir: (companyUid: string) =>
    api('GET', `companies/${companyUid}/root-folder`),
  submissionStats: (companyUid: string, data?: SubmissionStats) =>
    api('GET', `company-stats/${companyUid}/submissions`, data),
  submissions: (companyUid: string, data?: SubmissionStats) =>
    api('GET', `companies/${companyUid}/submissions`, data),
  certificates: (companyUid: string) =>
    api('GET', `companies/${companyUid}/certificates`),
  createCertificate: (companyUid: string, name: string) =>
    api('POST', `companies/${companyUid}/certificates`, { name }),
  expiringCertificates: (companyUid: string, dayOffset: number = 30) =>
    api(
      'GET',
      `companies/${companyUid}/expiring-certificates?dayOffset=${dayOffset}`,
    ),
  actions: (companyUid: string, filters?: any) =>
    api2<{ actions: Action[] }>(
      'GET',
      `companies/${companyUid}/actions`,
      filters,
    ),
  createAction: (companyUid: string, data: CreateAction) =>
    api2<{ action: Action }>('POST', `companies/${companyUid}/actions`, data),
  actionStats: (companyUid: string, filters: ActionsFilters) =>
    api('GET', `company-stats/${companyUid}/actions`, filters),
  userActivity: (
    companyUid: string,
    filters: { start?: number; end?: number } = {},
  ) => api('GET', `companies/${companyUid}/user-activity`, filters),
  roleCertificateMatrix: (companyUid: string) =>
    api('GET', `companies/${companyUid}/role-certificate-matrix`),
  checklists: (companyUid: string, filters: { userUids?: string[] } = {}) =>
    api('GET', `companies/${companyUid}/checklists`, filters),
  createChecklist: (
    companyUid: string,
    data: { name: string; templateUid?: string },
  ) =>
    api2<{ checklist: Checklist }>(
      'POST',
      `companies/${companyUid}/checklists`,
      data,
    ),
  checklistTemplates: (companyUid: string) =>
    api2<{ checklistTemplates: Checklist[] }>(
      'GET',
      `companies/${companyUid}/checklist-templates`,
    ),
  trainingMatrix: (
    companyUid: string,
    filters: {
      userUids?: string[];
      siteUids?: string[];
      crewUids?: string[];
    } = {},
  ) => api('GET', `companies/${companyUid}/training-matrix`, filters),
  incidents: (
    companyUid: string,
    filters: {
      start?: number;
      end?: number;
      siteUids?: string[];
      crewUids?: string[];
    } = {},
  ) => api('GET', `companies/${companyUid}/incidents`, filters),
  exportIncidents: (
    companyUid: string,
    filters: {
      start?: number;
      end?: number;
      siteUids?: string[];
      crewUids?: string[];
    } = {},
  ) => api2('GET', `company-exports/${companyUid}/incidents`, filters),
  createIncident: (companyUid: string, data: any) =>
    api2<{ incident: Incident }>(
      'POST',
      `companies/${companyUid}/incidents`,
      data,
    ),
  exportTrainingMatrixAsPdf: (
    companyUid: string,
    filters?: TrainingMatrixExportFilters,
  ) => api('GET', `company-exports/${companyUid}/training-matrix-pdf`, filters),
  exportTrainingMatrixAsCsv: (
    companyUid: string,
    filters?: TrainingMatrixExportFilters,
  ) => api('GET', `company-exports/${companyUid}/training-matrix-csv`, filters),
  createConversation: (companyUid: string, data: any) =>
    api2<any>('POST', `companies/${companyUid}/conversations`, data),
  auditStats: (
    companyUid: string,
    filters?: {
      formUids?: string[];
      userUids?: string[];
      siteUids?: string[];
      crewUids?: string[];
      start?: number;
      end?: number;
    },
  ) => api2<any>('GET', `company-stats/${companyUid}/audits`, filters),
  exportAudits: (
    companyUid: string,
    filters?: {
      formUids?: string[];
      userUids?: string[];
      siteUids?: string[];
      crewUids?: string[];
      start?: number;
      end?: number;
    },
  ) =>
    api2<any>(
      'GET',
      `company-exports/${companyUid}/audit-submission-summaries-csv`,
      filters,
    ),
  incidentProps: (companyUid: string) =>
    api2<{
      incidentProps: IncidentProp[];
      incidentPropOptions: IncidentPropOption[];
    }>('GET', `companies/${companyUid}/incident-props`),
  trashedForms: (companyUid: string) =>
    api2<{
      forms: Form[];
    }>('GET', `companies/${companyUid}/trashed-forms`),
  trashedCrews: (companyUid: string) =>
    api2<{
      crews: Crew[];
    }>('GET', `companies/${companyUid}/trashed-crews`),
  trashedSites: (companyUid: string) =>
    api2<{
      sites: Site[];
    }>('GET', `companies/${companyUid}/trashed-sites`),
  createCrewInvite: (
    companyUid: string,
    crewUid: string,
    invites: Array<{ role: string; email: string }>,
  ) =>
    api2('POST', `companies/${companyUid}/crews/${crewUid}/invitations`, {
      invites,
    }),
  suspendedUsers: (companyUid: string) =>
    api2<{ suspendedUsers: User[] }>(
      'GET',
      `companies/${companyUid}/suspended-users`,
    ),
  attachExistingUser: (companyUid: string, userUid: string, role: string) =>
    api2('PUT', `companies/${companyUid}/users/${userUid}`, { role }),
  applyAllForm: (companyUid: string, formUid: string, type: string) =>
    api2('POST', `companies/${companyUid}/forms/${formUid}/apply-all`, {
      type,
    }),
  avatar: (companyUid: string) =>
    api2<{ avatar: Storage; path: string }>(
      'GET',
      `companies/${companyUid}/avatar`,
    ),
  setAvatar: (companyUid: string, data: any) =>
    api2<{ avatar: Storage; path: string }>(
      'POST',
      `companies/${companyUid}/avatar`,
      data,
    ),
  removeAvatar: (companyUid: string) =>
    api2('DELETE', `companies/${companyUid}/avatar`),
  getCompanyGroupLabels: (companyUid: string) =>
    api2('GET', `companies/${companyUid}/company-group`),
};

export default companyApi;

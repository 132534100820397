import * as React from 'react';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { User, Company, CompanyGroup } from '../types';
import Sidebox, { SideboxContent } from '../components/Sidebox';
import SideboxHeader from '../components/sidebox/SideboxHeader';
import Tabs from '../components/Tabs';
import UserOverview from './userSidebox/UserOverview';
import UserCrews from './userSidebox/UserCrews';
import UserSites from './userSidebox/UserSites';
import UserIcon from '../svg/User';
import { connect } from 'react-redux';
import { StoreState } from '../store';
import { localStorageKeys } from '../constants/index';
import userApi from '../api/user.api';
import { getSiteLabelPlural, getCrewLabelPlural } from '../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  user: User;
  handleClose: () => void;
  hideRole?: boolean;
  authUser: User;
  buttons?: any[];
  company?: Company;
  companyGroup?: CompanyGroup;
}

interface State {
  activeTab: number;
}

class UsersSidebox extends React.Component<Props, State> {
  public state = {
    activeTab: 0,
  };

  private onTabChange = (tabIndex: number) => {
    this.setState({
      activeTab: tabIndex,
    });
  };

  private onLoginAs = async () => {
    const { data } = await userApi.token(this.props.user.uid);
    if (data) {
      const currentToken = localStorage.getItem(localStorageKeys.USER_TOKEN);
      const currentRefresh = localStorage.getItem(
        localStorageKeys.REFRESH_TOKEN,
      );
      localStorage.setItem(
        localStorageKeys.ORIGINAL_USER_TOKEN,
        currentToken || '',
      );
      localStorage.setItem(
        localStorageKeys.ORIGINAL_REFRESH_TOKEN,
        currentRefresh || '',
      );
      localStorage.setItem(localStorageKeys.USER_TOKEN, data.token);
      localStorage.setItem(localStorageKeys.REFRESH_TOKEN, data.refreshToken);
      window.location.href = '/';
    }
  };

  public render() {
    const {
      user,
      isOpen,
      handleClose,
      t,
      hideRole,
      authUser,
      buttons,
      companyGroup,
      company,
    } = this.props;

    return (
      <Sidebox
        isOpen={isOpen}
        contentLabel={t('super:screens.users.userDetailsTitle')}
        handleClose={handleClose}
      >
        {user && (
          <div>
            <SideboxHeader
              handleClose={handleClose}
              buttons={[
                ...(buttons ? buttons : []),
                ...(authUser.has_wildcard_access
                  ? [
                      {
                        label: 'Login as User',
                        Icon: UserIcon,
                        onClick: this.onLoginAs,
                      },
                    ]
                  : []),
              ]}
            >
              <h3>{user ? user.username : '-'}</h3>
            </SideboxHeader>
            <SideboxContent>
              <Tabs
                tabs={[
                  {
                    label: t('super:screens.users.overview'),
                    component: <UserOverview user={user} hideRole={hideRole} />,
                  },
                  {
                    label: `${getCrewLabelPlural()}`,
                    component: (
                      <UserCrews
                        user={user}
                        company={company}
                        companyGroup={companyGroup}
                      />
                    ),
                  },
                  {
                    label: `${getSiteLabelPlural()}`,
                    component: (
                      <UserSites
                        user={user}
                        company={company}
                        companyGroup={companyGroup}
                      />
                    ),
                  },
                ]}
                activeTab={this.state.activeTab}
                onTabChange={this.onTabChange}
              />
            </SideboxContent>
          </div>
        )}
      </Sidebox>
    );
  }
}

export default withNamespaces()(
  connect((state: StoreState) => ({
    authUser: state.me.user,
  }))(UsersSidebox),
);

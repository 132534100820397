import classNames from 'classnames';
import * as React from 'react';

const HeaderButton = ({
  children,
  active,
  onClick,
}: {
  children: any;
  active?: boolean;
  onClick?: () => void;
}) => (
  <button
    onClick={onClick}
    className={classNames('border-0 bg-transparent mx-4 cursor-pointer', {
      'font-bold': active,
    })}
  >
    {children}
  </button>
);

export default HeaderButton;

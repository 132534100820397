import * as React from 'react';
import { cssVars } from '../../constants';
import styled from 'styled-components';
import { HeaderActions, HeaderAction } from './optionParentStyles';
import BaseWidgetChild, {
  RenderEditingProps,
  RenderLabelProps,
} from './BaseWidgetChild';

const OptionLabel = styled.label`
  display: inline-block;
`;

const OptionTags = styled.div`
  margin-top: ${cssVars.padding[2]};
  display: flex;
  flex-wrap: wrap;
`;

const OptionTag = styled.span`
  background: ${cssVars.colors.primary};
  margin: ${cssVars.margin[1]};
  color: white;
  padding: ${cssVars.padding[1]};
`;

interface Props {
  optionId: number;
  openDeleteOption?: (optionId: number) => any;
  index: number;
}

export default class SelectionChild extends React.Component<Props> {
  private transformDataBeforeSave = ({ text }: { text: string }) => ({
    // strip trailing semicolon after editing
    text: text.replace(/(;)$/g, ''),
  });

  private renderEditing = ({
    title,
    text,
    onTextChange,
    onTitleChange,
    stopEditing,
    cancelEditing,
    doneText,
    cancelText,
  }: RenderEditingProps) => (
    <div className={'flex flex-col w-full'}>
      <div className={'flex flex-1 items-stretch mb-2'}>
        <input
          placeholder={'Question title...'}
          value={title}
          onChange={onTitleChange}
          className={'px-2 py-4 border-0 w-full'}
        />

        <HeaderActions>
          <HeaderAction
            className={'bg-success text-white'}
            onClick={stopEditing}
          >
            {doneText}
          </HeaderAction>
        </HeaderActions>
      </div>
      <div className={'flex flex-1 items-stretch'}>
        <input
          placeholder={'Options'}
          value={text}
          onChange={onTextChange}
          className={'px-2 py-4 border-0 w-full'}
        />
        <HeaderActions>
          <HeaderAction
            className={'bg-default text-white'}
            onClick={cancelEditing}
          >
            {cancelText}
          </HeaderAction>
        </HeaderActions>
      </div>
    </div>
  );

  private renderLabel = ({
    defaultText,
    optionTitle,
    optionText,
  }: RenderLabelProps) => (
    <div>
      <OptionLabel>{optionTitle || defaultText}</OptionLabel>

      {optionText && (
        <OptionTags>
          <br />
          {optionText.split(';').map(s => (
            <OptionTag key={s}>{s}</OptionTag>
          ))}
        </OptionTags>
      )}
    </div>
  );

  public render() {
    const { optionId, openDeleteOption, index } = this.props;

    return (
      <BaseWidgetChild
        optionId={optionId}
        renderEditing={this.renderEditing}
        renderLabel={this.renderLabel}
        transformDataBeforeSave={this.transformDataBeforeSave}
        mandatory={true}
        openDeleteOption={openDeleteOption}
        index={index}
      />
    );
  }
}

import * as React from 'react';
import styled from 'styled-components';
import { biggerThanMD, mandatoryErrorStyles } from '../../helpers/style';
import WidgetContainer from '../form/WidgetContainer';
import WidgetContent from '../form/WidgetContent';
import {
  Widget,
  ResponseFile,
  ResponseComment,
  QuestionResponse,
} from '../../types';
import formService from '../../services/forms.service';
import WidgetMetaButtons from './WidgetMetaButtons';
import DropUpload from '../../components/DropUpload';
import { MAX_V2_WIDGET_UPLOADS, cssVars } from '../../constants';
import cloudinaryService from '../../services/cloudinary.service';
import { toast } from 'react-toastify';
import { useState } from 'react';
import Loading from '../../components/Loading';
import RequiredWidget from './RequiredWidget';

const ChangeButton = styled.button`
  background: transparent;
  padding: ${cssVars.padding[2]};
  color: white;
  cursor: pointer;
  border: 0;

  &:hover {
    background: white;
    color: black;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

interface Props {
  widget: Widget;
  response: QuestionResponse;
  comments: ResponseComment[];
  files: ResponseFile[];
  editable?: boolean;
  instanceRevUid: string;
  error?: boolean;
}

const FileUpload = ({
  widget,
  editable,
  instanceRevUid,
  response,
  error,
  files,
  comments,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [images, setImages] = useState<ResponseFile[]>(files ? files : []);

  const onDrop = async (filesToUpload: File[]) => {
    const tempImages: ResponseFile[] = images;
    if (filesToUpload.length + images.length > MAX_V2_WIDGET_UPLOADS) {
      toast.error(`Cannot upload more than ${MAX_V2_WIDGET_UPLOADS} files`);
      return;
    }
    const { data } = await formService.getInstanceRevisionFileSignature(
      instanceRevUid,
    );
    if (!data) {
      return;
    }
    setLoading(true);

    const { signature, timestamp, folder } = data;
    for (const file of filesToUpload) {
      const cloudinaryResult = await cloudinaryService.uploadSigned(file, {
        signature,
        folder,
        timestamp,
      });
      if (!cloudinaryResult) {
        continue;
      }

      const fileObj = {
        file_id: cloudinaryResult.id,
        file_url: cloudinaryResult.url,
        name: file.name,
        // https://stackoverflow.com/questions/190852/how-can-i-get-file-extensions-with-javascript/12900504#12900504
        extension: file.name.slice(
          (Math.max(0, file.name.lastIndexOf('.')) || Infinity) + 1,
        ),
      };
      const {
        data: uploadedFileData,
        errors,
      } = await formService.uploadWidgetFile(
        instanceRevUid,
        widget.uid,
        fileObj,
      );
      if (errors || !uploadedFileData) {
        toast.error('Failed to upload file!');
        continue;
      }
      tempImages.push(uploadedFileData.file);
    }
    setImages(tempImages);
    setLoading(false);
  };

  const onRemove = async (fileUid: string) => {
    setLoading(true);
    const newImages = images.filter(({ uid }) => uid !== fileUid);
    setImages(newImages);
    await formService.deleteWidgetFile(fileUid);
    setLoading(false);
  };

  return (
    <WidgetContainer>
      <WidgetContent
        style={error ? mandatoryErrorStyles : {}}
        className="bg-white mt-4 rounded"
      >
        <div className={'mb-4 relative'}>
          <Loading loading={loading} />
          <Wrapper>
            <label>
              {widget.title}
              {widget.mandatory && <RequiredWidget />}
            </label>
          </Wrapper>
          <DropUpload
            block={true}
            margin={false}
            onDrop={onDrop}
            disabled={!editable}
            accept={'image/*'}
          />
          <div className="flex flex-col w-full">
            <div className="overflow-y-scroll mt-4">
              {images.map(image => (
                <>
                  <div className="inline-block relative w-1/6 h-auto">
                    <div className="m-2 relative">
                      <a href={image.file_url} target="_blank">
                        <img
                          src={image.file_url}
                          className="w-full cursor-pointer"
                        />
                      </a>
                      <div
                        className="absolute flex justify-center w-full p-2"
                        style={{
                          bottom: 0,
                          left: 0,
                          background: 'rgba(0, 0, 0, 0.6)',
                          display: !editable ? 'none' : undefined,
                        }}
                      >
                        <ChangeButton
                          onClick={() => onRemove(image.uid)}
                          disabled={!editable}
                        >
                          REMOVE
                        </ChangeButton>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
        <WidgetMetaButtons
          editable={editable}
          widget={widget}
          instanceRevUid={instanceRevUid}
          comments={comments}
          showFileButton={false}
        />
      </WidgetContent>
    </WidgetContainer>
  );
};

export default FileUpload;

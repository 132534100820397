import * as React from 'react';
import Modal from '../../components/Modal';
import ModalHeader from '../../components/modal/ModalHeader';
import ModalBody from '../../components/modal/ModalBody';
import InlineInput from '../../components/InlineInput';
import Apartment from '../../svg/Apartment';
import ModalFooter from '../../components/modal/ModalFooter';
import Button from '../../components/Button';
import { useTextInput } from '../../helpers/hooks';
import companyGroupApi from '../../api/companyGroup.api';
import { CompanyGroup } from '../../types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  onCreate?: (group: CompanyGroup) => void;
}

const CompanyGroupCreate = ({ isOpen, handleClose, onCreate }: Props) => {
  const name = useTextInput();
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);

  const create = async () => {
    setLoading(true);
    const { data, errors: resErrors } = await companyGroupApi.create({
      name: name.value,
    });
    setLoading(false);
    if (data) {
      name.setValue('');
      if (onCreate) {
        onCreate(data.companyGroup);
      }
    }
    if (resErrors) {
      setErrors(resErrors);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Create company group"
    >
      <ModalHeader handleClose={handleClose}>Create Company Group</ModalHeader>
      <ModalBody>
        <InlineInput
          label="Name"
          icon={<Apartment />}
          errorMessage={errors.name}
          {...name}
        />
      </ModalBody>
      <ModalFooter right={true}>
        <Button type="primary" onClick={create} loading={loading}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CompanyGroupCreate;

import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Company, Form, FormVersion } from '../../types';
import moment from 'moment';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Button from '../../components/Button';
import { History } from 'history';
import ResourceTable from '../../components/ResourceTable';
import PageHeading from '../../components/PageHeading';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { Dispatch } from 'redux';
import { addForm, formsFetchRequest } from '../../actions/forms';
import FormCreate from './form/FormCreate';
import { needsToBeFetched } from '../../helpers/globalState';
import { FormsState } from '../../reducers/forms';
import { getDivisionLabel } from '../../helpers';

interface State {
  createFormOpen: boolean;
  loading: boolean;
  formCreateModalVersion: null | FormVersion;
}

interface Props extends WithNamespaces {
  company: Company;
  history: History;
  forms: FormsState;
  formsLoading: boolean;
  formsLoaded: boolean;
  getForms: (companyUid: string) => any;
  addForm: (form: Form) => any;
}

class Forms extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      createFormOpen: false,
      formCreateModalVersion: null,
    };
  }

  public componentDidMount() {
    if (needsToBeFetched(this.props.forms)) {
      this.props.getForms(this.props.company.uid);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (needsToBeFetched(this.props.forms)) {
      this.props.getForms(this.props.company.uid);
    }
  }

  private openCreateForm = (version: FormVersion) => {
    this.setState({
      formCreateModalVersion: version,
      createFormOpen: true,
    });
  };

  private closeCreateForm = () => {
    this.setState({
      createFormOpen: false,
    });
  };

  private onFormCreate = (form: Form) => {
    this.closeCreateForm();
    this.props.addForm(form);
    if (form.v2) {
      this.props.history.push(`/dashboard/super/form-builder/${form.uid}`);
    } else {
      this.props.history.push(`/dashboard/super/forms/${form.uid}`);
    }
  };

  public render() {
    const { t, forms, company } = this.props;
    const { loading, createFormOpen, formCreateModalVersion } = this.state;
    const tableColumns = [
      {
        Header: t('super:name'),
        accessor: 'name',
      },
      {
        Header: t('super:type'),
        accessor: (p: Form) => t(`forms:${p.type}`),
        id: 'type',
      },
      {
        Header: t('super:created'),
        accessor: (p: Form) => moment(p.created_at * 1000).format('YYYY-MM-DD'),
        id: 'created_at',
      },
    ];

    return (
      <Page>
        <PageContent>
          <PageHeading
            title={`${getDivisionLabel()} Active Forms`}
            subtitle={`${forms.data.length} ${t('super:available')}`}
            renderRight={() => (
              <div className="flex flex-row items-center justify-center">
                <Button
                  type={'primary'}
                  onClick={() => this.openCreateForm('v1')}
                  style={{ marginRight: 4 }}
                >
                  {t('super:screens.forms.createButton')}
                </Button>
              </div>
            )}
          />

          <ResourceTable
            data={forms.data}
            columns={tableColumns}
            defaultSortKey={'name'}
            loading={loading}
            onRowClick={form =>
              this.props.history.push(
                `/dashboard/super/${form.v2 ? 'form-builder' : 'forms'}/${
                  form.uid
                }`,
              )
            }
            fetched={forms.loaded}
            emptyTitle={'You have not created any forms'}
            emptyMessage={
              'Create a custom form using our Form Builder by clicking "Create Form" in the top right.'
            }
          />
        </PageContent>

        <FormCreate
          onCreate={this.onFormCreate}
          company={company}
          handleClose={this.closeCreateForm}
          isOpen={createFormOpen}
          version={formCreateModalVersion || 'v1'}
        />
      </Page>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    forms: state.forms,
    formsLoaded: state.forms.loaded,
    formsLoading: state.forms.loading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getForms: (companyUid: string) => dispatch(formsFetchRequest(companyUid)),
    addForm: (form: Form) => dispatch(addForm(form)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Forms),
);

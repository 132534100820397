import { call, takeLatest, put } from 'redux-saga/effects';
import companyGroupApi from '../../api/companyGroup.api';
import { COMPANY_GROUP_CREWS_FETCH_REQUEST } from '../../constants/actionTypes';
import {
  companyGroupCrewsFetchFailure,
  companyGroupCrewsFetchSuccess,
} from '../../actions/companyGroup/crews';

export function* getCrews(params: any) {
  const { data, errors } = yield call(companyGroupApi.crews, params.payload);
  if (errors) {
    yield put(companyGroupCrewsFetchFailure(errors));
    return;
  }

  yield put(companyGroupCrewsFetchSuccess(data.crews));
}

export function* watchGetCompanyGroupCrews() {
  yield takeLatest(COMPANY_GROUP_CREWS_FETCH_REQUEST, getCrews);
}

import { all } from 'redux-saga/effects';
import { watchGetSites } from './sites';
import { watchGetUsers } from './users';
import { watchGetCrews } from './crews';
import { watchGetForms } from './forms';
import { watchGetCertificates } from './certificates';
import { watchGetWorkRoles } from './workRoles';
import { watchGetRoleCertificateMatrix } from './roleCertificateMatrix';
import { watchGetCompanyGroupCompanies } from './companyGroup/companies';
import { watchGetCompanyGroupCrews } from './companyGroup/crews';
import { watchGetCompanyGroupSites } from './companyGroup/sites';
import { watchGetCompanyGroupUsers } from './companyGroup/users';
import { watchGetCompanyGroupForms } from './companyGroup/forms';
import { watchGetSiteForms } from './siteForms';

export default function* rootSaga() {
  yield all([
    watchGetSites(),
    watchGetUsers(),
    watchGetCrews(),
    watchGetForms(),
    watchGetCertificates(),
    watchGetWorkRoles(),
    watchGetRoleCertificateMatrix(),
    watchGetCompanyGroupCompanies(),
    watchGetCompanyGroupCrews(),
    watchGetCompanyGroupSites(),
    watchGetCompanyGroupUsers(),
    watchGetCompanyGroupForms(),
    watchGetSiteForms(),
  ]);
}

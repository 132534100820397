import * as React from 'react';
import Avatar from '../../../components/Avatar';
import { MyActionsResponse } from '../../../api/user.api';
import moment from 'moment';
import ChevRightOutline from '../../../svg/ChevRightOutline';
import { Link } from 'react-router-dom';

interface Props {
  actions: MyActionsResponse[];
}

const Actions = ({ actions }: Props) => {
  return (
    <div className={'bg-white'}>
      <div className="p-4">
        <h3>Actions</h3>
      </div>
      <div>
        <ul
          className="list-reset"
          style={{ maxHeight: '300px', overflow: 'scroll' }}
        >
          {actions.map((x, i) => (
            <li key={x.action.uid}>
              <Link
                className={`text-black no-underline p-4 flex flex-row items-center justify-between cursor-pointer hover:bg-grey-dark ${
                  i % 2 === 0 ? 'bg-grey-lighter' : 'bg-grey-light hover'
                }`}
                to={`/dashboard/worker/actions/${x.action.uid}`}
              >
                <div className="flex flex-row items-center">
                  <Avatar
                    fallbackName={x.user ? x.user.username : ''}
                    margin={true}
                    user={x.user}
                  />
                  <div className="flex flex-col">
                    <p>{x.action.description}</p>
                    <p className={'text-grey-darker text-sm'}>
                      Due: {moment.unix(x.action.date_due).fromNow()}
                    </p>
                  </div>
                </div>
                <ChevRightOutline className={'w-6 h-6'} />
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Actions;

import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Company, Crew } from '../../types';
import moment from 'moment';
import CrewsSidebox from '../../containers/CrewSidebox';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import CrewCreate from './crews/CrewCreate';
import Button from '../../components/Button';
import ResourceTable from '../../components/ResourceTable';
import PageHeading from '../../components/PageHeading';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { Dispatch } from 'redux';
import { crewsFetchRequest, addCrew } from '../../actions/crews';
import { CrewsState } from '../../reducers/crews';
import { useMountVisibility, useEntityRefresh } from '../../helpers/hooks';
import { UsersState } from '../../reducers/users';
import { usersFetchRequest } from '../../actions/users';
import {
  getDivisionLabel,
  getCrewLabelPlural,
  getCrewLabel,
  getSiteLabelPlural,
} from '../../helpers';

interface Props extends WithNamespaces {
  company: Company;
  crews: CrewsState;
  getCrews: (companyUid: string) => any;
  getUsers: (companyUid: string) => any;
  addCrew: (crew: Crew) => any;
  users: UsersState;
}

const Crews = ({
  company,
  crews,
  getCrews,
  addCrew: pAddCrew,
  t,
  users,
  getUsers,
}: Props) => {
  const detailState = useMountVisibility();
  const createState = useMountVisibility();
  const [crew, setCrew] = React.useState<Crew | null>(null);

  useEntityRefresh(company.uid, crews, getCrews);
  useEntityRefresh(company.uid, users, getUsers);

  const openCrewDetail = (c: Crew) => {
    setCrew(c);
    detailState.open();
  };

  const handleCrewCreate = (c: Crew) => {
    createState.close();
    setCrew(c);
    detailState.open();
    pAddCrew(c);
  };

  const onDelete = () => {
    getCrews(company.uid);
    detailState.close();
  };

  const tableColumns = [
    {
      Header: t('super:name'),
      accessor: 'name',
    },
    {
      Header: t('super:created'),
      accessor: (p: Crew) => moment(p.created_at * 1000).format('YYYY-MM-DD'),
      id: 'created_at',
    },
  ];

  return (
    <Page>
      <PageContent>
        <PageHeading
          title={`${getDivisionLabel()} Active ${getCrewLabelPlural()}`}
          subtitle={`${crews.data.length} ${t('super:available')}`}
          renderRight={() => (
            <Button type={'primary'} onClick={createState.open}>
              {`Create ${getCrewLabel()}`}
            </Button>
          )}
        />

        <ResourceTable
          data={crews.data}
          columns={tableColumns}
          defaultSortKey={'name'}
          onRowClick={openCrewDetail}
          fetched={crews.loaded}
          emptyTitle={`You have not created any ${getCrewLabelPlural().toLowerCase()}`}
          emptyMessage={`${getCrewLabelPlural()} allow you to group users together and place them on ${getSiteLabelPlural().toLowerCase()}. To create a crew, click "Create ${getCrewLabel()}" in the top right.`}
        />

        {crew &&
          detailState.mounted && (
            <CrewsSidebox
              isOpen={detailState.visible}
              handleClose={detailState.close}
              crew={crew}
              tabs={['overview', 'sites']}
              users={users.data}
              onDelete={onDelete}
            />
          )}
        {createState.mounted && (
          <CrewCreate
            isOpen={createState.visible}
            handleClose={createState.close}
            onCreate={handleCrewCreate}
          />
        )}
      </PageContent>
    </Page>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    crews: state.crews,
    users: state.users,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
    addCrew: (crew: Crew) => dispatch(addCrew(crew)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Crews),
);

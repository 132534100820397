import * as React from 'react';
import { Company, Crew } from '../../types';
import { useOptionalSelectInput } from '../../helpers/hooks';
import SideboxSectionHeader from '../../components/sidebox/SideboxSectionHeader';
import SideboxSectionContent from '../../components/sidebox/SideboxSectionContent';
import StackedSelect from '../../components/StackedSelect';
import Button from '../../components/Button';
import crewApi from '../../api/crew.api';
import Loading from '../../components/Loading';
import { toast } from 'react-toastify';
import { getCrewLabel, getDivisionLabel } from '../../helpers';
import Trash from '../../../src/svg/Trash';

interface Props {
  companies: Company[];
  crew: Crew;
}

const CrewCompanies = ({ companies: allCompanies, crew }: Props) => {
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [loading, setLoading] = React.useState(false);
  const selectedCompany = useOptionalSelectInput();

  const fetchCompanies = async () => {
    setLoading(true);
    const { data } = await crewApi.companies(crew.uid);
    if (data) {
      setCompanies(data.companies);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchCompanies();
  }, []);

  const attachCompanies = async () => {
    if (!selectedCompany.selected) {
      return;
    }

    setLoading(true);

    const { data, errors } = await crewApi.attachCompany(
      crew.uid,
      selectedCompany.selected,
    );

    if (errors) {
      toast.error('Something went wrong');
    }

    if (data) {
      selectedCompany.onChange(null);
      fetchCompanies();
    }

    setLoading(false);
  };

  async function detachCompany(companyUid: string) {
    if (!crew.uid || !companyUid) {
      return;
    }
    setLoading(true);
    const { data } = await crewApi.detachCompany(companyUid, crew.uid);
    setLoading(false);
    if (!data) {
      return void toast.error(
        `There was an error removing this ${getDivisionLabel()}`,
      );
    }
    return void fetchCompanies();
  }

  const crewCompanyUids = companies.map(c => c.uid);
  const filteredCompanies = allCompanies
    .filter(c => crewCompanyUids.indexOf(c.uid) === -1)
    .map(c => ({
      label: c.name,
      value: c.uid,
    }));

  return (
    <div>
      <Loading loading={loading} />
      <SideboxSectionHeader title="Divisions" />
      <SideboxSectionContent>
        <div>
          <StackedSelect
            label={`Add ${getCrewLabel()} to ${getDivisionLabel()}`}
            options={filteredCompanies}
            {...selectedCompany}
          />
          <div className="my-4 flex justify-end">
            <Button
              type="default"
              block={true}
              loading={loading}
              onClick={attachCompanies}
            >
              Save
            </Button>
          </div>
        </div>
        <hr className={'my-4'} />
        <div>
          <ul>
            {companies.map((company: Company) => (
              <li className="flex flex-row justify-between items-center p-4 bg-grey-light">
                <span className="text-lg">{company.name}</span>
                <Button onClick={() => detachCompany(company.uid)}>
                  <Trash color="white" className={'w-4 h-4'} />
                </Button>
              </li>
            ))}
          </ul>
        </div>
      </SideboxSectionContent>
    </div>
  );
};

export default CrewCompanies;

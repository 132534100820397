import * as React from 'react';
import SideboxSectionContent from '../../components/sidebox/SideboxSectionContent';
import InlineSelect from '../../components/InlineSelect';
import { useSimpleFetch, useCompanyCrewOptions } from '../../helpers/hooks';
import storageApi from '../../api/storage.api';
import { Crew } from '../../types';
import { SelectOptionObject } from '../../components/Select';
import Tags from '../../components/Tags';
import UserGroup from '../../svg/UserGroup';
import StackedSelect from '../../components/StackedSelect';

interface Props {
  storageUid: string;
}

const ShareCrews = ({ storageUid }: Props) => {
  const [selectedCrews, setSelectedCrews] = React.useState<Crew[]>([]);
  const crews = useSimpleFetch({
    initial: [],
    change: [storageUid],
    fetch: () => storageApi.shared(storageUid),
    accessor: 'crews',
  });
  const crewOptions = useCompanyCrewOptions();

  React.useEffect(
    () => {
      setSelectedCrews(crews.value);
    },
    [crews.value],
  );

  const share = async (opt: SelectOptionObject) => {
    await storageApi.shareWithCrew(storageUid, opt.value);
    crews.performFetch();
  };

  const unshare = async (crew: Crew) => {
    storageApi.unshare(storageUid, crew.uid);
    setSelectedCrews(selectedCrews.filter(u => u.uid !== crew.uid));
  };

  return (
    <SideboxSectionContent>
      {selectedCrews.length > 0 && (
        <div className="mb-4">
          <Tags tags={selectedCrews} accessor={'name'} onClick={unshare} />
        </div>
      )}
      <StackedSelect
        label="Share with crews"
        options={crewOptions}
        value={null}
        onChange={share}
      />
    </SideboxSectionContent>
  );
};

export default ShareCrews;

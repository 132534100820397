import * as crewActions from '../../actions/companyGroup/crews';
import { ActionType, getType } from 'typesafe-actions';
import { Crew } from '../../types';

export type CompanyGroupCrewAction = ActionType<typeof crewActions>;

export interface CompanyGroupCrewsState {
  data: Crew[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyGroupUid: string;
}

const DEFAULT_STATE: CompanyGroupCrewsState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  companyGroupUid: '',
};

export default function companyGroupCrewsReducer(
  state: CompanyGroupCrewsState = DEFAULT_STATE,
  action: CompanyGroupCrewAction,
) {
  switch (action.type) {
    case getType(crewActions.companyGroupCrewsFetchRequest):
      return {
        ...state,
        loading: true,
        companyGroupUid: action.payload,
      };
    case getType(crewActions.companyGroupCrewsFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...action.payload],
      };
    case getType(crewActions.companyGroupCrewsFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
      };
    case getType(crewActions.companyGroupAddCrew):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(crewActions.companyGroupRemoveCrew):
      return {
        ...state,
        data: state.data.filter(crew => crew.uid !== action.payload),
      };
    default:
      return state;
  }
}

import * as React from 'react';
import DateFilterWidget from '../components/DateFilterWidget';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {
  Company,
  Crew,
  Form,
  Site,
  User as IUser,
  CompanyGroup,
} from '../types';
import Button from '../components/Button';
import CreateIncident from './CreateIncident';
import { History } from 'history';
import { useMountVisibility } from '../helpers/hooks';
import IncidentsMain from './incidents/Main';

interface Props extends WithNamespaces {
  company?: Company;
  companyGroup?: CompanyGroup;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
  history: History;
  companies?: Company[];
  queryOverrides?: {
    siteUids?: string[];
    crewUids?: string[];
    companyUids?: string[];
    userUids?: string[];
  };
}

const Incidents = ({
  company,
  companyGroup,
  history,
  users,
  crews,
  forms,
  sites,
  companies,
  queryOverrides,
}: Props) => {
  const createState = useMountVisibility();

  const onIncidentCreate = (incident: any) => {
    createState.close();
    goToIncident(incident.uid);
  };

  const goToIncident = (incidentUid: string) => {
    if (company) {
      history.push(`/dashboard/super/dashboard/incidents/${incidentUid}`);
    }
    if (companyGroup) {
      history.push(
        `/dashboard/company-admin/dashboard/incidents/${incidentUid}`,
      );
    }
  };

  if (company && !company.incident_module) {
    return (
      <div className="flex flex-col bg-white justify-center items-center p-4 py-10">
        <div className="md:w-1/2 text-center">
          <h3>Welcome to Incident Management.</h3>
          <p>You have not yet subscribed to the Incident Management module.</p>
          <p>
            When you subscribe to Incident Management you’ll be able to track
            all incidents that occur and collect all relevant information for an
            incident in a single case#.{' '}
          </p>
          <p>
            Contact <a href="mailto:sales@safetytek.ca">sales@safetytek.ca</a>{' '}
            to enable this feature.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className={'relative'}>
      <div className="flex md:justify-end py-4">
        <Button type={'primary'} onClick={createState.open}>
          Add Incident
        </Button>
      </div>
      <DateFilterWidget defaultView="last30Days">
        {({ start, end, Widget, getWidgetProps }) => (
          <Widget {...getWidgetProps()}>
            {() => (
              <IncidentsMain
                start={start}
                end={end}
                goToIncident={goToIncident}
                users={users}
                crews={crews}
                forms={forms}
                companies={companies}
                sites={sites}
                company={company}
                companyGroup={companyGroup}
                queryOverrides={queryOverrides}
              />
            )}
          </Widget>
        )}
      </DateFilterWidget>

      <CreateIncident
        handleClose={createState.close}
        isOpen={createState.visible}
        sites={sites}
        crews={crews}
        companies={companies}
        company={company}
        companyGroup={companyGroup}
        onCreate={onIncidentCreate}
      />
    </div>
  );
};

export default withNamespaces()(Incidents);

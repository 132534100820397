import * as React from 'react';
import Button from '../../components/Button';
import { api2 } from '../../api/api';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { User, Conversation, Message } from '../../types';
import moment from 'moment';
import { useTextInput, useMountVisibility } from '../../helpers/hooks';
import conversationApi from '../../api/conversation.api';
import trim from 'ramda/es/trim';
import { listenPrivate } from '../../helpers/socket';
import { throttle } from 'lodash';
import MessageAttachments from './MessageAttachments';
import { toast } from 'react-toastify';
import { getFullName } from '../../helpers/index';
import Loading from '../../components/Loading';

interface Props {
  openAllMessages: () => void;
  conversation: Conversation;
  user: User;
}

const MessageDetail = ({ openAllMessages, conversation, user }: Props) => {
  const [messages, setMessages] = React.useState<
    Array<{ sender?: User; message: Message }>
  >([]);
  const text = useTextInput();
  const messageContainer = React.useRef();
  const [shouldScroll, setShouldScroll] = React.useState(true);
  const uploadState = useMountVisibility();
  const [uploading, setUploading] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const getMessages = async () => {
    if (!conversation) {
      return;
    }
    setLoading(true);
    const { data } = await api2(
      'GET',
      `users/${user.uid}/conversations/${conversation.uid}/messages`,
    );
    if (data) {
      setMessages(data.messages);
    }
    setLoading(false);
  };

  const sendMessage = async () => {
    if (!trim(text.value)) {
      return;
    }
    setMessages([
      ...messages,
      {
        message: {
          text: text.value,
          uid: `${messages.length + 1}`,
          created_at: moment().unix(),
        },
        sender: user,
      },
    ]);
    text.setValue('');
    await conversationApi.sendMessage(conversation.uid, text.value);
  };

  const addMessage = React.useCallback((data: any) => {
    setMessages(prevMessages => [...prevMessages, data]);
    conversationApi.viewMessage(user.uid, data.message.uid);
  }, []);

  const handleShouldScroll = React.useCallback(
    throttle(evt => {
      if (
        evt.target.clientHeight ===
        evt.target.scrollHeight - evt.target.scrollTop
      ) {
        setShouldScroll(true);
      } else {
        setShouldScroll(false);
      }
    }, 500),
    [],
  );

  const openMessageUpload = () => {
    const el = document.getElementById('message-upload');
    if (el) {
      el.click();
    }
  };

  const onFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const { files: iFiles } = e.currentTarget;
    if (iFiles && iFiles.length > 0) {
      const formData = new FormData();
      formData.append('uploads', iFiles[0]);
      setUploading(true);
      const { data, errors } = await conversationApi.upload(
        conversation.uid,
        formData,
      );
      setUploading(false);
      if (data) {
        toast.success('Uploaded');
      } else if (errors) {
        toast.error('Failed to upload');
      }
    }
  };

  React.useEffect(
    () => {
      const msgRef = messageContainer.current;
      if (shouldScroll) {
        msgRef.scrollTop = msgRef.scrollHeight;
      }
    },
    [messages, shouldScroll],
  );

  React.useEffect(
    () => {
      if (messageContainer.current) {
        messageContainer.current.addEventListener('scroll', handleShouldScroll);
      }

      return () => {
        messageContainer.current.removeEventListener(
          'scroll',
          handleShouldScroll,
        );
      };
    },
    [messageContainer.current],
  );

  React.useEffect(
    () => {
      getMessages();
      const unsub = listenPrivate(
        `conversations.${conversation.uid}`,
        'newMessage',
        addMessage,
      );
      return () => {
        unsub();
      };
    },
    [addMessage],
  );

  return (
    <div className={'flex flex-col h-full relative'}>
      <div className="flex flex-row items-stretch border-0 border-b-1 border-solid border-border">
        <button
          children={'Back'}
          className={
            'bg-default block text-white border-0 text-center p-4 cursor-pointer mr-4'
          }
          onClick={openAllMessages}
        />
        <h3 className="p-4 font-normal">{conversation.name}</h3>
      </div>

      <div className="border-0 border-b-1 border-solid border-border flex flex-row justify-between p-4 items-center">
        <p
          onClick={uploadState.open}
          className={'cursor-pointer hover:underline'}
        >
          View Attachments
        </p>
        <input
          type="file"
          className="hidden"
          id="message-upload"
          onChange={onFileChange}
          accept="image/*,application/pdf"
        />
        <Button
          type={'primary'}
          children={'Upload Files'}
          onClick={openMessageUpload}
          loading={uploading}
        />
      </div>

      <div
        className="flex-1 flex flex-col relative overflow-scroll"
        style={{ paddingBottom: '75px' }}
        ref={messageContainer}
      >
        <Loading loading={loading} />
        {messages.map(({ message, sender }: any) => {
          const created = moment
            .unix(message.created_at)
            .format('h:mm a, MMM DD, YYYY');
          if (!sender) {
            return (
              <div
                className="flex flex-row items-start justify-center my-2 flex-no-shrink sf-hd"
                key={message.uid}
              >
                <p className={'inline-block text-grey-darker italic'}>
                  {message.text}
                </p>
              </div>
            );
          }
          const messageText = message.text
            .split('\n')
            .map((item: string, key: number) => {
              return (
                <React.Fragment key={key}>
                  {item}
                  <br />
                </React.Fragment>
              );
            });

          return (
            <div
              className="flex flex-col px-2 py-4 flex-no-shrink sf-hd"
              key={message.uid}
              style={{
                borderLeftWidth: '4px',
                borderLeftStyle: 'solid',
                borderLeftColor: `#${sender.profile_color[0]}`,
              }}
            >
              <div className="flex flex-row items-center">
                <h3 className={'text-md font-normal'}>{getFullName(sender)}</h3>{' '}
                <span
                  className={'ml-2 text-sm text-grey-darker'}
                  title={created}
                >
                  {moment.unix(message.created_at).fromNow()}
                </span>
              </div>
              <div className={'mt-2'}>{messageText}</div>
            </div>
          );
        })}
      </div>

      <div
        className="flex flex-row"
        style={{ position: 'absolute', bottom: 0, width: '100%' }}
      >
        <textarea
          className={'w-full p-4 border-grey-dark'}
          placeholder={'Type a message...'}
          {...text}
        />
        <Button type={'primary'} children={'Submit'} onClick={sendMessage} />
      </div>

      {uploadState.mounted && (
        <MessageAttachments
          isOpen={uploadState.visible}
          handleClose={uploadState.close}
          conversationUid={conversation.uid}
        />
      )}
    </div>
  );
};

export default connect((state: StoreState) => ({
  user: state.me.user,
}))(MessageDetail);

import * as React from 'react';
import ReactTable, { TableProps } from 'react-table';
import 'react-table/react-table.css';
import './table/table.scss';

export default class Table extends React.Component<Partial<TableProps>> {
  public render() {
    return <ReactTable {...this.props} />;
  }
}

import * as React from 'react';

interface Props {
  isTooLong: boolean;
  isTooShort: boolean;
  maxCharLength: number;
}

function LengthErrorLabel({ isTooLong, maxCharLength, isTooShort }: Props) {
  return (
    <p
      className={
        isTooLong || isTooShort ? 'flex justify-end text-red mr-8' : 'hidden'
      }
    >
      {isTooLong && `Maximum ${maxCharLength} characters!`}
      {isTooShort && `Cannot leave labels empty!`}
    </p>
  );
}

export default LengthErrorLabel;

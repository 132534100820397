import * as React from 'react';

interface Props {
  type: 'warning' | 'info' | 'danger';
  title?: string;
  text?: string;
  primaryButton?: {
    label: string;
    type?: string;
    onClick?: () => void;
  };
}

const colorTypes = {
  info: 'blue',
  warning: 'yellow',
  danger: 'red',
};

const InlineAlert = ({ type = 'info', title, text }: Props) => {
  const borderColor = `${colorTypes[type]}-dark`;
  const bgColor = `${colorTypes[type]}-lighter`;
  const txtColor = `${colorTypes[type]}-darkest`;
  return (
    <div
      className={`mb-4 border-0 border-l-4 border-solid border-${borderColor} bg-${bgColor} p-4`}
    >
      {title && (
        <p className={`text-${txtColor} ${text ? 'mb-4' : ''} font-bold`}>
          {title}
        </p>
      )}
      {text && <p className={`text-${txtColor}`}>{text}</p>}
    </div>
  );
};

export default InlineAlert;

import * as React from 'react';
import InlineSelect from '../../../components/InlineSelect';
import UserGroup from '../../../svg/UserGroup';
import { CrewsState } from '../../../reducers/crews';
import { Company, Crew } from '../../../types';
import {
  useEntityRefresh,
  useMultiSelectInput,
  useSimpleFetch,
} from '../../../helpers/hooks';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { crewsFetchRequest } from '../../../actions/crews';
import { Dispatch } from 'redux';
import checklistApi from '../../../api/checklist.api';
import Loading from '../../../components/Loading';
import Tags from '../../../components/Tags';
import { SelectOptionObject } from '../../../components/Select';

interface Props {
  crews: CrewsState;
  getCrews: (companyUid: string) => any;
  company: Company;
  checklistUid: string;
}

const ShareCrews = ({
  company,
  getCrews,
  crews: crewsState,
  checklistUid,
}: Props) => {
  useEntityRefresh(company.uid, crewsState, getCrews);
  const [selectedCrews, setSelectedCrews] = React.useState<Crew[]>([]);
  const crews = useSimpleFetch<Crew[]>({
    initial: [],
    accessor: 'crews',
    fetch: () => checklistApi.shared(checklistUid),
  });

  React.useEffect(
    () => {
      setSelectedCrews(crews.value);
    },
    [crews.value],
  );

  const crewOptions = crewsState.data.map(crew => ({
    label: crew.name,
    value: crew.uid,
  }));

  const onRemove = (crew: Crew) => {
    setSelectedCrews(selectedCrews.filter(u => u.uid !== crew.uid));
    checklistApi.unshare(checklistUid, crew.uid);
  };

  const onCrewSelect = async (opt: SelectOptionObject) => {
    setSelectedCrews([
      ...selectedCrews,
      crewsState.data.find(u => u.uid === opt.value)!,
    ]);

    checklistApi.shareWithCrew(checklistUid, opt.value);
  };

  return (
    <div className={'p-4 relative'}>
      <Loading loading={crews.loading} />
      {selectedCrews.length > 0 && (
        <div className="mb-4">
          <Tags tags={selectedCrews} onClick={onRemove} accessor={'name'} />
        </div>
      )}
      <InlineSelect
        options={crewOptions}
        label={'Share with'}
        icon={<UserGroup />}
        menuPortalTarget={document.body}
        menuPosition={'fixed'}
        onChange={onCrewSelect}
        value={null}
      />
    </div>
  );
};

export default connect(
  (store: StoreState) => ({
    crews: store.crews,
    company: store.me.company,
  }),
  (dispatch: Dispatch) => ({
    getCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
  }),
)(ShareCrews);

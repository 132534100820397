import * as React from 'react';
import ResourceTable from '../../components/ResourceTable';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import {
  useSimpleFetch,
  useEntityRefresh,
  useMountVisibility,
} from '../../helpers/hooks';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { User, Company, Crew } from '../../types';
import CrewSidebox from '../../containers/CrewSidebox';
import { UsersState } from '../../reducers/users';
import { usersFetchRequest } from '../../actions/users';
import { Dispatch } from 'redux';
import companyUserApi from '../../api/companyUser.api';

interface Props {
  user: User;
  company: Company;
  users: UsersState;
  getUsers: (companyUid: string) => any;
}

const CrewManager = ({ user, users, company, getUsers }: Props) => {
  const [selectedCrew, setSelectedCrew] = React.useState<null | Crew>(null);
  const sideboxState = useMountVisibility();
  useEntityRefresh(company.uid, users, getUsers);
  const crews = useSimpleFetch({
    initial: [],
    change: [user.uid, company.uid],
    fetch: () => companyUserApi.managedCrews(company.uid, user.uid),
    accessor: 'crews',
  });

  const selectCrew = (crew: Crew) => {
    setSelectedCrew(crew);
    sideboxState.open();
  };

  const handleClose = () => {
    sideboxState.close();
    setTimeout(() => setSelectedCrew(null), 100);
  };

  return (
    <Page>
      <PageContent>
        <ResourceTable
          data={crews.value}
          columns={[{ accessor: 'name', Header: 'Crew Name' }]}
          defaultSortKey={'name'}
          onRowClick={row => selectCrew(row)}
        />
        {sideboxState.mounted && selectedCrew ? (
          <CrewSidebox
            isOpen={sideboxState.visible}
            handleClose={handleClose}
            users={users.data}
            crew={selectedCrew}
            tabs={['overview']}
          />
        ) : null}
      </PageContent>
    </Page>
  );
};

const mapState = (state: StoreState) => ({
  user: state.me.user,
  company: state.me.company,
  users: state.users,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  };
}

export default connect(
  mapState,
  mapDispatchToProps,
)(CrewManager);

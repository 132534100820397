import * as React from 'react';
import { FormOption } from '../types';
import Section from './form/Section';
import { orderBy } from 'natural-orderby';

interface Props {
  sections: FormOption[];
  live: boolean;
}

const Form = ({ sections, live }: Props) => {
  return (
    <div>
      {orderBy(sections, [v => v.sort], ['asc']).map(section => (
        <Section key={section.id} optionId={section.id} live={live} />
      ))}
    </div>
  );
};

export default Form;

import * as React from 'react';
import BaseWidget from './BaseWidget';
import { withNamespaces, WithNamespaces } from 'react-i18next';

import 'tinymce';
import 'tinymce/plugins/table';
import 'tinymce/plugins/textcolor';
import 'tinymce/plugins/preview';
import 'tinymce/themes/modern/theme';
import { Editor } from '@tinymce/tinymce-react';
import formOptionApi from '../../api/formOption.api';
import { debounce } from 'lodash';
import { connect } from 'react-redux';
import { updateFormOption } from '../../actions/formOptions';
import { FormOption } from '../../types';
import { Dispatch } from 'redux';
import Button from '../../components/Button';
import { toast } from 'react-toastify';

interface Props extends WithNamespaces {
  optionId: number;
  formUid: string;
  openDeleteOption?: (optionId: number) => any;
  index: number;
  updateFormOption: (option: FormOption) => any;
}

class Table extends React.Component<Props> {
  private saveText = debounce(async (text: string, optionText: string) => {
    try {
      const { formOption } = await formOptionApi.patch(this.props.optionId, {
        text: !!text ? text : optionText,
      });

      this.props.updateFormOption(formOption);
      toast.success('Table Saved');
    } catch (e) {
      //
    }
  }, 500);

  public render() {
    const { optionId, t, openDeleteOption, index } = this.props;

    return (
      <BaseWidget
        optionId={optionId}
        defaultTitle={t('common:formWidgets.table')}
        openDeleteOption={openDeleteOption}
        index={index}
        renderChildren={({ optionText, onTextChange, text }) => {
          return (
            <div>
              <Editor
                initialValue=""
                value={optionText}
                onChange={e => {
                  onTextChange(e.target.getContent());
                }}
                init={{
                  skin_url: `${process.env.PUBLIC_URL}/skins/lightgray`,
                  plugins: ['table', 'textcolor', 'preview'],
                  toolbar:
                    'undo redo | styleselect | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist | table | forecolor backcolor | input',
                  branding: false,
                  menu: {},
                  content_style:
                    '.mce-menu {position: fixed;} .mce-item-table{min-width: 100%;}',
                  setup: (editor: any) => {
                    editor.addButton('input', {
                      title: 'Input Field',
                      image: '/images/input-field.png',
                      onclick: () => {
                        const id =
                          '_' +
                          Math.random()
                            .toString(36)
                            .substr(2, 9);
                        editor.focus();
                        editor.selection.setContent(
                          `<div class="form-group"><input type="text" name="data[${optionId}][${id}]" class="form-control"/></div>`,
                        );
                        onTextChange(editor.getContent());
                      },
                    });
                  },
                  height: 300,
                  style_formats: [
                    { title: 'Strong text', inline: 'b' },
                    {
                      title: 'Danger text',
                      inline: 'span',
                      styles: { color: '#d9534f' },
                    },
                    {
                      title: 'Danger header',
                      block: 'h3',
                      styles: { color: '#d9534f' },
                    },
                  ],

                  statusbar: false,
                }}
              />
              <div className="flex flex-row justify-end bg-grey p-4">
                <Button
                  type="primary"
                  onClick={() => this.saveText(text, optionText)}
                >
                  Save Table
                </Button>
              </div>
            </div>
          );
        }}
      />
    );
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateFormOption: (option: FormOption) =>
      dispatch(updateFormOption(option)),
  };
}

export default withNamespaces()(connect(null, mapDispatchToProps)(Table));

import * as React from 'react';
import styled from 'styled-components';
import CloseOutline from '../../svg/CloseOutline';
import { cssVars } from '../../constants/index';

export const Header = styled.div`
  background: url(/images/sidebox-header.png);
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  position: relative;
  font-size: 1.25em;
`;

export const CloseButton = styled.button`
  position: absolute;
  right: 12px;
  top: 10px;
  width: 12px;
  height: auto;
  cursor: pointer;
  background: transparent;
  border: none;

  & path {
    fill: white;
  }
`;

const HeaderButtons = styled.div`
  display: flex;
  flex-direction: row;
  border: 0;
  border-top: 1px solid rgba(255, 255, 255, 0.4);
`;

const HeaderButton = styled.button`
  flex: 1;
  background: transparent;
  border: 0;
  border-right: 1px solid rgba(255, 255, 255, 0.4);
  color :rgba(255, 255, 255, 0.9);
  padding: ${cssVars.padding[2]};
  text-align: center;
  font-size: ${cssVars.textSizes.sm}
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: ${cssVars.colors.default};
  }

  & svg {
    margin-right: ${cssVars.margin[2]};
    width: ${cssVars.width[4]};
    height: ${cssVars.width[4]};
  }

  &:last-of-type {
    border-right: 0;
  }
`;

interface Props extends React.ComponentProps<any> {
  handleClose: () => any;
  buttons?: Array<{
    label: string;
    Icon?: React.ComponentType;
    onClick?: () => any | void;
    loading?: boolean;
  }>;
}

const SideboxHeader = ({ children, buttons, handleClose }: Props) => {
  return (
    <Header>
      <div className="p-4">{children}</div>

      {buttons && (
        <HeaderButtons>
          {buttons.map(({ label, Icon, onClick, loading }, i) => (
            <HeaderButton onClick={onClick} key={i} disabled={loading}>
              {Icon && <Icon />}
              {label}
            </HeaderButton>
          ))}
        </HeaderButtons>
      )}

      <CloseButton onClick={handleClose} aria-label={'Close'}>
        <CloseOutline />
      </CloseButton>
    </Header>
  );
};

export default SideboxHeader;

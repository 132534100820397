import * as React from 'react';
import { TimePicker } from 'antd';
import styled from 'styled-components';
import InlineInputBase, {
  Props as InlineInputBaseProps,
} from './InlineInputBase';
import { cssVars } from '../constants';
import moment from 'moment';

import 'antd/lib/time-picker/style/index.css';
import Clock from '../svg/Clock';

const StyledTime = styled(TimePicker)`
  flex: 1;

  & input {
    padding: ${cssVars.padding[3]};
    outline: none;
    border: none;
    border-radius: 0;
    height: 100%;
  }
`;

interface Props extends InlineInputBaseProps {
  onChange?: (time: moment.Moment, timeString: string) => void;
  value?: moment.Moment;
  labelSize?: number;
  disabled?: boolean;
}

export default class InlineTimePicker extends React.Component<Props, {}> {
  public static defaultProps = {
    inputProps: {},
  };

  public render() {
    const {
      icon,
      label,
      errorMessage,
      value,
      onChange,
      labelSize,
      disabled,
    } = this.props;

    return (
      <InlineInputBase
        icon={icon || <Clock />}
        label={label}
        errorMessage={errorMessage}
        labelSize={labelSize}
      >
        <StyledTime
          value={value}
          onChange={onChange}
          format={'H:mm'}
          disabled={disabled}
        />
      </InlineInputBase>
    );
  }
}

import * as React from 'react';
import FreeDrawCanvas from '../legacy/FreeDrawCanvas';
import { useEffect } from 'react';

interface Props {
  defaultBg?: string;
  canvasRef?: React.RefObject<HTMLCanvasElement>;
}

// https://stackoverflow.com/questions/17386707/how-to-check-if-a-canvas-is-blank
export function isCanvasBlank(canvas: HTMLCanvasElement) {
  const context = canvas.getContext('2d');

  if (!context) {
    return true;
  }

  const pixelBuffer = new Uint32Array(
    context.getImageData(0, 0, canvas.width, canvas.height).data.buffer,
  );

  return !pixelBuffer.some(color => color !== 0);
}

const SignatureCanvas = ({ defaultBg, canvasRef }: Props) => {
  const onClear = async (clear: any) => {
    clear();
  };

  return (
    <FreeDrawCanvas background={defaultBg}>
      {({ getCanvasProps, clear }) => (
        <>
          <label className={'mb-3 flex flex-row items-center justify-between'}>
            Signature{' '}
            <span
              className={'cursor-pointer hover:text-grey'}
              onClick={() => onClear(clear)}
            >
              (clear)
            </span>
          </label>
          <div className="border-2 border-solid border-border max-w-full overflow-hidden">
            <canvas
              {...(() => {
                // Blame @oz
                // We need to set both refs, therefore we must use this monster
                const result = getCanvasProps();
                return {
                  ...result,
                  ref: r => {
                    result.ref.current = r;
                    canvasRef.current = r;
                  },
                };
              })()}
            />
          </div>
        </>
      )}
    </FreeDrawCanvas>
  );
};

export default SignatureCanvas;

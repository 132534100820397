import * as React from 'react';
import Modal from '../components/Modal';
import ModalBody from '../components/modal/ModalBody';
import ModalHeader from '../components/modal/ModalHeader';
import ModalFooter from '../components/modal/ModalFooter';
import Button from '../components/Button';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import InlineSelect from '../components/InlineSelect';
import SideboxSectionHeader from '../components/sidebox/SideboxSectionHeader';
import Pencil from '../svg/Pencil';
import UserGroup from '../svg/UserGroup';
import Location from '../svg/Location';
import InlineDatePicker from '../components/InlineDatePicker';
import InlineInput from '../components/InlineInput';
import {
  Crew,
  Site,
  Company,
  CompanyGroup,
  IncidentPropOption,
  IncidentProp,
} from '../types';
import companyApi from '../api/company.api';
import ErrorMessage from '../components/ErrorMessage';
import Loading from '../components/Loading';
import {
  useOptionalSelectInput,
  useOptionalDateInput,
  useTextInput,
  useRequiredDateInput,
} from '../helpers/hooks';
import Suitcase from '../svg/Suitcase';
import companyGroupApi from '../api/companyGroup.api';
import IncidentProps from './IncidentProps';
import InlineTimePicker from '../components/InlineTimePicker';
import moment from 'moment';
import {
  getDivisionLabelPlural,
  getSiteLabel,
  getDivisionLabel,
  getCrewLabel,
} from '../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  sites: Site[];
  crews: Crew[];
  companies?: Company[];
  onCreate: (incident: any) => void;
  company?: Company;
  companyGroup?: CompanyGroup;
}

const CreateIncident = ({
  crews,
  sites,
  company,
  companyGroup,
  onCreate,
  isOpen,
  handleClose,
  t,
  companies,
}: Props) => {
  const site = useOptionalSelectInput();
  const crew = useOptionalSelectInput();
  const caseNumber = useTextInput();
  const description = useTextInput();
  const occurred = useRequiredDateInput(moment());
  const reported = useRequiredDateInput(moment());
  const occurredTime = useRequiredDateInput(moment());
  const reportedTime = useRequiredDateInput(moment());
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const selectedCompany = useOptionalSelectInput();

  const [incidentPropSelects, setIncidentPropSelects] = React.useState({});
  const [incidentPropOther, setIncidentPropOther] = React.useState({});
  const [incidentProps, setIncidentProps] = React.useState<IncidentProp[]>([]);
  const [incidentPropOptions, setIncidentPropOptions] = React.useState<
    IncidentPropOption[]
  >([]);

  const getCrewOptions = () =>
    crews.map(c => ({
      label: c.name,
      value: c.uid,
    }));

  const getSiteOptions = () =>
    sites.map(s => ({
      label: s.name,
      value: s.uid,
    }));

  const getCompanyOptions = () =>
    (companies || []).map(c => ({
      label: c.name,
      value: c.uid,
    }));

  const isCompany = company ? true : false;
  const scope = isCompany ? company : companyGroup;

  if (!scope) {
    return null;
  }

  const fetchIncidentProps = async () => {
    const endpoint = isCompany
      ? companyApi.incidentProps
      : companyGroupApi.incidentProps;

    const { data } = await endpoint(scope.uid);

    if (data) {
      setIncidentProps(data.incidentProps);
      setIncidentPropOptions(data.incidentPropOptions);
    }
  };

  const onSubmit = async (
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
  ) => {
    e.preventDefault();

    const companyUid = company
      ? company.uid
      : selectedCompany.selected
        ? selectedCompany.selected
        : '';

    if (!companyUid) {
      setErrors({ companyUid: 'is required' });
      return;
    }

    setLoading(true);

    const props = Object.keys(incidentPropSelects).reduce((curr, id) => {
      if (Array.isArray(incidentPropSelects[id])) {
        curr[id] = incidentPropSelects[id].map((x: any) => x.value);
      } else {
        curr[id] = incidentPropSelects[id];
      }
      return curr;
    }, {});
    // get "other" only when selected
    const other = Object.keys(props).reduce((curr, id) => {
      if (Array.isArray(props[id]) && props[id].indexOf('other') !== -1) {
        curr[id] = incidentPropOther[id];
      }
      return curr;
    }, {});

    const { data, errors: resErrors } = await companyApi.createIncident(
      companyUid,
      {
        caseNumber: caseNumber.value,
        siteUid: site.selected ? site.selected : undefined,
        crewUid: crew.selected ? crew.selected : undefined,
        description: description.value,
        dateOccurred: moment(
          `${occurred.value.format('YYYY-MM-DD')} ${occurredTime.value.format(
            'H:mm',
          )}`,
          'YYYY-MM-DD H:mm',
        ).unix(),
        dateReported: moment(
          `${reported.value.format('YYYY-MM-DD')} ${reported.value.format(
            'H:mm',
          )}`,
          'YYYY-MM-DD H:mm',
        ).unix(),
        props: {
          ...props,
          other: {
            ...other,
          },
        },
      },
    );

    if (data) {
      onCreate(data.incident);
    }

    if (resErrors) {
      setErrors(resErrors);
    }

    setLoading(false);
  };

  React.useEffect(
    () => {
      fetchIncidentProps();
    },
    [company, companyGroup],
  );

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={'Create an Incident'}
      handleClose={handleClose}
    >
      <ModalHeader handleClose={handleClose}>Create an Incident</ModalHeader>
      <ModalBody>
        <Loading loading={loading} />
        <form onSubmit={onSubmit}>
          <InlineInput
            label={'Case #'}
            icon={<Pencil />}
            {...caseNumber}
            errorMessage={errors.caseNumber}
          />
          {companyGroup && (
            <InlineSelect
              options={getCompanyOptions()}
              label={getDivisionLabel()}
              icon={<Suitcase />}
              {...selectedCompany}
              isClearable={true}
              errorMessage={errors.companyUid}
            />
          )}
          <InlineSelect
            options={getSiteOptions()}
            label={getSiteLabel()}
            icon={<Location />}
            {...site}
            isClearable={true}
            errorMessage={errors.siteUid}
          />
          <InlineSelect
            options={getCrewOptions()}
            label={getCrewLabel()}
            icon={<UserGroup />}
            {...crew}
            isClearable={true}
            errorMessage={errors.crewUid}
          />
          <IncidentProps
            props={incidentProps}
            propOptions={incidentPropOptions}
            propValues={incidentPropSelects}
            otherValues={incidentPropOther}
            onPropValuesChange={setIncidentPropSelects}
            onOtherValuesChange={setIncidentPropOther}
          />
          <SideboxSectionHeader leftIcon={<Pencil />} title={'Description'} />
          <textarea
            className={'w-full p-4 mb-4 border-grey-darker'}
            {...description}
          />
          {errors.description && (
            <ErrorMessage className={'mb-4'}>{errors.description}</ErrorMessage>
          )}
          <div className="flex flex-col md:flex-row md:items-stretch">
            <div className="flex-1 md:mr-4">
              <InlineDatePicker
                label={'Occurred'}
                {...occurred}
                errorMessage={errors.dateOccurred}
              />
            </div>
            <div className="flex-1">
              <InlineTimePicker label="Time" {...occurredTime} />
            </div>
          </div>
          <div className="flex flex-col md:flex-row md:items-stretch">
            <div className="flex-1 md:mr-4">
              <InlineDatePicker
                label={'Reported'}
                {...reported}
                errorMessage={errors.dateReported}
              />
            </div>
            <div className="flex-1">
              <InlineTimePicker label="Time" {...reportedTime} />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter right={true}>
        <Button type={'primary'} onClick={onSubmit} loading={loading}>
          {t('common:submit')}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withNamespaces()(CreateIncident);

import * as React from 'react';
import { connect } from 'react-redux';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { CompanyGroup, User } from '../../../types';
import { CompanyGroupUsersState } from '../../../reducers/companyGroup/users';
import { useMountVisibility, useEntityRefresh } from '../../../helpers/hooks';
import Avatar from '../../../components/Avatar';
import Page from '../../../components/Page';
import PageContent from '../../../components/PageContent';
import PageHeading from '../../../components/PageHeading';
import ResourceTable from '../../../components/ResourceTable';
import { StoreState } from '../../../store';
import { companyGroupUsersFetchRequest } from '../../../actions/companyGroup/users';
import { Dispatch } from 'redux';
import UserSidebox from '../../../containers/UserSidebox';
import { getDivisionLabelPlural, getDivisionLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  companyGroup: CompanyGroup;
  getUsers: (companyGroupUid: string) => any;
  users: CompanyGroupUsersState;
}

const Users = ({ companyGroup, getUsers, users, t }: Props) => {
  const sideboxState = useMountVisibility();
  const [user, setUser] = React.useState<User | null>(null);

  useEntityRefresh(companyGroup.uid, users, getUsers);

  const openSidebox = (u: User) => {
    setUser(u);
    sideboxState.open();
  };

  const tableColumns = [
    {
      Header: '',
      accessor: (r: any) => r,
      id: 'avatar',
      Filter: () => <span />,
      Cell: (row: any) => (
        <Avatar
          bgColor={row.original.profile_color[0]}
          fgColor={row.original.profile_color[1]}
          fallbackName={row.original.username}
          margin={false}
          user={row.original}
        />
      ),
      maxWidth: 75,
    },
    { Header: t('common:username'), accessor: 'username' },
    { Header: t('common:firstName'), accessor: 'fname' },
    { Header: t('common:lastName'), accessor: 'lname' },
  ];

  return (
    <div>
      <PageHeading
        title={`${getDivisionLabel()} Active Users`}
        subtitle={`${users.data.length} ${t('super:available')}`}
      />

      <ResourceTable
        data={users.data}
        columns={tableColumns}
        defaultSortKey={'username'}
        onRowClick={openSidebox}
      />

      {sideboxState.mounted && (
        <UserSidebox
          isOpen={sideboxState.visible}
          handleClose={sideboxState.close}
          user={user!}
          hideRole={true}
          companyGroup={companyGroup}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    companyGroup: state.me.companyGroup,
    users: state.companyGroup.users,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUsers: (companyGroupUid: string) =>
      dispatch(companyGroupUsersFetchRequest(companyGroupUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Users),
);

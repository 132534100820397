import * as React from 'react';
import { makeGetChildren, makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption } from '../../types';
import styled from 'styled-components';
import { cssVars } from '../../constants';
import Row from './Row';
import {
  filterChildrenByPublished,
  getOptionTitle,
} from '../../helpers/formOption';

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  box-shadow: -1px 4px 10px 3px rgba(0, 0, 0, 0.1);
`;

const Header = styled.header`
  background: ${cssVars.colors['form-option-row']};
  padding: ${cssVars.padding[4]};
  text-transform: uppercase;
  font-size: ${cssVars.textSizes.sm};
`;

const Body = styled.div`
  background: white;
  padding: ${cssVars.padding[4]};
`;

interface Props {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
}

const Section = ({ option, childOptions, live }: Props) => {
  if (!option) {
    return null;
  }

  const filteredChildren = live
    ? filterChildrenByPublished(childOptions)
    : childOptions || [];

  return (
    <Container>
      <Header>
        <h2>{getOptionTitle(option, { live })}</h2>
      </Header>
      <Body>
        {filteredChildren.map(o => (
          <Row key={o.id} optionId={o.id} live={live} />
        ))}
      </Body>
    </Container>
  );
};

const makeMapStateToProps = () => {
  const getOption = makeGetOption();
  const getChildren = makeGetChildren();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    childOptions: getChildren(state, ownProps),
  });
};

export default connect(makeMapStateToProps)(Section);

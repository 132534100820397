import * as React from 'react';
import ModalHeader from './modal/ModalHeader';
import ModalBody from './modal/ModalBody';
import ModalFooter from './modal/ModalFooter';
import Button from './Button';
import Modal from './Modal';

interface Props {
  title: string;
  body: string;
  contentLabel: string;
  handleClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  loading?: boolean;
}

export default class ConfirmationDialogue extends React.Component<Props> {
  public render() {
    const {
      title,
      body,
      contentLabel,
      handleClose,
      onConfirm,
      isOpen,
      loading,
    } = this.props;

    return (
      <Modal
        contentLabel={contentLabel}
        handleClose={handleClose}
        isOpen={isOpen}
      >
        <ModalHeader handleClose={handleClose}>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter right={true}>
          <Button type="default" onClick={handleClose} className={'mr-3'}>
            Cancel
          </Button>
          <Button type="primary" onClick={onConfirm} loading={loading}>
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

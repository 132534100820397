import api from './api';

const workRoleApi = {
  delete: (workRoleUid: string) => api('DELETE', `work-roles/${workRoleUid}`),
  update: (workRoleUid: string, data: { name: string }) =>
    api('PATCH', `work-roles/${workRoleUid}`, data),
  markCertTypeAsRequired: (workRoleUid: string, certTypeUid: string) =>
    api('PUT', `work-roles/${workRoleUid}/certificates/${certTypeUid}`),
  markCertTypeAsOptional: (workRoleUid: string, certTypeUid: string) =>
    api('DELETE', `work-roles/${workRoleUid}/certificates/${certTypeUid}`),
};

export default workRoleApi;

import { createAction } from 'typesafe-actions';
import { PermissionsError, WorkRole } from '../types';
import {
  WORK_ROLES_ADD,
  WORK_ROLES_FETCH_FAILURE,
  WORK_ROLES_FETCH_REQUEST,
  WORK_ROLES_FETCH_SUCCESS,
  WORK_ROLES_REMOVE,
  WORK_ROLES_SET,
  WORK_ROLES_UPDATE,
} from '../constants/actionTypes';

export const workRolesFetchRequest = createAction(
  WORK_ROLES_FETCH_REQUEST,
  resolve => (companyUid: string) => resolve(companyUid),
);

export const workRolesFetchSuccess = createAction(
  WORK_ROLES_FETCH_SUCCESS,
  resolve => (workRoles: WorkRole[]) => resolve(workRoles),
);

export const workRolesFetchFailure = createAction(
  WORK_ROLES_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const addWorkRole = createAction(
  WORK_ROLES_ADD,
  resolve => (workRole: WorkRole) => resolve(workRole),
);

export const setWorkRoles = createAction(
  WORK_ROLES_SET,
  resolve => (workRoles: WorkRole[]) => resolve(workRoles),
);

export const updateWorkRole = createAction(
  WORK_ROLES_UPDATE,
  resolve => (workRole: WorkRole) => resolve(workRole),
);

export const removeWorkRole = createAction(
  WORK_ROLES_REMOVE,
  resolve => (workRoleUid: string) => resolve(workRoleUid),
);

import { api2 } from './api';
import { Submission, ParsedSubmData, User, Site, Action } from '../types';

export interface SubmissionActionItem {
  action: Action;
  user: User;
  site?: Site;
  creator: User;
}

const submissionApi = {
  show: (submissionUid: string) =>
    api2<{ submission: Submission }>('GET', `submissions/${submissionUid}`),
  send: (submissionUid: string, { emails }: { emails: string[] }) =>
    api2<{ jobId: string }>('POST', `submissions/${submissionUid}/send`, {
      emails,
    }),
  responses: (submissionUid: string, { revId }: { revId?: number | string }) =>
    api2<{ submData: ParsedSubmData[] }>(
      'GET',
      `submissions/${submissionUid}/responses`,
      { revId },
    ),
  updateFormOption: (submUid: string, formOptionId: number, data: any) =>
    api2<{ submData: ParsedSubmData }>(
      'PUT',
      `submissions/${submUid}/form-options/${formOptionId}`,
      { data },
    ),
  uploadFormOptionFile: (submUid: string, formOptionId: number, data: any) =>
    api2<{ submData: ParsedSubmData }>(
      'POST',
      `submissions/${submUid}/form-options/${formOptionId}/uploads`,
      data,
    ),
  removeFormOptionFile: (
    submUid: string,
    formOptionId: number,
    identifier?: string | number,
  ) =>
    api2<{ submData: ParsedSubmData }>(
      'DELETE',
      `submissions/${submUid}/form-options/${formOptionId}/uploads${
        identifier ? `/${identifier}` : ''
      }`,
    ),
  removeFormOptionData: (
    submUid: string,
    formOptionId: number,
    identifier: any,
  ) =>
    api2<{ submData: ParsedSubmData }>(
      'DELETE',
      `submissions/${submUid}/form-options/${formOptionId}/unset${
        identifier ? `/${identifier}` : ''
      }`,
    ),
  compliance: (submUid: string, revId?: number) =>
    api2<any>('GET', `submissions/${submUid}/compliance`, { revId }),
  actions: (submUid: string, revId?: number) =>
    api2<{
      actions: SubmissionActionItem[];
    }>('GET', `submissions/${submUid}/actions`, { revId }),
  save: (submUid: string) => api2<any>('PATCH', `submissions/${submUid}`),
  downloadUrl: (submUid: string) =>
    api2<{ url: string }>('GET', `submissions/${submUid}/download-url`),
  approve: (submUid: string) =>
    api2<any>('POST', `submissions/${submUid}/approve`),
  reject: (submUid: string) =>
    api2<any>('POST', `submissions/${submUid}/reject`),
  canReview: (submUid: string, { revId }: { revId?: string | number }) =>
    api2<any>('GET', `submissions/${submUid}/can-review`, { revId }),
  setLocation: (submUid: string, data: any) =>
    api2<any>('POST', `submissions/${submUid}/location`, { geoLocation: data }),
};

export default submissionApi;

import * as React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import ProfileDropdown from './topNavigation/ProfileDropdown';
import ChangeViewDropdown from './topNavigation/ChangeViewDropdown';
import Alarm from '../../svg/Alarm';
import Envelope from '../../svg/Envelope';
import Download from '../../svg/Download';
import { biggerThanSM } from '../../helpers/style';
import Menu from '../../svg/Menu';
import Sidebox from '../Sidebox';
import SideboxHeader from '../sidebox/SideboxHeader';
import { throttle } from 'lodash';
import { cssVars } from '../../constants';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { User, CompanyGroup } from '../../types';
import userApi from '../../api/user.api';

const Container = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  background: #fff;
  border-bottom: 1px solid #ebebeb;
  z-index: 9;
`;

const NavigationRightContainer = styled.div`
  display: none;
  flex-direction: row;
  align-items: center;

  ${biggerThanSM(`
    display: flex;
  `)};
`;

const VerticalSeparator = styled.div`
  width: 1px;
  height: 40px;
  background: #666666;
  margin: 0 10px;
`;

const SidebarTriggersContainer = styled.div`
  & svg {
    width: 16px;
  }
`;

const MobileMenuButton = styled.button`
  background: none;
  border: 0;

  ${biggerThanSM(`
    display: none;
  `)};
`;

const NotifBadge = styled.span`
  position: absolute;
  top: -2px;
  right: -7px;
  width: 14px;
  height: 14px;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background: ${cssVars.colors.red};
  font-size: ${cssVars.textSizes.xs};
`;

interface State {
  mobileMenuOpen: boolean;
  mobile: boolean;
  messageCount: number;
  notifCount: number;
}

interface Props {
  openNotifications: () => void;
  openDownloads: () => void;
  openMessages: () => void;
  onNotificationCount: (count: number) => void;
  user: User;
  companyGroup: CompanyGroup;
}

class TopNavigation extends React.Component<Props, State> {
  private notifInterval: any;

  constructor(props: Props) {
    super(props);

    this.state = {
      mobileMenuOpen: false,
      mobile: false,
      messageCount: 0,
      notifCount: 0,
    };

    this.notifInterval = null;
  }

  public componentDidMount(): void {
    this.setScreenSize();
    this.getMessageCount();
    this.getNotifCount();

    window.addEventListener('resize', this.setScreenSize);
    this.notifInterval = setInterval(() => {
      this.getMessageCount();
      this.getNotifCount();
    }, 10000);
  }

  public componentWillUnmount() {
    clearInterval(this.notifInterval);
  }

  private getMessageCount = async () => {
    const { data } = await userApi.unreadMessageCount(this.props.user.uid);
    if (data) {
      this.setState({
        messageCount: data.count,
      });
    }
  };

  private getNotifCount = async () => {
    const { data } = await userApi.unreadNotifCount(this.props.user.uid);
    if (data) {
      this.setState({
        notifCount: data.count,
      });
      this.props.onNotificationCount(data.count);
    }
  };

  private setScreenSize = throttle(() => {
    const mobile = window.innerWidth < parseInt(cssVars.screens.sm, 10);
    this.setState({
      mobile,
      mobileMenuOpen: mobile ? this.state.mobileMenuOpen : false,
    });
  }, 100);

  private closeMobileMenu = () => {
    this.setState({
      mobileMenuOpen: false,
    });
  };

  private openMobileMenu = () => {
    this.setState({
      mobileMenuOpen: true,
    });
  };

  public render() {
    const {
      openNotifications,
      openDownloads,
      openMessages,
      companyGroup,
    } = this.props;
    const { messageCount, notifCount } = this.state;
    return (
      <Container>
        <Link to={'/dashboard/worker/dashboard'}>
          {(() => {
            if (companyGroup) {
              if (companyGroup.limbach) {
                return (
                  <img
                    src={'/images/limbach.png'}
                    style={{ maxWidth: '165px' }}
                  />
                );
              }
              if (companyGroup.sam) {
                return (
                  <img src={'/images/sam.png'} style={{ maxWidth: '105px' }} />
                );
              }
            }
            return (
              <img
                src={'/images/minimal-logo-login.png'}
                style={{ height: '42px' }}
              />
            );
          })()}
        </Link>

        <NavigationRightContainer>
          <ChangeViewDropdown />

          <ProfileDropdown />

          <VerticalSeparator />

          <SidebarTriggersContainer>
            <div
              className="relative inline-block cursor-pointer"
              onClick={openNotifications}
            >
              <Alarm />
              {notifCount > 0 && (
                <NotifBadge>{notifCount > 9 ? '9+' : notifCount}</NotifBadge>
              )}
            </div>
            <div
              className="relative inline-block mx-8 cursor-pointer"
              onClick={openMessages}
            >
              <Envelope />
              {messageCount > 0 && (
                <NotifBadge>
                  {messageCount > 9 ? '9+' : messageCount}
                </NotifBadge>
              )}
            </div>
            <Download className={'cursor-pointer'} onClick={openDownloads} />
          </SidebarTriggersContainer>
        </NavigationRightContainer>

        <MobileMenuButton onClick={this.openMobileMenu}>
          <Menu className={'w-8 h-8'} />
        </MobileMenuButton>

        <Sidebox
          isOpen={this.state.mobile && this.state.mobileMenuOpen}
          contentLabel={'Mobile navigation'}
          handleClose={this.closeMobileMenu}
        >
          <SideboxHeader handleClose={this.closeMobileMenu}>
            Navigation
          </SideboxHeader>

          <div className="flex flex-row justify-between p-4 border-0 border-b-1 border-grey-darker border-solid">
            <Alarm
              className={'cursor-pointer w-8 h-8'}
              onClick={openNotifications}
            />
            <Envelope
              className={'cursor-pointer w-8 h-8'}
              onClick={openMessages}
            />
            <Download
              className={'cursor-pointer w-8 h-8'}
              onClick={openDownloads}
            />
          </div>

          <ChangeViewDropdown static={true} />

          <ProfileDropdown static={true} />
        </Sidebox>
      </Container>
    );
  }
}

export default connect((state: StoreState) => ({
  user: state.me.user,
  companyGroup: state.me.companyGroup,
}))(TopNavigation);

import * as React from 'react';
import Apartment from '../../../svg/Apartment';
import Menu from '../../../svg/Menu';
import { Link, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  colors,
  colorTypes,
  complimentaryTextColors,
  cssVars,
  localStorageKeys,
} from '../../../constants';
import Cog from '../../../svg/Cog';
import Power from '../../../svg/Power';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Company, User, CompanyGroup } from '../../../types';
import { Dispatch } from 'redux';
import { setCompany, setCompanyGroup } from '../../../actions/me';
import Avatar from '../../Avatar';
import { RouterProps, RouteComponentProps } from 'react-router';
import Select from '../../Select';
import UserIcon from '../../../svg/User';
import { boxShadow } from '../../../helpers/style';
import { getFullName } from '../../../helpers';
import { orderBy } from 'natural-orderby';

const UserProfileContainer = styled.button<{ static?: boolean }>`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  padding: ${props => (props.static ? cssVars.padding[4] : '')};
`;

const UserName = styled.h3`
  margin-bottom: 0;
`;

const CompanyName = styled.h4`
  font-weight: 300;
`;

const UserProfileDropdown = styled.div<{ static?: boolean }>`
  position: ${props => (props.static ? 'block' : 'absolute')};
  top: 65px;
  left: 0;
  display: flex;
  flex-direction: column;
  background: white;
  ${props => (props.static ? '' : boxShadow)};
  min-width: 224px;
  z-index: 9;

  ${props =>
    props.static
      ? ''
      : `
  &:before {
    content: '';
    position: absolute;
    top: -1.4rem;
    left: 10px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1.5rem;
    border-color: transparent transparent ${colors[colorTypes.PRIMARY]};
  }`};
`;

const CompanyChangeContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & svg {
    width: 15px;
  }
`;

const CompanyInfoContainer = styled.div`
  background: ${colors[colorTypes.PRIMARY]};
  color: ${complimentaryTextColors[colorTypes.PRIMARY]};
  padding: 16px;
`;

const CurrentCompanyContainer = styled.div`
  display: flex;
  align-items: center;

  & svg {
    margin-right: 5px;

    path {
      fill: ${complimentaryTextColors[colorTypes.PRIMARY]};
    }
  }
`;

const UserDropdownLinkContainer = styled.div`
  padding: 16px;
  display: flex;
  flex-direction: column;
`;

const LinkListItem = styled.div`
  padding: 10px 0;

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  & a {
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: row;
    align-items: center;

    &:hover {
      color: ${colors[colorTypes.PRIMARY]};

      svg path {
        fill: ${colors[colorTypes.PRIMARY]};
      }
    }
  }

  & svg {
    width: 12px;
    margin-right: 10px;
  }
`;

const ToggleCompanySearchButton = styled.button`
  cursor: pointer;
  background: none;
  border: 0;

  &:hover {
    path {
      fill: ${colors[colorTypes.WHITE]};
    }
  }
`;

const CompanySearchContainer = styled.div`
  margin-top: 10px;
  border-top: 1px solid ${colors[colorTypes.WHITE]};
  max-height: 200px;
  overflow: scroll;
`;

const CompanyListItem = styled.div`
  padding: 15px 5px;
  font-size: 1.25em;
  cursor: pointer;

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

interface State {
  profileDropdownOpen: boolean;
  companySearchOpen: boolean;
  search: string;
  isLoggedInAs: boolean;
}

interface Props extends RouteComponentProps {
  user: User;
  company: Company;
  companies: Company[];
  companyGroup: CompanyGroup;
  companyGroups: CompanyGroup[];
  setCompany: (company: Company) => void;
  static?: boolean;
  setCompanyGroup: (companyGroup: CompanyGroup) => any;
}

class ProfileDropdown extends React.Component<Props, State> {
  public state = {
    profileDropdownOpen: false,
    companySearchOpen: false,
    search: '',
    isLoggedInAs: false,
  };

  private blurTimoutId: any = null;

  public componentDidMount() {
    const original = localStorage.getItem(localStorageKeys.ORIGINAL_USER_TOKEN);
    if (original) {
      this.setState({
        isLoggedInAs: true,
      });
    }
  }

  private stopImpersonating = (e: any) => {
    e.preventDefault();
    const originalToken = localStorage.getItem(
      localStorageKeys.ORIGINAL_USER_TOKEN,
    );
    const originalRefresh = localStorage.getItem(
      localStorageKeys.ORIGINAL_REFRESH_TOKEN,
    );
    localStorage.setItem(localStorageKeys.USER_TOKEN, originalToken || '');
    localStorage.setItem(localStorageKeys.REFRESH_TOKEN, originalRefresh || '');
    localStorage.removeItem(localStorageKeys.ORIGINAL_USER_TOKEN);
    localStorage.removeItem(localStorageKeys.ORIGINAL_REFRESH_TOKEN);
    window.location.href = '/';
  };

  private toggleProfileDropdown = () => {
    this.setState({
      profileDropdownOpen: !this.state.profileDropdownOpen,
      companySearchOpen: false,
    });
  };

  private closeDropdown = () => {
    this.setState({
      profileDropdownOpen: false,
      companySearchOpen: false,
    });
  };

  private toggleCompanySearch = () => {
    this.setState({
      companySearchOpen: !this.state.companySearchOpen,
    });
  };

  private selectCompany = (company: Company) => {
    localStorage.setItem(localStorageKeys.CURRENT_COMPANY_UID, company.uid);
    this.props.setCompany(company);
  };

  private selectCompanyGroup = (companyGroup: CompanyGroup) => {
    localStorage.setItem(
      localStorageKeys.CURRENT_COMPANY_GROUP_UID,
      companyGroup.uid,
    );
    this.props.setCompanyGroup(companyGroup);
  };

  private isCompany = () => {
    return window.location.href.indexOf('/dashboard/company-admin') === -1;
  };

  private handleBlur = () => {
    this.blurTimoutId = setTimeout(() => this.closeDropdown());
  };

  private handleFocus = () => {
    clearTimeout(this.blurTimoutId);
  };

  private onSearch = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({ search: e.currentTarget.value });
  };

  public render() {
    const {
      user,
      company,
      companyGroup,
      companies,
      companyGroups,
    } = this.props;

    return (
      <div
        style={{ position: 'relative' }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        tabIndex={0}
      >
        <UserProfileContainer
          static={this.props.static}
          onClick={this.toggleProfileDropdown}
          aria-haspopup="true"
          aria-expanded={this.state.profileDropdownOpen}
        >
          <Avatar
            bgColor={user.profile_color ? user.profile_color[0] : undefined}
            fgColor={user.profile_color ? user.profile_color[1] : undefined}
            fallbackName={user.username}
            size={'LG'}
            user={user}
          />
          <div>
            <UserName>{getFullName(user)}</UserName>
            <CompanyName>
              {this.isCompany() ? company.name : companyGroup.name}
            </CompanyName>
          </div>
        </UserProfileContainer>
        {(this.state.profileDropdownOpen || this.props.static) && (
          <UserProfileDropdown static={this.props.static}>
            <CompanyInfoContainer>
              <CompanyChangeContainer>
                <CurrentCompanyContainer>
                  <Apartment />
                  <span>
                    {this.isCompany() ? company.name : companyGroup.name}
                  </span>
                </CurrentCompanyContainer>
                <ToggleCompanySearchButton
                  onClick={this.toggleCompanySearch}
                  aria-haspopup="true"
                  aria-expanded={this.state.companySearchOpen}
                  aria-controls="companyListDropdown"
                >
                  <Menu />
                </ToggleCompanySearchButton>
              </CompanyChangeContainer>
              {this.state.companySearchOpen && (
                <CompanySearchContainer id={'companyListDropdown'}>
                  <input
                    className="w-full p-2 border-1 border-solid border-white bg-transparent"
                    onChange={this.onSearch}
                    value={this.state.search}
                  />
                  {orderBy(
                    this.isCompany() ? companies : companyGroups,
                    [(x: Company | CompanyGroup) => x.name],
                    ['asc'],
                  )
                    .filter(c =>
                      c.name
                        .toLowerCase()
                        .includes(this.state.search.toLowerCase()),
                    )
                    .map(c => (
                      <CompanyListItem
                        key={c.uid}
                        onClick={() =>
                          this.isCompany()
                            ? this.selectCompany(c)
                            : this.selectCompanyGroup(c)
                        }
                        tabIndex={0}
                      >
                        {c.name}
                      </CompanyListItem>
                    ))}
                </CompanySearchContainer>
              )}
            </CompanyInfoContainer>
            {!this.state.companySearchOpen && (
              <UserDropdownLinkContainer>
                <LinkListItem>
                  <Link to={'/dashboard/worker/profile'}>
                    <Cog />
                    My Profile
                  </Link>
                </LinkListItem>
                {this.state.isLoggedInAs && (
                  <LinkListItem>
                    <a href="#" onClick={this.stopImpersonating}>
                      <UserIcon />
                      Stop Impersonating
                    </a>
                  </LinkListItem>
                )}
                <LinkListItem>
                  <Link to={'/logout'}>
                    <Power /> Logout
                  </Link>
                </LinkListItem>
              </UserDropdownLinkContainer>
            )}
          </UserProfileDropdown>
        )}
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    user: state.me.user,
    company: state.me.company,
    companies: state.me.companies,
    companyGroup: state.me.companyGroup,
    companyGroups: state.me.companyGroups,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setCompany: (company: Company) => dispatch(setCompany(company)),
    setCompanyGroup: (companyGroup: CompanyGroup) =>
      dispatch(setCompanyGroup(companyGroup)),
  };
}

export default withRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ProfileDropdown),
);

import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const Folder = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M0 4c0-1.1.9-2 2-2h7l2 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z" />
  </svg>
);

export default BaseSVG(Folder);

import { api2 } from './api';
import {
  Form,
  Company,
  Site,
  SubmData,
  SubmRev,
  Submission,
  Deployment,
  User,
  Crew,
} from '../types';

const fcApi = {
  show: (fcUid: string) =>
    api2<{
      form: Form;
      company: Company;
      site: Site;
      workingCopy: Submission;
      deployment: Deployment;
      deployee: Site | Crew | User;
    }>('GET', `fc/${fcUid}`),
  createIfNotExists: (deploymentUid: string, deployeeUid: string) =>
    api2<{ fc: { uid: string } }>(
      'POST',
      `deployments/${deploymentUid}/deployees/${deployeeUid}/fc`,
    ),
  responses: (fcUid: string) =>
    api2<{ submData: SubmData[] }>('GET', `fc/${fcUid}/responses`),
  submit: (fcUid: string, data: { all?: true } = {}) =>
    api2('POST', `fc/${fcUid}/submit`, data),
  history: (fcUid: string) =>
    api2<Array<{ submission: Submission; revs?: SubmRev[] }>>(
      'GET',
      `fc/${fcUid}/submissions`,
    ),
};

export default fcApi;

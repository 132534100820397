import styled from 'styled-components';
import { cssVars } from '../../constants';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: ${cssVars.margin[4]};
`;

export const HeaderWrapper = styled.header`
  display: flex;
  flex-direction: row;
  align-items: stretch;
`;

export const DragWrapper = styled.div`
  padding: ${cssVars.padding[2]};
  cursor: grab;
  background: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      opacity: 0.8;
    }

    > div {
      transform: scale(1.1);
    }
  }

  & svg {
    width: 18px;
    height: 18px;
  }
`;

export const DragHandler = styled.div`
  width: 8px;
  height: 28px;
  background: url(/images/handler_dots.svg);
  transition: 100ms all linear;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${cssVars.padding[2]};
  width: 100%;
`;

export const HeaderActions = styled.div`
  display: flex;
  flex-direction: row;
`;

export const HeaderAction = styled.button`
  border: none;
  padding: ${cssVars.padding[4]};
  border-left: 1px solid ${cssVars.colors.border};
  cursor: pointer;
`;

export const Body = styled.div`
  border-top: 1px solid ${cssVars.colors['almost-black']};
  padding: ${cssVars.padding[4]};
`;

export const TitleInput = styled.input`
  flex: 1;
  height: 100%;
  border: 0;
  padding: 0 ${cssVars.padding[2]};
`;

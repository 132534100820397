import axios from 'axios';
import history from '../services/history';
import { localStorageKeys } from '../constants';
import { toast } from 'react-toastify';
import { socket } from '../helpers/socket';
import qs from 'qs';
import { get } from 'lodash';
import * as Sentry from '@sentry/browser';
import store from '../store';
import getAuth0Client from '../auth0';

const instance = axios.create();

let isRefreshing = false;
let subscribers: any = [];

function onRefreshError() {
  toast.dismiss();
  toast.error('Your session has expired, please re-authenticate');
  return history.push('/logout');
}

function onRefreshSuccess(token: string) {
  onRrefreshed(token);
  localStorage.setItem(localStorageKeys.USER_TOKEN, token);
  subscribers = [];
}

instance.interceptors.response.use(undefined, err => {
  const { config, response } = err;
  const originalRequest = config;

  if (!response && process.env.NODE_ENV === 'production') {
    Sentry.captureException(err);
  }

  if (
    response &&
    response.status === 400 &&
    response.data &&
    response.data.errors &&
    response.data.errors.token
  ) {
    if (!isRefreshing) {
      isRefreshing = true;
      const { user } = store.getState().me;
      if (user && user.authentication_provider === 'sso') {
        getAuth0Client()
          .then(client => client.getTokenSilently())
          .then(token => {
            isRefreshing = false;
            onRefreshSuccess(token);
          })
          .catch(() => {
            isRefreshing = false;
            onRefreshError();
          });
      } else {
        const refreshToken = localStorage.getItem(
          localStorageKeys.REFRESH_TOKEN,
        );
        api2<{ token: string }>('POST', 'refresh', {
          refreshToken,
        }).then(res => {
          const { data, errors } = res;
          isRefreshing = false;
          if (!data || errors) {
            return onRefreshError();
          }
          onRefreshSuccess(data.token);
        });
      }
    }
    const requestSubscribers = new Promise(resolve => {
      subscribeTokenRefresh((token: any) => {
        originalRequest.headers.Authorization = `Bearer ${token}`;
        resolve(axios(originalRequest));
      });
    });
    return requestSubscribers;
  }
  return Promise.reject(err);
});

function subscribeTokenRefresh(cb: any) {
  subscribers.push(cb);
}

function onRrefreshed(token: any) {
  subscribers.map((cb: any) => cb(token));
}

export const baseApi = async (
  method: string,
  url: string,
  data: any = {},
  options: any = {},
) => {
  const token = localStorage.getItem(localStorageKeys.USER_TOKEN);

  const contentType =
    data instanceof FormData ? 'multipart/form-data' : 'application/json';

  try {
    const response = await instance({
      method: method.toLowerCase(),
      url,
      ...(method.toLowerCase() === 'get' ? { params: data } : { data }),
      headers: {
        Authorization: token ? `Bearer ${token}` : '',
        'Content-Type': contentType,
        'X-Socket-Id': socket.id,
        'X-Seconds-Offset': new Date().getTimezoneOffset() * 60,
      },
      paramsSerializer: params => {
        return qs.stringify(data);
      },
      ...options,
    });

    return response.data;
  } catch (e) {
    const errorData = get(e, 'response.data', null);

    if (!errorData) {
      if (process.env.NODE_ENV === 'production') {
        Sentry.captureException(e);
      }
      return Promise.reject({ ...e, errors: {} });
    }

    const status = get(e, 'response.status', null);

    if (errorData.errors.token === 'token_expired' && status === 401) {
      // TODO: refresh token
      toast.dismiss();
      toast.error('Your session has expired, please re-authenticate');
      return history.push('/logout');
    } else if (errorData.errors.token === 'token_invalid' && status === 400) {
      toast.dismiss();
      toast.error('Your session has expired, please re-authenticate');
      return history.push('/logout');
    }

    let errors = {};

    if (e && e.response && e.response.data && e.response.data.errors) {
      errors = e.response.data.errors;
    }

    return Promise.reject({ ...e, errors });
  }
};

const api = async (
  method: string,
  url: string,
  data: any = {},
  options: any = {},
): Promise<any> =>
  baseApi(method, `${process.env.API_URL}/v1/${url}`, data, options);

export const api2 = async <T>(
  method: string,
  url: string,
  data: any = {},
  options: any = {},
): Promise<{ data: T | undefined; errors: any | undefined }> => {
  try {
    const res = await api(method, url, data, options);
    return { data: res, errors: undefined };
  } catch (e) {
    return { data: undefined, errors: get(e, 'errors', e) };
  }
};

export default api;

import { api2 } from './api';
import { SubmData, Submission, Form, SubmRev } from '../types';

const submRevApi = {
  show: (revId: number) =>
    api2<{
      submission: Submission;
      form: Form;
      meta: {
        company_name: string;
        site_name: string;
        form_name: string;
        deploy_name: string;
        deploy_type: string;
      };
      rev: SubmRev;
    }>('GET', `subm-revs/${revId}`),
  responses: (revId: string | number) =>
    api2<{ submData: SubmData[] }>('GET', `subm-revs/${revId}/responses`),
};

export default submRevApi;

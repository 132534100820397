import * as React from 'react';
import User from '../../../svg/User';
import ChevronDown from '../../../svg/ChevronDown';
import Trophy from '../../../svg/Trophy';
import Suitcase from '../../../svg/Suitcase';
import Key from '../../../svg/Key';
import styled from 'styled-components';
import {
  NavLink,
  Route,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { User as IUser, AuthPermissions, Company } from '../../../types';
import { boxShadow } from '../../../helpers/style';

const ViewChangeTrigger = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;

  & svg {
    width: 20px;
    height: auto;
    margin-right: 10px;
  }
`;

const ViewSelectWrapper = styled.div<{ static?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: white;
  padding: 12px;
  z-index: 9;

  ${props =>
    props.static
      ? ''
      : `
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  ${boxShadow}
  &:before {
    content: '';
    position: absolute;
    top: -1.4rem;
    left: 0;
    right: 0;
    margin: 0 auto;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 1rem 1.5rem;
    border-color: transparent transparent #fff;
  }`};
`;

const ViewSelectItem = styled(NavLink)<{ active?: boolean }>`
  text-align: center;
  margin-right: 20px;
  padding: 5px;
  cursor: pointer;
  color: #afafaf;
  border: 1px solid transparent;
  background: white;
  text-decoration: none;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    border: 1px solid #ededed;
    color: black;
    svg path {
      fill: black;
    }
  }

  &.active {
    border: 1px solid #ededed;

    svg path {
      fill: black;
    }
  }

  & svg {
    width: 20px;
    margin-bottom: 10px;
  }

  ${props => (props.active ? `color: black;` : '')};
`;

interface State {
  selectViewOpen: boolean;
}

interface Props extends RouteComponentProps {
  static?: boolean;
  user: IUser;
  company: Company;
  permissions: AuthPermissions;
}

class ChangeViewDropdown extends React.Component<Props, State> {
  public state = {
    selectViewOpen: false,
  };

  private blurTimoutId: any = null;

  private toggleSelectView = () => {
    this.setState({
      selectViewOpen: !this.state.selectViewOpen,
    });
  };

  private handleBlur = () => {
    this.blurTimoutId = setTimeout(() => this.closeDropdown());
  };

  private handleFocus = () => {
    clearTimeout(this.blurTimoutId);
  };

  private closeDropdown = () => {
    this.setState({
      selectViewOpen: false,
    });
  };

  private canSeeSuper = () => {
    if (this.canSeeAdmin()) {
      return true;
    }
    const company = this.props.permissions.companies.find(
      x => x.companyUid === this.props.company.uid,
    );
    if (!company) {
      return false;
    }
    return company.roles.indexOf('super') !== -1;
  };
  private canSeeCompanyAdmin = () => {
    if (this.canSeeAdmin()) {
      return true;
    }
    const result = this.props.permissions.companyGroups.find(
      x => x.roles.indexOf('companyAdmin') !== -1,
    );
    return !!result;
  };
  private canSeeAdmin = () => {
    return this.props.user.has_wildcard_access;
  };

  public render() {
    return (
      <div
        style={{ position: 'relative' }}
        onBlur={this.handleBlur}
        onFocus={this.handleFocus}
        tabIndex={0}
      >
        {!this.props.static && (
          <ViewChangeTrigger
            onClick={this.toggleSelectView}
            aria-haspopup="true"
            aria-expanded={this.state.selectViewOpen}
            aria-controls={'viewSelectContainer'}
          >
            <Route path={'/dashboard/worker'} component={User} />
            <Route path={'/dashboard/super'} component={Trophy} />
            <Route path={'/dashboard/company-admin'} component={Suitcase} />
            <Route path={'/dashboard/admin'} component={Key} />
            <ChevronDown />
          </ViewChangeTrigger>
        )}
        {(this.state.selectViewOpen || this.props.static) && (
          <ViewSelectWrapper
            id={'viewSelectContainer'}
            static={this.props.static}
          >
            <ViewSelectItem
              to={'/dashboard/worker'}
              onClick={this.closeDropdown}
            >
              <User />
              Worker
            </ViewSelectItem>
            {this.canSeeSuper() && (
              <ViewSelectItem
                to={'/dashboard/super'}
                onClick={this.closeDropdown}
              >
                <Trophy />
                Super
              </ViewSelectItem>
            )}
            {this.canSeeCompanyAdmin() && (
              <ViewSelectItem
                to={'/dashboard/company-admin'}
                onClick={this.closeDropdown}
              >
                <Suitcase />
                Company
              </ViewSelectItem>
            )}
            {this.canSeeAdmin() && (
              <ViewSelectItem
                to={'/dashboard/admin'}
                onClick={this.closeDropdown}
              >
                <Key />
                Admin
              </ViewSelectItem>
            )}
          </ViewSelectWrapper>
        )}
      </div>
    );
  }
}

export default withRouter(
  connect((store: StoreState) => ({
    user: store.me.user,
    permissions: store.me.permissions,
    company: store.me.company,
  }))(ChangeViewDropdown),
);

import * as React from 'react';
import styled from 'styled-components';
import {
  colors,
  colorTypes,
  complimentaryTextColors,
  cssVars,
} from '../../constants';
import CloseOutline from '../../svg/CloseOutline';

const Wrapper = styled.header`
  background: ${colors[colorTypes.DEFAULT]};
  color: ${complimentaryTextColors[colorTypes.PRIMARY]};
  padding: ${cssVars.padding[5]};
  position: relative;
  font-weight: 600;
  font-size: 1.25em;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 12px;
  top: 50%;
  transform: translateY(-50%);
  width: 12px;
  height: auto;
  cursor: pointer;
  background: transparent;
  border: none;

  & path {
    fill: white;
  }
`;

interface Props {
  handleClose: () => any;
}

export default class ModalHeader extends React.Component<Props> {
  public render() {
    return (
      <Wrapper>
        {this.props.children}

        <CloseButton onClick={this.props.handleClose} aria-label={'Close'}>
          <CloseOutline />
        </CloseButton>
      </Wrapper>
    );
  }
}

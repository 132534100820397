import * as React from 'react';
import ReactSelect from 'react-select';
import { StylesConfig } from 'react-select/lib/styles';
import { colors } from '../constants';
import { Option } from 'react-select/lib/filters';
import { orderBy } from 'natural-orderby';

export interface SelectOptionObject {
  value: string;
  label: string;
}

export type SelectOption = SelectOptionObject | null;

export interface Props {
  options: SelectOption[];
  value?: SelectOption | SelectOptionObject[];
  styles?: StylesConfig;
  onChange?: any;
  onInput?: any;
  onKeyDown?: any;
  id?: string;
  tabIndex?: string;
  disabled?: boolean;
  multi?: boolean;
  menuPosition?: string;
  menuPortalTarget?: any;
  closeMenuOnSelect?: boolean;
}

const customSelectStlyes = {
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    zIndex: 10,
  }),
  menuPortal: (base: any) => ({
    ...base,
    borderRadius: 0,
    zIndex: 10,
  }),
  control: (base: any) => ({
    ...base,
    borderRadius: 0,
  }),
  option: (base: any, state: any) => ({
    ...base,
    background: state.isFocused ? colors.PRIMARY : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      background: colors.PRIMARY,
      color: 'white',
    },
    '&.active': {
      background: colors.PRIMARY,
      color: 'white',
    },
  }),
};

export default class Select extends React.Component<Props> {
  private filterOption = (option: Option, input: string) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) > -1;
  };
  public render() {
    return (
      <ReactSelect
        options={orderBy(
          this.props.options,
          [x => (x ? x.label : null)],
          ['asc'],
        )}
        styles={customSelectStlyes}
        onChange={this.props.onChange}
        onInputChange={this.props.onInput}
        onKeyDown={this.props.onKeyDown}
        inputId={this.props.id}
        tabIndex={this.props.tabIndex}
        value={this.props.value}
        isDisabled={this.props.disabled}
        isMulti={this.props.multi}
        menuPosition={this.props.menuPosition}
        menuPortalTarget={this.props.menuPortalTarget}
        closeMenuOnSelect={this.props.closeMenuOnSelect}
        filterOption={this.filterOption}
      />
    );
  }
}

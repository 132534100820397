import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const Upload = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z" />
  </svg>
);

export default BaseSVG(Upload);

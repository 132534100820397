import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import Tabs from '../../components/Tabs';
import ActionsList from './actions/ActionsList';
import ActionCreate from '../../containers/ActionCreate';
import {
  useMountVisibility,
  useSimpleFetch,
  useEntityRefresh,
} from '../../helpers/hooks';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Company, User, Action } from '../../types';
import userApi, { MyActionsResponse } from '../../api/user.api';
import Loading from '../../components/Loading';
import { History } from 'history';
import { SitesState } from '../../reducers/sites';
import { UsersState } from '../../reducers/users';
import { Dispatch } from 'redux';
import { sitesFetchRequest } from '../../actions/sites';
import { usersFetchRequest } from '../../actions/users';

interface Props {
  company: Company;
  user: User;
  history: History;
  sites: SitesState;
  users: UsersState;
  getSites: (companyUid: string) => any;
  getUsers: (companyUid: string) => any;
}

const Actions = ({
  user,
  company,
  history,
  sites,
  users,
  getSites,
  getUsers,
}: Props) => {
  useEntityRefresh(company.uid, users, getUsers);
  useEntityRefresh(company.uid, sites, getSites);
  const createState = useMountVisibility();
  const myActions = useSimpleFetch<MyActionsResponse[]>({
    initial: [],
    change: [company.uid],
    fetch: () => userApi.createdActions(user.uid, company.uid),
    accessor: 'createdActions',
  });
  const assignedActions = useSimpleFetch<MyActionsResponse[]>({
    initial: [],
    change: [company.uid],
    fetch: () => userApi.assignedActions(user.uid, company.uid),
    accessor: 'assignedActions',
  });

  const tabs = [
    {
      label: 'My Actions',
      component: (
        <ActionsList
          title={'Actions I Created'}
          onCreateClick={createState.open}
          actions={myActions.value.filter(x => !x.action.completed)}
        />
      ),
    },
    {
      label: 'Related to me',
      component: (
        <ActionsList
          title={'Actions Assigned to Me'}
          onCreateClick={createState.open}
          actions={assignedActions.value.filter(x => !x.action.completed)}
        />
      ),
    },
    {
      label: 'Completed',
      component: (
        <ActionsList
          title={'Completed Actions'}
          onCreateClick={createState.open}
          actions={[...assignedActions.value, ...myActions.value].filter(
            x => x.action.completed,
          )}
        />
      ),
    },
  ];

  const onCreate = (action: Action) => {
    createState.close();
    history.push(`/dashboard/worker/actions/${action.uid}`);
  };

  return (
    <Page>
      <Loading loading={myActions.loading || assignedActions.loading} />
      <PageContent>
        <Tabs tabs={tabs} raised={true} />
      </PageContent>

      {createState.mounted && (
        <ActionCreate
          isOpen={createState.visible}
          handleClose={createState.close}
          companyUid={company.uid}
          onCreate={onCreate}
          sites={sites.data}
          users={users.data}
        />
      )}
    </Page>
  );
};

export default connect(
  (state: StoreState) => ({
    company: state.me.company,
    user: state.me.user,
    sites: state.sites,
    users: state.users,
  }),
  (dispatch: Dispatch) => ({
    getSites: (companyUid: string) => dispatch(sitesFetchRequest(companyUid)),
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  }),
)(Actions);

import * as React from 'react';
import BaseWidget from './BaseWidget';
import { HeaderAction } from './optionParentStyles';
import SelectionChild from './SelectionChild';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import formOptionApi from '../../api/formOption.api';
import { formFeatureIds } from '../../constants';
import { FormOption } from '../../types';
import { Dispatch } from 'redux';
import { addFormOption } from '../../actions/formOptions';
import { connect } from 'react-redux';

interface Props extends WithNamespaces {
  optionId: number;
  openDeleteOption?: (optionId: number) => any;
  formUid: string;
  addFormOption?: (option: FormOption) => any;
  index: number;
}

class Selection extends React.Component<Props> {
  private addQuestion = async () => {
    const { formUid, optionId: parentId } = this.props;

    try {
      const { option } = await formOptionApi.create({
        formUid,
        parentId,
        typeId: formFeatureIds.SELECTION_ITEM,
      });

      if (this.props.addFormOption) {
        this.props.addFormOption(option);
      }
    } catch (e) {
      //
    }
  };

  public render() {
    const { optionId, t, openDeleteOption, index } = this.props;

    return (
      <BaseWidget
        optionId={optionId}
        defaultTitle={t('common:formWidgets.selection')}
        openDeleteOption={openDeleteOption}
        extraActions={[
          <HeaderAction key={0} onClick={this.addQuestion}>
            {t('super:screens.form.addQuestion')}
          </HeaderAction>,
        ]}
        renderChild={(id: number, i) => (
          <SelectionChild
            key={id}
            optionId={id}
            index={i}
            openDeleteOption={openDeleteOption}
          />
        )}
        index={index}
      />
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  addFormOption: (option: FormOption) => dispatch(addFormOption(option)),
});

export default withNamespaces()(
  connect(
    null,
    mapDispatchToProps,
  )(Selection),
);

import * as React from 'react';
import { Form } from '../../../types';
import File from '../../../svg/File';
import styled from 'styled-components';
import { cssVars } from '../../../constants/index';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import fcApi from '../../../api/fc.api';
import { useMountVisibility } from '../../../helpers/hooks';
import PickDraft from './PickDraft';

interface Props extends RouteComponentProps {
  form: Form;
  fc?: { uid: string };
  deployment: any;
  deployee: any;
}

const Inner = styled.div<{ type: string }>`
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 40px 40px 0 0;
  border-color: ${props => cssVars.colors[`form-${props.type}`]} transparent
    transparent;
  z-index: 1;
`;

const Form2 = styled.div`
  position: absolute;
  left: 5px;
  top: 3px;
  z-index: 2;
`;

const Wrapper = styled.div`
  border-radius: 4px;
  overflow: hidden;
`;

const FormItem = ({ form, fc, history, deployee, deployment }: Props) => {
  const creating = React.useRef(false);
  const draftModal = useMountVisibility();

  const onClick = async (version: boolean) => {
    if (version) {
      draftModal.open();
      return;
    }

    // prevent accidental double+ click creating multiple fcs
    if (!creating.current) {
      let fcUid = fc ? fc.uid : '';
      if (!fcUid) {
        creating.current = true;
        const { data } = await fcApi.createIfNotExists(
          deployment.uid,
          deployee.uid,
        );
        if (data) {
          fcUid = data.fc.uid;
        }
      }
      history.push(`/dashboard/worker/forms/${fcUid}`);
      creating.current = false;
    }
  };

  const onDraftSelect = (instanceUid: string) => {
    draftModal.close();
    history.push(`/dashboard/worker/formsV2/${instanceUid}`);
  };

  return (
    <Wrapper
      className={
        'bg-grey p-4 text-center m-4 relative cursor-pointer hover:bg-grey-light'
      }
      style={{ width: '116px' }}
      onClick={() => onClick(form.v2)}
    >
      <Inner type={form.type} />
      <Form2 className={form.v2 ? '' : 'hidden'}>V2</Form2>
      <File className={'w-10 h-10 mb-4 text-grey-darker'} />
      <p>{form.name}</p>

      {draftModal.mounted && (
        <PickDraft
          isOpen={draftModal.visible}
          handleClose={draftModal.close}
          deployeeUid={deployee.uid}
          deploymentUid={deployment.uid}
          onSelect={onDraftSelect}
        />
      )}
    </Wrapper>
  );
};

export default withRouter<Props>(FormItem);

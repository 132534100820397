import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import StackedSelect from '../../../components/StackedSelect';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import { useRequiredSelectInput } from '../../../helpers/hooks';
import companyApi from '../../../api/company.api';
import { toast } from 'react-toastify';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  formUid: string;
  companyUid: string;
  onSuccess: () => void;
}

const ApplyAll = ({
  isOpen,
  handleClose,
  companyUid,
  formUid,
  onSuccess,
}: Props) => {
  const options = [
    { label: 'Crews', value: 'crew' },
    { label: 'Sites', value: 'site' },
    { label: 'Users', value: 'user' },
  ];
  const type = useRequiredSelectInput(options[0]);
  const [loading, setLoading] = React.useState(false);

  const onSubmit = async () => {
    setLoading(true);
    const { data } = await companyApi.applyAllForm(
      companyUid,
      formUid,
      type.selected,
    );
    setLoading(false);
    if (data) {
      toast.success(`Applying form to all ${type.value.label}`);
      onSuccess();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Apply all form"
    >
      <ModalHeader handleClose={handleClose}>Mass Assign Form</ModalHeader>
      <ModalBody>
        <StackedSelect
          options={options}
          label="Apply to all:"
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          {...type}
        />
        <p className="text-grey-darker mt-2 text-sm">
          Changes may take a few minutes to apply.
        </p>
      </ModalBody>
      <ModalFooter right={true}>
        <Button type="default" onClick={onSubmit} loading={loading}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ApplyAll;

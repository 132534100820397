import { call, takeLatest, put } from 'redux-saga/effects';
import companyApi from '../api/company.api';
import { sitesFetchSuccess, sitesFetchFailure } from '../actions/sites';
import { SITES_FETCH_REQUEST } from '../constants/actionTypes';

export function* getSites(params: any) {
  const { data, errors } = yield call(companyApi.sites, params.payload);
  if (data) {
    yield put(sitesFetchSuccess(data.sites));
  }
  if (errors) {
    yield put(sitesFetchFailure(errors));
  }
}

export function* watchGetSites() {
  yield takeLatest(SITES_FETCH_REQUEST, getSites);
}

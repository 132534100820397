import * as React from 'react';
import { useSimpleFetch, useMountVisibility } from '../../helpers/hooks';
import incidentApi from '../../api/incident.api';
import moment from 'moment';
import { Submission, Crew, User, Form, FC, Site } from '../../types';
import Button from '../../components/Button';
import OptionTitle from '../form/OptionTitle';
import Trash from '../../svg/Trash';
import IncidentSubmissionsSidebox from './IncidentSubmissionsSidebox';
import NewSubmissionText from '../../components/NewSubmissionText';
import { getFullName } from '../../helpers/index';

interface Props {
  incidentUid: string;
  sites: Site[];
  crews: Crew[];
  forms: Form[];
  editing: boolean;
}

export interface IncidentSubmission {
  submission: Submission;
  deployee: Crew | User | Site;
  form: Form;
  fc: FC;
  site: Site;
  user: User;
}

const IncidentSubmissions = ({
  incidentUid,
  forms,
  sites,
  crews,
  editing,
}: Props) => {
  const submissions = useSimpleFetch<IncidentSubmission[]>({
    initial: [],
    change: [incidentUid],
    fetch: () => incidentApi.submissions(incidentUid),
    accessor: 'submissions',
  });

  const sideboxState = useMountVisibility();

  const removeSubmission = async (uid: string) => {
    submissions.setValue(
      submissions.value.filter(item => item.submission.uid !== uid),
    );
    await incidentApi.detachSubmission(incidentUid, uid);
  };

  const onSelect = (item: IncidentSubmission) => {
    submissions.setValue([...submissions.value, item]);
  };

  return (
    <div>
      <div className="flex flex-row items-center">
        <OptionTitle title={'Submissions'} />
        <Button type={'primary'} onClick={sideboxState.open}>
          View
        </Button>
      </div>
      <div className="p-4">
        {submissions.value.map(item => (
          <div key={item.submission.uid} className={'flex items-center mb-4'}>
            <a
              className="flex-1 bg-grey-lighter p-4 no-underline text-black"
              href={`/dashboard/worker/forms/${item.fc.uid}/submission/${
                item.submission.uid
              }`}
              target={'_blank'}
            >
              <p className={'mb-2'}>
                <NewSubmissionText
                  user={getFullName(item.user)}
                  form={item.form.name}
                  site={item.site.name}
                  deployee={
                    (item.deployee as Crew).name ||
                    getFullName(item.deployee as User)
                  }
                />
              </p>
              <p className={'text-grey-darker'}>
                {moment
                  .unix(item.submission.created_at)
                  .format('MMMM Do, YYYY hh:mm a')}
              </p>
            </a>
            {editing && (
              <Trash
                onClick={() => removeSubmission(item.submission.uid)}
                className={
                  'w-6 h-6 text-red cursor-pointer hover:text-black ml-4'
                }
              />
            )}
          </div>
        ))}
      </div>

      {sideboxState.mounted && (
        <IncidentSubmissionsSidebox
          isOpen={sideboxState.visible}
          handleClose={sideboxState.close}
          crews={crews}
          sites={sites}
          forms={forms}
          onSelect={onSelect}
          selectedSubmissionUids={submissions.value.map(x => x.submission.uid)}
          incidentUid={incidentUid}
        />
      )}
    </div>
  );
};

export default IncidentSubmissions;

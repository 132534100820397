import * as React from 'react';
import PageHeading from './PageHeading';
import Select from './Select';

interface Props {
  title: string;
  items: any[];
  children: (state: { items: any[] }) => JSX.Element | JSX.Element[];
}

export default class ResourceList extends React.Component<Props> {
  public render() {
    const { title, items, children } = this.props;

    // todo: sort items

    return (
      <div className="p-4 bg-white">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-center">
          <PageHeading title={title} subtitle={items.length + ' available'} />

          <div className="hidden">
            <div
              className={
                'flex flex-col md:flex-row md:items-center justify-between'
              }
            >
              <label htmlFor="" className={'mr-2 mb-2 md:mb-0'}>
                Sort By:
              </label>
              <div style={{ width: '200px' }} className={'mr-2 mb-2 md:mb-0'}>
                <Select options={[]} />
              </div>
              <div style={{ width: '200px' }} className={'mr-2 mb-2 md:mb-0'}>
                <Select options={[]} />
              </div>
              <input type="text" className={'p-3'} placeholder={'search...'} />
            </div>
          </div>
        </div>

        <hr className={'my-4'} />

        {children({ items })}
      </div>
    );
  }
}

import * as crewActions from '../actions/crews';
import { ActionType, getType } from 'typesafe-actions';
import { Crew } from '../types';

export type CrewAction = ActionType<typeof crewActions>;

export interface CrewsState {
  data: Crew[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyUid: string;
  invalid: boolean;
}

const DEFAULT_STATE: CrewsState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  invalid: false,
  companyUid: '',
};

export default function crewsReducer(
  state: CrewsState = DEFAULT_STATE,
  action: CrewAction,
) {
  switch (action.type) {
    case getType(crewActions.crewsFetchRequest):
      return {
        ...state,
        loading: true,
        companyUid: action.payload,
      };
    case getType(crewActions.crewsFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...action.payload],
        invalid: false,
      };
    case getType(crewActions.crewsFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
        invalid: true,
      };
    case getType(crewActions.addCrew):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(crewActions.removeCrew):
      return {
        ...state,
        data: state.data.filter(crew => crew.uid !== action.payload),
      };
    case getType(crewActions.crewsInvalidate):
      return {
        ...state,
        invalid: true,
      };
    default:
      return state;
  }
}

import * as React from 'react';
import Button from '../../../components/Button';
import CertificateCreate from '../../../containers/CertificateCreate';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { User } from '../../../types';
import { useMountVisibility, useSimpleFetch } from '../../../helpers/hooks';
import userApi from '../../../api/user.api';
import UserCertificates from '../../../containers/UserCertificates';
import InlineAlert from '../../../components/InlineAlert';

interface Props {
  user: User;
}

const Certificates = ({ user }: Props) => {
  const createState = useMountVisibility();
  const certificates = useSimpleFetch<any>({
    initial: [],
    change: [user.uid],
    fetch: () => userApi.certs(user.uid),
    accessor: 'certificates',
  });

  const onCertCreate = () => {
    createState.close();
    certificates.performFetch();
  };

  const onDelete = () => {
    certificates.performFetch();
  };

  return (
    <div>
      <div className="flex flex-row justify-end mb-4">
        <Button
          type={'primary'}
          children={'Add Certificate'}
          onClick={createState.open}
        />
      </div>
      <div className="bg-white p-4">
        <div className="lg:w-1/3">
          {certificates.value.length === 0 ? (
            <p>You have no certificates.</p>
          ) : (
            <UserCertificates certs={certificates.value} onDelete={onDelete} />
          )}
        </div>

        <CertificateCreate
          isOpen={createState.visible}
          handleClose={createState.close}
          userUid={user.uid}
          onCreate={onCertCreate}
        />
      </div>
    </div>
  );
};

export const _Certificates = connect((state: StoreState) => ({
  user: state.me.user,
}))(Certificates);

export default function CertificatesTemporarilyDisabled(_: any) {
  return (
    <div className="p-8 text-center">
      <InlineAlert
        type={'warning'}
        text={`This feature is currently disabled while we work to improve it. Thanks
    for understanding!`}
      />
    </div>
  );
}

import * as React from 'react';
import { useSimpleFetch } from '../../helpers/hooks';
import { Storage } from '../../types';
import incidentApi from '../../api/incident.api';
import OptionTitle from '../form/OptionTitle';
import FileComp from '../../screens/worker/files/File';
import DropUpload from '../../components/DropUpload';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

interface Props {
  incidentUid: string;
}

const IncidentUploads = ({ incidentUid }: Props) => {
  const uploads = useSimpleFetch<Storage[]>({
    initial: [],
    change: [incidentUid],
    accessor: 'uploads',
    fetch: () => incidentApi.uploads(incidentUid),
  });

  const onDrop = async (files: File[]) => {
    const data = new FormData();
    files.forEach(file => data.append('uploads', file));
    await incidentApi.upload(incidentUid, data);
    uploads.performFetch();
  };

  return (
    <div>
      <OptionTitle title={'Attachments'} />
      <div className="p-4">
        <div className="flex flex-row flex-wrap mb-4">
          <DropUpload onDrop={onDrop} />
          {uploads.value.map(i => (
            <div className="m-2">
              <FileComp storage={i} key={i.uid} />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DragDropContext(HTML5Backend)(IncidentUploads);

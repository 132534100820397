import * as React from 'react';
import * as ReactDOM from 'react-dom';
import styled from 'styled-components';
import InlineInputBase, {
  Props as InlineInputBaseProps,
} from './InlineInputBase';
import { throttle } from 'lodash';

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
  // @ts-ignore
} from 'react-places-autocomplete';
import { RefObject } from 'react';
import { boxShadow } from '../helpers/style';

const TextInput = styled.input`
  width: 100%;
  flex: 1;
  padding: 8px;
  outline: none;
  border: none;
`;

const DropdownContainer = styled.div`
  width: 100%;
  background: white;
  border: 1px solid #ebebeb;
  ${boxShadow} z-index: 9;
`;

export interface LatLng {
  lat: number;
  lng: number;
}

interface Props extends InlineInputBaseProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onChange?: (location: string) => void;
  value?: string;
  labelSize?: number;
  onLatLng?: (latlng: LatLng) => void;
}

export default class InlineLocationInput extends React.Component<Props, {}> {
  public static defaultProps = {
    inputProps: {},
  };

  private el: HTMLDivElement;
  private inputRef: RefObject<HTMLInputElement>;
  private resizeListener: any;

  constructor(props: Props) {
    super(props);

    this.el = document.createElement('div');

    this.inputRef = React.createRef();

    this.resizeListener = throttle(() => {
      if (!this.inputRef.current) {
        this.el.style.display = 'none';
        return;
      }

      const inputRect = this.inputRef.current.getClientRects()[0];
      const { top, left, width, height } = inputRect;

      this.el.style.display = 'block';
      this.el.style.position = 'fixed';
      this.el.style.top = top + height + 4 + 'px';
      this.el.style.left = left + 'px';
      this.el.style.width = width + 'px';
      this.el.style.zIndex = '9';
    }, 150);
  }

  public componentDidMount() {
    setTimeout(() => {
      document.body.appendChild(this.el);

      this.resizeListener();

      window.addEventListener('resize', this.resizeListener);
    }, 160); // this is just over the time of the animation of the modal coming into place
  }

  public componentWillUnmount() {
    document.body.removeChild(this.el);

    window.removeEventListener('resize', this.resizeListener);
  }

  private onSelect = async (address: string) => {
    if (this.props.onChange) {
      this.props.onChange(address);

      if (this.props.onLatLng) {
        const results = await geocodeByAddress(address);
        const latlng = await getLatLng(results[0]);
        this.props.onLatLng(latlng);
      }
    }
  };

  public render() {
    const {
      icon,
      label,
      inputProps,
      errorMessage,
      value,
      onChange,
      labelSize,
    } = this.props;

    return (
      <InlineInputBase
        icon={icon}
        label={label}
        errorMessage={errorMessage}
        id={inputProps && inputProps.id}
        labelSize={labelSize}
      >
        <PlacesAutocomplete
          onSelect={this.onSelect}
          value={value}
          onChange={onChange}
        >
          {({
            getInputProps,
            suggestions,
            getSuggestionItemProps,
            loading,
          }: any) => (
            <div style={{ position: 'relative', flex: 1 }}>
              <TextInput
                ref={this.inputRef}
                {...getInputProps({
                  placeholder: 'Search Places ...',
                  className: 'location-search-input',
                })}
              />
              {ReactDOM.createPortal(
                <DropdownContainer>
                  {loading && <div>Loading...</div>}
                  {suggestions.map((suggestion: any) => {
                    const className = suggestion.active
                      ? 'suggestion-item--active'
                      : 'suggestion-item';
                    // inline style for demonstration purpose
                    const style = suggestion.active
                      ? {
                          backgroundColor: '#fafafa',
                          cursor: 'pointer',
                          padding: '8px',
                        }
                      : {
                          backgroundColor: '#ffffff',
                          cursor: 'pointer',
                          padding: '8px',
                        };
                    return (
                      <div
                        key={suggestion.id}
                        {...getSuggestionItemProps(suggestion, {
                          className,
                          style,
                        })}
                      >
                        <span>{suggestion.description}</span>
                      </div>
                    );
                  })}
                </DropdownContainer>,
                this.el,
              )}
            </div>
          )}
        </PlacesAutocomplete>
      </InlineInputBase>
    );
  }
}

import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import OptionTitle from './OptionTitle';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import WidgetContainer from './WidgetContainer';
import WidgetContent from './WidgetContent';
import DropUpload from '../../components/DropUpload';
import { toast } from 'react-toastify';
import submissionApi from '../../api/submission.api';
import { Dispatch } from 'redux';
import { updateSubmData } from '../../actions/submData';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import TextArea from '../../components/TextArea';
import { getPrivateUrlFromPath } from '../../components/PrivateImage';
import styled from 'styled-components';
import { cssVars } from '../../constants/index';
import { debounce } from 'lodash';
import { useDeepEqualEffect } from '../../helpers/hooks';
import Loading from '../../components/Loading';

const ChangeButton = styled.div`
  background: transparent;
  padding: ${cssVars.padding[2]};
  color: white;
  cursor: pointer;

  &:hover {
    background: white;
    color: black;
  }
`;

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
  editable?: boolean;
  submUid?: string;
  updateSubmData: (submData: ParsedSubmData) => any;
  submData?: ParsedSubmData;
}

const ImportImage = ({
  option,
  t,
  live,
  submUid,
  editable,
  submData,
  updateSubmData: pUpdateSubmData,
}: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live }) || '';

  const images = submData ? submData.parsed : {};
  const hasMax = Object.keys(images).length >= 5;
  const [descriptions, setDescriptions] = React.useState({});
  const listeners = React.useRef({});
  const [loading, setLoading] = React.useState(false);

  const onDrop = async (files: File[]) => {
    if (!live || !editable) {
      return;
    }
    if (files.length > 5 || hasMax) {
      toast.error('Cannot upload more than 5 files');
      return;
    }
    setLoading(true);
    const data = new FormData();
    files.forEach(file => data.append('uploads', file));
    const res = await submissionApi.uploadFormOptionFile(
      submUid!,
      option.id,
      data,
    );
    if (res.data) {
      pUpdateSubmData(res.data.submData);
    }
    setLoading(false);
  };

  const onDescChange = (key: string, value: string) => {
    setDescriptions({ ...descriptions, [key]: value });
    listeners.current[key] =
      listeners.current[key] ||
      debounce(async (val: string) => {
        await submissionApi.updateFormOption(submUid!, option.id, {
          key,
          value: val,
        });
      }, 500);
    listeners.current[key](value);
  };

  const onRemove = async (key: string) => {
    setLoading(true);
    const res = await submissionApi.removeFormOptionFile(
      submUid!,
      option.id,
      key,
    );
    if (res.data) {
      pUpdateSubmData(res.data.submData);
    }
    setLoading(false);
  };

  useDeepEqualEffect(
    () => {
      setDescriptions(
        Object.keys(images).reduce(
          (curr, next) => ({
            ...curr,
            [next]: images[next].desc,
          }),
          {},
        ),
      );
    },
    [images],
  );

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent className={'relative'}>
        <Loading loading={loading} />
        <DropUpload
          block={true}
          onDrop={onDrop}
          disabled={!live || !editable || hasMax}
          accept={'image/*'}
        />
        <div className="flex flex-col">
          {Object.keys(images).map(imageKey => (
            <div
              className="flex flex-row items-center items-stretch mt-4"
              key={imageKey}
            >
              <div style={{ maxWidth: '200px' }} className="mr-4 relative">
                <a
                  href={getPrivateUrlFromPath(images[imageKey].full)}
                  target="_blank"
                >
                  <img
                    src={getPrivateUrlFromPath(images[imageKey].full, {
                      thumb: true,
                    })}
                    className="max-w-full cursor-pointer"
                  />
                </a>
                <div
                  className="absolute flex justify-center w-full p-2"
                  style={{
                    bottom: 0,
                    left: 0,
                    background: 'rgba(0, 0, 0, 0.6)',
                  }}
                >
                  <ChangeButton onClick={() => onRemove(imageKey)}>
                    REMOVE
                  </ChangeButton>
                </div>
              </div>
              <TextArea
                value={descriptions[imageKey]}
                onChange={e => onDescChange(imageKey, e.currentTarget.value)}
                placeholder="Describe this image..."
              />
            </div>
          ))}
        </div>
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    submUid: state.form.submissionUid,
    editable: state.form.editable,
  });
}

function mapDispatch(dispatch: Dispatch) {
  return {
    updateSubmData: (submData: ParsedSubmData) =>
      dispatch(updateSubmData(submData)),
  };
}

export default withNamespaces()(
  connect(
    makeMapStateToProps,
    mapDispatch,
  )(ImportImage),
);

import * as React from 'react';
import ModalBody from '../../components/modal/ModalBody';
import Modal from '../../components/Modal';
import Loading from '../../components/Loading';
import styled from 'styled-components';
import { cssVars, MAX_V2_WIDGET_UPLOADS } from '../../constants/index';
import DropUpload from '../../components/DropUpload';
import { toast } from 'react-toastify';
import formService from '../../services/forms.service';
import cloudinaryService from '../../services/cloudinary.service';
import { useState } from 'react';
import { ResponseFile } from '../../types';
import File from '../../svg/File';

const ChangeButton = styled.button`
  background: transparent;
  padding: ${cssVars.padding[2]};
  color: white;
  cursor: pointer;
  border: 0;

  &:hover {
    background: white;
    color: black;
  }
`;

interface Props {
  editable: boolean;
  isOpen: boolean;
  contentLabel: string;
  handleClose: () => void;
  instanceRevUid: string;
  widgetUid: string;
  files: ResponseFile[];
}

const WidgetFileModal = ({
  editable,
  isOpen,
  contentLabel,
  handleClose,
  instanceRevUid,
  widgetUid,
  files,
}: Props) => {
  const [images, setImages] = useState<ResponseFile[]>(files ? files : []);
  const [loading, setLoading] = useState(false);

  const disabled = !editable;

  const onDrop = async (filesToUpload: File[]) => {
    const tempImages: ResponseFile[] = images;
    if (filesToUpload.length + images.length > MAX_V2_WIDGET_UPLOADS) {
      toast.error(`Cannot upload more than ${MAX_V2_WIDGET_UPLOADS} files`);
      return;
    }
    const { data } = await formService.getInstanceRevisionFileSignature(
      instanceRevUid,
    );
    if (!data) {
      return;
    }
    setLoading(true);

    const { signature, timestamp, folder } = data;
    for (const file of filesToUpload) {
      const cloudinaryResult = await cloudinaryService.uploadSigned(file, {
        signature,
        folder,
        timestamp,
      });
      if (!cloudinaryResult) {
        toast.error('File size too large! Maximum file size is 10MB');
        continue;
      }
      const fileObj = {
        file_id: cloudinaryResult.id,
        file_url: cloudinaryResult.url,
      };
      const {
        data: uploadedFileData,
        errors,
      } = await formService.uploadWidgetFile(
        instanceRevUid,
        widgetUid,
        fileObj,
      );
      if (errors || !uploadedFileData) {
        toast.error('Failed to upload file!');
        continue;
      }
      tempImages.push(uploadedFileData.file);
    }
    setImages(tempImages);
    setLoading(false);
  };

  const onRemove = async (fileUid: string) => {
    const newImages = images.filter(({ uid }) => uid !== fileUid);
    setImages(newImages);
    await formService.deleteWidgetFile(fileUid);
  };

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      handleClose={handleClose}
    >
      <ModalBody>
        <div className="p-5 flex flex-col items-center justify-center">
          <Loading loading={loading} />
          {disabled ? null : (
            <DropUpload block={true} onDrop={onDrop} disabled={disabled} />
          )}
          <div className="flex flex-col w-full">
            <div className="overflow-y-scroll mt-4">
              {images.map(image => (
                <>
                  <div className="inline-block relative w-1/3">
                    <div className="m-2 relative">
                      <a href={image.file_url} target="_blank">
                        {image.file_id.includes('.') ? (
                          <File height={200} color={cssVars.colors.grey} />
                        ) : (
                          <img
                            src={image.file_url}
                            className="w-full cursor-pointer"
                            height={200}
                          />
                        )}
                      </a>
                      <div
                        className="absolute flex justify-center w-full p-2"
                        style={{
                          bottom: 0,
                          left: 0,
                          background: 'rgba(0, 0, 0, 0.6)',
                          display: disabled ? 'none' : undefined,
                        }}
                      >
                        <ChangeButton
                          onClick={() => onRemove(image.uid)}
                          disabled={disabled}
                        >
                          REMOVE
                        </ChangeButton>
                      </div>
                    </div>
                  </div>
                </>
              ))}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default WidgetFileModal;

import api, { api2 } from './api';
import {
  Company,
  Action,
  Checklist,
  Site,
  User,
  Certificate,
  Storage,
  NotifType,
  UsersCrews,
  Crew,
} from '../types';
import { CompanyUserConversation } from './companyUser.api';

interface UserUpdateData {
  username?: string;
  email?: string;
  fname?: string;
  lname?: string;
}

export interface MyActionsResponse {
  action: Action;
  site: Site;
  company: Company;
  user: User;
}

const userApi = {
  downloads: (userUid: string) => api2('GET', `users/${userUid}/downloads`),
  uploadSignature: (userUid: string, data: any) =>
    api2('PUT', `users/${userUid}/signature`, data),
  removeSignature: (userUid: string) =>
    api2('DELETE', `users/${userUid}/signature`),
  token: (userUid: string) =>
    api2<{ token: string; refreshToken: string }>(
      'GET',
      `users/${userUid}/token`,
    ),
  signature: (userUid: string) =>
    api2<{ url?: string }>('GET', `users/${userUid}/signature`),
  update: (userUid: string, data: UserUpdateData) =>
    api('PATCH', `users/${userUid}`, data),
  attachWorkRole: (userUid: string, workRoleUid: string) =>
    api('PUT', `users/${userUid}/work-roles/${workRoleUid}`),
  crews: (userUid: string, companyUid: string) =>
    api2<{ crews: UsersCrews[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/crews`,
    ),
  crewsInCompanyGroup: (userUid: string, groupUid: string) =>
    api2<{ crews: UsersCrews[] }>(
      'GET',
      `company-groups/${groupUid}/users/${userUid}/crews`,
    ),
  sites: (userUid: string, companyUid: string) =>
    api2<{ sites: Site[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/sites`,
    ),
  sitesInCompanyGroup: (userUid: string, groupUid: string) =>
    api2<{ sites: Site[] }>(
      'GET',
      `company-groups/${groupUid}/users/${userUid}/sites`,
    ),
  workRole: (userUid: string, companyUid: string) =>
    api('GET', `companies/${companyUid}/users/${userUid}/work-role`),
  createCertificate: (userUid: string, data: FormData) =>
    api('POST', `users/${userUid}/certificates`, data),
  certs: (userUid: string) =>
    api2<{ certificates: Array<{ cert: Certificate; image?: Storage }> }>(
      'GET',
      `users/${userUid}/certificates`,
    ),
  companies: (userUid: string) =>
    api2<{ companies: Company[] }>('GET', `users/${userUid}/companies`),
  companiesWithRoles: (userUid: string) =>
    api2<{ companies: Array<{ company: Company; role: string }> }>(
      'GET',
      `users/${userUid}/companies-with-roles`,
    ),
  createdActions: (userUid: string, companyUid: string) =>
    api2<{ createdActions: MyActionsResponse[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/created-actions`,
    ),
  assignedActions: (userUid: string, companyUid: string) =>
    api2<{ assignedActions: MyActionsResponse[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/assigned-actions`,
    ),
  createdChecklists: (userUid: string, companyUid: string) =>
    api2<{ createdChecklists: Checklist[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/created-checklists`,
    ),
  sharedChecklists: (userUid: string, companyUid: string) =>
    api2<{ sharedChecklists: Checklist[] }>(
      'GET',
      `companies/${companyUid}/users/${userUid}/shared-checklists`,
    ),
  deleteAllDownloads: (userUid: string) =>
    api2('DELETE', `users/${userUid}/downloads`),
  favouriteForm: (userUid: string, fcUid: string) =>
    api2('PUT', `users/${userUid}/favourite-forms/${fcUid}`),
  unfavouriteForm: (userUid: string, fcUid: string) =>
    api2('DELETE', `users/${userUid}/favourite-forms/${fcUid}`),
  resetPassword: (
    userUid: string,
    data: { oldPassword: string; newPassword: string },
  ) => api2('POST', `users/${userUid}/reset-password`, data),
  updatePassword: (userUid: string, data: { password: string }) =>
    api2('PUT', `users/${userUid}/password`, data),
  conversations: (userUid: string) =>
    api2<{ conversations: CompanyUserConversation[] }>(
      'GET',
      `users/${userUid}/conversations`,
    ),
  unreadMessageCount: (userUid: string) =>
    api2<{ count: number }>('GET', `users/${userUid}/unread-message-count`),
  unreadNotifCount: (userUid: string) =>
    api2<{ count: number }>(
      'GET',
      `users/${userUid}/unread-notification-count`,
    ),
  notifications: (userUid: string) =>
    api2<{ notifications: NotifType[] }>(
      'GET',
      `users/${userUid}/notifications`,
    ),
  siteCrews: (userUid: string, siteUid: string) =>
    api2<{ crews: Crew[] }>('GET', `users/${userUid}/sites/${siteUid}/crews`),
};

export default userApi;

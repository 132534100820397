import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import Tabs from '../../components/Tabs';
import ChecklistView from './checklists/ChecklistView';
import ChecklistCreate from './checklists/ChecklistCreate';
import { useMountVisibility, useSimpleFetch } from '../../helpers/hooks';
import { Checklist, Company, User } from '../../types';
import userApi from '../../api/user.api';
import Loading from '../../components/Loading';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { History } from 'history';

interface Props {
  company: Company;
  user: User;
  history: History;
}

const Checklists = ({ company, user, history }: Props) => {
  const myChecklists = useSimpleFetch<Checklist[]>({
    initial: [],
    change: [company.uid],
    fetch: () => userApi.createdChecklists(user.uid, company.uid),
    accessor: 'createdChecklists',
  });
  const sharedChecklists = useSimpleFetch<Checklist[]>({
    initial: [],
    change: [company.uid],
    fetch: () => userApi.sharedChecklists(user.uid, company.uid),
    accessor: 'sharedChecklists',
  });
  const createState = useMountVisibility();

  const tabs = [
    {
      label: 'My Checklists',
      component: (
        <ChecklistView
          title={'Checklists I created'}
          onCreateClick={createState.open}
          checklists={myChecklists.value}
          history={history}
        />
      ),
    },
    {
      label: 'Shared Checklists',
      component: (
        <ChecklistView
          title={'Checklists shared with me'}
          onCreateClick={createState.open}
          checklists={sharedChecklists.value}
          history={history}
        />
      ),
    },
  ];

  return (
    <Page>
      <Loading loading={myChecklists.loading || sharedChecklists.loading} />

      <PageContent>
        <Tabs tabs={tabs} raised={true} />
      </PageContent>

      <ChecklistCreate
        isOpen={createState.visible}
        handleClose={createState.close}
        history={history}
      />
    </Page>
  );
};

export default connect((state: StoreState) => ({
  company: state.me.company,
  user: state.me.user,
}))(Checklists);

import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Company, Site } from '../../../types';
import siteApi from '../../../api/site.api';
import SiteModifyModal, { SubmitData } from './SiteModifyModal';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getSiteLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  onCreate: (site: Site) => void;
}

interface State {
  loading: boolean;
  errors: {
    name?: string;
  };
}

class SiteCreate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      errors: {
        name: '',
      },
    };
  }

  private submit = async (data: SubmitData) => {
    const { name, latlng } = data;

    try {
      const { site } = await siteApi.create(this.props.company.uid, {
        name,
        location: latlng || undefined,
      });

      this.setState({
        errors: {},
      });

      this.props.onCreate(site);
    } catch (e) {
      this.setState({
        errors: e.response.data.errors,
      });
    }
  };

  public render() {
    const { isOpen, handleClose, t } = this.props;
    const { errors, loading } = this.state;

    return (
      <SiteModifyModal
        isOpen={isOpen}
        contentLabel={`Create ${getSiteLabel()}`}
        title={`Create ${getSiteLabel()}`}
        handleClose={handleClose}
        onSubmit={this.submit}
        errors={errors}
        loading={loading}
      />
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
  };
}

export default withNamespaces()(connect(mapStateToProps)(SiteCreate));

import * as React from 'react';
import * as R from 'ramda';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import InlineSelect from '../../../components/InlineSelect';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Company, Site, User } from '../../../types';
import { toast } from 'react-toastify';
import UserGroup from '../../../svg/UserGroup';
import { SelectOptionObject } from '../../../components/Select';
import siteApi from '../../../api/site.api';
import Loading from '../../../components/Loading';
import { Dispatch } from 'redux';
import { usersFetchRequest } from '../../../actions/users';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getSiteLabel, getCrewLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  site: Site;
  afterSubmit: (user: User) => void;
  users: User[];
  usersLoading: boolean;
  usersLoaded: boolean;
  getUsers: (companyUid: string) => any;
  managersOnSite?: User[];
}

interface State {
  selected: SelectOptionObject | null;
  loadingUsers: boolean;
  submitting: boolean;
}

class AddManagers extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selected: null,
      loadingUsers: false,
      submitting: false,
    };
  }

  public componentDidMount() {
    if (!this.props.usersLoaded) {
      this.props.getUsers(this.props.company.uid);
    }
  }

  private submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { selected } = this.state;
    const { t, site, users, afterSubmit } = this.props;

    if (!selected) {
      return;
    }

    this.setState({
      submitting: true,
    });

    try {
      await siteApi.attachManager(site.uid, selected.value);

      const user = users.find(u => u.uid === selected!.value);

      afterSubmit(user!);
    } catch (e) {
      toast.error(
        `Failed to attach ${getCrewLabel().toLowerCase()} to ${getSiteLabel().toLowerCase()}`,
      );
    }

    this.setState({
      submitting: false,
    });
  };

  private onSelectedChange = (selected: SelectOptionObject) => {
    this.setState({
      selected,
    });
  };

  private getManagerOptions = () => {
    const { users, managersOnSite } = this.props;

    const managersOnSiteUids = (managersOnSite || []).map(c => c.uid);
    const filteredManagers = users.filter(
      c => !R.contains(c.uid, managersOnSiteUids),
    );

    return R.map(f => ({ label: f.username, value: f.uid }), filteredManagers);
  };

  public render() {
    const { isOpen, handleClose, usersLoading, t } = this.props;
    const { submitting, selected } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={`Add Managers to ${getSiteLabel()}`}
        handleClose={handleClose}
      >
        <ModalHeader handleClose={handleClose}>
          {`Add Managers to ${getSiteLabel()}`}
        </ModalHeader>

        <form onSubmit={this.submit}>
          <ModalBody>
            <Loading loading={usersLoading} />
            <InlineSelect
              label={t('common:users')}
              icon={<UserGroup />}
              inputProps={{
                placeholder: t('super:screens.sites.managerInputPlaceholder'),
              }}
              options={this.getManagerOptions()}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              value={selected}
              onChange={this.onSelectedChange}
              backspaceRemovesValue={false}
            />
          </ModalBody>
          <ModalFooter right={true}>
            <Button
              type={'primary'}
              disabled={selected === null}
              loading={submitting}
            >
              {t('common:submit')}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    users: state.users.data,
    usersLoading: state.users.loading,
    usersLoaded: state.users.loaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddManagers),
);

import { call, takeLatest, put } from 'redux-saga/effects';
import companyApi from '../api/company.api';
import { WORK_ROLES_FETCH_REQUEST } from '../constants/actionTypes';
import {
  workRolesFetchFailure,
  workRolesFetchSuccess,
} from '../actions/workRoles';

export function* getWorkRoles(params: any) {
  const { data, errors } = yield call(companyApi.workRoles, params.payload);
  if (data) {
    yield put(workRolesFetchSuccess(data.workRoles));
  } else if (errors) {
    yield put(workRolesFetchFailure(errors));
  }
}

export function* watchGetWorkRoles() {
  yield takeLatest(WORK_ROLES_FETCH_REQUEST, getWorkRoles);
}

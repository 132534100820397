import * as React from 'react';
import { useState, useEffect, useMemo } from 'react';
import FormView from '../../containers/FormView';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import Loading from '../../components/Loading';
import PageToolbar from '../../components/PageToolbar';
import Clock from '../../svg/Clock';
import Checkmark from '../../svg/Checkmark';
import {
  useMountVisibility,
  useSimpleFetch,
  useLogarithmicPoll,
} from '../../helpers/hooks';
import FormMeta from './fillableDocument/FormMeta';
import formService, {
  WidgetsAndResponse,
  TopForms,
} from '../../services/forms.service';
import {
  InstanceRevision,
  SignOff as ISignOff,
  InstanceMeta,
  Company,
  User as IUser,
} from '../../types';
import { toast } from 'react-toastify';
import { History } from 'history';
import { match as IMatch } from 'react-router';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import User from '../../svg/User';
import SignOffModal from '../../containers/fillableDocument/SignOffModal';
import RevisionHeader from './fillableDocument/RevisionHeader';
import HistorySidebox from './fillableDocument/HistorySidebox';
import File from '../../svg/File';
import { sleep } from '../../helpers/index';
import Star from '../../svg/Star';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { contains } from 'ramda';
import StarFull from '../../svg/StarFull';
import { getLocation } from '../../helpers/location';

const PDF_POLL_RETRIES = 5;

interface Props {
  match: IMatch<{ instanceUid: string; instanceRevisionUid?: string }>;
  company: Company;
  history: History;
  scrollContent: React.RefObject<HTMLElement>;
  user: IUser;
}

function FillableDocument({
  match,
  history,
  company,
  scrollContent,
  user,
}: Props) {
  const [loading, setLoading] = useState(false);
  const { instanceUid, instanceRevisionUid } = match.params;
  const [widgetsAndResponses, setWidgetsAndResponses] = useState<
    WidgetsAndResponse[]
  >([]);
  const [pollingPdf, setPollingPdf] = useState<boolean>(false);
  const getPdfTimeout = useLogarithmicPoll();
  const [gettingSignOffs, setGettingSignOffs] = useState(false);
  const [instanceMeta, setInstanceMeta] = useState<InstanceMeta | null>(null);
  const [signOffs, setSignOffs] = useState<ISignOff[]>([]);
  const [unansweredWidgets, setUnansweredWidgets] = useState<{
    [key: string]: boolean;
  }>({});
  const [stickySidebar, setStickySidebar] = useState<boolean>(false);
  const [stickySidebarWidth, setStickySidebarWidth] = useState<number>(0);
  const [favoriting, setFavoriting] = useState<boolean>(false);
  const historyState = useMountVisibility();
  const confirmationDialogue = useMountVisibility();
  const signOffModal = useMountVisibility();

  const workingCopy = useSimpleFetch<InstanceRevision | null>({
    initial: null,
    change: [instanceUid],
    fetch: () => formService.instanceWorkingCopy(instanceUid),
    accessor: 'workingCopy',
  });

  const favouriteV2Forms = useSimpleFetch<TopForms | null>({
    initial: null,
    fetch: () => formService.getTopForms(company.uid, user.uid),
    accessor: 'topForms',
    change: [company.uid, user.uid],
  });

  const isFavouriteForm = useMemo(
    () =>
      instanceMeta &&
      favouriteV2Forms.value &&
      contains(
        instanceMeta.form.uid,
        favouriteV2Forms.value.favorites.map(form => form.uid),
      ),
    [instanceMeta, favouriteV2Forms.value],
  );

  const favouriteClass = isFavouriteForm ? 'text-yellow' : 'text-black';

  const pdfLoaded = instanceMeta && !!instanceMeta.instance_revision.pdf_url;

  const revUid = instanceRevisionUid
    ? instanceRevisionUid
    : workingCopy.value
      ? workingCopy.value.uid
      : null;

  async function postLocationInfo(instanceRevUid: string) {
    const locationMeta = await getLocation();
    if (!locationMeta) {
      return;
    }
    const { lat, lng } = locationMeta.location;
    const { data } = await formService.geostampInstanceRev(instanceRevUid, {
      lat,
      lng,
    });
    if (!data) {
      toast.error('Failed to get location info!');
      return;
    }
  }

  async function getSignOffs() {
    if (!revUid) {
      return;
    }
    setGettingSignOffs(true);
    const { data } = await formService.getSignOffs(revUid);
    if (!data) {
      toast.error('Failed fetching sign offs');
      setGettingSignOffs(false);
      return;
    }
    setSignOffs(data.signOffs);
    setGettingSignOffs(false);
  }

  async function getInstanceMeta() {
    if (!revUid) {
      return;
    }
    const { data } = await formService.instanceMeta(revUid);

    if (data) {
      setInstanceMeta(data.meta);
      if (instanceRevisionUid && !pdfLoaded) {
        pollPdf();
      }
      return;
    }
    toast.error('Failed to get information!');
  }

  async function pollPdf() {
    if (!revUid) {
      return;
    }
    setPollingPdf(true);
    for (let i = 0; i < PDF_POLL_RETRIES; ++i) {
      const { data } = await formService.instanceMeta(revUid);
      if (data && data.meta.instance_revision.pdf_url) {
        setInstanceMeta(data.meta);
        setPollingPdf(false);
        return;
      }
      const timeout = getPdfTimeout();
      if (timeout) {
        await sleep(timeout);
      }
    }
    setPollingPdf(false);
    toast.error('Failed to fetch PDF!');
  }

  async function getWidgetsAndResponses() {
    if (!revUid) {
      return;
    }
    const { data } = await formService.widgetsAndResponses(revUid);
    if (data) {
      setWidgetsAndResponses(data.responses);
    }
  }

  async function fetchInitialData() {
    setLoading(true);
    await getInstanceMeta();
    await getWidgetsAndResponses();
    await getSignOffs();
    setLoading(false);
  }

  function scrollListener() {
    if (!scrollContent.current) {
      return;
    }
    const scrollTop = scrollContent.current.scrollTop;
    const parentWidth = document.getElementById('sidebarContainer')!
      .offsetWidth;
    const windowWidth = window.innerWidth;

    if (
      (!instanceRevisionUid && scrollTop >= 57) ||
      (instanceRevisionUid && scrollTop >= 150)
    ) {
      if (windowWidth > 991) {
        setStickySidebarWidth(parentWidth - 12);
      } else {
        setStickySidebarWidth(parentWidth);
      }
      setStickySidebar(true);
    } else {
      setStickySidebarWidth(0);
      setStickySidebar(false);
    }
  }

  function resizeListener() {
    const windowWidth = window.innerWidth;
    // TODO: use ref
    const parentWidth = document.getElementById('sidebarContainer')!
      .offsetWidth;

    if (windowWidth > 991) {
      setStickySidebarWidth(parentWidth - 12);
    } else {
      setStickySidebarWidth(parentWidth);
    }
  }

  function handleSubmitErrors(unansweredWidgetUids: string[]) {
    const newErrors = { ...unansweredWidgets };
    for (const widgetUid of unansweredWidgetUids) {
      newErrors[widgetUid] = true;
    }
    setUnansweredWidgets(newErrors);
  }

  useEffect(
    () => {
      if (scrollContent.current) {
        scrollContent.current.addEventListener('scroll', scrollListener);
      }
      window.addEventListener('resize', resizeListener);

      return () => {
        if (scrollContent.current) {
          scrollContent.current!.removeEventListener('scroll', scrollListener);
        }
        window.removeEventListener('resize', resizeListener);
      };
    },
    [workingCopy.value, scrollContent.current, revUid],
  );

  useEffect(
    () => {
      if (revUid) {
        postLocationInfo(revUid);
      }
    },
    [revUid],
  );

  useEffect(
    () => {
      fetchInitialData();
    },
    [revUid],
  );

  async function submit() {
    confirmationDialogue.close();
    if (!workingCopy.value) {
      toast.error('Form not ready yet!');
      return;
    }
    setLoading(true);
    setStickySidebar(false);
    const { data, errors } = await formService.formSubmit(
      workingCopy.value.uid,
    );
    setLoading(false);
    if (!data && errors && errors.unansweredWidgetUids) {
      getWidgetsAndResponses();
      handleSubmitErrors(errors.unansweredWidgetUids);
      toast.error('Missing mandatory responses!');
      return;
    }
    if (!data) {
      toast.error('An error occurred submitting the form!');
      return;
    }
    toast.success('Form submitted!');
    history.push('/dashboard/worker/dashboard');
  }

  function openPdf() {
    if (instanceMeta && instanceMeta.instance_revision.pdf_url) {
      window.open(instanceMeta.instance_revision.pdf_url);
    }
  }

  async function toggleFavorite() {
    if (!instanceMeta) {
      return;
    }
    const toggleFavoriteRemote = isFavouriteForm
      ? formService.removefavoriteForm
      : formService.favoriteForm;
    const prefix = isFavouriteForm ? 'un' : '';
    setFavoriting(true);
    const { data, errors } = await toggleFavoriteRemote(
      user.uid,
      instanceMeta.form.uid,
    );
    if (data) {
      toast.success(`Successfully ${prefix}favorited form!`);
      await favouriteV2Forms.performFetch();
    }
    if (errors) {
      toast.error(`An error occurred ${prefix}favoriting form!`);
    }
    setFavoriting(false);
  }

  const actions: Array<
    Partial<{
      label: string;
      icon: any;
      onClick: () => void;
      disabled: boolean;
      type: 'success';
      href: any;
      external: any;
    }>
  > = instanceRevisionUid
    ? [
        {
          label:
            loading || pollingPdf ? 'Loading...' : pdfLoaded ? 'PDF' : 'Error!',
          icon: <File className={'w-8 h-8'} />,
          onClick: openPdf,
          disabled: loading,
        },
        {
          label: 'History',
          icon: <Clock className={'w-8 h-8'} />,
          onClick: historyState.open,
        },
      ]
    : [
        {
          label: favoriting ? '' : isFavouriteForm ? 'Favorited' : 'Favorite',
          icon: favoriting ? (
            <span>Loading...</span>
          ) : isFavouriteForm ? (
            <StarFull
              className={`w-8 h-8 hover:text-red cursor-pointer ${favouriteClass}`}
            />
          ) : (
            <Star className={`w-8 h-8 hover:text-yellow cursor-pointer`} />
          ),
          onClick: favoriting ? () => undefined : toggleFavorite,
        },
        {
          label: 'Sign Off',
          icon: <User className={'w-8 h-8'} />,
          onClick: signOffModal.open,
        },
        {
          label: 'History',
          icon: <Clock className={'w-8 h-8'} />,
          onClick: historyState.open,
        },
        {
          label: 'Submit',
          type: 'success' as 'success',
          icon: <Checkmark className={'w-8 h-8'} />,
          onClick: confirmationDialogue.open,
        },
      ];

  const editable = instanceRevisionUid === undefined;

  return (
    <div>
      <Loading loading={loading} />
      <Page>
        <PageToolbar
          sticky={true}
          title={instanceMeta ? instanceMeta.form.name : ''}
          actions={actions}
        />
        <PageContent>
          {instanceMeta &&
            instanceMeta.instance_revision.submitter_name &&
            instanceMeta.instance_revision.submitted_at && (
              <RevisionHeader
                history={history}
                instanceUid={instanceUid}
                deployeeUid={instanceMeta.deployee.uid}
                instanceRevisionUid={instanceRevisionUid}
                submitter={instanceMeta.instance_revision.submitter_name}
                submitted_at={instanceMeta.instance_revision.submitted_at}
                instanceMeta={instanceMeta}
              />
            )}
          <div className={'flex flex-col lg:flex-row lg:flex-row-reverse'}>
            {instanceMeta && (
              <FormMeta
                editable={editable}
                loading={loading}
                signOffs={signOffs}
                stickySidebar={stickySidebar}
                actions={actions}
                stickySidebarWidth={stickySidebarWidth}
                instanceMeta={instanceMeta}
                gettingSignOffs={gettingSignOffs}
              />
            )}
            <div
              className="w-full mt-4 lg:w-3/4 lg:mt-0"
              style={stickySidebar ? { paddingBottom: '300px' } : {}}
            >
              {workingCopy.value && (
                <FormView
                  widgetsAndResponses={widgetsAndResponses}
                  instanceRevUid={workingCopy.value.uid}
                  editable={editable}
                  emptyMandatoryWidgets={unansweredWidgets}
                  setEmptyMandatoryWidgets={setUnansweredWidgets}
                />
              )}
            </div>
          </div>
        </PageContent>
        <ConfirmationDialogue
          title={'Submit Form'}
          body={'Are you sure you wish to submit this form?'}
          contentLabel={'Confirm Submission'}
          handleClose={confirmationDialogue.close}
          onConfirm={submit}
          isOpen={confirmationDialogue.visible}
        />
        {historyState.mounted && instanceMeta ? (
          <HistorySidebox
            isOpen={historyState.visible}
            handleClose={historyState.close}
            instanceUid={instanceUid}
            deployeeUid={instanceMeta.deployee.uid}
            formUid={instanceMeta.form.uid}
            siteUid={instanceMeta.site.uid}
          />
        ) : null}
        {signOffModal.mounted && (
          <SignOffModal
            title={'Sign Off'}
            contentLabel={'Sign Off'}
            handleClose={signOffModal.close}
            onConfirm={getSignOffs}
            isOpen={signOffModal.visible}
            instanceRevisionUid={workingCopy.value ? workingCopy.value.uid : ''}
            getSignOffs={() => getSignOffs()}
          />
        )}
      </Page>
    </div>
  );
}

const mapState = (state: StoreState) => ({
  user: state.me.user,
});

export default connect(mapState)(FillableDocument);

import * as React from 'react';
import { GoogleMap, withGoogleMap } from 'react-google-maps';
import MarkerClusterer from 'react-google-maps/lib/components/addons/MarkerClusterer';
import companyApi, { SubmissionStats } from '../../api/company.api';
import SubmissionsMarker from './Marker';
import { Company, CompanyGroup } from '../../types';
import companyGroupApi from '../../api/companyGroup.api';

interface Props {
  filters: SubmissionStats;
  company?: Company;
  companyGroup?: CompanyGroup;
}

const getLatLng = (geolocation?: string) => {
  if (!geolocation) {
    return null;
  }

  try {
    const parsedGeo = JSON.parse(geolocation);

    if (parsedGeo.lat && parsedGeo.lng) {
      return {
        lat: parseFloat(parsedGeo.lat),
        lng: parseFloat(parsedGeo.lng),
      };
    }
  } catch (e) {
    //
  }

  return null;
};

const SubmissionsMap = ({ company, companyGroup, filters }: Props) => {
  const map = React.useRef<any>(null);
  const [forms, setForms] = React.useState<any>([]);
  const [openMarker, setOpenMarker] = React.useState<null | string>(null);

  const parentUid = company
    ? company.uid
    : companyGroup
      ? companyGroup.uid
      : null;

  const getSubmissions = async () => {
    try {
      let submissions;

      if (company) {
        const data = await companyApi.submissions(company.uid, filters);
        submissions = data.submissions;
      }

      if (companyGroup) {
        const data = await companyGroupApi.submissions(
          companyGroup.uid,
          filters,
        );
        submissions = data.submissions;
      }

      // Fit map to new bounds
      const bounds = new (window as any).google.maps.LatLngBounds();
      submissions.forEach((form: any) => {
        form.submissions.forEach((submission: any) => {
          const latlng = getLatLng(submission.geo_location);
          if (latlng) {
            bounds.extend(
              new (window as any).google.maps.LatLng(latlng.lat, latlng.lng),
            );
          }
        });
      });

      setForms(submissions);

      if (map && map.current) {
        map.current.fitBounds(bounds);
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(
    () => {
      getSubmissions();
    },
    [parentUid, filters],
  );

  return (
    <GoogleMap ref={map} defaultCenter={{ lat: 0, lng: 0 }} defaultZoom={1}>
      <MarkerClusterer enableRetinaIcons={true} maxZoom={16}>
        {forms.map((form: any) =>
          form.submissions.map((submission: any) => {
            const latlng = getLatLng(submission.geo_location);
            if (!latlng) {
              return null;
            }
            return (
              <SubmissionsMarker
                key={submission.uid}
                submission={submission}
                form={form.form}
                position={latlng}
                onClick={(uid: string) => setOpenMarker(uid)}
                onClose={() => setOpenMarker(null)}
                open={submission.uid === openMarker}
              />
            );
          }),
        )}
      </MarkerClusterer>
    </GoogleMap>
  );
};

export default withGoogleMap(SubmissionsMap);

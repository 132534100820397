import * as React from 'react';
import {
  useContentEditable,
  handleContentLengthErrors,
} from '../../helpers/hooks';
import {
  FormFabricatorProps,
  useWidgetChange,
  useWidgetMandatory,
  BasicLabel,
} from './common';
import BuilderButtons from './BuilderButtons';
import { useState, useEffect } from 'react';
import Select from '../../components/Select';
import WidgetContent from '../form/WidgetContent';
import InlineToggle from '../../components/InlineToggle';
import { SelectType } from '../../types';
import LengthErrorLabel from './LengthErrorLabel';
import { MAX_NAME_LENGTH } from '../../constants/index';
import { onContentEditablePaste } from '../../helpers';

type Props = FormFabricatorProps;

function User({
  widget,
  setWidgets,
  mandatory,
  formRevisionUid,
  openDelete,
  editErrors,
  setEditErrors,
}: Props) {
  const [isTooLong, setIsTooLong] = useState<boolean>(false);
  const [isTooShort, setIsTooShort] = useState<boolean>(false);
  const [selectType, setSelectType] = useState<SelectType>(
    widget.select_type ? widget.select_type : 'multi',
  );

  const { handleWidgetChange } = useWidgetChange({
    formRevisionUid,
    setWidgets,
    widget,
  });

  const { handleDone, handleBlurEvent } = useContentEditable(
    'title',
    handleWidgetChange,
  );

  const { checked, handleMandatoryChange } = useWidgetMandatory({
    widget,
    formRevisionUid,
    mandatory,
  });

  const title = widget.title;

  useEffect(
    () => {
      handleWidgetChange({
        select_type: selectType,
      });
    },
    [selectType],
  );

  useEffect(
    () => {
      if (isTooShort || isTooLong) {
        setEditErrors([...editErrors, widget.uid]);
        return;
      }
      setEditErrors(editErrors.filter(uid => uid !== widget.uid));
    },
    [isTooLong, isTooShort],
  );

  return (
    <WidgetContent>
      <div className="mb-4">
        <LengthErrorLabel
          isTooLong={isTooLong}
          isTooShort={isTooShort}
          maxCharLength={MAX_NAME_LENGTH}
        />
        <div className={'mb-2 flex flex-row space-between'}>
          <div className="w-full">
            <BasicLabel
              className={
                isTooShort || isTooLong
                  ? 'border-2 border-red border-solid rounded'
                  : ''
              }
              contentEditable={true}
              onInput={(event: React.KeyboardEvent<any>) =>
                handleContentLengthErrors(
                  event,
                  MAX_NAME_LENGTH,
                  isTooLong,
                  isTooShort,
                  setIsTooLong,
                  setIsTooShort,
                  handleDone,
                )
              }
              onPaste={(e: any) => onContentEditablePaste(e)}
              onBlur={handleBlurEvent}
            >
              {title}
            </BasicLabel>
          </div>
          <InlineToggle
            label={'Select Multiple?'}
            className="flex border-none items-center ml-2"
            checked={selectType === 'multi' ? true : false}
            onChange={() =>
              setSelectType(selectType === 'multi' ? 'single' : 'multi')
            }
          />
        </div>
        <Select disabled={true} options={[]} />
      </div>
      <BuilderButtons
        handleMandatoryChange={handleMandatoryChange}
        checked={checked}
        openDelete={openDelete}
        id={widget.uid}
      />
    </WidgetContent>
  );
}

export default User;

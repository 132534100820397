import * as React from 'react';
import ActionCreate from '../ActionCreate';
import { Company, Action, Site } from '../../types';
import { UsersState } from '../../reducers/users';
import { SitesState } from '../../reducers/sites';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import { usersFetchRequest } from '../../actions/users';
import { sitesFetchRequest } from '../../actions/sites';
import { useEntityRefresh } from '../../helpers/hooks';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  submissionUid: string;
  company: Company;
  users: UsersState;
  sites: SitesState;
  getUsers: (companyUid: string) => any;
  getSites: (companyUid: string) => any;
  formOptionId?: number;
  onCreate?: (action: Action) => void;
  site?: Site;
}

const FormActionCreate = ({
  isOpen,
  handleClose,
  formOptionId,
  submissionUid,
  company,
  users,
  sites,
  getUsers,
  getSites,
  onCreate,
  site,
}: Props) => {
  useEntityRefresh(company.uid, users, getUsers);
  useEntityRefresh(company.uid, sites, getSites);
  return (
    <ActionCreate
      isOpen={isOpen}
      handleClose={handleClose}
      companyUid={company.uid}
      sites={sites.data}
      users={users.data}
      submissionUid={submissionUid}
      formOptionId={formOptionId}
      onCreate={onCreate}
      currentSite={site}
    />
  );
};

const mapState = (state: StoreState) => ({
  company: state.me.company,
  users: state.users,
  sites: state.sites,
  submissionUid: state.form.submissionUid,
  site: state.form.site,
});

const mapDispatch = (dispatch: Dispatch) => ({
  getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  getSites: (companyUid: string) => dispatch(sitesFetchRequest(companyUid)),
});

export default connect(
  mapState,
  mapDispatch,
)(FormActionCreate);

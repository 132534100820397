import * as React from 'react';
import Button from '../../../components/Button';
import { WorkRole } from '../../../types';
import SaveRemoveInput from './SaveRemoveInput';
import { Dispatch } from 'redux';
import { connect } from 'react-redux';
import ConfirmationDialogue from '../../../components/ConfirmationDialogue';
import companyApi from '../../../api/company.api';
import { toast } from 'react-toastify';
import {
  addWorkRole,
  removeWorkRole,
  updateWorkRole,
} from '../../../actions/workRoles';
import workRoleApi from '../../../api/workRoleApi.api';

interface Props {
  workRoles: WorkRole[];
  updateWorkRole: (workRole: WorkRole) => any;
  removeWorkRole: (workRoleUid: string) => any;
  addWorkRole: (workRole: WorkRole) => any;
  companyUid: string;
}

interface State {
  workRoleUidToDelete: string | null;
  deleting: boolean;
  workRoleName: string;
  adding: boolean;
}

class WorkRoles extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      workRoleUidToDelete: null,
      deleting: false,
      workRoleName: '',
      adding: false,
    };
  }

  private onWorkRoleNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      workRoleName: e.currentTarget.value,
    });
  };

  private onWorkRoleAdd = async () => {
    this.setState({
      adding: true,
    });

    try {
      const { workRole } = await companyApi.createWorkRole(
        this.props.companyUid,
        this.state.workRoleName,
      );

      this.props.addWorkRole(workRole);
      this.setState({
        workRoleName: '',
      });
    } catch (e) {
      toast.success('Added work role');
    }

    this.setState({
      adding: false,
    });
  };

  private onSave = async (role: WorkRole, name: string) => {
    try {
      const { workRole } = await workRoleApi.update(role.uid, { name });
      this.props.updateWorkRole(workRole);
      toast.success('Saved work role');
    } catch (e) {
      toast.error('Failed saving work role');
    }
  };

  private onDeleteClicked = (role: WorkRole) => {
    this.setState({
      workRoleUidToDelete: role.uid,
    });
  };

  private closeDeleteModal = () => {
    this.setState({
      workRoleUidToDelete: null,
    });
  };

  private deleteWorkRole = async () => {
    if (!this.state.workRoleUidToDelete) {
      return;
    }

    this.setState({
      deleting: true,
    });

    try {
      await workRoleApi.delete(this.state.workRoleUidToDelete);
    } catch (e) {
      //
    }

    this.props.removeWorkRole(this.state.workRoleUidToDelete);

    this.setState({
      workRoleUidToDelete: null,
      deleting: false,
    });
  };

  public render() {
    const { workRoles } = this.props;
    const { workRoleUidToDelete, deleting, workRoleName, adding } = this.state;

    return (
      <div>
        <div className="p-4 flex flex-col bg-grey-lighter">
          <label className={'mb-2 block'}>Add new Role</label>
          <div className={'flex flex-row'}>
            <input
              type="text"
              className={'w-full p-2'}
              placeholder={'Role name'}
              value={workRoleName}
              onChange={this.onWorkRoleNameChange}
            />
            <Button
              type={'primary'}
              loading={adding}
              onClick={this.onWorkRoleAdd}
            >
              Add
            </Button>
          </div>
        </div>

        <ul className="bg-white list-reset">
          {workRoles.map(role => (
            <SaveRemoveInput
              key={role.uid}
              value={role.name}
              onSave={(value: string) => this.onSave(role, value)}
              onRemove={() => this.onDeleteClicked(role)}
            />
          ))}
        </ul>

        <ConfirmationDialogue
          title={'Delete Role?'}
          body={'Delete this role?'}
          contentLabel={'Delete Role?'}
          handleClose={this.closeDeleteModal}
          onConfirm={this.deleteWorkRole}
          isOpen={!!workRoleUidToDelete}
          loading={deleting}
        />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  updateWorkRole: (role: WorkRole) => dispatch(updateWorkRole(role)),
  removeWorkRole: (workRoleUid: string) =>
    dispatch(removeWorkRole(workRoleUid)),
  addWorkRole: (role: WorkRole) => dispatch(addWorkRole(role)),
});

export default connect(
  null,
  mapDispatchToProps,
)(WorkRoles);

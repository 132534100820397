import * as React from 'react';
import ResourceTable from '../../../components/ResourceTable';
import { Certificate, WorkRole } from '../../../types';
import MatrixCheckbox from './MatrixCheckbox';

interface Props {
  roles: WorkRole[];
  certificates: Certificate[];
  matrix: {
    // roleUid -> list of required cert uids
    [roleUid: string]: string[];
  };
  loaded: boolean;
}

export default class Requirements extends React.Component<Props> {
  public render() {
    const { roles, certificates, loaded } = this.props;

    const columns = [
      {
        Header: 'User Role',
        accessor: (r: WorkRole) => r.name,
        id: 'roleName',
      },
      ...certificates.map(cert => ({
        Header: cert.name,
        accessor: cert.uid,
        Cell: (row: any) => (
          <MatrixCheckbox roleUid={row.original.uid} certTypeUid={cert.uid} />
        ),
      })),
    ];

    return (
      <div>
        <ResourceTable
          data={roles}
          columns={columns}
          defaultSortKey={'role'}
          fetched={loaded}
          emptyTitle={'Missing Roles & Certificates'}
          emptyMessage={
            'Once you create a few roles and certificate types, you can use the table here to mark certificates as mandatory for a certain role.'
          }
        />
      </div>
    );
  }
}

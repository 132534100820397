import styled from 'styled-components';
import { biggerThanMD } from '../../helpers/style';
import { cssVars } from '../../constants/index';
import { Widget } from '../../types';
import formService from '../../services/forms.service';
import { toast } from 'react-toastify';
import { useState } from 'react';

export interface FormFabricatorProps {
  mandatory?: boolean;
  widget: Widget;
  formUid: string;
  formRevisionUid: any;
  setWidgets(uid: string, data: any): void;
  openDelete(id: string): void;
  editErrors: string[];
  setEditErrors: React.Dispatch<React.SetStateAction<string[]>>;
}

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${cssVars.colors.border};
  border-radius: 3px;
  align-items: stretch;

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

export const BasicLabel = styled.label`
  line-height: 35px;
  width: 100%;
  display: block;
  padding: 0.25rem;
`;

export const InspectionLabel = styled.label<{ error: boolean }>`
  padding: ${cssVars.padding[4]};
  line-height: 35px;
  width: 100%;
  border: ${props => (props.error ? 'solid 2px red' : '')};
  border-radius: 4px;
  ${biggerThanMD(`
    padding: 0 ${cssVars.padding[4]};
  `)};
`;

export const ButtonWrapper = styled.div`
  width: 100%;
  flex: 1;
  ${biggerThanMD(`
    width: auto;
  `)};
`;

export const WidgetActionButton = styled.button<{ bg?: string }>`
  background: transparent;
  border: none;
  padding: 0px;
  padding-right: 5px;
  cursor: pointer;
  flex: 1;
  position: relative;

  ${biggerThanMD(`
    flex: 0;
  `)} &:first-of-type {
    border-left: 0;
  }

  svg {
    padding: 1px;
    fill: ${props => (props.bg ? props.bg : cssVars.colors['grey-darker'])};
  }
`;

export function useWidgetChange({
  formRevisionUid,
  setWidgets,
  widget,
}: {
  formRevisionUid: string;
  setWidgets(uid: string, data: any): void;
  widget: Widget;
}) {
  async function handleWidgetChange(widgetData: Partial<Widget>) {
    // TODO handle errors
    const { data } = await formService.updateWidget(
      formRevisionUid,
      widget.uid,
      widgetData,
    );
    if (data) {
      setWidgets(widget.uid, data.widget);
    }
  }
  return { handleWidgetChange };
}

export function useWidgetMandatory({
  widget,
  formRevisionUid,
  mandatory = false,
}: {
  widget: Widget;
  formRevisionUid: string;
  mandatory?: boolean;
}) {
  const [checked, setChecked] = useState<boolean>(mandatory);
  const handleMandatoryChange = async () => {
    setChecked(!checked);
    const { data } = await formService.updateWidget(
      formRevisionUid,
      widget.uid,
      {
        mandatory: !checked,
      },
    );
    if (!data) {
      toast.error('Failed to update widget!');
    }
  };
  return { checked, handleMandatoryChange };
}

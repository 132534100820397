import * as workRoleActions from '../actions/workRoles';
import { ActionType, getType } from 'typesafe-actions';
import { PermissionsError, WorkRole } from '../types';

export type WorkRoleAction = ActionType<typeof workRoleActions>;

export interface WorkRolesState {
  loading: boolean;
  loaded: boolean;
  errors: PermissionsError;
  data: WorkRole[];
  companyUid: string;
}

const DEFAULT_STATE: WorkRolesState = {
  loading: false,
  loaded: false,
  errors: {
    token: [],
  },
  data: [],
  companyUid: '',
};

export default function workRolesReducer(
  state: WorkRolesState = DEFAULT_STATE,
  action: WorkRoleAction,
) {
  switch (action.type) {
    case getType(workRoleActions.workRolesFetchRequest):
      return {
        ...state,
        loading: true,
        companyUid: action.payload,
      };
    case getType(workRoleActions.workRolesFetchSuccess):
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...action.payload],
      };
    case getType(workRoleActions.workRolesFetchFailure):
      return {
        ...state,
        loaded: false,
        loading: false,
        data: [],
        errors: { ...action.payload },
      };
    case getType(workRoleActions.setWorkRoles):
      return {
        ...state,
        data: [...action.payload],
      };
    case getType(workRoleActions.addWorkRole):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(workRoleActions.updateWorkRole):
      return {
        ...state,
        data: state.data.map(
          cert =>
            cert.uid === action.payload.uid ? { ...action.payload } : cert,
        ),
      };
    case getType(workRoleActions.removeWorkRole):
      return {
        ...state,
        data: state.data.filter(cert => cert.uid !== action.payload),
      };
    default:
      return state;
  }
}

import * as userActions from '../actions/users';
import { ActionType, getType } from 'typesafe-actions';
import { User } from '../types';

export type UserAction = ActionType<typeof userActions>;

export interface UsersState {
  data: User[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyUid: string;
  invalid: boolean;
}

const DEFAULT_STATE: UsersState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  companyUid: '',
  invalid: false,
};

export default function usersReducer(
  state: UsersState = DEFAULT_STATE,
  action: UserAction,
) {
  switch (action.type) {
    case getType(userActions.usersFetchRequest):
      return {
        ...state,
        loading: true,
        companyUid: action.payload,
      };
    case getType(userActions.usersFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...action.payload],
        invalid: false,
      };
    case getType(userActions.usersFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
        invalid: true,
      };
    case getType(userActions.addUser):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(userActions.removeUser):
      return {
        ...state,
        data: state.data.filter(user => user.uid !== action.payload),
      };
    case getType(userActions.usersInvalidate):
      return {
        ...state,
        invalid: true,
      };
    default:
      return state;
  }
}

import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../constants';
import Checkmark from '../svg/Checkmark';

const Wrapper = styled.div`
  & input {
    display: none;
  }

  & input[type='checkbox'] + label {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid ${cssVars.colors['grey-darker']};
    border-radius: 2px;
    cursor: pointer;

    svg {
      visibility: hidden;
    }
  }

  & input[type='checkbox']:checked + label {
    border: 2px solid ${cssVars.colors.red};
    background: ${cssVars.colors.red};
    border-radius: 2px;
    color: white;

    svg {
      visibility: visible;
    }
  }
`;

interface Props {
  checked?: boolean;
  id: string;
  onChange?: (checked: boolean) => void;
}

class Checkbox extends React.Component<Props> {
  private onChange = (e: React.FormEvent<HTMLInputElement>) => {
    const { onChange } = this.props;
    if (onChange) {
      onChange(e.currentTarget.checked);
    }
  };

  public render() {
    const { id, checked } = this.props;
    return (
      <Wrapper>
        <input
          type={'checkbox'}
          id={id}
          checked={checked}
          onChange={this.onChange}
        />
        <label htmlFor={id}>
          <Checkmark className={'w-4 h-4'} />
        </label>
      </Wrapper>
    );
  }
}

export default Checkbox;

import * as React from 'react';
import { useEffect } from 'react';
import { localStorageKeys } from '../constants/index';
import { History } from 'history';
import getAuth0Client from '../auth0';
import Loading from '../components/Loading';

const Auth0Callback = ({ history }: { history: History }) => {
  async function getToken() {
    const client = await getAuth0Client();
    const token = await client.getTokenSilently();
    localStorage.setItem(localStorageKeys.USER_TOKEN, token);
  }

  useEffect(() => {
    getToken()
      .then(() => {
        history.push('/dashboard');
      })
      .catch(() => {
        history.push('/login');
      });
    return;
  }, []);

  return <Loading loading={true} />;
};

export default Auth0Callback;

import * as React from 'react';
import { Form, Company } from '../../types';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { StoreState } from '../../store';
import { WithNamespaces } from 'react-i18next';
import ResourceTable from '../../components/ResourceTable';
import { addForm } from '../../actions/forms';
import { FormsState } from '../../reducers/forms';
import { useState, useEffect } from 'react';
import { useMountVisibility, useEntityRefresh } from '../../helpers/hooks';
import { sitesFetchRequest } from '../../actions/sites';
import { SitesState } from '../../reducers/sites';
import { crewsFetchRequest } from '../../actions/crews';
import { CrewsState } from '../../reducers/crews';
import PageHeading from '../../components/PageHeading';
import { RouteComponentProps } from 'react-router-dom';
import formService from '../../services/forms.service';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import PickDraft from './site/PickDraft';

interface Props extends WithNamespaces, RouteComponentProps {
  userUid: string;
  company: Company;
  getSites: (companyUid: string) => any;
  getCrews: (companyUid: string) => any;
  forms: FormsState;
  sites: SitesState;
  crews: CrewsState;
}

const Forms = ({
  getSites,
  userUid,
  company,
  sites,
  getCrews,
  crews,
  history,
}: Props) => {
  useEntityRefresh(company.uid, sites, getSites);
  useEntityRefresh(company.uid, crews, getCrews);
  const draftModal = useMountVisibility();
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedFormName, setSelectedFormName] = useState<string>('');
  const [selectedFormUid, setSelectedFormUid] = useState<string>('');
  const [forms, setForms] = useState<Form[]>([]);

  async function getForms() {
    setLoading(true);
    const { data } = await formService.getV2Forms(company.uid);
    setLoading(false);
    if (data) {
      setForms(data.formDownload.map(({ form }) => form));
      return;
    }
    toast.error('Failed to get forms!');
  }

  const tableColumns = [
    {
      Header: 'Name',
      accessor: 'name',
    },
  ];

  function openFormModal(formName: string, formUid: string) {
    setSelectedFormName(formName);
    setSelectedFormUid(formUid);
    draftModal.open();
  }

  useEffect(
    () => {
      if (draftModal.visible === false) {
        setSelectedFormUid('');
      }
    },
    [draftModal.visible],
  );

  useEffect(
    () => {
      getForms();
    },
    [company.uid],
  );

  return loading ? (
    <Loading />
  ) : (
    <Page>
      <PageContent>
        <PageHeading
          title={`Select V2 Form`}
          subtitle="You can only see forms here that were created through the version 2 form builder."
        />
        <ResourceTable
          data={forms}
          columns={tableColumns}
          defaultSortKey={'name'}
          loading={loading}
          onRowClick={(event: any) => openFormModal(event.name, event.uid)}
          fetched={loading}
          emptyTitle={'There are no forms to fill out.'}
          emptyMessage={'Please have your supervisor create a v2 form first'}
        />
      </PageContent>
      {draftModal.mounted && (
        <PickDraft
          isOpen={draftModal.mounted}
          handleClose={draftModal.close}
          history={history}
          formName={selectedFormName}
          formUid={selectedFormUid}
        />
      )}
    </Page>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    userUid: state.me.user.uid,
    company: state.me.company,
    forms: state.forms,
    sites: state.sites,
    crews: state.crews,
    formsLoaded: state.forms.loaded,
    formsLoading: state.forms.loading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getSites: (companyUid: string) => dispatch(sitesFetchRequest(companyUid)),
    getCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
    addForm: (form: Form) => dispatch(addForm(form)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Forms);

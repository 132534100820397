import store from '../store';

export interface EntityState<T = any> {
  loading: boolean;
  loaded: boolean;
  companyUid?: string;
  companyGroupUid?: string;
  data: T;
  invalid?: boolean;
}

export const needsToBeFetched = (state: EntityState) => {
  const { company, companyGroup } = store.getState().me;

  if (state.invalid && !state.loading) {
    return true;
  }

  if (
    typeof state.companyUid !== 'undefined' &&
    company.uid !== state.companyUid
  ) {
    return true;
  }

  if (
    typeof state.companyGroupUid !== 'undefined' &&
    companyGroup.uid !== state.companyGroupUid
  ) {
    return true;
  }

  return !state.loaded && !state.loading;
};

import * as i18n from 'i18next';

i18n.init({
  lng: 'en',
  fallbackLng: 'en',
  ns: ['common'],
  defaultNS: 'common',
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
  resources: {
    en: {
      common: require('./lang/en/common.json'),
      login: require('./lang/en/login.json'),
      super: require('./lang/en/super.json'),
      forms: require('./lang/en/forms.json'),
      widgets: require('./lang/en/widgets.json'),
    },
  },
});

export default i18n;

import * as React from 'react';
import { useState, useEffect } from 'react';
import { match } from 'react-router';
import formApi from '../../api/form.api';
import {
  FormOption,
  Form as IForm,
  Company,
  Site,
  User,
  SubmData,
  Submission,
  ParsedSubmData,
  Deployment,
  Colors,
  Crew,
  SubmRev,
} from '../../types';
import FormView from '../../containers/Form';
import { formFeatureIds, localStorageKeys } from '../../constants';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { setFormOptions } from '../../actions/formOptions';
import Suitcase from '../../svg/Suitcase';
import Location from '../../svg/Location';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import PageToolbar from '../../components/PageToolbar';
import fcApi from '../../api/fc.api';
import Loading from '../../components/Loading';
import submissionApi from '../../api/submission.api';
import { setSubmData } from '../../actions/submData';
import { useMountVisibility, useSimpleFetch } from '../../helpers/hooks';
import HistorySidebox from './form/HistorySidebox';
import Clock from '../../svg/Clock';
import Checkmark from '../../svg/Checkmark';
import Envelope from '../../svg/Envelope';
import submRevApi from '../../api/submRev.api';
import SendForm from './form/SendForm';
import Button from '../../components/Button';
import { Link } from 'react-router-dom';
import File from '../../svg/File';
import {
  attempt,
  getDivisionLabel,
  getSiteLabel,
  getCrewLabel,
} from '../../helpers/index';
import { toast } from 'react-toastify';
import { History } from 'history';
import Save from '../../svg/Save';
import { FormState } from '../../reducers/form';
import { setFormMeta } from '../../actions/form';
import AuditScore from './form/AuditScore';
import Star from '../../svg/Star';
import companyUserApi, {
  FavouriteFormResponse,
} from '../../api/companyUser.api';
import { StoreState } from '../../store';
import { contains } from 'ramda';
import userApi from '../../api/user.api';
import ChevRightOutline from '../../svg/ChevRightOutline';
import { SiteFormsState } from '../../reducers/siteForms';
import { siteFormsFetchRequest } from '../../actions/siteForms';
import { FormsOnSite } from '../../api/site.api';
import Download from '../../svg/Download';
import { getLocation } from '../../helpers/location';
import Select, {
  SelectOption,
  SelectOptionObject,
} from '../../components/Select';
import moment from 'moment';
import CloseOutline from '../../svg/CloseOutline';
import CheckCircle from '../../svg/CheckCircle';
import Pencil from '../../svg/Pencil';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import StarFull from '../../svg/StarFull';

interface Props extends WithNamespaces {
  match: match<{ fcUid: string; submissionUid?: string; revId?: string }>;
  setFormOptions: (options: FormOption[]) => any;
  setSubmData: (submData: ParsedSubmData[]) => any;
  setFormMeta: (meta: Partial<FormState>) => any;
  history: History;
  company: Company;
  user: User;
  form: FormState;
  siteFormsState: SiteFormsState;
  requestSiteForms: (siteUid: string, userUid: string) => any;
}

const Form = ({
  match: pMatch,
  setFormOptions: pSetFormOptions,
  setSubmData: pSetSubmData,
  history,
  setFormMeta: pSetFormMeta,
  company: currentCompany,
  user,
  form: formState,
  siteFormsState,
  requestSiteForms,
  t,
}: Props) => {
  const { fcUid, submissionUid, revId } = pMatch.params;
  const [options, setOptions] = useState<FormOption[]>([]);
  const [form, setForm] = useState<IForm | null>(null);
  const [company, setCompany] = useState<Company | null>(null);
  const [site, setSite] = useState<Site | null>(null);
  const [loading, setLoading] = useState(false);
  const historyState = useMountVisibility();
  const sendState = useMountVisibility();
  const [submission, setSubmission] = React.useState<Submission | null>(null);
  const [revMeta, setRevMeta] = React.useState<any>({});
  const [deployment, setDeployment] = React.useState<Deployment | null>(null);
  const [deployee, setDeployee] = React.useState<Crew | User | Site | null>(
    null,
  );
  const [submDataFetched, setSubmDataFetched] = React.useState(false);
  const [temp, setTemp] = React.useState<number | null>(null);
  const [address, setAddress] = React.useState<string | null>(null);
  const [submitting, setSubmitting] = React.useState(false);
  const [canReview, setCanReview] = useState(false);
  const [localLocation, setLocalLocation] = useState('');
  const [rev, setRev] = React.useState<null | SubmRev>(null);

  const mode = revId ? 'revision' : submissionUid ? 'submission' : 'new';

  const favouriteForms = useSimpleFetch<FavouriteFormResponse[]>({
    initial: [],
    fetch: () => companyUserApi.favouriteForms(currentCompany.uid, user.uid),
    accessor: 'favouriteForms',
    change: [currentCompany.uid, user.uid],
  });
  const isFavouriteForm = contains(
    fcUid,
    favouriteForms.value.map(x => x.fc.uid),
  );

  const getActions = async () => {
    if (!submission) {
      return;
    }
    const { data } = await submissionApi.actions(submission.uid);
    if (data) {
      pSetFormMeta({ actions: data.actions });
    }
  };

  const getCompliance = async () => {
    if (!submission || !form || form.type !== 'audit') {
      return;
    }
    setLoading(true);
    const { data } = await submissionApi.compliance(
      submission.uid,
      revId ? +revId : undefined,
    );
    if (data) {
      pSetFormMeta({ compliance: data.compliance });
    }
    setLoading(false);
  };

  const getFormOptions = async (f: IForm) => {
    setLoading(true);
    try {
      const res = await formApi.options(f.uid);

      setOptions(res.options);
      pSetFormOptions(res.options);
    } catch (e) {
      //
    }
    setLoading(false);
  };

  const getNodes = async () => {
    setLoading(true);

    if (revId) {
      const { data } = await submRevApi.show(+revId);
      if (data) {
        setForm(data.form);
        setRevMeta(data.meta);
        setSubmission(data.submission);
        setRev(data.rev);
      }
    } else {
      const { data } = await fcApi.show(fcUid);

      if (data) {
        setForm(data.form);
        setCompany(data.company);
        setSite(data.site);
        pSetFormMeta({ site: data.site });
        setDeployment(data.deployment);
        setDeployee(data.deployee);
        if (!submissionUid) {
          setSubmission(data.workingCopy);
        } else {
          const { data: submRes } = await submissionApi.show(submissionUid);
          if (submRes) {
            setSubmission(submRes.submission);
          }
        }
      }
    }
    setLoading(false);
  };

  const setWorkingCopyLocation = () => {
    if (!submissionUid && !revId) {
      getLocation().then(res => {
        if (!res) {
          return;
        }
        setAddress(res.location.address);
        setTemp(res.weather.temp);
      });
    }
  };

  const getResponses = async () => {
    if (!submission) {
      return;
    }
    setSubmDataFetched(false);
    const { data } = await submissionApi.responses(submission.uid, { revId });

    if (data) {
      pSetSubmData(data.submData);
    }
    setSubmDataFetched(true);
  };

  useEffect(
    () => {
      if (site) {
        requestSiteForms(site.uid, user.uid);
      }
    },
    [site ? site.uid : '', user.uid],
  );

  const setSubmLocation = async () => {
    const location = await getLocation();
    if (!submission) {
      return;
    }
    if (location) {
      const { data } = await submissionApi.setLocation(submission.uid, {
        temp: location.weather.temp,
        icon: location.weather.icon,
        main: location.weather.main,
        description: location.weather.description,
        address: location.location.address,
        lat: location.location.lat,
        lng: location.location.lng,
      });
      if (data) {
        setLocalLocation(data.submission.geo_location);
      }
    }
  };

  useEffect(
    () => {
      if (submission && !submission.geo_location && !submissionUid && !revId) {
        setSubmLocation();
      }
    },
    [submission ? submission.uid : ''],
  );

  useEffect(
    () => {
      if (submission) {
        setWorkingCopyLocation();
        getResponses();
        pSetFormMeta({ submissionUid: submission.uid });
        getActions();
      }
    },
    [submission],
  );

  const submit = async () => {
    setSubmitting(true);
    await fcApi.submit(fcUid);
    setSubmitting(false);
    toast.success('Form submitted');
    history.push('/dashboard/worker/dashboard');
  };

  const submitAll = async () => {
    setSubmitting(true);
    await fcApi.submit(fcUid, { all: true });
    setSubmitting(false);
    toast.success('Submitted all forms');
    history.push('/dashboard/worker/dashboard');
  };

  const save = async () => {
    if (!submissionUid) {
      return;
    }
    setSubmitting(true);
    await submissionApi.save(submissionUid);
    toast.success('Form saved');
    setSubmitting(false);
    history.push('/dashboard/worker/dashboard');
  };

  const approve = async () => {
    if (!submissionUid) {
      return;
    }
    setSubmitting(true);
    await submissionApi.approve(submissionUid);
    toast.success('Form approved');
    setSubmitting(false);
    history.push('/dashboard/worker/dashboard');
  };
  const reject = async () => {
    if (!submissionUid) {
      return;
    }
    setSubmitting(true);
    await submissionApi.reject(submissionUid);
    toast.success('Form rejected');
    setSubmitting(false);
    history.push('/dashboard/worker/dashboard');
  };

  const favouriteForm = async () => {
    if (isFavouriteForm) {
      await userApi.unfavouriteForm(user.uid, fcUid);
      toast.success('Form removed from favourites');
    } else {
      await userApi.favouriteForm(user.uid, fcUid);
      toast.success('Form favourited');
    }
    favouriteForms.performFetch();
  };

  useEffect(
    () => {
      if (form) {
        getFormOptions(form);
      }
    },
    [form],
  );

  useEffect(
    () => {
      if (form && submission && form.type === 'audit') {
        getCompliance();
      }
    },
    [form, submission],
  );

  useEffect(
    () => {
      getNodes();
    },
    [fcUid],
  );

  useEffect(
    () => {
      pSetFormMeta({ editable: !revId });
    },
    [revId],
  );

  useEffect(() => {
    return () => {
      pSetFormOptions([]);
      pSetSubmData([]);
      pSetFormMeta({ editable: false, submissionUid: '' });
    };
  }, []);
  const getCanReview = async () => {
    if (!submissionUid) {
      return;
    }
    const { data } = await submissionApi.canReview(submissionUid, { revId });
    if (data) {
      setCanReview(data.ok);
    }
  };

  useEffect(
    () => {
      if (submissionUid) {
        getCanReview();
      }
    },
    [submissionUid, revId],
  );

  const creating = React.useRef(false);

  const createFcIfNotExists = async (dep: Deployment, depee: Crew | User) => {
    // prevent accidental double+ click creating multiple fcs
    if (!creating.current) {
      creating.current = true;
      const { data } = await fcApi.createIfNotExists(dep.uid, depee.uid);
      creating.current = false;
      if (data) {
        return data.fc;
      }
    }
    return;
  };

  const onDeployeeChange = async (newDep: SelectOptionObject) => {
    if (!form || !deployment || !deployee || deployment.type === 'site') {
      return;
    }
    const depState =
      deployment.type === 'crew'
        ? siteFormsState.data.crews
        : siteFormsState.data.users;
    const accessor = deployment.type;
    const nextDep = (depState as any).find(
      (x: any) => x[accessor].uid === newDep.value,
    );
    if (nextDep === -1) {
      return;
    }
    let nextFc: any = nextDep.forms.find((x: any) => x.form.uid === form.uid);
    if (nextFc && nextFc.fc) {
      nextFc = nextFc.fc;
    } else if (!nextFc || !nextFc.fc) {
      nextFc = await createFcIfNotExists(deployment, nextDep[accessor]);
    }
    if (!nextFc) {
      return;
    }
    history.push(`/dashboard/worker/forms/${nextFc.uid}`);
  };

  if (loading) {
    return (
      <div className="p-8 relative">
        <Loading loading={loading} />
      </div>
    );
  }

  const formName = revId ? revMeta.form_name : form ? form.name : '';
  const formUid = form ? form.uid : '';
  const companyName = revId
    ? revMeta.company_name
    : company
      ? company.name
      : '';
  const siteName = revId ? revMeta.site_name : site ? site.name : '';
  const geo = localLocation
    ? attempt(() => JSON.parse(localLocation || ''), {})
    : submission
      ? attempt(() => JSON.parse(submission.geo_location || ''), {})
      : {};
  const deploymentType = revId
    ? revMeta.deploy_type
    : deployment
      ? deployment.type
      : '';
  const deploymentName = revId
    ? revMeta.deploy_name
    : deployee
      ? (deployee as User).username || (deployee as Crew).name
      : '';
  const formType = revId ? revMeta.form_type : form ? form.type : '';

  const canAllSubmit =
    formType === 'audit' &&
    !revId &&
    !submissionUid &&
    (deploymentType === 'crew' || deploymentType === 'user');

  const goToEditSubm = () => {
    history.push(
      `/dashboard/worker/forms/${fcUid}/submission/${submissionUid}`,
    );
  };

  const favouriteClass = isFavouriteForm ? 'text-yellow' : 'text-black';

  const deploymentOptions =
    deploymentType === 'crew'
      ? siteFormsState.data.crews
      : deploymentType === 'users'
        ? siteFormsState.data.users
        : [];

  const submitUnix = rev
    ? rev.created
    : submission
      ? submission.created_at
      : null;
  const submissionDate = submitUnix
    ? moment.unix(submitUnix).format('MMM Do, YYYY hh:mm a')
    : '';

  return (
    <Page>
      <Loading loading={submitting} />
      <PageToolbar
        sticky={true}
        title={''}
        actions={[
          { label: 'History', icon: <Clock />, onClick: historyState.open },
          ...(submissionUid || revId
            ? [
                {
                  label: 'Download Latest',
                  icon: <Download />,
                  href: `${
                    process.env.API_URL
                  }/v1/submissions/${submissionUid}/download?token=${localStorage.getItem(
                    localStorageKeys.USER_TOKEN,
                  )}`,
                  external: true,
                },
                { label: 'Send', icon: <Envelope />, onClick: sendState.open },
              ]
            : []),
          mode === 'revision'
            ? { label: 'Edit', onClick: goToEditSubm, icon: <Pencil /> }
            : mode === 'submission'
              ? {
                  label: 'Save',
                  icon: <Save />,
                  onClick: save,
                }
              : !canAllSubmit
                ? {
                    label: 'Submit',
                    type: 'success',
                    icon: <Checkmark />,
                    onClick: submit,
                  }
                : null,
          ...(canAllSubmit
            ? [
                {
                  label: 'Submit All',
                  type: 'red' as Colors,
                  onClick: submitAll,
                  icon: <Checkmark />,
                },
              ]
            : []),
        ].filter(x => x !== null)}
      />
      <PageContent>
        <div className="p-4">
          {mode === 'revision' && (
            <div className="mb-4 border-0 border-l-4 border-solid border-yellow-dark bg-yellow-lighter p-4">
              <p className="text-yellow-darkest mb-4 font-bold">
                You're viewing a revision of a submission.
              </p>
              <div>
                <Button
                  type="default"
                  className={'mr-4'}
                  onClick={goToEditSubm}
                >
                  Edit this submission
                </Button>
                <Link
                  to={`/dashboard/worker/forms/${fcUid}`}
                  className={'no-underline text-red'}
                >
                  Fill out a fresh form
                </Link>
              </div>
            </div>
          )}

          {canReview && (
            <div className="flex flex-col md:flex-row bg-default md:justify-between md:items-center">
              <p className={'p-4 md:py-0 md:px-4 text-white'}>
                This form has been sent to you for review
              </p>
              <div className="flex flex-row">
                <button
                  className="flex-1 md:flex-initial bg-transparent cursor-pointer hover:bg-grey-darkest text-white flex flex-row items-center p-4 border-0 md:border-l-1 md:border-solid md:border-grey-dark"
                  disabled={submitting}
                  onClick={reject}
                >
                  <CloseOutline className={'w-6 h-6 mr-2 text-red'} />
                  Reject
                </button>
                <button
                  className="flex-1 md:flex-initial bg-transparent cursor-pointer hover:bg-grey-darkest text-white flex flex-row items-center p-4 border-0 border-l-1 border-solid border-grey-dark"
                  disabled={submitting}
                  onClick={approve}
                >
                  <CheckCircle className={'w-6 h-6 mr-2 text-green'} />
                  Approve
                </button>
              </div>
            </div>
          )}
          <div className="bg-white p-4 flex flex-col mb-4">
            <div className="flex flex-col md:flex-row md:items-center md:justify-between">
              <div className="flex flex row items-center mb-4 md:mb-0">
                <File className={'w-6 h-6 mr-4'} />
                <div>
                  <h3 className={'font-normal'}>{formName}</h3>
                  <p className={'text-grey-darker'}>ID: {formUid}</p>
                </div>
                {isFavouriteForm ? (
                  <StarFull
                    className={`w-6 h-6 hover:text-red cursor-pointer ml-2 ${favouriteClass}`}
                    onClick={favouriteForm}
                  />
                ) : (
                  <Star
                    className={`w-6 h-6 hover:text-yellow cursor-pointer ml-2}`}
                    onClick={favouriteForm}
                  />
                )}
              </div>
              {canAllSubmit ? (
                <div className="flex flex-row w-full md:w-1/3 lg:w-1/5">
                  <Select
                    options={(deploymentOptions as any).map((x: any) => ({
                      label: x.crew
                        ? x.crew.name
                        : x.user
                          ? x.user.username
                          : '',
                      value: x.crew ? x.crew.uid : x.user ? x.user.uid : '',
                    }))}
                    onChange={onDeployeeChange}
                    value={{ label: deploymentName, value: '' }}
                  />
                </div>
              ) : null}
              {rev ? (
                <div>
                  <strong>Submitted: </strong> {submissionDate}
                </div>
              ) : null}
            </div>

            <hr className={'my-4'} />

            <div className="flex flex-col md:flex-row md:items-center">
              <div className="flex-1 flex flex-row items-center">
                <Suitcase className={'w-6 h-6 mr-4'} />
                <div>
                  <h3 className={'font-normal'}>{getDivisionLabel()}</h3>
                  <p className={'text-grey-darker'}>{companyName}</p>
                </div>
              </div>
              <div className="flex-1 flex flex-row items-center">
                <Location className={'w-6 h-6 mr-4'} />
                <div>
                  <h3 className={'font-normal'}>{getSiteLabel()}</h3>
                  <p className={'text-grey-darker'}>{siteName}</p>
                </div>
              </div>
              {deploymentType === 'crew' || deploymentType === 'user' ? (
                <div className="flex-1 flex flex-row items-center">
                  <Location className={'w-6 h-6 mr-4'} />
                  <div>
                    <h3 className={'font-normal'}>
                      {deploymentType === 'crew' ? getCrewLabel() : 'User'}
                    </h3>
                    <p className={'text-grey-darker'}>{deploymentName}</p>
                  </div>
                </div>
              ) : null}
              <div className="flex-1 flex flex-row items-center">
                <Location className={'w-6 h-6 mr-4'} />
                <div>
                  <h3 className={'font-normal'}>Location</h3>
                  <p className={'text-grey-darker'}>
                    {geo.address || address || '-'}
                  </p>
                </div>
              </div>
              <div className="flex-1 flex flex-row items-center">
                <Suitcase className={'w-6 h-6 mr-4'} />
                <div>
                  <h3 className={'font-normal'}>Weather</h3>
                  <p className={'text-grey-darker'}>
                    {geo.temp ? (
                      <>
                        {geo.temp}
                        &deg;
                        {geo.unit}
                      </>
                    ) : temp ? (
                      Math.round(temp)
                    ) : (
                      '-'
                    )}
                  </p>
                </div>
              </div>
            </div>

            {form &&
              form.type === 'audit' && (
                <AuditScore
                  all={
                    deploymentType.toLowerCase() === 'crew' ||
                    deploymentType.toLowerCase() === 'user'
                  }
                  compliance={formState.compliance}
                />
              )}
          </div>
          {submDataFetched && (
            <FormView
              sections={options.filter(s => s.f_id === formFeatureIds.SECTION)}
              live={true}
            />
          )}
          {historyState.mounted && (
            <HistorySidebox
              isOpen={historyState.visible}
              handleClose={historyState.close}
              fcUid={fcUid}
            />
          )}
          {sendState.mounted &&
            submissionUid && (
              <SendForm
                isOpen={sendState.visible}
                handleClose={sendState.close}
                submissionUid={submissionUid}
              />
            )}
        </div>
      </PageContent>
    </Page>
  );
};

const mapStateToProps = (state: StoreState) => ({
  company: state.me.company,
  user: state.me.user,
  form: state.form,
  siteFormsState: state.siteForms,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setFormOptions: (options: FormOption[]) => dispatch(setFormOptions(options)),
  setSubmData: (submData: ParsedSubmData[]) => dispatch(setSubmData(submData)),
  setFormMeta: (meta: Partial<FormState>) => dispatch(setFormMeta(meta)),
  requestSiteForms: (siteUid: string, userUid: string) =>
    dispatch(siteFormsFetchRequest({ siteUid, userUid })),
});

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Form),
);

import * as React from 'react';
import InlineInputBase, {
  Props as InlineInputBaseProps,
} from './InlineInputBase';
import { SelectOption } from './Select';
import ReactSelect from 'react-select';
import { colors } from '../constants';
import { Option } from 'react-select/lib/filters';
import { orderBy } from 'natural-orderby';

const customSelectStlyes = {
  container: (base: any) => ({
    ...base,
    flex: 1,
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: 0,
    zIndex: 10,
  }),
  menuPortal: (base: any) => ({
    ...base,
    borderRadius: 0,
    zIndex: 10,
  }),
  control: (base: any) => ({
    ...base,
    border: 0,
    borderRadius: 0,
  }),
  option: (base: any, state: any) => ({
    ...base,
    background: state.isFocused ? colors.PRIMARY : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      background: colors.PRIMARY,
      color: 'white',
    },
    '&.active': {
      background: colors.PRIMARY,
      color: 'white',
    },
  }),
};

interface Props extends InlineInputBaseProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onChange?: (value: SelectOption | SelectOption[]) => void;
  value?: SelectOption | SelectOption[];
  defaultValue?: SelectOption | SelectOption[];
  options: SelectOption[];
  menuPortalTarget?: any;
  menuPosition?: 'absolute' | 'fixed';
  isMulti?: boolean;
  disabled?: boolean;
  isClearable?: boolean;
  backspaceRemovesValue?: boolean;
  className?: string;
}

export default class InlineSelect extends React.Component<Props, {}> {
  public static defaultProps = {
    inputProps: {},
    backspaceRemovesValue: false,
  };

  private filterOption = (option: Option, input: string) => {
    return option.label.toLowerCase().indexOf(input.toLowerCase()) > -1;
  };

  public render() {
    const {
      icon,
      label,
      inputProps,
      errorMessage,
      value,
      defaultValue,
      options,
      onChange,
      menuPortalTarget,
      menuPosition,
      isMulti,
      disabled,
      isClearable,
      backspaceRemovesValue,
      className,
    } = this.props;

    return (
      <InlineInputBase
        icon={icon}
        label={label}
        errorMessage={errorMessage}
        id={inputProps && inputProps.id}
        className={className}
      >
        <ReactSelect
          //defaultValue must be below value
          value={value}
          defaultValue={defaultValue}
          options={orderBy(options, [x => (x ? x.label : null)], ['asc'])}
          styles={customSelectStlyes}
          onChange={onChange}
          menuPortalTarget={menuPortalTarget}
          menuPosition={menuPosition}
          isMulti={isMulti}
          isDisabled={disabled}
          isClearable={isClearable}
          backspaceRemovesValue={backspaceRemovesValue}
          filterOption={this.filterOption}
        />
      </InlineInputBase>
    );
  }
}

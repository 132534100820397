import * as React from 'react';
import Button from '../../../components/Button';
import ResourceList from '../../../components/ResourceList';
import { Action } from '../../../types';
import ActionItem from '../../../components/ActionItem';
import Radios from '../../../components/Radios';
import moment from 'moment';
import { MyActionsResponse } from '../../../api/user.api';

interface Props {
  title: string;
  onCreateClick: () => void;
  actions: MyActionsResponse[];
}

const ActionsList = ({ title, onCreateClick, actions }: Props) => {
  const [view, setView] = React.useState('all');

  const filterActionByStatus = ({ action }: { action: Action }) => {
    switch (view) {
      case 'urgent':
        return action.urgent;
      case 'overdue':
        return moment.unix(action.date_due).isBefore(moment());
      case 'all':
      default:
        return true;
    }
  };

  const handleChange = (v: string) => {
    setView(v);
  };

  return (
    <div>
      <div className="flex justify-end p-4">
        <Button
          type={'primary'}
          children={'Create Action'}
          onClick={onCreateClick}
        />
      </div>
      <ResourceList title={title} items={actions}>
        {({ items }) => (
          <div>
            <div className={'mb-4'}>
              <Radios
                onChange={handleChange}
                checked={view}
                inputs={[
                  { label: 'All', value: 'all' },
                  { label: 'Urgent', value: 'urgent' },
                  { label: 'Overdue', value: 'overdue' },
                ]}
              />
            </div>
            {items.filter(filterActionByStatus).map(item => (
              <ActionItem key={item.action.uid} action={item.action} />
            ))}
          </div>
        )}
      </ResourceList>
    </div>
  );
};

export default ActionsList;

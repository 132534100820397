import { api2 } from './api';
import { User, Crew, Storage } from '../types';

export interface ChildrenResponse {
  storage: Storage;
  locked: boolean;
  type: 'Dir' | 'File';
}

const storageApi = {
  show: (storageUid: string) =>
    api2<{ storage: Storage; path: Storage[] }>('GET', `storage/${storageUid}`),
  children: (storageUid: string) =>
    api2<{ children: ChildrenResponse[]; path: Storage[] }>(
      'GET',
      `storage/${storageUid}/children`,
    ),
  create: (storageUid: string, data: { name: string }) =>
    api2('POST', `storage/${storageUid}/children`, data),
  shared: (storageUid: string) =>
    api2<{ users: User[]; crews: Crew[] }>(
      'GET',
      `storage/${storageUid}/shared`,
    ),
  shareWithUser: (storageUid: string, userUid: string) =>
    api2('POST', `storage/${storageUid}/shared`, { userUids: [userUid] }),
  shareWithCrew: (storageUid: string, crewUid: string) =>
    api2('POST', `storage/${storageUid}/shared`, { crewUids: [crewUid] }),
  unshare: (storageUid: string, nodeUid: string) =>
    api2('DELETE', `storage/${storageUid}/shared/${nodeUid}`),
  delete: (storageUid: string) => api2('DELETE', `storage/${storageUid}`),
  upload: (storageUid: string, data: any) =>
    api2('POST', `storage/${storageUid}/uploads`, data),
  getPrivateUrlFromUid: (uid: string) =>
    api2<{ url: string }>('GET', `private-storage/${uid}?url=true`),
  changeParent: (dragUid: string, dropUid: string) =>
    api2('PUT', `storage/${dragUid}/parent/${dropUid}`),
  regenerate: (storageUids: string[]) =>
    api2('POST', `fix-submission-pdfs`, { storageUids }),
};

export default storageApi;

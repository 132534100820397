import * as React from 'react';
import styled from 'styled-components';
import { biggerThanMD } from '../../helpers/style';

const Wrapper = styled.main`
  padding: 4px;
  max-height: 70%;
  overflow: auto;

  ${biggerThanMD(`
    padding: 16px 8px;
  `)};
`;

export default class ModalBody extends React.Component {
  public render() {
    return <Wrapper>{this.props.children}</Wrapper>;
  }
}

import * as React from 'react';
import Page from '../../../components/Page';
import PageToolbar from '../../../components/PageToolbar';
import PageContent from '../../../components/PageContent';
import Trash from '../../../svg/Trash';
import Button from '../../../components/Button';

export default class ChecklistEdit extends React.Component {
  public render() {
    return (
      <Page>
        <PageToolbar
          title={'Edit: Checklist Name'}
          actions={[
            { label: 'Save as Template' },
            { label: 'Done', type: 'success' },
          ]}
        />

        <PageContent>
          <div className="bg-grey-lighter p-8 flex flex-row justify-between items-end">
            <div className={'flex-1 flex flex-col'}>
              <label htmlFor="newChecklistItem" className={'block mb-2'}>
                Add new Task
              </label>
              <input
                className={'p-3 border-2 border-solid border-border flex-1'}
              />
            </div>
            <div className={'ml-4'}>
              <Button type={'primary'} children={'Add'} />
            </div>
          </div>
          <div className="bg-white p-4">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between border-0 border-b-1 border-solid border-border p-4">
                <h3>Task</h3>
                <h3>Options</h3>
              </div>

              <div className="flex flex-row justify-between items-center p-4 border-0 border-b-1 border-solid border-border">
                <input
                  className={'p-3 border-2 border-solid border-border flex-1'}
                />
                <button
                  className={
                    'flex flex-col bg-transparent border-0 items-center p-2 ml-4 cursor-pointer'
                  }
                >
                  <Trash className={'w-4 h-4'} />
                  <p>Remove</p>
                </button>
              </div>
            </div>
          </div>
        </PageContent>
      </Page>
    );
  }
}

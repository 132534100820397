import tailwindConfig from '../tailwind';
import { WidgetType, SelectType } from '../types';

export const ENTER_KEY_CODE = 13;

export const selectTypes = {
  SINGLE: 'single' as SelectType,
  MULTI: 'multi' as SelectType,
};

export const formWidgetTypes = {
  INSPECTION: 'inspection' as WidgetType,
  TITLE: 'title' as WidgetType,
  QUESTION: 'question' as WidgetType,
  FILE: 'file' as WidgetType,
  CONTENT: 'content' as WidgetType,
  SELECTION: 'selection' as WidgetType,
  USER: 'user' as WidgetType,
};

export const formWidgetTypeDefaultData = {
  [formWidgetTypes.CONTENT]: {
    title: 'Content',
    content: 'Content',
  },
  [formWidgetTypes.FILE]: {
    title: 'File Upload',
    mandatory: false,
  },
  [formWidgetTypes.INSPECTION]: {
    title: 'Inspection',
    positive_label: 'Yes',
    negative_label: 'No',
    neutral_label: 'N/A',
    mandatory: false,
  },
  [formWidgetTypes.QUESTION]: {
    title: 'Question',
    mandatory: false,
  },
  [formWidgetTypes.SELECTION]: {
    select_type: selectTypes.MULTI,
    title: 'Selection',
    mandatory: false,
    options: [] as string[],
  },
  [formWidgetTypes.TITLE]: {
    title: 'Title',
  },
  [formWidgetTypes.USER]: {
    select_type: selectTypes.MULTI,
    title: 'User Select',
    mandatory: false,
    options: [] as string[],
  },
};

export const inspectionAnswers = {
  POSITIVE: 'positive',
  NEGATIVE: 'negative',
  NEUTRAL: 'neutral',
};

export const colorTypes = {
  PRIMARY: 'PRIMARY',
  WHITE: 'WHITE',
  DEFAULT: 'DEFAULT',
  BLACK: 'BLACK',
  ALMOST_BLACK: 'ALMOST_BLACK',
  GREY: 'GREY',
  INCIDENT: 'INCIDENT',
  AUDIT: 'AUDIT',
  GENERAL: 'GENERAL',
  INSPECTION: 'INSPECTION',
  HAZARD: 'HAZARD',
};

export const colors = {
  [colorTypes.PRIMARY]: '#F44B4E',
  [colorTypes.DEFAULT]: '#333333',
  [colorTypes.WHITE]: '#FFFFFF',
  [colorTypes.BLACK]: '#000000',
  [colorTypes.ALMOST_BLACK]: '#333333',
  [colorTypes.GREY]: '#444',
  [colorTypes.AUDIT]: '#E55851',
  [colorTypes.GENERAL]: '#CDC7C2',
  [colorTypes.INCIDENT]: '#4DA387',
  [colorTypes.HAZARD]: '#F2BD42',
};

/**
 * Given the left background color, use the right color for text
 */
export const complimentaryTextColors = {
  [colorTypes.PRIMARY]: colors[colorTypes.WHITE],
  [colorTypes.DEFAULT]: colors[colorTypes.WHITE],
  [colorTypes.BLACK]: colors[colorTypes.WHITE],
  [colorTypes.ALMOST_BLACK]: colors[colorTypes.WHITE],
  [colorTypes.AUDIT]: colors[colorTypes.WHITE],
  [colorTypes.GENERAL]: colors[colorTypes.WHITE],
  [colorTypes.INCIDENT]: colors[colorTypes.WHITE],
  [colorTypes.HAZARD]: colors[colorTypes.WHITE],
};

export const hoverColors = {
  [colorTypes.PRIMARY]: 'rgba(244, 75, 78, 0.83)',
  [colorTypes.DEFAULT]: 'rgba(51, 51, 51, 0.83)',
};

export const semanticColors = {
  LABEL_COLOR: colors[colorTypes.GREY],
};

export const mediaQueries = {
  SM: 576,
  MD: 768,
  LG: 992,
  XL: 1200,
};

export const localStorageKeys = {
  USER_TOKEN: 'USER_TOKEN',
  REFRESH_TOKEN: 'REFRESH_TOKEN',
  ORIGINAL_USER_TOKEN: 'ORIGINAL_USER_TOKEN',
  ORIGINAL_REFRESH_TOKEN: 'ORIGINAL_REFRESH_TOKEN',
  CURRENT_COMPANY_UID: 'CURRENT_COMPANY_UID',
  CURRENT_COMPANY_GROUP_UID: 'CURRENT_COMPANY_GROUP_UID',
  FILE_VIEW: 'FILE_VIEW',
  LOCATION: 'LOCATION',
};

export const globalStyles = {
  FONT_SIZE: 0.75, // em
};

export const globalStylesFormatted = {
  FONT_SIZE: `${globalStyles.FONT_SIZE}em`,
};

export const dragDropTypes = {
  CREW: 'CREW',
  FOLDER: 'FOLDER',
  FILE: 'FILE',
};

export const cssVars = tailwindConfig;

export const formFeatureIds = {
  IMAGE: 1,
  INSTRUCTIONS: 2,
  QUESTION: 3,
  QUESTION_ANSWER: 4,
  SELECTION: 5,
  SIGN_OFF: 6,
  SIGNATURE: 7,
  SKETCH: 8,
  TABLE: 9,
  USER_INPUT: 10,
  USERS: 11,
  SECTION: 12,
  QUESTION_ITEM: 13,
  QUESTION_ANSWER_ITEM: 14,
  SELECTION_ITEM: 15,
  ROW: 16,
  COL: 17,
  AUDIT: 18,
  AUDIT_ITEM: 19,
  ACTIONS: 20,
  INSPECTION: 21,
};

const {
  AUDIT,
  AUDIT_ITEM,
  QUESTION,
  QUESTION_ANSWER,
  QUESTION_ANSWER_ITEM,
  QUESTION_ITEM,
  COL,
  SECTION,
  ROW,
  SELECTION,
  SELECTION_ITEM,
  USERS,
  USER_INPUT,
  TABLE,
  SKETCH,
  SIGNATURE,
  SIGN_OFF,
  IMAGE,
  INSTRUCTIONS,
  ACTIONS,
  INSPECTION,
} = formFeatureIds;

export const widgetsForDroppable = {
  [SECTION]: [ROW],
  [ROW]: [COL],
  [COL]: [
    QUESTION_ANSWER,
    QUESTION,
    AUDIT,
    USERS,
    USER_INPUT,
    SELECTION,
    TABLE,
    SKETCH,
    SIGNATURE,
    SIGN_OFF,
    IMAGE,
    INSTRUCTIONS,
    ACTIONS,
    INSPECTION,
  ],
  [AUDIT]: [AUDIT_ITEM],
  [SELECTION]: [SELECTION_ITEM],
  [QUESTION]: [QUESTION_ITEM],
  [QUESTION_ANSWER]: [QUESTION_ANSWER_ITEM],
};

export const incidentStatuses = {
  COMPLETED: 1,
  OPENED: 2,
};

export const csvPlans = {
  ON: 1,
  OFF: 2,
  TRIAL: 3,
};

export const jobStatuses = {
  SUCCESS: 1,
  FAIL: 2,
  PENDING: 3,
};

export const statusCodes = {
  SUCCESS: 200,
  ERROR: 500,
  BAD_REQUEST: 400,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  FORM_ERROR: 422,
};

export const MAX_V2_WIDGET_UPLOADS = 16;
export const MAX_INPUT_FIELD_SIZE = 8192;

export const MAX_NAME_LENGTH = 128;
export const MAX_INPUT_FIELD_LENGTH = 8192;

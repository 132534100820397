import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../../../constants';
import { Colors } from '../../../types';
import ChevronUp from '../../../svg/ChevronUp';
import ChevronDown from '../../../svg/ChevronDown';
import { biggerThanMD } from '../../../helpers/style';

const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: ${cssVars.margin[4]};

  ${biggerThanMD(`
    margin: 0 10px;
  `)};
`;

const Button = styled.button`
  border: none;
  color: white;
  cursor: pointer;
`;

const Label = styled.label`
  background: ${cssVars.colors['form-option-row']};
  padding: ${cssVars.padding[1]};
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

interface Props {
  type: Colors;
  label: string;
  onUp?: () => void;
  onDown?: () => void;
}

const PointCounter = ({ type, label, onUp, onDown }: Props) => (
  <Container>
    <Button className={`bg-${type}`} onClick={onUp}>
      <ChevronUp className={'w-6 h-6'} />
    </Button>
    <Label>{label}</Label>
    <Button className={`bg-${type}`} onClick={onDown}>
      <ChevronDown className={'w-6 h-6'} />
    </Button>
  </Container>
);

export default PointCounter;

import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: visible;
  height: 100%;
`;

export default class Page extends React.Component {
  public render() {
    return <Container children={this.props.children} />;
  }
}

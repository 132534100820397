import * as meActions from '../actions/me';
import { ActionType, getType } from 'typesafe-actions';
import { User, Company, CompanyGroup, AuthPermissions } from '../types';
import { CompanyUserConversation } from '../api/companyUser.api';

export type MeAction = ActionType<typeof meActions>;

export interface MeState {
  user: User;
  company: Company;
  companies: Company[];
  companyGroup: CompanyGroup;
  companyGroups: CompanyGroup[];
  permissions: AuthPermissions;
  conversations: CompanyUserConversation[];
}

const DEFAULT_STATE = {
  user: {} as User,
  company: {} as Company,
  companies: [],
  companyGroup: {} as CompanyGroup,
  companyGroups: [],
  permissions: {
    companies: [],
    companyGroups: [],
  },
  conversations: [],
};

export default function(state: MeState = DEFAULT_STATE, action: MeAction) {
  switch (action.type) {
    case getType(meActions.setUser):
      return {
        ...state,
        user: action.payload,
      };
    case getType(meActions.setCompany):
      return {
        ...state,
        company: { ...action.payload },
      };
    case getType(meActions.setCompanies):
      return {
        ...state,
        companies: action.payload,
      };
    case getType(meActions.setCompanyGroup):
      return {
        ...state,
        companyGroup: { ...action.payload },
      };
    case getType(meActions.setCompanyGroups):
      return {
        ...state,
        companyGroups: action.payload,
      };
    case getType(meActions.setPermissions):
      return {
        ...state,
        permissions: action.payload,
      };
    case getType(meActions.setConversations):
      return {
        ...state,
        conversations: action.payload,
      };
    case getType(meActions.addConversation):
      return {
        ...state,
        conversations: [...state.conversations, action.payload],
      };
    default:
      return state;
  }
}

import * as React from 'react';
import PageHeading from '../../../components/PageHeading';
import { useMountVisibility } from '../../../helpers/hooks';
import ConfirmationDialogue from '../../../components/ConfirmationDialogue';

interface Props {
  entities: any[];
  accessor: string;
  name: string;
  onRestore: () => void;
  endpoint: (uid: string) => void;
}

const TrashedEntities = ({
  entities,
  accessor,
  name,
  onRestore,
  endpoint,
}: Props) => {
  const confirmState = useMountVisibility();
  const [loading, setLoading] = React.useState(false);
  const [uid, setUid] = React.useState('');

  const onClick = (u: string) => {
    setUid(u);
    confirmState.open();
  };

  const onConfirm = async () => {
    setLoading(true);
    await endpoint(uid);
    setUid('');
    setLoading(false);
    onRestore();
    confirmState.close();
  };

  return (
    <div>
      <PageHeading title={`${name}`} />
      <div className={'p-4 bg-white'}>
        <div className="flex flex-col">
          {entities.map(e => (
            <div
              className="bg-grey-lighter flex flex-row justify-between items-center pl-4 mb-4"
              key={e.uid}
            >
              <label>{e[accessor]}</label>
              <button
                className={
                  'bg-red text-white px-4 py-6 border-0 cursor-pointer'
                }
                onClick={() => onClick(e.uid)}
              >
                Restore
              </button>
            </div>
          ))}
        </div>
      </div>
      <ConfirmationDialogue
        isOpen={confirmState.visible}
        contentLabel={`Restore ${name}`}
        handleClose={confirmState.close}
        title={`Restore ${name}`}
        body={'Upon restoring will become available for use again'}
        onConfirm={onConfirm}
        loading={loading}
      />
    </div>
  );
};

export default TrashedEntities;

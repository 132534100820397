import * as React from 'react';
import BaseWidget, { RenderMenuProps } from './BaseWidget';
import User from '../../svg/User';
import { User as IUser, Company, CompanyGroup } from '../../types';
import Button from '../../components/Button';
import InlineSelect from '../../components/InlineSelect';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { useOptionalSelectInput } from '../../helpers/hooks';
import companyApi from '../../api/company.api';
import companyGroupApi from '../../api/companyGroup.api';

const UnstyledNavLink = styled(NavLink)`
  text-decoration: none;
  color: inherit;
`;

interface Props {
  users: IUser[];
  company?: Company;
  companyGroup?: CompanyGroup;
}

const Checklist = ({ users, company, companyGroup }: Props) => {
  const user = useOptionalSelectInput(null);
  const [checklists, setChecklists] = React.useState<any>([]);

  const fetchData = async () => {
    const filters = user.selected
      ? { userUids: [user.selected] }
      : { userUids: [] };
    try {
      if (company) {
        const res = await companyApi.checklists(company.uid, filters);
        setChecklists(res.checklists);
      }
      if (companyGroup) {
        const res = await companyGroupApi.checklists(companyGroup.uid, filters);
        setChecklists(res.checklists);
      }
    } catch (e) {
      //
    }
  };

  const parentUid = company
    ? company.uid
    : companyGroup
      ? companyGroup.uid
      : null;

  React.useEffect(
    () => {
      fetchData();
    },
    [user.selected ? user.selected : '', parentUid],
  );

  const getChecklistCompletePercent = (checklist: any) => {
    return (
      (+checklist.complete / (+checklist.complete + +checklist.incomplete)) *
      100
    );
  };

  const getChecklistIncompletePercent = (checklist: any) => {
    return (
      (+checklist.incomplete / (+checklist.complete + +checklist.incomplete)) *
      100
    );
  };

  const getUserOptions = () =>
    users.map(u => ({
      label: u.username,
      value: u.uid,
    }));

  const renderMenu = ({ closeMenu }: RenderMenuProps) => {
    return (
      <div className="h-full w-full p-4 flex items-center">
        <div className="flex-1">
          <InlineSelect
            options={getUserOptions()}
            label={'User'}
            icon={<User />}
            isClearable={true}
            {...user}
          />
          <Button
            type={'default'}
            block={true}
            children={'Done'}
            onClick={closeMenu}
          />
        </div>
      </div>
    );
  };

  return (
    <BaseWidget title={'Latest Checklist Progress'} renderMenu={renderMenu}>
      <div className="flex flex-col p-4 overflow-scroll h-full">
        {checklists.map((checklist: any) => (
          <div className={'flex-1 mb-8'}>
            <p className={'font-bold mb-2'}>
              <UnstyledNavLink
                to={`/dashboard/worker/checklists/${checklist.uid}`}
              >
                {checklist.name} ({+checklist.complete}/
                {+checklist.complete + +checklist.incomplete})
              </UnstyledNavLink>
            </p>
            <div style={{ width: `100%`, height: '4px' }}>
              {+checklist.complete === 0 && +checklist.incomplete === 0 ? (
                <div className="bg-grey-darker h-full" />
              ) : (
                <div className={'flex flex-row h-full'}>
                  <div
                    className={'bg-green h-full'}
                    style={{
                      width: `${getChecklistCompletePercent(checklist)}%`,
                    }}
                  />
                  <div
                    className={'bg-red h-full'}
                    style={{
                      width: `${getChecklistIncompletePercent(checklist)}%`,
                    }}
                  />
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </BaseWidget>
  );
};

export default Checklist;

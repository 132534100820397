import { createAction } from 'typesafe-actions';
import { PermissionsError, Form } from '../../types';
import {
  COMPANY_GROUP_FORMS_FETCH_REQUEST,
  COMPANY_GROUP_FORMS_FETCH_SUCCESS,
  COMPANY_GROUP_FORMS_FETCH_FAILURE,
  COMPANY_GROUP_FORMS_ADD,
  COMPANY_GROUP_FORMS_REMOVE,
} from '../../constants/actionTypes';

export const companyGroupFormsFetchRequest = createAction(
  COMPANY_GROUP_FORMS_FETCH_REQUEST,
  resolve => (companyGroupUid: string) => resolve(companyGroupUid),
);

export const companyGroupFormsFetchSuccess = createAction(
  COMPANY_GROUP_FORMS_FETCH_SUCCESS,
  resolve => (forms: Form[]) => resolve(forms),
);

export const companyGroupFormsFetchFailure = createAction(
  COMPANY_GROUP_FORMS_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const companyGroupAddForm = createAction(
  COMPANY_GROUP_FORMS_ADD,
  resolve => (form: Form) => resolve(form),
);

export const companyGroupRemoveForm = createAction(
  COMPANY_GROUP_FORMS_REMOVE,
  resolve => (formUid: string) => resolve(formUid),
);

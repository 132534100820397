import * as React from 'react';
import BaseWidget from './BaseWidget';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import InlineInput from '../../components/InlineInput';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption } from '../../types';
import { toast } from 'react-toastify';
import { cssVars } from '../../constants';

interface Props extends WithNamespaces {
  optionId: number;
  formUid: string;
  openDeleteOption?: (optionId: number) => any;
  index: number;
  option?: FormOption;
}

interface State {
  y: string;
  n: string;
  na: string;
  disabled: boolean;
}

class Inspection extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      y: 'Yes',
      n: 'No',
      na: 'N/A',
      disabled: false,
    };
  }

  componentDidMount() {
    const { option } = this.props;
    if (option && option.text_rev) {
      try {
        const parsedJson = JSON.parse(option.text_rev);
        this.setState(parsedJson);
      } catch (e) {}
    }
  }

  private handleChange = (
    e: React.FormEvent<HTMLInputElement>,
    onTextChange: (value: string) => void,
  ) => {
    const target = e.currentTarget;
    const { y, n, na } = this.state;
    let newState = {
      y,
      n,
      na,
      [target.name]: target.value,
      disabled: false,
    };
    if (
      newState.y.length === 0 ||
      newState.n.length === 0 ||
      newState.na.length === 0
    ) {
      newState = {
        y,
        n,
        na,
        [target.name]: target.value,
        disabled: true,
      };
    }
    this.setState(newState);
    onTextChange(JSON.stringify(newState));
  };

  public render() {
    const { index, optionId, t, openDeleteOption } = this.props;
    const { y, n, na, disabled } = this.state;
    return (
      <BaseWidget
        optionId={optionId}
        index={index}
        defaultTitle={t('common:formWidgets.inspection')}
        openDeleteOption={openDeleteOption}
        disabled={disabled}
        renderChildren={({ editing, onTextChange, optionText }) => (
          <div>
            {editing ? (
              <div>
                {y.length === 0 && (
                  <p className="text-red">Cannot leave answer labels empty</p>
                )}
                <InlineInput
                  label={'Positive Option Label'}
                  type={'text'}
                  value={y}
                  onChange={e => this.handleChange(e, onTextChange)}
                  name={'y'}
                />
                {n.length === 0 && (
                  <p className="text-red">Cannot leave answer labels empty</p>
                )}
                <InlineInput
                  label={'Negative Option Label'}
                  type={'text'}
                  value={n}
                  onChange={e => this.handleChange(e, onTextChange)}
                  name={'n'}
                />
                {na.length === 0 && (
                  <p className="text-red">Cannot leave answer labels empty</p>
                )}
                <InlineInput
                  label={'Neutral Option Label'}
                  type={'text'}
                  value={na}
                  onChange={e => this.handleChange(e, onTextChange)}
                  name={'na'}
                />
              </div>
            ) : (
              <div />
            )}
          </div>
        )}
      />
    );
  }
}

function makeMapStateToProps() {
  const getOption = makeGetOption();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(Inspection));

import * as React from 'react';
import SideboxSectionHeader from '../../../../components/sidebox/SideboxSectionHeader';
import UserGroup from '../../../../svg/UserGroup';
import SideboxSectionContent from '../../../../components/sidebox/SideboxSectionContent';
import InlineSelect from '../../../../components/InlineSelect';
import {
  SelectOptionObject,
  SelectOption,
} from '../../../../components/Select';
import { Crew, User } from '../../../../types';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { getSiteLabel, getCrewLabel } from '../../../../helpers';

interface Props extends WithNamespaces {
  crews: Crew[];
  users: User[];
  type: SelectOption;
  audience: SelectOption;
  custom: SelectOptionObject[];
  onTypeChange: (type: SelectOptionObject) => void;
  onAudienceChange: (type: SelectOptionObject) => void;
  onCustomChange: (type: SelectOptionObject[]) => void;
}

export const deploymentTypeOptions = (t: TranslationFunction) => [
  { label: `${getSiteLabel()}`, value: 'site' },
  { label: `${getCrewLabel()}`, value: 'crew' },
  { label: t('common:user'), value: 'user' },
];

export const deploymentAudienceOptions = (t: TranslationFunction) => [
  { label: t('common:all'), value: 'all' },
  { label: t('common:custom'), value: 'custom' },
];

class Deployment extends React.Component<Props> {
  public render() {
    const {
      users,
      crews,
      custom,
      audience,
      type,
      onTypeChange,
      onAudienceChange,
      onCustomChange,
      t,
      company,
    } = this.props;

    const userOptions = users.map(user => ({
      label: user.username,
      value: user.uid,
    }));
    const crewOptions = crews.map(crew => ({
      label: crew.name,
      value: crew.uid,
    }));

    const customOptions = type
      ? type.value === 'crew'
        ? crewOptions
        : userOptions
      : [];

    const typeOptions = deploymentTypeOptions(t);

    const audienceOptions = deploymentAudienceOptions(t);

    return (
      <div>
        <SideboxSectionHeader
          title={t('super:screens.site.deploymentSectionTitle')}
          leftIcon={<UserGroup />}
        />

        <SideboxSectionContent>
          <InlineSelect
            label={t('common:type')}
            value={type}
            options={typeOptions}
            onChange={onTypeChange}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />
          {type &&
            type.value !== 'site' && (
              <InlineSelect
                label={t('super:screens.site.deploymentAudienceInputLabel')}
                value={audience}
                options={audienceOptions}
                onChange={onAudienceChange}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
            )}
          {audience &&
            audience.value === 'custom' &&
            type &&
            type.value !== 'site' && (
              <InlineSelect
                label={t('super:screens.site.deploymentCustomInputLabel')}
                options={customOptions}
                value={custom}
                isMulti={true}
                onChange={onCustomChange}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
              />
            )}
        </SideboxSectionContent>
      </div>
    );
  }
}

export default withNamespaces()(Deployment);

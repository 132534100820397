import * as formOptionActions from '../actions/formOptions';
import { ActionType, getType } from 'typesafe-actions';
import { FormOption } from '../types';
import * as R from 'ramda';
import { orderBy } from 'natural-orderby';

export type FormOptionAction = ActionType<typeof formOptionActions>;

export interface FormOptionsState {
  byId: {
    [key: number]: FormOption;
  };
  allIds: number[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  formUid: string;
  draggingFid: null | number;
}

const DEFAULT_STATE: FormOptionsState = {
  byId: {},
  allIds: [],
  loading: false,
  loaded: false,
  errors: {},
  formUid: '',
  draggingFid: null,
};

export const reorder = ({
  state,
  source,
  destination,
  sourceIndex,
  destinationIndex,
}: any) => {
  const result = orderBy(
    Object.keys(state.byId)
      .map(x => ({ ...state.byId[x] }))
      .filter(x => x.parent_id === destination || x.parent_id === +destination),
    [x => x.sort],
    ['asc'],
  );
  const [removed] = result.splice(sourceIndex, 1);
  result.splice(destinationIndex, 0, removed);
  return result.map((x, i) => ({ ...x, sort: i }));
};

export const move = ({
  state,
  source,
  destination,
  sourceIndex,
  destinationIndex,
}: any) => {
  const flat = Object.keys(state.byId).map(x => ({ ...state.byId[x] }));
  const sourceChildren = orderBy(
    flat.filter(x => x.parent_id === state.byId[+source].parent_id),
    [x => x.sort],
    ['asc'],
  );
  const destChildren = orderBy(
    flat.filter(x => x.parent_id === +destination),
    [x => x.sort],
    ['asc'],
  );
  const [removed] = sourceChildren.splice(sourceIndex, 1);
  removed.parent_id = +destination;
  destChildren.splice(destinationIndex, 0, removed);
  return destChildren.map((x, i) => ({ ...x, sort: i }));
};

export const groupAndSortOptions = (
  options: any,
  parentId: any = null,
): any => {
  return groupAndSortOptionsWithObjects(options, parentId).map((x: any) => x.id);
};

export const groupAndSortOptionsWithObjects = (
  options: any,
  parentId: any = null,
): any => {
  return [
    ...orderBy(
      options.filter(
        (o: any) => o.parent_id === parentId || +o.parent_id === +parentId!,
      ),
      [(v: any) => v.sort],
      ['asc'],
    ).reduce(
      (curr, o: any) => [
        ...curr,
        o,
        ...groupAndSortOptionsWithObjects(options, o.id),
      ],
      [],
    ),
  ];
};

export default function formOptionsReducer(
  state: FormOptionsState = DEFAULT_STATE,
  action: FormOptionAction,
) {
  switch (action.type) {
    case getType(formOptionActions.setFormOptions):
      return {
        ...state,
        byId: R.reduce(
          (curr, o) => ((curr[o.id] = o), curr),
          {},
          action.payload,
        ),
        allIds: groupAndSortOptions(action.payload),
      };
    case getType(formOptionActions.addFormOption):
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: { ...action.payload },
        },
        allIds: [...state.allIds, action.payload.id],
      };
    case getType(formOptionActions.removeFormOption):
      delete state.byId[action.payload];
      return {
        ...state,
        allIds: state.allIds.filter(o => o !== action.payload),
      };
    case getType(formOptionActions.updateFormOption):
      return {
        ...state,
        byId: {
          ...state.byId,
          [action.payload.id]: { ...action.payload },
        },
      };
    case getType(formOptionActions.replaceFormOptions):
      return {
        ...state,
        ...action.payload,
      };
    case getType(formOptionActions.setDragFid):
      return {
        ...state,
        draggingFid: action.payload,
      };
    default:
      return state;
  }
}

import * as React from 'react';
import PageToolbar from '../../components/PageToolbar';
import { match, Route } from 'react-router';
import { Colors, Form as IForm, FormOption, Company, User } from '../../types';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import formApi from '../../api/form.api';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import FormPreview from '../../containers/Form';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { setFormOptions } from '../../actions/formOptions';
import { Dispatch } from 'redux';
import { makeGetSections } from '../../selectors/formOptions';
import { makeGetFormFromUrl } from '../../selectors/form';
import FormEdit from './form/FormEdit';
import FormImport from './form/FormImport';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { History } from 'history';
import Pencil from '../../svg/Pencil';
import File from '../../svg/File';
import Cog from '../../svg/Cog';
import Save from '../../svg/Save';
import Upload from '../../svg/Upload';
import UserGroup from '../../svg/UserGroup';
import ApplyAll from './form/ApplyAll';
import Download from '../../svg/Download';
import { openDownloads } from '../../actions/ui';
import jobApi from '../../api/job.api';
import { jobStatuses } from '../../constants/index';
import Trash from '../../svg/Trash';
import FormDelete from './form/FormDelete';
import { formsInvalidate } from '../../actions/forms';
import { updateForm } from '../../actions/forms';
import FormBuilder from '../../containers/FormBuilder';
import Power from '../../svg/Power';
import ConvertV2 from './form/ConvertV2';

interface Props extends WithNamespaces {
  match: match<{ formUid: string }>;
  form: IForm;
  setFormOptions: (options: FormOption[]) => any;
  options: FormOption[];
  history: History;
  company?: Company;
  showDownloads: () => any;
  user?: User;
  invalidateForms: () => void;
  updateForm: (form: IForm) => any;
}

interface State {
  form: IForm | null;
  editFormOpen: boolean;
  importFormOpen: boolean;
  loadingFormOptions: boolean;
  applyAllOpen: boolean;
  loadingImport: boolean;
  deleteFormOpen: boolean;
  convertFormOpen: boolean;
}

class Form extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      form: null,
      editFormOpen: false,
      importFormOpen: false,
      loadingFormOptions: false,
      applyAllOpen: false,
      loadingImport: false,
      deleteFormOpen: false,
      convertFormOpen: false,
    };
  }

  public componentDidMount() {
    if (this.props.form) {
      this.setState({
        form: this.props.form,
      });
    } else {
      this.fetchForm();
    }

    this.fetchFormOptions();
  }

  private fetchForm = async () => {
    const { data } = await formApi.show(this.props.match.params.formUid);
    if (data) {
      this.setState({
        form: data.form,
      });
    }
  };

  private fetchFormOptions = async () => {
    this.setState({
      loadingFormOptions: true,
    });

    try {
      const { options } = await formApi.options(
        this.props.match.params.formUid,
      );
      this.props.setFormOptions(options);
    } catch (e) {
      toast.error('Failed to fetch form options');
    }

    this.setState({
      loadingFormOptions: false,
    });
  };

  private openEditForm = () => {
    this.setState({
      editFormOpen: true,
    });
  };

  private closeEditForm = () => {
    this.setState({
      editFormOpen: false,
    });
  };

  private openImportForm = () => {
    this.setState({
      importFormOpen: true,
    });
  };

  private closeImportForm = () => {
    this.setState({
      importFormOpen: false,
    });
  };

  private openApplyAll = () => {
    this.setState({
      applyAllOpen: true,
    });
  };

  private closeApplyAll = () => {
    this.setState({
      applyAllOpen: false,
    });
  };

  private openConvertForm = () => {
    this.setState({
      convertFormOpen: true,
    });
  };

  private closeConvertForm = () => {
    this.setState({
      convertFormOpen: false,
    });
  };

  private afterApplyAll = () => {
    this.closeApplyAll();
  };

  private goToPreview = () => {
    this.props.history.push(
      `/dashboard/super/forms/${this.props.match.params.formUid}/preview`,
    );
  };

  private goToBuilder = () => {
    this.props.history.push(`/dashboard/super/forms/${this.state.form!.uid}`);
  };

  public componentWillUnmount = () => {
    this.props.setFormOptions([]);
  };

  private publish = async () => {
    const { data } = await formApi.publish(this.props.match.params.formUid);
    if (data) {
      toast.success('Form published');
    }
  };

  private openDeleteModal = async () => {
    this.setState({
      deleteFormOpen: true,
    });
  };

  private closeDeleteModal = async () => {
    this.setState({
      deleteFormOpen: false,
    });
  };

  private onUpdate = (form: IForm) => {
    this.setState({
      editFormOpen: false,
    });
    this.props.updateForm(form);
  };

  private exportForm = async () => {
    if (!this.state.form) {
      return;
    }
    await formApi.export(this.state.form.uid);
    this.props.showDownloads();
  };

  private onImportQueued = (jobId: string) => {
    this.setState({
      loadingImport: true,
    });
    const interval = setInterval(async () => {
      const { data, errors } = await jobApi.show(jobId);
      if (data) {
        const { status } = data.job;
        if (status === jobStatuses.SUCCESS) {
          this.fetchFormOptions();
        } else if (status === jobStatuses.FAIL) {
          toast.error('Failed to import form');
        }
        if (status !== jobStatuses.PENDING) {
          clearInterval(interval);
          this.setState({
            loadingImport: false,
          });
        }
      }
      if (errors) {
        clearInterval(interval);
        this.setState({
          loadingImport: false,
        });
      }
    }, 2000);
  };

  private onDelete = () => {
    this.closeDeleteModal();
    this.props.invalidateForms();
    this.props.history.push('/dashboard/super/forms');
  };

  private onConvert = (newFormUid: string) => {
    this.closeConvertForm();
    this.props.invalidateForms();
    this.props.history.push(`/dashboard/super/form-builder/${newFormUid}`);
  };

  public render() {
    const {
      form,
      editFormOpen,
      importFormOpen,
      loadingFormOptions,
      loadingImport,
    } = this.state;
    const { options, t, history, company } = this.props;

    if (!form || loadingFormOptions || loadingImport) {
      return <Loading loading={true} />;
    }

    const toolbarActions = [
      {
        label: 'Delete',
        type: 'primary' as Colors,
        onClick: this.openDeleteModal,
        icon: <Trash />,
      },
      ...(company && company.forms2 && form.v2 !== true
        ? [
            {
              label: 'Convert To V2 (Beta)',
              onClick: this.openConvertForm,
              icon: <Power />,
            },
          ]
        : []),
      {
        label: 'Apply All',
        onClick: this.openApplyAll,
        icon: <UserGroup />,
      },
      {
        label: t('super:screens.form.import'),
        onClick: this.openImportForm,
        icon: <Upload />,
      },
      {
        label: t('super:screens.form.export'),
        onClick: this.exportForm,
        icon: <Download />,
      },
      {
        label: t('super:screens.form.edit'),
        onClick: this.openEditForm,
        icon: <Pencil />,
      },
      history.location.pathname.indexOf('preview') === -1
        ? {
            label: t('super:screens.form.preview'),
            onClick: this.goToPreview,
            icon: <File />,
          }
        : {
            label: t('super:screens.form.builder'),
            onClick: this.goToBuilder,
            icon: <Cog />,
          },
      {
        label: t('super:screens.form.publish'),
        type: 'success' as Colors,
        onClick: this.publish,
        icon: <Save />,
      },
    ];

    return (
      <Page>
        <PageToolbar title={form.name} actions={toolbarActions} />
        <PageContent>
          <Route
            path={'/dashboard/super/forms/:formUid'}
            exact={true}
            render={() => (
              <FormBuilder
                sections={options}
                formUid={form.uid}
                formType={form.type}
              />
            )}
          />

          <Route
            path={'/dashboard/super/forms/:formUid/preview'}
            render={() => <FormPreview sections={options} live={false} />}
          />
        </PageContent>

        <ConvertV2
          isOpen={this.state.convertFormOpen}
          handleClose={this.closeConvertForm}
          formUid={this.props.match.params.formUid}
          onConvert={this.onConvert}
        />

        <ApplyAll
          isOpen={this.state.applyAllOpen}
          handleClose={this.closeApplyAll}
          onSuccess={this.afterApplyAll}
          formUid={form.uid}
          companyUid={this.props.company!.uid}
        />
        <FormEdit
          isOpen={editFormOpen}
          handleClose={this.closeEditForm}
          contentLabel={t('super:screens.form.editForm')}
          title={t('super:screens.form.editForm')}
          form={form}
          onUpdate={this.onUpdate}
        />
        <FormImport
          isOpen={importFormOpen}
          handleClose={this.closeImportForm}
          contentLabel={t('super:screens.form.importForm')}
          title={t('super:screens.form.importForm')}
          formUid={form.uid}
          onImportQueued={this.onImportQueued}
        />
        <FormDelete
          isOpen={this.state.deleteFormOpen}
          handleClose={this.closeDeleteModal}
          formUid={this.props.match.params.formUid}
          onDelete={this.onDelete}
        />
      </Page>
    );
  }
}

function mapStateToProps() {
  const getSections = makeGetSections();
  const getForm = makeGetFormFromUrl();
  const m = (state: StoreState, ownProps: Props) => ({
    form: getForm(state, ownProps),
    options: getSections(state),
    company: state.me.company,
    user: state.me.user,
  });
  return m;
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setFormOptions: (options: FormOption[]) =>
      dispatch(setFormOptions(options)),
    showDownloads: () => dispatch(openDownloads()),
    invalidateForms: () => dispatch(formsInvalidate()),
    updateForm: (form: IForm) => dispatch(updateForm(form)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Form),
);

import { Task } from '../../../types';
import * as React from 'react';
import SaveRemoveInput from '../../super/roles/SaveRemoveInput';
import taskApi from '../../../api/task.api';

interface Props {
  task: Task;
  refresh: () => void;
}

const TaskEdit = ({ task, refresh }: Props) => {
  const onSave = async (val: string) => {
    taskApi.update(task.uid, { name: val });
  };
  const onRemove = async () => {
    await taskApi.delete(task.uid);
    refresh();
  };

  return (
    <SaveRemoveInput
      key={task.uid}
      value={task.name}
      onSave={onSave}
      onRemove={onRemove}
    />
  );
};

export default TaskEdit;

import * as uiActions from '../actions/ui';
import { ActionType, getType } from 'typesafe-actions';

export type UiAction = ActionType<typeof uiActions>;

export interface UiState {
  downloadsOpen: boolean;
}

const DEFAULT_STATE: UiState = {
  downloadsOpen: false,
};

export default function uiReducer(
  state: UiState = DEFAULT_STATE,
  action: UiAction,
) {
  switch (action.type) {
    case getType(uiActions.openDownloads):
      return {
        ...state,
        downloadsOpen: true,
      };
    case getType(uiActions.closeDownloads):
      return {
        ...state,
        downloadsOpen: false,
      };
    default:
      return state;
  }
}

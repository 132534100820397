import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../constants';
import ErrorMessage from './ErrorMessage';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${cssVars.margin[4]};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  margin-bottom: ${cssVars.margin[3]};
  display: block;
`;

const Input = styled.input`
  width: 100%;
  border: 2px solid ${cssVars.colors.border};
  padding: ${cssVars.padding[2]};
`;

interface Props {
  id?: string;
  label?: string;
  placeholder?: string;
  value?: string;
  type?: string;
  disabled?: boolean;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  errorMessage?: string;
}

const StackedInput = ({
  id,
  label,
  placeholder,
  value,
  type = 'text',
  disabled,
  onChange,
  errorMessage,
}: Props) => (
  <Wrapper>
    {label && <Label htmlFor={id}>{label}</Label>}
    <Input
      id={id}
      placeholder={placeholder}
      value={value}
      type={type}
      disabled={disabled}
      onChange={onChange}
    />
    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
  </Wrapper>
);

export default StackedInput;

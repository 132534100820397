import * as React from 'react';
import InlineToggle from '../../components/InlineToggle';
import Trash from '../../svg/Trash';
import { cssVars } from '../../constants';

interface Props {
  handleMandatoryChange?: () => void;
  checked?: boolean;
  openDelete(id: string): void;
  id: string;
}

function BuilderButtons({
  handleMandatoryChange,
  checked,
  openDelete,
  id,
}: Props) {
  return (
    <div className="flex flex-row justify-between items-center">
      <div>
        <Trash
          className={'w-8 h-8 mr-4 cursor-pointer'}
          color={cssVars.colors.primary}
          onClick={() => openDelete(id)}
        />
      </div>
      {handleMandatoryChange && (
        <InlineToggle
          label={'Mandatory?'}
          className="flex border-none items-center"
          onChange={handleMandatoryChange}
          checked={checked}
        />
      )}
    </div>
  );
}

export default BuilderButtons;

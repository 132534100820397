import * as React from 'react';
import { companyGroupUsersFetchRequest } from '../../../actions/companyGroup/users';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { StoreState } from '../../../store';
import UserIcon from '../../../svg/User';
import { CompanyGroup, User } from '../../../types';
import PageHeading from '../../../components/PageHeading';
import InlineSelect from '../../../components/InlineSelect';
import {
  useSimpleFetch,
  useMultiSelectInput,
  useEntityRefresh,
} from '../../../helpers/hooks';
import companyGroupApi from '../../../api/companyGroup.api';
import { toast } from 'react-toastify';
import Button from '../../../components/Button';
import { CompanyGroupUsersState } from '../../../reducers/companyGroup/users';

interface Props {
  users: CompanyGroupUsersState;
  companyGroup: CompanyGroup;
  getUsers: (group: string) => any;
}

const Admins = ({ users, companyGroup, getUsers }: Props) => {
  useEntityRefresh(companyGroup.uid, users, getUsers);
  const selected = useMultiSelectInput();
  const admins = useSimpleFetch<User[]>({
    initial: [],
    accessor: 'users',
    fetch: () => companyGroupApi.admins(companyGroup.uid),
    change: [companyGroup.uid],
  });

  React.useEffect(
    () => {
      selected.onChange(
        admins.value.map(x => ({ label: x.username, value: x.uid })),
      );
    },
    [admins.value],
  );

  const userOptions = users.data
    .filter(u => selected.value.map(x => x.value).indexOf(u.uid) === -1)
    .map(user => ({
      label: user.username,
      value: user.uid,
    }));

  const onSave = async () => {
    const { data } = await companyGroupApi.setAdmins(
      companyGroup.uid,
      selected.value.map(x => x.value),
    );
    if (data) {
      toast.success('Updated admins');
    }
  };

  return (
    <div>
      <PageHeading title="Company Admins" />
      <div className="bg-white md:w-1/2 p-4">
        <InlineSelect
          options={userOptions}
          label={'Admins'}
          icon={<UserIcon />}
          menuPosition={'fixed'}
          menuPortalTarget={document.body}
          isMulti={true}
          {...selected}
        />
        <div className="flex flex-row justify-end">
          <Button type="primary" onClick={onSave}>
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    companyGroup: state.me.companyGroup,
    users: state.companyGroup.users,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUsers: (companyGroupUid: string) =>
      dispatch(companyGroupUsersFetchRequest(companyGroupUid)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Admins);

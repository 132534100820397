import * as React from 'react';
import Toggle from 'react-toggle';
import SideboxSectionHeader from '../../../../components/sidebox/SideboxSectionHeader';
import SideboxSectionContent from '../../../../components/sidebox/SideboxSectionContent';
import InlineSelect from '../../../../components/InlineSelect';
import Notification from '../../../../svg/Notification';
import { SelectOptionObject } from '../../../../components/Select';
import InlineTimePicker from '../../../../components/InlineTimePicker';
import moment from 'moment';
import { TranslationFunction } from 'i18next';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { StoreState } from '../../../../store';
import { Company } from '../../../../types';

interface Props extends WithNamespaces {
  type: SelectOptionObject;
  period: SelectOptionObject[];
  reminder: boolean;
  weekend: boolean;
  time: moment.Moment;
  onTypeChange: (type: SelectOptionObject) => void;
  onPeriodChange: (period: SelectOptionObject[]) => void;
  onReminderChange: (reminders: boolean) => void;
  onWeekendChange: (weekends: boolean) => void;
  onTimeChange: (time: moment.Moment) => void;
  company: Company;
}

export const reminderTypeOptions = (t: TranslationFunction) => [
  { label: t('common:daily'), value: 'daily' },
  { label: t('common:weekly'), value: 'weekly' },
  { label: t('common:monthly'), value: 'custom' },
];

export const reminderPeriodOptions = (t: TranslationFunction) => ({
  daily: [],
  weekly: [
    { label: t('common:monday'), value: 'monday' },
    { label: t('common:tuesday'), value: 'tuesday' },
    { label: t('common:wednesday'), value: 'wednesday' },
    { label: t('common:thursday'), value: 'thursday' },
    { label: t('common:friday'), value: 'friday' },
    { label: t('common:saturday'), value: 'saturday' },
    { label: t('common:sunday'), value: 'sunday' },
  ],
  custom: Array.from({ length: 31 }).map((i, idx) => ({
    label: `${idx + 1}`,
    value: `${idx + 1}`,
  })),
});

class Reminders extends React.Component<Props> {
  private onReminderTypeChange = (reminderType: SelectOptionObject) => {
    this.props.onTypeChange(reminderType);
    this.props.onPeriodChange([
      reminderPeriodOptions(this.props.t)[reminderType.value][0],
    ]);
  };

  private onReminderPeriodChange = (reminderPeriod: SelectOptionObject[]) => {
    this.props.onPeriodChange(reminderPeriod);
  };

  private onRemindersToggle = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.onReminderChange(event.currentTarget.checked);
  };

  private onWeekendToggle = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.onWeekendChange(event.currentTarget.checked);
  };

  public render() {
    const {
      reminder,
      period,
      type,
      time,
      onTimeChange,
      weekend,
      t,
      company,
    } = this.props;

    const periodOptions = reminderPeriodOptions(t)[type ? type.value : 'daily'];
    const enabled = !!company.reminders;

    return (
      <div className="relative">
        {!enabled && (
          <div
            className="absolute w-full h-full flex flex-col items-center justify-center text-white p-4 text-center"
            style={{ zIndex: 9999, background: 'rgba(0, 0, 0, 0.7)' }}
          >
            <p>
              Send out customizable reminders to your workers to fill out this
              form.
            </p>
            <p>
              Contact{' '}
              <a href="mailto:sales@safetytek.ca" className="text-red">
                sales@safetytek.ca
              </a>{' '}
              to enable this feature.
            </p>
          </div>
        )}
        <SideboxSectionHeader
          title={t('super:screens.site.reminders')}
          leftIcon={<Notification />}
          rightIcon={
            <Toggle
              icons={false}
              checked={reminder}
              onChange={this.onRemindersToggle}
            />
          }
        />

        <SideboxSectionContent>
          <InlineSelect
            label={t('common:type')}
            options={reminderTypeOptions(t)}
            value={type}
            disabled={!reminder}
            onChange={this.onReminderTypeChange}
          />

          {type.value !== 'daily' && (
            <InlineSelect
              label={t('common:when')}
              options={periodOptions}
              value={period}
              onChange={this.onReminderPeriodChange}
              disabled={!reminder}
              isMulti={true}
            />
          )}

          <InlineTimePicker
            label={t('common:time')}
            value={time}
            onChange={onTimeChange}
            disabled={!reminder}
          />

          {type.value === 'daily' && (
            <div className={'flex flex-row justify-between items-center'}>
              <label htmlFor={'weekend'}>
                {t('super:screens.site.remindersWeekendsLabel')}
              </label>
              <Toggle
                id={'weekend'}
                disabled={!reminder}
                icons={false}
                checked={weekend}
                onChange={this.onWeekendToggle}
              />
            </div>
          )}
        </SideboxSectionContent>
      </div>
    );
  }
}

const mapState = (state: StoreState) => ({
  company: state.me.company,
});

export default withNamespaces()(connect(mapState)(Reminders));

import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import Tabs from '../../../components/Tabs';
import ShareUsers from './ShareUsers';
import ShareCrews from './ShareCrews';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  checklistUid: string;
}

const ChecklistShare = ({ isOpen, handleClose, checklistUid }: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      contentLabel={'Share this Checklist'}
      handleClose={handleClose}
    >
      <ModalHeader handleClose={handleClose}>Share Checklist</ModalHeader>

      <ModalBody>
        <Tabs
          tabs={[
            {
              label: 'Users',
              component: <ShareUsers checklistUid={checklistUid} />,
            },
            {
              label: 'Crews',
              component: <ShareCrews checklistUid={checklistUid} />,
            },
          ]}
        />
      </ModalBody>

      <ModalFooter right={true}>
        <Button type={'primary'} children={'Done'} onClick={handleClose} />
      </ModalFooter>
    </Modal>
  );
};

export default ChecklistShare;

import { createAction } from 'typesafe-actions';
import { Crew } from '../../types';
import {
  SUPER_SITE_CREWS_SET,
  SUPER_SITE_CREWS_ADD,
  SUPER_SITE_CREWS_REMOVE,
} from '../../constants/actionTypes';

export const setSiteCrews = createAction(
  SUPER_SITE_CREWS_SET,
  resolve => (crews: Crew[]) => resolve(crews),
);

export const addSiteCrew = createAction(
  SUPER_SITE_CREWS_ADD,
  resolve => (crew: Crew) => resolve(crew),
);

export const removeSiteCrew = createAction(
  SUPER_SITE_CREWS_REMOVE,
  resolve => (crewUid: string) => resolve(crewUid),
);

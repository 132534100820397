import { createAction } from 'typesafe-actions';
import { User } from '../../types';
import {
  SUPER_SITE_MANAGERS_SET,
  SUPER_SITE_MANAGERS_ADD,
  SUPER_SITE_MANAGERS_REMOVE,
} from '../../constants/actionTypes';

export const setSiteManagers = createAction(
  SUPER_SITE_MANAGERS_SET,
  resolve => (users: User[]) => resolve(users),
);

export const addSiteManager = createAction(
  SUPER_SITE_MANAGERS_ADD,
  resolve => (user: User) => resolve(user),
);

export const removeSiteManager = createAction(
  SUPER_SITE_MANAGERS_REMOVE,
  resolve => (userUid: string) => resolve(userUid),
);

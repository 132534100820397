import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import PageHeading from '../../components/PageHeading';
import Button from '../../components/Button';
import { useSimpleFetch, useMountVisibility } from '../../helpers/hooks';
import companyGroupApi from '../../api/companyGroup.api';
import ResourceTable from '../../components/ResourceTable';
import CompanyGroupCreate from './CompanyGroupCreate';
import { connect } from 'react-redux';
import { CompanyGroup } from '../../types';
import { setCompanyGroups } from '../../actions/me';
import { StoreState } from '../../store';

interface Props {
  groups: CompanyGroup[];
  setGroups: (groups: CompanyGroup[]) => any;
}

const Companies = ({ groups, setGroups }: Props) => {
  const companies = useSimpleFetch({
    initial: [],
    accessor: 'companyGroups',
    fetch: () => companyGroupApi.all(),
    change: [],
  });
  const createState = useMountVisibility();
  const onCreate = (group: CompanyGroup) => {
    createState.close();
    companies.performFetch();
    setGroups([...groups, group]);
  };
  return (
    <Page>
      <PageContent>
        <PageHeading
          title="Companies"
          renderRight={() => (
            <Button
              type="primary"
              children="Create"
              onClick={createState.open}
            />
          )}
        />
        <ResourceTable
          defaultSortKey={'name'}
          columns={[{ Header: 'name', accessor: 'name' }]}
          data={companies.value}
        />
        <CompanyGroupCreate
          isOpen={createState.visible}
          handleClose={createState.close}
          onCreate={onCreate}
        />
      </PageContent>
    </Page>
  );
};

export default connect(
  (state: StoreState) => ({
    groups: state.me.companyGroups,
  }),
  dispatch => ({
    setGroups: (groups: CompanyGroup[]) => dispatch(setCompanyGroups(groups)),
  }),
)(Companies);

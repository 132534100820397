import * as React from 'react';
import { FormOption } from '../../types';
import {
  Body,
  Container,
  DragHandler,
  DragWrapper,
  Header,
  HeaderAction,
  HeaderActions,
  HeaderWrapper,
  TitleInput,
} from './optionParentStyles';
import { Dispatch } from 'redux';
import { updateFormOption } from '../../actions/formOptions';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { makeGetChildrenIds, makeGetOption } from '../../selectors/formOptions';
import Cog from '../../svg/Cog';
import Trash from '../../svg/Trash';
import BaseWidgetChild from './BaseWidgetChild';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getOptionText, getOptionTitle } from '../../helpers/formOption';
import formOptionApi from '../../api/formOption.api';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import { contains } from 'ramda';
import { widgetsForDroppable } from '../../constants/index';

export interface RenderChildrenProps {
  text: string;
  onTextChange: (
    e: string | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  BaseWidgetChild: typeof BaseWidgetChild;
  childOptions?: number[];
  editing: boolean;
  optionText: string;
}

interface Props extends WithNamespaces {
  optionId: number;
  updateFormOption?: (option: FormOption) => any;
  option?: FormOption;
  childOptions?: number[];
  extraActions?: any[];
  defaultTitle?: string;
  renderChildren?: (state: RenderChildrenProps) => any;
  renderChild?: (optionId: number, i: number) => any;
  openDeleteOption?: (optionId: number) => any;
  index: number;
  fid?: number;
  disabled?: boolean;
}

interface State {
  editing: boolean;
  title: string;
  text: string;
}

class BaseWidget extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
      title: '',
      text: '',
    };
  }

  private startEditing = () => {
    this.setState({
      editing: true,
      title: getOptionTitle(this.props.option),
      text: getOptionText(this.props.option),
    });
  };

  private stopEditing = async () => {
    this.setState({
      editing: false,
    });

    if (!this.props.updateFormOption || !this.props.option) {
      return;
    }

    this.props.updateFormOption({
      ...this.props.option,
      title_rev: this.state.title,
      text_rev: this.state.text,
    });

    try {
      await formOptionApi.patch(this.props.optionId, {
        title: this.state.title,
        text: this.state.text,
      });
    } catch (e) {
      //
    }
  };

  private cancelEditing = () => {
    this.setState({
      editing: false,
    });
  };

  private onTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      title: e.currentTarget.value,
    });
  };

  private onTextChange = (
    e: string | React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    this.setState({
      text: typeof e === 'string' ? e : e.currentTarget.value,
    });
  };

  private renderChildren = () => {
    const {
      renderChild,
      childOptions,
      renderChildren,
      option,
      openDeleteOption,
    } = this.props;

    if (renderChildren) {
      return renderChildren({
        childOptions,
        BaseWidgetChild,
        text: this.state.text,
        onTextChange: this.onTextChange,
        editing: this.state.editing,
        optionText: getOptionText(option),
      });
    }
    if (!childOptions) {
      return null;
    }
    return (childOptions || []).map((id, i) =>
      renderChild ? (
        renderChild(id, i)
      ) : (
        <BaseWidgetChild
          key={id}
          optionId={id}
          mandatory={true}
          openDeleteOption={openDeleteOption}
          index={i}
        />
      ),
    );
  };

  private openConfirmDelete = () => {
    if (this.props.openDeleteOption) {
      this.props.openDeleteOption(this.props.optionId);
    }
  };

  public render() {
    const { option, extraActions, t, index, fid, disabled } = this.props;
    const { editing, title } = this.state;

    const defaultTitle = this.props.defaultTitle || 'Option';

    if (!option) {
      return null;
    }

    const renderedChildren = this.renderChildren();

    return (
      <Draggable draggableId={`${option.id}`} index={index}>
        {provided => (
          <Container
            className={'bg-white'}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <HeaderWrapper className={'bg-white'}>
              <DragWrapper
                className={'text-black'}
                {...provided.dragHandleProps}
              >
                <DragHandler />
              </DragWrapper>
              <Header>
                {editing ? (
                  <React.Fragment>
                    <TitleInput
                      value={title}
                      onChange={this.onTitleChange}
                      placeholder={t(
                        'super:screens.form.titleInputPlaceholder',
                      )}
                    />
                    <HeaderActions>
                      <HeaderAction
                        className={'bg-default text-white'}
                        onClick={this.cancelEditing}
                      >
                        {t('super:screens.form.cancel')}
                      </HeaderAction>
                      <HeaderAction
                        className={
                          disabled
                            ? 'bg-grey text-white'
                            : 'bg-success text-white'
                        }
                        onClick={this.stopEditing}
                        disabled={disabled}
                      >
                        {t('super:screens.form.done')}
                      </HeaderAction>
                    </HeaderActions>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    {getOptionTitle(option) || defaultTitle}
                    <HeaderActions>
                      {extraActions && extraActions.map(e => e)}
                      <HeaderAction
                        className={'bg-transparent'}
                        onClick={this.startEditing}
                      >
                        <Cog className={'w-4 h-4'} />
                      </HeaderAction>
                      <HeaderAction
                        className={'bg-transparent'}
                        onClick={this.openConfirmDelete}
                      >
                        <Trash className={'w-4 h-4'} />
                      </HeaderAction>
                    </HeaderActions>
                  </React.Fragment>
                )}
              </Header>
            </HeaderWrapper>
            {this.props.renderChildren || this.props.renderChild ? (
              <Body>{renderedChildren}</Body>
            ) : (
              <Droppable
                droppableId={`${option.id}`}
                isDropDisabled={
                  !contains(fid, widgetsForDroppable[option.f_id] || [])
                }
              >
                {drop => (
                  <div ref={drop.innerRef} {...drop.droppableProps}>
                    {!renderedChildren ? null : renderedChildren.length ===
                      0 ? (
                      <Body />
                    ) : (
                      <Body>{renderedChildren}</Body>
                    )}
                    {drop.placeholder}
                  </div>
                )}
              </Droppable>
            )}
          </Container>
        )}
      </Draggable>
    );
  }
}

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getChildren = makeGetChildrenIds();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    childOptions: getChildren(state, ownProps),
    fid: state.formOptions.draggingFid,
  });
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateFormOption: (option: FormOption) =>
      dispatch(updateFormOption(option)),
  };
}

export default withNamespaces()(
  connect(makeMapStateToProps, mapDispatchToProps)(BaseWidget),
);

import * as certActions from '../actions/certificates';
import { ActionType, getType } from 'typesafe-actions';
import { Certificate, PermissionsError } from '../types';

export type CertificateAction = ActionType<typeof certActions>;

export interface CertificatesState {
  loading: boolean;
  loaded: boolean;
  errors: PermissionsError;
  data: Certificate[];
  companyUid: string;
}

const DEFAULT_STATE: CertificatesState = {
  loading: false,
  loaded: false,
  errors: {
    token: [],
  },
  data: [],
  companyUid: '',
};

export default function certificatesReducer(
  state: CertificatesState = DEFAULT_STATE,
  action: CertificateAction,
) {
  switch (action.type) {
    case getType(certActions.certificatesFetchRequest):
      return {
        ...state,
        loading: true,
        companyUid: action.payload,
      };
    case getType(certActions.certificatesFetchSuccess):
      return {
        ...state,
        loading: false,
        loaded: true,
        data: [...action.payload],
      };
    case getType(certActions.certificatesFetchFailure):
      return {
        ...state,
        loaded: false,
        loading: false,
        data: [],
        errors: { ...action.payload },
      };
    case getType(certActions.setCertificates):
      return {
        ...state,
        data: [...action.payload],
      };
    case getType(certActions.addCertificate):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(certActions.updateCertificate):
      return {
        ...state,
        data: state.data.map(
          cert =>
            cert.uid === action.payload.uid ? { ...action.payload } : cert,
        ),
      };
    case getType(certActions.removeCertificate):
      return {
        ...state,
        data: state.data.filter(cert => cert.uid !== action.payload),
      };
    default:
      return state;
  }
}

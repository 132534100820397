import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const Clock = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M10 20a10 10 0 1 1 0-20 10 10 0 0 1 0 20zm0-2a8 8 0 1 0 0-16 8 8 0 0 0 0 16zm-1-7.59V4h2v5.59l3.95 3.95-1.41 1.41L9 10.41z" />
  </svg>
);

export default BaseSVG(Clock);

import { baseApi } from '../api/api';
import { get } from 'lodash';
import { NotificationPreferences } from '../types';

const authApi = async <T>(
  method: string,
  url: string,
  data: any = {},
  options: any = {},
): Promise<{ data: T | undefined; errors: any | undefined }> => {
  try {
    const res = await baseApi(
      method,
      `${process.env.AUTH_URL}/${url}`,
      data,
      options,
    );
    return { data: res, errors: undefined };
  } catch (e) {
    return { data: undefined, errors: get(e, 'errors', e) };
  }
};

const authService = {
  forceEmailCheck: (email: string, password: string) =>
    authApi('POST', `verify-email`, { email, password }),
  verifyEmailCode: (code: string) => authApi('POST', `confirm-email`, { code }),
  resendVerification: () => authApi('POST', `resend-verification-email`),
  userType: (username: string) =>
    authApi<{ userType: string | null }>('POST', `user-type`, { username }),
};

export default authService;

import * as React from 'react';
import styled from 'styled-components';
import { colors } from '../tailwind';
import { Colors } from '../types';
import ReactLoading from 'react-loading';

const Wrapper = styled.button<Props>`
  border: 0;
  padding: 6px 12px;
  cursor: pointer;
  text-transform: uppercase;
  background: ${props => (props.type ? colors[props.type] : colors.primary)};
  color: white;

  width: ${props => (props.block ? '100%' : 'auto')};
  display: ${props => (props.block ? 'flex' : 'inline-flex')};
  align-items: center;
  justify-content: center;

  &:hover {
    background: ${props =>
      props.type ? colors[`${props.type}-light`] : colors['primary-light']};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  & > div {
    margin-right: 5px;
  }
`;

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  type?: Colors;
  block?: boolean;
  disabled?: boolean;
  loading?: boolean;
}

export default class Button extends React.Component<Props> {
  public render() {
    return (
      <Wrapper
        {...this.props}
        disabled={this.props.disabled || this.props.loading}
      >
        {this.props.loading ? (
          <ReactLoading
            type={'spin'}
            color={colors.primary}
            width={'1em'}
            height={'1em'}
          />
        ) : null}
        {this.props.children}
      </Wrapper>
    );
  }
}

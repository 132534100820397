export const SITES_FETCH_REQUEST = 'SITES_FETCH_REQUEST';
export const SITES_FETCH_SUCCESS = 'SITES_FETCH_SUCCESS';
export const SITES_FETCH_FAILURE = 'SITES_FETCH_FAILURE';
export const SITES_ADD = 'SITES_ADD';
export const SITES_REMOVE = 'SITES_REMOVE';
export const SITES_INVALIDATE = 'SITES_INVALIDATE';

export const USERS_FETCH_REQUEST = 'USERS_FETCH_REQUEST';
export const USERS_FETCH_SUCCESS = 'USERS_FETCH_SUCCESS';
export const USERS_FETCH_FAILURE = 'USERS_FETCH_FAILURE';
export const USERS_ADD = 'USERS_ADD';
export const USERS_REMOVE = 'USERS_REMOVE';
export const USERS_INVALIDATE = 'USERS_INVALIDATE';

export const CREWS_FETCH_REQUEST = 'CREWS_FETCH_REQUEST';
export const CREWS_FETCH_SUCCESS = 'CREWS_FETCH_SUCCESS';
export const CREWS_FETCH_FAILURE = 'CREWS_FETCH_FAILURE';
export const CREWS_ADD = 'CREWS_ADD';
export const CREWS_REMOVE = 'CREWS_REMOVE';
export const CREWS_INVALIDATE = 'CREWS_INVALIDATE';

export const FORMS_FETCH_REQUEST = 'FORMS_FETCH_REQUEST';
export const FORMS_FETCH_SUCCESS = 'FORMS_FETCH_SUCCESS';
export const FORMS_FETCH_FAILURE = 'FORMS_FETCH_FAILURE';
export const FORMS_ADD = 'FORMS_ADD';
export const FORMS_UPDATE = 'FORMS_UPDATE';
export const FORMS_REMOVE = 'FORMS_REMOVE';
export const FORMS_INVALIDATE = 'FORMS_INVALIDATE';

export const AUTH_SET_USER = 'SET_AUTH_USER';
export const AUTH_SET_COMPANY = 'SET_AUTH_COMPANY';
export const AUTH_SET_COMPANIES = 'SET_AUTH_COMPANIES';
export const AUTH_SET_COMPANY_GROUP = 'AUTH_SET_COMPANY_GROUP';
export const AUTH_SET_COMPANY_GROUPS = 'AUTH_SET_COMPANY_GROUPS';
export const AUTH_USER_LOGOUT = 'auth/USER_LOGOUT';
export const AUTH_SET_PERMISSIONS = 'auth/AUTH_SET_PERMISSIONS';
export const AUTH_SET_CONVERSATIONS = 'auth/AUTH_SET_CONVERSATIONS';
export const AUTH_ADD_CONVERSATIONS = 'auth/AUTH_ADD_CONVERSATIONS';

export const SUPER_SITE_FORMS_SET = 'super/SITE_FORMS_SET';
export const SUPER_SITE_FORMS_ADD = 'super/SITE_FORMS_ADD';
export const SUPER_SITE_FORMS_REMOVE = 'super/SITE_FORMS_REMOVE';

export const SUPER_SITE_CREWS_SET = 'super/SITE_CREWS_SET';
export const SUPER_SITE_CREWS_ADD = 'super/SITE_CREWS_ADD';
export const SUPER_SITE_CREWS_REMOVE = 'super/SITE_CREWS_REMOVE';

export const SUPER_SITE_MANAGERS_SET = 'super/SITE_MANAGERS_SET';
export const SUPER_SITE_MANAGERS_ADD = 'super/SITE_MANAGERS_ADD';
export const SUPER_SITE_MANAGERS_REMOVE = 'super/SITE_MANAGERS_REMOVE';

export const FORM_OPTIONS_SET = 'FORM_OPTIONS_SET';
export const FORM_OPTIONS_ADD = 'FORM_OPTIONS_ADD';
export const FORM_OPTIONS_REMOVE = 'FORM_OPTIONS_REMOVE';
export const FORM_OPTIONS_UPDATE = 'FORM_OPTIONS_UPDATE';
export const FORM_OPTIONS_MOVE = 'FORM_OPTIONS_MOVE';
export const FORM_OPTIONS_REPLACE = 'FORM_OPTIONS_REPLACE';
export const FORM_OPTIONS_DRAG_F_ID = 'FORM_OPTIONS_DRAG_F_ID';

export const CERTIFICATES_FETCH_REQUEST = 'CERTIFICATES_FETCH_REQUEST';
export const CERTIFICATES_FETCH_SUCCESS = 'CERTIFICATES_FETCH_SUCCESS';
export const CERTIFICATES_FETCH_FAILURE = 'CERTIFICATES_FETCH_FAILURE';
export const CERTIFICATES_SET = 'CERTIFICATES_SET';
export const CERTIFICATES_ADD = 'CERTIFICATES_ADD';
export const CERTIFICATES_REMOVE = 'CERTIFICATES_REMOVE';
export const CERTIFICATES_UPDATE = 'CERTIFICATES_UPDATE';

export const WORK_ROLES_FETCH_REQUEST = 'WORK_ROLES_FETCH_REQUEST';
export const WORK_ROLES_FETCH_SUCCESS = 'WORK_ROLES_FETCH_SUCCESS';
export const WORK_ROLES_FETCH_FAILURE = 'WORK_ROLES_FETCH_FAILURE';
export const WORK_ROLES_SET = 'WORK_ROLES_SET';
export const WORK_ROLES_ADD = 'WORK_ROLES_ADD';
export const WORK_ROLES_UPDATE = 'WORK_ROLES_UPDATE';
export const WORK_ROLES_REMOVE = 'WORK_ROLES_REMOVE';

export const ROLE_CERT_MATRIX_FETCH_REQUEST = 'ROLE_CERT_MATRIX_FETCH_REQUEST';
export const ROLE_CERT_MATRIX_FETCH_SUCCESS = 'ROLE_CERT_MATRIX_FETCH_SUCCESS';
export const ROLE_CERT_MATRIX_FETCH_FAILURE = 'ROLE_CERT_MATRIX_FETCH_FAILURE';
export const ROLE_CERT_MATRIX_SET = 'ROLE_CERT_MATRIX_SET';
export const ROLE_CERT_MATRIX_ADD = 'ROLE_CERT_MATRIX_ADD';
export const ROLE_CERT_MATRIX_UPDATE = 'ROLE_CERT_MATRIX_UPDATE';
export const ROLE_CERT_MATRIX_REMOVE = 'ROLE_CERT_MATRIX_REMOVE';

export const COMPANY_GROUP_FORMS_FETCH_REQUEST =
  'COMPANY_GROUP_FORMS_FETCH_REQUEST';
export const COMPANY_GROUP_FORMS_FETCH_SUCCESS =
  'COMPANY_GROUP_FORMS_FETCH_SUCCESS';
export const COMPANY_GROUP_FORMS_FETCH_FAILURE =
  'COMPANY_GROUP_FORMS_FETCH_FAILURE';
export const COMPANY_GROUP_FORMS_ADD = 'COMPANY_GROUP_FORMS_ADD';
export const COMPANY_GROUP_FORMS_REMOVE = 'COMPANY_GROUP_FORMS_REMOVE';

export const COMPANY_GROUP_SITES_FETCH_REQUEST =
  'COMPANY_GROUP_SITES_FETCH_REQUEST';
export const COMPANY_GROUP_SITES_FETCH_SUCCESS =
  'COMPANY_GROUP_SITES_FETCH_SUCCESS';
export const COMPANY_GROUP_SITES_FETCH_FAILURE =
  'COMPANY_GROUP_SITES_FETCH_FAILURE';
export const COMPANY_GROUP_SITES_ADD = 'COMPANY_GROUP_SITES_ADD';
export const COMPANY_GROUP_SITES_REMOVE = 'COMPANY_GROUP_SITES_REMOVE';

export const COMPANY_GROUP_CREWS_FETCH_REQUEST =
  'COMPANY_GROUP_CREWS_FETCH_REQUEST';
export const COMPANY_GROUP_CREWS_FETCH_SUCCESS =
  'COMPANY_GROUP_CREWS_FETCH_SUCCESS';
export const COMPANY_GROUP_CREWS_FETCH_FAILURE =
  'COMPANY_GROUP_CREWS_FETCH_FAILURE';
export const COMPANY_GROUP_CREWS_ADD = 'COMPANY_GROUP_CREWS_ADD';
export const COMPANY_GROUP_CREWS_REMOVE = 'COMPANY_GROUP_CREWS_REMOVE';

export const COMPANY_GROUP_USERS_FETCH_REQUEST =
  'COMPANY_GROUP_USERS_FETCH_REQUEST';
export const COMPANY_GROUP_USERS_FETCH_SUCCESS =
  'COMPANY_GROUP_USERS_FETCH_SUCCESS';
export const COMPANY_GROUP_USERS_FETCH_FAILURE =
  'COMPANY_GROUP_USERS_FETCH_FAILURE';
export const COMPANY_GROUP_USERS_ADD = 'COMPANY_GROUP_USERS_ADD';
export const COMPANY_GROUP_USERS_REMOVE = 'COMPANY_GROUP_USERS_REMOVE';

export const COMPANY_GROUP_COMPANIES_FETCH_SUCCESS =
  'COMPANY_GROUP_COMPANIES_FETCH_SUCCESS';
export const COMPANY_GROUP_COMPANIES_FETCH_REQUEST =
  'COMPANY_GROUP_COMPANIES_FETCH_REQUEST';
export const COMPANY_GROUP_COMPANIES_FETCH_FAILURE =
  'COMPANY_GROUP_COMPANIES_FETCH_FAILURE';
export const COMPANY_GROUP_COMPANIES_ADD = 'COMPANY_GROUP_COMPANIES_ADD';
export const COMPANY_GROUP_COMPANIES_REMOVE = 'COMPANY_GROUP_COMPANIES_REMOVE';

export const SUBM_DATA_SET = 'SUBM_DATA_SET';
export const SUBM_DATA_UPDATE = 'SUBM_DATA_UPDATE';
export const SUBM_DATA_ADD = 'SUBM_DATA_ADD';

export const OPEN_DOWNLOADS = 'OPEN_DOWNLOADS';
export const CLOSE_DOWNLOADS = 'CLOSE_DOWNLOADS';

export const SET_FORM_META = 'SET_FORM_META';

export const ASSIGNED_ACTIONS_FETCH_REQUEST = 'ASSIGNED_ACTIONS_FETCH_REQUEST';
export const ASSIGNED_ACTIONS_FETCH_SUCCESS = 'ASSIGNED_ACTIONS_FETCH_SUCCESS';
export const ASSIGNED_ACTIONS_FETCH_FAILURE = 'ASSIGNED_ACTIONS_FETCH_FAILURE';

export const CREATED_ACTIONS_FETCH_REQUEST = 'CREATED_ACTIONS_FETCH_REQUEST';
export const CREATED_ACTIONS_FETCH_SUCCESS = 'CREATED_ACTIONS_FETCH_SUCCESS';
export const CREATED_ACTIONS_FETCH_FAILURE = 'CREATED_ACTIONS_FETCH_FAILURE';

export const FORM_COMPLIANCE_FETCH_REQUEST = 'FORM_COMPLIANCE_FETCH_REQUEST';
export const FORM_COMPLIANCE_FETCH_SUCCESS = 'FORM_COMPLIANCE_FETCH_SUCCESS';

export const WORKER_SITE_FORMS_FETCH_REQUEST =
  'WORKER_SITE_FORMS_FETCH_REQUEST';
export const WORKER_SITE_FORMS_FETCH_SUCCESS =
  'WORKER_SITE_FORMS_FETCH_SUCCESS';
export const WORKER_SITE_FORMS_FETCH_FAILURE =
  'WORKER_SITE_FORMS_FETCH_FAILURE';

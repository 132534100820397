import * as React from 'react';
import HeaderButton from './HeaderButton';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import DatePicker from '../DatePicker';
import { Moment } from 'moment';
import { DateFilterType } from '../DateFilterWidget';

interface Props extends WithNamespaces {
  onStartEndChange: (start: Moment, end: Moment) => void;
  start: Moment;
  end: Moment;
  view: DateFilterType;
  onViewChange: (view: DateFilterType) => void;
  views: DateFilterType[];
}

const DateFilters = ({
  onStartEndChange,
  end,
  start,
  view,
  onViewChange,
  t,
  views,
}: Props) => {
  const [lastView, setLastView] = React.useState(view);
  const onStartChange = (date: Moment) => {
    onStartEndChange(date, end);
  };
  const onEndChange = (date: Moment) => {
    onStartEndChange(start, date);
  };
  const handleViewChange = (v: DateFilterType) => {
    setLastView(view);
    onViewChange(v);
  };

  if (view !== 'custom') {
    return (
      <>
        {views.map(b => (
          <HeaderButton
            key={b}
            active={view === b}
            onClick={() => handleViewChange(b)}
          >
            {t(`widgets:dateFilters.${b}`)}
          </HeaderButton>
        ))}
      </>
    );
  }

  return (
    <div className={'flex flex-row items-center'}>
      <DatePicker value={start} onChange={onStartChange}>
        {({ getInputProps, ref }) => (
          <div className={'mr-2'}>
            <label className={'mr-2'}>From</label>
            <input {...getInputProps()} ref={ref} className={'p-2'} />
          </div>
        )}
      </DatePicker>
      <DatePicker value={end} onChange={onEndChange}>
        {({ getInputProps, ref }) => (
          <div>
            <label className={'mr-2'}>To</label>
            <input {...getInputProps()} ref={ref} className={'p-2'} />
          </div>
        )}
      </DatePicker>
      <HeaderButton onClick={() => onViewChange(lastView)}>
        &times;
      </HeaderButton>
    </div>
  );
};

export default withNamespaces()(DateFilters);

import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import Super from './Super';
import { History } from 'history';
import { connect } from 'react-redux';
import LocalHeader from '../components/headers/LocalHeader';
import TopNavigation from '../components/headers/TopNavigation';
import styled from 'styled-components';
import { localStorageKeys, cssVars } from '../constants';
import meApi from '../api/me.api';
import { Dispatch } from 'redux';
import {
  setCompanies,
  setCompany,
  setUser,
  setCompanyGroup,
  setCompanyGroups,
  setPermissions,
} from '../actions/me';
import { Company, User, CompanyGroup, AuthPermissions } from '../types';
import * as R from 'ramda';
import Loading from '../components/Loading';
import store, { StoreState } from '../store';
import CompanyAdmin from './CompanyAdmin';
import Worker from './Worker';
import Notifications from '../containers/Notifications';
import Downloads from '../containers/Downloads';
import Messages from '../containers/Messages';
import { useMountVisibility } from '../helpers/hooks';
import { openDownloads, closeDownloads } from '../actions/ui';
import { getLocation } from '../helpers/location';
import Admin from './Admin';
import EmailChecker from '../containers/EmailChecker';

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #ededed;
`;

const AutoHeightNavs = styled.div`
  flex-grow: 0;
`;

interface Props {
  history: History;
  setUser: (user: User) => void;
  setCompanies: (companies: Company[]) => void;
  setCompany: (company: Company) => void;
  setCompanyGroup: (companyGroup: CompanyGroup) => any;
  setCompanyGroups: (companyGroups: CompanyGroup[]) => any;
  setPermissions: (perms: AuthPermissions) => any;
  downloadsOpen: boolean;
  openDownloads: () => any;
  closeDownloads: () => any;
  user: User;
}

const Dashboard = ({
  history,
  setUser: pSetUser,
  setCompanies: pSetCompanies,
  setCompany: pSetCompany,
  setCompanyGroup: pSetCompanyGroup,
  setCompanyGroups: pSetCompanyGroups,
  closeDownloads: pCloseDownloads,
  openDownloads: pOpenDownloads,
  setPermissions: pSetPermissions,
  downloadsOpen,
  user,
}: Props) => {
  const [userAvailable, setUserAvailable] = React.useState(false);
  const [notificationCount, setNotificationCount] = React.useState<number>(0);
  const notificationState = useMountVisibility();
  const messagesState = useMountVisibility();
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const showVerifyEmail = useMountVisibility();

  const getUser = async () => {
    // fetch the current user
    const { data } = await meApi.me();

    if (data) {
      // store in redux
      pSetUser(data.user);
    }
  };

  const getCompanies = async () => {
    const { data } = await meApi.companies();
    if (!data) {
      return;
    }
    const { companies } = data;
    pSetCompanies(companies);
    setCompanies(companies);
    if (companies.length > 0) {
      const previousCompanyUid = localStorage.getItem(
        localStorageKeys.CURRENT_COMPANY_UID,
      );
      const previousCompany = R.find(
        (c: Company) => c.uid === previousCompanyUid,
        companies,
      );

      if (previousCompanyUid && previousCompany) {
        pSetCompany(previousCompany);
      } else {
        pSetCompany(companies[0]);
      }
    }
  };

  const getCompanyGroups = async () => {
    const { data } = await meApi.companyGroups();

    if (!data) {
      return;
    }
    const { companyGroups } = data;

    pSetCompanyGroups(companyGroups);

    if (companyGroups.length > 0) {
      const previousCompanyGroupUid = localStorage.getItem(
        localStorageKeys.CURRENT_COMPANY_GROUP_UID,
      );
      const previousCompanyGroup = R.find(
        (c: CompanyGroup) => c.uid === previousCompanyGroupUid,
        companyGroups,
      );

      if (previousCompanyGroupUid && previousCompanyGroup) {
        pSetCompanyGroup(previousCompanyGroup);
      } else {
        pSetCompanyGroup(companyGroups[0]);
      }
    }
  };

  const getPermissions = async () => {
    const { data } = await meApi.permissions();

    if (!data) {
      return;
    }
    const { permissions } = data;

    pSetPermissions(permissions);
  };

  function onNotificationCount(count: number) {
    setNotificationCount(count);
  }

  React.useEffect(() => {
    // redirect if token is not present
    if (!localStorage.getItem(localStorageKeys.USER_TOKEN)) {
      history.push('/login');
      return;
    }

    // Prevent refetching info when HMR is enabled
    // TODO: when a user can belong to NO companies this will break
    if (store.getState().me.user && store.getState().me.companies.length > 0) {
      setUserAvailable(true);
      return;
    }

    getUser().then(() => {
      getCompanies().then(() => {
        getCompanyGroups().then(() => {
          getPermissions().then(() => {
            setUserAvailable(true);
          });
        });
      });
    });

    getLocation();
  }, []);

  React.useEffect(() => {
    // user starts out as empty obj
    if (
      user &&
      Object.keys(user).length > 0 &&
      user.authentication_provider !== 'sso' &&
      (!user.email || user.email_verification !== 'success')
    ) {
      showVerifyEmail.open();
    }
  }, [user && user.email]);

  if (!userAvailable) {
    return (
      <div style={{ position: 'absolute', height: '100vh', width: '100vw' }}>
        <Loading loading={true} />
      </div>
    );
  }

  return (
    <Container>
      <AutoHeightNavs>
        <Route
          path={'/dashboard/worker'}
          render={props => <LocalHeader bg={cssVars.colors.green} />}
        />
        <Route
          path={'/dashboard/super'}
          render={props => <LocalHeader bg={cssVars.colors.red} />}
        />
        <Route
          path={'/dashboard/company-admin'}
          render={props => <LocalHeader bg={'#253D4E'} />}
        />
        <Route
          path={'/dashboard/admin'}
          render={props => <LocalHeader bg={cssVars.colors.default} />}
        />
        <TopNavigation
          openNotifications={notificationState.open}
          openDownloads={pOpenDownloads}
          openMessages={messagesState.open}
          onNotificationCount={onNotificationCount}
        />
      </AutoHeightNavs>
      <Switch>
        <Redirect
          exact={true}
          from={'/dashboard'}
          to={'/dashboard/worker/dashboard'}
        />
        <Redirect
          exact={true}
          from={'/dashboard/worker'}
          to={'/dashboard/worker/dashboard'}
        />
        <Redirect
          exact={true}
          from={'/dashboard/super'}
          to={'/dashboard/super/dashboard'}
        />
        <Redirect
          exact={true}
          from={'/dashboard/company-admin'}
          to={'/dashboard/company-admin/dashboard'}
        />
        <Redirect
          exact={true}
          from={'/dashboard/admin'}
          to={'/dashboard/admin/dashboard'}
        />
      </Switch>

      <Route path={'/dashboard/super'} component={Super} />
      <Route path={'/dashboard/worker'} component={Worker} />
      <Route path={'/dashboard/company-admin'} component={CompanyAdmin} />
      <Route path={'/dashboard/admin'} component={Admin} />

      {companies.length === 0 && (
        <div className="flex-1 flex flex-col justify-center items-center p-4 text-center">
          <h1 className="text-3xl md:text-5xl mb-2">
            You are not on any Divisions!
          </h1>
          <p className="text-xl">
            If you think this is an error please contact your supervisor, or
            reach out to{' '}
            <a href="mailto:support@safetytek.ca">support@safetytek.ca</a>
          </p>
        </div>
      )}
      <EmailChecker
        isOpen={showVerifyEmail.visible}
        handleClose={showVerifyEmail.close}
      />
      <Notifications
        isOpen={notificationState.visible}
        handleClose={notificationState.close}
        notificationCount={notificationCount}
      />
      <Downloads isOpen={downloadsOpen} handleClose={pCloseDownloads} />
      <Messages
        isOpen={messagesState.visible}
        handleClose={messagesState.close}
      />
    </Container>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    downloadsOpen: state.ui.downloadsOpen,
    user: state.me.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setUser: (user: User) => dispatch(setUser(user)),
    setCompany: (company: Company) => dispatch(setCompany(company)),
    setCompanies: (companies: Company[]) => dispatch(setCompanies(companies)),
    setCompanyGroup: (companyGroup: CompanyGroup) =>
      dispatch(setCompanyGroup(companyGroup)),
    setCompanyGroups: (companyGroups: CompanyGroup[]) =>
      dispatch(setCompanyGroups(companyGroups)),
    openDownloads: () => dispatch(openDownloads()),
    closeDownloads: () => dispatch(closeDownloads()),
    setPermissions: (perms: AuthPermissions) => dispatch(setPermissions(perms)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);

import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { makeGetChildren } from '../../selectors/formOptions';
import { FormOption } from '../../types';
import styled from 'styled-components';
import { formFeatureIds } from '../../constants';
import QuestionAnswer from './QuestionAnswer';
import Audit from './Audit';
import { filterChildrenByPublished } from '../../helpers/formOption';
import Actions from './Actions';
import Table from './Table';
import YesNo from './YesNo';
import UserInput from './UserInput';
import Instructions from './Instructions';
import Signature from './Signature';
import ImportImage from './ImportImage';
import Selection from './Selection';
import SignOff from './SignOff';
import Users from './Users';
import Sketch from './Sketch';
import Inspection from './Inspection';

const Container = styled.div`
  flex: 1;
  max-width: 100%;
`;

const FieldMap = {
  [formFeatureIds.QUESTION_ANSWER]: QuestionAnswer,
  [formFeatureIds.AUDIT]: Audit,
  [formFeatureIds.ACTIONS]: Actions,
  [formFeatureIds.TABLE]: Table,
  [formFeatureIds.QUESTION]: YesNo,
  [formFeatureIds.USER_INPUT]: UserInput,
  [formFeatureIds.INSTRUCTIONS]: Instructions,
  [formFeatureIds.SIGNATURE]: Signature,
  [formFeatureIds.IMAGE]: ImportImage,
  [formFeatureIds.SELECTION]: Selection,
  [formFeatureIds.SIGN_OFF]: SignOff,
  [formFeatureIds.USERS]: Users,
  [formFeatureIds.SKETCH]: Sketch,
  [formFeatureIds.INSPECTION]: Inspection,
};

interface Props {
  optionId: number;
  childOptions?: FormOption[];
  live?: boolean;
}

const Column = ({ childOptions, live }: Props) => {
  const filteredChildren = live
    ? filterChildrenByPublished(childOptions)
    : childOptions || [];

  return (
    <Container>
      {filteredChildren.map(i => {
        const Component = FieldMap[i.f_id];
        if (!Component) {
          return null;
        }

        return <Component key={i.id} optionId={i.id} live={live} />;
      })}
    </Container>
  );
};

function makeMapStateToProps() {
  const getChildren = makeGetChildren();
  return (state: StoreState, ownProps: Props) => ({
    childOptions: getChildren(state, ownProps),
  });
}

export default connect(makeMapStateToProps)(Column);

import { createAction } from 'typesafe-actions';
import { Company, User, CompanyGroup, AuthPermissions } from '../types';
import {
  AUTH_SET_USER,
  AUTH_SET_COMPANY,
  AUTH_SET_COMPANIES,
  AUTH_SET_COMPANY_GROUPS,
  AUTH_SET_COMPANY_GROUP,
  AUTH_SET_PERMISSIONS,
  AUTH_SET_CONVERSATIONS,
  AUTH_ADD_CONVERSATIONS,
} from '../constants/actionTypes';
import { CompanyUserConversation } from '../api/companyUser.api';

export const setUser = createAction(AUTH_SET_USER, resolve => (user: User) =>
  resolve(user),
);

export const setCompany = createAction(
  AUTH_SET_COMPANY,
  resolve => (company: Company) => resolve(company),
);

export const setCompanies = createAction(
  AUTH_SET_COMPANIES,
  resolve => (companies: Company[]) => resolve(companies),
);

export const setCompanyGroup = createAction(
  AUTH_SET_COMPANY_GROUP,
  resolve => (companyGroup: CompanyGroup) => resolve(companyGroup),
);

export const setCompanyGroups = createAction(
  AUTH_SET_COMPANY_GROUPS,
  resolve => (companyGroups: CompanyGroup[]) => resolve(companyGroups),
);

export const setPermissions = createAction(
  AUTH_SET_PERMISSIONS,
  resolve => (permissions: AuthPermissions) => resolve(permissions),
);

export const setConversations = createAction(
  AUTH_SET_CONVERSATIONS,
  resolve => (conversations: CompanyUserConversation[]) =>
    resolve(conversations),
);

export const addConversation = createAction(
  AUTH_ADD_CONVERSATIONS,
  resolve => (conversation: CompanyUserConversation) => resolve(conversation),
);

import * as React from 'react';
import * as R from 'ramda';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import InlineSelect from '../../../components/InlineSelect';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Company, Crew, Site } from '../../../types';
import { toast } from 'react-toastify';
import UserGroup from '../../../svg/UserGroup';
import { SelectOptionObject } from '../../../components/Select';
import siteApi from '../../../api/site.api';
import Loading from '../../../components/Loading';
import { Dispatch } from 'redux';
import { crewsFetchRequest } from '../../../actions/crews';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { CrewsState } from '../../../reducers/crews';
import { needsToBeFetched } from '../../../helpers/globalState';
import {
  getSiteLabel,
  getCrewLabelPlural,
  getCrewLabel,
} from '../../../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  site: Site;
  afterSubmit: (crews: Crew[]) => void;
  crews: CrewsState;
  getCrews: (companyUid: string) => any;
  crewsOnSite?: Crew[];
}

interface State {
  selected: SelectOptionObject[];
  loadingCrews: boolean;
  submitting: boolean;
}

class AddForms extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selected: [],
      loadingCrews: false,
      submitting: false,
    };
  }

  public componentDidMount() {
    if (needsToBeFetched(this.props.crews)) {
      this.props.getCrews(this.props.company.uid);
    }
  }

  private submit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { selected } = this.state;
    const { t, site, crews: companyCrews, afterSubmit } = this.props;

    this.setState({
      submitting: true,
    });

    try {
      await Promise.all(
        selected.map(async selection => {
          await siteApi.attachCrew(site.uid, selection.value);
        }),
      );

      const crews = selected.map(
        selection =>
          companyCrews.data.find(crew => crew.uid === selection.value)!,
      );

      this.setState({
        selected: [],
      });

      afterSubmit(crews);
    } catch (e) {
      toast.error(
        `Failed to attach ${getCrewLabel().toLowerCase()} to ${getSiteLabel().toLowerCase()}`,
      );
    }

    this.setState({
      submitting: false,
    });
  };

  private onSelectedChange = (selected: SelectOptionObject[]) => {
    this.setState({
      selected,
    });
  };

  private getCrewOptions = () => {
    const { crews, crewsOnSite } = this.props;

    const crewsOnSiteUids = (crewsOnSite || []).map(c => c.uid);
    const filteredCrews = crews.data.filter(
      c => !R.contains(c.uid, crewsOnSiteUids),
    );

    return R.map(f => ({ label: f.name, value: f.uid }), filteredCrews);
  };

  public render() {
    const { isOpen, handleClose, t } = this.props;
    const { submitting, selected } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={`Add ${getCrewLabelPlural()} to ${getSiteLabel()}`}
        handleClose={handleClose}
      >
        <ModalHeader handleClose={handleClose}>
          {`Add ${getCrewLabelPlural()} to ${getSiteLabel()}`}
        </ModalHeader>

        <form onSubmit={this.submit}>
          <ModalBody>
            <InlineSelect
              label={`${getCrewLabelPlural()}`}
              icon={<UserGroup />}
              inputProps={{ placeholder: `Add ${getCrewLabelPlural()}` }}
              options={this.getCrewOptions()}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              isMulti={true}
              value={selected}
              onChange={this.onSelectedChange}
            />
          </ModalBody>
          <ModalFooter right={true}>
            <Button
              type={'primary'}
              disabled={selected.length === 0}
              loading={submitting}
            >
              {t('common:submit')}
            </Button>
          </ModalFooter>
        </form>
      </Modal>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    crews: state.crews,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(AddForms),
);

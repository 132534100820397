import * as React from 'react';
import formService from '../../services/forms.service';
import { useState, useEffect, useRef } from 'react';
import { useTextInput } from '../../helpers/hooks';
import InlineInput from '../../components/InlineInput';
import SignatureCanvas, {
  isCanvasBlank,
} from '../../components/SignatureCanvas';
import { toast } from 'react-toastify';
import Envelope from '../../svg/Envelope';
import User from '../../svg/User';
import cloudinaryService from '../../services/cloudinary.service';
import Loading from '../../components/Loading';

interface SignOffErrors {
  email?: string | string[];
  full_name?: string | string[];
  signature_id?: string | string[];
  signature_url?: string | string[];
}

interface Props {
  instanceRevisionUid: string;
  setSubmitFunc: (func: any) => void;
  handleClose: () => void;
  getSignOffs: () => void;
}

async function getFileSignature(instanceRevisionUid: string) {
  const { data } = await formService.getInstanceRevisionFileSignature(
    instanceRevisionUid,
  );
  if (!data) {
    return;
  }
  const { signature, timestamp, folder } = data;
  return {
    signature,
    folder,
    timestamp,
  };
}

function ThirdPartySignOff({
  instanceRevisionUid,
  setSubmitFunc,
  handleClose,
  getSignOffs,
}: Props) {
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<SignOffErrors>({});
  const fullName = useTextInput();
  const email = useTextInput();
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const withContext = canvasRef.current && canvasRef.current.getContext('2d');

  async function handleSubmit() {
    setLoading(true);
    if (!canvasRef.current) {
      setLoading(false);
      toast.error('Failed to upload signature');
      return;
    }
    if (isCanvasBlank(canvasRef.current)) {
      setLoading(false);
      toast.error('You cannot leave the signature blank!');
      return;
    }
    const fileSig = await getFileSignature(instanceRevisionUid);
    if (!fileSig) {
      setLoading(false);
      toast.error('Something went wrong, try again.');
      return;
    }
    if (!email.value || !fullName.value) {
      setLoading(false);
      toast.error('Please enter an email and name');
      return;
    }
    const cloudinaryRes = await cloudinaryService.uploadSigned(
      canvasRef.current.toDataURL(),
      fileSig,
    );
    if (!cloudinaryRes) {
      setLoading(false);
      toast.error('Failed to upload signature');
      return;
    }
    const { errors: resErrors } = await formService.thirdPartySignOff(
      instanceRevisionUid,
      {
        full_name: fullName.value,
        email: email.value,
        signature_id: cloudinaryRes.id,
        signature_url: cloudinaryRes.url,
      },
    );
    if (resErrors) {
      setErrors(resErrors);
      setLoading(false);
      return;
    }
    toast.success('Sign off successful!');
    getSignOffs();
    handleClose();
  }

  useEffect(
    () => {
      setSubmitFunc(() => handleSubmit);
    },
    [instanceRevisionUid, fullName.value, email.value],
  );

  const onClear = () => {
    // @ts-ignore
    window.$(canvasRef.current).sketch('erase');
    try {
      canvasRef!
        .current!.getContext('2d')!
        .clearRect(0, 0, canvasRef!.current!.width, canvasRef!.current!.height);
    } catch {
      //
    }
  };

  useEffect(() => {
    onClear();
    if (canvasRef && canvasRef.current && withContext) {
      // @ts-ignore
      window.$(canvasRef.current).sketch('erase');
      withContext.clearRect(
        0,
        0,
        canvasRef.current.width,
        canvasRef.current.height,
      );
    }
  }, []);

  return (
    <div>
      <Loading loading={loading} />
      <InlineInput
        label={'Email'}
        type={'email'}
        name={'Email'}
        errorMessage={errors.email}
        icon={<Envelope />}
        {...email}
      />
      <InlineInput
        label={'Full Name'}
        type={'name'}
        name={'Full Name'}
        icon={<User />}
        {...fullName}
        errorMessage={errors.full_name}
      />
      <div className="w-1/2">
        <SignatureCanvas canvasRef={canvasRef} />
      </div>
    </div>
  );
}

export default ThirdPartySignOff;

import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { SubmissionActionItem } from '../../api/submission.api';
import Avatar from '../../components/Avatar';
import moment from 'moment';
import Cog from '../../svg/Cog';
import { Link } from 'react-router-dom';
import TextArea from '../../components/TextArea';
import actionApi from '../../api/action.api';
import { debounce } from 'lodash';
import { getFullName } from '../../helpers/index';
import DropUpload from '../../components/DropUpload';
import { useSimpleFetch } from '../../helpers/hooks';
import { Storage } from '../../types';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import PrivateImage from '../../components/PrivateImage';
import Trash from '../../svg/Trash';
import styled from 'styled-components';
import { cssVars } from '../../constants/index';
import File from '../../svg/File';
import { connect } from 'react-redux';
import { StoreState } from '../../store';

const Notes = ({
  actionUid,
  initialValue,
  id,
}: {
  actionUid: string;
  initialValue: string;
  id: string;
}) => {
  const [value, setValue] = React.useState(initialValue || '');

  const updateNotes = React.useCallback(
    debounce(async (val: any) => {
      await actionApi.update(actionUid, {
        notes: val,
      });
    }, 500),
    [],
  );

  const setAndSync = (val: any) => {
    setValue(val);
    updateNotes(val);
  };

  return (
    <>
      <label htmlFor={id} className="mb-4 block">
        Completion notes:
      </label>
      <TextArea
        id={id}
        onChange={e => setAndSync(e.currentTarget.value)}
        value={value}
      />
    </>
  );
};

const Attachments = ({ actionUid }: { actionUid: string }) => {
  const uploads = useSimpleFetch<Storage[]>({
    initial: [],
    change: [actionUid],
    accessor: 'uploads',
    fetch: () => actionApi.uploads(actionUid),
  });
  const [fileToDelete, setFileToDelete] = React.useState('');

  const onDrop = async (files: File[]) => {
    if (files.length > 15) {
      return;
    }
    const data = new FormData();
    files.forEach(file => data.append('uploads', file));
    await actionApi.upload(actionUid, data);
    uploads.performFetch();
  };

  const onDeleteClick = (uid: string) => {
    setFileToDelete(uid);
  };

  const onDeleteFile = async () => {
    await actionApi.removeUpload(actionUid, fileToDelete);
    uploads.setValue(uploads.value.filter(x => x.uid !== fileToDelete));
    setFileToDelete('');
  };

  const ImgDelete = styled.div`
    background-color: ${cssVars.colors.red};
    position: absolute;
    top: -12px;
    right: -12px;
    width: 24px;
    height: 24px;
    border-radius: 50%;

    svg {
      color: #fff;
      position: absolute;
      top: 45%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    &:hover {
      cursor: pointer;
    }
  `;

  const ImgPreview = styled.div`
    position: relative;
    border: solid 2px ${cssVars.colors.grey};
    border-radius: 4px;
    min-width: 5rem;
    height: 5rem;
  `;

  const extNames = ['jpg', 'jpeg', 'png'];

  return (
    <div className="flex flex-row justify-end mx-4">
      <div
        className={
          uploads.value.length === 0
            ? 'hidden'
            : 'flex flex-row items-end overflow-x-scroll mx-2 p-2'
        }
        style={{ maxWidth: '300px' }}
      >
        {uploads.value.map(i => (
          <ImgPreview className="p-2 m-2">
            {extNames.indexOf(i.ext!) > -1 ? (
              <PrivateImage storage={i} square={'100%'} />
            ) : (
              <div className="text-center">
                <File />
                <p>.{i.ext}</p>
              </div>
            )}
            {console.log(i.ext)}
            <ImgDelete>
              <Trash
                onClick={() => onDeleteClick(i.uid)}
                width={'17px'}
                height={'17px'}
              />
            </ImgDelete>
          </ImgPreview>
        ))}
      </div>
      <DropUpload onDrop={onDrop} />
      <ConfirmationDialogue
        isOpen={!!fileToDelete}
        title="Delete File"
        body="Are you sure you wish to delete this file?"
        onConfirm={onDeleteFile}
        handleClose={() => setFileToDelete('')}
        contentLabel="Delete action file"
      />
    </div>
  );
};

interface Props extends WithNamespaces {
  optionId: number;
  actions: SubmissionActionItem[];
}

const ActionPreview = ({ optionId, actions }: Props) => {
  if (!optionId) {
    return null;
  }

  return (
    <div>
      {(actions || []).filter(x => x.action.subm_option === optionId).map(x => (
        <div className="p-4 bg-form-option-row flex-wrap flex flex-col lg:flex-row lg:items-start lg:justify-between mt-4">
          <div className="flex-1 flex flex-col sm:flex-row justify-between mb-4 lg:mb-0 lg:mr-8">
            <div className="flex flex-row items-start mb-4 sm:mb-0">
              <Avatar fallbackName="AB" user={x.user} />
              <div className="flex flex-col">
                <h3 className="mb-4">{x.user.username}</h3>
                <div className="mb-4">
                  {x.action.status !== 1 && (
                    <span className="bg-blue text-white py-2 px-4 mr-2">
                      Opened
                    </span>
                  )}
                  {x.action.urgent && (
                    <span className="bg-yellow-dark text-white py-2 px-4 mr-2">
                      Urgent
                    </span>
                  )}
                </div>
                <p className="mb-4">{x.action.description}</p>
                <div>
                  <p>
                    <strong>Corrective Action:</strong>
                  </p>
                  <p>{x.action.corrective}</p>
                </div>
              </div>
            </div>
            <div className="text-right">
              <p className="mb-4">
                Due Date:{' '}
                <strong>
                  {moment.unix(x.action.date_due).format('DD/MM/YYYY')}
                </strong>
              </p>
              <p className="mb-4">
                Created by: <strong>{getFullName(x.creator)}</strong>
              </p>
              <Link
                className="bg-default text-white no-underline inline-flex p-2 px-4 items-center"
                to={`/dashboard/worker/actions/${x.action.uid}`}
              >
                <Cog className="w-4 h-4 mr-2" /> Edit
              </Link>
            </div>
          </div>
          <div className="flex-1">
            <Notes
              actionUid={x.action.uid}
              id={`option-${optionId}-action-notes`}
              initialValue={x.action.notes || ''}
            />
          </div>
          <div style={{ width: '100%' }}>
            <Attachments actionUid={x.action.uid} />
          </div>
        </div>
      ))}
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    actions: state.form.actions,
  };
}

export default DragDropContext(HTML5Backend)(
  connect(mapStateToProps)(withNamespaces()(ActionPreview)),
);

import * as React from 'react';
import { CompanyGroup, User } from '../../../../types';
import companyGroupApi from '../../../../api/companyGroup.api';
import Avatar from '../../../../components/Avatar';
import ResourceTable from '../../../../components/ResourceTable';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import Loading from '../../../../components/Loading';

interface Props extends WithNamespaces {
  type: 'crew' | 'user' | 'company' | 'site';
  entityUid: string;
  companyGroup: CompanyGroup;
}

const Users = ({ type, entityUid, companyGroup, t }: Props) => {
  const [users, setUsers] = React.useState<User[]>([]);
  const [loading, setLoading] = React.useState(false);

  const formatQueryString = () => ({ [`${type}Uids`]: [entityUid] });

  const fetchUsers = async () => {
    setLoading(true);
    const { data, errors } = await companyGroupApi.users(
      companyGroup.uid,
      formatQueryString(),
    );

    if (data) {
      setUsers(data.users);
    }

    setLoading(false);
  };

  React.useEffect(
    () => {
      fetchUsers();
    },
    [type, entityUid],
  );

  const tableColumns = [
    {
      Header: '',
      accessor: (r: any) => r,
      id: 'avatar',
      Filter: () => <span />,
      Cell: (row: any) => (
        <Avatar
          bgColor={row.original.profile_color[0]}
          fgColor={row.original.profile_color[1]}
          fallbackName={row.original.username}
          margin={false}
          user={row.original}
        />
      ),
      maxWidth: 75,
    },
    { Header: t('common:username'), accessor: 'username' },
    { Header: t('common:firstName'), accessor: 'fname' },
    { Header: t('common:lastName'), accessor: 'lname' },
  ];

  return (
    <div className="bg-white p-4 relative">
      <Loading loading={loading} />
      <ResourceTable
        data={users}
        columns={tableColumns}
        defaultSortKey={'username'}
      />
    </div>
  );
};

export default withNamespaces()(Users);

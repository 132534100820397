import * as React from 'react';
import { FormOption } from '../../types';
import { cssVars } from '../../constants';
import styled from 'styled-components';
import {
  DragWrapper,
  HeaderActions,
  HeaderAction,
  TitleInput,
  DragHandler,
} from './optionParentStyles';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import { updateFormOption } from '../../actions/formOptions';
import { connect } from 'react-redux';
import Toggle from 'react-toggle';
import Trash from '../../svg/Trash';
import Cog from '../../svg/Cog';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getOptionMandatory, getOptionTitle } from '../../helpers/formOption';
import { getOptionText } from '../../helpers/formOption';
import formOptionApi from '../../api/formOption.api';
import { Draggable } from 'react-beautiful-dnd';

const Container = styled.div`
  display: flex;
  background: ${cssVars.colors['form-option-row']};
  margin-bottom: ${cssVars.padding[4]};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Body = styled.div`
  padding: ${cssVars.padding[4]};
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export interface RenderEditingProps {
  title: string;
  text: string;
  onTextChange: (e: React.FormEvent<HTMLInputElement>) => void;
  onTitleChange: (e: React.FormEvent<HTMLInputElement>) => void;
  stopEditing: () => void;
  cancelEditing: () => void;
  doneText: string;
  cancelText: string;
}

export interface RenderLabelProps {
  optionTitle: string;
  optionText: string;
  defaultText: string;
}

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  mandatory: boolean;
  updateFormOption?: (option: FormOption) => any;
  transformDataBeforeSave?: (state: {
    title: string;
    text: string;
  }) => { title?: string; text?: string };

  renderEditing?: (state: RenderEditingProps) => any;
  renderLabel?: (state: RenderLabelProps) => any;
  openDeleteOption?: (optionId: number) => any;
  index: number;
}

interface State {
  editing: boolean;
  title: string;
  text: string;
}

class BaseWidgetChild extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      editing: false,
      title: '',
      text: '',
    };
  }

  private startEditing = () => {
    this.setState({
      editing: true,
      title: getOptionTitle(this.props.option),
      text: getOptionText(this.props.option),
    });
  };

  private stopEditing = async () => {
    this.setState({
      editing: false,
    });

    if (!this.props.updateFormOption || !this.props.option) {
      return;
    }

    const { text, title } = this.state;

    const updateData = {
      title,
      text,
      ...(this.props.transformDataBeforeSave
        ? this.props.transformDataBeforeSave({ text, title })
        : {}),
    };

    this.props.updateFormOption({
      ...this.props.option,
      title_rev: updateData.title,
      text_rev: updateData.text,
    });

    try {
      await formOptionApi.patch(this.props.optionId, {
        ...updateData,
      });
    } catch (e) {
      //
    }
  };

  private cancelEditing = () => {
    this.setState({
      editing: false,
    });
  };

  private onTitleChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      title: e.currentTarget.value,
    });
  };

  private onTextChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      text: e.currentTarget.value,
    });
  };

  private onMandatoryChange = async (e: React.FormEvent<HTMLInputElement>) => {
    if (!this.props.updateFormOption || !this.props.option) {
      return;
    }

    const value = e.currentTarget.checked ? 1 : 0;

    this.props.updateFormOption({
      ...this.props.option,
      mandatory_rev: value,
    });

    try {
      await formOptionApi.patch(this.props.optionId, {
        mandatory: value,
      });
    } catch (e) {
      //
    }
  };

  private renderEditing = () => {
    const { title, text } = this.state;
    const { t } = this.props;

    if (this.props.renderEditing) {
      return this.props.renderEditing({
        title,
        text,
        onTextChange: this.onTextChange,
        onTitleChange: this.onTitleChange,
        cancelEditing: this.cancelEditing,
        stopEditing: this.stopEditing,
        cancelText: t('super:screens.form.cancel'),
        doneText: t('super:screens.form.done'),
      });
    }

    return (
      <React.Fragment>
        <TitleInput
          placeholder={t('super:screens.form.questionInputPlaceholder')}
          value={title}
          onChange={this.onTitleChange}
        />

        <HeaderActions>
          <HeaderAction
            className={'bg-default text-white'}
            onClick={this.cancelEditing}
          >
            {t('super:screens.form.cancel')}
          </HeaderAction>
          <HeaderAction
            className={'bg-success text-white'}
            onClick={this.stopEditing}
          >
            {t('super:screens.form.done')}
          </HeaderAction>
        </HeaderActions>
      </React.Fragment>
    );
  };

  private renderLabel = () => {
    const { option, renderLabel, mandatory, t } = this.props;
    if (!option) {
      return;
    }

    return (
      <React.Fragment>
        {renderLabel
          ? renderLabel({
              optionTitle: getOptionTitle(option),
              optionText: getOptionText(option),
              defaultText: t('super:screens.form.questionInputPlaceholder'),
            })
          : getOptionTitle(option) ||
            t('super:screens.form.questionInputPlaceholder')}
        <HeaderActions>
          {mandatory && (
            <div className={'flex items-center mx-4'}>
              <label className={'pr-3'} htmlFor={`mandatory-${option.id}`}>
                {t('super:screens.form.mandatory')}
              </label>{' '}
              <Toggle
                onChange={this.onMandatoryChange}
                checked={!!getOptionMandatory(option)}
                icons={false}
                id={`mandatory-${option.id}`}
              />
            </div>
          )}
          <HeaderAction
            className={'bg-transparent'}
            onClick={this.startEditing}
          >
            <Cog className={'fill-current w-4 h-4'} />
          </HeaderAction>
          <HeaderAction
            className={'bg-transparent'}
            onClick={this.openDeleteOption}
          >
            <Trash className={'fill-current w-4 h-4'} />
          </HeaderAction>
        </HeaderActions>
      </React.Fragment>
    );
  };

  private openDeleteOption = () => {
    if (this.props.openDeleteOption) {
      this.props.openDeleteOption(this.props.optionId);
    }
  };

  public render() {
    const { option, index } = this.props;

    if (!option) {
      return null;
    }

    return (
      <Draggable draggableId={`${option.id}`} index={index}>
        {provided => (
          <Container ref={provided.innerRef} {...provided.draggableProps}>
            <DragWrapper {...provided.dragHandleProps}>
              <DragHandler />
            </DragWrapper>
            <Body>
              {this.state.editing ? this.renderEditing() : this.renderLabel()}
            </Body>
          </Container>
        )}
      </Draggable>
    );
  }
}

function makeMapStateToProps() {
  const getOption = makeGetOption();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
  });
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateFormOption: (option: FormOption) =>
      dispatch(updateFormOption(option)),
  };
}

export default withNamespaces()(
  connect(makeMapStateToProps, mapDispatchToProps)(BaseWidgetChild),
);

import * as React from 'react';
import { Company, User, Conversation } from '../../types';
import { CompanyUserConversation } from '../../api/companyUser.api';
import moment from 'moment';
import userApi from '../../api/user.api';
import { useLongPoll } from '../../helpers/hooks';
import { orderBy } from 'natural-orderby';
import { connect } from 'react-redux';
import { setConversations } from '../../actions/me';
import { StoreState } from '../../store';

const MAX_TEXT_PREVIEW = 60;

const shortenText = (text: string) => {
  if (!text) {
    return '';
  }
  if (text.length <= MAX_TEXT_PREVIEW) {
    return text;
  }
  return `${text.substr(0, MAX_TEXT_PREVIEW)}...`;
};

interface Props {
  openMessageDetail: (conversation: Conversation) => void;
  openNewMessage: () => void;
  company: Company;
  user: User;
  setConvos: (convos: CompanyUserConversation[]) => any;
  conversations: CompanyUserConversation[];
}

const AllMessages = ({
  openMessageDetail,
  openNewMessage,
  company,
  user,
  setConvos,
  conversations,
}: Props) => {
  const fetchMessages = async () => {
    const { data } = await userApi.conversations(user.uid);
    if (data) {
      setConvos(data.conversations);
    }
  };

  useLongPoll(fetchMessages, [], 10000);

  return (
    <div className={'relative'}>
      <div className="flex flex-row items-stretch justify-between border-0 border-b-1 border-solid border-border">
        <h3 className="p-4 font-normal">Messages</h3>
        <button
          children={'New'}
          className={
            'bg-blue block text-white border-0 text-center p-4 cursor-pointer'
          }
          onClick={openNewMessage}
        />
      </div>

      <div className="flex flex-col">
        {orderBy(
          conversations,
          [
            v =>
              v.latest_message
                ? v.latest_message.created_at
                : v.conversation.created_at,
          ],
          ['desc'],
        ).map(({ conversation, sender, latest_message, unread_messages }) => (
          <div
            className={`flex flex-col p-4 border-0 border-b-1 border-border border-solid hover:bg-grey-lighter cursor-pointer ${
              unread_messages === 0 ? 'bg-white' : 'bg-blue-lightest'
            }`}
            onClick={() => openMessageDetail(conversation)}
            key={conversation.uid}
          >
            <div className={'flex flex-row items-center justify-between'}>
              <h4>{conversation.name}</h4>
              {latest_message && (
                <p className={'text-sm text-grey-darker '}>
                  {moment.unix(latest_message.created_at).fromNow()}
                </p>
              )}
            </div>
            <div className="flex flex-row justify-between items-center">
              {latest_message && (
                <p style={{ maxWidth: '75%' }}>
                  {shortenText(latest_message.text)}
                </p>
              )}
              {unread_messages > 0 && (
                <span
                  className={
                    'bg-blue w-8 h-8 rounded flex items-center justify-center text-white mt-2'
                  }
                >
                  {unread_messages}
                </span>
              )}
            </div>
          </div>
        ))}

        {/* <div className="flex flex-row p-4 items-start border-0 border-b-1 border-border border-solid bg-blue-lightest">
          <Avatar fallbackName={'BS'} />
          <div>
            <h4>Bob Safety</h4>
            <p>hello</p>
            <p className={'text-sm text-grey-darker'}>7 Days ago</p>
          </div>
        </div> */}
      </div>
    </div>
  );
};

export default connect(
  (state: StoreState) => ({
    conversations: state.me.conversations,
  }),
  dispatch => ({
    setConvos: (convos: CompanyUserConversation[]) =>
      dispatch(setConversations(convos)),
  }),
)(AllMessages);

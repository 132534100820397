import * as React from 'react';
import Sidebox, { SideboxContent } from '../../../components/Sidebox';
import SideboxHeader from '../../../components/sidebox/SideboxHeader';
import ChevronDown from '../../../svg/ChevronDown';
import { useSimpleFetch } from '../../../helpers/hooks';
import moment from 'moment';
import Loading from '../../../components/Loading';
import formService from '../../../services/forms.service';
import { useEffect, useState } from 'react';
import { orderBy } from 'natural-orderby';
import { InstanceRevision, Instance, User } from '../../../types';
import { getFullName } from '../../../helpers';
import * as R from 'ramda';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  instanceUid: string;
  deployeeUid: string;
  siteUid: string;
  formUid: string;
}

const HistorySidebox = ({
  isOpen,
  handleClose,
  siteUid,
  formUid,
  deployeeUid,
}: Props) => {
  const history = useSimpleFetch<
    Array<{
      instance: Instance;
      instance_revisions: Array<{
        instance_revision: InstanceRevision;
        submitter: User;
      }>;
    }>
  >({
    initial: [],
    change: [siteUid, formUid, deployeeUid],
    accessor: 'history',
    fetch: () =>
      formService.getDeployeeHistory({ siteUid, deployeeUid, formUid }),
  });
  const [sortedHistory, setSortedHistory] = useState<
    Array<{
      instance: Instance;
      instance_revisions: Array<{
        instance_revision: InstanceRevision;
        submitter: User;
      }>;
    }>
  >([]);
  const [loading, setLoading] = useState<boolean>(false);

  function sortHistory() {
    // sort top level list by first revision submitted_at
    // sort each instance revision list by submitted_at desc
    setLoading(true);
    setSortedHistory(
      orderBy(
        history.value.map(historyElement => ({
          ...historyElement,
          instance_revisions: orderBy(
            historyElement.instance_revisions,
            [({ instance_revision }) => instance_revision.submitted_at],
            ['desc'],
          ),
        })),
        [
          ({ instance_revisions }) => {
            const firstInstanceRevision = instance_revisions[0];
            return (
              (firstInstanceRevision &&
                firstInstanceRevision.instance_revision.submitted_at) ||
              0
            );
          },
        ],
        ['desc'],
      ),
    );
    setLoading(false);
  }

  useEffect(
    () => {
      sortHistory();
    },
    [history.value],
  );

  return (
    <Sidebox
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Submission History"
    >
      <SideboxHeader handleClose={handleClose}>History</SideboxHeader>
      <SideboxContent>
        <Loading loading={history.loading || loading} />
        {sortedHistory.length === 0 && (
          <p className={'p-4 text-center'}>No submission history</p>
        )}
        {sortedHistory.map(({ instance, instance_revisions }) => {
          const instanceRev = R.last(instance_revisions);
          if (!instanceRev) {
            return null;
          }
          return (
            <div
              className="p-4 flex flex-col"
              key={instanceRev.instance_revision.uid}
            >
              <div className="flex justify-between items-center border-0 border-b-1 border-solid border-grey">
                <p>
                  Originally submitted by {getFullName(instanceRev.submitter)}{' '}
                  on{' '}
                  {moment
                    .unix(instanceRev.instance_revision.submitted_at || 0)
                    .format('MMM Do, YYYY hh:mm a')}
                </p>
                <ChevronDown className={'w-4 h-4'} />
              </div>
              <ul className="list-reset mt-2">
                {instance_revisions.map(({ instance_revision, submitter }) => (
                  <li
                    key={instance_revision.uid}
                    className={`p-4 bg-grey-light mb-2 rounded`}
                  >
                    <a
                      href={`/dashboard/worker/formsV2/${
                        instance.uid
                      }/revisions/${instance_revision.uid}`}
                    >
                      Revision made by {getFullName(submitter)} on{' '}
                      {moment
                        .unix(instance_revision.submitted_at || 0)
                        .format('MMM Do, YYYY hh:mm a')}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          );
        })}
      </SideboxContent>
    </Sidebox>
  );
};

export default HistorySidebox;

import * as React from 'react';
import InlineSelect from '../../../components/InlineSelect';
import UserIcon from '../../../svg/User';
import { User, Company } from '../../../types';
import { UsersState } from '../../../reducers/users';
import {
  useEntityRefresh,
  useMultiSelectInput,
  useSimpleFetch,
} from '../../../helpers/hooks';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { usersFetchRequest } from '../../../actions/users';
import { Dispatch } from 'redux';
import RemovableAvatars from '../../../components/RemovableAvatars';
import checklistApi from '../../../api/checklist.api';
import { SelectOption, SelectOptionObject } from '../../../components/Select';
import Loading from '../../../components/Loading';

interface Props {
  getUsers: (companyUid: string) => any;
  users: UsersState;
  company: Company;
  checklistUid: string;
}

const ShareUsers = ({
  company,
  users: usersState,
  getUsers,
  checklistUid,
}: Props) => {
  useEntityRefresh(company.uid, usersState, getUsers);
  const [selectedUsers, setSelectedUsers] = React.useState<User[]>([]);
  const users = useSimpleFetch<User[]>({
    initial: [],
    accessor: 'users',
    fetch: () => checklistApi.shared(checklistUid),
  });

  React.useEffect(
    () => {
      setSelectedUsers(users.value);
    },
    [users.value],
  );

  const selectedUserUids = selectedUsers.map(u => u.uid);

  const userOptions = usersState.data
    .filter(u => selectedUserUids.indexOf(u.uid) === -1)
    .map(user => ({
      label: user.username,
      value: user.uid,
    }));

  const onRemove = (user: User) => {
    setSelectedUsers(selectedUsers.filter(u => u.uid !== user.uid));
    checklistApi.unshare(checklistUid, user.uid);
  };

  const onUserSelect = async (opt: SelectOptionObject) => {
    setSelectedUsers([
      ...selectedUsers,
      usersState.data.find(u => u.uid === opt.value)!,
    ]);

    checklistApi.shareWithUser(checklistUid, opt.value);
  };

  return (
    <div className={'p-4 relative'}>
      <Loading loading={users.loading} />
      {selectedUsers.length > 0 && (
        <div className="mb-4">
          <RemovableAvatars users={selectedUsers} onClick={onRemove} />
        </div>
      )}
      <InlineSelect
        options={userOptions}
        label={'Share with'}
        icon={<UserIcon />}
        menuPosition={'fixed'}
        menuPortalTarget={document.body}
        onChange={onUserSelect}
        value={null}
      />
    </div>
  );
};

export default connect(
  (store: StoreState) => ({
    users: store.users,
    company: store.me.company,
  }),
  (dispatch: Dispatch) => ({
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  }),
)(ShareUsers);

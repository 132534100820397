import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import Tabs from '../../components/Tabs';
import TrashedEntities from './trash/TrashedEntities';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Company, Form, Crew } from '../../types';
import { useSimpleFetch } from '../../helpers/hooks';
import companyApi from '../../api/company.api';
import crewApi from '../../api/crew.api';
import siteApi from '../../api/site.api';
import formApi from '../../api/form.api';
import { Dispatch } from 'redux';
import { crewsInvalidate } from '../../actions/crews';
import { sitesInvalidate } from '../../actions/sites';
import { formsInvalidate } from '../../actions/forms';
import companyUserApi from '../../api/companyUser.api';
import { usersInvalidate } from '../../actions/users';
import { getSiteLabelPlural, getCrewLabelPlural } from '../../helpers';

interface Props {
  company: Company;
  invalidateCrews: () => any;
  invalidateSites: () => any;
  invalidateForms: () => any;
  invalidateUsers: () => any;
}

const Trash = ({
  company,
  invalidateCrews,
  invalidateForms,
  invalidateSites,
  invalidateUsers,
}: Props) => {
  const forms = useSimpleFetch<Form[]>({
    initial: [],
    change: [company.uid],
    accessor: 'forms',
    fetch: () => companyApi.trashedForms(company.uid),
  });
  const sites = useSimpleFetch<Form[]>({
    initial: [],
    change: [company.uid],
    accessor: 'sites',
    fetch: () => companyApi.trashedSites(company.uid),
  });
  const crews = useSimpleFetch<Crew[]>({
    initial: [],
    change: [company.uid],
    accessor: 'crews',
    fetch: () => companyApi.trashedCrews(company.uid),
  });
  const suspendedUsers = useSimpleFetch<Crew[]>({
    initial: [],
    change: [company.uid],
    accessor: 'suspendedUsers',
    fetch: () => companyApi.suspendedUsers(company.uid),
  });

  const onCrew = () => {
    crews.performFetch();
    invalidateCrews();
  };
  const onSite = () => {
    sites.performFetch();
    invalidateSites();
  };
  const onForm = () => {
    forms.performFetch();
    invalidateForms();
  };
  const onSuspendedUser = () => {
    suspendedUsers.performFetch();
    invalidateUsers();
  };

  const tabs = [
    {
      label: 'Forms',
      component: (
        <TrashedEntities
          name={'Trashed Forms'}
          entities={forms.value}
          accessor={'name'}
          endpoint={formApi.activate}
          onRestore={onForm}
        />
      ),
    },
    {
      label: `${getSiteLabelPlural()}`,
      component: (
        <TrashedEntities
          name={`Trashed ${getSiteLabelPlural()}`}
          entities={sites.value}
          accessor={'name'}
          endpoint={siteApi.activate}
          onRestore={onSite}
        />
      ),
    },
    {
      label: `${getCrewLabelPlural()}`,
      component: (
        <TrashedEntities
          name={`Trashed ${getCrewLabelPlural()}`}
          entities={crews.value}
          accessor={'name'}
          endpoint={crewApi.activate}
          onRestore={onCrew}
        />
      ),
    },
    {
      label: 'Suspended Users',
      component: (
        <TrashedEntities
          name={'Suspended Users'}
          entities={suspendedUsers.value}
          accessor={'username'}
          endpoint={uid => companyUserApi.unsuspend(company.uid, uid)}
          onRestore={onSuspendedUser}
        />
      ),
    },
  ];

  return (
    <Page>
      <PageContent>
        <Tabs tabs={tabs} raised={true} />
      </PageContent>
    </Page>
  );
};

export default connect(
  (state: StoreState) => ({ company: state.me.company }),
  (dispatch: Dispatch) => ({
    invalidateCrews: () => dispatch(crewsInvalidate()),
    invalidateSites: () => dispatch(sitesInvalidate()),
    invalidateForms: () => dispatch(formsInvalidate()),
    invalidateUsers: () => dispatch(usersInvalidate()),
  }),
)(Trash);

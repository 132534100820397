import * as React from 'react';
import SideboxSectionHeader from '../../../../components/sidebox/SideboxSectionHeader';
import SideboxSectionContent from '../../../../components/sidebox/SideboxSectionContent';
import UserIcon from '../../../../svg/User';
import Toggle from 'react-toggle';
import Send from '../../../../svg/Send';
import InlineSelect from '../../../../components/InlineSelect';
import { User } from '../../../../types';
import { SelectOptionObject } from '../../../../components/Select';
import { WithNamespaces, withNamespaces } from 'react-i18next';

interface Props extends WithNamespaces {
  users: User[];
  sendEmails: boolean;
  selected: SelectOptionObject[];
  pdf: boolean;
  onToggle: (value: boolean) => void;
  onSelect: (users: SelectOptionObject[]) => void;
  onPdfToggle: (value: boolean) => void;
}

class SendOnSubmission extends React.Component<Props> {
  private onSendEmailsToggle = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.onToggle(event.currentTarget.checked);
  };

  private onPdfToggle = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.onPdfToggle(event.currentTarget.checked);
  };

  public render() {
    const { users, sendEmails, selected, onSelect, pdf, t } = this.props;

    const userOptions = users.map(user => ({
      label: user.username,
      value: user.uid,
    }));

    return (
      <div>
        <SideboxSectionHeader
          title={t('super:screens.site.sendEmailsTitle')}
          leftIcon={<Send />}
          rightIcon={
            <Toggle
              icons={false}
              checked={sendEmails}
              onChange={this.onSendEmailsToggle}
            />
          }
        />

        <SideboxSectionContent>
          <InlineSelect
            label={t('super:screens.site.sendEmailsAudienceLabel')}
            icon={<UserIcon />}
            options={userOptions}
            isMulti={true}
            disabled={!sendEmails}
            onChange={onSelect}
            value={selected}
            menuPortalTarget={document.body}
            menuPosition={'fixed'}
          />

          <div className={'flex flex-row justify-between items-center'}>
            <label htmlFor={'sendPdf'}>
              {t('super:screens.site.sendEmailsPdfLabel')}
            </label>
            <Toggle
              id={'sendPdf'}
              disabled={!sendEmails}
              checked={pdf}
              onChange={this.onPdfToggle}
              icons={false}
            />
          </div>
        </SideboxSectionContent>
      </div>
    );
  }
}

export default withNamespaces()(SendOnSubmission);

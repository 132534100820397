import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalBody from '../../../components/modal/ModalBody';
import ChevronUp from '../../../svg/ChevronUp';
import ChevronDown from '../../../svg/ChevronDown';
import styled from 'styled-components';
import { cssVars } from '../../../constants/index';
import Button from '../../../components/Button';

const RankButton = styled.button`
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  labels: any;
  buttons: any;
  state: any;
  onUp: (rank: string) => any;
  onDown: (rank: string) => any;
}

const MobileObservationModal = ({
  isOpen,
  handleClose,
  labels,
  buttons,
  state,
  onUp,
  onDown,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Mark observations"
    >
      <ModalBody>
        <div className="flex flex-col">
          <div className="flex flex-row justify-between items-center mb-4">
            <RankButton className="bg-grey" onClick={() => onDown('positive')}>
              <ChevronDown className="w-6 h-6" />
            </RankButton>
            <p className="flex-1 text-center">Positive ({state.positive})</p>
            <RankButton className="bg-green" onClick={() => onUp('positive')}>
              <ChevronUp className="w-6 h-6" />
            </RankButton>
          </div>
          {Object.keys(buttons).map(x => (
            <div
              className="flex flex-row justify-between items-center mb-4"
              key={x}
            >
              <RankButton className="bg-grey" onClick={() => onDown(x)}>
                <ChevronDown className="w-6 h-6" />
              </RankButton>
              <p className="flex-1 text-center">
                {labels[x]} ({state[x]})
              </p>
              <RankButton
                className={`bg-${buttons[x]}`}
                onClick={() => onUp(x)}
              >
                <ChevronUp className="w-6 h-6" />
              </RankButton>
            </div>
          ))}
          <Button type="default" onClick={handleClose}>
            Save
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default MobileObservationModal;

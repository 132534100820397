import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Company, Site } from '../../types';
import moment from 'moment';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Button from '../../components/Button';
import SiteCreate from './sites/SiteCreate';
import { History } from 'history';
import ResourceTable from '../../components/ResourceTable';
import PageHeading from '../../components/PageHeading';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { Dispatch } from 'redux';
import { addSite, sitesFetchRequest } from '../../actions/sites';
import { SitesState } from '../../reducers/sites';
import { useMountVisibility, useEntityRefresh } from '../../helpers/hooks';
import {
  getDivisionLabel,
  getSiteLabelPlural,
  getSiteLabel,
} from '../../helpers';

interface Props extends WithNamespaces {
  company: Company;
  history: History;
  sites: SitesState;
  addSite: (site: Site) => any;
  getSites: (companyUid: string) => any;
}

const Sites = ({
  company,
  sites,
  getSites,
  history,
  addSite: pAddSite,
  t,
}: Props) => {
  const createState = useMountVisibility();
  useEntityRefresh(company.uid, sites, getSites);

  const onSiteCreated = (site: Site) => {
    createState.close();
    pAddSite(site);
    history.push(`/dashboard/super/sites/${site.uid}`);
  };

  const tableColumns = [
    {
      Header: t('super:name'),
      accessor: 'name',
    },
    {
      Header: t('super:created'),
      accessor: (p: Site) => moment(p.created_at * 1000).format('YYYY-MM-DD'),
      id: 'created_at',
    },
  ];

  return (
    <Page>
      <PageContent>
        <PageHeading
          title={`${getDivisionLabel()} Active ${getSiteLabelPlural()}`}
          subtitle={`${sites.data.length} available`}
          renderRight={() => (
            <Button type={'primary'} onClick={createState.open}>
              {`Create ${getSiteLabel()}`}
            </Button>
          )}
        />

        <ResourceTable
          data={sites.data}
          columns={tableColumns}
          defaultSortKey={'name'}
          loading={sites.loading}
          onRowClick={site =>
            history.push(`/dashboard/super/sites/${site.uid}`)
          }
          fetched={sites.loaded}
          emptyTitle={`You haven't created any sites`}
          emptyMessage={`Sites help you organize your form submissions and restrict access to only assigned crews. Click "Create Site" in the top right to get started.`}
        />
      </PageContent>

      <SiteCreate
        onCreate={onSiteCreated}
        handleClose={createState.close}
        isOpen={createState.visible}
      />
    </Page>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    sites: state.sites,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    addSite: (site: Site) => dispatch(addSite(site)),
    getSites: (companyUid: string) => dispatch(sitesFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Sites),
);

import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import { Company, Crew, User as IUser } from '../../../types';
import InlineInput from '../../../components/InlineInput';
import User from '../../../svg/User';
import Envelope from '../../../svg/Envelope';
import Lock from '../../../svg/Lock';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import SideboxSectionHeader from '../../../components/sidebox/SideboxSectionHeader';
import SideboxSectionContent from '../../../components/sidebox/SideboxSectionContent';
import InlineSelect from '../../../components/InlineSelect';
import Trophy from '../../../svg/Trophy';
import UserGroup from '../../../svg/UserGroup';
import Button from '../../../components/Button';
import companyApi from '../../../api/company.api';
import { toast } from 'react-toastify';
import * as R from 'ramda';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import { SelectOption, SelectOptionObject } from '../../../components/Select';
import crewApi from '../../../api/crew.api';
import { Dispatch } from 'redux';
import { crewsFetchRequest } from '../../../actions/crews';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getCrewLabelPlural } from '../../../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  onCreate: (user: IUser) => void;
  crews: Crew[];
  crewsLoading: boolean;
  crewsLoaded: boolean;
  getCrews: (companyUid: string) => any;
}

interface State {
  username: string;
  password: string;
  email: string;
  role: SelectOption;
  selectedCrews: SelectOptionObject[];
  errors: {
    username?: string;
    password?: string;
    email?: string;
  };
  loadingSubmit: boolean;
}

class UserCreate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      username: '',
      password: '',
      email: '',
      role: null,
      selectedCrews: [],
      errors: {},
      loadingSubmit: false,
    };
  }

  public componentDidMount() {
    if (!this.props.crewsLoaded) {
      this.props.getCrews(this.props.company.uid);
    }
  }

  private submit = async (
    e: React.FormEvent<HTMLFormElement | HTMLButtonElement>,
  ) => {
    e.preventDefault();

    const { username, email, password, selectedCrews, role } = this.state;

    let user: IUser | null = null;

    this.setState({
      loadingSubmit: true,
    });

    try {
      const response = await companyApi.createUser(this.props.company.uid, {
        username,
        email,
        password,
        role: role ? role.value : undefined,
      });

      user = response.user;
    } catch (e) {
      this.setState({
        errors: e.response.data.errors,
      });
      toast.error(this.props.t('super:screens.users.createErrorMessage'));
    }

    if (!user) {
      this.setState({
        loadingSubmit: false,
      });
      return;
    }

    try {
      await Promise.all(
        selectedCrews.map(
          async selection =>
            await crewApi.attachUser(selection.value, user!.uid),
        ),
      );
    } catch (e) {
      toast.error(
        `Failed to attach user to ${getCrewLabelPlural().toLowerCase()}`,
      );
    }

    this.setState({
      loadingSubmit: false,
      username: '',
      email: '',
      password: '',
      role: null,
      selectedCrews: [],
    });

    this.props.onCreate(user);
  };

  private isFormValid = () => {
    return (
      this.state.username !== '' &&
      this.state.password !== '' &&
      this.state.role !== null
    );
  };

  private onUsernameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      username: e.currentTarget.value,
    });
  };

  private onPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      password: e.currentTarget.value,
    });
  };

  private onEmailChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      email: e.currentTarget.value,
    });
  };

  private onRoleChange = (role: SelectOptionObject) => {
    this.setState({
      role,
    });
  };

  private onCrewSelect = (selectedCrews: SelectOptionObject[]) => {
    this.setState({
      selectedCrews,
    });
  };

  public render() {
    const { isOpen, handleClose, crews, t } = this.props;
    const {
      username,
      password,
      email,
      role,
      errors,
      selectedCrews,
      loadingSubmit,
    } = this.state;

    const crewOptions = R.map(c => ({ label: c.name, value: c.uid }), crews);

    const roleOptions = [
      { label: t('common:companyRoles.employee'), value: 'employee' },
      { label: t('common:companyRoles.trainee'), value: 'trainee' },
      { label: t('common:companyRoles.super'), value: 'super' },
    ];

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={t('super:screens.users.createUserTitle')}
        handleClose={handleClose}
      >
        <ModalHeader handleClose={handleClose}>
          {t('super:screens.users.createUserTitle')}
        </ModalHeader>

        <ModalBody>
          <SideboxSectionHeader
            leftIcon={<User />}
            title={t('super:screens.users.userDetailsTitle')}
          />

          <SideboxSectionContent>
            <InlineInput
              label={t('super:screens.users.username')}
              icon={<User />}
              value={username}
              onChange={this.onUsernameChange}
              errorMessage={errors.username}
            />
            <InlineInput
              label={t('super:screens.users.email')}
              icon={<Envelope />}
              value={email}
              onChange={this.onEmailChange}
              errorMessage={errors.email}
            />
            <InlineInput
              label={t('super:screens.users.password')}
              icon={<Lock />}
              value={password}
              onChange={this.onPasswordChange}
              errorMessage={errors.password}
              inputProps={{ type: 'password' }}
            />
            <InlineSelect
              options={roleOptions}
              label={t('super:screens.users.role')}
              icon={<Trophy />}
              value={role}
              onChange={this.onRoleChange}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
            />
          </SideboxSectionContent>

          <SideboxSectionHeader
            leftIcon={<UserGroup />}
            title={getCrewLabelPlural()}
          />

          <SideboxSectionContent>
            <InlineSelect
              options={crewOptions}
              label={`${getCrewLabelPlural()}`}
              icon={<UserGroup />}
              menuPortalTarget={document.body}
              menuPosition={'fixed'}
              isMulti={true}
              value={selectedCrews}
              onChange={this.onCrewSelect}
            />
          </SideboxSectionContent>
        </ModalBody>
        <ModalFooter right={true}>
          <Button
            type={'primary'}
            disabled={!this.isFormValid()}
            onClick={this.submit}
            loading={loadingSubmit}
          >
            {t('super:screens.users.create')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    crews: state.crews.data,
    crewsLoading: state.crews.loading,
    crewsLoaded: state.crews.loaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserCreate),
);

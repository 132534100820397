import * as React from 'react';
import ChevronDown from '../../../svg/ChevronDown';
import Forms from './Forms';
import Avatar from '../../../components/Avatar';
import { useTextInput } from '../../../helpers/hooks';
import Search from '../../../svg/Search';
import { orderBy } from 'natural-orderby';
import { getFullName } from '../../../helpers/index';
import { UserFormOnSite } from '../../../api/site.api';

interface Props {
  forms: UserFormOnSite[];
}

const UserForm = ({ forms, user }: any) => {
  const [open, setOpen] = React.useState(false);

  return (
    <div
      className="flex flex-col border-1 border-solid border-grey mb-4"
      key={user.uid}
    >
      <div
        className={
          'flex flex-row justify-between items-center bg-grey hover:bg-grey-light p-4 cursor-pointer'
        }
        onClick={() => setOpen(!open)}
      >
        <div className="flex-1 flex flex-row items-center">
          <Avatar
            fallbackName={user.username}
            fgColor={user.profile_color ? user.profile_color[1] : undefined}
            bgColor={user.profile_color ? user.profile_color[0] : undefined}
            user={user}
          />
          <h3 className={'font-normal'}>{getFullName(user)}</h3>
        </div>
        <ChevronDown className={'w-6 h-6'} />
      </div>
      {open && (
        <div className="p-4">
          <Forms forms={forms} deployee={user} />
        </div>
      )}
    </div>
  );
};

const UserForms = ({ forms }: Props) => {
  const search = useTextInput('');

  return (
    <div>
      <div className="relative">
        <Search
          className={'w-6 h-6 absolute'}
          style={{ top: '50%', left: '1rem', transform: 'translateY(-50%)' }}
        />
        <input
          className={'p-4 w-full'}
          placeholder={'Search users...'}
          style={{ paddingLeft: '3rem', border: `1px solid #eee` }}
          {...search}
        />
      </div>
      <div className="bg-white p-4">
        {orderBy(forms, [x => x.user.username], ['asc']).map(form => (
          <UserForm key={form.user.uid} forms={form.forms} user={form.user} />
        ))}
      </div>
    </div>
  );
};

export default UserForms;

import * as React from 'react';
import Button from '../../../components/Button';
import { History } from 'history';
import { useMountVisibility } from '../../../helpers/hooks';
import PickDraft from '../site/PickDraft';
import moment from 'moment';
import { InstanceMeta } from '../../../types';

interface Props {
  history: History;
  instanceUid: string;
  deployeeUid: string;
  instanceRevisionUid?: string;
  submitter: string;
  submitted_at: number;
  instanceMeta: InstanceMeta;
}

const RevisionHeader = ({
  history,
  instanceUid,
  deployeeUid,
  instanceRevisionUid,
  submitter,
  submitted_at,
  instanceMeta,
}: Props) => {
  const draftModal = useMountVisibility();

  const onFreshForm = async () => {
    draftModal.open();
  };

  if (!instanceRevisionUid) {
    return null;
  }
  return (
    <div className="flex flex-row justify-between mb-4 border-0 border-l-4 border-solid border-yellow-dark bg-yellow-lighter p-4">
      <div>
        <p className="text-yellow-darkest mb-4 font-bold">
          You're viewing a revision of a submission.
        </p>
        <div>
          <Button
            type="default"
            className={'mb-2 sm:mr-4'}
            onClick={() => {
              history.push(`/dashboard/worker/formsV2/${instanceUid}`);
            }}
          >
            Edit this submission
          </Button>
          <Button
            type="red"
            onClick={onFreshForm}
            className={'no-underline text-red'}
          >
            Fill out a fresh form
          </Button>
        </div>
        {draftModal.mounted && (
          <PickDraft
            isOpen={draftModal.mounted}
            handleClose={draftModal.close}
            deployeeUid={deployeeUid}
            history={history}
            formName={instanceMeta.form.name}
            siteUid={instanceMeta.site.uid}
            formUid={instanceMeta.form.uid}
          />
        )}
      </div>
      {submitter &&
        submitted_at && (
          <div className="flex flex-col justify-center">
            <p>Submitted by: {submitter}</p>
            <i>{moment.unix(submitted_at).format('MMM D, YYYY hh:mm a')}</i>
          </div>
        )}
    </div>
  );
};
export default RevisionHeader;

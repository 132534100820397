import { createAction } from 'typesafe-actions';
import { FormsOnSite } from '../api/site.api';
import {
  WORKER_SITE_FORMS_FETCH_REQUEST,
  WORKER_SITE_FORMS_FETCH_SUCCESS,
  WORKER_SITE_FORMS_FETCH_FAILURE,
} from '../constants/actionTypes';
import { PermissionsError } from '../types';

export const siteFormsFetchRequest = createAction(
  WORKER_SITE_FORMS_FETCH_REQUEST,
  resolve => (params: { siteUid: string; userUid: string }) => resolve(params),
);

export const siteFormsFetchSuccess = createAction(
  WORKER_SITE_FORMS_FETCH_SUCCESS,
  resolve => (forms: FormsOnSite) => resolve(forms),
);

export const siteFormsFetchFailure = createAction(
  WORKER_SITE_FORMS_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

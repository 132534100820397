import { call, takeLatest, put } from 'redux-saga/effects';
import companyGroupApi from '../../api/companyGroup.api';
import { COMPANY_GROUP_COMPANIES_FETCH_REQUEST } from '../../constants/actionTypes';
import {
  companyGroupCompaniesFetchFailure,
  companyGroupCompaniesFetchSuccess,
} from '../../actions/companyGroup/companies';

export function* getCompanies(params: any) {
  const { data, errors } = yield call(
    companyGroupApi.companies,
    params.payload,
  );
  if (errors) {
    yield put(companyGroupCompaniesFetchFailure(errors));
    return;
  }

  yield put(companyGroupCompaniesFetchSuccess(data.companies));
}

export function* watchGetCompanyGroupCompanies() {
  yield takeLatest(COMPANY_GROUP_COMPANIES_FETCH_REQUEST, getCompanies);
}

import * as React from 'react';
import SideboxSectionHeader from '../../../../components/sidebox/SideboxSectionHeader';
import ThumbsUp from '../../../../svg/ThumbsUp';
import Toggle from 'react-toggle';
import SideboxSectionContent from '../../../../components/sidebox/SideboxSectionContent';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props extends WithNamespaces {
  value: boolean;
  onChange: (value: boolean) => void;
}

class Approval extends React.Component<Props> {
  private onApprovalToggle = (event: React.FormEvent<HTMLInputElement>) => {
    this.props.onChange(event.currentTarget.checked);
  };

  public render() {
    const { value, t } = this.props;

    return (
      <div>
        <SideboxSectionHeader
          title={t('super:screens.site.approval')}
          leftIcon={<ThumbsUp />}
          rightIcon={
            <Toggle
              icons={false}
              checked={value}
              onChange={this.onApprovalToggle}
            />
          }
        />

        <SideboxSectionContent>
          <p>
            {value
              ? t('super:screens.site.needsApprovalText')
              : t('super:screens.site.noApprovalText')}
          </p>
        </SideboxSectionContent>
      </div>
    );
  }
}

export default withNamespaces()(Approval);

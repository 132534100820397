import api, { api2 } from './api';
import { Company } from '../types';
import { entitiesApi } from '../../src/services/entities.service';

interface CrewCreateBody {
  name: string;
}

const crewApi = {
  create: (companyUid: string, data: CrewCreateBody) =>
    api('POST', `companies/${companyUid}/crews`, data),
  update: (crewUid: string, data: CrewCreateBody) =>
    api('PATCH', `crews/${crewUid}`, data),
  sites: (crewUid: string) => api('GET', `crews/${crewUid}/sites`),
  users: (crewUid: string) => api('GET', `crews/${crewUid}/users`),
  companies: (crewUid: string) =>
    api2<{ companies: Company[] }>('GET', `crews/${crewUid}/companies`),
  attachCompany: (crewUid: string, companyUid: string) =>
    entitiesApi('PUT', `crews/${crewUid}/companies/${companyUid}`),
  detachCompany: (companyUid: string, crewUid: string) =>
    api2('DELETE', `/companies/${companyUid}/crews/${crewUid}`),
  removeUser: (crewUid: string, userUid: string) =>
    api('DELETE', `crews/${crewUid}/users/${userUid}`),
  attachUser: (crewUid: string, userUid: string, role: string = 'worker') =>
    api('PUT', `crews/${crewUid}/users/${userUid}`, { role }),
  deactivate: (crewUid: string) => api2('POST', `crews/${crewUid}/deactivate`),
  activate: (crewUid: string) => api2('POST', `crews/${crewUid}/activate`),
};

export default crewApi;

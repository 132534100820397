import * as React from 'react';
import { Action, CompanyGroup } from '../../../../types';
import ActionItem from '../../../../components/ActionItem';
import companyGroupApi from '../../../../api/companyGroup.api';
import Loading from '../../../../components/Loading';

interface Props {
  type: 'crew' | 'user' | 'company' | 'site';
  entityUid: string;
  companyGroup: CompanyGroup;
}

const Actions = ({ type, entityUid, companyGroup }: Props) => {
  const [actions, setActions] = React.useState<Action[]>([]);
  const [loading, setLoading] = React.useState(false);

  const formatQueryString = () => ({ [`${type}Uids`]: [entityUid] });

  const fetchActions = async () => {
    setLoading(true);
    const { data, errors } = await companyGroupApi.actions(
      companyGroup.uid,
      formatQueryString(),
    );

    if (data) {
      setActions(data.actions);
    }

    setLoading(false);
  };

  React.useEffect(
    () => {
      fetchActions();
    },
    [type, entityUid],
  );

  return (
    <div className="bg-white p-4 relative">
      <Loading loading={loading} />
      {actions.map(action => (
        <ActionItem action={action} />
      ))}
    </div>
  );
};

export default Actions;

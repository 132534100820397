import * as React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import {
  Feed,
  SubmitFormFeed,
  ReviewFormFeed,
  SentFormFeed,
  SubmitWorkingCopyFeed,
  FeedType,
  DocumentSignedOffFeed,
  Company,
  CompanyGroup,
} from '../../../types';
import moment from 'moment';
import Envelope from '../../../svg/Envelope';
import Checkmark from '../../../svg/Checkmark';
import File from '../../../svg/File';
import Warning from '../../../svg/Warning';
import { useTextInput } from '../../../helpers/hooks';
import NewSubmissionText from '../../../components/NewSubmissionText';
import CloseOutline from '../../../svg/CloseOutline';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { unidashUrl } from '../../../helpers';

interface FeedItemProps {
  link?: string;
  Icon: any;
  iconColor: string;
  text: JSX.Element;
  feed: Feed;
}

const Wrapper = styled.div`
  -webkit-overflow-scrolling: touch;
`;

function checkFeed<T extends Feed>(feed: Feed, feedType: FeedType): feed is T {
  return feed.type === feedType;
}

function getFeedProps(feed: Feed): null | FeedItemProps {
  if (checkFeed<DocumentSignedOffFeed>(feed, 'document_signed_off')) {
    return {
      Icon: File,
      iconColor: 'white',
      text: (
        <>
          <strong>{feed.sender_name}</strong> has signed off on the document{' '}
          <strong>{feed.document_name}</strong>
        </>
      ),
      feed,
      link: unidashUrl(`/documents/${feed.document_uid}/analytics`) || '',
      external: true,
    };
  }
  if (checkFeed<SubmitWorkingCopyFeed>(feed, 'submit_working_copy')) {
    return {
      link: `/dashboard/worker/formsV2/${feed.instance_uid}/revisions/${
        feed.instance_revision_uid
      }`,
      Icon: File,
      iconColor: 'white',
      text: (
        <NewSubmissionText
          user={feed.submitter_name}
          form={feed.form_name}
          site={feed.site_name}
          deployeeType={feed.deployee_type}
          deployee={feed.deployee_name}
        />
      ),
      feed,
    };
  }

  const forText =
    feed.site !== feed.deploy ? (
      <>
        {' '}
        for <strong>{feed.deploy}</strong>
      </>
    ) : null;
  if (checkFeed<SubmitFormFeed>(feed, 'submit_form')) {
    return {
      link: `/dashboard/worker/forms/${feed.fc_id}/submission/${feed.subm_id}${
        feed.rev_id ? `/revision/${feed.rev_id}` : ''
      }`,
      Icon: feed.approval ? Warning : File,
      iconColor: feed.approval ? 'yellow' : 'white',
      text: (
        <NewSubmissionText
          user={feed.user}
          form={feed.form}
          site={feed.site}
          deployee={feed.deploy}
        />
      ),
      feed,
    };
  }
  if (checkFeed<SentFormFeed>(feed, 'sent_form')) {
    return {
      link: `/dashboard/worker/forms/${feed.fc_id}/submission/${feed.subm_id}`,
      Icon: Envelope,
      iconColor: 'white',
      text: (
        <>
          <strong>{feed.user}</strong> sent a <strong>{feed.form}</strong>{' '}
          submission from <strong>{feed.site}</strong>
          {forText}.
        </>
      ),
      feed,
    };
  }
  if (
    checkFeed<ReviewFormFeed>(feed, 'approve_form') ||
    checkFeed<ReviewFormFeed>(feed, 'reject_form')
  ) {
    return {
      link: `/dashboard/worker/forms/${feed.fc_id}/submission/${feed.subm_id}`,
      Icon: feed.type === 'approve_form' ? Checkmark : CloseOutline,
      iconColor: feed.type === 'approve_form' ? 'green' : 'red',
      text: (
        <>
          <strong>{feed.user}</strong>{' '}
          {feed.type === 'approve_form' ? 'approved' : 'rejected'} a form
          submission <strong>{feed.form}</strong> to{' '}
          <strong>{feed.site}</strong>
          {forText}.
        </>
      ),
      feed,
    };
  }

  return null;
}

const FeedItem = ({
  link,
  Icon,
  iconColor,
  text,
  feed,
  external,
}: FeedItemProps) => {
  const El = external ? 'a' : Link;
  const className =
    'flex flex-row items-center p-4 border-0 border-b-1 border-solid border-grey-darker text-white no-underline hover:bg-default';
  const props = external
    ? { href: link, className, target: '_blank' }
    : { to: link || '/', href: link || '/', className };
  return React.createElement(El, props, [
    <Icon className={`w-6 h-6 text-${iconColor}`} />,
    <div className={'pl-4'}>
      <h4 className="font-normal">{text}</h4>
      <p className="text-sm text-grey-darker">
        {moment.unix(feed.created_at).fromNow()}
      </p>
    </div>,
  ]);
};

interface Props {
  className?: string;
  feed: Feed[];
  company: Company;
  companyGroup: CompanyGroup;
}

const Newsfeed = ({ className, feed, company, companyGroup }: Props) => {
  const search = useTextInput();

  const filteredFeed = feed.filter(feedItem => {
    if (!search.value) {
      return true;
    }
    for (const field of ['user', 'form', 'deploy', 'site', 'type']) {
      if (
        feedItem[field] &&
        typeof feedItem[field] === 'string' &&
        feedItem[field].toLowerCase().indexOf(search.value.toLowerCase()) !== -1
      ) {
        return true;
      }
    }
    return false;
  });

  return (
    <Wrapper className={classNames('bg-grey-darkest text-white', className)}>
      <div className="flex flex-row border-0 border-b-1 border-solid border-grey-dark">
        <div className="w-1/4 p-4">
          <h3 className={'font-normal'}>News</h3>
        </div>
        <div className="flex-1">
          <input
            type="text"
            className={'p-4 w-full h-full border-0 text-white'}
            placeholder={'Search...'}
            style={{ background: 'rgba(255,255,255,0.1)' }}
            {...search}
          />
        </div>
      </div>
      <div className="flex flex-col">
        {filteredFeed.map(feedItem => {
          const props = getFeedProps(feedItem);
          if (props === null) {
            return null;
          }
          return <FeedItem key={feedItem.uid} feed={feedItem} {...props} />;
        })}
      </div>
    </Wrapper>
  );
};

export default connect((state: StoreState) => ({
  company: state.me.company,
  companyGroup: state.me.companyGroup,
}))(Newsfeed);

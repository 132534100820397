import * as React from 'react';
import PageHeading from '../../../components/PageHeading';
import Button from '../../../components/Button';
import { Site, User, Company } from '../../../types';
import siteApi from '../../../api/site.api';
import { toast } from 'react-toastify';
import ResourceTable from '../../../components/ResourceTable';
import Avatar from '../../../components/Avatar';
import AddManagers from './AddManagers';
import UserCreate from '../users/UserCreate';
import { connect } from 'react-redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import UserSidebox from '../../../containers/UserSidebox';
import { useMountVisibility } from '../../../helpers/hooks';
import Trash from '../../../svg/Trash';
import { StoreState } from '../../../store';
import { getSiteLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  site: Site;
  managers: User[];
  setSiteManagers: (users: User[]) => any;
  addSiteManager: (user: User) => any;
  removeSiteManager: (userUid: string) => any;
  company: Company;
  fetched: boolean;
  loading: boolean;
}

const Managers = ({
  site,
  managers,
  t,
  company,
  fetched,
  loading,
  ...rest
}: Props) => {
  const addManagerState = useMountVisibility();
  const createState = useMountVisibility();
  const detailState = useMountVisibility();
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  const afterManagerAdded = (user: User) => {
    addManagerState.close();

    rest.addSiteManager(user);
  };

  const afterManagerCreate = async (user: User) => {
    createState.close();

    try {
      await siteApi.attachManager(site.uid, user.uid);
    } catch (e) {
      toast.error(t('super:screens.site.addManagerErrorMessage'));
    }

    rest.addSiteManager(user);
  };

  const selectUser = (row: any) => {
    setSelectedUser(row);
    detailState.open();
  };

  const revokeManager = async () => {
    if (selectedUser) {
      rest.removeSiteManager(selectedUser.uid);
      siteApi.removeManager(site.uid, selectedUser.uid);
    }
    detailState.close();
  };

  const tableColumns = [
    {
      Header: '',
      accessor: (r: any) => r,
      id: 'avatar',
      Filter: () => <span />,
      Cell: (row: any) => (
        <Avatar
          bgColor={row.original.profile_color[0]}
          fgColor={row.original.profile_color[1]}
          fallbackName={row.original.username}
          margin={false}
          user={row.original}
        />
      ),
      maxWidth: 75,
    },
    { Header: t('common:username'), accessor: 'username' },
    { Header: t('common:firstName'), accessor: 'fname' },
    { Header: t('common:lastName'), accessor: 'lname' },
  ];

  return (
    <div>
      <PageHeading
        title={`${getSiteLabel()} Managers`}
        renderRight={() => (
          <React.Fragment>
            <Button
              type={'default'}
              onClick={addManagerState.open}
              className={'mr-3'}
            >
              {`Add ${getSiteLabel()} Manager`}
            </Button>
            <Button type={'primary'} onClick={createState.open}>
              {`Create ${getSiteLabel()} Manager`}
            </Button>
          </React.Fragment>
        )}
      />

      <ResourceTable
        defaultSortKey={'username'}
        columns={tableColumns}
        data={managers}
        onRowClick={selectUser}
        loading={loading}
        fetched={fetched}
        emptyTitle={`No Managers On This ${getSiteLabel()}`}
        emptyMessage={`${getSiteLabel()} managers can view all form submissions and activity`}
      />

      <AddManagers
        site={site}
        isOpen={addManagerState.visible}
        handleClose={addManagerState.close}
        afterSubmit={afterManagerAdded}
        managersOnSite={managers}
      />
      <UserCreate
        isOpen={createState.visible}
        handleClose={createState.close}
        onCreate={afterManagerCreate}
      />
      {selectedUser && (
        <UserSidebox
          isOpen={detailState.visible}
          handleClose={detailState.close}
          user={selectedUser}
          company={company}
          buttons={[
            { Icon: Trash, label: 'Revoke manager', onClick: revokeManager },
          ]}
        />
      )}
    </div>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
  };
}

export default withNamespaces()(connect(mapStateToProps)(Managers));

import { cssVars, mediaQueries } from '../constants/index';

export const biggerThanSM = (css: string) => {
  return `@media screen and (min-width: ${mediaQueries.SM}px) {
    ${css}
  }`;
};

export const biggerThanMD = (css: string) => {
  return `@media screen and (min-width: ${mediaQueries.MD}px) {
    ${css}
  }`;
};

export const biggerThanLG = (css: string) => {
  return `@media screen and (min-width: ${mediaQueries.LG}px) {
    ${css}
  }`;
};

export const boxShadow = `box-shadow: -1px 4px 10px 3px rgba(0, 0, 0, 0.1);`;

export const mandatoryErrorStyles: any = {
  borderColor: cssVars.colors.primary,
  borderWidth: 2,
  borderStyle: 'solid',
  padding: '1rem',
};

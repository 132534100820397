import * as React from 'react';
import styled from 'styled-components';
import InlineInputBase, {
  Props as InlineInputBaseProps,
} from './InlineInputBase';
import { cssVars } from '../constants';

const TextInput = styled.input`
  flex: 1;
  padding: ${cssVars.padding[3]};
  outline: none;
  border: none;

  &:disabled {
    background: ${cssVars.colors['grey-light']};
  }
`;

interface Props extends InlineInputBaseProps {
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  value?: string;
  labelSize?: number;
  disabled?: boolean;
  type?: string;
  name?: string;
  maxLength?: number;
}

export default class InlineInput extends React.Component<Props, {}> {
  public static defaultProps = {
    inputProps: {},
  };

  public render() {
    const {
      icon,
      label,
      inputProps,
      errorMessage,
      value,
      onChange,
      labelSize,
      disabled,
      margin,
      type,
      name,
      maxLength,
    } = this.props;

    return (
      <InlineInputBase
        icon={icon}
        label={label}
        errorMessage={errorMessage}
        id={inputProps && inputProps.id}
        labelSize={labelSize}
        margin={margin}
      >
        <TextInput
          type={type || 'text'}
          value={value}
          onChange={onChange}
          disabled={disabled}
          name={name}
          maxLength={maxLength}
          tabIndex={inputProps && inputProps.tabIndex}
          {...inputProps}
        />
      </InlineInputBase>
    );
  }
}

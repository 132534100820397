import * as React from 'react';
import InlineSelect from '../../components/InlineSelect';
import Button from '../../components/Button';
import UserGroup from '../../svg/UserGroup';
import Cog from '../../svg/Cog';
import { Site, Crew, User, Company, WorkRole, Conversation } from '../../types';
import {
  useRequiredSelectInput,
  useMultiSelectInput,
  useTextInput,
  useOptionalSelectInput,
} from '../../helpers/hooks';
import UserIcon from '../../svg/User';
import Location from '../../svg/Location';
import companyApi from '../../api/company.api';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import Apartment from '../../svg/Apartment';
import { toast } from 'react-toastify';
import classNames from 'classnames';
import { getCrewLabel, getSiteLabel } from '../../helpers';

interface Props {
  openAllMessages: () => void;
  openMessageDetail: (conversation: Conversation) => void;
  company: Company;
  companies: Company[];
}

const NewMessage = ({
  openAllMessages,
  openMessageDetail,
  companies,
}: Props) => {
  const audienceOptions = () => [
    { label: 'Company', value: 'company' },
    { label: getSiteLabel(), value: 'site' },
    { label: getCrewLabel(), value: 'crew' },
    { label: 'Users', value: 'user' },
  ];

  const sendToOptions = () => [
    { label: 'All', value: 'all' },
    { label: 'Custom', value: 'custom' },
  ];

  const companyOptions = () =>
    companies.map(c => ({
      label: c.name,
      value: c.uid,
    }));

  const audience = useRequiredSelectInput(audienceOptions()[0]);
  const sendTo = useRequiredSelectInput(sendToOptions()[0]);
  const company = useRequiredSelectInput(companyOptions()[0]);
  const selectedAudience = useMultiSelectInput();
  const workRoles = useMultiSelectInput();
  const [allSites, setAllSites] = React.useState<Site[]>([]);
  const [allCrews, setAllCrews] = React.useState<Crew[]>([]);
  const [allUsers, setAllUsers] = React.useState<User[]>([]);
  const [allWorkRoles, setAllWorkRoles] = React.useState<WorkRole[]>([]);
  const message = useTextInput();
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState({});

  const siteOptions = () =>
    allSites.map(s => ({
      label: s.name,
      value: s.uid,
    }));
  const crewOptions = () =>
    allCrews.map(c => ({
      label: c.name,
      value: c.uid,
    }));
  const userOptions = () =>
    allUsers.map(u => ({
      label: u.username,
      value: u.uid,
    }));
  const workRoleOptions = () =>
    allWorkRoles.map(x => ({ label: x.name, value: x.uid }));

  React.useEffect(
    () => {
      selectedAudience.onChange([]);
    },
    [audience.selected, sendTo.selected],
  );

  const fetchSites = async () => {
    const { data } = await companyApi.sites(company.selected);
    if (data) {
      setAllSites(data.sites);
    }
  };
  const fetchCrews = async () => {
    const { data } = await companyApi.crews(company.selected);
    if (data) {
      setAllCrews(data.crews);
    }
  };
  const fetchUsers = async () => {
    const { data } = await companyApi.users(company.selected);
    if (data) {
      setAllUsers(data.users);
    }
  };
  const fetchWorkRoles = async () => {
    const { data } = await companyApi.workRoles(company.selected);
    if (data) {
      setAllWorkRoles(data.workRoles);
    }
  };

  React.useEffect(
    () => {
      // get users, sites, crews
      if (company.selected) {
        fetchSites();
        fetchCrews();
        fetchUsers();
        fetchWorkRoles();
      }
    },
    [company.selected],
  );

  const submit = async () => {
    if (message.value.length === 0) {
      return;
    }
    setLoading(true);
    const { data, errors: resErrors } = await companyApi.createConversation(
      company.selected,
      {
        all: audience.selected === 'company' ? true : sendTo.selected === 'all',
        type: audience.selected,
        participantUids: selectedAudience.value.map(c => c.value),
        message: message.value,
        workRoleUids: workRoles.selected.map(x => x.value),
      },
    );

    if (data) {
      openMessageDetail(data.conversation);
    }

    if (resErrors) {
      if (resErrors.token) {
        toast.error(
          'Only supervisors can send messages to all. Narrow down your audience',
        );
      } else {
        setErrors(resErrors);
      }
    }

    setLoading(false);
  };

  return (
    <div>
      <div className="flex flex-row items-stretch border-0 border-b-1 border-solid border-border">
        <button
          children={'Back'}
          className={
            'bg-default block text-white border-0 text-center p-4 cursor-pointer mr-4'
          }
          onClick={openAllMessages}
        />
        <h3 className="p-4 font-normal">New Message</h3>
      </div>

      <div className={'p-4'}>
        <InlineSelect
          icon={<Apartment />}
          options={companyOptions()}
          label={'Company'}
          {...company}
        />

        <InlineSelect
          icon={<UserGroup />}
          options={audienceOptions()}
          label={'Audience'}
          {...audience}
        />

        {audience.selected !== 'company' && (
          <>
            <InlineSelect
              icon={<UserGroup />}
              options={sendToOptions()}
              label={'Send To'}
              {...sendTo}
            />
            {sendTo.selected === 'custom' && (
              <>
                {audience.selected === 'crew' && (
                  <InlineSelect
                    icon={<UserGroup />}
                    options={crewOptions()}
                    label={getCrewLabel()}
                    isMulti={true}
                    {...selectedAudience}
                    errorMessage={errors.participantUids}
                  />
                )}
                {audience.selected === 'user' && (
                  <InlineSelect
                    icon={<UserIcon />}
                    options={userOptions()}
                    label={'Users'}
                    isMulti={true}
                    {...selectedAudience}
                    errorMessage={errors.participantUids}
                  />
                )}
                {audience.selected === 'site' && (
                  <InlineSelect
                    icon={<Location />}
                    options={siteOptions()}
                    label={getSiteLabel()}
                    isMulti={true}
                    {...selectedAudience}
                    errorMessage={errors.participantUids}
                  />
                )}
              </>
            )}
          </>
        )}

        <InlineSelect
          icon={<Cog />}
          options={workRoleOptions()}
          isMulti={true}
          label={'Roles'}
          {...workRoles}
        />

        <div className="flex flex-row">
          <textarea
            className={classNames(
              'w-full p-4',
              errors.message ? 'border-red' : 'border-grey-dark',
            )}
            placeholder={'Type a message...'}
            {...message}
          />
          <Button
            type={'primary'}
            children={'Submit'}
            onClick={submit}
            loading={loading}
          />
        </div>
      </div>
    </div>
  );
};

export default connect((state: StoreState) => ({
  company: state.me.company,
  companies: state.me.companies,
}))(NewMessage);

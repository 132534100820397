import * as React from 'react';
import { makeGetChildren, makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption } from '../../types';
import OptionTitle from './OptionTitle';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import {
  filterChildrenByPublished,
  getOptionTitle,
} from '../../helpers/formOption';
import WidgetContainer from './WidgetContainer';
import WidgetContent from './WidgetContent';
import SelectionItem from './SelectionItem';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
}

const Selection = ({ option, t, live, childOptions }: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live }) || '';

  const filteredChildren = live
    ? filterChildrenByPublished(childOptions)
    : childOptions || [];

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent>
        {filteredChildren.map(c => (
          <SelectionItem key={c.id} optionId={c.id} live={live} />
        ))}
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getChildren = makeGetChildren();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    childOptions: getChildren(state, ownProps),
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(Selection));

import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import Tabs from '../../components/Tabs';
import Overview from './profile/Overview';
import Certificates from './profile/Certificates';
import Notifications from './profile/Notifications';

export default class Profile extends React.Component {
  public render() {
    return (
      <Page>
        <PageContent>
          <Tabs
            raised={true}
            tabs={[
              { label: 'Overview', component: <Overview /> },
              { label: 'Certificates', component: <Certificates /> },
              { label: 'Notifications', component: <Notifications /> },
            ]}
          />
        </PageContent>
      </Page>
    );
  }
}

import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Crew, CompanyGroup, User } from '../../types';
import moment from 'moment';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import ResourceTable from '../../components/ResourceTable';
import PageHeading from '../../components/PageHeading';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import { Dispatch } from 'redux';
import { CompanyGroupCrewsState } from '../../reducers/companyGroup/crews';
import { useEntityRefresh, useMountVisibility } from '../../helpers/hooks';
import { companyGroupCrewsFetchRequest } from '../../actions/companyGroup/crews';
import CrewSidebox from '../../containers/CrewSidebox';
import { CompanyGroupUsersState } from '../../reducers/companyGroup/users';
import { companyGroupUsersFetchRequest } from '../../actions/companyGroup/users';
import { companyGroupCompaniesFetchRequest } from '../../actions/companyGroup/companies';
import { CompanyGroupCompanyState } from '../../reducers/companyGroup/companies';
import { getCrewLabelPlural, getDivisionLabelPlural } from '../../helpers';

interface Props extends WithNamespaces {
  companyGroup: CompanyGroup;
  crews: CompanyGroupCrewsState;
  getCrews: (companyGroupUid: string) => any;
  getUsers: (companyGroupUid: string) => any;
  getCompanies: (companyGroupUid: string) => any;
  users: CompanyGroupUsersState;
  companies: CompanyGroupCompanyState;
}

const Crews = ({
  companyGroup,
  getCrews,
  crews,
  t,
  users,
  getUsers,
  companies,
  getCompanies,
}: Props) => {
  const sideboxState = useMountVisibility();
  const [crew, setCrew] = React.useState<Crew | null>(null);

  useEntityRefresh(companyGroup.uid, crews, getCrews);
  useEntityRefresh(companyGroup.uid, users, getUsers);
  useEntityRefresh(companyGroup.uid, companies, getCompanies);

  const openSidebox = (c: Crew) => {
    setCrew(c);
    sideboxState.open();
  };

  const tableColumns = [
    {
      Header: t('super:name'),
      accessor: 'name',
    },
    {
      Header: t('super:created'),
      accessor: (p: Crew) => moment(p.created_at * 1000).format('YYYY-MM-DD'),
      id: 'created_at',
    },
  ];

  return (
    <Page>
      <PageContent>
        <PageHeading
          title={`${getCrewLabelPlural()} in all ${getDivisionLabelPlural()}`}
          subtitle={`${crews.data.length} ${t('super:available')}`}
        />

        <ResourceTable
          data={crews.data}
          columns={tableColumns}
          defaultSortKey={'name'}
          onRowClick={openSidebox}
        />

        {sideboxState.mounted && (
          <CrewSidebox
            isOpen={sideboxState.visible}
            handleClose={sideboxState.close}
            crew={crew!}
            users={users.data}
            companies={companies.data}
            tabs={['overview', 'companies']}
          />
        )}
      </PageContent>
    </Page>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    companyGroup: state.me.companyGroup,
    crews: state.companyGroup.crews,
    users: state.companyGroup.users,
    companies: state.companyGroup.companies,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getCrews: (companyGroupUid: string) =>
      dispatch(companyGroupCrewsFetchRequest(companyGroupUid)),
    getUsers: (companyGroupUid: string) =>
      dispatch(companyGroupUsersFetchRequest(companyGroupUid)),
    getCompanies: (companyGroupUid: string) =>
      dispatch(companyGroupCompaniesFetchRequest(companyGroupUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Crews),
);

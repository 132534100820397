import * as React from 'react';
import { FormFabricatorProps, useWidgetChange } from './common';
import BuilderButtons from './BuilderButtons';
import WidgetContent from '../form/WidgetContent';
import { useState, useEffect } from 'react';
import LengthErrorLabel from './LengthErrorLabel';
import { MAX_INPUT_FIELD_LENGTH } from '../../constants';
import ReactQuill from 'react-quill';
import marked from 'marked';
import { turndownService } from '../../helpers';
import 'react-quill/dist/quill.snow.css';

type Props = FormFabricatorProps;

function Content({
  widget,
  setWidgets,
  formRevisionUid,
  openDelete,
  editErrors,
  setEditErrors,
}: Props) {
  const [content, setContent] = useState<string>(
    widget.content ? marked(widget.content) : '',
  );

  const isTooLong = content.length === MAX_INPUT_FIELD_LENGTH;

  const { handleWidgetChange } = useWidgetChange({
    formRevisionUid,
    setWidgets,
    widget,
  });

  function handleBlur() {
    const markdownContent = turndownService.turndown(content);
    handleWidgetChange({ content: markdownContent });
  }

  useEffect(
    () => {
      if (isTooLong) {
        setEditErrors([...editErrors, widget.uid]);
        return;
      }
      setEditErrors(editErrors.filter(uid => uid !== widget.uid));
    },
    [isTooLong],
  );

  return (
    <WidgetContent>
      <div className="mb-4">
        {/* has no minimum length */}
        <LengthErrorLabel
          isTooShort={false}
          isTooLong={content.length === MAX_INPUT_FIELD_LENGTH ? true : false}
          maxCharLength={MAX_INPUT_FIELD_LENGTH}
        />
        <div className={'my-2'}>
          <ReactQuill
            value={content}
            onChange={(e: any) => setContent(e)}
            onBlur={handleBlur}
          />
        </div>
      </div>
      <BuilderButtons openDelete={openDelete} id={widget.uid} />
    </WidgetContent>
  );
}

export default Content;

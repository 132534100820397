import * as React from 'react';
import HeaderButton from './dateFilterWidget/HeaderButton';
import DateFilters from './dateFilterWidget/DateFilters';
import moment, { Moment } from 'moment';

interface Tab {
  accessor: string;
  label: string;
}

interface Props {
  children: (
    state: {
      start: Moment;
      end: Moment;
      Widget: React.ComponentType<WidgetProps>;
      getWidgetProps: () => WidgetProps;
    },
  ) => JSX.Element;
  defaultView?: DateFilterType;
  views?: DateFilterType[];
}

interface WidgetProps {
  tabs?: Tab[];
  children?: (state: { tab?: string }) => JSX.Element;
  defaultView?: DateFilterType;
  views: DateFilterType[];
  view: DateFilterType;
  start: Moment;
  end: Moment;
  onStartEndChange: (start: Moment, end: Moment) => void;
  onViewChange: (view: DateFilterType) => void;
}

export type DateFilterType =
  | 'all'
  | 'thisMonth'
  | 'last30Days'
  | 'today'
  | 'thisYear'
  | 'pastYear'
  | 'lastWeek'
  | 'lastQuarter'
  | 'custom';

const DEFAULT_VIEW = 'last30Days';
const DEFAULTS_VIEWS: DateFilterType[] = [
  'all',
  'today',
  'thisMonth',
  'last30Days',
  'custom',
];

const getDateFilterStartEnd = (filter: DateFilterType) => {
  switch (filter) {
    case 'today':
      return {
        start: moment().startOf('day'),
        end: moment().endOf('day'),
      };
    case 'thisMonth':
      return {
        start: moment().startOf('month'),
        end: moment().endOf('month'),
      };
    case 'lastQuarter':
      return {
        start: moment().startOf('quarter'),
        end: moment(),
      };
    case 'lastWeek':
      return {
        start: moment()
          .startOf('week')
          .subtract(1, 'week'),
        end: moment()
          .endOf('week')
          .subtract(1, 'week'),
      };
    case 'pastYear':
      return {
        start: moment().subtract(1, 'year'),
        end: moment(),
      };
    case 'all':
      return {
        start: moment.unix(0),
        end: moment().add(1, 'year'),
      };
    case 'last30Days':
    default:
      return {
        start: moment().subtract(30, 'day'),
        end: moment(),
      };
  }
};

const Widget = ({
  children,
  tabs,
  start,
  end,
  onStartEndChange,
  onViewChange,
  views,
  view,
}: WidgetProps) => {
  const [activeTab, setActiveTab] = React.useState(0);
  return (
    <div className={'mt-4 bg-white'}>
      <div className="bg-grey-lighter flex flex-col md:flex-row justify-between items-center p-4">
        {tabs ? (
          <div className="flex-1 mb-4 md:mb-0">
            {tabs.map((tab, i) => (
              <HeaderButton
                key={i}
                active={activeTab === i}
                onClick={() => setActiveTab(i)}
              >
                {tab.label}
              </HeaderButton>
            ))}
          </div>
        ) : (
          <div className={'flex-1'} />
        )}

        <div className="flex-1 flex justify-end">
          <DateFilters
            start={start}
            end={end}
            view={view}
            onStartEndChange={onStartEndChange}
            onViewChange={onViewChange}
            views={views}
          />
        </div>
      </div>

      <div>
        {children &&
          children({
            tab: tabs ? tabs[activeTab].accessor : undefined,
          })}
      </div>
    </div>
  );
};

const DateFilterWidget = ({
  children,
  defaultView = DEFAULT_VIEW,
  views = DEFAULTS_VIEWS,
}: Props) => {
  const [startEnd, setStartEnd] = React.useState(
    getDateFilterStartEnd(defaultView),
  );
  const [view, setView] = React.useState<DateFilterType>(defaultView);

  const onStartEndChange = (newStart: Moment, newEnd: Moment) => {
    if (newStart.isAfter(newEnd)) {
      newStart = newEnd.clone();
    }

    setStartEnd({ start: newStart, end: newEnd });
  };

  const onViewChange = (newView: DateFilterType) => {
    const { start, end } = getDateFilterStartEnd(newView);
    onStartEndChange(start, end);
    setView(newView);
  };

  const getWidgetProps = () => ({
    onViewChange,
    onStartEndChange,
    ...startEnd,
    view,
    views,
  });

  return children({
    ...startEnd,
    Widget,
    getWidgetProps,
  });
};

export default DateFilterWidget;

import * as React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';
import { biggerThanMD } from '../helpers/style';
import { colors, colorTypes } from '../constants';

const Container = styled.div`
  display: none;

  ${biggerThanMD(`
    display: flex;
    background: #f6f6f6;
    overflow: scroll;
    max-width: 200px;
    flex-direction: column;
    align-items: center; 
  `)};
`;

const linkStyles = `
display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  font-size: 14px;
  color: #464646;
  padding: 20px 18px;
  text-decoration: none;
  transition: 0.1s background linear;
  text-transform: capitalize;

  span {
    font-size: 11px;
    margin-left: 1rem;
    padding: 2px 6px;
    border-radius: 6px;
    color: #fff;
    background-color: ${colors[colorTypes.PRIMARY]};
  }

  &.active {
    background: ${colors[colorTypes.PRIMARY]};
    color: ${colors[colorTypes.WHITE]};
    svg path {
      fill: ${colors[colorTypes.WHITE]};
    }
    span {
      background-color: ${colors[colorTypes.WHITE]};
      color: ${colors[colorTypes.PRIMARY]};
    }
  }

  &:hover {
    background: black;
    color: #fff;

    svg path {
      fill: white;
    }
  }

  & svg {
    width: 16px;
    height: auto;
    margin-right: 12px;

    path {
      fill: #464646;
    }
  }
`;

const Item = styled(NavLink)`
  ${linkStyles};
`;

const ExternalItem = styled.a`
  ${linkStyles};
`;

interface RouteItem {
  icon: ReactNode;
  label: string;
  path: string;
  exact?: boolean;
  badge?: ReactNode;
  external?: boolean;
}

interface Props {
  routes: RouteItem[];
}

export default class SideNavigation extends React.Component<Props> {
  public render() {
    const { routes } = this.props;

    return (
      <Container>
        {routes.map(
          ({ icon, label, path, exact, badge, external }) =>
            external ? (
              <ExternalItem href={path} target="_blank">
                {icon} {label} {badge}
              </ExternalItem>
            ) : (
              <Item to={path} exact={exact} key={path}>
                {icon} {label} {badge}
              </Item>
            ),
        )}
      </Container>
    );
  }
}

import * as React from 'react';
import InlineSelect from '../components/InlineSelect';
import OptionTitle from './form/OptionTitle';
import PageToolbar from '../components/PageToolbar';
import incidentApi from '../api/incident.api';
import { match as rMatch, withRouter, RouterProps } from 'react-router';
import Loading from '../components/Loading';
import InlineInput from '../components/InlineInput';
import Pencil from '../svg/Pencil';
import UserGroup from '../svg/UserGroup';
import Location from '../svg/Location';
import InlineDatePicker from '../components/InlineDatePicker';
import moment from 'moment';
import {
  Crew,
  Site,
  Incident as IIncident,
  User as IUser,
  Colors,
  Company,
  CompanyGroup,
  IncidentProp,
  IncidentPropOption,
  Form,
} from '../types';
import User from '../svg/User';
import {
  useMountVisibility,
  useTextInput,
  useOptionalDateInput,
  useOptionalSelectInput,
} from '../helpers/hooks';
import TextArea from '../components/TextArea';
import ConfirmationDialogue from '../components/ConfirmationDialogue';
import companyApi from '../api/company.api';
import IncidentSubmissions from './incident/IncidentSubmissions';
import IncidentActions from './incident/IncidentActions';
import IncidentUploads from './incident/IncidentUploads';
import IncidentProps from './IncidentProps';
import { History } from 'history';
import { toast } from 'react-toastify';
import Checkmark from '../svg/Checkmark';
import Trash from '../svg/Trash';
import Save from '../svg/Save';
import { getSiteLabel, getCrewLabel } from '../helpers';

interface Props extends RouterProps {
  match: rMatch<{ incidentUid: string }>;
  sites: Site[];
  crews: Crew[];
  companies?: Company[];
  users: IUser[];
  company?: Company;
  companyGroup?: CompanyGroup;
  history: History;
  forms: Form[];
}

const Incident = ({
  match,
  sites,
  crews,
  users,
  company,
  companyGroup,
  history,
  forms,
}: Props) => {
  const { incidentUid } = match.params;

  const [incident, setIncident] = React.useState<null | IIncident>(null);
  const [reporter, setReporter] = React.useState<null | IUser>(null);
  const deleteState = useMountVisibility();
  const [incidentPropSelects, setIncidentPropSelects] = React.useState({});
  const [incidentPropOther, setIncidentPropOther] = React.useState({});

  // Editing state
  const [editing, setEditing] = React.useState(false);
  const description = useTextInput();
  const caseNo = useTextInput();
  const occurred = useOptionalDateInput();
  const reported = useOptionalDateInput();
  const site = useOptionalSelectInput();
  const crew = useOptionalSelectInput();
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);
  const incidentCompany = useOptionalSelectInput();
  const [deleting, setDeleting] = React.useState(false);

  const [incidentProps, setIncidentProps] = React.useState<IncidentProp[]>([]);
  const [incidentPropOptions, setIncidentPropOptions] = React.useState<
    IncidentPropOption[]
  >([]);

  const isCompany = company ? true : false;
  const scope = isCompany ? company : companyGroup;

  if (!scope) {
    return null;
  }

  const fetchIncidentProps = async () => {
    if (!incidentCompany.value) {
      return;
    }
    const { data } = await companyApi.incidentProps(
      incidentCompany.value.value,
    );

    if (data) {
      setIncidentProps(data.incidentProps);
      setIncidentPropOptions(data.incidentPropOptions);

      if (incident) {
        const selects = {};
        const others = {};
        Object.keys(incident).forEach(key => {
          const prop = data.incidentProps.find(i => i.slug === key);
          if (prop) {
            if (prop.multiple) {
              const selectedOnIncident = incident[key];
              const selected = data.incidentPropOptions.filter(
                i =>
                  i.incident_prop_id === prop.id &&
                  selectedOnIncident.indexOf(i.slug) !== -1,
              );
              selects[prop.id] = selected.map(i => ({
                label: i.name,
                value: i.slug,
              }));
              if (selectedOnIncident.indexOf('other') !== -1) {
                others[prop.id] = incident[`${prop.slug}_other`];
              }
            } else {
              selects[prop.id] = incident[key];
            }
          }
        });
        setIncidentPropSelects(selects);
        setIncidentPropOther(others);
      }
    }
  };

  const fetchIncident = async () => {
    try {
      const data = await incidentApi.show(match.params.incidentUid);
      setIncident(data.incident);
      setReporter(data.user);
      description.setValue(data.incident.description);
      caseNo.setValue(data.incident.case_id);
      if (data.incident.occured) {
        occurred.onChange(moment.unix(data.incident.occured));
      }
      if (data.incident.reported) {
        reported.onChange(moment.unix(data.incident.reported));
      }
      if (data.site) {
        site.onChange({ label: data.site.name, value: data.site.uid });
      }
      if (data.crew) {
        crew.onChange({ label: data.crew.name, value: data.crew.uid });
      }
      if (data.company) {
        incidentCompany.onChange({
          label: data.company.name,
          value: data.company.uid,
        });
      }
    } catch (e) {
      //
    }
  };

  const onDelete = async () => {
    // setDeleting(true);
    // await incidentApi.complete(incidentUid);
    deleteState.close();
    // setDeleting(false);
    // history.push(`/dashboard/super/dashboard/incidents`);
  };

  const onComplete = async () => {
    setLoading(true);
    const res = await incidentApi.complete(incidentUid);
    if (res.data) {
      toast.success('Completed incident');
      history.push(`/dashboard/super/dashboard/incidents`);
    }
    setLoading(false);
  };

  const onUncomplete = async () => {
    setLoading(true);
    const res = await incidentApi.uncomplete(incidentUid);
    if (res.data) {
      toast.success('Marked incident as not complete');
    }
    await fetchIncident();
    setLoading(false);
  };

  const onUpdate = async () => {
    if (!incident) {
      return;
    }

    setLoading(true);

    const props = Object.keys(incidentPropSelects).reduce((curr, id) => {
      if (Array.isArray(incidentPropSelects[id])) {
        curr[id] = incidentPropSelects[id].map((x: any) => x.value);
      } else {
        curr[id] = incidentPropSelects[id];
      }
      return curr;
    }, {});
    // get "other" only when selected
    const other = Object.keys(props).reduce((curr, id) => {
      if (Array.isArray(props[id]) && props[id].indexOf('other') !== -1) {
        curr[id] = incidentPropOther[id];
      }
      return curr;
    }, {});

    const { errors: err } = await incidentApi.update(incident.uid, {
      caseNumber: caseNo.value,
      siteUid: site.selected!,
      crewUid: crew.selected!,
      occurred: occurred.unix()!,
      reported: reported.unix()!,
      description: description.value,
      props: {
        ...props,
        other: {
          ...other,
        },
      },
    });

    if (err) {
      setErrors(err);
    } else {
      setEditing(false);
    }

    setLoading(false);
  };

  const getSiteOptions = () =>
    sites.map(s => ({
      label: s.name,
      value: s.uid,
    }));

  const getCrewOptions = () =>
    crews.map(c => ({
      label: c.name,
      value: c.uid,
    }));

  const toolbarActions = editing
    ? [
        {
          label: 'Save',
          type: 'success' as Colors,
          onClick: onUpdate,
          disabled: loading,
          icon: <Save />,
        },
      ]
    : [
        {
          label: 'Delete',
          onClick: deleteState.open,
          disabled: loading,
          icon: <Trash />,
        },
        {
          label: 'Edit',
          onClick: () => setEditing(!editing),
          disabled: loading,
          icon: <Pencil />,
        },
        ...(incident
          ? incident.status === 2
            ? [
                {
                  label: 'Complete',
                  type: 'success' as Colors,
                  disabled: loading,
                  onClick: onComplete,
                  icon: <Checkmark />,
                },
              ]
            : [
                {
                  label: 'Uncomplete',
                  type: 'default' as Colors,
                  disabled: loading,
                  onClick: onUncomplete,
                },
              ]
          : []),
      ];

  React.useEffect(
    () => {
      fetchIncident();
    },
    [incidentUid],
  );

  React.useEffect(
    () => {
      fetchIncidentProps();
    },
    [incidentCompany.value],
  );

  if (!incident || !reporter) {
    return <Loading loading={true} />;
  }

  return (
    <div className={'bg-white relative'}>
      <Loading loading={loading} />
      <PageToolbar title={'Incident'} actions={toolbarActions} />

      <div className="p-4">
        <div className="flex mb-4">
          <div className="flex-1">
            <InlineInput
              icon={<Pencil />}
              label={'Case #'}
              disabled={!editing}
              {...caseNo}
              errorMessage={errors.caseNumber}
            />
          </div>
          <div className="flex-1 mx-4">
            <InlineSelect
              icon={<Location />}
              options={getSiteOptions()}
              label={getSiteLabel()}
              disabled={!editing}
              {...site}
              errorMessage={errors.siteUid}
            />
          </div>
          <div className="flex-1">
            <InlineSelect
              icon={<UserGroup />}
              options={getCrewOptions()}
              label={getCrewLabel()}
              disabled={!editing}
              {...crew}
              errorMessage={errors.crewUid}
            />
          </div>
        </div>
        <div className="flex">
          <div className="flex-1">
            <InlineInput
              icon={<User />}
              label={'Reporter'}
              disabled={true}
              value={reporter.username}
            />
          </div>
          <div className="flex-1 mx-4">
            <InlineDatePicker
              label={'Occurred'}
              disabled={!editing}
              {...occurred}
              errorMessage={errors.occurred}
            />
          </div>
          <div className="flex-1">
            <InlineDatePicker
              label={'Reported'}
              value={moment.unix(incident.reported)}
              disabled={!editing}
              {...reported}
              errorMessage={errors.reported}
            />
          </div>
        </div>

        <IncidentProps
          props={incidentProps}
          propOptions={incidentPropOptions}
          propValues={incidentPropSelects}
          otherValues={incidentPropOther}
          onPropValuesChange={setIncidentPropSelects}
          onOtherValuesChange={setIncidentPropOther}
          disabled={!editing}
        />

        <div>
          <OptionTitle title={'Description'} />
          {editing ? (
            <TextArea {...description} />
          ) : (
            <div className="p-4">{incident.description}</div>
          )}
        </div>

        {incidentCompany.selected && (
          <IncidentActions
            incidentUid={incidentUid}
            sites={sites}
            users={users}
            companyUid={incidentCompany.selected}
          />
        )}

        <IncidentSubmissions
          incidentUid={incidentUid}
          sites={sites}
          forms={forms}
          crews={crews}
          editing={editing}
        />

        <IncidentUploads incidentUid={incidentUid} />
      </div>

      {deleteState.mounted && (
        <ConfirmationDialogue
          contentLabel="Delete this incident?"
          title="Delete this incident?"
          body="Are you sure you wish to delete this incident?"
          onConfirm={onDelete}
          handleClose={deleteState.close}
          isOpen={deleteState.visible}
          loading={deleting}
        />
      )}
    </div>
  );
};

export default withRouter(Incident);

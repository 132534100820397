import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const Cloud = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M16.88 9.1A4 4 0 0 1 16 17H5a5 5 0 0 1-1-9.9V7a3 3 0 0 1 4.52-2.59A4.98 4.98 0 0 1 17 8c0 .38-.04.74-.12 1.1z" />
  </svg>
);

export default BaseSVG(Cloud);

import { trim } from 'ramda';
import { User } from '../types';
import store from '../store';
// @ts-ignore
import TurndownService from 'turndown';
import { localStorageKeys } from '../constants/index';

export function unidashUrl(uri: string): string | null {
  const token = localStorage.getItem(localStorageKeys.USER_TOKEN);
  const refreshToken = localStorage.getItem(localStorageKeys.REFRESH_TOKEN);
  const { company, companyGroup } = store.getState().me;
  const uniUrl = process.env.UNI_URL;
  if (token === null || refreshToken === null || !company || !companyGroup) {
    return null;
  }
  let url = `${uniUrl}${uri}?companyUid=${company.uid}&companyGroupUid=${
    companyGroup.uid
  }`;
  // handle non sso users
  if (refreshToken) {
    url += `&refresh=${refreshToken}&token=${token}`;
  }
  return url;
}

export const turndownService = new TurndownService();

export function onContentEditablePaste(e: any) {
  e.preventDefault();
  const text = (e.originalEvent || e).clipboardData.getData('text/plain');
  document.execCommand('insertText', false, text);
}

export async function sleep(ms: number) {
  await new Promise(resolve => setTimeout(() => resolve(), ms));
}

export function attempt<T>(cb: () => T, defaultReturn: T | null = null) {
  try {
    return cb();
  } catch (e) {
    return defaultReturn;
  }
}

export function dataURItoBlob(dataURI: string) {
  'use strict';
  let byteString;

  if (dataURI.split(',')[0].indexOf('base64') !== -1) {
    byteString = atob(dataURI.split(',')[1]);
  } else {
    byteString = decodeURI(dataURI.split(',')[1]);
  }

  const mimestring = dataURI
    .split(',')[0]
    .split(':')[1]
    .split(';')[0];

  const content = new Array();
  for (let i = 0; i < byteString.length; i++) {
    content[i] = byteString.charCodeAt(i);
  }

  return new Blob([new Uint8Array(content)], { type: mimestring });
}

export const unique = <T>(key: string, arr: T[]) => {
  const flags = new Set();
  const retList: T[] = [];
  for (const arrayElem of arr) {
    const flag = trim(arrayElem[key] || '');
    if (flags.has(flag)) {
      continue;
    }
    retList.push(arrayElem);
    flags.add(flag);
  }
  return retList;
};

export const getFullName = (user: User) => {
  const { fname, lname, username } = user;
  const fullname = [fname, lname].filter(name => !!name).join(' ');
  return fullname || username;
};

export const blobToB64 = async (blob: Blob): Promise<null | string> => {
  const reader = new FileReader();
  reader.readAsDataURL(blob);
  return new Promise<string>(resolve => {
    reader.onloadend = () => {
      resolve(reader.result!.toString());
    };
  });
};

export function hideForADFS(user: User) {
  const { authentication_provider } = user;
  if (authentication_provider === 'sso') {
    return true;
  } else {
    return false;
  }
}

export function getSiteLabel(): string {
  const state = store.getState();
  return state.me.companyGroup.site_label || 'Site';
}

export function getSiteLabelPlural(): string {
  const state = store.getState();
  return state.me.companyGroup.site_label_plural || 'Sites';
}

export function getCrewLabel(): string {
  const state = store.getState();
  return state.me.companyGroup.crew_label || 'Crew';
}

export function getCrewLabelPlural(): string {
  const state = store.getState();
  return state.me.companyGroup.crew_label_plural || 'Crews';
}

export function getDivisionLabel(): string {
  const state = store.getState();
  return state.me.companyGroup.division_label || 'Division';
}

export function getDivisionLabelPlural(): string {
  const state = store.getState();
  return state.me.companyGroup.division_label_plural || 'Divisions';
}

export function ObjectFilter(obj: {}, filter: any) {
  return Object.keys(obj)
    .filter(key => filter(obj[key]))
    .reduce((res, key) => ((res[key] = obj[key]), res), {});
}

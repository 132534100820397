import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../constants';
import Select, {
  SelectOption,
  Props as SelectProps,
  SelectOptionObject,
} from './Select';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${cssVars.margin[4]};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  margin-bottom: ${cssVars.margin[3]};
  display: block;
`;

interface Props extends SelectProps {
  id?: string;
  label?: string;
  placeholder?: string;
  options: SelectOptionObject[];
  multi?: boolean;
}

const StackedSelect = ({ id, label, options, multi, ...rest }: Props) => (
  <Wrapper>
    {label && <Label htmlFor={id}>{label}</Label>}
    <Select
      id={id}
      options={options}
      multi={multi}
      closeMenuOnSelect={!multi}
      {...rest}
    />
  </Wrapper>
);

export default StackedSelect;

import React from 'react';
import Sidebox from '../../components/Sidebox';
import SideboxHeader from '../../components/sidebox/SideboxHeader';
import InlineSelect from '../../components/InlineSelect';
import UserGroup from '../../svg/UserGroup';
import Location from '../../svg/Location';
import File from '../../svg/File';
import UserIcon from '../../svg/User';
import InlineDatePicker from '../../components/InlineDatePicker';
import Button from '../../components/Button';
import Cog from '../../svg/Cog';
import { Crew, User, Form, Site } from '../../types';
import { getFullName } from '../../helpers/index';
import moment from 'moment';
import Loading from '../../components/Loading';
import { IncidentSubmission } from './IncidentSubmissions';
import {
  useOptionalSelectInput,
  useRequiredDateInput,
} from '../../helpers/hooks';
import incidentApi from '../../api/incident.api';
import { contains } from 'ramda';
import formApi from '../../api/form.api';
import NewSubmissionText from '../../components/NewSubmissionText';

interface Props {
  crews: Crew[];
  sites: Site[];
  forms: Form[];
  isOpen: boolean;
  handleClose: () => void;
  onSelect: (item: IncidentSubmission) => void;
  selectedSubmissionUids: string[];
  incidentUid: string;
}

interface Errors {
  form?: string;
}

const DEFAULT_START = moment().startOf('month');
const DEFAULT_END = moment();

const IncidentSubmissionsSidebox = ({
  crews,
  sites,
  forms,
  isOpen,
  handleClose,
  onSelect,
  selectedSubmissionUids,
  incidentUid,
}: Props) => {
  const [allSubmissions, setAllSubmissions] = React.useState<
    IncidentSubmission[]
  >([]);
  const [loading, setLoading] = React.useState(false);
  const [errors, setErrors] = React.useState<Errors>({});
  const [searched, setSearched] = React.useState(false);
  const form = useOptionalSelectInput();
  const site = useOptionalSelectInput();
  const crew = useOptionalSelectInput();
  const start = useRequiredDateInput(DEFAULT_START);
  const end = useRequiredDateInput(DEFAULT_END);

  const formOptions = forms.map(item => ({
    value: item.uid,
    label: item.name,
  }));
  const crewOptions = crews.map(item => ({
    value: item.uid,
    label: item.name,
  }));
  const siteOptions = sites.map(item => ({
    value: item.uid,
    label: item.name,
  }));

  const getSubmissions = async () => {
    if (form.selected === null) {
      setErrors({ form: 'is required' });
      return;
    } else {
      setErrors({});
    }

    setLoading(true);
    try {
      const res = await formApi.submissions(form.selected!, {
        siteUids: site.selected ? [site.selected] : [],
        crewUids: crew.selected ? [crew.selected] : [],
        start: start.unix(),
        end: end.unix(),
      });
      setAllSubmissions(res.submissions);
    } catch (e) {
      //
    }
    setLoading(false);
    setSearched(true);
  };

  const selectSubmission = async (item: any) => {
    onSelect({ ...item, form: forms.find(x => x.uid === form.selected) });
    setAllSubmissions(
      allSubmissions.filter(
        result => result.submission.uid !== item.submission.uid,
      ),
    );
    await incidentApi.attachSubmission(incidentUid, item.submission.uid);
  };

  const filteredResults = allSubmissions.filter(
    item => !contains(item.submission.uid, selectedSubmissionUids),
  );

  return (
    <Sidebox
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Attach submissions to an incident"
    >
      <SideboxHeader handleClose={handleClose}>
        Attach Submissions
      </SideboxHeader>

      <div className="p-4">
        <Loading loading={loading} />
        <InlineSelect
          label="Form"
          icon={<File />}
          options={formOptions}
          errorMessage={errors.form}
          {...form}
        />
        <InlineSelect
          label="Site"
          icon={<Location />}
          options={siteOptions}
          isClearable={true}
          {...site}
        />
        <InlineSelect
          label="Crew"
          icon={<UserGroup />}
          options={crewOptions}
          isClearable={true}
          {...crew}
        />
        <InlineDatePicker label={'From'} {...start} />
        <InlineDatePicker label={'To'} {...end} />

        <Button type={'default'} onClick={getSubmissions}>
          Search
        </Button>

        <hr className={'my-4'} />

        {searched &&
          filteredResults.length === 0 && (
            <p className={'text-center'}>No results for your search</p>
          )}

        {filteredResults.map(item => {
          return (
            <div
              key={item.submission.uid}
              className={'flex items-center justify-between mb-4'}
            >
              <div className={'flex flex-col'}>
                <div className={'flex'}>
                  <p>
                    <NewSubmissionText
                      user={getFullName(item.user)}
                      form={item.form.name}
                      site={item.site.name}
                      deployee={
                        (item.deployee as Crew).name ||
                        getFullName(item.deployee as User)
                      }
                    />
                  </p>
                </div>

                <p className={'text-grey-darker text-sm mt-2'}>
                  {moment
                    .unix(item.submission.created_at)
                    .format('hh:mm a - MMM Do, YYYY')}
                </p>
              </div>

              <div className={'flex flex-col items-center'}>
                <Button
                  type={'default'}
                  className={'mb-2'}
                  onClick={() => selectSubmission(item)}
                >
                  Select
                </Button>
                <a
                  href={`/dashboard/worker/forms/${item.fc.uid}/submission/${
                    item.submission.uid
                  }`}
                  target={'_blank'}
                  className={'text-red no-underline'}
                >
                  View
                </a>
              </div>
            </div>
          );
        })}
      </div>
    </Sidebox>
  );
};

export default IncidentSubmissionsSidebox;

import * as React from 'react';
import WidgetContent from '../form/WidgetContent';
import {
  FormFabricatorProps,
  useWidgetChange,
  useWidgetMandatory,
  BasicLabel,
} from './common';
import {
  useContentEditable,
  handleContentLengthErrors,
} from '../../helpers/hooks';
import TextArea from '../../components/TextArea';
import BuilderButtons from './BuilderButtons';
import { useState, useEffect } from 'react';
import LengthErrorLabel from './LengthErrorLabel';
import { MAX_NAME_LENGTH } from '../../constants/index';
import { onContentEditablePaste } from '../../helpers';

type Props = FormFabricatorProps;

function Question({
  formRevisionUid,
  setWidgets,
  widget,
  mandatory,
  openDelete,
  editErrors,
  setEditErrors,
}: Props) {
  const [isTooLong, setIsTooLong] = useState<boolean>(false);
  const [isTooShort, setIsTooShort] = useState<boolean>(false);
  const { handleWidgetChange } = useWidgetChange({
    formRevisionUid,
    setWidgets,
    widget,
  });
  const { handleDone, handleBlurEvent } = useContentEditable(
    'title',
    handleWidgetChange,
  );
  const { checked, handleMandatoryChange } = useWidgetMandatory({
    widget,
    formRevisionUid,
    mandatory,
  });

  const title = widget.title;

  useEffect(
    () => {
      if (isTooShort || isTooLong) {
        setEditErrors([...editErrors, widget.uid]);
        return;
      }
      setEditErrors(editErrors.filter(uid => uid !== widget.uid));
    },
    [isTooLong, isTooShort],
  );

  return (
    <WidgetContent>
      <div className={'mb-4'}>
        <LengthErrorLabel
          isTooLong={isTooLong}
          isTooShort={isTooShort}
          maxCharLength={MAX_NAME_LENGTH}
        />
        <div className="w-full">
          <BasicLabel
            className={
              isTooShort || isTooLong
                ? 'border-2 border-red border-solid rounded'
                : ''
            }
            contentEditable={true}
            onInput={(event: React.KeyboardEvent<any>) =>
              handleContentLengthErrors(
                event,
                MAX_NAME_LENGTH,
                isTooLong,
                isTooShort,
                setIsTooLong,
                setIsTooShort,
                handleDone,
              )
            }
            onPaste={(e: any) => onContentEditablePaste(e)}
            onBlur={handleBlurEvent}
          >
            {title}
          </BasicLabel>
        </div>
        <TextArea className="mt-2" disabled={true} />
      </div>
      <BuilderButtons
        handleMandatoryChange={handleMandatoryChange}
        checked={checked}
        openDelete={openDelete}
        id={widget.uid}
      />
    </WidgetContent>
  );
}
export default Question;

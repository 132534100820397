import * as React from 'react';
import Modal from '../../components/Modal';
import ModalHeader from '../../components/modal/ModalHeader';
import ModalBody from '../../components/modal/ModalBody';
import ModalFooter from '../../components/modal/ModalFooter';
import Button from '../../components/Button';

interface Props {
  title: string;
  body: string;
  contentLabel: string;
  handleClose: () => void;
  onConfirm: (uid: string) => Promise<any>;
  isOpen: boolean;
  loading?: boolean;
  id: string;
}

export default class ConfirmationDialogue extends React.Component<Props> {
  private onClick = () => {
    const { onConfirm, id } = this.props;

    this.setState({
      loading: true,
    });

    onConfirm(id);

    this.setState({
      loading: false,
    });
  };

  public render() {
    const {
      title,
      body,
      contentLabel,
      handleClose,
      isOpen,
      loading,
      id,
    } = this.props;

    return (
      <Modal
        contentLabel={contentLabel}
        handleClose={handleClose}
        isOpen={isOpen}
      >
        <ModalHeader handleClose={handleClose}>{title}</ModalHeader>
        <ModalBody>{body}</ModalBody>
        <ModalFooter right={true}>
          <Button type="default" onClick={handleClose} className={'mr-3'}>
            Cancel
          </Button>
          <Button
            type="primary"
            onClick={this.onClick}
            loading={loading}
            id={id}
          >
            Ok
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

import * as React from 'react';
import Sidebox, { SideboxContent } from '../../../components/Sidebox';
import { Form, Company } from '../../../types';
import SideboxHeader from '../../../components/sidebox/SideboxHeader';
import formApi from '../../../api/form.api';
import CategoryList from '../../../components/CategoryList';
import SideboxSectionHeader from '../../../components/sidebox/SideboxSectionHeader';
import SideboxSectionContent from '../../../components/sidebox/SideboxSectionContent';
import Loading from '../../../components/Loading';
import StackedSelect from '../../../components/StackedSelect';
import Button from '../../../components/Button';
import { useOptionalSelectInput } from '../../../helpers/hooks';
import { toast } from 'react-toastify';
import { getDivisionLabelPlural } from '../../../helpers';
import { WithNamespaces, withNamespaces } from 'react-i18next';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  form: Form;
  companies: Company[];
}

const FormSidebox = ({
  form,
  isOpen,
  handleClose,
  companies: companiesForSelect,
  t,
}: Props) => {
  const [companies, setCompanies] = React.useState<Company[]>([]);
  const [loading, setLoading] = React.useState(false);
  const selectedCompany = useOptionalSelectInput();

  const fetchCompanies = async () => {
    setLoading(true);
    const { data, errors } = await formApi.companies(form.uid);
    if (data) {
      setCompanies(data.companies);
    }
    if (errors) {
      toast.error('Failed fetching companies!');
    }
    setLoading(false);
  };

  async function removeFormFromCompany(formUid: string, companyUid: string) {
    if (companies.length === 1) {
      toast.error('Form must have at least one division!');
      return;
    }
    setLoading(true);
    const { errors } = await formApi.removeCompany(formUid, companyUid);
    setLoading(false);
    if (errors) {
      toast.error('Failed removing company!');
      return;
    }
    fetchCompanies();
  }

  const attachCompanies = async () => {
    if (!selectedCompany.selected) {
      return;
    }

    setLoading(true);

    const { data, errors } = await formApi.attachCompany(
      form.uid,
      selectedCompany.selected,
    );

    if (errors) {
      toast.error('Something went wrong');
    }

    if (data) {
      selectedCompany.onChange(null);
      fetchCompanies();
    }

    setLoading(false);
  };

  React.useEffect(() => {
    fetchCompanies();
  }, []);

  const attachedCompanyUids = companies.map(c => c.uid);

  const companyOptions = companiesForSelect
    .filter(c => attachedCompanyUids.indexOf(c.uid) === -1)
    .map(c => ({
      label: c.name,
      value: c.uid,
    }));

  return (
    <Sidebox
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel={`Companies for form ${form.name}`}
    >
      <Loading loading={loading} />
      <SideboxHeader handleClose={handleClose}>
        {form.name} Companies
      </SideboxHeader>
      <SideboxContent>
        <SideboxSectionHeader title={getDivisionLabelPlural()} />
        <SideboxSectionContent>
          <div>
            <StackedSelect
              label={`Add ${getDivisionLabelPlural().toLowerCase()} to form`}
              options={companyOptions}
              {...selectedCompany}
            />
            <div className="my-4 flex justify-end">
              <Button
                type="default"
                block={true}
                loading={loading}
                onClick={attachCompanies}
              >
                Save
              </Button>
            </div>
          </div>
          <hr className={'my-4'} />
          <CategoryList
            items={companies}
            itemLabelAccessor={'name'}
            menuItems={[
              {
                label: t('common:delete'),
                onClick: company =>
                  removeFormFromCompany(form.uid, company.uid),
              },
            ]}
          />
        </SideboxSectionContent>
      </SideboxContent>
    </Sidebox>
  );
};

export default withNamespaces()(FormSidebox);

import * as Sentry from '@sentry/browser';
import * as React from 'react';
import Warning from '../svg/Warning';
import store from '../store';

class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { error: null };
  }

  public componentDidCatch(error: any, errorInfo: any) {
    this.setState({ error });
    if (process.env.NODE_ENV === 'production') {
      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
        const user = store.getState().me.user;
        scope.setUser({
          email: user ? user.email : '',
          id: user ? user.uid : '',
          username: user ? user.username : '',
        });
      });
    }
  }

  public render() {
    if (this.state.error) {
      return (
        <div className={'flex flex-col items-center justify-center p-8'}>
          <Warning
            className={'block mb-4 text-red'}
            style={{ width: '164px', height: 'auto' }}
          />
          <h2 className={'mb-2'}>Something went terribly wrong...</h2>
          <p>Our engineers have been notified and will resolve this shortly.</p>
          <p className={'mb-4'}>In the mean time, try refreshing the page.</p>
          <button
            className={
              'px-4 py-2 border-0 bg-default hover:bg-grey-darkest text-white cursor-pointer'
            }
            onClick={() => Sentry.showReportDialog()}
          >
            Report feedback
          </button>
        </div>
      );
    }
    return this.props.children;
  }
}

export default ErrorBoundary;

import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import {
  Company,
  Crew,
  Form,
  Site,
  User as IUser,
  CompanyGroup,
} from '../types';
import { SubmissionStats } from '../api/company.api';
import DateFilterWidget from '../components/DateFilterWidget';
import SubmissionStatisticsMain from './submissionStatistics/Main';

interface Props extends WithNamespaces {
  company?: Company;
  companyGroup?: CompanyGroup;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
  companies?: Company[];
  queryOverrides?: Partial<SubmissionStats>;
}

const Submissions = (props: Props) => {
  const tabs = [
    {
      accessor: 'forms',
      label: 'Forms',
    },
    {
      accessor: 'map',
      label: 'Map',
    },
    {
      accessor: 'dataReporting',
      label: 'Data Reporting',
    },
  ];

  return (
    <div className={'mb-4 relative'}>
      <DateFilterWidget defaultView="last30Days">
        {({ start, end, Widget, getWidgetProps }) => (
          <Widget tabs={tabs} {...getWidgetProps()}>
            {({ tab }) => (
              <SubmissionStatisticsMain
                start={start}
                end={end}
                tab={tab!}
                {...props}
              />
            )}
          </Widget>
        )}
      </DateFilterWidget>
    </div>
  );
};

export default withNamespaces()(Submissions);

import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const Calendar = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z" />
  </svg>
);

export default BaseSVG(Calendar);

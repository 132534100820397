import { call, takeLatest, put } from 'redux-saga/effects';
import { FORMS_FETCH_REQUEST } from '../constants/actionTypes';
import { formsFetchSuccess, formsFetchFailure } from '../actions/forms';
import companyApi from '../api/company.api';

export function* getForms(params: any) {
  const { data, errors } = yield call(companyApi.forms, params.payload);
  if (data) {
    yield put(formsFetchSuccess(data.forms));
  } else if (errors) {
    yield put(formsFetchFailure(errors));
  }
}

export function* watchGetForms() {
  yield takeLatest(FORMS_FETCH_REQUEST, getForms);
}

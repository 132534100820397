import api, { api2 } from './api';
import { SubmissionStats, ActionsFilters } from './company.api';
import {
  Action,
  User,
  IncidentProp,
  IncidentPropOption,
  CompanyGroup,
  Company,
  Crew,
} from '../types';

export interface CreateCompany {
  name: string;
  csvPlan: number;
  reminders: boolean;
  tempUnit: string;
  auditForms: boolean;
  incidents: boolean;
  spence: boolean;
}

interface CompanyGroupActionsFilters extends ActionsFilters {
  companyUids?: string[];
  crewUids?: string[];
}

const companyGroupApi = {
  all: () => api2<{ companyGroups: CompanyGroup[] }>('GET', `company-groups`),
  create: (data: { name: string }) =>
    api2<{ companyGroup: CompanyGroup }>('POST', `company-groups`, data),
  admins: (companyGroupUid: string) =>
    api2<{ users: User[] }>('GET', `company-groups/${companyGroupUid}/admins`),
  setAdmins: (companyGroupUid: string, adminUids: string[]) =>
    api2('PUT', `company-groups/${companyGroupUid}/admins`, { adminUids }),
  sites: (companyGroupUid: string) =>
    api2('GET', `company-groups/${companyGroupUid}/sites`),
  crews: (companyGroupUid: string) =>
    api2<{ crews: Crew[] }>('GET', `company-groups/${companyGroupUid}/crews`),
  forms: (companyGroupUid: string) =>
    api2('GET', `company-groups/${companyGroupUid}/forms`),
  users: (
    companyGroupUid: string,
    filters?: {
      userUids?: string[];
      crewUids?: string[];
      companyUids?: string[];
      siteUids?: string[];
    },
  ) =>
    api2<{ users: User[] }>(
      'GET',
      `company-groups/${companyGroupUid}/users`,
      filters,
    ),
  companies: (companyGroupUid: string) =>
    api2('GET', `company-groups/${companyGroupUid}/companies`),
  createCompany: (companyGroupUid: string, data: CreateCompany) =>
    api2<{ company: Company }>(
      'POST',
      `company-groups/${companyGroupUid}/companies`,
      data,
    ),
  submissions: (companyGroupUid: string, data?: SubmissionStats) =>
    api('GET', `company-groups/${companyGroupUid}/submissions`, data),
  submissionStats: (companyGroupUid: string, data?: SubmissionStats) =>
    api('GET', `company-group-stats/${companyGroupUid}/submissions`, data),
  trainingCompliance: (companyGroupUid: string) =>
    api2<{ trainingCompliance: { [companyName: string]: number } }>(
      'GET',
      `company-group-stats/${companyGroupUid}/training-compliance`,
    ),
  userActivity: (
    companyGroupUid: string,
    filters: { start?: number; end?: number } = {},
  ) => api('GET', `company-groups/${companyGroupUid}/user-activity`, filters),
  checklists: (
    companyGroupUid: string,
    filters: { userUids?: string[] } = {},
  ) => api('GET', `company-groups/${companyGroupUid}/checklists`, filters),
  actionStats: (companyGroupUid: string, filters: ActionsFilters) =>
    api('GET', `company-group-stats/${companyGroupUid}/actions`, filters),
  actions: (companyGroupUid: string, filters: CompanyGroupActionsFilters) =>
    api2<{ actions: Action[] }>(
      'GET',
      `company-groups/${companyGroupUid}/actions`,
      filters,
    ),
  incidents: (
    companyGroupUid: string,
    filters: {
      start?: number;
      end?: number;
      siteUids?: string[];
      crewUids?: string[];
    } = {},
  ) => api('GET', `company-groups/${companyGroupUid}/incidents`, filters),
  exportIncidents: (
    companyGroupUid: string,
    filters: {
      start?: number;
      end?: number;
      siteUids?: string[];
      crewUids?: string[];
    } = {},
  ) =>
    api2('GET', `company-group-exports/${companyGroupUid}/incidents`, filters),
  auditStats: (
    companyUid: string,
    filters?: {
      formUids?: string[];
      companyUids?: string[];
      userUids?: string[];
      siteUids?: string[];
      crewUids?: string[];
      start?: number;
      end?: number;
    },
  ) => api2<any>('GET', `company-group-stats/${companyUid}/audits`, filters),
  exportAudits: (
    companyUid: string,
    filters?: {
      formUids?: string[];
      companyUids?: string[];
      userUids?: string[];
      siteUids?: string[];
      crewUids?: string[];
      start?: number;
      end?: number;
    },
  ) =>
    api2<any>(
      'GET',
      `company-group-exports/${companyUid}/audit-submission-summaries-csv`,
      filters,
    ),
  incidentProps: (companyGroupUid: string) =>
    api2<{
      incidentProps: IncidentProp[];
      incidentPropOptions: IncidentPropOption[];
    }>('GET', `company-groups/${companyGroupUid}/incident-props`),
  updateCompany: (
    companyGroupUid: string,
    companyUid: string,
    data: Partial<CreateCompany>,
  ) =>
    api2(
      'PATCH',
      `company-groups/${companyGroupUid}/companies/${companyUid}`,
      data,
    ),
};

export default companyGroupApi;

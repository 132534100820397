import * as React from 'react';
import { useSimpleFetch, useMountVisibility } from '../../helpers/hooks';
import incidentApi from '../../api/incident.api';
import { Action, User, Site } from '../../types';
import OptionTitle from '../form/OptionTitle';
import Button from '../../components/Button';
import ActionItem from '../../components/ActionItem';
import ActionCreate from '../ActionCreate';

interface Props {
  incidentUid: string;
  users: User[];
  sites: Site[];
  companyUid: string;
}

const IncidentActions = ({ incidentUid, users, sites, companyUid }: Props) => {
  const actions = useSimpleFetch<Action[]>({
    initial: [],
    accessor: 'actions',
    change: [incidentUid],
    fetch: () => incidentApi.actions(incidentUid),
  });
  const createActionState = useMountVisibility();
  const onActionCreate = () => {
    createActionState.close();
    actions.performFetch();
  };

  return (
    <div>
      <div className="flex flex-row items-center">
        <OptionTitle title={'Actions'} />
        <Button type={'primary'} onClick={createActionState.open}>
          Create
        </Button>
      </div>
      <div className="p-4">
        {actions.value.map(action => (
          <ActionItem key={action.uid} action={action} />
        ))}
      </div>

      {createActionState.mounted && (
        <ActionCreate
          isOpen={createActionState.visible}
          handleClose={createActionState.close}
          users={users}
          sites={sites}
          companyUid={companyUid}
          onCreate={onActionCreate}
          incidentUid={incidentUid}
        />
      )}
    </div>
  );
};

export default IncidentActions;

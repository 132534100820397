import { createAction } from 'typesafe-actions';
import { PermissionsError } from '../types';
import {
  ROLE_CERT_MATRIX_ADD,
  ROLE_CERT_MATRIX_FETCH_FAILURE,
  ROLE_CERT_MATRIX_FETCH_REQUEST,
  ROLE_CERT_MATRIX_FETCH_SUCCESS,
  ROLE_CERT_MATRIX_REMOVE,
  ROLE_CERT_MATRIX_SET,
} from '../constants/actionTypes';

type RequiredCertTypeUids = string[];

interface Matrix {
  [roleUid: string]: RequiredCertTypeUids;
}

export const roleCertificateMatrixFetchRequest = createAction(
  ROLE_CERT_MATRIX_FETCH_REQUEST,
  resolve => (companyUid: string) => resolve(companyUid),
);

export const roleCertificateMatrixFetchSuccess = createAction(
  ROLE_CERT_MATRIX_FETCH_SUCCESS,
  resolve => (matrix: Matrix) => resolve(matrix),
);

export const roleCertificateMatrixFetchFailure = createAction(
  ROLE_CERT_MATRIX_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const addRequiredCertificateToRole = createAction(
  ROLE_CERT_MATRIX_ADD,
  resolve => (roleUid: string, certTypeUid: string) =>
    resolve({ roleUid, certTypeUid }),
);

export const setRoleCertificateMatrix = createAction(
  ROLE_CERT_MATRIX_SET,
  resolve => (matrix: Matrix) => resolve(matrix),
);

export const removeReuiredCertificateFromRole = createAction(
  ROLE_CERT_MATRIX_REMOVE,
  resolve => (roleUid: string, certTypeUid: string) =>
    resolve({ roleUid, certTypeUid }),
);

const objectHelper = {
  values<T = any>(object: { [key: string]: T }) {
    const vals: T[] = [];
    for (const key of Object.keys(object)) {
      vals.push(object[key]);
    }
    return vals;
  },
};
export default objectHelper;

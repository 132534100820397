import { localStorageKeys } from '../constants/index';
import moment from 'moment';
import { attempt } from './index';
import meApi, { LocationResponse } from '../api/me.api';
import store from '../store';

export const getLocation = () => {
  return new Promise<null | LocationResponse>(resolve => {
    const location =
      attempt(() =>
        JSON.parse(localStorage.getItem(localStorageKeys.LOCATION) || ''),
      ) || {};
    const now = moment().unix();
    if (location && now < location.expires && location.location.address) {
      resolve(location);
    } else if (window.navigator && window.navigator.geolocation) {
      const company = store.getState().me.company;
      window.navigator.geolocation.getCurrentPosition(
        position => {
          meApi
            .location(company.uid, {
              lat: position.coords.latitude,
              lng: position.coords.longitude,
            })
            .then(res => {
              if (res.data) {
                localStorage.setItem(
                  localStorageKeys.LOCATION,
                  JSON.stringify({
                    expires: moment()
                      .add(15, 'minutes')
                      .unix(),
                    location: res.data.location.location,
                    weather: res.data.location.weather,
                  }),
                );
                resolve(res.data.location);
              } else {
                resolve(null);
              }
            });
        },
        () => {
          resolve(null);
        },
      );
    } else {
      resolve(null);
    }
  });
};

import * as React from 'react';
import { Md5 } from 'ts-md5';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import InlineInput from '../../components/InlineInput';
import User from '../../svg/User';
import Envelope from '../../svg/Envelope';
import {
  Company,
  User as UserInterface,
  Certificate,
  Storage,
} from '../../types';
import Button from '../../components/Button';
import Loading from '../../components/Loading';
import userApi from '../../api/user.api';
import { StoreState } from '../../store';
import InlineSelect from '../../components/InlineSelect';
import { SelectOption, SelectOptionObject } from '../../components/Select';
import Lock from '../../svg/Lock';
import Row from '../../components/Row';
import SideboxSectionContent from '../../components/sidebox/SideboxSectionContent';
import SideboxSectionHeader from '../../components/sidebox/SideboxSectionHeader';
import Power from '../../svg/Power';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { needsToBeFetched } from '../../helpers/globalState';
import { WorkRolesState } from '../../reducers/workRoles';
import { Dispatch } from 'redux';
import { workRolesFetchRequest } from '../../actions/workRoles';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import Cog from '../../svg/Cog';
import Apartment from '../../svg/Apartment';
import companyUserApi from '../../api/companyUser.api';
import AddToDivision from './AddToDivision';
import Trash from '../../svg/Trash';
import {
  getDivisionLabel,
  getDivisionLabelPlural,
  hideForADFS,
} from '../../helpers';
import UserCertificates from '../UserCertificates';

interface Props extends WithNamespaces {
  user: UserInterface;
  company: Company;
  workRoles: WorkRolesState;
  getWorkRoles: (companyUid: string) => any;
  hideRole?: boolean;
}

interface State {
  fname: string;
  lname: string;
  username: string;
  email: string;
  loading: boolean;
  errors: Errors;
  selectedRole: SelectOption;
  newPassword: string;
  newPasswordError: string;
  companiesWithRoles: any[];
  addDivision: boolean;
  certs: Array<{ cert: Certificate; image?: Storage }>;
}

interface Errors {
  username?: string[];
  fname?: string[];
  lname?: string[];
  email?: string[];
}

class UserOverview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      fname: '',
      lname: '',
      username: '',
      email: '',
      loading: false,
      errors: {},
      selectedRole: null,
      newPassword: '',
      newPasswordError: '',
      companiesWithRoles: [],
      addDivision: false,
      certs: [],
    };
  }

  public componentDidMount() {
    if (!this.props.user) {
      return;
    }

    const { username, email, fname, lname } = this.props.user;

    this.setState({
      fname: fname || '',
      lname: lname || '',
      username,
      email,
    });

    this.getCurrentRole();
    this.getCompanies();
    this.fetchCerts();

    if (needsToBeFetched(this.props.workRoles)) {
      this.props.getWorkRoles(this.props.company.uid);
    }
  }

  private fetchCerts = async () => {
    this.setState({
      loading: true,
    });
    const res = await userApi.certs(this.props.user.uid);
    if (res.data) {
      this.setState({
        certs: res.data.certificates,
        loading: false,
      });
    }
  };

  private onUpdate = () => {
    this.fetchCerts();
  };

  private getCurrentRole = async () => {
    const { user, company } = this.props;

    try {
      const { workRole } = await userApi.workRole(user.uid, company.uid);
      if (workRole) {
        this.setState({
          selectedRole: {
            label: workRole.name,
            value: workRole.uid,
          },
        });
      }
    } catch (e) {
      toast.error('Something went wrong getting the work role');
    }
  };

  private getCompanies = async () => {
    const { user } = this.props;
    const { data } = await userApi.companiesWithRoles(user.uid);
    if (data) {
      this.setState({
        companiesWithRoles: data.companies,
      });
    }
  };

  private onFnameChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      fname: event.currentTarget.value,
    });
  };

  private onLnameChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      lname: event.currentTarget.value,
    });
  };

  private onUsernameChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      username: event.currentTarget.value,
    });
  };

  private onEmailChange = (event: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      email: event.currentTarget.value,
    });
  };

  private onRoleChange = async (value: SelectOption) => {
    const previousRole = this.state.selectedRole;

    if (value === null) {
      return;
    }

    this.setState({
      selectedRole: value,
    });

    try {
      await userApi.attachWorkRole(this.props.user.uid, value.value);
      toast.success("Updated user's worker role");
    } catch (e) {
      this.setState({
        selectedRole: previousRole,
      });

      toast.error('Could not assign worker role');
    }
  };

  private submit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    this.setState({
      loading: true,
    });

    const { username, email, fname, lname } = this.state;

    try {
      await userApi.update(this.props.user.uid, {
        username,
        email,
        fname,
        lname,
      });
    } catch (e) {
      return this.setState({
        errors: e.response.data.errors,
      });
    } finally {
      this.setState({
        loading: false,
      });
    }

    toast.success('User Updated!');

    this.setState({
      errors: {},
    });
  };

  private onPasswordResetSubmit = async () => {
    this.setState({
      loading: true,
    });
    const { data, errors } = await userApi.updatePassword(this.props.user.uid, {
      password: this.state.newPassword,
    });
    if (data) {
      toast.success('Users password reset');
      this.setState({
        newPasswordError: '',
        newPassword: '',
      });
    }
    if (errors) {
      this.setState({
        newPasswordError: errors.password,
      });
    }
    this.setState({
      loading: false,
    });
  };

  private generateNewPassword = () => {
    this.setState({
      newPassword: Md5.hashStr(Math.random().toString(36))
        .toString()
        .substring(16),
    });
  };

  private onNewPasswordChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      newPassword: e.currentTarget.value,
    });
  };

  private onCompanyRoleChange = async (
    companyUid: string,
    newRole: SelectOptionObject,
  ) => {
    this.setState({
      companiesWithRoles: this.state.companiesWithRoles.map(
        x => (x.company.uid === companyUid ? { ...x, role: newRole.value } : x),
      ),
    });
    const { data } = await companyUserApi.updateRole(
      companyUid,
      this.props.user.uid,
      newRole.value,
    );
    if (data) {
      toast.success('Role updated');
    }
  };

  private openAddDivision = () => {
    this.setState({
      addDivision: true,
    });
  };

  private closeAddDivision = () => {
    this.setState({
      addDivision: false,
    });
  };

  private onDivisionAdd = () => {
    this.closeAddDivision();
    toast.success(`User added to ${getDivisionLabel().toLowerCase()}`);
    this.getCompanies();
  };

  private suspendFromDiv = async (companyUid: string) => {
    await companyUserApi.suspend(companyUid, this.props.user.uid);
    this.getCompanies();
  };

  public render() {
    const { user, t, workRoles, hideRole } = this.props;
    const {
      username,
      email,
      fname,
      lname,
      loading,
      errors,
      selectedRole,
      newPassword,
      newPasswordError,
      companiesWithRoles,
    } = this.state;

    if (!user) {
      return null;
    }

    const roleOptions = [
      { label: t('common:companyRoles.employee'), value: 'employee' },
      { label: t('common:companyRoles.trainee'), value: 'trainee' },
      { label: t('common:companyRoles.super'), value: 'super' },
    ];

    return (
      <div>
        <SideboxSectionContent>
          <form onSubmit={this.submit} style={{ position: 'relative' }}>
            <Loading loading={loading} />
            <InlineInput
              label={t('super:screens.users.firstNameInputLabel')}
              icon={<User />}
              value={fname}
              onChange={this.onFnameChange}
              disabled={hideForADFS(user)}
              inputProps={{
                placeholder: t('super:screens.users.firstNameInputPlaceholder'),
                tabIndex: loading ? -1 : undefined,
              }}
              errorMessage={errors.fname}
            />
            <InlineInput
              label={t('super:screens.users.lastNameInputLabel')}
              icon={<User />}
              value={lname}
              onChange={this.onLnameChange}
              disabled={hideForADFS(user)}
              inputProps={{
                placeholder: t('super:screens.users.lastNameInputPlaceholder'),
                tabIndex: loading ? -1 : undefined,
              }}
              errorMessage={errors.lname}
            />
            <InlineInput
              label={t('super:screens.users.username')}
              icon={<User />}
              value={username}
              onChange={this.onUsernameChange}
              disabled={hideForADFS(user)}
              inputProps={{
                placeholder: t('super:screens.users.username'),
                tabIndex: loading ? -1 : undefined,
              }}
              errorMessage={errors.username}
            />
            <InlineInput
              label={t('super:screens.users.email')}
              icon={<Envelope />}
              value={email}
              onChange={this.onEmailChange}
              disabled={hideForADFS(user)}
              inputProps={{
                placeholder: t('super:screens.users.email'),
                type: 'email',
                tabIndex: loading ? -1 : undefined,
              }}
              errorMessage={errors.email}
            />

            <Button
              type={'default'}
              block={true}
              tabIndex={loading ? -1 : undefined}
            >
              {t('common:submit')}
            </Button>
          </form>
        </SideboxSectionContent>
        <SideboxSectionHeader leftIcon={<Lock />} title={'Certificates'} />
        {this.state.certs.length === 0 ? (
          <p>This user does not have any certificates.</p>
        ) : (
          <UserCertificates certs={this.state.certs} onUpdate={this.onUpdate} />
        )}

        {!hideRole && (
          <>
            <SideboxSectionHeader
              leftIcon={<Lock />}
              title={t('super:screens.users.workRole')}
            />

            <SideboxSectionContent>
              <InlineSelect
                options={workRoles.data.map(i => ({
                  label: i.name,
                  value: i.uid,
                }))}
                label={t('super:screens.users.role')}
                icon={<User />}
                onChange={this.onRoleChange}
                value={selectedRole}
              />
            </SideboxSectionContent>
          </>
        )}

        <div className={hideForADFS(user) ? 'hidden' : ''}>
          <SideboxSectionHeader
            leftIcon={<Lock />}
            title={t('super:screens.users.password')}
          />

          <SideboxSectionContent>
            <InlineInput
              label={t('super:screens.users.password')}
              inputProps={{
                placeholder: t('super:screens.users.resetPassword'),
              }}
              icon={<Lock />}
              value={newPassword}
              onChange={this.onNewPasswordChange}
              errorMessage={newPasswordError}
            />
            <Row>
              <Button
                type={'default'}
                block={true}
                onClick={this.generateNewPassword}
              >
                {t('super:screens.users.generate')}
              </Button>
              <Button
                type={'primary'}
                block={true}
                onClick={this.onPasswordResetSubmit}
                loading={loading}
              >
                {t('super:screens.users.reset')}
              </Button>
            </Row>
          </SideboxSectionContent>
        </div>

        <SideboxSectionHeader
          leftIcon={<Lock />}
          title={`${getDivisionLabelPlural()}`}
          rightIcon={
            <Button type="primary" onClick={this.openAddDivision}>
              Add
            </Button>
          }
        />

        <SideboxSectionContent>
          <div className="flex flex-row justify-end mb-4" />
          {companiesWithRoles.map((x, i) => (
            <div key={x.company.uid} className="flex flex-row items-center">
              <div className="flex-1">
                <InlineSelect
                  options={[]}
                  label="Company"
                  icon={<Apartment />}
                  disabled={true}
                  value={{ label: x.company.name, value: x.company.uid }}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                />
                <InlineSelect
                  options={roleOptions}
                  label="Role"
                  icon={<Cog />}
                  value={roleOptions.find(y => y.value === x.role)}
                  menuPortalTarget={document.body}
                  menuPosition={'fixed'}
                  onChange={e =>
                    e && this.onCompanyRoleChange(x.company.uid, e as any)
                  }
                />
                {i !== companiesWithRoles.length - 1 && <hr className="mb-4" />}
              </div>
              <div>
                <Trash
                  className="text-red cursor-pointer w-6 h-6 mx-4"
                  onClick={() => this.suspendFromDiv(x.company.uid)}
                />
              </div>
            </div>
          ))}
        </SideboxSectionContent>

        <AddToDivision
          isOpen={this.state.addDivision}
          handleClose={this.closeAddDivision}
          user={this.props.user}
          onSuccess={this.onDivisionAdd}
        />
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    workRoles: state.workRoles,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getWorkRoles: (companyUid: string) =>
      dispatch(workRolesFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserOverview),
);

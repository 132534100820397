import * as React from 'react';
import { match } from 'react-router';
import { unidashUrl } from '../helpers/index';
import { useState, useEffect } from 'react';
import { History } from 'history';

interface Props {
  match: match<{ redirect: string }>;
  history: History;
}

function RedirectUni({ match: propsMatch, history }: Props) {
  const [redirectText, setRedirectText] = useState<string>('Redirecting...');
  const unidashRedirectUrl = unidashUrl(
    `/${decodeURIComponent(propsMatch.params.redirect)}`,
  );

  function redirectUni(url: string) {
    window.location.href = url;
  }

  function redirectDashboard() {
    history.push('/');
  }

  useEffect(() => {
    if (unidashRedirectUrl !== null) {
      return redirectUni(unidashRedirectUrl);
    }
    setRedirectText('Redirection failed');
    setTimeout(() => redirectDashboard(), 2000);
  }, []);

  return (
    <div>
      <text>{redirectText}</text>
    </div>
  );
}
export default RedirectUni;

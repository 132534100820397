import * as React from 'react';
import Sidebox from '../components/Sidebox';
import AllMessages from './messages/AllMessages';
import MessageDetail from './messages/MessageDetail';
import NewMessage from './messages/NewMessage';
import { connect } from 'react-redux';
import { StoreState } from '../store';
import { Company, User, Conversation } from '../types';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  user: User;
  users: User[];
}

const Messages = ({ isOpen, handleClose, company, user, users }: Props) => {
  const [currentView, setCurrentView] = React.useState<
    'all' | 'new' | 'detail'
  >('all');
  const [conversation, setConversation] = React.useState<Conversation | null>(
    null,
  );

  const openMessageDetail = (conv: Conversation) => {
    setCurrentView('detail');
    setConversation(conv);
  };

  const goToAllMessages = () => {
    setCurrentView('all');
  };

  const openNewMessage = () => {
    setCurrentView('new');
  };

  return (
    <Sidebox
      isOpen={isOpen}
      contentLabel={'Messages'}
      handleClose={handleClose}
    >
      {currentView === 'all' && (
        <AllMessages
          openMessageDetail={openMessageDetail}
          openNewMessage={openNewMessage}
          company={company}
          user={user}
        />
      )}
      {currentView === 'detail' &&
        conversation && (
          <MessageDetail
            openAllMessages={goToAllMessages}
            conversation={conversation}
          />
        )}
      {currentView === 'new' && (
        <NewMessage
          openAllMessages={goToAllMessages}
          openMessageDetail={openMessageDetail}
        />
      )}
    </Sidebox>
  );
};

export default connect((state: StoreState) => ({
  company: state.me.company,
  user: state.me.user,
  users: state.users.data,
}))(Messages);

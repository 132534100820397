import * as React from 'react';
import { InfoWindow, Marker } from 'react-google-maps';
import * as moment from 'moment';

interface Props {
  submission: any;
  position: { lat: number; lng: number };
  onClick: any;
  onClose: any;
  open: boolean;
  form: any;
}

export default class SubmissionsMarker extends React.Component<Props> {
  private onClick = () => {
    this.props.onClick(this.props.submission.uid);
  };

  public render() {
    const { position, onClose, open, form, submission } = this.props;
    return (
      <Marker position={position} onClick={this.onClick}>
        {open && (
          <InfoWindow onCloseClick={onClose}>
            <div>
              <strong>Form:</strong> {form.name}
              <br />
              <strong>Date:</strong>{' '}
              {moment.unix(submission.created_at).format('YYYY-MM-DD hh:mm a')}
              <br />
              <a
                href={`${process.env.PUBLIC_URL}/dashboard/worker/forms/${
                  submission.fcUid
                }/submission/${submission.uid}`}
                target="_blank"
                className="text-red"
              >
                Open Submission
              </a>
            </div>
          </InfoWindow>
        )}
      </Marker>
    );
  }
}

import { call, takeLatest, put } from 'redux-saga/effects';
import companyGroupApi from '../../api/companyGroup.api';
import { COMPANY_GROUP_SITES_FETCH_REQUEST } from '../../constants/actionTypes';
import {
  companyGroupSitesFetchFailure,
  companyGroupSitesFetchSuccess,
} from '../../actions/companyGroup/sites';

export function* getSites(params: any) {
  const { data, errors } = yield call(companyGroupApi.sites, params.payload);
  if (errors) {
    yield put(companyGroupSitesFetchFailure(errors));
    return;
  }

  yield put(companyGroupSitesFetchSuccess(data.sites));
}

export function* watchGetCompanyGroupSites() {
  yield takeLatest(COMPANY_GROUP_SITES_FETCH_REQUEST, getSites);
}

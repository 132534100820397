import * as React from 'react';
import InlineSelect from '../InlineSelect';
import { SelectOption, SelectOptionObject } from '../Select';
import UserGroup from '../../svg/UserGroup';
import User from '../../svg/User';
import Location from '../../svg/Location';
import { Crew, Form, Site, User as IUser, Company } from '../../types';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import File from '../../svg/File';
import Suitcase from '../../svg/Suitcase';
import { getSiteLabel, getCrewLabel, getDivisionLabel } from '../../helpers';

export const getTypeOptions = (t?: any) => {
  return [
    { label: 'All', value: 'all' },
    { label: 'Submissions', value: 'submitter' },
    { label: 'Sign Offs', value: 'signer' },
  ];
};

interface EntityFilterSelections {
  users?: SelectOptionObject[];
  crews?: SelectOptionObject[];
  form?: SelectOption;
  sites?: SelectOptionObject[];
  type?: SelectOption;
  companies?: SelectOptionObject[];
}

interface Props extends WithNamespaces {
  entities: {
    users?: IUser[];
    crews?: Crew[];
    forms?: Form[];
    sites?: Site[];
    companies?: Company[];
  };
  filters: EntityFilterSelections;
  onFilterChange: (filters: EntityFilterSelections) => void;
  filterV2: boolean;
}

class EntityFilter extends React.Component<Props> {
  private onFormFilterChange = (form: SelectOption) => {
    this.props.onFilterChange({
      ...this.props.filters,
      form,
    });
  };

  private onCompanyFilterChange = (companies: SelectOptionObject[]) => {
    this.props.onFilterChange({
      ...this.props.filters,
      companies,
    });
  };

  private onSitesFilterChange = (sites: SelectOptionObject[]) => {
    this.props.onFilterChange({
      ...this.props.filters,
      sites,
    });
  };

  private onCrewsFilterChange = (crews: SelectOptionObject[]) => {
    this.props.onFilterChange({
      ...this.props.filters,
      crews,
    });
  };

  private onUsersFilterChange = (users: SelectOptionObject[]) => {
    this.props.onFilterChange({
      ...this.props.filters,
      users,
    });
  };

  private onUserSubmissionTypeChange = (type: SelectOption) => {
    this.props.onFilterChange({
      ...this.props.filters,
      type,
    });
  };

  public render() {
    const { entities, filters, t, filterV2 } = this.props;
    const { users, forms, sites, crews, companies } = entities;

    const userOptions = (users || []).map(u => ({
      label: u.username,
      value: u.uid,
    }));
    const formOptions = (forms || [])
      .filter(({ v2 }) => (filterV2 ? !v2 : true))
      .map(u => ({
        label: u.name,
        value: u.uid,
      }));
    const siteOptions = (sites || []).map(u => ({
      label: u.name,
      value: u.uid,
    }));
    const crewOptions = (crews || []).map(u => ({
      label: u.name,
      value: u.uid,
    }));
    const companyOptions = (companies || []).map(u => ({
      label: u.name,
      value: u.uid,
    }));

    return (
      <div className={'flex flex-col md:flex-row p-4'}>
        {entities.companies && (
          <div className="flex-1 mx-2">
            <InlineSelect
              options={companyOptions}
              label={`${getDivisionLabel()}`}
              icon={<Suitcase />}
              value={filters.companies}
              isMulti={true}
              onChange={this.onCompanyFilterChange}
              isClearable={true}
            />
          </div>
        )}
        {entities.forms && (
          <div className="flex-1 mx-2">
            <InlineSelect
              options={formOptions}
              label={t('common:form')}
              icon={<File />}
              value={filters.form}
              onChange={this.onFormFilterChange}
              isClearable={true}
            />
          </div>
        )}
        {entities.sites && (
          <div className="flex-1 mx-2">
            <InlineSelect
              options={siteOptions}
              label={`${getSiteLabel()}`}
              icon={<Location />}
              isMulti={true}
              value={filters.sites}
              onChange={this.onSitesFilterChange}
            />
          </div>
        )}
        {entities.crews && (
          <div className="flex-1 mx-2">
            <InlineSelect
              options={crewOptions}
              label={`${getCrewLabel()}`}
              icon={<UserGroup />}
              isMulti={true}
              value={filters.crews}
              onChange={this.onCrewsFilterChange}
            />
          </div>
        )}
        {entities.users && (
          <div className="flex-1 mx-2">
            <InlineSelect
              options={userOptions}
              label={t('common:user')}
              icon={<User />}
              isMulti={true}
              value={filters.users}
              onChange={this.onUsersFilterChange}
            />
          </div>
        )}
        {filters.users &&
          filters.users.length > 0 &&
          !filters.form && (
            <div className="flex-1 mx-2">
              <InlineSelect
                options={getTypeOptions()}
                label={t('common:type')}
                icon={<User />}
                value={filters.type}
                onChange={this.onUserSubmissionTypeChange}
              />
            </div>
          )}
      </div>
    );
  }
}

export default withNamespaces()(EntityFilter);

import * as React from 'react';
import Modal from '../../components/Modal';
import ModalHeader from '../../components/modal/ModalHeader';
import ModalBody from '../../components/modal/ModalBody';
import InlineSelect from '../../components/InlineSelect';
import Apartment from '../../svg/Apartment';
import Cog from '../../svg/Cog';
import ModalFooter from '../../components/modal/ModalFooter';
import Button from '../../components/Button';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Company, User } from '../../types';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {
  useOptionalSelectInput,
  useRequiredSelectInput,
} from '../../helpers/hooks';
import companyApi from '../../api/company.api';

interface Props extends WithNamespaces {
  handleClose: () => void;
  isOpen: boolean;
  companies: Company[];
  user: User;
  onSuccess: () => void;
}

const AddToDivision = ({
  isOpen,
  handleClose,
  companies,
  t,
  user,
  onSuccess,
}: Props) => {
  const companyOptions = companies.map(x => ({ label: x.name, value: x.uid }));
  const roleOptions = [
    { label: t('common:companyRoles.employee'), value: 'employee' },
    { label: t('common:companyRoles.trainee'), value: 'trainee' },
    { label: t('common:companyRoles.super'), value: 'super' },
  ];

  const company = useRequiredSelectInput(companyOptions[0]);
  const role = useRequiredSelectInput(roleOptions[0]);
  const [loading, setLoading] = React.useState(false);

  const submit = async () => {
    setLoading(true);
    const { data } = await companyApi.attachExistingUser(
      company.selected,
      user.uid,
      role.selected,
    );
    setLoading(false);
    if (data) {
      onSuccess();
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Add user to division"
    >
      <ModalHeader handleClose={handleClose}>Add to Division</ModalHeader>
      <ModalBody>
        <InlineSelect
          icon={<Apartment />}
          label="Division"
          options={companyOptions}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          {...company}
        />
        <InlineSelect
          icon={<Cog />}
          label="Role"
          options={roleOptions}
          menuPortalTarget={document.body}
          menuPosition={'fixed'}
          {...role}
        />
      </ModalBody>
      <ModalFooter right={true}>
        <Button type="primary" loading={loading} onClick={submit}>
          Submit
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default withNamespaces()(
  connect((state: StoreState) => ({
    companies: state.me.companies,
  }))(AddToDivision),
);

import * as React from 'react';
import moment from 'moment';
import ResourceTable from '../../components/ResourceTable';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { SelectOptionSubmissionFilters } from './Main';

interface Props extends WithNamespaces {
  forms: any;
  onRowClick: (formData: { formUid: string; formV2: boolean }) => void;
  filters: SelectOptionSubmissionFilters;
  formattedFilters: any;
}

const SubmissionsTable = ({ forms, t, onRowClick }: Props) => {
  const data = forms.map((x: any) => ({
    ...x,
    key: x.formName,
  }));

  return (
    <ResourceTable
      defaultSortKey={'allSubmissions'}
      defaultSortDesc={true}
      data={data}
      columns={[
        { Header: t('common:form'), accessor: 'key' },
        {
          Header: t('widgets:submissions.allSubmissions'),
          accessor: 'allSubmissions',
        },
        {
          Header: t('widgets:submissions.earliest'),
          accessor: (r: any) => r,
          id: 'earliest',
          Cell: (row: any) =>
            moment
              .unix(row.original.earliest)
              .utc()
              .format('hh:mm a'),
        },
        {
          Header: t('widgets:submissions.average'),
          accessor: (r: any) => r,
          id: 'average',
          Cell: (row: any) =>
            moment
              .unix(row.original.average)
              .utc()
              .format('hh:mm a'),
        },
        {
          Header: t('widgets:submissions.latest'),
          accessor: (r: any) => r,
          id: 'latest',
          Cell: (row: any) =>
            moment
              .unix(row.original.latest)
              .utc()
              .format('hh:mm a'),
        },
        {
          Header: t('widgets:submissions.submFrequency'),
          accessor: (r: any) => r,
          id: 'submFrequency',
          Cell: (row: any) => {
            const val = (row.original.submFrequency / 86400).toFixed(1);
            return val !== '0.0' ? `${val} days` : '';
          },
        },
      ]}
      onRowClick={row => {
        if (row.hasOwnProperty('form')) {
          onRowClick({ formUid: row.form.uid, formV2: true });
        } else {
          onRowClick({ formUid: row.formUid, formV2: false });
        }
      }}
    />
  );
};

export default withNamespaces()(SubmissionsTable);

import * as React from 'react';
import * as Sentry from '@sentry/browser';
import styled from 'styled-components';
import { Router, Route, Redirect } from 'react-router-dom';
import { Provider } from 'react-redux';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import Login from './screens/Login';
import history from './services/history';
import Dashboard from './screens/Dashboard';
import Logout from './screens/Logout';
import store from './store';
import './App.css';
import './tailwind.css';
import 'react-toggle/style.css';
import { biggerThanSM } from './helpers/style';
import AcceptCrewInvite from './screens/AcceptCrewInvite';
import ErrorBoundary from './components/ErrorBoundary';
import ReactGA from 'react-ga';
import Auth0Callback from './screens/Auth0Callback';
import RedirectUni from './screens/RedirectUni';
import { localStorageKeys } from './constants/index';
import { useEffect } from 'react';
import VerifyEmail from './screens/VerifyEmail';

if (process.env.NODE_ENV === 'production') {
  ReactGA.initialize('UA-124763303-1');
  ReactGA.pageview(window.location.pathname + window.location.search);
  history.listen(location => {
    ReactGA.pageview(location.pathname + location.search);
  });
  Sentry.init({
    dsn: 'https://53840378eff241fbb6863eb16187236a@sentry.io/1410628',
  });
}

const Container = styled.div`
  ${biggerThanSM(`
    height: 100vh;
  `)};
`;

function checkIfFromUnidash() {
  const params = new URLSearchParams(window.location.search);
  const token = params.get('token');
  const refresh = params.get('refresh');
  const companyUid = params.get('companyUid');
  const companyGroupUid = params.get('companyGroupUid');
  if (!token || !refresh || !companyUid || !companyGroupUid) {
    return;
  }
  localStorage.setItem(localStorageKeys.USER_TOKEN, token);
  localStorage.setItem(localStorageKeys.REFRESH_TOKEN, refresh);
  localStorage.setItem(localStorageKeys.CURRENT_COMPANY_UID, companyUid);
  localStorage.setItem(
    localStorageKeys.CURRENT_COMPANY_GROUP_UID,
    companyGroupUid,
  );
  const [hrefWithoutQuery] = window.location.href.split('?');
  window.location.href = hrefWithoutQuery;
}

const App = () => {
  const [ready, setReady] = React.useState(false);

  useEffect(() => {
    checkIfFromUnidash();
    setReady(true);
  }, []);

  if (!ready) {
    return null;
  }

  return (
    <Provider store={store}>
      <Router history={history}>
        <ErrorBoundary>
          <Container>
            <Route path="/redirect-uni/:redirect" component={RedirectUni} />
            <Route
              exact={true}
              path="/"
              render={() => <Redirect to="/dashboard/worker/dashboard" />}
            />
            <Route path="/dashboard" component={Dashboard} />
            <Route
              exact={true}
              path={'/auth0-callback'}
              component={Auth0Callback}
            />
            <Route exact={true} path={'/login'} component={Login} />
            <Route exact={true} path={'/logout'} component={Logout} />
            <Route
              exact={true}
              path={'/crew-invitations/:crewInviteUid'}
              component={AcceptCrewInvite}
            />
            <Route path={'/verify'} component={VerifyEmail} />
            <ToastContainer position={toast.POSITION.BOTTOM_LEFT} />
          </Container>
        </ErrorBoundary>
      </Router>
    </Provider>
  );
};

export default App;

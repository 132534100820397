import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { match } from 'react-router';
import Tabs from '../../components/Tabs';
import siteApi from '../../api/site.api';
import { toast } from 'react-toastify';
import { Company, Crew, Form, Site as ISite, User } from '../../types';
import Loading from '../../components/Loading';
import Forms from './site/Forms';
import Crews from './site/Crews';
import { History } from 'history';
import PageToolbar from '../../components/PageToolbar';
import Book from '../../svg/Book';
import Cog from '../../svg/Cog';
import Trash from '../../svg/Trash';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import SiteEdit from './site/SiteEdit';
import SiteDuplicate from './site/SiteDuplicate';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import Managers from './site/Managers';
import { Dispatch } from 'redux';
import { setSiteForms } from '../../actions/super/siteForms';
import { setSiteCrews } from '../../actions/super/siteCrews';
import { setSiteManagers } from '../../actions/super/siteManagers';
import { addSite, removeSite } from '../../actions/sites';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import {
  useSimpleFetch,
  useMountVisibility,
  useFirstRender,
} from '../../helpers/hooks';
import {
  getCrewLabelPlural,
  getSiteLabel,
  getSiteLabelPlural,
} from '../../helpers';

interface Props extends WithNamespaces {
  match: match<{ siteUid: string }>;
  company: Company;
  history: History;
  setSiteForms: (forms: Form[]) => void;
  setSiteCrews: (crews: Crew[]) => void;
  setSiteManagers: (users: User[]) => void;
  forms: Form[];
  crews: Crew[];
  managers: User[];
  addSite: (site: ISite) => any;
  removeSite: (siteUid: string) => any;
}

const Site = ({ company, history, t, ...props }: Props) => {
  const siteUid = props.match.params.siteUid;
  const crews = useSimpleFetch<Crew[]>({
    accessor: 'crews',
    initial: [],
    change: [siteUid],
    fetch: () => siteApi.crews(siteUid),
  });
  const forms = useSimpleFetch<Form[]>({
    accessor: 'forms',
    initial: [],
    change: [siteUid],
    fetch: () => siteApi.forms(siteUid),
  });
  const managers = useSimpleFetch<User[]>({
    accessor: 'managers',
    initial: [],
    change: [siteUid],
    fetch: () => siteApi.managers(siteUid),
  });
  const site = useSimpleFetch<ISite | null>({
    accessor: 'site',
    initial: null,
    change: [siteUid],
    fetch: () => siteApi.show(siteUid),
  });
  const editState = useMountVisibility();
  const dupState = useMountVisibility();
  const deleteState = useMountVisibility();
  const [deleting, setDeleting] = React.useState(false);
  const isFirstRender = useFirstRender();

  React.useEffect(
    () => {
      if (!isFirstRender) {
        history.push('/dashboard/super/sites');
      }
    },
    [company.uid],
  );

  const onSiteDuplicate = (newSite: ISite) => {
    dupState.close();
    props.addSite(newSite);
    history.push(`/dashboard/super/sites`);
    toast.success(`${getSiteLabel()} duplicated`);
  };

  const onSiteUpdate = (newSite: ISite) => {
    editState.close();
    site.setValue(newSite);
  };

  const onDeleteSite = async () => {
    setDeleting(true);
    const { data, errors } = await siteApi.deactivate(siteUid);
    if (data) {
      props.removeSite(siteUid);
      deleteState.close();
      toast.success(`${getSiteLabel()} deleted`);
      history.push('/dashboard/super/sites');
    }
    if (errors) {
      toast.error(`Could not delete ${getSiteLabel().toLowerCase()}`);
    }
    setDeleting(false);
  };

  if (!site.value) {
    return <Loading loading={site.loading} />;
  }

  const tabs = [
    {
      label: t('super:screens.site.formsTabLabel'),
      component: (
        <Forms
          forms={forms.value}
          loading={forms.loading}
          site={site.value}
          getForms={forms.performFetch}
          fetched={forms.fetched}
          addSiteForm={form => forms.setValue(prev => [...prev, form])}
        />
      ),
    },
    {
      label: `${getCrewLabelPlural()}`,
      component: (
        <Crews
          crews={crews.value}
          site={site.value}
          getCrews={crews.performFetch}
          loading={crews.loading}
          fetched={crews.fetched}
          addSiteCrew={crew => crews.setValue(prev => [...prev, crew])}
        />
      ),
    },
    {
      label: `${getSiteLabel()} Managers`,
      component: (
        <Managers
          site={site.value}
          managers={managers.value}
          fetched={managers.fetched}
          loading={managers.loading}
          addSiteManager={user => managers.setValue(prev => [...prev, user])}
          removeSiteManager={uid =>
            managers.setValue(prev => prev.filter(i => i.uid !== uid))
          }
          setSiteManagers={users => managers.setValue(users)}
        />
      ),
    },
  ];

  return (
    <Page>
      <PageToolbar
        title={site.value.name}
        actions={[
          {
            label: t('common:duplicate'),
            icon: <Book />,
            onClick: dupState.open,
          },
          {
            label: t('common:edit'),
            icon: <Cog />,
            onClick: editState.open,
          },
          { icon: <Trash />, type: 'primary', onClick: deleteState.open },
        ]}
      />

      <PageContent>
        <Tabs tabs={tabs} raised={true} />
      </PageContent>

      <SiteEdit
        isOpen={editState.visible}
        handleClose={editState.close}
        handleUpdate={onSiteUpdate}
        site={site.value}
      />
      <SiteDuplicate
        isOpen={dupState.visible}
        handleClose={dupState.close}
        handleUpdate={onSiteDuplicate}
        site={site.value}
      />
      <ConfirmationDialogue
        contentLabel={`Delete ${getSiteLabel()}`}
        title={`Delete ${getSiteLabel()}`}
        body={t(
          `Are you sure you want to delete this ${getSiteLabel().toLowerCase()}?`,
        )}
        isOpen={deleteState.visible}
        handleClose={deleteState.close}
        onConfirm={onDeleteSite}
        loading={deleting}
      />
    </Page>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    forms: state.super.siteForms,
    crews: state.super.siteCrews,
    managers: state.super.siteManagers,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    setSiteForms: (forms: Form[]) => dispatch(setSiteForms(forms)),
    setSiteCrews: (crews: Crew[]) => dispatch(setSiteCrews(crews)),
    setSiteManagers: (users: User[]) => dispatch(setSiteManagers(users)),
    addSite: (site: ISite) => dispatch(addSite(site)),
    removeSite: (siteUid: string) => dispatch(removeSite(siteUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Site),
);

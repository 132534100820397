import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const ChevronDown = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z" />
  </svg>
);

export default BaseSVG(ChevronDown);

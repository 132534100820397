import * as React from 'react';
import { User } from '../types';
import Avatar from './Avatar';
import Trash from '../svg/Trash';
import styled from 'styled-components';
import { cssVars } from '../constants/index';
import { useState } from 'react';
import CloseOutline from '../svg/CloseOutline';
// @ts-ignore
import ReactTooltip from 'react-tooltip';

const TrashButton = styled.button`
  background: transparent;
  border: 1px solid ${cssVars.colors.grey};
  padding: ${cssVars.padding[3]};
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: grey;
    color: white;
  }
`;

const AvatarDeleteButton = styled.button`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface Props {
  users: User[];
  onClick: (user: User) => any | void;
}

const RemovableAvatars = ({ users, onClick }: Props) => {
  const [editing, setEditing] = useState(false);

  return (
    <div className="flex flex-row flex-wrap">
      {users.map(user => (
        <div className="relative mb-1" data-tip={user.username}>
          {editing ? (
            <AvatarDeleteButton onClick={() => onClick(user)}>
              <CloseOutline className={'w-4 h-4'} />
            </AvatarDeleteButton>
          ) : null}
          <Avatar
            bgColor={user.profile_color[0]}
            fgColor={user.profile_color[1]}
            fallbackName={user.username}
            user={user}
          />
          <ReactTooltip effect={'solid'} />
        </div>
      ))}

      <TrashButton onClick={() => setEditing(!editing)}>
        <Trash className={'w-4 h-4'} />
      </TrashButton>
    </div>
  );
};

export default RemovableAvatars;

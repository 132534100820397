import * as React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';
import classNames from 'classnames';
import ErrorMessage from './ErrorMessage';

const InputWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const Wrapper = styled.div`
  display: flex;
  border: 2px solid #e5e5e5;
`;

const Label = styled.label<{ labelSize?: number }>`
  flex: ${props => (props.labelSize ? props.labelSize : 0.25)};
  background: #ededed;
  padding: 9px;
  text-transform: uppercase;
  display: flex;
  align-items: center;

  & svg {
    width: 12px;
    margin-right: 6px;
  }
`;

export interface Props {
  label: string;
  icon?: ReactNode;
  errorMessage?: string | string[];
  id?: string;
  labelSize?: number;
  className?: string;
  margin?: boolean;
}

export default class InlineInputBase extends React.Component<Props, {}> {
  public static defaultProps = {
    inputProps: {},
    labelSize: 0.25,
    margin: true,
  };

  public render() {
    const {
      icon,
      label,
      errorMessage,
      id,
      labelSize,
      className,
      margin,
    } = this.props;

    return (
      <InputWrapper className={classNames({ 'mb-4': margin }, className)}>
        <Wrapper>
          <Label htmlFor={id} labelSize={labelSize}>
            {icon}
            {label}
          </Label>
          {this.props.children}
        </Wrapper>
        {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
      </InputWrapper>
    );
  }
}

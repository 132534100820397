import { call, takeLatest, put } from 'redux-saga/effects';
import companyApi from '../api/company.api';
import { ROLE_CERT_MATRIX_FETCH_REQUEST } from '../constants/actionTypes';
import {
  roleCertificateMatrixFetchFailure,
  roleCertificateMatrixFetchSuccess,
} from '../actions/roleCertificateMatrix';

export function* getRoleCertificateMatrix(params: any) {
  try {
    const { matrix } = yield call(
      companyApi.roleCertificateMatrix,
      params.payload,
    );
    yield put(roleCertificateMatrixFetchSuccess(matrix));
  } catch (e) {
    yield put(roleCertificateMatrixFetchFailure(e.errors));
  }
}

export function* watchGetRoleCertificateMatrix() {
  yield takeLatest(ROLE_CERT_MATRIX_FETCH_REQUEST, getRoleCertificateMatrix);
}

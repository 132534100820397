import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import OptionTitle from './OptionTitle';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import WidgetContent from './WidgetContent';
import WidgetContainer from './WidgetContainer';
// @ts-ignore
import CanvasDraw from 'react-canvas-draw';
import styled from 'styled-components';
import { cssVars } from '../../constants/index';
import Checkmark from '../../svg/Checkmark';
import Button from '../../components/Button';
import FreeDrawCanvas from '../../legacy/FreeDrawCanvas';
import { useDeepEqualEffect } from '../../helpers/hooks';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { debounce } from 'lodash';
import { dataURItoBlob } from '../../helpers/index';
import submissionApi from '../../api/submission.api';
import storageApi from '../../api/storage.api';

const ColorButton = styled.button<{ bg: string }>`
  background: ${props => props.bg};
  width: 30px;
  height: 30px;
  border: 0;
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
`;

const BrushButton = styled.button<{ active?: boolean }>`
  background: ${props => (props.active ? 'white' : 'transparent')};
  padding: ${cssVars.padding[2]};
  display: flex;
  flex-direction: column;
  border: 0;
  cursor: pointer;
`;

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
  submData?: ParsedSubmData;
  editing?: boolean;
  submUid?: string;
}

const Sketch = ({ option, t, live, submData, editing, submUid }: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live }) || '';

  const buttonSizes = [1, 3, 5, 8, 12, 16];
  const buttonColors = [
    'white',
    'black',
    '#808080',
    '#09CC72',
    '#FDBE4A',
    '#FF7450',
    '#FC424B',
    '#00B5F6',
    '#745FE6',
    '#A48F85',
  ];

  const [color, setColor] = React.useState('black');
  const [size, setSize] = React.useState(3);
  const parsed = submData ? submData.parsed : {};
  const [bgUrl, setBgUrl] = React.useState<string | null>(null);
  const [checkedForExistingImg, setExistingImgCheck] = React.useState(false);

  const onDrawFinish = React.useCallback(
    debounce(async dataUrl => {
      if (!dataUrl) {
        return;
      }
      const blob = dataURItoBlob(dataUrl);
      const data = new FormData();
      data.append('uploads', blob);
      await submissionApi.uploadFormOptionFile(submUid!, option.id, data);
    }, 1000),
    [],
  );

  const setExistingSig = async () => {
    if (!parsed.file) {
      setExistingImgCheck(true);
      return;
    }
    const res = await storageApi.getPrivateUrlFromUid(parsed.file);
    if (res.data) {
      setBgUrl(res.data.url);
    }
    setExistingImgCheck(true);
  };

  useDeepEqualEffect(
    () => {
      setExistingSig();
    },
    [parsed],
  );

  const onClear = async (clear: any) => {
    clear();
    await submissionApi.removeFormOptionFile(submUid!, option.id);
  };

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent id={`option-${option.id}-sketch-container`}>
        {checkedForExistingImg && (
          <FreeDrawCanvas
            color={color}
            size={size}
            onMouseUp={onDrawFinish}
            background={bgUrl || undefined}
          >
            {({ getCanvasProps, clear }) => (
              <div className="border-2 border-solid border-border">
                <div className="flex flex-col lg:flex-row bg-grey lg:items-center py-2 px-4 lg:justify-between">
                  <div className="lg:flex-1 lg:mr-2 flex justify-between items-center">
                    {buttonColors.map(x => (
                      <ColorButton bg={x} key={x} onClick={() => setColor(x)}>
                        {color === x && (
                          <Checkmark className={'w-4 h-4 fill-current'} />
                        )}
                      </ColorButton>
                    ))}
                  </div>
                  <div className="lg:flex-1 lg:px-2 lg:mx-2 lg:border-0 lg:border-l-1 lg:border-r-1 lg:border-grey-dark lg:border-solid flex justify-between items-center">
                    {buttonSizes.map(x => (
                      <BrushButton
                        key={x}
                        active={size === x}
                        onClick={() => setSize(x)}
                      >
                        {x}
                        PX
                        <div
                          className="bg-black mt-2 w-full"
                          style={{ height: `${x}px` }}
                        />
                      </BrushButton>
                    ))}
                  </div>
                  <div>
                    <Button
                      type={'default'}
                      onClick={() => onClear(clear)}
                      className={'mr-2'}
                    >
                      Clear Screen
                    </Button>
                  </div>
                </div>
                <canvas
                  {...getCanvasProps()}
                  width="660"
                  height="330"
                  style={{ cursor: 'crosshair', width: '100%' }}
                />
              </div>
            )}
          </FreeDrawCanvas>
        )}
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    submUid: state.form.submissionUid,
    editable: state.form.editable,
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(Sketch));

import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import OptionTitle from './OptionTitle';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import WidgetContent from './WidgetContent';
import WidgetContainer from './WidgetContainer';
import StackedInput from '../../components/StackedInput';
// @ts-ignore
import CanvasDraw from 'react-canvas-draw';
import { useTextInput, useDeepEqualEffect } from '../../helpers/hooks';
import submissionApi from '../../api/submission.api';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { debounce } from 'lodash';
import { dataURItoBlob } from '../../helpers/index';
import storageApi from '../../api/storage.api';
import FreeDrawCanvas from '../../legacy/FreeDrawCanvas';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
  submData?: ParsedSubmData;
  submUid?: string;
  editable?: boolean;
}

const Signature = ({ option, t, live, submData, editable, submUid }: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live }) || '';

  const parsed = submData ? submData.parsed : {};

  const name = useTextInput(parsed.signature_name || '');
  const isFirstRun = React.useRef(true);
  const [bgUrl, setBgUrl] = React.useState<string | null>(null);
  const [checkedForExistingImg, setExistingImgCheck] = React.useState(false);

  React.useEffect(
    () => {
      if (isFirstRun.current) {
        isFirstRun.current = false;
        return;
      }
      if (!live) {
        return;
      }

      const handler = setTimeout(async () => {
        await submissionApi.updateFormOption(submUid!, option.id, name.value);
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    },
    [name.value],
  );

  const onDrawFinish = React.useCallback(
    debounce(async dataUrl => {
      if (!dataUrl) {
        return;
      }
      const blob = dataURItoBlob(dataUrl);
      const data = new FormData();
      data.append('uploads', blob);
      await submissionApi.uploadFormOptionFile(submUid!, option.id, data);
    }, 1000),
    [],
  );

  const onClear = async (clear: any) => {
    clear();
    await submissionApi.removeFormOptionFile(submUid!, option.id);
  };

  const setExistingSig = async () => {
    if (!parsed.file) {
      setExistingImgCheck(true);
      return;
    }
    const res = await storageApi.getPrivateUrlFromUid(parsed.file);
    if (res.data) {
      setBgUrl(res.data.url);
    }
    setExistingImgCheck(true);
  };

  useDeepEqualEffect(
    () => {
      if (
        !isFirstRun.current &&
        parsed.signature_name &&
        parsed.signature_name !== name.value
      ) {
        name.setValue(parsed.signature_name);
      }
      setExistingSig();
    },
    [parsed],
  );

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent id={`option-${option.id}-sig-container`}>
        <div className={'flex flex-col sm:flex-row max-w-full overflow-hidden'}>
          <div className="sm:w-1/2">
            <StackedInput
              label={'Name'}
              {...name}
              disabled={!live || !editable}
            />
          </div>
          <div className="flex-1 flex-no-grow sm:ml-4">
            {checkedForExistingImg && (
              <FreeDrawCanvas
                onMouseUp={onDrawFinish}
                background={bgUrl || undefined}
              >
                {({ getCanvasProps, clear }) => (
                  <>
                    <label className={'mb-3 block'}>
                      Signature{' '}
                      <span
                        className={'cursor-pointer hover:text-grey'}
                        onClick={() => onClear(clear)}
                      >
                        (clear)
                      </span>
                    </label>
                    <div className="border-2 border-solid border-border max-w-full overflow-hidden">
                      <canvas {...getCanvasProps()} />
                    </div>
                  </>
                )}
              </FreeDrawCanvas>
            )}
          </div>
        </div>
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    submUid: state.form.submissionUid,
    editable: state.form.editable,
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(Signature));

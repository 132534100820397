import * as React from 'react';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import DropUpload from '../../components/DropUpload';
import { toast } from 'react-toastify';
import submissionApi from '../../api/submission.api';
import { Dispatch } from 'redux';
import { updateSubmData } from '../../actions/submData';
import TextArea from '../../components/TextArea';
import { getPrivateUrlFromPath } from '../../components/PrivateImage';
import styled from 'styled-components';
import { cssVars } from '../../constants/index';
import { debounce, get } from 'lodash';
import Loading from '../../components/Loading';
import Modal from '../../components/Modal';
import ModalBody from '../../components/modal/ModalBody';

const ChangeButton = styled.button`
  background: transparent;
  padding: ${cssVars.padding[2]};
  color: white;
  cursor: pointer;
  border: 0;

  &:hover {
    background: white;
    color: black;
  }
`;

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
  editable?: boolean;
  submUid?: string;
  updateSubmData: (submData: ParsedSubmData) => any;
  submData?: ParsedSubmData;
  isOpen: boolean;
  contentLabel: string;
  handleClose: () => void;
}

const InspectionImageModal = ({
  option,
  t,
  live,
  submUid,
  editable,
  submData,
  updateSubmData: pUpdateSubmData,
  isOpen,
  contentLabel,
  handleClose,
}: Props) => {
  if (!option) {
    return null;
  }

  const images = get(submData, 'parsed.images', {});
  const hasMax = Object.keys(images).length >= 5;
  const [descriptions, setDescriptions] = React.useState({});
  const [loading, setLoading] = React.useState(false);

  const onDescDebounce = React.useCallback(
    debounce(async (desc: any) => {
      await submissionApi.updateFormOption(submUid!, option.id, {
        answer: '',
        comment: '',
        ...(submData && submData.parsed ? submData.parsed : {}),
        images: Object.keys(desc).map(key => ({
          key,
          comment: desc[key],
        })),
      });
    }, 500),
    [submData],
  );

  const onDrop = async (files: File[]) => {
    if (!live || !editable) {
      return;
    }
    if (files.length > 5 || hasMax) {
      toast.error('Cannot upload more than 5 files');
      return;
    }
    setLoading(true);
    const data = new FormData();
    files.forEach(file => data.append('uploads', file));
    const res = await submissionApi.uploadFormOptionFile(
      submUid!,
      option.id,
      data,
    );
    if (res.data) {
      pUpdateSubmData(res.data.submData);
    }
    setLoading(false);
  };

  const onDescChange = (key: string, value: string) => {
    const newDesc = { ...descriptions, [key]: value };
    setDescriptions(newDesc);
    onDescDebounce(newDesc);
  };

  const onRemove = async (key: string) => {
    setLoading(true);
    const res = await submissionApi.removeFormOptionFile(
      submUid!,
      option.id,
      key,
    );
    if (res.data) {
      pUpdateSubmData(res.data.submData);
    }
    setLoading(false);
  };

  // useDeepEqualEffect(
  //   () => {
  //     setDescriptions(
  //       Object.keys(images).reduce(
  //         (curr, next) => ({
  //           ...curr,
  //           [next]: images[next].comment,
  //         }),
  //         {},
  //       ),
  //     );
  //   },
  //   [images],
  // );

  const disabled = !editable || !live;

  return (
    <Modal
      isOpen={isOpen}
      contentLabel={contentLabel}
      handleClose={handleClose}
    >
      <ModalBody>
        <div className="p-5 flex flex-col items-center justify-center">
          <Loading loading={loading} />
          {disabled ? null : (
            <DropUpload
              block={true}
              onDrop={onDrop}
              disabled={disabled}
              accept={'image/*'}
            />
          )}
          <div className="flex flex-col w-full">
            <div className="flex flex-col md:flex-row items-center mt-4">
              {Object.keys(images).map(imageKey => (
                <>
                  <div
                    style={{ maxWidth: '200px' }}
                    className="mb-4 md:mb-0 md:mr-4 relative"
                  >
                    <a
                      href={getPrivateUrlFromPath(images[imageKey].full)}
                      target="_blank"
                    >
                      <img
                        src={getPrivateUrlFromPath(images[imageKey].full, {
                          thumb: true,
                        })}
                        className="max-w-full cursor-pointer"
                      />
                    </a>
                    <div
                      className="absolute flex justify-center w-full p-2"
                      style={{
                        bottom: 0,
                        left: 0,
                        background: 'rgba(0, 0, 0, 0.6)',
                        display: disabled ? 'none' : undefined,
                      }}
                    >
                      <ChangeButton
                        onClick={() => onRemove(imageKey)}
                        disabled={disabled}
                      >
                        REMOVE
                      </ChangeButton>
                    </div>
                  </div>
                  <TextArea
                    value={descriptions[imageKey]}
                    onChange={e =>
                      onDescChange(imageKey, e.currentTarget.value)
                    }
                    placeholder="Describe this image..."
                    className={'hidden'}
                  />
                </>
              ))}
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

function mapDispatch(dispatch: Dispatch) {
  return {
    updateSubmData: (submData: ParsedSubmData) =>
      dispatch(updateSubmData(submData)),
  };
}

export default withNamespaces()(
  connect(
    null,
    mapDispatch,
  )(InspectionImageModal),
);

import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import styled from 'styled-components';
import { cssVars } from '../../constants';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { useParsedSubmDataLocal } from '../../helpers/hooks';
import { biggerThanMD } from '../../helpers/style';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${cssVars.colors.border};

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  padding: ${cssVars.padding[4]};
  ${biggerThanMD(`
    padding: 0 ${cssVars.padding[4]};
  `)};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  ${biggerThanMD(`
    width: auto;
  `)};
`;

const Button = styled.button<{ bg?: string }>`
  background: ${props => (props.bg ? props.bg : cssVars.colors.border)};
  border: none;
  border-left: 1px solid black;
  padding: ${cssVars.padding[4]};
  cursor: pointer;
  flex: 1 1 0%;

  ${biggerThanMD(`
    flex: 0;
  `)} &:first-of-type {
    border-left: 0;
  }
`;

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  live?: boolean;
  submData?: ParsedSubmData;
  submUid?: string;
  editable?: boolean;
}

const YesNoItem = ({ option, t, live, submData, submUid, editable }: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live });

  const [value, setValue] = useParsedSubmDataLocal({
    option,
    submData,
    initial: '',
    submUid: submUid!,
  });

  return (
    <div className={'mb-4'}>
      <Wrapper>
        <Label>{title}</Label>
        <ButtonWrapper className="flex flex-nowrap">
          <Button
            bg={value === 'y' ? cssVars.colors.green : ''}
            disabled={!editable}
            onClick={() => setValue('y')}
          >
            {t('forms:yes')}
          </Button>
          <Button
            bg={value === 'n' ? cssVars.colors.red : ''}
            disabled={!editable}
            onClick={() => setValue('n')}
          >
            {t('forms:no')}
          </Button>
          <Button
            bg={value === 'na' ? cssVars.colors.default : ''}
            disabled={!editable}
            onClick={() => setValue('na')}
          >
            {t('forms:notApplicableShort')}
          </Button>
        </ButtonWrapper>
      </Wrapper>
    </div>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    editable: state.form.editable,
    submUid: state.form.submissionUid,
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(YesNoItem));

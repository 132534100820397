import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const ChevRightOutline = (props: SVGProps) => (
  <svg viewBox="0 0 20 20" {...props}>
    <path d="M10 0a10 10 0 1 1 0 20 10 10 0 0 1 0-20zM2 10a8 8 0 1 0 16 0 8 8 0 0 0-16 0zm10.54.7L9 14.25l-1.41-1.41L10.4 10 7.6 7.17 9 5.76 13.24 10l-.7.7z" />
  </svg>
);

export default BaseSVG(ChevRightOutline);

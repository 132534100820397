import * as React from 'react';
import { connect } from 'react-redux';
import { SideboxContent } from '../../../components/Sidebox';
import SideboxHeader from '../../../components/sidebox/SideboxHeader';
import {
  Form,
  Crew,
  User,
  Company,
  Site,
  Deployment as IDeployment,
  Storage,
} from '../../../types';
import Button from '../../../components/Button';
import { StoreState } from '../../../store';
import SelectFolder from './forms/SelectFolder';
import Approval from './forms/Approval';
import SendOnSubmission from './forms/SendOnSubmission';
import Reminders, {
  reminderTypeOptions,
  reminderPeriodOptions,
} from './forms/Reminders';
import Deployment, {
  deploymentTypeOptions,
  deploymentAudienceOptions,
} from './forms/Deployment';
import { Dispatch } from 'redux';
import { usersFetchRequest } from '../../../actions/users';
import { crewsFetchRequest } from '../../../actions/crews';
import siteApi from '../../../api/site.api';
import { toast } from 'react-toastify';
import Loading from '../../../components/Loading';
import { SelectOptionObject } from '../../../components/Select';
import deploymentApi from '../../../api/deployment.api';
import moment from 'moment';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import Trash from '../../../svg/Trash';
import Save from '../../../svg/Save';
import storageApi from '../../../api/storage.api';
import { getSiteLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  form: Form;
  handleClose: () => any | void;
  company: Company;
  crews: Crew[];
  users: User[];
  crewsLoaded: boolean;
  usersLoaded: boolean;
  getCrews: (companyUid: string) => any;
  getUsers: (companyUid: string) => any;
  site: Site;
  onRemove: () => void;
}

interface State {
  deployment: IDeployment | null;
  alert: boolean;
  alertReceivers: SelectOptionObject[];
  alertPdf: boolean;
  reminder: boolean;
  reminderType: SelectOptionObject;
  reminderPeriod: SelectOptionObject[];
  reminderTime: moment.Moment;
  reminderWeekends: boolean;
  folder: Storage | null;
  deploymentType: SelectOptionObject;
  deploymentAudience: SelectOptionObject;
  deploymentCustom: SelectOptionObject[];
  approval: boolean;

  loadingDeployment: boolean;
  savingDeployment: boolean;

  path: Storage[];
}

class FormSidebox extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      deployment: null,
      alert: false,
      alertPdf: false,
      reminder: false,
      reminderType: reminderTypeOptions(this.props.t)[0],
      reminderPeriod: [
        reminderPeriodOptions(this.props.t)[
          reminderTypeOptions(this.props.t)[0].value
        ],
      ],
      reminderTime: moment(),
      reminderWeekends: false,
      alertReceivers: [],
      folder: null,
      deploymentType: deploymentTypeOptions(this.props.t)[0],
      deploymentAudience: deploymentAudienceOptions(this.props.t)[0],
      deploymentCustom: [],
      approval: false,
      loadingDeployment: false,
      savingDeployment: false,
      path: [],
    };
  }

  public componentDidMount() {
    if (!this.props.usersLoaded) {
      this.props.getUsers(this.props.company.uid);
    }

    if (!this.props.crewsLoaded) {
      this.props.getCrews(this.props.company.uid);
    }

    this.getDeployment();
  }

  private getDeployment = async () => {
    this.setState({
      loadingDeployment: true,
    });

    const { t } = this.props;

    try {
      const {
        deployment,
        alert,
        reminder,
        alertReceivers,
        folder,
        receivers,
      } = await siteApi.deployment(this.props.site.uid, this.props.form.uid);

      if (deployment) {
        this.setState({
          deployment,
          deploymentType: deploymentTypeOptions(t).find(
            i => i.value === deployment.type,
          )!,
          deploymentAudience: deployment.all
            ? deploymentAudienceOptions(t).find(i => i.value === 'all')!
            : deploymentAudienceOptions(t).find(i => i.value === 'custom')!,
          deploymentCustom: receivers
            ? receivers.map((i: any) => ({
                label: deployment.type === 'user' ? i.username : i.name,
                value: i.uid,
              }))
            : [],
          approval: deployment.approval,
        });
      }

      if (folder) {
        this.getPath(folder);
        this.setState({
          folder,
        });
      }

      if (reminder) {
        const getReminderPeriod = (rem: any) => {
          if (rem.type === 'weekly') {
            return (rem.weekdays || []).map((i: string) => ({
              value: i,
              label: i,
            }));
          }

          if (rem.type === 'custom') {
            return (rem.days || []).map((i: string) => ({
              value: i,
              label: i,
            }));
          }

          return [];
        };

        this.setState({
          reminder: true,
          reminderType: reminderTypeOptions(this.props.t).find(
            i => i.value === reminder.type,
          )!,
          reminderWeekends: !!reminder.weekend,
          reminderTime: moment(reminder.time, 'H:mm'),
          reminderPeriod: getReminderPeriod(reminder),
        });
      }

      if (alert) {
        this.setState({
          alert: true,
          alertReceivers: alertReceivers.map((i: User) => ({
            label: i.username,
            value: i.uid,
          })),
          alertPdf: alert.pdf,
        });
      }
    } catch (e) {
      toast.error(
        this.props.t('super:screens.site.fetchDeploymentErrorMessage'),
      );
    }

    this.setState({
      loadingDeployment: false,
    });
  };

  private onFolderSelect = (folder: Storage) => {
    this.setState({
      folder,
    });
    this.getPath(folder);
  };

  private getPath = async (folder: Storage) => {
    const { data } = await storageApi.show(folder.uid);
    if (data) {
      this.setState({
        path: data.path,
      });
    }
  };

  private onDeploymentTypeChange = (deploymentType: SelectOptionObject) => {
    this.setState({
      deploymentType,
      deploymentCustom: [],
      deploymentAudience:
        deploymentType.value === 'site'
          ? deploymentAudienceOptions(this.props.t)[0]
          : this.state.deploymentAudience,
    });
  };

  private onDeploymentAudienceChange = (
    deploymentAudience: SelectOptionObject,
  ) => {
    this.setState({
      deploymentAudience,
    });
  };

  private onDeploymentCustomChange = (
    deploymentCustom: SelectOptionObject[],
  ) => {
    this.setState({
      deploymentCustom,
    });
  };

  private onApprovalChange = (approval: boolean) => {
    this.setState({
      approval,
    });
  };

  private onAlertChange = (alert: boolean) => {
    this.setState({
      alert,
    });
  };

  private onAlertReceiversChange = (alertReceivers: SelectOptionObject[]) => {
    this.setState({
      alertReceivers,
    });
  };

  private onReminderTypeChange = (reminderType: SelectOptionObject) => {
    this.setState({
      reminderType,
    });
  };

  private onReminderPeriodChange = (reminderPeriod: SelectOptionObject[]) => {
    this.setState({
      reminderPeriod,
    });
  };

  private onReminderChange = (reminder: boolean) => {
    this.setState({
      reminder,
    });
  };

  private onReminderWeekendsChange = (reminderWeekends: boolean) => {
    this.setState({
      reminderWeekends,
    });
  };

  private onReminderTimeChange = (reminderTime: moment.Moment) => {
    this.setState({
      reminderTime,
    });
  };

  private onSave = async () => {
    const { deployment, folder } = this.state;
    const { t } = this.props;

    this.setState({
      savingDeployment: true,
    });

    try {
      await deploymentApi.update(deployment!.uid, {
        storageUid: folder ? folder.uid : '',
        ...this.getReminderSaveData(),
        ...this.getDeploymentSaveData(),
        ...this.getAlertSaveData(),
      });
      toast.success(`Updated form for this ${getSiteLabel().toLowerCase()}`);
    } catch (e) {
      toast.error(t('super:screens.site.saveDeploymentErrorMessage'));
    }

    this.setState({
      savingDeployment: false,
    });
  };

  private getAlertSaveData = () => {
    return {
      alert: this.state.alert,
      ...(this.state.alert
        ? {
            alertData: {
              userUids: this.state.alertReceivers.map(i => i.value),
              pdf: this.state.alertPdf,
            },
          }
        : {}),
    };
  };

  private getDeploymentSaveData = () => {
    return {
      deployment: {
        approval: this.state.approval,
        type: this.state.deploymentType.value,
        all: this.state.deploymentAudience.value === 'all',
        ...(this.state.deploymentAudience.value === 'custom'
          ? { customUids: this.state.deploymentCustom.map(i => i.value) }
          : {}),
      },
    };
  };

  private getReminderSaveData = () => {
    const {
      reminder,
      reminderType,
      reminderTime,
      reminderWeekends,
      reminderPeriod,
    } = this.state;

    if (!reminder) {
      return {
        reminder: false,
      };
    }

    const baseData: any = {
      reminder: true,
      reminderData: {
        type: reminderType.value,
        time: reminderTime.format('H:mm'),
      },
    };

    switch (reminderType.value) {
      case 'daily':
        return {
          ...baseData,
          reminderData: {
            ...baseData.reminderData,
            weekend: !!reminderWeekends,
          },
        };
        break;
      case 'weekly':
        return {
          ...baseData,
          reminderData: {
            ...baseData.reminderData,
            weekdays: reminderPeriod.map(i => i.value),
          },
        };
      case 'custom':
        return {
          ...baseData,
          reminderData: {
            ...baseData.reminderData,
            days: reminderPeriod.map(i => +i.value),
          },
        };
      default:
        return {
          reminder: false,
        };
    }
  };

  private onAlertPdfChange = (alertPdf: boolean) => {
    this.setState({
      alertPdf,
    });
  };

  private removeFromSite = () => {
    this.props.onRemove();
  };

  public render() {
    const { form, handleClose, users, crews, t } = this.props;
    const {
      folder,
      deploymentAudience,
      deploymentCustom,
      deploymentType,
      alert,
      alertPdf,
      approval,
      alertReceivers,
      reminder,
      reminderType,
      reminderPeriod,
      reminderWeekends,
      loadingDeployment,
      reminderTime,
    } = this.state;

    if (loadingDeployment) {
      return <Loading loading={true} />;
    }

    return (
      <React.Fragment>
        <SideboxHeader
          handleClose={handleClose}
          buttons={[
            {
              Icon: Trash,
              label: `Remove From ${getSiteLabel()}`,
              onClick: this.removeFromSite,
            },
            {
              Icon: Save,
              label: 'Save',
              onClick: this.onSave,
            },
          ]}
        >
          {form.name}
        </SideboxHeader>
        <SideboxContent>
          <SelectFolder
            folder={folder}
            onSelect={this.onFolderSelect}
            path={this.state.path.slice(1)}
          />

          <Approval value={approval} onChange={this.onApprovalChange} />

          <SendOnSubmission
            users={users}
            sendEmails={alert}
            selected={alertReceivers}
            pdf={alertPdf}
            onPdfToggle={this.onAlertPdfChange}
            onToggle={this.onAlertChange}
            onSelect={this.onAlertReceiversChange}
          />

          <Reminders
            reminder={reminder}
            type={reminderType}
            period={reminderPeriod}
            weekend={reminderWeekends}
            time={reminderTime}
            onTypeChange={this.onReminderTypeChange}
            onPeriodChange={this.onReminderPeriodChange}
            onReminderChange={this.onReminderChange}
            onWeekendChange={this.onReminderWeekendsChange}
            onTimeChange={this.onReminderTimeChange}
          />

          <Deployment
            crews={crews}
            users={users}
            type={deploymentType}
            audience={deploymentAudience}
            custom={deploymentCustom}
            onTypeChange={this.onDeploymentTypeChange}
            onAudienceChange={this.onDeploymentAudienceChange}
            onCustomChange={this.onDeploymentCustomChange}
          />
        </SideboxContent>
      </React.Fragment>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    crews: state.crews.data,
    users: state.users.data,
    crewsLoaded: state.crews.loaded,
    usersLoaded: state.users.loaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
    getCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(FormSidebox),
);

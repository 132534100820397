import { createAction } from 'typesafe-actions';
import { Site, PermissionsError } from '../types';
import {
  SITES_FETCH_SUCCESS,
  SITES_ADD,
  SITES_REMOVE,
  SITES_FETCH_FAILURE,
  SITES_FETCH_REQUEST,
  SITES_INVALIDATE,
} from '../constants/actionTypes';

export const sitesFetchRequest = createAction(
  SITES_FETCH_REQUEST,
  resolve => (companyUid: string) => resolve(companyUid),
);

export const sitesFetchSuccess = createAction(
  SITES_FETCH_SUCCESS,
  resolve => (sites: Site[]) => resolve(sites),
);

export const sitesFetchFailure = createAction(
  SITES_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const addSite = createAction(SITES_ADD, resolve => (site: Site) =>
  resolve(site),
);

export const removeSite = createAction(
  SITES_REMOVE,
  resolve => (siteUid: string) => resolve(siteUid),
);

export const sitesInvalidate = createAction(SITES_INVALIDATE, resolve => () =>
  resolve(),
);

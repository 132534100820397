import * as React from 'react';
import {
  Company,
  Crew,
  CrewUser,
  WorkRoleLabels,
  User as IUser,
} from '../../types';
import InlineInput from '../../components/InlineInput';
import UserGroup from '../../svg/UserGroup';
import Button from '../../components/Button';
import SideboxSectionHeader from '../../components/sidebox/SideboxSectionHeader';
import InlineSelect from '../../components/InlineSelect';
import Cog from '../../svg/Cog';
import SideboxSectionContent from '../../components/sidebox/SideboxSectionContent';
import CategoryList from '../../components/CategoryList';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import crewApi from '../../api/crew.api';
import Loading from '../../components/Loading';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import User from '../../svg/User';
import { SelectOption } from '../../components/Select';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import Envelope from '../../svg/Envelope';
import CrewInvite from './CrewInvite';
import { getCrewLabel } from '../../helpers';

interface Props extends WithNamespaces {
  crew: Crew;
  company: Company;
  users: IUser[];
}

interface State {
  name: string;
  users: CrewUser[];
  loadingUsers: boolean;
  selectedRole: SelectOption;
  selectedUser: SelectOption;
  loadingUpdateCrew: boolean;
  loadingAssignNewUser: boolean;
  crewUpdateErrors: {
    name?: string;
  };
  inviteModalOpen: boolean;
}

class CrewOverview extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      name: '',
      users: [],
      loadingUsers: false,
      selectedUser: null,
      selectedRole: null,
      loadingUpdateCrew: false,
      loadingAssignNewUser: false,
      crewUpdateErrors: {},
      inviteModalOpen: false,
    };
  }

  public componentDidMount() {
    const { crew } = this.props;

    if (crew) {
      this.setState({
        name: crew.name,
      });
    }

    this.getCrewUsers();
  }

  private getCrewUsers = async () => {
    const { crew, t } = this.props;

    this.setState({
      loadingUsers: true,
    });

    try {
      const { users } = await crewApi.users(crew.uid);

      this.setState({
        users,
      });
    } catch (e) {
      toast.error(`Failed to fetch users on ${getCrewLabel().toLowerCase()}`);
    }

    this.setState({
      loadingUsers: false,
    });
  };

  private onNameChange = (e: React.FormEvent<HTMLInputElement>) => {
    this.setState({
      name: e.currentTarget.value,
    });
  };

  private groupUsersByRole = (users: CrewUser[]) =>
    R.groupBy(R.prop('role'), users);

  private removeUserFromCrew = async (item: CrewUser) => {
    this.setState({
      users: this.state.users.filter(i => i.uid !== item.uid),
    });
    await crewApi.removeUser(this.props.crew.uid, item.uid);
  };

  private changeUserRoleInCrew = async (
    user: CrewUser,
    newRole: WorkRoleLabels,
  ) => {
    const { users } = this.state;
    const { t, crew } = this.props;

    this.setState({
      users: users.map(c => {
        if (c.uid === user.uid) {
          return {
            ...user,
            role: newRole,
          };
        }
        return c;
      }),
    });

    try {
      await crewApi.attachUser(crew.uid, user.uid, newRole);

      toast.success(`Updated user role in ${getCrewLabel().toLowerCase()}`);
    } catch (e) {
      toast.error(t('super:screens.crews.updateUserRoleErrorMessage'));
    }
  };

  private getUserSelectOptions = (users: IUser[]) =>
    R.map(user => ({ label: user.username, value: user.uid }), users);

  private onUserSelect = (e: any) => {
    this.setState({
      selectedUser: e,
    });
  };

  private onRoleSelect = (role: SelectOption) => {
    this.setState({
      selectedRole: role,
    });
  };

  private assignUserToCrew = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { selectedUser, selectedRole } = this.state;
    const { t, crew } = this.props;

    if (!selectedUser || !selectedRole) {
      return;
    }

    this.setState({
      loadingAssignNewUser: true,
    });

    try {
      await crewApi.attachUser(
        crew.uid,
        selectedUser.value,
        selectedRole.value,
      );

      this.getCrewUsers();
    } catch (e) {
      toast.error(`Failed to add user to ${getCrewLabel().toLowerCase()}`);
    }

    this.setState({
      selectedUser: null,
      selectedRole: null,
      loadingAssignNewUser: false,
    });
  };

  private updateCrew = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { t, crew } = this.props;
    const { name } = this.state;

    this.setState({
      loadingUpdateCrew: true,
    });

    try {
      await crewApi.update(crew.uid, { name });
      toast.success(`${getCrewLabel()} updated`);
      this.setState({
        crewUpdateErrors: {},
      });
    } catch (e) {
      toast.error(`Failed to update ${getCrewLabel().toLowerCase()}`);
      this.setState({
        crewUpdateErrors: e.response.data.errors,
      });
    }

    this.setState({
      loadingUpdateCrew: false,
    });
  };

  private openInvite = () => {
    this.setState({
      inviteModalOpen: true,
    });
  };

  private closeInvite = () => {
    this.setState({
      inviteModalOpen: false,
    });
  };

  private onInviteSuccess = () => {
    this.closeInvite();
    toast.success('Users invited');
  };

  public render() {
    const {
      name,
      users,
      loadingUsers,
      selectedRole,
      selectedUser,
      loadingUpdateCrew,
      loadingAssignNewUser,
      crewUpdateErrors,
      inviteModalOpen,
    } = this.state;

    const { users: companyUsers, t, company, crew } = this.props;

    const groupedUsers = this.groupUsersByRole(users);

    const uidsOfUsersInCrew = R.map(R.prop('uid'), users);
    const filteredCompanyUsers = R.filter(
      user => !R.contains(user.uid, uidsOfUsersInCrew),
      companyUsers,
    );

    const userSelectOptions = this.getUserSelectOptions(filteredCompanyUsers);

    const roleOptions = [
      { label: t('common:crewRoles.worker'), value: 'worker' },
      { label: t('common:crewRoles.foreman'), value: 'foreman' },
      { label: `${getCrewLabel()} Manager`, value: 'manager' },
    ];

    return (
      <div>
        <SideboxSectionContent>
          <form onSubmit={this.updateCrew}>
            <InlineInput
              label={`${getCrewLabel()} Name`}
              icon={<UserGroup />}
              inputProps={{
                placeholder: `${getCrewLabel()} Name`,
              }}
              value={name}
              onChange={this.onNameChange}
              labelSize={0.5}
              errorMessage={crewUpdateErrors.name}
            />

            <Button
              type={'default'}
              block={true}
              disabled={R.trim(name) === ''}
              loading={loadingUpdateCrew}
            >
              {t('common:save')}
            </Button>
          </form>
        </SideboxSectionContent>

        <SideboxSectionHeader
          leftIcon={<User />}
          title={t('super:screens.crews.assignUsersTitle')}
        />

        <SideboxSectionContent>
          <Button
            type={'primary'}
            block={true}
            className={'mb-4'}
            onClick={this.openInvite}
          >
            <Envelope className={'w-4 h-4 mr-4'} />
            Invite External
          </Button>

          <hr className={'my-4'} />

          <form onSubmit={this.assignUserToCrew}>
            <InlineSelect
              options={userSelectOptions}
              label={t('super:screens.crews.user')}
              icon={<User />}
              value={selectedUser}
              onChange={this.onUserSelect}
            />
            <InlineSelect
              options={roleOptions}
              label={t('super:screens.crews.role')}
              icon={<Cog />}
              value={selectedRole}
              onChange={this.onRoleSelect}
            />

            <Button
              type={'default'}
              block={true}
              disabled={!selectedUser || !selectedRole}
              loading={loadingAssignNewUser}
            >
              {t('super:screens.crews.assign')}
            </Button>
          </form>
        </SideboxSectionContent>

        <SideboxSectionHeader
          leftIcon={<User />}
          title={`${getCrewLabel()} Members`}
        />

        <SideboxSectionContent>
          <div style={{ position: 'relative' }}>
            <Loading loading={loadingUsers} />
            <CategoryList
              title={t('common:crewRoles.worker')}
              items={groupedUsers.worker}
              itemLabelAccessor={'username'}
              menuItems={[
                {
                  label: t('common:delete'),
                  onClick: item => this.removeUserFromCrew(item),
                },
                {
                  label: t('common:crewRoles.foreman'),
                  onClick: item => this.changeUserRoleInCrew(item, 'foreman'),
                },
                {
                  label: `${getCrewLabel()} Manager`,
                  onClick: item => this.changeUserRoleInCrew(item, 'manager'),
                },
              ]}
            />
            <CategoryList
              title={t('common:crewRoles.foreman')}
              items={groupedUsers.foreman}
              itemLabelAccessor={'username'}
              menuItems={[
                {
                  label: t('common:delete'),
                  onClick: item => this.removeUserFromCrew(item),
                },
                {
                  label: t('common:crewRoles.worker'),
                  onClick: item => this.changeUserRoleInCrew(item, 'worker'),
                },
                {
                  label: t('common:crewRoles.manager'),
                  onClick: item => this.changeUserRoleInCrew(item, 'manager'),
                },
              ]}
            />
            <CategoryList
              title={`${getCrewLabel()} Manager`}
              items={groupedUsers.manager}
              itemLabelAccessor={'username'}
              menuItems={[
                {
                  label: t('common:delete'),
                  onClick: item => this.removeUserFromCrew(item),
                },
                {
                  label: t('common:crewRoles.worker'),
                  onClick: item => this.changeUserRoleInCrew(item, 'worker'),
                },
                {
                  label: t('common:crewRoles.foreman'),
                  onClick: item => this.changeUserRoleInCrew(item, 'foreman'),
                },
              ]}
            />
          </div>
        </SideboxSectionContent>

        <CrewInvite
          isOpen={inviteModalOpen}
          handleClose={this.closeInvite}
          companyUid={company.uid}
          crewUid={crew.uid}
          onSuccess={this.onInviteSuccess}
        />
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    usersLoaded: state.users.loaded,
    usersLoading: state.users.loading,
  };
}

export default withNamespaces()(connect(mapStateToProps)(CrewOverview));

import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import Newsfeed from './dashboard/Newsfeed';
import SiteMap from './dashboard/SiteMap';
import Actions from './dashboard/Actions';
import { useSimpleFetch } from '../../helpers/hooks';
import companyUserApi from '../../api/companyUser.api';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { User, Company, Site, Feed, Form } from '../../types';
import userApi, { MyActionsResponse } from '../../api/user.api';
import { compare } from 'natural-orderby';
import styled from 'styled-components';
import { cssVars } from '../../constants/index';
import { biggerThanMD } from '../../helpers/style';
import formService from '../../services/forms.service';
import { useEffect } from 'react';
import { History } from 'history';
import Favorites from './dashboard/Favorites';

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;

  ${biggerThanMD(`
    height: 100%;
    width: 33.333%;
    max-height: 100%;
    position: absolute;
  `)};
`;

const RightContainer = styled.div`
  display: none;

  ${biggerThanMD(`
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-top: ${cssVars.margin[4]};
  margin-top: 0;
  width: 66.666%;
`)};
`;

interface Props {
  user: User;
  company: Company;
  history: History;
}

const Dashboard = ({ company, user, history }: Props) => {
  const myActions = useSimpleFetch<MyActionsResponse[]>({
    initial: [],
    change: [company.uid],
    fetch: () => userApi.createdActions(user.uid, company.uid),
    accessor: 'createdActions',
  });
  const assignedActions = useSimpleFetch<MyActionsResponse[]>({
    initial: [],
    change: [company.uid],
    fetch: () => userApi.assignedActions(user.uid, company.uid),
    accessor: 'assignedActions',
  });
  const sites = useSimpleFetch<Site[]>({
    initial: [],
    change: [company.uid, user.uid],
    accessor: 'sites',
    fetch: () => companyUserApi.sites(company.uid, user.uid),
  });
  const feed = useSimpleFetch<Feed[]>({
    initial: [],
    change: [company.uid, user.uid],
    accessor: 'feed',
    fetch: () => formService.newsfeed(company.uid, user.uid),
  });

  const allActions = [...myActions.value, ...assignedActions.value]
    .filter(x => +x.action.status !== 1)
    .sort((a, b) => {
      return compare({ order: 'asc' })(a.action.date_due, b.action.date_due);
    });

  useEffect(
    () => {
      const handler = setInterval(() => {
        feed.performFetch();
      }, 10000);

      return () => {
        clearInterval(handler);
      };
    },
    [company.uid],
  );

  return (
    <Page>
      <PageContent>
        <div className="flex flex-col md:flex-row relative h-full">
          <div style={{ width: '33.333%' }} />
          <LeftContainer>
            <Newsfeed
              className={'h-full md:overflow-y-scroll'}
              feed={feed.value}
            />
          </LeftContainer>
          <RightContainer>
            {typeof google !== 'undefined' && <SiteMap sites={sites.value} />}
            <Favorites company={company} user={user} history={history} />
            <Actions actions={allActions} />
          </RightContainer>
        </div>
      </PageContent>
    </Page>
  );
};

const mapState = (state: StoreState) => ({
  user: state.me.user,
  company: state.me.company,
});

export default connect(mapState)(Dashboard);

import * as React from 'react';
import {
  ResponsiveContainer,
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  Cell,
} from 'recharts';

interface Props {
  submissions: any[];
  onClick: (
    formData: { formUid: string; formV2: boolean; deployeeUid?: string },
  ) => any;
}

const Chart = ({ submissions, onClick }: Props) => {
  return (
    <div
      className={'flex flex-col items-center justify-center'}
      style={
        submissions.length > 0 && submissions.length < 32
          ? { height: '400px' }
          : { height: '100px' }
      }
    >
      {submissions.length > 32 && (
        <p>
          Too much data to display. Relevant data can be found in the table
          below.
        </p>
      )}
      {submissions.length > 0 &&
        submissions.length < 32 && (
          <ResponsiveContainer width={'99%'} height="990%">
            <BarChart
              data={submissions}
              margin={{ top: 20, right: 30, left: 0, bottom: 0 }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis
                dataKey="key"
                type={'category'}
                angle={-45}
                textAnchor="end"
                interval={0}
                height={150}
              />
              <YAxis />
              <Tooltip />
              <Bar
                dataKey="allSubmissions"
                name={'All Submissions'}
                minPointSize={10}
                onClick={row => {
                  if (row.hasOwnProperty('form')) {
                    onClick({
                      formUid: row.form.uid,
                      formV2: true,
                      deployeeUid: row.deployee ? row.deployee.uid : null,
                    });
                  } else {
                    onClick({
                      formUid: row.formUid,
                      formV2: false,
                      deployeeUid: row.deployee ? row.deployee.uid : null,
                    });
                  }
                }}
              >
                {submissions.map(x => (
                  <Cell fill={x.form ? x.form.color : x.color} />
                ))}
              </Bar>
            </BarChart>
          </ResponsiveContainer>
        )}
      {submissions.length === 0 && (
        <p>No data to display. Try doing a broader query</p>
      )}
    </div>
  );
};

export default Chart;

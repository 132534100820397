import { call, takeLatest, put } from 'redux-saga/effects';
import companyGroupApi from '../../api/companyGroup.api';
import { COMPANY_GROUP_FORMS_FETCH_REQUEST } from '../../constants/actionTypes';
import {
  companyGroupFormsFetchSuccess,
  companyGroupFormsFetchFailure,
} from '../../actions/companyGroup/forms';

export function* getForms(params: any) {
  const { data, errors } = yield call(companyGroupApi.forms, params.payload);
  if (errors) {
    yield put(companyGroupFormsFetchFailure(errors));
    return;
  }

  yield put(companyGroupFormsFetchSuccess(data.forms));
}

export function* watchGetCompanyGroupForms() {
  yield takeLatest(COMPANY_GROUP_FORMS_FETCH_REQUEST, getForms);
}

import * as React from 'react';
import { makeGetOption } from '../../../selectors/formOptions';
import { StoreState } from '../../../store';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../../types';
import { getOptionTitle } from '../../../helpers/formOption';
import styled from 'styled-components';
import { cssVars } from '../../../constants';
import Button from '../../../components/Button';
import PointCounter from './PointCounter';
import { useDeepEqualEffect, useMountVisibility } from '../../../helpers/hooks';
import { makeGetSubmDataByOptionId } from '../../../selectors/submData';
import submissionApi from '../../../api/submission.api';
import PrivateImage, {
  getPrivateUrlFromUid,
} from '../../../components/PrivateImage';
import TextArea from '../../../components/TextArea';
import { createAction, getType, ActionType } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { addSubmData } from '../../../actions/submData';
import { isEqual } from 'lodash';
import { biggerThanMD, biggerThanLG } from '../../../helpers/style';
import Alarm from '../../../svg/Alarm';
import MobileObservationModal from './MobileObservationModal';
import ActionCreate from '../../ActionCreate';
import FormActionCreate from '../FormActionCreate';
import Observe from '../../../svg/Observe';
import Announcement from '../../../svg/Announcement';
import { FormState } from '../../../reducers/form';
import { setFormMeta } from '../../../actions/form';
import ActionPreview from '../ActionPreview';

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Header = styled.header`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${cssVars.padding[4]};
  border: 1px solid ${cssVars.colors.border};
  margin-bottom: ${cssVars.margin[4]};

  ${biggerThanLG(`
    padding: 0;
    padding-left: ${cssVars.padding[4]};
  `)};
`;

const Title = styled.h2`
  flex: 1;
  font-size: ${cssVars.textSizes.base};
`;

const ActionsWrapper = styled.div`
  display: none;

  ${biggerThanLG(`
    flex: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  `)};
`;

const Body = styled.div`
  display: flex;
  flex-direction: column-reverse;
  align-items: stretch;
  margin-bottom: ${cssVars.margin[4]};

  ${biggerThanLG(`
    flex-direction: row;
  `)};
`;

const ImageUpload = styled.div`
  margin-top: ${cssVars.margin[2]};
  & img {
    max-width: 100%;
  }

  ${biggerThanLG(`
    margin-top: 0;
    width: 140px;
    height: 160px;
  `)};
`;

const UploadPlaceholder = styled.div`
  background: ${cssVars.colors.grey};
  width: 100%;
  height: 80px;
  margin-top: ${cssVars.margin[2]};
  display: flex;
  justify-content: center;
  align-items: center;

  ${biggerThanLG(`
    width: 140px;
    height: 160px;
    margin-top: 0;
  `)};
`;

const ChangeImageBtn = styled.button`
  background: transparent;
  padding: ${cssVars.padding[1]} ${cssVars.padding[2]};
  border: 0;
  color: white;
  cursor: pointer;
  &:hover {
    background: white;
    color: black;
  }
`;

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  labels: {
    low: string;
    medium: string;
    high: string;
    extreme: string;
  };
  type: 'safe_unsafe' | 'pass_fail' | 'sat_unsat';
  live?: boolean;
  submData?: ParsedSubmData;
  addSubmData: (submData: ParsedSubmData) => any;
  submUid?: string;
  setFormMeta: (meta: Partial<FormState>) => any;
}

interface DataState {
  comment: string;
  positive: number;
  low: number;
  medium: number;
  high: number;
  extreme: number;
  file: string;
}

const initState = {
  comment: '',
  positive: 0,
  low: 0,
  medium: 0,
  high: 0,
  extreme: 0,
  file: '',
};

const actions = {
  increment: createAction('increment', resolve => (type: string) =>
    resolve(type),
  ),
  decrement: createAction('decrement', resolve => (type: string) =>
    resolve(type),
  ),
  setComment: createAction('setComment', resolve => (comment: string) =>
    resolve(comment),
  ),
  setState: createAction('setState', resolve => (state: DataState) =>
    resolve(state),
  ),
};

const reducer = (state: DataState, action: ActionType<typeof actions>) => {
  switch (action.type) {
    case getType(actions.increment):
      return { ...state, [action.payload]: state[action.payload] + 1 };
    case getType(actions.decrement):
      return {
        ...state,
        [action.payload]:
          state[action.payload] === 0 ? 0 : state[action.payload] - 1,
      };
    case getType(actions.setComment):
      return { ...state, comment: action.payload };
    case getType(actions.setState):
      return { ...action.payload };
    default:
      return state;
  }
};

const AuditItem = ({
  option,
  labels,
  type,
  t,
  live,
  submData,
  addSubmData: pAddSubmData,
  submUid,
  setFormMeta: pSetFormMeta,
}: Props) => {
  if (!option) {
    return null;
  }

  const parsed = submData ? submData.parsed : initState;
  const [state, dispatch] = React.useReducer(reducer, parsed);
  const lastState = React.useRef({ ...parsed });
  const mobileObv = useMountVisibility();
  const actionState = useMountVisibility();

  const getCompliance = async () => {
    const { data } = await submissionApi.compliance(submUid!);
    if (data) {
      pSetFormMeta({ compliance: data.compliance });
    }
  };

  const updateServer = async () => {
    const res = await submissionApi.updateFormOption(
      submUid!,
      option.id,
      state,
    );
    if (!submData && res.data) {
      pAddSubmData(res.data.submData);
    }
    getCompliance();
  };

  useDeepEqualEffect(
    () => {
      const handler = setTimeout(() => {
        if (
          (submData && !isEqual(state, lastState.current)) ||
          (!submData && !isEqual(state, initState))
        ) {
          lastState.current = { ...state };
          updateServer();
        }
      }, 500);

      return () => {
        clearTimeout(handler);
      };
    },
    [state],
  );

  const onFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
    const { files } = e.currentTarget;
    if (files && files.length > 0) {
      const data = new FormData();
      data.append('uploads', files[0]);
      const res = await submissionApi.uploadFormOptionFile(
        submUid!,
        option.id,
        data,
      );
      if (res.data) {
        dispatch(
          actions.setState({ ...state, file: res.data.submData.parsed.file }),
        );
      }
    }
  };

  const openFileSelect = () => {
    const el = document.getElementById(`option-${option.id}-img-upload`);
    if (el) {
      el.click();
    }
  };

  const onActionCreate = async () => {
    actionState.close();
    if (!submUid) {
      return;
    }
    const { data } = await submissionApi.actions(submUid);
    if (data) {
      pSetFormMeta({ actions: data.actions });
    }
  };

  const buttons = {
    low: 'yellow',
    medium: 'orange-light',
    high: 'orange-dark',
    extreme: 'red',
  };

  return (
    <Container>
      <Header>
        <div className="flex flex-col lg:flex-1">
          <Title>{getOptionTitle(option, { live })}</Title>
          <div className="lg:hidden flex flex-row justify-between items-center mt-2">
            <div className="flex items-center mr-4">
              <div
                className={`bg-green mr-2`}
                style={{ width: '10px', height: '10px' }}
              />
              {state.positive ? state.positive : 0}
            </div>
            {Object.keys(buttons).map(rank => (
              <div className="flex items-center mr-4">
                <div
                  className={`bg-${buttons[rank]} mr-2`}
                  style={{ width: '10px', height: '10px' }}
                />
                {state[rank] ? state[rank] : 0}
              </div>
            ))}
          </div>
        </div>
        <div className="lg:hidden flex flex-col items-end sm:flex-row">
          <Button
            className="flex items-center justify-center mb-2 sm:mb-0 sm:mr-2 text-black"
            type="grey"
            onClick={actionState.open}
          >
            <Announcement className="w-6 h-6 text-black" />
          </Button>
          <Button
            className="flex items-center justify-center"
            type="default"
            onClick={mobileObv.open}
          >
            <Observe className="w-6 h-6" />
          </Button>
        </div>
        <ActionsWrapper>
          <PointCounter
            label={`${t(`forms:${type}_pointLabel`)} ${
              state.positive ? state.positive : ''
            }`}
            type={'success'}
            onUp={() => dispatch(actions.increment('positive'))}
            onDown={() => dispatch(actions.decrement('positive'))}
          />
          {Object.keys(buttons).map(rank => (
            <PointCounter
              key={rank}
              label={`${labels[rank]} ${state[rank] ? state[rank] : ''}`}
              type={buttons[rank]}
              onUp={() => dispatch(actions.increment(rank))}
              onDown={() => dispatch(actions.decrement(rank))}
            />
          ))}
          <Button
            type={'default'}
            onClick={actionState.open}
            className="flex flex-row items-center"
          >
            <Announcement className="w-6 h-6 mr-2" />
            {t('common:action')}
          </Button>
        </ActionsWrapper>
      </Header>
      <Body>
        {state.file ? (
          <ImageUpload className="mb-4 lg:mb-0 lg:mr-4 relative">
            {state.file && (
              <img src={getPrivateUrlFromUid(state.file, { thumb: true })} />
            )}
            <div
              className="flex flex-row justify-between absolute w-full p-2"
              style={{ bottom: 0, left: 0, background: 'rgba(0, 0, 0, 0.5)' }}
            >
              <ChangeImageBtn onClick={openFileSelect}>Change</ChangeImageBtn>
              <ChangeImageBtn>Remove</ChangeImageBtn>
            </div>
          </ImageUpload>
        ) : (
          <UploadPlaceholder onClick={openFileSelect}>
            Click to upload
          </UploadPlaceholder>
        )}
        <TextArea
          value={state.comment}
          onChange={e => dispatch(actions.setComment(e.currentTarget.value))}
        />
        <input
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          id={`option-${option.id}-img-upload`}
          onChange={onFileChange}
          value={''}
        />
      </Body>
      {mobileObv.mounted && (
        <MobileObservationModal
          isOpen={mobileObv.visible}
          handleClose={mobileObv.close}
          labels={labels}
          buttons={buttons}
          state={state}
          onUp={rank => dispatch(actions.increment(rank))}
          onDown={rank => dispatch(actions.decrement(rank))}
        />
      )}
      {actionState.mounted && (
        <FormActionCreate
          isOpen={actionState.visible}
          handleClose={actionState.close}
          formOptionId={option.id}
          onCreate={onActionCreate}
        />
      )}
      <ActionPreview optionId={option.id} />
    </Container>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    submUid: state.form.submissionUid,
  });
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    addSubmData: (submData: ParsedSubmData) => dispatch(addSubmData(submData)),
    setFormMeta: (meta: Partial<FormState>) => dispatch(setFormMeta(meta)),
  };
}

export default withNamespaces()(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  )(AuditItem),
);

import * as React from 'react';
import WidgetContent from '../form/WidgetContent';
import WidgetContainer from '../form/WidgetContainer';
import {
  Widget,
  ResponseComment,
  ResponseFile,
  SelectionResponse,
} from '../../types';
import { mandatoryErrorStyles, biggerThanMD } from '../../helpers/style';
import WidgetMetaButtons from './WidgetMetaButtons';
import Select, { SelectOptionObject } from '../../components/Select';
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import formService from '../../services/forms.service';
import { selectTypes } from '../../constants';
import RequiredWidget from './RequiredWidget';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

interface Props {
  widget: Widget;
  response: SelectionResponse;
  comments: ResponseComment[];
  files: ResponseFile[];
  editable?: boolean;
  instanceRevUid: string;
  error?: boolean;
}

function Selection({
  widget,
  response,
  comments,
  files,
  editable,
  instanceRevUid,
  error,
}: Props) {
  const [options, setOptions] = useState<SelectOptionObject[]>([]);
  const [value, setValue] = useState<string[]>(
    response && response.selections ? response.selections : [],
  );

  function getOptions() {
    if (!widget.options) {
      return;
    }
    setOptions(
      widget.options.map((option: string) => ({
        value: option.toLowerCase(),
        label: option,
      })),
    );
  }

  useEffect(
    () => {
      getOptions();
    },
    [widget],
  );

  const updateServer = useCallback(
    debounce(
      async (selections: string[]) =>
        await formService.setWidgetResponse(instanceRevUid, widget.uid, {
          selections,
        }),
      800,
    ),
    [instanceRevUid, widget.uid],
  );

  const setAndSyncValue = async (selections: string[]) => {
    setValue(selections);
    updateServer(selections);
  };

  useEffect(
    () => {
      setAndSyncValue(value);
    },
    [value],
  );

  useEffect(
    () => {
      if (response === null) {
        return;
      }
      setAndSyncValue(response.selections);
    },
    [response],
  );

  return (
    <WidgetContainer>
      <WidgetContent
        style={error && value.length === 0 ? mandatoryErrorStyles : {}}
        className="bg-white mt-4 rounded"
      >
        <div className={'mb-4'}>
          <Wrapper>
            <label>
              {widget.title}
              {widget.mandatory && <RequiredWidget />}
            </label>
          </Wrapper>
          <Select
            options={options}
            multi={widget.select_type === 'multi' ? true : false}
            onChange={(selection: SelectOptionObject | SelectOptionObject[]) =>
              widget.select_type === selectTypes.SINGLE
                ? setValue([selection.label])
                : setValue(
                    selection.map(
                      (selected: SelectOptionObject) => selected.label,
                    ),
                  )
            }
            value={value.map((val: string) => ({
              value: val.toLowerCase(),
              label: val,
            }))}
            disabled={!editable}
          />
        </div>
        <WidgetMetaButtons
          editable={editable}
          files={files}
          widget={widget}
          instanceRevUid={instanceRevUid}
          comments={comments}
        />
      </WidgetContent>
    </WidgetContainer>
  );
}

export default Selection;

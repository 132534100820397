import * as React from 'react';
import { connect } from 'react-redux';
import { Company, User } from '../../types';
import { StoreState } from '../../store';
import Avatar from '../../components/Avatar';
import UsersSidebox from '../../containers/UserSidebox';
import UserCreate from './users/UserCreate';
import Button from '../../components/Button';
import ResourceTable from '../../components/ResourceTable';
import PageHeading from '../../components/PageHeading';
import PageContent from '../../components/PageContent';
import Page from '../../components/Page';
import { Dispatch } from 'redux';
import { usersFetchRequest } from '../../actions/users';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { UsersState } from '../../reducers/users';
import { needsToBeFetched } from '../../helpers/globalState';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import companyUserApi from '../../api/companyUser.api';
import { toast } from 'react-toastify';
import Trash from '../../svg/Trash';
import { getDivisionLabel } from '../../helpers';

interface State {
  userDetailOpen: boolean;
  createUserOpen: boolean;
  selectedUser: User | null;
  loading: boolean;
  suspendOpen: boolean;
}

interface Props extends WithNamespaces {
  company: Company;
  getUsers: (companyUid: string) => any;
  users: UsersState;
  user: User;
}

class Users extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      userDetailOpen: false,
      createUserOpen: false,
      selectedUser: null,
      loading: false,
      suspendOpen: false,
    };
  }

  public async componentDidMount() {
    if (needsToBeFetched(this.props.users)) {
      this.props.getUsers(this.props.company.uid);
    }
  }

  public componentDidUpdate(prevProps: Props) {
    if (needsToBeFetched(this.props.users)) {
      this.props.getUsers(this.props.company.uid);
    }
  }

  public openUserDetail = (user: User) => {
    this.setState({
      userDetailOpen: true,
      selectedUser: user,
    });
  };

  public closeUserDetail = () => {
    this.setState({
      userDetailOpen: false,
      selectedUser: null,
    });
    this.props.getUsers(this.props.company.uid);
  };

  private openCreateUser = () => {
    this.setState({
      createUserOpen: true,
    });
  };

  private closeCreateUser = () => {
    this.setState({
      createUserOpen: false,
    });
  };

  private onUserCreate = (user: User) => {
    this.closeCreateUser();
    this.props.getUsers(this.props.company.uid);
    this.openUserDetail(user);
  };

  private openSuspend = () => {
    this.setState({
      suspendOpen: true,
    });
  };

  private closeSuspend = () => {
    this.setState({
      suspendOpen: false,
    });
  };

  private onSuspend = async () => {
    const { company } = this.props;
    const { selectedUser } = this.state;
    if (!selectedUser) {
      return;
    }
    this.setState({
      loading: true,
    });
    const { data } = await companyUserApi.suspend(
      company.uid,
      selectedUser.uid,
    );
    if (data) {
      toast.success('User suspended');
    }
    this.setState({
      loading: false,
    });
    this.closeSuspend();
    this.closeUserDetail();
    this.props.getUsers(company.uid);
  };

  public render() {
    const { createUserOpen, suspendOpen, selectedUser, loading } = this.state;
    const { users, t, user } = this.props;
    const tableColumns = [
      {
        Header: '',
        accessor: (r: any) => r,
        id: 'avatar',
        Filter: () => <span />,
        Cell: (row: any) => (
          <Avatar
            bgColor={row.original.profile_color[0]}
            fgColor={row.original.profile_color[1]}
            fallbackName={row.original.username}
            margin={false}
            user={row.original}
          />
        ),
        maxWidth: 75,
      },
      { Header: t('common:username'), accessor: 'username' },
      { Header: t('common:firstName'), accessor: 'fname' },
      { Header: t('common:lastName'), accessor: 'lname' },
    ];

    return (
      <Page>
        <PageContent>
          <PageHeading
            title={`${getDivisionLabel()} Active Users`}
            subtitle={`${users.data.length} ${t('super:available')}`}
            renderRight={() => (
              <Button type={'primary'} onClick={this.openCreateUser}>
                {t('super:screens.users.createButton')}
              </Button>
            )}
          />

          <ResourceTable
            data={users.data}
            columns={tableColumns}
            defaultSortKey={'username'}
            loading={users.loading}
            onRowClick={this.openUserDetail}
            fetched={users.loaded}
            emptyTitle={'There are no users in this division'}
            emptyMessage={
              'To create a user, click "Create User" in the top right.'
            }
          />

          <UsersSidebox
            isOpen={this.state.userDetailOpen}
            handleClose={this.closeUserDetail}
            user={this.state.selectedUser!}
            company={this.props.company}
            buttons={
              selectedUser && selectedUser.uid !== user.uid
                ? [
                    {
                      Icon: Trash,
                      label: 'Remove from company',
                      onClick: this.openSuspend,
                    },
                  ]
                : undefined
            }
          />
          <UserCreate
            isOpen={createUserOpen}
            handleClose={this.closeCreateUser}
            onCreate={this.onUserCreate}
          />
          <ConfirmationDialogue
            isOpen={suspendOpen}
            handleClose={this.closeSuspend}
            title="Suspend User"
            body={`Are you sure you wish to suspend this user from ${this.props.company.name}? You will be able to restore access later via the "Trash" tab.`}
            contentLabel="Suspend User"
            loading={loading}
            onConfirm={this.onSuspend}
          />
        </PageContent>
      </Page>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
    users: state.users,
    user: state.me.user,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(mapStateToProps, mapDispatchToProps)(Users),
);

import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import styled from 'styled-components';
import formService from '../../../services/forms.service';
import { Instance, User, Site, Crew, Form, Company } from '../../../types';
import Button from '../../../components/Button';
import { useState, useEffect } from 'react';
import Loading from '../../../components/Loading';
import {
  getSiteLabelPlural,
  getCrewLabel,
  getCrewLabelPlural,
  ObjectFilter,
  getSiteLabel,
  getFullName,
} from '../../../helpers/index';
import { toast } from 'react-toastify';
import ModalFooter from '../../../components/modal/ModalFooter';
import { deploymentTypeOptions } from '../../super/site/forms/Deployment';
import { t } from 'i18next';
import { SelectOptionObject } from '../../../components/Select';
import ChevRightOutline from '../../../svg/ChevRightOutline';
import InlineSelect from '../../../components/InlineSelect';
import { WithNamespaces } from 'react-i18next';
import { RouteComponentProps } from 'react-router-dom';
import Location from '../../../svg/Location';
import { connect } from 'react-redux';
import { addForm } from '../../../actions/forms';
import { StoreState } from '../../../store';
import { Dispatch } from 'redux';
import { sitesFetchRequest } from '../../../actions/sites';
import { FormsState } from '../../../reducers/forms';
import { CrewsState } from '../../../reducers/crews';
import userApi from '../../../api/user.api';

const ModalSection = styled.section`
  min-height: 300px;
`;

interface Props extends WithNamespaces, RouteComponentProps {
  handleClose: () => void;
  isOpen: boolean;
  deployeeUid?: string;
  formName: string;
  siteUid?: string;
  formUid: string;
  userUid: string;
  company: Company;
  getCrews: (companyUid: string) => any;
  forms: FormsState;
  crews: CrewsState;
}

const PickDraft = ({
  isOpen,
  handleClose,
  deployeeUid,
  formName,
  siteUid,
  formUid,
  history,
  userUid,
  company,
}: Props) => {
  const [sites, setSites] = useState<Site[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [selectedSiteUid, setSelectedSiteUid] = useState<string | null>(
    siteUid || null,
  );
  const [selectedDeployeeUid, setSelectedDeployeeUid] = useState<string | null>(
    deployeeUid || null,
  );
  const [selectedType, setSelectedType] = useState<SelectOptionObject | null>(
    null,
  );
  const [siteValue, setSiteValue] = useState<SelectOptionObject | null>(
    siteUid && sites.length !== 0
      ? {
          label: sites.filter((site: Site) => site.uid === siteUid)[0].name,
          value: siteUid,
        }
      : null,
  );
  const [crews, setCrews] = useState<Crew[]>([]);
  const [crewValue, setCrewValue] = useState<SelectOptionObject | null>(
    deployeeUid && crews.length !== 0
      ? {
          label: crews.filter((crew: Crew) => crew.uid === deployeeUid)[0].name,
          value: deployeeUid,
        }
      : null,
  );
  const [drafts, setDrafts] = useState<
    Array<{
      creator: User;
      instance: Instance;
    }>
  >([]);
  const canCreate = formUid && selectedSiteUid && selectedDeployeeUid;
  const typeOptions = deploymentTypeOptions(t);

  function onCancel() {
    setSelectedSiteUid(null);
    setSelectedDeployeeUid(null);
    handleClose();
  }

  function onSiteChange(uid: string) {
    setSelectedSiteUid(uid);
    setSiteValue({
      label: sites.filter((site: Site) => site.uid === uid)[0].name,
      value: uid,
    });
  }

  function onTypeChange(deploymentType: SelectOptionObject) {
    setSelectedType(deploymentType);
    if (deploymentType.value === 'site') {
      setSelectedDeployeeUid(selectedSiteUid);
      return;
    }
    if (deploymentType.value === 'user') {
      setSelectedDeployeeUid(userUid);
      return;
    }
    if (deploymentType.value === 'crew') {
      getCrews();
    }
  }

  function onCrewChange(crewName: string, crewUid: string) {
    setSelectedDeployeeUid(crewUid);
    setCrewValue({
      label: crews.filter((crew: Crew) => crew.uid === crewUid)[0].name,
      value: crewUid,
    });
  }

  async function getDrafts() {
    setLoading(true);
    const uids = {
      formUids: formUid,
      siteUids: selectedSiteUid,
      deployeeUids: selectedDeployeeUid,
    };
    const filteredUids = ObjectFilter(uids, (uid: string) => uid !== null);
    const { data } = await formService.getFormDraft(filteredUids as any);
    if (!data) {
      toast.error('Could not get drafts!');
      setLoading(false);
      return;
    }
    setDrafts(data.drafts);
    setLoading(false);
  }

  async function getCrews() {
    if (!selectedSiteUid) {
      toast.error(
        `Cannot find any ${getCrewLabelPlural()} on the selected ${getSiteLabel()}. A ${getSiteLabel()} may not be selected.`,
      );
      return;
    }
    setLoading(true);
    const { data, errors } = await userApi.siteCrews(userUid, selectedSiteUid);
    if (data) {
      setCrews(data.crews);
    }
    if (errors) {
      toast.error(`Failed to fetch ${getCrewLabelPlural()}.`);
    }
    setLoading(false);
  }

  async function onCreateDraft() {
    setLoading(true);
    if (selectedSiteUid === null || selectedDeployeeUid === null) {
      return;
    }
    const { data } = await formService.createFormDraft(
      selectedSiteUid,
      formUid,
      selectedDeployeeUid,
    );
    if (!data) {
      toast.error('Failed to create draft!');
      setLoading(false);
      return;
    }
    history.push(`/dashboard/worker/formsV2/${data.draft.uid}`);
    setLoading(false);
  }

  async function onDraftDelete(draftUid: string) {
    setLoading(true);
    await formService.deleteDraft(draftUid);
    setLoading(false);
    getDrafts();
  }

  async function getSites() {
    if (!userUid || !company) {
      return;
    }
    const { data, errors } = await userApi.sites(userUid, company.uid);
    if (errors || !data) {
      toast.error('Unable to fetch sites!');
      return;
    }
    setSites(data.sites);
  }

  useEffect(() => {
    if (formUid === '') {
      return;
    }
    getDrafts();
    if (deployeeUid) {
      if (deployeeUid === siteUid) {
        setSelectedType({ label: getSiteLabel(), value: 'site' });
      } else if (deployeeUid === userUid) {
        setSelectedType({ label: 'User', value: 'crew' });
      } else {
        setSelectedType({ label: getCrewLabel(), value: 'crew' });
      }
    }
  }, []);

  useEffect(
    () => {
      if (!formUid || !selectedSiteUid) {
        return;
      }
      getDrafts();
    },
    [formUid, selectedSiteUid, selectedDeployeeUid],
  );

  useEffect(
    () => {
      getSites();
    },
    [userUid, company.uid],
  );

  return (
    <Modal
      contentLabel={'Fill out a form.'}
      handleClose={handleClose}
      isOpen={isOpen}
    >
      <ModalHeader handleClose={handleClose}>{formName}</ModalHeader>
      <ModalBody>
        <ModalSection>
          <div
            className="flex flex-row relative mb-2 overflow-x-scroll"
            style={{ height: '115px', minWidth: '100%' }}
          >
            <Loading loading={loading} />
            {drafts.length === 0 ? (
              <div className="w-full h-full flex items-center justify-center">
                <p className="font-bold">
                  There are no open drafts matching your selection. Please
                  create a new one.
                </p>
              </div>
            ) : (
              drafts.map((draft: any) => (
                <div
                  className="p-1 h-full cursor-pointer"
                  style={{ minWidth: '30%' }}
                >
                  <div className="bg-grey rounded p-2 h-full relative">
                    <div
                      onClick={() =>
                        history.push(
                          `/dashboard/worker/formsV2/${draft.instance.uid}`,
                        )
                      }
                    >
                      <b className="mb-1">{draft.instance.name}</b>
                      <p className="mb-1">
                        <ChevRightOutline className="w-4 h-4" />{' '}
                        {draft.deployee.name || getFullName(draft.deployee)}
                      </p>
                      <p className="mb-1">
                        <Location className="w-4 h-4" width="12" height="12" />{' '}
                        {draft.site.name}
                      </p>
                    </div>
                    <div
                      className="bg-red absolute w-full"
                      style={{
                        bottom: '0',
                        left: '0',
                        borderRadius: '0px 0px .25rem .25rem',
                      }}
                    >
                      <p
                        className="text-white text-center p-1"
                        onClick={() => onDraftDelete(draft.instance.uid)}
                      >
                        Delete
                      </p>
                    </div>
                  </div>
                </div>
              ))
            )}
          </div>
          <hr />
          <p className="font-bold my-2">Where are you filling out this form?</p>
          <InlineSelect
            label={getSiteLabelPlural()}
            options={sites.map((site: Site) => ({
              label: site.name,
              value: site.uid,
            }))}
            onChange={(event: any) => onSiteChange(event.value)}
            value={siteValue}
            menuPosition={'fixed'}
            menuPortalTarget={document.body}
          />
          <p className="font-bold my-2">Who is this form going to?</p>
          <InlineSelect
            disabled={!selectedSiteUid}
            label="Type"
            options={typeOptions}
            onChange={onTypeChange}
            value={selectedType}
            menuPosition={'fixed'}
            menuPortalTarget={document.body}
          />
          {selectedType && selectedType.value === 'crew' ? (
            <div>
              <p className="font-bold my-2">
                Which {getCrewLabel()} is this form going to?
              </p>
              <InlineSelect
                label={getCrewLabelPlural()}
                options={crews.map((crew: Crew) => ({
                  label: crew.name,
                  value: crew.uid,
                }))}
                onChange={(event: any) =>
                  onCrewChange(event.label, event.value)
                }
                value={crewValue}
                menuPosition={'fixed'}
                menuPortalTarget={document.body}
              />
            </div>
          ) : null}
        </ModalSection>
      </ModalBody>
      <ModalFooter right={true}>
        <Button type="default" onClick={onCancel} className={'mr-3'}>
          Cancel
        </Button>
        <Button
          type="primary"
          onClick={onCreateDraft}
          className={'mr-3'}
          disabled={!canCreate}
        >
          Create Draft
        </Button>
      </ModalFooter>
    </Modal>
  );
};

function mapStateToProps(state: StoreState) {
  return {
    userUid: state.me.user.uid,
    company: state.me.company,
    forms: state.forms,
    formsLoaded: state.forms.loaded,
    formsLoading: state.forms.loading,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getSites: (companyUid: string) => dispatch(sitesFetchRequest(companyUid)),
    addForm: (form: Form) => dispatch(addForm(form)),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PickDraft);

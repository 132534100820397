import * as React from 'react';
import * as ReactDOM from 'react-dom';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { cssVars } from '../constants';
import classNames from 'classnames';
import { Manager, Popper, Reference } from 'react-popper';

const WeekdayLabel = styled.div`
  width: 14%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${cssVars.padding[2]};
`;

const CalendarDay = styled.div<{ selected?: boolean; active?: boolean }>`
  outline: none;
  display: flex;
  border: 0;
  padding: ${cssVars.padding[2]};
  cursor: pointer;
  justify-content: center;
  align-items: center;
  background: ${props => (props.selected ? cssVars.colors.primary : 'white')};
  color: ${props => (props.selected ? 'white' : 'inherit')};
  width: 14%;
  opacity: ${props => (props.active ? 1 : 0.5)};

  &:hover {
    background: ${cssVars.colors.primary};
    color: white;
  }
`;

const CalendarDays = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  border-bottom: 1px solid ${cssVars.colors.border};
`;

const HeaderButton = styled.button`
  padding: ${cssVars.padding[2]};
  background: transparent;
  border: 0;
`;

interface DatePickerInputProps {
  value: string;
}

interface DatePickerChildProps {
  getInputProps: () => DatePickerInputProps;
  isOpen: boolean;
  ref: any;
}

interface DatePickerProps {
  children: (state: DatePickerChildProps) => JSX.Element;
  wrapperClassName?: string;
  value?: Moment | null;
  onChange?: (date: Moment | null) => void;
  disabled?: boolean;
}

interface DatePickerState {
  value: Moment | null;
  view: Moment;
  currentScreen: CurrentScreen;
  yearScreenStart: Moment;
  isOpen: boolean;
}

type CurrentScreen = 'day' | 'year' | 'month';

export default class DatePicker extends React.Component<
  DatePickerProps,
  DatePickerState
> {
  private blurTimeout: any;

  constructor(props: DatePickerProps) {
    super(props);

    this.state = {
      value: null,
      view: moment(),
      currentScreen: 'day',
      yearScreenStart: moment(),
      isOpen: false,
    };

    this.blurTimeout = null;
  }

  private getInputValue = () => {
    const value = this.getValue();

    if (!value) {
      return '';
    }

    return value.format('YYYY-MM-DD');
  };

  private getValue = () => {
    return typeof this.props.value === 'undefined'
      ? this.state.value
      : this.props.value;
  };

  private getInputProps = (): DatePickerInputProps => {
    return {
      value: this.getInputValue(),
    };
  };

  private selectDate = (date: Moment) => {
    let view = this.state.view.clone();

    const value = this.getValue();

    if (value && date.format('YYYY-MM') !== value.format('YYYY-MM')) {
      view = date.clone();
    }

    if (this.props.onChange) {
      this.props.onChange(date);
    }

    this.setState({
      value: date,
      view,
      isOpen: false,
    });
  };

  private changeScreen = (currentScreen: CurrentScreen) => {
    this.setState({
      currentScreen,
    });
  };

  private getDaysForView = () => {
    const { view } = this.state;
    const daysInMonth = view.daysInMonth();

    const viewFormatted = view.format('YYYY-MM');

    let days = [];

    for (let i = 0; i < daysInMonth; i++) {
      days.push({
        day: moment(`${viewFormatted}-${i + 1}`, 'YYYY-MM-D'),
        active: true,
      });
    }

    const firstDay = moment(
      viewFormatted + '-' + days[0].day.format('D'),
      'YYYY-MM-DD',
    ).isoWeekday();

    const daysInLastMonth = view
      .clone()
      .subtract(1, 'month')
      .daysInMonth();

    const lastMonthFormatted = view
      .clone()
      .subtract(1, 'month')
      .format('YYYY-MM');

    for (let i = 0; i < firstDay; i++) {
      days = [
        {
          day: moment(`${lastMonthFormatted}-${daysInLastMonth - i}`),
          active: false,
        },
        ...days,
      ];
    }

    const lastDay = moment(
      view.format('YYYY-MM') + '-' + days[days.length - 1].day.format('D'),
      'YYYY-MM-DD',
    ).isoWeekday();

    let lastFill = 7 - lastDay;

    if (lastFill === 0) {
      lastFill = 6;
    }

    const nextMonthFormatted = view
      .clone()
      .add(1, 'month')
      .format('YYYY-MM');

    for (let i = 0; i < lastFill; i++) {
      if (days.length % 7 !== 0 && days.length < 42) {
        days.push({
          day: moment(`${nextMonthFormatted}-${i + 1}`, 'YYYY-MM-D'),
          active: false,
        });
      }
    }

    return days;
  };

  private goBackMonth = () => {
    this.setState({
      view: this.state.view.subtract(1, 'month'),
    });
  };

  private goForwardMonth = () => {
    this.setState({
      view: this.state.view.add(1, 'month'),
    });
  };

  private onFocus = (e: any) => {
    e.stopPropagation();
    clearTimeout(this.blurTimeout);

    if (this.props.disabled) {
      return;
    }

    const value = this.getValue();
    this.setState({
      isOpen: true,
      view: this.state.isOpen
        ? this.state.view
        : value
          ? value.clone()
          : moment(),
    });
  };

  private onBlur = (e: any) => {
    e.stopPropagation();
    this.blurTimeout = setTimeout(() => {
      this.setState({
        isOpen: false,
      });
    }, 200);
  };

  private renderCalendar = (scheduleUpdate: any) => {
    const { view } = this.state;

    const days = this.getDaysForView();

    const value = this.getValue();

    return (
      <div className="flex flex-col">
        <div className="flex-1 flex justify-center p-4 border-0 border-b-1 border-solid border-border">
          <HeaderButton onClick={this.goBackMonth}>{'<'}</HeaderButton>
          <HeaderButton
            onClick={() => (this.changeScreen('month'), scheduleUpdate())}
          >
            {view.format('MMM')}
          </HeaderButton>
          <HeaderButton
            onClick={() => (this.changeScreen('year'), scheduleUpdate())}
          >
            {view.format('YYYY')}
          </HeaderButton>
          <HeaderButton onClick={this.goForwardMonth}>{'>'}</HeaderButton>
        </div>
        <CalendarDays>
          <WeekdayLabel>S</WeekdayLabel>
          <WeekdayLabel>M</WeekdayLabel>
          <WeekdayLabel>T</WeekdayLabel>
          <WeekdayLabel>W</WeekdayLabel>
          <WeekdayLabel>TH</WeekdayLabel>
          <WeekdayLabel>F</WeekdayLabel>
          <WeekdayLabel>S</WeekdayLabel>
        </CalendarDays>
        <CalendarDays>
          {days.map(i => (
            <CalendarDay
              selected={i.day.isSame(value!, 'date')}
              active={i.active}
              onClick={() => this.selectDate(i.day)}
            >
              {i.day.format('D')}
            </CalendarDay>
          ))}
        </CalendarDays>
      </div>
    );
  };

  private renderMonths = (scheduleUpdate: any) => {
    const { view } = this.state;

    const months = [
      { label: 'Jan', value: 1 },
      { label: 'Feb', value: 2 },
      { label: 'Mar', value: 3 },
      { label: 'Apr', value: 4 },
      { label: 'May', value: 5 },
      { label: 'Jun', value: 6 },
      { label: 'Jul', value: 7 },
      { label: 'Aug', value: 8 },
      { label: 'Sep', value: 9 },
      { label: 'Oct', value: 10 },
      { label: 'Nov', value: 11 },
      { label: 'Dec', value: 12 },
    ];

    return (
      <div className="flex flex-row flex-wrap">
        {months.map(month => (
          <button
            key={month.value}
            className={classNames('bg-transparent border-0 p-4 w-1/3', {
              'bg-primary': view.month() === month.value - 1,
              'text-white': view.month() === month.value - 1,
            })}
            onClick={() => {
              this.setState({
                view: view.month(month.value - 1),
                currentScreen: 'day',
              });
              scheduleUpdate();
            }}
          >
            {month.label}
          </button>
        ))}
      </div>
    );
  };

  private renderYears = (scheduleUpdate: any) => {
    const { view, yearScreenStart } = this.state;

    const startingYear = +yearScreenStart.format('YYYY');

    const NUM_YEARS = 12;

    const yearsInView = Array.from({ length: NUM_YEARS }).map(
      (_, i) => startingYear + i,
    );

    return (
      <div className="flex flex-row items-stretch">
        <HeaderButton
          onClick={() =>
            this.setState({
              yearScreenStart: yearScreenStart.subtract(NUM_YEARS, 'years'),
            })
          }
        >
          {'<'}
        </HeaderButton>
        <div className={'flex flex-row flex-wrap'}>
          {yearsInView.map(year => (
            <button
              key={year}
              className={classNames('bg-transparent border-0 p-4 w-1/3', {
                'bg-red': +view.format('YYYY') === year,
                'text-white': +view.format('YYYY') === year,
              })}
              onClick={e => {
                e.stopPropagation();
                this.setState({
                  view: view.year(year),
                  currentScreen: 'day',
                  yearScreenStart: view.clone().year(year),
                });
                scheduleUpdate();
              }}
            >
              {year}
            </button>
          ))}
        </div>
        <HeaderButton
          className={'p-2 bg-transparent border-0'}
          onClick={() =>
            this.setState({
              yearScreenStart: yearScreenStart.add(NUM_YEARS, 'years'),
            })
          }
        >
          {'>'}
        </HeaderButton>
      </div>
    );
  };

  public render() {
    const { children, wrapperClassName, disabled } = this.props;
    const { currentScreen, isOpen } = this.state;

    return (
      <Manager>
        <div
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          tabIndex={0}
          className={classNames(wrapperClassName, 'relative')}
        >
          <Reference>
            {({ ref }) =>
              children({ getInputProps: this.getInputProps, isOpen, ref })
            }
          </Reference>
          {isOpen &&
            ReactDOM.createPortal(
              <Popper placement={'bottom'}>
                {({ ref, placement, style, scheduleUpdate }) => (
                  <div
                    ref={ref}
                    data-placement={placement}
                    className={'bg-white filter-1'}
                    style={{ width: '200px', zIndex: 11, ...style }}
                    tabIndex={0}
                  >
                    {currentScreen === 'day' &&
                      this.renderCalendar(scheduleUpdate)}
                    {currentScreen === 'month' &&
                      this.renderMonths(scheduleUpdate)}
                    {currentScreen === 'year' &&
                      this.renderYears(scheduleUpdate)}
                  </div>
                )}
              </Popper>,
              document.body,
            )}
        </div>
      </Manager>
    );
  }
}

import * as React from 'react';
import { FormOption } from '../types';
import Section from './formBuilder/Section';
import Button from '../components/Button';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import WidgetAdd from './formBuilder/WidgetAdd';
import { Dispatch } from 'redux';
import {
  addFormOption,
  removeFormOption,
  moveFormOption,
  replaceFormOptions,
  setDragFid,
} from '../actions/formOptions';
import { connect } from 'react-redux';
import formOptionApi from '../api/formOption.api';
import { formFeatureIds } from '../constants';
import ConfirmationDialogue from '../components/ConfirmationDialogue';
import {
  DragDropContext,
  OnDragEndResponder,
  OnBeforeDragStartResponder,
  Droppable,
} from 'react-beautiful-dnd';
import store, { StoreState } from '../store';
import { move, reorder, groupAndSortOptions } from '../reducers/formOptions';

interface Props extends WithNamespaces {
  sections: FormOption[];
  formUid: string;
  formType: string;
  addFormOption: (option: FormOption) => any;
  removeFormOption: (optionId: number) => any;
  moveFormOption: (data: any) => any;
  replaceFormOptions: (data: any) => any;
  setDragFid: (fid: number | null) => any;
  fid: number;
}

interface State {
  addWidgetOpen: boolean;
  columnToAddWidget: number | null;
  confirmDeleteOpen: boolean;
  optionIdToDelete: number | null;
}

class FormBuilder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      addWidgetOpen: false,
      columnToAddWidget: null,
      confirmDeleteOpen: false,
      optionIdToDelete: null,
    };
  }

  private openAddWidget = (optionId: number) => {
    this.setState({
      addWidgetOpen: true,
      columnToAddWidget: optionId,
    });
  };

  private closeAddWidget = () => {
    this.setState({
      addWidgetOpen: false,
      columnToAddWidget: null,
    });
  };

  private onWidgetAdded = (option: FormOption) => {
    this.closeAddWidget();
    this.props.addFormOption(option);
  };

  private addSection = async () => {
    try {
      const { option } = await formOptionApi.create({
        formUid: this.props.formUid,
        typeId: formFeatureIds.SECTION,
      });
      this.props.addFormOption(option);
    } catch (e) {
      //
    }
  };

  private openConfirmDelete = (optionId: number) => {
    this.setState({
      confirmDeleteOpen: true,
      optionIdToDelete: optionId,
    });
  };

  private closeConfirmDelete = () => {
    this.setState({
      confirmDeleteOpen: false,
      optionIdToDelete: null,
    });
  };

  private deleteOption = async () => {
    if (!this.props.removeFormOption || !this.state.optionIdToDelete) {
      return;
    }

    this.props.removeFormOption(this.state.optionIdToDelete);

    try {
      await formOptionApi.delete(this.state.optionIdToDelete);
    } catch (e) {
      //
    }

    this.closeConfirmDelete();
  };

  private duplicateOption = async (optionId: number) => {
    try {
      const { formOptions } = await formOptionApi.duplicate(optionId);
      for (const option of formOptions) {
        this.props.addFormOption(option);
      }
    } catch (e) {
      //
    }
  };

  private onDragStart: OnBeforeDragStartResponder = result => {
    const { formOptions } = store.getState();
    const dragOption = formOptions.byId[result.draggableId];
    if (!dragOption) {
      return;
    }
    this.props.setDragFid(dragOption.f_id);
  };

  private onDragEnd: OnDragEndResponder = result => {
    this.props.setDragFid(null);
    if (!result.destination) {
      return;
    }
    const { formOptions } = store.getState();
    const destinationOption = formOptions.byId[result.destination.droppableId];
    const dragOption = formOptions.byId[result.draggableId];

    if (
      !dragOption ||
      (!destinationOption && result.destination.droppableId !== 'form-builder')
    ) {
      return;
    }

    const source = result.draggableId;
    let destination: any = result.destination.droppableId;
    const destinationIndex = result.destination.index;
    const sourceIndex = result.source.index;

    if (
      dragOption.f_id === formFeatureIds.SECTION &&
      destination === 'form-builder'
    ) {
      destination = null;
    }

    const isNewParent =
      destination && formOptions.byId[source].parent_id !== +destination;
    const params = {
      source,
      destination,
      destinationIndex,
      sourceIndex,
      state: formOptions,
    };
    const updatedOptions = isNewParent ? move(params) : reorder(params);
    const newById = {
      ...formOptions.byId,
      ...updatedOptions.reduce(
        (curr, x) => ({ ...curr, [x.id]: { ...x } }),
        {},
      ),
    };
    // insert in state

    // change parent id and sort order on dragOption
    this.props.replaceFormOptions({
      byId: newById,
      allIds: groupAndSortOptions(Object.keys(newById).map(k => newById[k])),
    });
    // update server
    formOptionApi.reorder(
      this.props.formUid,
      +result.destination.droppableId,
      updatedOptions.map(x => x.id),
    );
  };

  public render() {
    const { sections, t, formUid, formType, fid } = this.props;
    const { addWidgetOpen, columnToAddWidget, confirmDeleteOpen } = this.state;
    return (
      <React.Fragment>
        <DragDropContext
          onDragEnd={this.onDragEnd}
          onDragStart={this.onDragStart}
        >
          <Droppable
            isDropDisabled={fid !== formFeatureIds.SECTION}
            droppableId="form-builder"
          >
            {provided => (
              <div
                className={'flex justify-center flex-col'}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                {sections.map((section, i) => (
                  <Section
                    optionId={section.id}
                    key={section.id}
                    formUid={formUid}
                    openAddOption={this.openAddWidget}
                    openDeleteOption={this.openConfirmDelete}
                    duplicateOption={this.duplicateOption}
                    index={i}
                  />
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <div className="text-center">
          <Button type="primary" onClick={this.addSection}>
            {t('super:screens.form.addSection')}
          </Button>
        </div>

        <WidgetAdd
          isOpen={addWidgetOpen}
          handleClose={this.closeAddWidget}
          destination={columnToAddWidget}
          formUid={formUid}
          onCreate={this.onWidgetAdded}
          formType={formType}
        />

        <ConfirmationDialogue
          title={t('forms:deleteOptionModalTitle')}
          body={t('forms:deleteOptionModalBody')}
          contentLabel={t('forms:deleteOptionModalTitle')}
          handleClose={this.closeConfirmDelete}
          onConfirm={this.deleteOption}
          isOpen={confirmDeleteOpen}
        />
      </React.Fragment>
    );
  }
}

const mapState = (state: StoreState) => ({
  fid: state.formOptions.draggingFid,
});

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    addFormOption: (option: FormOption) => dispatch(addFormOption(option)),
    removeFormOption: (optionId: number) =>
      dispatch(removeFormOption(optionId)),
    moveFormOption: (data: any) => dispatch(moveFormOption(data)),
    replaceFormOptions: (data: any) => dispatch(replaceFormOptions(data)),
    setDragFid: (fid: number | null) => dispatch(setDragFid(fid)),
  };
}

export default withNamespaces()(
  connect(mapState, mapDispatchToProps)(FormBuilder),
);

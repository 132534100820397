import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import { SignOff } from '../../../types';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  signOffs: SignOff[];
}

const SignedUsersModal = ({ handleClose, isOpen, signOffs }: Props) => {
  return (
    <Modal
      contentLabel={'Users who have signed off'}
      handleClose={handleClose}
      isOpen={isOpen}
    >
      <ModalHeader handleClose={handleClose}>Signed off users</ModalHeader>
      <ModalBody>
        <div className="flex flex-col">
          {signOffs.map(user => (
            <div className="flex flex-row items-center">
              <div className="w-2/3">
                <h3>{user.full_name}</h3>
                <p>Email: {user.email}</p>
              </div>
              <div className="w-1/3">
                <img
                  src={user.signature_url}
                  alt={`${user.full_name}'s signature`}
                  className="w-full"
                />
              </div>
            </div>
          ))}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SignedUsersModal;

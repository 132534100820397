import * as React from 'react';
import { Certificate, Storage } from '../types';
import UserCertificate from './userCertificates/UserCertificate';

interface Props {
  certs: Array<{ cert: Certificate; image?: Storage }>;
  onUpdate?: (cert: Certificate) => void;
  onDelete?: (cert: Certificate) => void;
}

const UserCertificates = ({ certs, onUpdate, onDelete }: Props) => {
  return (
    <div className="flex flex-col">
      {certs.map(cert => (
        <UserCertificate
          key={cert.cert.uid}
          cert={cert.cert}
          image={cert.image}
          onUpdate={onUpdate}
          onDelete={onDelete}
        />
      ))}
    </div>
  );
};

export default UserCertificates;

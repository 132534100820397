import * as React from 'react';
import Page from '../../components/Page';
import PageToolbar from '../../components/PageToolbar';
import PageContent from '../../components/PageContent';
import ChecklistShare from './checklist/ChecklistShare';
import {
  useMountVisibility,
  useSimpleFetch,
  useTextInput,
} from '../../helpers/hooks';
import checklistApi from '../../api/checklist.api';
import { match } from 'react-router';
import { Checklist as IChecklist, Task, User } from '../../types';
import Loading from '../../components/Loading';
import Checkbox from '../../components/Checkbox';
import taskApi from '../../api/task.api';
import moment from 'moment';
import Button from '../../components/Button';
import TaskEdit from './checklist/TaskEdit';
import { toast } from 'react-toastify';
import Save from '../../svg/Save';
import Archive from '../../svg/Archive';
import Share from '../../svg/Share';
import Pencil from '../../svg/Pencil';

interface Props {
  match: match<{ checklistUid: string }>;
}

const Checklist = ({ match: pMatch }: Props) => {
  const { checklistUid } = pMatch.params;
  const shareState = useMountVisibility();
  const [editing, setEditing] = React.useState(false);
  const newTask = useTextInput();
  const [adding, setAdding] = React.useState(false);
  const [savingTemplate, setSavingTemplate] = React.useState(false);

  const checklist = useSimpleFetch<IChecklist | null>({
    initial: null,
    fetch: () => checklistApi.show(pMatch.params.checklistUid),
    change: [checklistUid],
    accessor: 'checklist',
  });

  const tasks = useSimpleFetch<Array<{ task: Task; user?: User }>>({
    initial: [],
    fetch: () => checklistApi.tasks(pMatch.params.checklistUid),
    change: [checklistUid],
    accessor: 'tasks',
  });

  const [localTasks, setLocalTasks] = React.useState<{
    [uid: string]: boolean;
  }>({});

  React.useEffect(
    () => {
      setLocalTasks(
        tasks.value.reduce((curr, task) => {
          curr[task.task.uid] = +task.task.status === 1;
          return curr;
        }, {}),
      );
    },
    [checklistUid, tasks.value],
  );

  const onLocalTaskChange = async (uid: string, checked: boolean) => {
    setLocalTasks({
      ...localTasks,
      [uid]: checked,
    });
    await taskApi.update(uid, {
      status: checked ? 1 : 2,
    });
    tasks.performFetch();
  };

  const onTaskAdd = async () => {
    setAdding(true);
    await checklistApi.createTask(checklistUid, { name: newTask.value });
    newTask.setValue('');
    setAdding(false);
    await tasks.performFetch();
  };

  const saveAsTemplate = async () => {
    setSavingTemplate(true);
    const res = await checklistApi.saveAsTemplate(checklistUid);
    if (res.data) {
      toast.success('Saved as template');
    }
    if (res.errors) {
      toast.error('Something went wrong saving the template');
    }
    setSavingTemplate(false);
  };

  if (!checklist.value) {
    return <Loading loading={true} />;
  }

  const actions = [
    { label: 'Share', onClick: shareState.open, icon: <Share /> },
    { label: 'Edit', onClick: () => setEditing(true), icon: <Pencil /> },
    // { label: 'Archive', type: 'primary', icon: <Archive /> },
  ];

  const editingActions = [
    {
      label: 'Save as Template',
      onClick: saveAsTemplate,
      icon: <Save />,
      disabled: savingTemplate,
    },
    { label: 'Done', onClick: () => setEditing(false) },
  ];

  return (
    <Page>
      <PageToolbar
        title={checklist.value.name}
        actions={editing ? editingActions : actions}
      />

      <PageContent>
        {!editing ? (
          <div className="bg-white p-4">
            <div className="flex flex-col">
              <div className="flex flex-row justify-between border-0 border-b-1 border-solid border-border p-4">
                <h3>Task</h3>
                <h3>Complete</h3>
              </div>

              {tasks.value.map(item => (
                <div className="flex flex-row justify-between items-center p-4 border-0 border-b-1 border-solid border-border">
                  <div>
                    <p>{item.task.name}</p>
                    {+item.task.status === 1 &&
                      item.user && (
                        <p className={'text-sm text-grey-darker mt-1'}>
                          Completed by{' '}
                          <span className={'text-grey-darkest'}>
                            {item.user.username}
                          </span>{' '}
                          on{' '}
                          <span className={'text-grey-darkest'}>
                            {moment
                              .unix(item.task.completed!)
                              .format('MMM Do, YYYY hh:mm a')}
                          </span>
                        </p>
                      )}
                  </div>
                  <Checkbox
                    id={`task-${item.task.uid}`}
                    checked={localTasks[item.task.uid]}
                    onChange={e => onLocalTaskChange(item.task.uid, e)}
                  />
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>
            <div className="p-4 flex flex-col bg-grey-lighter">
              <label className={'mb-2 block'}>Add new task</label>
              <div className={'flex flex-row'}>
                <input
                  type="text"
                  className={'w-full p-2'}
                  placeholder={'Task name'}
                  {...newTask}
                />
                <Button type={'primary'} onClick={onTaskAdd} loading={adding}>
                  Add
                </Button>
              </div>
            </div>

            <ul className="bg-white list-reset">
              {tasks.value.map(item => (
                <TaskEdit
                  task={item.task}
                  key={item.task.uid}
                  refresh={tasks.performFetch}
                />
              ))}
            </ul>
          </div>
        )}
      </PageContent>

      {shareState.mounted && (
        <ChecklistShare
          isOpen={shareState.visible}
          handleClose={shareState.close}
          checklistUid={checklistUid}
        />
      )}
    </Page>
  );
};

export default Checklist;

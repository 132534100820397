import * as React from 'react';
import Checkbox from '../../../components/Checkbox';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Dispatch } from 'redux';
import {
  addRequiredCertificateToRole,
  removeReuiredCertificateFromRole,
} from '../../../actions/roleCertificateMatrix';
import workRoleApi from '../../../api/workRoleApi.api';

interface MatrixCheckboxProps {
  roleUid: string;
  certTypeUid: string;
  requiredCerts?: string[];
  check?: (roleUid: string, certUid: string) => any;
  uncheck?: (roleUid: string, certUid: string) => any;
}

class MatrixCheckbox extends React.Component<MatrixCheckboxProps> {
  private onChange = (checked: boolean) => {
    if (!this.props.check || !this.props.uncheck) {
      return;
    }

    if (checked) {
      this.props.check(this.props.roleUid, this.props.certTypeUid);
      this.markRequired();
    } else {
      this.props.uncheck(this.props.roleUid, this.props.certTypeUid);
      this.markOptional();
    }
  };

  private markRequired = async () => {
    try {
      await workRoleApi.markCertTypeAsRequired(
        this.props.roleUid,
        this.props.certTypeUid,
      );
    } catch (e) {
      //
    }
  };

  private markOptional = async () => {
    try {
      await workRoleApi.markCertTypeAsOptional(
        this.props.roleUid,
        this.props.certTypeUid,
      );
    } catch (e) {
      //
    }
  };

  public render() {
    const { requiredCerts, certTypeUid, roleUid } = this.props;
    return (
      <Checkbox
        onChange={this.onChange}
        checked={requiredCerts && requiredCerts.indexOf(certTypeUid) !== -1}
        id={`${roleUid}-${certTypeUid}`}
      />
    );
  }
}

export default connect(
  (state: StoreState, ownProps: MatrixCheckboxProps) => ({
    requiredCerts: state.roleCertificateMatrix.data[ownProps.roleUid],
  }),
  (dispatch: Dispatch) => ({
    check: (roleUid: string, certUid: string) =>
      dispatch(addRequiredCertificateToRole(roleUid, certUid)),
    uncheck: (roleUid: string, certUid: string) =>
      dispatch(removeReuiredCertificateFromRole(roleUid, certUid)),
  }),
)(MatrixCheckbox);

import * as React from 'react';
import { Moment } from 'moment';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import ResourceTable from '../../components/ResourceTable';
import EntityFilter from '../../components/dateFilterWidget/EntityFilter';
import {
  CompanyGroup,
  Company,
  Form,
  Crew,
  Site,
  User as IUser,
} from '../../types';
import { SelectOption, SelectOptionObject } from '../../components/Select';
import { incidentStatuses } from '../../constants/index';
import companyApi from '../../api/company.api';
import companyGroupApi from '../../api/companyGroup.api';
import Loading from '../../components/Loading';
import Button from '../../components/Button';
import Upload from '../../svg/Upload';
import { connect } from 'react-redux';
import { openDownloads } from '../../actions/ui';
import { getSiteLabel } from '../../helpers';
import { useInicidentProps } from '../../helpers/hooks';

interface Props extends WithNamespaces {
  company?: Company;
  companyGroup?: CompanyGroup;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
  companies?: Company[];
  queryOverrides?: {
    siteUids?: string[];
    crewUids?: string[];
    companyUids?: string[];
    userUids?: string[];
  };
  goToIncident: (uid: string) => void;
  start: Moment;
  end: Moment;
  showDownloads: () => any;
}

interface Filters {
  users: SelectOptionObject[];
  form: SelectOption;
  crews: SelectOptionObject[];
  sites: SelectOptionObject[];
  companies: SelectOptionObject[];
}

const IncidentsMain = ({
  start,
  end,
  queryOverrides,
  company,
  companyGroup,
  users,
  crews,
  forms,
  sites,
  companies,
  goToIncident,
  showDownloads,
}: Props) => {
  const [incidents, setIncidents] = React.useState<any>([]);
  const [loading, setLoading] = React.useState(false);
  const [filters, setFilters] = React.useState<Filters>({
    users: [],
    form: null,
    crews: [],
    sites: [],
    companies: [],
  });
  const { incidentPropOptions } = useInicidentProps(company ? company.uid : '');

  function formatIncidentType(type: string) {
    for (const { slug, name } of incidentPropOptions) {
      if (slug === type) {
        return name;
      }
    }
    return '';
  }

  const columns = [
    { Header: 'ID', accessor: 'incident.id' },
    { Header: 'Case #', accessor: 'incident.case_id' },
    {
      Header: 'Created By',
      accessor: (r: any) => (r.user ? r.user.username : ''),
      id: 'creator',
    },
    {
      Header: () => getSiteLabel(),
      accessor: (r: any) => (r.site ? r.site.name : ''),
      id: 'site',
    },
    {
      Header: 'Type',
      accessor: ({ incident }: any) =>
        incident.type ? incident.type.map(formatIncidentType).join(', ') : '',
      id: 'incident',
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: (row: any) =>
        +row.original.incident.status === incidentStatuses.OPENED
          ? 'Opened'
          : 'Completed',
    },
  ];

  const isCompany = company ? true : false;
  const scope = isCompany ? company : companyGroup;

  if (!scope) {
    return null;
  }

  const fetchIncidents = async () => {
    setLoading(true);
    const endpoint = isCompany
      ? companyApi.incidents
      : companyGroupApi.incidents;
    try {
      const res = await endpoint(scope.uid, {
        ...getFiltersFormatted(),
        ...queryOverrides,
      });
      setIncidents(res.incidents);
    } catch (e) {
      //
    }
    setLoading(false);
  };

  React.useEffect(
    () => {
      setFilters({ ...filters, sites: [], crews: [] });
      fetchIncidents();
    },
    [scope.uid],
  );

  React.useEffect(
    () => {
      fetchIncidents();
    },
    [filters, start.unix(), end.unix()],
  );

  const getFiltersFormatted = () => {
    return {
      start: start.unix(),
      end: end.unix(),
      siteUids: filters.sites.map(site => site.value),
      crewUids: filters.crews.map(crew => crew.value),
      companyUids: filters.companies.map(c => c.value),
    };
  };

  const onFilterChange = (newFilters: any) => {
    setFilters({
      filters,
      ...newFilters,
    });
  };

  const onExport = async () => {
    setLoading(true);
    const endpoint = isCompany
      ? companyApi.exportIncidents
      : companyGroupApi.exportIncidents;
    await endpoint(scope.uid, {
      ...getFiltersFormatted(),
      ...queryOverrides,
    });
    showDownloads();
    setLoading(false);
  };

  const entities = {
    sites,
    crews,
    companies,
  };
  return (
    <div className="relative">
      <Loading loading={loading} />
      {!queryOverrides && (
        <EntityFilter
          onFilterChange={onFilterChange}
          entities={entities}
          filters={filters}
        />
      )}
      <div className="flex flex-row justify-end p-4 pt-0">
        <Button type="default" onClick={onExport} loading={loading}>
          <Upload className={'w-4 h-4 mr-4'} />
          Export Data
        </Button>
      </div>
      <ResourceTable
        data={incidents}
        columns={columns}
        defaultSortKey={'id'}
        onRowClick={(row: any) => goToIncident(row.incident.uid)}
      />
    </div>
  );
};

export default withNamespaces()(
  connect(
    null,
    dispatch => ({
      showDownloads: () => dispatch(openDownloads()),
    }),
  )(IncidentsMain),
);

import * as React from 'react';
import styled from 'styled-components';
import InlineInput from '../components/InlineInput';
import Cog from '../svg/Cog';
import User from '../svg/User';
import Lock from '../svg/Lock';
import Button from '../components/Button';
import { match } from 'react-router';
import { useTextInput } from '../helpers/hooks';
import InlineAlert from '../components/InlineAlert';
import { cssVars, localStorageKeys } from '../constants/index';
import { History } from 'history';
import Loading from '../components/Loading';
import crewInvitationApi from '../api/crewInvitation.api';
import Axios from 'axios';

const LogoImg = styled.img`
  max-width: 100%;
  height: auto;
  align-self: center;
  margin-bottom: 50px;
`;

const ContentWrapper = styled.div`
  padding: ${cssVars.padding[8]};
  @media screen and (min-width: 700px) {
    padding: ${cssVars.padding[4]} 0;
    max-width: 500px;
  }
`;

interface Props {
  match: match<{ crewInviteUid: string }>;
  history: History;
}

const AcceptCrewInvite = ({ match: pMatch, history }: Props) => {
  const username = useTextInput();
  const password = useTextInput();
  const again = useTextInput();
  const firstName = useTextInput();
  const lastName = useTextInput();
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    if (localStorage.getItem(localStorageKeys.USER_TOKEN) != null) {
      history.push('/dashboard/worker');
    }
  }, []);

  const onSubmit = async () => {
    if (password.value !== again.value) {
      setErrors({ again: ['does not match'] });
      return;
    }
    setLoading(true);
    try {
      const res = await Axios.post(
        `${process.env.API_URL}/v1/crew-invitations/${
          pMatch.params.crewInviteUid
        }/accept`,
        {
          firstName: firstName.value,
          lastName: lastName.value,
          username: username.value,
          password: password.value,
        },
      );
      if (res.data) {
        localStorage.setItem(localStorageKeys.USER_TOKEN, res.data.token);
        localStorage.setItem(
          localStorageKeys.REFRESH_TOKEN,
          res.data.refreshToken,
        );
        history.push('/dashboard/worker/dashboard');
        return;
      }
    } catch (e) {
      const { data } = e.response;
      if (data.errors) {
        if (data.errors.token) {
          setErrors({ code: ['invalid'] });
        } else {
          setErrors(data.errors);
        }
      }
    }
    setLoading(false);
  };

  return (
    <div className="relative flex justify-center">
      <Loading loading={loading} />
      <ContentWrapper
        className={'relative flex flex-1 flex-col justify-center'}
      >
        <LogoImg src={'/images/logo.png'} />

        <InlineAlert
          type="info"
          text={`You're almost ready! Just pick a username and password`}
        />

        <div className="flex flex-col mt-4 bg-white p-4">
          <div className="">
            <label htmlFor="first" className={'mb-4 block'}>
              (Optional) Your Name:
            </label>
            <div className="flex flex-col md:flex-row">
              <div className="flex-1 md:mr-4">
                <InlineInput
                  label="First"
                  id="first"
                  icon={<User />}
                  {...firstName}
                  errorMessage={errors.firstName}
                />
              </div>
              <div className="flex-1">
                <InlineInput
                  label="Last"
                  icon={<User />}
                  {...lastName}
                  errorMessage={errors.lastName}
                />
              </div>
            </div>
          </div>
          <hr className="mb-4" />
          <InlineInput
            label="username"
            icon={<User />}
            {...username}
            errorMessage={errors.username}
          />
          <InlineInput
            label="Password"
            icon={<Lock />}
            {...password}
            type={'password'}
            errorMessage={errors.password}
          />
          <InlineInput
            label="Again"
            icon={<Lock />}
            {...again}
            type={'password'}
            errorMessage={errors.again}
          />
          <InlineInput
            label="code"
            icon={<Cog />}
            disabled={true}
            value={pMatch.params.crewInviteUid}
            errorMessage={errors.code}
          />
          <Button type="default" loading={loading} onClick={onSubmit}>
            Accept Invite
          </Button>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default AcceptCrewInvite;

import * as React from 'react';
import styled from 'styled-components';
import { cssVars, inspectionAnswers } from '../../constants/index';
import { biggerThanMD, mandatoryErrorStyles } from '../../helpers/style';
import { useState, useCallback, useEffect } from 'react';
import WidgetContainer from '../form/WidgetContainer';
import WidgetContent from '../form/WidgetContent';
import {
  Widget,
  InspectionResponse,
  ResponseFile,
  ResponseComment,
} from '../../types';
import formService from '../../services/forms.service';
import { debounce } from 'lodash';
import WidgetMetaButtons from './WidgetMetaButtons';
import RequiredWidget from './RequiredWidget';

const InspectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 2px solid ${cssVars.colors.border};
  border-radius: 3px;

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  padding: ${cssVars.padding[4]};
  ${biggerThanMD(`
    padding: 0 ${cssVars.padding[4]};
  `)};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  ${biggerThanMD(`
    width: auto;
  `)};
`;

const AnswerButton = styled.button<{ bg?: string }>`
  background: ${props => (props.bg ? props.bg : cssVars.colors.border)};
  border: none;
  border-left: 1px solid ${cssVars.colors.border};
  padding: ${cssVars.padding[4]};
  cursor: pointer;
  flex: 1 1 0%;

  ${biggerThanMD(`
    flex: 1;
  `)} &:first-of-type {
    border-left: 0;
  }
`;

interface Props {
  widget: Widget;
  response: InspectionResponse | null;
  comments: ResponseComment[];
  files: ResponseFile[];
  editable?: boolean;
  instanceRevUid: string;
  error?: boolean;
}

const DEFAULT_VALUE = {
  inspection_answer: '',
};

const Inspection = ({
  widget,
  editable,
  instanceRevUid,
  response,
  error,
  files,
  comments,
}: Props) => {
  const [value, setValue] = useState(
    response ? response.inspection_answer : DEFAULT_VALUE,
  );

  const updateServer = useCallback(
    debounce(
      async val =>
        await formService.setWidgetResponse(instanceRevUid, widget.uid, {
          inspection_answer: val,
        }),
      800,
    ),
    [instanceRevUid, widget.uid],
  );

  const setAndSyncValue = async (answer: string) => {
    setValue(answer);
    updateServer(answer);
  };

  useEffect(
    () => {
      if (response === null) {
        return;
      }
      setValue(response.inspection_answer);
    },
    [response],
  );

  return (
    <WidgetContainer>
      <WidgetContent
        style={
          error && response === null && value === DEFAULT_VALUE
            ? mandatoryErrorStyles
            : {}
        }
        className="bg-white mt-4 rounded"
      >
        <div className={'mb-4'}>
          <InspectionWrapper>
            <Label>
              {widget.title}
              {widget.mandatory && <RequiredWidget />}
            </Label>
            <ButtonWrapper className="flex flex-nowrap">
              <AnswerButton
                bg={
                  value === inspectionAnswers.POSITIVE
                    ? cssVars.colors.green
                    : ''
                }
                disabled={!editable}
                onClick={() => setAndSyncValue(inspectionAnswers.POSITIVE)}
              >
                {widget.positive_label}
              </AnswerButton>
              <AnswerButton
                bg={
                  value === inspectionAnswers.NEGATIVE ? cssVars.colors.red : ''
                }
                disabled={!editable}
                onClick={() => setAndSyncValue(inspectionAnswers.NEGATIVE)}
              >
                {widget.negative_label}
              </AnswerButton>
              <AnswerButton
                bg={
                  value === inspectionAnswers.NEUTRAL
                    ? cssVars.colors.default
                    : ''
                }
                disabled={!editable}
                onClick={() => setAndSyncValue(inspectionAnswers.NEUTRAL)}
              >
                {widget.neutral_label}
              </AnswerButton>
            </ButtonWrapper>
          </InspectionWrapper>
        </div>
        <WidgetMetaButtons
          editable={editable}
          files={files}
          widget={widget}
          instanceRevUid={instanceRevUid}
          comments={comments}
        />
      </WidgetContent>
    </WidgetContainer>
  );
};

export default Inspection;

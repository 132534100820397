import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../../constants';

const Wrapper = styled.div`
  width: 100%;
  padding: ${cssVars.padding[4]};
  position: relative;
`;

const Background = styled.div`
  width: 100%;
  z-index: 0;
  border: 1px dashed #afafaf;
  height: 1px;
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
`;

const Title = styled.h2<{ visible: boolean }>`
  background: #afafaf;
  color: white;
  padding: ${cssVars.padding[2]} ${cssVars.padding[4]};
  display: inline-block;
  position: relative;
  z-index: 1;
  font-size: ${cssVars.textSizes.lg};
  font-weight: normal;
  ${props => (props.visible ? '' : 'visibility: hidden;')};
`;

interface Props {
  title?: string;
}

const OptionTitle = ({ title }: Props) => {
  return (
    <Wrapper>
      <Background />
      <Title visible={!!title}>{title || 'empty'}</Title>
    </Wrapper>
  );
};

export default OptionTitle;

import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: row;

  & > * {
    flex: 1;
    margin-left: 10px;

    &:first-child {
      margin-left: 0;
    }
  }
`;

export default class Row extends React.Component {
  public render() {
    return <Container>{this.props.children}</Container>;
  }
}

import * as siteFormActions from '../../actions/super/siteForms';
import { ActionType, getType } from 'typesafe-actions';
import { Form } from '../../types';

export type SuperSiteForms = ActionType<typeof siteFormActions>;

export type SuperSiteFormState = Form[];

const DEFAULT_STATE: SuperSiteFormState = [];

export default function superSiteFormsReducer(
  state: SuperSiteFormState = DEFAULT_STATE,
  action: SuperSiteForms,
) {
  switch (action.type) {
    case getType(siteFormActions.setSiteForms):
      return [...action.payload];
    case getType(siteFormActions.addSiteForm):
      return [...state, { ...action.payload }];
    case getType(siteFormActions.removeSiteForm):
      return state.filter(form => form.uid !== action.payload);
    default:
      return state;
  }
}

import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const FolderOpen = (props: SVGProps) => (
  <svg viewBox="0 0 32 32" enableBackground="new 0 0 32 32" {...props}>
    <g>
      <path
        fill="#E0B03B"
        d="M26.9,8.4H9.5V7.6c0-0.6-0.5-1-1-1H2.8c-0.6,0-1,0.5-1,1v0.8H1.5C0.7,8.4,0,9,0,9.9V26c0,0.8,0.7,1.5,1.5,1.5
		h25.4c0.8,0,1.5-0.7,1.5-1.5V9.9C28.4,9,27.7,8.4,26.9,8.4L26.9,8.4z"
      />
      <path fill="#FFFFFF" d="M2.1,9.8h24.1V26H2.1L2.1,9.8z" />
      <path
        fill="#FFC843"
        d="M28.8,26c-0.2,0.8-1,1.5-1.9,1.5H1.5c-0.8,0-1.3-0.7-1.1-1.5l3.2-13c0.2-0.8,1-1.5,1.9-1.5h25.4
		c0.8,0,1.3,0.7,1.1,1.5L28.8,26L28.8,26z"
      />
    </g>
  </svg>
);

export default BaseSVG(FolderOpen);

import * as userActions from '../../actions/companyGroup/users';
import { ActionType, getType } from 'typesafe-actions';
import { User } from '../../types';

export type CompanyGroupUserAction = ActionType<typeof userActions>;

export interface CompanyGroupUsersState {
  data: User[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyGroupUid: string;
}

const DEFAULT_STATE: CompanyGroupUsersState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  companyGroupUid: '',
};

export default function companyGroupUsersReducer(
  state: CompanyGroupUsersState = DEFAULT_STATE,
  action: CompanyGroupUserAction,
) {
  switch (action.type) {
    case getType(userActions.companyGroupUsersFetchRequest):
      return {
        ...state,
        loading: true,
        companyGroupUid: action.payload,
      };
    case getType(userActions.companyGroupUsersFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...action.payload],
      };
    case getType(userActions.companyGroupUsersFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
      };
    case getType(userActions.companyGroupAddUser):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(userActions.companyGroupRemoveUser):
      return {
        ...state,
        data: state.data.filter(user => user.uid !== action.payload),
      };
    default:
      return state;
  }
}

import * as siteActions from '../actions/sites';
import { ActionType, getType } from 'typesafe-actions';
import { Site } from '../types';

export type SiteAction = ActionType<typeof siteActions>;

export interface SitesState {
  data: Site[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyUid: string;
  invalid: boolean;
}

const DEFAULT_STATE: SitesState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  companyUid: '',
  invalid: false,
};

export default function sitesReducer(
  state: SitesState = DEFAULT_STATE,
  action: SiteAction,
) {
  switch (action.type) {
    case getType(siteActions.sitesFetchRequest):
      return {
        ...state,
        loading: true,
        companyUid: action.payload,
      };
    case getType(siteActions.sitesFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...action.payload],
        invalid: false,
      };
    case getType(siteActions.sitesFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
        invalid: true,
      };
    case getType(siteActions.addSite):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(siteActions.removeSite):
      return {
        ...state,
        data: state.data.filter(site => site.uid !== action.payload),
      };
    case getType(siteActions.sitesInvalidate):
      return {
        ...state,
        invalid: true,
      };
    default:
      return state;
  }
}

import * as React from 'react';
import {
  Container,
  HeaderWrapper,
  HeaderActions,
  Header,
  HeaderAction,
  Body,
  DragWrapper,
  DragHandler,
} from './optionParentStyles';
import Tabs, { Tab } from '../../components/Tabs';
import Column from './Column';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { makeGetChildren } from '../../selectors/formOptions';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { FormOption } from '../../types';
import { Dispatch } from 'redux';
import { addFormOption, removeFormOption } from '../../actions/formOptions';
import formOptionApi from '../../api/formOption.api';
import { formFeatureIds } from '../../constants';
import { Draggable } from 'react-beautiful-dnd';

interface Props extends WithNamespaces {
  optionId: number;
  childOptions?: FormOption[];
  openAddOption?: (optionId: number) => void;
  removeFormOption?: (optionId: number) => any;
  addFormOption?: (option: FormOption) => any;
  openDeleteOption?: (optionId: number) => any;
  duplicateOption?: (optionId: number) => any;
  formUid: string;
  index: number;
  fid?: number;
}

interface State {
  activeTab: number;
}

class Row extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      activeTab: 0,
    };
  }

  public componentDidMount() {
    // todo if no columns, create one
  }

  private changeTab = (activeTab: number) => {
    this.setState({
      activeTab,
    });
  };

  private addColumn = async (tabIndex: number) => {
    const { formUid, optionId: parentId, childOptions } = this.props;

    const newIndex = (childOptions || []).length;

    try {
      const { option } = await formOptionApi.create({
        formUid,
        parentId,
        typeId: formFeatureIds.COL,
      });

      if (this.props.addFormOption) {
        this.props.addFormOption(option);
      }

      this.setState({
        activeTab: newIndex,
      });
    } catch (e) {
      //
    }
  };

  public componentWillUpdate(
    nextProps: Readonly<Props>,
    nextState: Readonly<State>,
    nextContext: any,
  ): void {
    const prevChildren = this.props.childOptions || [];
    const nextChildren = nextProps.childOptions || [];

    // removed a column, make first tab active
    if (nextChildren.length < prevChildren.length) {
      this.setState({
        activeTab: 0,
      });
    }
  }

  private renderTabs = () => {
    const { activeTab } = this.state;
    const {
      t,
      childOptions,
      openAddOption,
      openDeleteOption,
      formUid,
    } = this.props;

    const tabs: Tab[] = (childOptions || []).map((o, i, arr) => ({
      label: `${t('common:formWidgets.column')} ${i + 1}`,
      component: (
        <Column
          optionId={o.id}
          formUid={formUid}
          openAddOption={openAddOption}
          openDeleteOption={openDeleteOption}
          canBeDeleted={arr.length > 1}
        />
      ),
    }));

    if (tabs.length < 4) {
      tabs.push({
        label: '+ Add Column',
        onClick: this.addColumn,
      });
    }

    return (
      <Tabs tabs={tabs} activeTab={activeTab} onTabChange={this.changeTab} />
    );
  };

  private openConfirmDelete = () => {
    if (this.props.openDeleteOption) {
      this.props.openDeleteOption(this.props.optionId);
    }
  };

  private duplicateOption = () => {
    if (this.props.duplicateOption) {
      this.props.duplicateOption(this.props.optionId);
    }
  };

  public render() {
    const { t, index, optionId } = this.props;

    return (
      <Draggable draggableId={`${optionId}`} index={index}>
        {provided => (
          <Container
            className={'bg-form-option-row'}
            ref={provided.innerRef}
            {...provided.draggableProps}
          >
            <HeaderWrapper className={'bg-form-option-row'}>
              <DragWrapper {...provided.dragHandleProps}>
                <DragHandler />
              </DragWrapper>
              <Header>
                {t('common:formWidgets.row')}
                <HeaderActions>
                  <HeaderAction
                    className={'bg-transparent'}
                    onClick={this.duplicateOption}
                  >
                    {t('super:screens.form.duplicate')}
                  </HeaderAction>
                  <HeaderAction
                    className={'bg-primary text-white'}
                    onClick={this.openConfirmDelete}
                  >
                    {t('super:screens.form.delete')}
                  </HeaderAction>
                </HeaderActions>
              </Header>
            </HeaderWrapper>
            <Body>{this.renderTabs()}</Body>
          </Container>
        )}
      </Draggable>
    );
  }
}

function makeMapStateToProps() {
  const getChildren = makeGetChildren();
  return (state: StoreState, ownProps: Props) => ({
    childOptions: getChildren(state, ownProps),
    fid: state.formOptions.draggingFid,
  });
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    removeFormOption: (optionId: number) =>
      dispatch(removeFormOption(optionId)),
    addFormOption: (option: FormOption) => dispatch(addFormOption(option)),
  };
}

export default withNamespaces()(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  )(Row),
);

import * as React from 'react';
import moment from 'moment';
import { ChildrenResponse } from '../../../api/storage.api';
import VerticalMenu from '../../../svg/VerticalMenu';
import styled from 'styled-components';
import { cssVars, dragDropTypes } from '../../../constants/index';
import FolderOpenLocked from '../../../svg/FolderOpenLocked';
import File from '../../../svg/File';
import FolderOpen from '../../../svg/FolderOpen';
import CheckCircle from '../../../svg/CheckCircle';
import { contains } from 'ramda';
import ChevronUp from '../../../svg/ChevronUp';
import ChevronDown from '../../../svg/ChevronDown';
import { compare } from 'natural-orderby';
import { getPrivateUrlFromUid } from '../../../components/PrivateImage';
import {
  DragSource,
  DragSourceConnector,
  DropTargetConnector,
  DropTargetMonitor,
  DropTarget,
} from 'react-dnd';

const Table = styled.table`
  border-spacing: 0;
  width: 100%;
  border: 1px solid ${cssVars.colors.grey};
`;

const Td = styled.td`
  padding: ${cssVars.padding[4]};
  border-left: 1px solid ${cssVars.colors.grey};
  border-bottom: 1px solid ${cssVars.colors.grey};

  &:first-of-type {
    border-left: 0;
  }
`;

const Th = styled.th`
  background: ${cssVars.colors.grey};
  font-weight: normal;
  padding: ${cssVars.padding[4]};
  text-align: left;
  border-left: 1px solid ${cssVars.colors['grey-dark']};
  position: relative;
  cursor: pointer;

  &:first-of-type {
    border-left: 0;
  }

  &:hover {
    background: ${cssVars.colors['grey-light']};
  }

  & svg {
    position: absolute;
    top: 50%;
    right: 15px;
    transform: translateY(-50%);
  }
`;

const Tr = styled.tr<{ selected?: boolean }>`
  &:hover td {
    background: ${cssVars.colors['grey-lighter']};
    cursor: pointer;
  }

  ${props =>
    props.selected
      ? `
      & td {
        background: ${cssVars.colors['red-lighter']};
      }
      &:hover td {
        background: ${cssVars.colors['red-lightest']};
      }
    `
      : ''};
`;

const Row = ({
  storage,
  onClick,
  selected,
  type,
  onDoubleClick,
  locked,
  openFolder,
  openMenu,
  connectDragSource,
  connectDropTarget,
  isOver,
}: any) => {
  const dropFunc = type === 'Dir' ? connectDropTarget : (input: any) => input;
  return (
    <Tr
      ref={ref => {
        dropFunc(ref);
        connectDragSource(ref);
      }}
      onClick={onClick}
      selected={selected}
      onDoubleClick={onDoubleClick}
    >
      <Td style={{ background: isOver ? cssVars.colors.red : undefined }}>
        <CheckCircle
          className={`w-8 h-8 mr-4 ${
            selected ? 'text-red' : 'text-grey-darker'
          }`}
        />
        {type === 'Dir' ? (
          <>
            {locked ? (
              <FolderOpenLocked className="w-8 h-8" />
            ) : (
              <FolderOpen className="w-8 h-8" />
            )}
          </>
        ) : (
          <>
            <File className="w-8 h-8" />
          </>
        )}
      </Td>
      {type === 'Dir' ? (
        <>
          <Td className={'lg:hidden'} onClick={openFolder}>
            {storage.name}
          </Td>
          <Td
            style={{ background: isOver ? cssVars.colors.red : undefined }}
            className={'hidden lg:table-cell'}
          >
            {storage.name}
          </Td>
        </>
      ) : (
        <Td style={{ background: isOver ? cssVars.colors.red : undefined }}>
          <a
            href={getPrivateUrlFromUid(storage.uid)}
            target="_blank"
            className={'text-black no-underline'}
          >
            {storage.name}
          </a>
        </Td>
      )}
      <Td
        style={{ background: isOver ? cssVars.colors.red : undefined }}
        className={'hidden lg:table-cell'}
      >
        {moment.unix(storage.created_at).format('MMM Do, YYYY - hh:mm a')}
      </Td>
      <Td
        style={{ background: isOver ? cssVars.colors.red : undefined }}
        className={'hidden lg:table-cell'}
      >
        {storage.size ? `${(storage.size / 1024).toFixed(0)} KB` : '-'}
      </Td>
      <Td style={{ background: isOver ? cssVars.colors.red : undefined }}>
        <VerticalMenu className={'w-6 h-6 hover:text-red'} onClick={openMenu} />
      </Td>
    </Tr>
  );
};

const ParentRow = ({
  onDoubleClick,
  onMobilePress,
  connectDropTarget,
  isOver,
}: any) => {
  return (
    <Tr onDoubleClick={onDoubleClick} ref={ref => connectDropTarget(ref)}>
      <Td style={{ background: isOver ? cssVars.colors.red : undefined }}>
        <CheckCircle className={`w-8 h-8 mr-4 opacity-0`} />
        <FolderOpen className="w-8 h-8" />
      </Td>
      <Td className={'lg:hidden'} onClick={onMobilePress}>
        Go to parent...
      </Td>
      <Td
        className={'hidden lg:table-cell'}
        style={{ background: isOver ? cssVars.colors.red : undefined }}
      >
        Go to parent...
      </Td>
      <Td
        className={'hidden lg:table-cell'}
        style={{ background: isOver ? cssVars.colors.red : undefined }}
      >
        -
      </Td>
      <Td
        className={'hidden lg:table-cell'}
        style={{ background: isOver ? cssVars.colors.red : undefined }}
      >
        -
      </Td>
      <Td>-</Td>
    </Tr>
  );
};

const dragSource = {
  beginDrag: (props: any) => ({
    uid: props.storage.uid,
  }),
};

const dragCollect = (connect: DragSourceConnector) => ({
  connectDragSource: connect.dragSource(),
});

const dropTarget = {
  drop: ({ storage, onDrop }: any, monitor: any) => {
    onDrop(monitor.getItem().uid, storage ? storage.uid : '');
  },
};

const dropCollect = (
  connect: DropTargetConnector,
  monitor: DropTargetMonitor,
) => ({
  connectDropTarget: connect.dropTarget(),
  isOver: monitor.isOver(),
});

const ConnectedRow = DropTarget(
  [dragDropTypes.FILE, dragDropTypes.FOLDER],
  dropTarget,
  dropCollect,
)(DragSource(dragDropTypes.FOLDER, dragSource, dragCollect)(Row));

const ConnectedParentRow = DropTarget(
  [dragDropTypes.FILE, dragDropTypes.FOLDER],
  dropTarget,
  dropCollect,
)(ParentRow);

interface Props {
  storage: ChildrenResponse[];
  toggleSelected: (uid: string) => void;
  selected: string[];
  onMenuClick: (uid: string) => void;
  openFolder: (uid: string) => void;
  openParent?: () => void;
  onDrop: any;
  parentUid: string;
}

const ListView = ({
  storage,
  toggleSelected,
  selected,
  onMenuClick,
  openFolder,
  openParent,
  onDrop,
  parentUid,
}: Props) => {
  const [sortKey, setSortKey] = React.useState('name');
  const [sortAsc, setSortAsc] = React.useState(true);

  const iconClass = 'w-6 h-6 ml-2 text-red cursor-pointer hover:text-red-light';

  const SortIcon = sortAsc ? (
    <ChevronUp className={iconClass} />
  ) : (
    <ChevronDown className={iconClass} />
  );

  const setSort = (key: string) => {
    if (sortKey === key) {
      setSortAsc(!sortAsc);
    } else {
      setSortKey(key);
      setSortAsc(true);
    }
  };

  const sorted = [...storage].sort((a, b) => {
    const compareFunc = compare({ order: sortAsc ? 'asc' : 'desc' });
    switch (sortKey) {
      case 'date':
        return compareFunc(a.storage.created_at, b.storage.created_at);
      case 'size':
        return compareFunc(a.storage.size, b.storage.size);
      case 'name':
      default:
        return compareFunc(a.storage.name, b.storage.name);
    }
  });

  return (
    <Table>
      <thead>
        <tr>
          <Th />
          <Th onClick={() => setSort('name')}>
            Name {sortKey === 'name' ? SortIcon : null}
          </Th>
          <Th
            className={'hidden lg:table-cell'}
            onClick={() => setSort('date')}
          >
            Date Created {sortKey === 'date' ? SortIcon : null}
          </Th>
          <Th
            className={'hidden lg:table-cell'}
            onClick={() => setSort('size')}
          >
            Size {sortKey === 'size' ? SortIcon : null}
          </Th>
          <Th />
        </tr>
      </thead>
      <tbody>
        {openParent && (
          <ConnectedParentRow
            onDoubleClick={() => openParent()}
            onMobilePress={e => {
              e.preventDefault();
              openParent();
            }}
            onDrop={(dragUid: string) => onDrop(dragUid, parentUid)}
          />
        )}
        {sorted.map(x => (
          <ConnectedRow
            key={x.storage.uid}
            storage={x.storage}
            onClick={() => toggleSelected(x.storage.uid)}
            selected={contains(x.storage.uid, selected)}
            onDoubleClick={
              x.type === 'Dir' ? () => openFolder(x.storage.uid) : undefined
            }
            locked={x.locked}
            type={x.type}
            openFolder={e => {
              e.preventDefault();
              openFolder(x.storage.uid);
            }}
            openMenu={e => {
              e.stopPropagation();
              onMenuClick(x.storage.uid);
            }}
            onDrop={onDrop}
          />
        ))}
      </tbody>
    </Table>
  );
};

export default ListView;

import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import formApi from '../../../api/form.api';
import { toast } from 'react-toastify';

interface Props {
  formUid: string;
  isOpen: boolean;
  handleClose: () => void;
  onDelete: () => void;
}

interface State {
  loading: boolean;
}

class FormDelete extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };
  }
  private deleteForm = async () => {
    this.setState({
      loading: true,
    });
    const { data, errors } = await formApi.deactivate(this.props.formUid);
    if (data) {
      toast.success('Form deleted');
      this.props.onDelete();
    } else if (errors) {
      toast.error('Could not delete form');
    }
    this.setState({
      loading: false,
    });
  };

  public render() {
    return (
      <Modal
        isOpen={this.props.isOpen}
        handleClose={this.props.handleClose}
        contentLabel={'Delete this form?'}
      >
        <ModalHeader handleClose={this.props.handleClose}>
          Delete Form
        </ModalHeader>
        <ModalBody>
          <p>
            Are you sure you want to delete this form? You can restore it later
            from the trash.
          </p>
        </ModalBody>
        <ModalFooter right={true}>
          <Button
            type={'default'}
            onClick={this.props.handleClose}
            className="mr-4"
          >
            Cancel
          </Button>
          <Button
            type={'primary'}
            onClick={this.deleteForm}
            loading={this.state.loading}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default FormDelete;

import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionText, getOptionTitle } from '../../helpers/formOption';
import styled from 'styled-components';
import { cssVars } from '../../constants';
import WidgetContainer from './WidgetContainer';
import WidgetContent from './WidgetContent';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import submissionApi from '../../api/submission.api';
import { debounce, forEach } from 'lodash';

const Header = styled.header`
  padding: ${cssVars.padding[4]};
  background: ${cssVars.colors.default};
  color: white;
`;

const Body = styled.div`
  background: ${cssVars.colors['form-option-row']};
  padding: ${cssVars.padding[4]};
  overflow-x: scroll;

  & table {
    padding: ${cssVars.padding[4]};
    min-width: 100%;
  }

  & tr {
    display: flex !important;
  }

  & td {
    padding: ${cssVars.padding[2]};
    vertical-align: top;
    width: 250px;
  }

  & td .inputDiv,
  & td .form-group {
    height: 100%;
  }

  & .inputDiv {
    padding: ${cssVars.padding[2]};
    border: 1px solid ${cssVars.colors.border};
    background-color: #fff;
    max-height: 100px;
    overflow-y: scroll;
  }

  & input {
    padding: ${cssVars.padding[2]};
    border: 1px solid ${cssVars.colors.border};
    width: 100%;
  }
`;

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  live?: boolean;
  submData?: ParsedSubmData;
  editable?: boolean;
  submUid?: string;
}

const Table = ({ option, t, live, submData, editable, submUid }: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live }) || '';

  const text = getOptionText(option, { live });

  const listeners = React.useRef({});

  React.useEffect(() => {
    if (submData) {
      forEach(Object.keys(submData.parsed), id => {
        const input = document.querySelector(
          `#option-${option.id} input[name$="[${id}]"]`,
        );
        if (!input) {
          return;
        }
        input.setAttribute('value', submData.parsed[id]);
      });
    }

    forEach(document.querySelectorAll(`#option-${option.id} tr`), el => {
      el.style.height = '';
      el.style.width = '';
    });

    forEach(document.querySelectorAll(`#option-${option.id} td`), el => {
      el.style.height = '';
      el.style.width = '';
    });

    forEach(document.querySelectorAll(`#option-${option.id} input`), el => {
      const newEl = document.createElement('div');

      if (editable) {
        el.removeAttribute('disabled');
      } else {
        el.setAttribute('disabled', 'disabled');
      }
      const name = el.getAttribute('name');
      if (!name) {
        return;
      }

      newEl.setAttribute('name', name);
      newEl.setAttribute('class', `inputDiv ${el.getAttribute('class')}`);
      newEl.setAttribute('contenteditable', 'true');
      newEl.innerHTML = el.getAttribute('value') || '';

      el.replaceWith(newEl);

      const id = name.split('[')[2].replace(']', '');
      newEl.removeEventListener('input', listeners.current[id]);
      listeners.current[id] = debounce(async (e: any) => {
        await submissionApi.updateFormOption(submUid!, option.id, {
          key: id,
          value: e.target.innerHTML,
        });
      }, 500);
      newEl.addEventListener('input', listeners.current[id]);
    });
  }, []);

  return (
    <WidgetContainer>
      <WidgetContent>
        <Header>{title}</Header>
        <Body
          id={`option-${option.id}`}
          dangerouslySetInnerHTML={{ __html: text }}
        />
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    submUid: state.form.submissionUid,
    editable: state.form.editable,
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(Table));

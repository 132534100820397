import * as React from 'react';
import ResourceTable from '../../../components/ResourceTable';
import { Crew, Site, Company } from '../../../types';
import moment from 'moment';
import CrewsSidebox from '../../../containers/CrewSidebox';
import PageHeading from '../../../components/PageHeading';
import Button from '../../../components/Button';
import AddCrews from './AddCrews';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { useMountVisibility, useEntityRefresh } from '../../../helpers/hooks';
import { UsersState } from '../../../reducers/users';
import { StoreState } from '../../../store';
import { usersFetchRequest } from '../../../actions/users';
import Trash from '../../../svg/Trash';
import siteApi from '../../../api/site.api';
import {
  getCrewLabelPlural,
  getSiteLabelPlural,
  getSiteLabel,
  getSiteLabel,
  getSiteLabelPlural,
  getCrewLabel,
} from '../../../helpers';

interface Props extends WithNamespaces {
  crews: Crew[];
  site: Site;
  getCrews: () => void;
  loading: boolean;
  addSiteCrew: (crew: Crew) => any;
  users: UsersState;
  getUsers: (companyUid: string) => any;
  company: Company;
  fetched: boolean;
}

const Crews = ({
  crews,
  site,
  getCrews,
  loading,
  addSiteCrew,
  t,
  users,
  company,
  getUsers,
  fetched,
}: Props) => {
  const [crew, setCrew] = React.useState<Crew | null>(null);
  const detailState = useMountVisibility();
  const addState = useMountVisibility();

  useEntityRefresh(company.uid, users, getUsers);

  const openCrewDetail = (c: Crew) => {
    setCrew(c);
    detailState.open();
  };

  const afterCrewsAdded = (addedCrews: Crew[]) => {
    addState.close();
    addedCrews.forEach(c => {
      addSiteCrew(c);
    });
  };

  const removeFromSite = async () => {
    detailState.close();
    if (crew) {
      await siteApi.removeCrew(site.uid, crew.uid);
    }
    getCrews();
  };

  const tableColumns = [
    { Header: t('common:name'), accessor: 'name' },
    {
      Header: t('common:created'),
      // this will break now that the node server is handling the created_at's and setting them to the right time
      accessor: (p: Crew) => moment(p.created_at * 1000).format('YYYY-MM-DD'),
      id: 'created_at',
    },
  ];

  return (
    <div>
      <PageHeading
        title={`${getCrewLabelPlural()}`}
        renderRight={() => (
          <Button type={'primary'} onClick={addState.open}>
            {`Add ${getCrewLabelPlural()}`}
          </Button>
        )}
      />
      <ResourceTable
        data={crews}
        columns={tableColumns}
        defaultSortKey={'name'}
        onRowClick={openCrewDetail}
        loading={loading}
        fetched={fetched}
        emptyTitle={`No ${getCrewLabelPlural()} on this site`}
        emptyMessage={`In order for your users to see this site in their list, they need to be on a ${getCrewLabel()} that is attached to this site. To attach a crew to this site, click the "Add ${getCrewLabel()}" button in the top right.`}
      />
      {detailState.mounted &&
        crew && (
          <CrewsSidebox
            isOpen={detailState.visible}
            crew={crew}
            handleClose={detailState.close}
            tabs={['overview', `${getSiteLabelPlural()}`]}
            users={users.data}
            buttons={[
              {
                Icon: Trash,
                label: `Remove From ${getSiteLabel()}`,
                onClick: removeFromSite,
              },
            ]}
          />
        )}
      {addState.mounted && (
        <AddCrews
          site={site}
          handleClose={addState.close}
          isOpen={addState.visible}
          crewsOnSite={crews}
          afterSubmit={afterCrewsAdded}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  users: state.users,
  company: state.me.company,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
});

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Crews),
);

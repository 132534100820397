import { createAction } from 'typesafe-actions';
import { PermissionsError, Crew } from '../types';
import {
  CREWS_FETCH_REQUEST,
  CREWS_FETCH_SUCCESS,
  CREWS_FETCH_FAILURE,
  CREWS_ADD,
  CREWS_REMOVE,
  CREWS_INVALIDATE,
} from '../constants/actionTypes';

export const crewsFetchRequest = createAction(
  CREWS_FETCH_REQUEST,
  resolve => (companyUid: string) => resolve(companyUid),
);

export const crewsFetchSuccess = createAction(
  CREWS_FETCH_SUCCESS,
  resolve => (crews: Crew[]) => resolve(crews),
);

export const crewsFetchFailure = createAction(
  CREWS_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const crewsInvalidate = createAction(CREWS_INVALIDATE, resolve => () =>
  resolve(),
);

export const addCrew = createAction(CREWS_ADD, resolve => (crew: Crew) =>
  resolve(crew),
);

export const removeCrew = createAction(
  CREWS_REMOVE,
  resolve => (crewUid: string) => resolve(crewUid),
);

import * as React from 'react';
import Forms from './Forms';
import { Site, Form, Deployment, FC } from '../../../types';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props extends WithNamespaces {
  forms: Array<{ form: Form; deployment: Deployment; fc?: FC }>;
  site: Site;
}

const SiteForms = ({ forms, site, t }: Props) => {
  const formsByType = forms.reduce((curr, next) => {
    curr[next.form.type] = curr[next.form.type] || [];
    if (next) {
      curr[next.form.type].push(next);
    }
    return curr;
  }, {});

  return (
    <div>
      <div className="bg-white p-4">
        {Object.keys(formsByType).map(formType => (
          <div key={formType}>
            <h3 className={'font-normal'}>{`${formType}`} forms</h3>
            <hr className={'my-2 border-grey-light'} />
            <Forms forms={formsByType[formType]} deployee={site} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default withNamespaces()(SiteForms);

import * as React from 'react';
import {
  Company,
  Crew,
  Form,
  Site,
  User as IUser,
  CompanyGroup,
} from '../../../types';
import UserActivity from '../../../containers/dashboard/UserActivity';
import Checklist from '../../../containers/dashboard/Checklist';
import Actions from '../../../containers/dashboard/Actions';
import SubmissionStatistics from '../../../containers/SubmissionStatistics';

interface Props {
  companyGroup: CompanyGroup;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
  companies: Company[];
}

const Statistics = ({
  companyGroup,
  users,
  crews,
  forms,
  sites,
  companies,
}: Props) => {
  return (
    <div>
      <SubmissionStatistics
        companyGroup={companyGroup}
        users={users}
        crews={crews}
        sites={sites}
        forms={forms}
        companies={companies}
      />

      <div className="flex flex-col md:flex-row mb-4">
        <div className="flex-1 md:ml-4">
          <UserActivity companyGroup={companyGroup} />
        </div>
      </div>

      <div className="flex flex-col md:flex-row">
        <div className="flex-1 mb-4 md:mb-0">
          <Checklist companyGroup={companyGroup} users={users} />
        </div>
        <div className="flex-1 md:ml-4">
          <Actions
            companyGroup={companyGroup}
            users={users}
            sites={sites}
            forms={forms}
          />
        </div>
      </div>
    </div>
  );
};

export default Statistics;

import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import { toast } from 'react-toastify';
import formService from '../../../services/forms.service';
import { StoreState } from '../../../store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { FormOption } from '../../../types';
import { formFeatureIds } from '../../../constants';
import { useState, useEffect } from 'react';
import objectHelper from '../../../helpers/object.helper';
import { contains } from 'ramda';

interface Props {
  formUid: string;
  isOpen: boolean;
  handleClose: () => void;
  onConvert: (newFormUid: string) => void;
  formOptions: { [key: number]: FormOption };
}

const notSupportedWidgets = new Set([
  formFeatureIds.SKETCH, // 8
  formFeatureIds.SIGNATURE, // 7
  formFeatureIds.TABLE, // 9
  formFeatureIds.AUDIT, // 18
  formFeatureIds.AUDIT_ITEM, // 19
  formFeatureIds.ACTIONS, // 20
]);

const notSupportedWidgetTypes = {
  8: 'Sketch',
  7: 'Signature',
  9: 'Table',
  18: 'Audit',
  19: 'Audit Item',
  20: 'Actions',
};

function ConvertV2({
  isOpen,
  handleClose,
  formUid,
  onConvert,
  formOptions,
}: Props) {
  const [loading, setLoading] = useState<boolean>(false);
  const formOptionsArray = objectHelper.values<FormOption>(formOptions);
  const [unsupportedFId, setUnsupportedFId] = useState({});
  const [unsupportedCount, setUnsupportedCount] = useState(0);

  function getWidgetCounts() {
    const fIdObject = {};
    let count = 0;
    for (const { f_id: fId } of formOptionsArray) {
      if (notSupportedWidgets.has(fId)) {
        fIdObject[fId] = (fIdObject[fId] || 0) + 1;
        count = count + 1;
      }
    }
    setUnsupportedFId(fIdObject);
    setUnsupportedCount(count);
  }

  async function convertForm() {
    setLoading(true);
    const { data, errors } = await formService.convertV2(formUid);
    if (data) {
      toast.success('Form converted!');
      onConvert(data.newForm.uid);
    } else if (errors) {
      toast.error('Could not convert form');
    }
    setLoading(false);
  }

  useEffect(() => {
    getWidgetCounts();
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel={'Convert this form?'}
    >
      <ModalHeader handleClose={handleClose}>Convert form to V2</ModalHeader>
      <ModalBody>
        {Object.keys(unsupportedFId).length === 0 ? (
          <p className="text-green text-xl mb-2">
            This form conversion has no conflicts!
          </p>
        ) : (
          <div className="mb-2">
            <p className="text-red text-xl">
              Could not convert {unsupportedCount} widgets. continue with
              conversion?
            </p>
            <ul className="ml-4">
              {Object.keys(unsupportedFId).map(widget => (
                <li className="text-red text-lg ml-2">
                  ({unsupportedFId[widget]}){notSupportedWidgetTypes[widget]}
                </li>
              ))}
            </ul>
            <p className="text-red text-xl">continue with conversion?</p>
          </div>
        )}
        <p>All mandatory widgets will be reset.</p>
        <p>
          Sign offs are no longer a widget. You are now able to sign off as many
          times as necessary.
        </p>
      </ModalBody>
      <ModalFooter right={true}>
        <Button type={'default'} onClick={handleClose} className="mr-4">
          Cancel
        </Button>
        <Button type={'primary'} onClick={convertForm} loading={loading}>
          Convert
        </Button>
      </ModalFooter>
    </Modal>
  );
}

const mapState = (state: StoreState) => ({
  formOptions: state.formOptions.byId,
});

export default connect(mapState)(ConvertV2);

import { createAction } from 'typesafe-actions';
import { PermissionsError, User } from '../types';
import {
  USERS_FETCH_SUCCESS,
  USERS_FETCH_REQUEST,
  USERS_FETCH_FAILURE,
  USERS_ADD,
  USERS_REMOVE,
  USERS_INVALIDATE,
} from '../constants/actionTypes';

export const usersFetchRequest = createAction(
  USERS_FETCH_REQUEST,
  resolve => (companyUid: string) => resolve(companyUid),
);

export const usersFetchSuccess = createAction(
  USERS_FETCH_SUCCESS,
  resolve => (users: User[]) => resolve(users),
);

export const usersFetchFailure = createAction(
  USERS_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const addUser = createAction(USERS_ADD, resolve => (user: User) =>
  resolve(user),
);

export const removeUser = createAction(
  USERS_REMOVE,
  resolve => (userUid: string) => resolve(userUid),
);

export const usersInvalidate = createAction(USERS_INVALIDATE, resolve => () =>
  resolve(),
);

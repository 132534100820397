import * as React from 'react';
import classNames from 'classnames';

export interface SVGProps {
  color?: string;
  className?: string;
  onClick?: (...args: any[]) => any | void;
  style?: any;
  width?: string;
  height?: string;
}

const BaseSVG = (Component: any) => {
  return class extends React.PureComponent<SVGProps> {
    public render() {
      return (
        <Component
          {...this.props}
          className={classNames('fill-current', this.props.className)}
        />
      );
    }
  };
};

export default BaseSVG;

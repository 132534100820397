import * as React from 'react';
import SideboxSectionHeader from '../../../../components/sidebox/SideboxSectionHeader';
import SideboxSectionContent from '../../../../components/sidebox/SideboxSectionContent';
import FolderOpen from '../../../../svg/FolderOpen';
import Folder from '../../../../svg/Folder';
import SelectFolderModal from '../SelectFolderModal';
import Button from '../../../../components/Button';
import { Storage } from '../../../../types';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface State {
  selectFolderOpen: boolean;
}

interface Props extends WithNamespaces {
  folder: Storage | null;
  path: Storage[];
  onSelect: (folder: Storage) => void;
}

class SelectFolder extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      selectFolderOpen: false,
    };
  }

  private openSelectFolder = (e: any) => {
    e.preventDefault();
    this.setState({
      selectFolderOpen: true,
    });
  };

  private closeSelectFolder = () => {
    this.setState({
      selectFolderOpen: false,
    });
  };

  private onSelect = (folder: Storage) => {
    this.closeSelectFolder();
    this.props.onSelect(folder);
  };

  public render() {
    const { selectFolderOpen } = this.state;
    const { folder, t, path } = this.props;

    return (
      <div>
        <SideboxSectionHeader
          title={t('super:screens.site.selectFolderModalTitle')}
          leftIcon={<Folder />}
        />
        <SideboxSectionContent>
          <div className={'flex flex-row items-center'}>
            {folder ? (
              <React.Fragment>
                <div className={'w-10'}>
                  <FolderOpen />
                </div>
                <div className={'flex-1 ml-6 flex flex-col'}>
                  {folder.name}
                  <p className="text-sm text-grey-darker">
                    {path.map(x => x.name).join(' > ')}
                  </p>
                </div>
              </React.Fragment>
            ) : (
              <div className={'flex-1'}>
                {t('super:screens.site.noFolderSelected')}
              </div>
            )}
            <div>
              <Button type={'default'} onClick={e => this.openSelectFolder(e)}>
                {t('super:screens.site.selectFolderButton')}
              </Button>
            </div>
          </div>
        </SideboxSectionContent>
        <SelectFolderModal
          isOpen={selectFolderOpen}
          handleClose={this.closeSelectFolder}
          onSelect={this.onSelect}
        />
      </div>
    );
  }
}

export default withNamespaces()(SelectFolder);

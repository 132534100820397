import * as React from 'react';
import { Form, Site } from '../../../types';
import ResourceTable from '../../../components/ResourceTable';
import PageHeading from '../../../components/PageHeading';
import Button from '../../../components/Button';
import AddForms from './AddForms';
import FormSidebox from './FormSidebox';
import Loading from '../../../components/Loading';
import Sidebox from '../../../components/Sidebox';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import siteApi from '../../../api/site.api';
import { useMountVisibility } from '../../../helpers/hooks';
import { getSiteLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  forms: Form[];
  loading: boolean;
  site: Site;
  addSiteForm: (form: Form) => any;
  getForms: () => void;
  fetched: boolean;
}

const Forms = ({
  addSiteForm,
  forms,
  loading,
  site,
  getForms,
  t,
  fetched,
}: Props) => {
  const [selectedForm, setSelectedForm] = React.useState<Form | null>(null);
  const addFormState = useMountVisibility();
  const formDetailState = useMountVisibility();

  const openFormDetail = (form: Form) => {
    formDetailState.open();
    setSelectedForm(form);
  };

  const onFormAdded = (form: Form) => {
    addFormState.close();
    openFormDetail(form);
    addSiteForm(form);
  };

  const onRemove = async () => {
    formDetailState.close();
    if (selectedForm) {
      await siteApi.removeForm(site.uid, selectedForm.uid);
    }
    getForms();
  };

  const tableColumns = [
    { Header: t('common:name'), accessor: 'name' },
    { Header: t('common:type'), accessor: 'type' },
  ];

  return (
    <div className={'relative'}>
      <Loading loading={loading} />
      <PageHeading
        title={'Forms'}
        renderRight={() => (
          <Button type={'primary'} onClick={addFormState.open}>
            {t('super:screens.sites.addForm')}
          </Button>
        )}
      />
      <ResourceTable
        data={forms}
        columns={tableColumns}
        defaultSortKey={'name'}
        onRowClick={openFormDetail}
        loading={loading}
        fetched={fetched}
        emptyTitle={'No forms have been added to this site'}
        emptyMessage={
          'In order to fill out a form it must first be added to the site. Click "Add Form" in the top right to begin.'
        }
      />
      {addFormState.mounted && (
        <AddForms
          isOpen={addFormState.visible}
          handleClose={addFormState.close}
          site={site}
          onComplete={onFormAdded}
          siteForms={forms}
        />
      )}
      <Sidebox
        isOpen={formDetailState.visible}
        handleClose={formDetailState.close}
        contentLabel={`Add Forms to ${getSiteLabel()}`}
      >
        <FormSidebox
          handleClose={formDetailState.close}
          form={selectedForm!}
          site={site}
          onRemove={onRemove}
        />
      </Sidebox>
    </div>
  );
};

export default withNamespaces()(Forms);

import * as React from 'react';
import Sidebox, { SideboxContent } from '../../../components/Sidebox';
import SideboxHeader from '../../../components/sidebox/SideboxHeader';
import ChevronDown from '../../../svg/ChevronDown';
import { useSimpleFetch } from '../../../helpers/hooks';
import fcApi from '../../../api/fc.api';
import moment from 'moment';
import { Submission, SubmRev } from '../../../types';
import Loading from '../../../components/Loading';
import { last } from 'lodash';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  fcUid: string;
}

const HistorySidebox = ({ isOpen, handleClose, fcUid }: Props) => {
  const history = useSimpleFetch<
    Array<{ submission: Submission; revs?: SubmRev[] }>
  >({
    initial: [],
    change: [fcUid],
    accessor: 'submissions',
    fetch: () => fcApi.history(fcUid),
  });

  history.value.forEach(
    x =>
      x.revs &&
      x.revs.sort((a, b) => {
        return +a.rev > +b.rev ? -1 : 1;
      }),
  );

  const filteredHistory = history.value.filter(
    item => +item.submission.status !== 2 && item.revs && item.revs.length > 0,
  );

  return (
    <Sidebox
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Submisison History"
    >
      <SideboxHeader handleClose={handleClose}>History</SideboxHeader>
      <SideboxContent>
        <Loading loading={history.loading} />
        {filteredHistory.length === 0 && (
          <p className={'p-4 text-center'}>No submission history</p>
        )}
        {filteredHistory.map(item => (
          <div className="p-4 flex flex-col" key={item.submission.uid}>
            <div className="flex justify-between items-center border-0 border-b-1 border-solid border-grey">
              <p>
                Original Submission @{' '}
                {moment.unix(last(item.revs).created).format('MMM Do, YYYY')}
              </p>
              <ChevronDown className={'w-4 h-4'} />
            </div>
            <ul className="list-reset mt-2">
              {item.revs &&
                item.revs.map((v, i) => (
                  <li
                    key={v.id}
                    className={`p-4 ${
                      i % 2 === 0 ? 'bg-grey-light' : 'bg-grey-lighter'
                    }`}
                  >
                    <a
                      href={`/dashboard/worker/forms/${fcUid}/submission/${
                        item.submission.uid
                      }/revision/${v.id}`}
                    >
                      Revision made @{' '}
                      {moment.unix(v.created).format('MMM Do, YYYY hh:mm a')}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        ))}
      </SideboxContent>
    </Sidebox>
  );
};

export default HistorySidebox;

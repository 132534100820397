import * as React from 'react';
import { LatLng } from '../../../components/InlineLocationInput';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import * as R from 'ramda';
import SiteModifyForm from './SiteModifyForm';
import { withNamespaces, WithNamespaces } from 'react-i18next';

declare const google: any;

export interface SubmitData {
  name: string;
  location: string;
  latlng: LatLng | null;
}

interface Props extends WithNamespaces {
  onSubmit: (data: SubmitData) => void;
  loading: boolean;
  isOpen: boolean;
  handleClose: () => void;
  errors: {
    name?: string;
  };
  contentLabel: string;
  title: string;
  defaultName?: string;
  defaultLocation?: string;
}

interface State {
  name: string;
  location: string;
  latlng: LatLng | null;
}

class SiteModifyModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    let latlng = null;

    if (props.defaultLocation) {
      const [lat, lng] = props.defaultLocation.split(', ');
      if (lat && lng) {
        latlng = { lat: parseFloat(lat), lng: parseFloat(lng) };
      }
    }

    this.state = {
      name: props.defaultName || '',
      location: '',
      latlng,
    };
  }

  public componentDidMount() {
    if (this.state.latlng && typeof google !== 'undefined') {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode(
        { location: this.state.latlng },
        (res: any, status: any) => {
          if (status === 'OK' && res[0]) {
            this.setState({
              location: res[0].formatted_address,
            });
          }
        },
      );
    }
  }

  private onNameChange = (name: string) => {
    this.setState({
      name,
    });
  };

  private onLocationChange = (location: string) => {
    this.setState({
      location,
      latlng: null,
    });
  };

  private onLatLngChange = (latlng: LatLng) => {
    this.setState({
      latlng,
    });
  };

  private onSubmit = (
    e: React.FormEvent<HTMLFormElement> | React.FormEvent<HTMLButtonElement>,
  ) => {
    e.preventDefault();

    this.props.onSubmit({
      name: this.state.name,
      latlng: this.state.latlng,
      location: this.state.location,
    });
  };

  public render() {
    const {
      isOpen,
      handleClose,
      loading,
      errors,
      contentLabel,
      title,
      t,
    } = this.props;
    const { name, location } = this.state;

    return (
      <Modal
        isOpen={isOpen}
        contentLabel={contentLabel}
        handleClose={handleClose}
      >
        <ModalHeader handleClose={handleClose}>{title}</ModalHeader>
        <ModalBody>
          <form onSubmit={this.onSubmit}>
            <SiteModifyForm
              name={name}
              location={location}
              errors={errors}
              onNameChange={this.onNameChange}
              onLocationChange={this.onLocationChange}
              onLatLngChange={this.onLatLngChange}
            />
          </form>
        </ModalBody>
        <ModalFooter right={true}>
          <Button
            type={'primary'}
            disabled={R.trim(name) === ''}
            loading={loading}
            onClick={this.onSubmit}
          >
            {t('common:submit')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
}

export default withNamespaces()(SiteModifyModal);

import api, { api2 } from './api';
import { Company, Form, SubmData, SubmissionFilters } from '../types';

interface FormCreateData {
  name: string;
  color: string;
  type: string;
}

const formApi = {
  show: (formUid: string) => api2<{ form: Form }>('GET', `forms/${formUid}`),
  options: (formUid: string) => api('GET', `forms/${formUid}/options`),
  submissions: async (formUid: string, filters: any) =>
    api('GET', `forms/${formUid}/submissions`, filters),
  noSubmissions: async (formUid: string, filters: any) =>
    api2<any>('GET', `forms/${formUid}/no-submissions`, filters),
  responses: (formUid: string, filters: any) =>
    api('GET', `forms/${formUid}/responses`, filters),
  responsesForOption: (optionId: number, filters: any) =>
    api2<{ responses: SubmData[] }>(
      'GET',
      `form-options/${optionId}/responses`,
      filters,
    ),
  companies: (formUid: string) =>
    api2<{ companies: Company[] }>('GET', `forms/${formUid}/companies`),
  attachCompany: (formUid: string, companyUid: string) =>
    api2('PUT', `forms/${formUid}/companies/${companyUid}`),
  removeCompany: (formUid: string, companyUid: string) =>
    api2('DELETE', `forms/${formUid}/companies/${companyUid}`),
  create: (companyUid: string, data: FormCreateData) =>
    api2<{ form: Form }>('POST', `companies/${companyUid}/forms`, data),
  update: (
    formUid: string,
    data: { name?: string; color?: string; type?: string },
  ) => api2<{ form: Form }>('PATCH', `forms/${formUid}`, data),
  publish: (formUid: string) => api2('POST', `forms/${formUid}/publish`),
  deactivate: (formUid: string) => api2('POST', `forms/${formUid}/deactivate`),
  activate: (formUid: string) => api2('POST', `forms/${formUid}/activate`),
  exportSubmissions: (formUid: string, companyUid: string, filters: any) =>
    api2(
      'GET',
      `company-exports/${companyUid}/form-submissions/${formUid}`,
      filters,
    ),
  export: (formUid: string) => api2('GET', `forms/${formUid}/export`),
  import: (formUid: string, data: any) =>
    api2('POST', `forms/${formUid}/import`, data),
  submissionStats: (
    formUid: string,
    companyUid: string,
    data?: SubmissionFilters,
  ) =>
    api2<{ stats: any }>(
      'GET',
      `company-stats/${companyUid}/forms/${formUid}/submissions`,
      data,
    ),
};

export default formApi;

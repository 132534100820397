import { createSelector } from 'reselect';
import store, { StoreState } from '../store';
import { formFeatureIds } from '../constants';
import * as R from 'ramda';
import { FormOption } from '../types';
import { orderBy } from 'natural-orderby';

interface Props {
  optionId: number;
}

const getOptions = (state: StoreState) => state.formOptions.byId;

const getOption = (state: StoreState, props: Props) =>
  state.formOptions.byId[props.optionId];

const getAllOptionIds = (state: StoreState) => state.formOptions.allIds;

const getOptionId = (state: StoreState, props: Props) => props.optionId;

export const makeGetPublishedSections = () =>
  createSelector([getOptions], options =>
    R.values(options)
      .filter(o => o.f_id === formFeatureIds.SECTION)
      .filter(o => o.published !== 0),
  );

export const makeGetPublishedChildrenIds = () =>
  createSelector(
    [getAllOptionIds, getOptionId],
    (options, optionId): number[] => {
      const state = store.getState();
      return options
        .filter(o => state.formOptions.byId[o].parent_id === optionId)
        .filter(o => state.formOptions.byId[o].published !== 0)
        .map(o => o);
    },
  );

export const makeGetPublishedChildren = () =>
  createSelector(
    [getAllOptionIds, getOptionId],
    (options, optionId): FormOption[] => {
      const state = store.getState();
      return options
        .filter(o => state.formOptions.byId[o].parent_id === optionId)
        .map(o => state.formOptions.byId[o])
        .filter(o => o.published !== 0)
        .sort((a: FormOption, b: FormOption) => {
          if (a.sort === b.sort) {
            return 0;
          }

          return a.sort > b.sort ? 1 : -1;
        });
    },
  );

export const makeGetSections = () =>
  createSelector([getOptions], options =>
    orderBy(
      R.values(options).filter(o => o.f_id === formFeatureIds.SECTION),
      [v => v.sort],
      ['asc'],
    ),
  );

export const makeGetChildrenIds = () =>
  createSelector(
    [getAllOptionIds, getOptionId],
    (options, optionId): number[] => {
      const state = store.getState();
      return options
        .filter(o => state.formOptions.byId[o].parent_id === optionId)
        .map(o => o);
    },
  );

export const makeGetChildren = () =>
  createSelector(
    [getAllOptionIds, getOptionId],
    (options, optionId): FormOption[] => {
      const state = store.getState();
      return orderBy(
        options
          .filter(o => state.formOptions.byId[o].parent_id === optionId)
          .map(o => state.formOptions.byId[o]),
        [x => x.sort],
        ['asc'],
      );
    },
  );

export const makeGetOption = () =>
  createSelector([getOption], option => option);

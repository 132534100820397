import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import Calendar from '../../svg/Calendar';
import InlineSelect from '../../components/InlineSelect';
import OptionTitle from '../../containers/form/OptionTitle';
import PageToolbar from '../../components/PageToolbar';
import styled from 'styled-components';
import { cssVars } from '../../constants';
import Location from '../../svg/Location';
import User from '../../svg/User';
import { match } from 'react-router';
import {
  Action as IAction,
  Site,
  User as IUser,
  Company,
  Storage,
  Form,
  Incident,
  FC,
  Submission,
} from '../../types';
import actionApi from '../../api/action.api';
import Loading from '../../components/Loading';
import {
  useTextInput,
  useEntityRefresh,
  useOptionalSelectInput,
  useRequiredDateInput,
  useToggle,
  useSimpleFetch,
} from '../../helpers/hooks';
import moment from 'moment';
import { SitesState } from '../../reducers/sites';
import { UsersState } from '../../reducers/users';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import { sitesFetchRequest } from '../../actions/sites';
import { usersFetchRequest } from '../../actions/users';
import ErrorMessage from '../../components/ErrorMessage';
import Save from '../../svg/Save';
import Checkmark from '../../svg/Checkmark';
import Pencil from '../../svg/Pencil';
import { History } from 'history';
import DropUpload from '../../components/DropUpload';
import InlineDatePicker from '../../components/InlineDatePicker';
import InlineToggle from '../../components/InlineToggle';
import FileComp from './files/File';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import { debounce } from 'lodash';
import { DragDropContext } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';
import { Link } from 'react-router-dom';
import { getFullName, getSiteLabel } from '../../helpers/index';

const Textarea = styled.textarea`
  width: 100%;
  padding: ${cssVars.padding[4]};
  border-color: ${cssVars.colors.border};
`;

interface Props {
  match: match<{ actionUid: string }>;
  sites: SitesState;
  users: UsersState;
  getSites: (companyUid: string) => any;
  getUsers: (companyUid: string) => any;
  company: Company;
  history: History;
}

const Action = ({
  match: pMatch,
  sites,
  users,
  company,
  getSites,
  getUsers,
  history,
}: Props) => {
  useEntityRefresh(company.uid, users, getUsers);
  useEntityRefresh(company.uid, sites, getSites);

  const { actionUid } = pMatch.params;

  // current action state
  const [action, setAction] = React.useState<null | IAction>(null);
  const [creator, setCreator] = React.useState('');
  const [form, setForm] = React.useState<null | Form>(null);
  const [fcUid, setFcUid] = React.useState('');
  const [submissionUid, setSubmissionUid] = React.useState('');
  const [incident, setIncident] = React.useState<null | Incident>(null);
  const [site, setSite] = React.useState<null | Site>(null);
  const [assignedUser, setAssignedUser] = React.useState<null | IUser>(null);
  const [loading, setLoading] = React.useState(false);

  // editing state
  const [editing, setEditing] = React.useState(false);
  const description = useTextInput('');
  const corrective = useTextInput('');
  const notes = useTextInput('');
  const selectedSite = useOptionalSelectInput();
  const selectedAssignedUser = useOptionalSelectInput();
  const dateDue = useRequiredDateInput(moment());
  const [errors, setErrors] = React.useState<any>({});
  const urgent = useToggle();
  const uploads = useSimpleFetch<Storage[]>({
    initial: [],
    change: [actionUid],
    accessor: 'uploads',
    fetch: () => actionApi.uploads(actionUid),
  });
  const [fileToDelete, setFileToDelete] = React.useState('');

  const setEditingData = (data: any) => {
    setAction(data.action);
    if (data.site) {
      setSite(data.site);
      selectedSite.onChange({ label: data.site.name, value: data.site.uid });
    }
    if (data.assignedUser) {
      setAssignedUser(data.assignedUser);
      selectedAssignedUser.onChange({
        label: data.assignedUser.username,
        value: data.assignedUser.uid,
      });
    }
    if (data.creator) {
      setCreator(getFullName(data.creator));
    }
    if (data.form) {
      setForm(data.form);
    }
    if (data.fc) {
      setFcUid(data.fc.uid);
    }
    if (data.submission) {
      setSubmissionUid(data.submission.uid);
    }
    if (data.incident) {
      setIncident(data.incident);
    }
    description.setValue(data.action.description);
    corrective.setValue(data.action.corrective || '');
    notes.setValue(data.action.notes || '');
    dateDue.onChange(moment.unix(data.action.date_due));
    urgent.setValue(data.action.urgent);
  };

  const fetchAction = async () => {
    setLoading(true);
    const { data } = await actionApi.show(pMatch.params.actionUid);
    if (data) {
      setEditingData(data);
    }
    setLoading(false);
  };

  const updateAction = async () => {
    setLoading(true);

    const dueDate = dateDue.unix();

    const { data, errors: err } = await actionApi.update(
      pMatch.params.actionUid,
      {
        description: description.value,
        correctiveAction: corrective.value,
        notes: notes.value,
        siteUid: selectedSite.selected || '',
        userUid: selectedAssignedUser.selected || '',
        urgent: urgent.checked,
        ...(dueDate ? { dueDate } : {}),
      },
    );

    if (err) {
      setErrors(err);
    } else {
      setErrors({});
    }

    if (data) {
      setEditingData(data);
      setEditing(false);
    }

    setLoading(false);
  };

  const onComplete = async () => {
    if (!action) {
      return;
    }
    setLoading(true);
    const res = await actionApi.complete(action.uid);
    if (res.data) {
      history.push('/dashboard/worker/actions');
    }
    setLoading(false);
  };

  const onUncomplete = async () => {
    if (!action) {
      return;
    }
    setLoading(true);
    const res = await actionApi.uncomplete(action.uid);
    if (res.data) {
      await fetchAction();
    }
    setLoading(false);
  };

  React.useEffect(
    () => {
      fetchAction();
    },
    [pMatch.params.actionUid],
  );

  const updateNotes = React.useCallback(
    debounce(async (val: string) => {
      await actionApi.update(pMatch.params.actionUid, {
        notes: val,
      });
    }, 600),
    [],
  );

  const onNotesChange = (e: any) => {
    notes.setValue(e.currentTarget.value);
    updateNotes(e.currentTarget.value);
  };

  const onDrop = async (files: File[]) => {
    if (files.length > 15) {
      return;
    }
    const data = new FormData();
    files.forEach(file => data.append('uploads', file));
    await actionApi.upload(actionUid, data);
    uploads.performFetch();
  };

  const onDeleteClick = (uid: string) => {
    setFileToDelete(uid);
  };

  const onDeleteFile = async () => {
    setLoading(true);
    await actionApi.removeUpload(actionUid, fileToDelete);
    uploads.setValue(uploads.value.filter(x => x.uid !== fileToDelete));
    setFileToDelete('');
    setLoading(false);
  };

  if (!action) {
    return <Loading loading={loading} />;
  }

  const overdue = moment.unix(action.date_due).isBefore(moment());
  const complete = +action.status === 1;

  const toolbarActions = [
    { label: 'Edit', onClick: () => setEditing(true), icon: <Pencil /> },
    complete
      ? {
          label: 'Mark Incomplete',
          type: 'default' as any,
          onClick: onUncomplete,
        }
      : {
          label: 'Complete',
          type: 'success' as any,
          icon: <Checkmark />,
          onClick: onComplete,
        },
  ];

  const editingActions = [
    {
      label: 'Save',
      type: 'success' as any,
      onClick: updateAction,
      icon: <Save />,
    },
  ];

  const dueBarClassNames = (() => {
    if (overdue && !complete) {
      return 'bg-red';
    }
    if (complete) {
      return 'bg-green';
    }
    if (action.urgent) {
      return 'bg-yellow-dark';
    }
    return 'bg-grey-darker';
  })();

  const dueBarText = (() => {
    if (overdue && !complete) {
      return 'This action spanned over its deadline: ';
    }
    if (complete) {
      return 'This action was completed on: ';
    }
    return 'This action to be completed by: ';
  })();

  const siteOptions = sites.data.map(s => ({
    label: s.name,
    value: s.uid,
  }));
  const userOptions = users.data.map(s => ({
    label: s.username,
    value: s.uid,
  }));

  return (
    <Page>
      <Loading loading={loading} />
      <PageToolbar
        title={description.value}
        actions={editing ? editingActions : toolbarActions}
      />

      <PageContent>
        <div className="bg-white">
          <div
            className={`${dueBarClassNames} text-white p-2 flex flex-row items-center justify-between`}
          >
            <div className="flex">
              <Calendar className={'w-4 h-4 mr-2'} />
              <p>
                {dueBarText}
                <strong>
                  {moment
                    .unix(complete ? action.completed || 0 : action.date_due)
                    .format('MMM Do, YYYY')}
                  {complete &&
                    ` at ${moment
                      .unix(complete ? action.completed || 0 : action.date_due)
                      .format('hh:mm a')}`}
                </strong>
              </p>
            </div>
          </div>

          <div className="p-4">
            <InlineSelect
              options={siteOptions}
              label={`${getSiteLabel()} Name`}
              icon={<Location />}
              disabled={!editing}
              isClearable={true}
              errorMessage={errors.siteUid}
              {...selectedSite}
            />
            <InlineSelect
              options={userOptions}
              label={'Assigned To'}
              icon={<User />}
              disabled={!editing}
              errorMessage={errors.userUid}
              {...selectedAssignedUser}
            />
          </div>

          {editing && (
            <div className="p-4 w-full flex flex-col lg:flex-row lg:items-center">
              <div className="flex-1">
                <InlineDatePicker label="Date Due" {...dateDue} />
              </div>
              <div className="flex-1 mt-4 lg:mt-0 lg:ml-4">
                <InlineToggle label="Is this action urgent?" {...urgent} />
              </div>
            </div>
          )}

          <OptionTitle title={'Related Information'} />
          <div className="p-8">
            <p>
              Created on {moment.unix(action.created_at).format('MMM Do, YYYY')}{' '}
              at {moment.unix(action.created_at).format('hh:mm a')}
            </p>
            {creator !== '' ? <p>Created by: {creator}</p> : ''}
            {form ? (
              <p>
                Created on form:{' '}
                <Link
                  className="text-blue no-underline inline-flex items-center"
                  to={`/dashboard/worker/forms/${fcUid}/submission/${submissionUid}`}
                >
                  {form.name}
                </Link>
              </p>
            ) : (
              ''
            )}
            {incident ? (
              <p>
                Created on:{' '}
                <Link
                  className="text-blue no-underline inline-flex items-center"
                  to={`/dashboard/super/dashboard/incidents/${incident.uid}`}
                >
                  Incident #{incident.case_id}
                </Link>
              </p>
            ) : (
              ''
            )}
          </div>

          <OptionTitle title={'Task Description'} />
          <div className="p-8">
            {editing ? (
              <>
                <Textarea {...description} />
                {errors.description && (
                  <ErrorMessage>{errors.description}</ErrorMessage>
                )}
              </>
            ) : (
              <p>{description.value}</p>
            )}
          </div>

          <OptionTitle title={'Corrective Action'} />
          <div className="p-8">
            {editing ? (
              <>
                <Textarea {...corrective} />
                {errors.corrective && (
                  <ErrorMessage>{errors.corrective}</ErrorMessage>
                )}
              </>
            ) : (
              <p>{corrective.value}</p>
            )}
          </div>

          <OptionTitle title={'Completion Notes'} />
          <div className="p-8">
            <Textarea
              className="w-full p-4 border-border"
              value={notes.value}
              onChange={onNotesChange}
              rows={8}
            />
            {errors.notes && <ErrorMessage>{errors.notes}</ErrorMessage>}
          </div>

          <OptionTitle title={'Attachments'} />
          <div className="p-4">
            <div className="flex flex-row flex-wrap mb-4">
              <DropUpload onDrop={onDrop} />
              {uploads.value.map(i => (
                <div className="m-2">
                  <FileComp
                    storage={i}
                    key={i.uid}
                    onMenuClick={onDeleteClick}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <ConfirmationDialogue
          isOpen={!!fileToDelete}
          title="Delete File"
          body="Are you sure you wish to delete this file?"
          onConfirm={onDeleteFile}
          handleClose={() => setFileToDelete('')}
          contentLabel="Delete action file"
          loading={loading}
        />
      </PageContent>
    </Page>
  );
};

const mapStateToProps = (state: StoreState) => ({
  sites: state.sites,
  users: state.users,
  company: state.me.company,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getSites: (companyUid: string) => dispatch(sitesFetchRequest(companyUid)),
  getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
});

export default DragDropContext(HTML5Backend)(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(Action),
);

import * as React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  flex: 1;
  position: relative;
  padding: 12px;
  overflow: visible;
`;

export default class PageContent extends React.Component {
  public render() {
    return <Container children={this.props.children} />;
  }
}

import * as React from 'react';
import styled from 'styled-components';
import { biggerThanMD, mandatoryErrorStyles } from '../../helpers/style';
import { useCallback, useState, useEffect } from 'react';
import WidgetContainer from '../form/WidgetContainer';
import WidgetContent from '../form/WidgetContent';
import {
  Widget,
  ResponseFile,
  ResponseComment,
  QuestionResponse,
} from '../../types';
import formService from '../../services/forms.service';
import { debounce } from 'lodash';
import TextArea from '../../components/TextArea';
import WidgetMetaButtons from './WidgetMetaButtons';
import RequiredWidget from './RequiredWidget';
import objectHelper from '../../helpers/object.helper';
import { toast } from 'react-toastify';
import { MAX_INPUT_FIELD_SIZE } from '../../constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

interface Props {
  widget: Widget;
  response: QuestionResponse;
  comments: ResponseComment[];
  files: ResponseFile[];
  editable?: boolean;
  instanceRevUid: string;
  error?: boolean;
  setFormError: (widgetUid: string, formErrors: string[]) => void;
  clearMandatoryError: (widgetUid: string) => void;
}

const Question = ({
  widget,
  editable,
  instanceRevUid,
  response,
  error,
  files,
  comments,
  setFormError,
  clearMandatoryError,
}: Props) => {
  const [value, setValue] = useState(response ? response.question_answer : '');

  const updateServer = useCallback(
    debounce(async val => {
      const { errors } = await formService.setWidgetResponse(
        instanceRevUid,
        widget.uid,
        {
          question_answer: val,
        },
      );
      if (errors) {
        for (const errArray of objectHelper.values(errors)) {
          setFormError(widget.uid, errArray);
          if (Array.isArray(errArray)) {
            errArray.map(errorText => toast.error(errorText));
          }
        }
      }
    }, 800),
    [instanceRevUid, widget.uid],
  );

  const setAndSyncValue = async (answerInput: string) => {
    if (answerInput !== '') {
      clearMandatoryError(widget.uid);
    }
    setValue(answerInput);
    updateServer(answerInput);
  };

  useEffect(
    () => {
      if (response === null) {
        return;
      }
      if (value === '') {
        setFormError(widget.uid, ['requires response']);
      }
      setValue(response.question_answer);
    },
    [response],
  );

  return (
    <WidgetContainer>
      <WidgetContent
        style={error ? mandatoryErrorStyles : {}}
        className="bg-white mt-4 rounded"
      >
        <div className={'mb-4'}>
          <Wrapper>
            <label>
              {widget.title}
              {widget.mandatory && <RequiredWidget />}
            </label>
          </Wrapper>
          <TextArea
            className="mt-2 rounded"
            onChange={userInput =>
              setAndSyncValue(userInput.currentTarget.value)
            }
            value={value}
            placeholder={'Enter your answer here...'}
            maxLength={MAX_INPUT_FIELD_SIZE}
            disabled={!editable}
          />
        </div>
        <WidgetMetaButtons
          editable={editable}
          files={files}
          widget={widget}
          instanceRevUid={instanceRevUid}
          comments={comments}
        />
      </WidgetContent>
    </WidgetContainer>
  );
};

export default Question;

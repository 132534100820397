import { Crew, Site, Submissions, User } from '../../types';
import * as React from 'react';
import { Item } from './Sidebox';
import moment from 'moment';
import Avatar from '../../components/Avatar';

interface Props {
  submissions: Submissions;
}

function SubmissionList({ submissions }: Props) {
  return (
    <div>
      {submissions.map(
        ({
          submitter,
          user,
          instance_revision,
          submission,
          instance,
          fc,
          site,
          deployee,
        }: any) => {
          const username = submitter ? submitter.username : user.username;
          const bgColor = submitter
            ? submitter.profile_color[0]
            : user.profile_color[0];
          const fgColor = submitter
            ? submitter.profile_color[1]
            : user.profile_color[1];
          const date = instance_revision
            ? instance_revision.submitted_at
            : submission.updated_at;
          const href = instance
            ? `/dashboard/worker/formsV2/${instance.uid}/revisions/${
                instance_revision.uid
              }`
            : `/dashboard/worker/forms/${fc.uid}/submission/${submission.uid}`;
          return (
            <Item key={username} href={href} target={'_blank'}>
              <h3 className={'font-normal text-lg'}>
                <strong>
                  {(deployee as Site | Crew).name ||
                    (deployee as User).username}
                </strong>{' '}
                for <strong>{site.name}</strong>
              </h3>
              <p className="text-sm text-grey-darker my-2">
                {moment.unix(date).format('hh:mm a, MMM Do, YYYY')}
              </p>
              <p>SUBMITTED BY:</p>
              <div className={'flex flex-row items-center mt-2'}>
                <Avatar
                  fallbackName={username}
                  margin={true}
                  bgColor={bgColor}
                  fgColor={fgColor}
                  user={submitter}
                />
                {username}
              </div>
            </Item>
          );
        },
      )}
    </div>
  );
}

export default SubmissionList;

import * as React from 'react';
import CloseOutline from '../../svg/CloseOutline';
import styled from 'styled-components';
import { biggerThanMD } from '../../helpers/style';
import VerticalMenu from '../../svg/VerticalMenu';

const WidgetContent = styled.div`
  min-height: 292px;

  ${biggerThanMD(`
  height: 292px;
`)};
`;

export interface RenderMenuProps {
  closeMenu: () => void;
}

interface Props {
  title: string;
  renderMenu?: (state: RenderMenuProps) => JSX.Element;
}

interface State {
  menuOpen: boolean;
}

export default class BaseWidget extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      menuOpen: false,
    };
  }

  private openMenu = () => {
    this.setState({
      menuOpen: true,
    });
  };

  private closeMenu = () => {
    this.setState({
      menuOpen: false,
    });
  };

  public render() {
    const { title, children, renderMenu } = this.props;
    const { menuOpen } = this.state;
    return (
      <div className={'bg-white border-1 border-solid border-border'}>
        <div
          className={
            'p-4 border-0 border-b-1 border-solid border-border flex items-center justify-between'
          }
        >
          <h3 className={'font-normal'}>{title}</h3>
          {renderMenu &&
            !menuOpen && (
              <VerticalMenu
                className={'w-6 h-6 cursor-pointer hover:text-primary'}
                onClick={this.openMenu}
              />
            )}
          {menuOpen && (
            <CloseOutline
              className={'w-6 h-6 cursor-pointer hover:text-primary'}
              onClick={this.closeMenu}
            />
          )}
        </div>
        <WidgetContent>
          {!menuOpen && children}
          {menuOpen && renderMenu && renderMenu({ closeMenu: this.closeMenu })}
        </WidgetContent>
      </div>
    );
  }
}

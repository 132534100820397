import { call, takeLatest, put } from 'redux-saga/effects';
import companyApi from '../api/company.api';
import { USERS_FETCH_REQUEST } from '../constants/actionTypes';
import { usersFetchSuccess, usersFetchFailure } from '../actions/users';

export function* getUsers(params: any) {
  const { data, errors } = yield call(companyApi.users, params.payload);
  if (data) {
    yield put(usersFetchSuccess(data.users));
  } else if (errors) {
    yield put(usersFetchFailure(errors));
  }
}

export function* watchGetUsers() {
  yield takeLatest(USERS_FETCH_REQUEST, getUsers);
}

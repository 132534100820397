import * as React from 'react';
import {
  useContentEditable,
  handleContentLengthErrors,
} from '../../helpers/hooks';
import { FormFabricatorProps, useWidgetChange } from './common';
import BuilderButtons from './BuilderButtons';
import { useState, useEffect } from 'react';
import LengthErrorLabel from './LengthErrorLabel';
import { MAX_NAME_LENGTH } from '../../constants/index';
import { onContentEditablePaste } from '../../helpers';

type Props = FormFabricatorProps;

function Title({
  widget,
  setWidgets,
  formRevisionUid,
  openDelete,
  editErrors,
  setEditErrors,
}: Props) {
  const [isTooLong, setIsTooLong] = useState<boolean>(false);
  const [isTooShort, setIsTooShort] = useState<boolean>(false);
  const { handleWidgetChange } = useWidgetChange({
    formRevisionUid,
    setWidgets,
    widget,
  });

  const { handleDone, handleBlurEvent } = useContentEditable(
    'title',
    handleWidgetChange,
  );

  useEffect(
    () => {
      if (isTooShort || isTooLong) {
        setEditErrors([...editErrors, widget.uid]);
        return;
      }
      setEditErrors(editErrors.filter(uid => uid !== widget.uid));
    },
    [isTooLong, isTooShort],
  );

  return (
    <div>
      <LengthErrorLabel
        isTooLong={isTooLong}
        maxCharLength={MAX_NAME_LENGTH}
        isTooShort={isTooShort}
      />
      <div
        className="flex flex-row justify-between m-4 p-4 bg-grey-dark rounded"
        style={isTooShort || isTooLong ? { border: 'solid 2px red' } : {}}
      >
        <h1
          className={'w-full'}
          contentEditable={true}
          onInput={(event: React.KeyboardEvent<any>) =>
            handleContentLengthErrors(
              event,
              MAX_NAME_LENGTH,
              isTooLong,
              isTooShort,
              setIsTooLong,
              setIsTooShort,
              handleDone,
            )
          }
          onPaste={(e: any) => onContentEditablePaste(e)}
          onBlur={handleBlurEvent}
        >
          {widget.title}
        </h1>
        <BuilderButtons openDelete={openDelete} id={widget.uid} />
      </div>
    </div>
  );
}

export default Title;

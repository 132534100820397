import io from 'socket.io-client';
import { localStorageKeys } from '../constants';

export const socket = io(`${process.env.SOCKET_URL}`);

type Handler = (data: any) => void;

interface RoomsStore {
  [room: string]: {
    [job: string]: Handler[];
  };
}

const rooms: RoomsStore = {};

// should only join room _once_
// should allow multiple event listeners
// should allow unsubscribe of events
export const listen = (room: string, event: string, handler: Handler) => {
  // only join room if not in already
  if (!rooms[room]) {
    socket.emit(
      'joinRoom',
      room,
      localStorage.getItem(localStorageKeys.USER_TOKEN),
    );
    rooms[room] = {};

    socket.on(event, (data: any) => {
      rooms[room][event].forEach(h => {
        h(data);
      });
    });
  }

  rooms[room][event] = rooms[room][event]
    ? [...rooms[room][event], handler]
    : [handler];

  return () => {
    rooms[room][event] = rooms[room][event].filter(h => h !== handler);
  };
};

export const listenPrivate = (room: string, event: string, handler: Handler) =>
  listen(`private-${room}`, event, handler);

export const listenPublic = (room: string, event: string, handler: Handler) =>
  listen(`public-${room}`, event, handler);

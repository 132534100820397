import { useState, useEffect, useRef, useMemo } from 'react';
import { Moment } from 'moment';
import { SelectOption, SelectOptionObject } from '../components/Select';
import { EntityState, needsToBeFetched } from './globalState';
import { isEqual } from 'lodash';
import store, { StoreState } from '../store';
import { usersFetchRequest } from '../actions/users';
import {
  FormOption,
  ParsedSubmData,
  IncidentProp,
  IncidentPropOption,
} from '../types';
import { crewsFetchRequest } from '../actions/crews';
import { addSubmData } from '../actions/submData';
import submissionApi from '../api/submission.api';
import { ENTER_KEY_CODE } from '../constants/index';
import { toast } from 'react-toastify';
import companyApi from '../api/company.api';

interface MountVisibiltyOptions {
  timeout?: number;
  afterMount?: () => void;
  afterVisible?: () => void;
  beforeUnmount?: () => void;
  beforeInvisible?: () => void;
  afterUnmount?: () => void;
}

export function useFormFabricatorWidgetErrors({
  potentialErrors,
  setEditError,
  editErrors,
  widgetUid,
}: {
  potentialErrors: boolean[];
  setEditError: (widgetUids: string[]) => void;
  editErrors: string[];
  widgetUid: string;
}) {
  useEffect(() => {
    if (potentialErrors.some(Boolean)) {
      setEditError([...editErrors, widgetUid]);
      return;
    }
    setEditError(editErrors.filter(uid => uid !== widgetUid));
  }, potentialErrors);
}

export function useLogarithmicPoll() {
  return useMemo(() => {
    function* logGenerator() {
      for (let i = 1; ; ++i) {
        yield (Math.log(i) / Math.log(1.2)) * 1000;
      }
    }
    const generator = logGenerator();
    function getTimeout() {
      return generator.next().value;
    }
    return getTimeout;
  }, []);
}

export const useMountVisibility = (options?: MountVisibiltyOptions) => {
  const DEFAULT_OPTIONS = {
    timeout: 50,
    // tslint:disable-next-line:no-empty
    afterUnmount: () => {},
  };
  const { timeout, afterUnmount } = { ...DEFAULT_OPTIONS, ...options };

  const [mounted, setMounted] = useState(false);
  const [visible, setVisible] = useState(false);

  const open = () => {
    setMounted(true);

    setTimeout(() => {
      setVisible(true);
    }, timeout);
  };

  const close = () => {
    setVisible(false);

    setTimeout(() => {
      setMounted(false);
      afterUnmount();
    }, timeout * 2);
  };

  return {
    mounted,
    visible,
    open,
    close,
  };
};

export const useToggle = (initialValue: boolean = false) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: React.FormEvent<HTMLInputElement>) => {
    setValue(e.currentTarget.checked);
  };

  return { checked: value, onChange, setValue };
};

export const useOptionalSelectInput = (initialValue: SelectOption = null) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (val: SelectOption) => {
    setValue(val);
  };

  return { value, onChange, selected: value ? value.value : null };
};

export const useRequiredSelectInput = (initialValue: SelectOptionObject) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (val: SelectOptionObject) => {
    setValue(val);
  };

  return { value, onChange, selected: value.value };
};

export const useMultiSelectInput = (
  initialValue: SelectOptionObject[] = [],
) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (val: SelectOptionObject[]) => {
    setValue(val);
  };

  return { value, onChange, selected: value };
};

export const useTextInput = (initialValue: string = '') => {
  const [value, setValue] = useState(initialValue);

  const onChange = (
    e: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    setValue(e.currentTarget.value);
  };

  return { value, onChange, setValue };
};

export const useOptionalDateInput = (initialValue: Moment | null = null) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (date: Moment | null) => {
    setValue(date);
  };

  const onClear = () => {
    setValue(null);
  };

  return {
    value,
    onChange,
    unix: () => (value ? value.unix() : null),
    onClear,
  };
};

export const useRequiredDateInput = (initialValue: Moment) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (date: Moment) => {
    setValue(date);
  };

  return { value, onChange, unix: () => (value ? value.unix() : null) };
};

export const useEntityRefresh = (
  uid: string,
  entityState: EntityState,
  fetchData: any,
) => {
  useEffect(
    () => {
      if (needsToBeFetched(entityState)) {
        fetchData(uid);
      }
    },
    [uid],
  );
};

export function useContentEditable(
  key: string,
  onContentChange: (contentObject: any) => void,
) {
  function processContentText(content: string) {
    return content.trim();
  }

  function handleBlurEvent(event: React.FocusEvent<any>) {
    const content = processContentText(event.currentTarget.innerText);
    event.currentTarget.innerText = content;
    onContentChange({ [key]: content });
  }

  function handleDone(event: React.KeyboardEvent<any>) {
    if (event.which === ENTER_KEY_CODE) {
      event.currentTarget.blur();
    }
  }

  return { handleDone, handleBlurEvent };
}

export function handleContentLengthErrors(
  event: React.KeyboardEvent<any>,
  maxLength: number,
  isTooLong: boolean,
  isTooShort: boolean,
  setIsTooLong: (value: boolean) => void,
  setIsTooShort: (value: boolean) => void,
  handleDone: (event: React.KeyboardEvent<any>) => void,
) {
  if (event.currentTarget.innerHTML.length >= maxLength) {
    if (isTooLong) {
      return;
    }
    toast.error(`Title is too long. Maximum of ${maxLength} characters!`);
    setIsTooLong(true);
    return;
  }
  if (event.currentTarget.innerHTML.length === 0) {
    if (isTooShort) {
      return;
    }
    toast.error(`Cannot leave labels empty!`);
    setIsTooShort(true);
    return;
  }

  if (isTooLong) {
    setIsTooLong(false);
  }
  if (isTooShort) {
    setIsTooShort(false);
  }
  handleDone(event);
}

interface UseFetchProps<T> {
  initial: T;
  change?: any[];
  fetch: () => Promise<any>;
  accessor: string;
}

export const useSimpleFetch = <T extends any>({
  initial,
  change,
  fetch,
  accessor,
}: UseFetchProps<T>) => {
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState<T>(initial);
  const [errors, setErrors] = useState<any>({});
  const [fetched, setFetched] = useState(false);

  const performFetch = async () => {
    setLoading(true);
    const res = await fetch();
    if (res.errors) {
      setErrors(res.errors);
    }
    if (res.data) {
      setValue(res.data[accessor]);
    }
    setLoading(false);
    setFetched(true);
  };

  useEffect(() => {
    performFetch();
  }, change || []);

  return {
    value,
    errors,
    loading,
    performFetch,
    setValue,
    fetched,
  };
};

export const useNetworkSyncInput = <T extends any>(
  value: T,
  endpoint: (value: T) => Promise<any | void>,
  ms: number = 200,
) => {
  const sync = async () => {
    await endpoint(value);
  };

  useEffect(
    () => {
      const handler = setTimeout(() => {
        sync();
      }, ms);

      return () => {
        clearTimeout(handler);
      };
    },
    [value],
  );
};

interface UseCompanyEntitySelectOptions {
  entity: (state: StoreState) => EntityState;
  fetch: (companyUid: string) => any;
  label: string;
}

export const useCompanyEntitySelectOptions = ({
  entity,
  fetch,
  label,
}: UseCompanyEntitySelectOptions) => {
  const state = store.getState();

  const [entityState, setEntityState] = useState(entity(state));
  const [company, setCompany] = useState(state.me.company);

  useEntityRefresh(company.uid, entityState, () =>
    store.dispatch(fetch(company.uid)),
  );

  useEffect(() => {
    const unsub = store.subscribe(() => {
      const newState = store.getState();
      if (newState.me.company !== company) {
        setCompany(newState.me.company);
      }
      if (entity(newState) !== entityState) {
        setEntityState(entity(newState));
      }
    });

    return () => unsub();
  }, []);

  return entityState.data.map((u: any) => ({
    label: u[label],
    value: u.uid,
  }));
};

export const useCompanyCrewOptions = () =>
  useCompanyEntitySelectOptions({
    entity: state => state.crews,
    fetch: crewsFetchRequest,
    label: 'name',
  });

export const useCompanyUserOptions = () =>
  useCompanyEntitySelectOptions({
    entity: state => state.users,
    fetch: usersFetchRequest,
    label: 'username',
  });

// export const useDeepEqualEffect = (
//   cb: React.EffectCallback,
//   onChange: any[],
// ) => {
//   const ref = useRef(onChange);

//   useEffect(() => {
//     if (
//       any(i => !isEqual(onChange[i], ref.current[i]), range(0, onChange.length))
//     ) {
//       ref.current = onChange;
//       return cb();
//     }
//   });
// };

function useDeepCompareMemoize(value: any[]) {
  const ref = useRef<any>(null);

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

export function useDeepEqualEffect(
  callback: React.EffectCallback,
  dependencies: any[],
) {
  useEffect(callback, useDeepCompareMemoize(dependencies));
}

export const useParsedSubmDataSync = ({
  option,
  data,
  delay = 1000,
  submData,
  initial,
  submUid,
}: {
  option: FormOption;
  submData?: ParsedSubmData;
  data: any;
  delay?: number;
  initial: any;
  submUid: string;
}) => {
  const addData = (newData: ParsedSubmData) =>
    store.dispatch(addSubmData(newData));

  const updateServer = async () => {
    const res = await submissionApi.updateFormOption(submUid, option.id, data);
    if (!submData && res.data) {
      addData(res.data.submData);
    }
  };

  useDeepEqualEffect(
    () => {
      const handler = setTimeout(() => {
        if (
          (submData && !isEqual(data, submData.parsed)) ||
          (!submData && !isEqual(data, initial))
        ) {
          updateServer();
        }
      }, delay);

      return () => {
        clearTimeout(handler);
      };
    },
    [data],
  );
};

/**
 * For naive local state usage
 * Creates the subm data if not exists
 * Does _not_ update the submData redux as value changes
 */
export const useParsedSubmDataLocal = <T>({
  option,
  submData,
  initial,
  submUid,
}: {
  option: FormOption;
  submData?: ParsedSubmData;
  initial: T;
  submUid: string;
}) => {
  const parsed = submData ? submData.parsed : initial;
  const [value, setValue] = useState(parsed);

  useParsedSubmDataSync({
    option,
    submData,
    data: value,
    initial,
    submUid,
  });

  useDeepEqualEffect(
    () => {
      // if the submission was updated externally,
      // set the value if we havent set anything locally
      if (submData && isEqual(initial, value)) {
        setValue(submData.parsed);
      }
    },
    [parsed],
  );

  return [value, setValue];
};

/**
 * Need to:
 * Not update when initial submData.parsed is set
 *
 * !submData && data !== initial
 * submData && data !== submData.parsed
 */

export function useMedia(queries: string[], values: any[], defaultValue: any) {
  // Array containing a media query list for each query
  const mediaQueryLists = queries.map(q => window.matchMedia(q));

  // Function that gets value based on matching media query
  const getValue = () => {
    // Get index of first media query that matches
    const index = mediaQueryLists.findIndex(mql => mql.matches);
    // Return related value or defaultValue if none
    return typeof values[index] !== 'undefined' ? values[index] : defaultValue;
  };

  // State and setter for matched value
  const [value, setValue] = useState(getValue);

  useEffect(
    () => {
      // Event listener callback
      // Note: By defining getValue outside of useEffect we ensure that it has ...
      // ... current values of hook args (as this hook callback is created once on mount).
      const handler = () => setValue(getValue);
      // Set a listener for each media query with above handler as callback.
      mediaQueryLists.forEach((mql: any) => mql.addListener(handler));
      // Remove listeners on cleanup
      return () =>
        mediaQueryLists.forEach((mql: any) => mql.removeListener(handler));
    },
    [], // Empty array ensures effect is only run on mount and unmount
  );

  return value;
}

export const useLongPoll = (cb: any, change: any[] = [], timeout = 4000) => {
  useEffect(() => {
    cb();

    const handler = setInterval(() => {
      cb();
    }, timeout);

    return () => {
      clearInterval(handler);
    };
  }, change);
};

export const useFirstRender = () => {
  const firstRender = useRef(true);

  useEffect(() => {
    firstRender.current = false;
  }, []);

  return firstRender.current;
};

export function useInicidentProps(companyUid: string) {
  const [incidentProps, setIncidentProps] = useState<IncidentProp[]>([]);
  const [incidentPropOptions, setIncidentPropOptions] = useState<
    IncidentPropOption[]
  >([]);
  async function fetchIncidentProps() {
    if (!companyUid) {
      return;
    }
    const { data } = await companyApi.incidentProps(companyUid);
    if (data) {
      setIncidentProps(data.incidentProps);
      setIncidentPropOptions(data.incidentPropOptions);
    }
  }
  useEffect(
    () => {
      fetchIncidentProps();
    },
    [companyUid],
  );

  return { incidentProps, incidentPropOptions };
}

import * as React from 'react';
import CloseOutline from '../../../svg/CloseOutline';
import styled from 'styled-components';
import { cssVars } from '../../../constants/index';
import Search from '../../../svg/Search';
import LockClosed from '../../../svg/LockClosed';
import { ComplianceState } from '../../../reducers/form';

const ThirdBlock = ({
  all,
  Icon,
  title,
  single,
  site,
}: {
  all?: boolean;
  Icon: React.ComponentType<any>;
  title: string;
  single: number | string;
  site: number | string;
}) => (
  <div className="bg-grey-darkest flex-1 lg:flex-initial flex justify-between text-white p-8 items-center lg:w-1/4 mb-4 lg:mb-0">
    <div className="flex flex-col items-center">
      <Icon className={'w-8 h-8'} />
      <p className="mt-2">{title}</p>
    </div>
    {all ? (
      <AllTable>
        <tbody>
          <AllTR>
            <AllTD>CREW</AllTD>
            <AllTD>{single}</AllTD>
          </AllTR>
          <AllTR border={true}>
            <AllTD>SITE</AllTD>
            <AllTD>{site}</AllTD>
          </AllTR>
        </tbody>
      </AllTable>
    ) : (
      <h2 className="font-normal">{single}</h2>
    )}
  </div>
);

const AllTable = styled.table`
  border-collapse: separate;
  border-spacing: 0;
`;

const AllTD = styled.td`
  padding: 4px ${cssVars.padding[2]};
  border: 0;
`;

const AllTR = styled.tr<{ border?: boolean }>`
  & td {
    ${props => (props.border ? 'border-top: 1px solid white;' : '')};
  }
`;

const roundIfNotZero = (num: number, fixed: number = 2) =>
  num === 0 ? 0 : num.toFixed(fixed);

interface Props {
  compliance: ComplianceState;
  all: boolean;
}

const AuditScore = ({ compliance: pCompliance, all }: Props) => {
  const { audits, audits_all, compliance, compliance_all } = pCompliance;
  return (
    <div className="flex flex-col lg:flex-row lg:justify-between mt-4">
      <ThirdBlock
        all={all}
        Icon={Search}
        title="Observations"
        single={audits ? audits.observations : 0}
        site={audits_all ? audits_all.observations : 0}
      />
      <div className="bg-red p-8 flex-1 flex justify-between items-center text-white mb-4 lg:mb-0 lg:mx-4">
        <div className="flex flex-col items-center">
          <CloseOutline className={'w-8 h-8'} />
          <p className="mt-2">Unsafe Conditions</p>
        </div>
        {all ? (
          <AllTable>
            <tbody>
              <AllTR>
                <AllTD>CREW</AllTD>
                {audits ? (
                  <>
                    <AllTD>{audits.unsafe.all}</AllTD>
                    {audits.unsafe.ranks.map(x => (
                      <AllTD key={x.label}>{x.value || 0}</AllTD>
                    ))}
                  </>
                ) : (
                  <>
                    <AllTD>0</AllTD>
                    <AllTD>0</AllTD>
                    <AllTD>0</AllTD>
                    <AllTD>0</AllTD>
                    <AllTD>0</AllTD>
                  </>
                )}
              </AllTR>
              <AllTR border={true}>
                <AllTD>SITE</AllTD>
                {audits_all ? (
                  <>
                    <AllTD>{audits_all.unsafe.all}</AllTD>
                    {audits_all.unsafe.ranks.map(x => (
                      <AllTD key={x.label}>{x.value || 0}</AllTD>
                    ))}
                  </>
                ) : (
                  <>
                    <AllTD>0</AllTD>
                    <AllTD>0</AllTD>
                    <AllTD>0</AllTD>
                    <AllTD>0</AllTD>
                    <AllTD>0</AllTD>
                  </>
                )}
              </AllTR>
              <AllTR>
                <AllTD />
                <AllTD>All</AllTD>
                <AllTD />
                <AllTD />
                <AllTD />
                <AllTD />
              </AllTR>
            </tbody>
          </AllTable>
        ) : (
          <div className="flex-1 flex flex-row justify-end ml-8">
            <div className="text-center mx-4">
              <h3>{audits ? audits.unsafe.all : 0}</h3>
              <p>All</p>
            </div>
            {audits ? (
              audits.unsafe.ranks.map(x => (
                <div className="text-center mx-4" key={x.label}>
                  <h3>{x.value || 0}</h3>
                  <p>{x.label}</p>
                </div>
              ))
            ) : (
              <>
                <div className="text-center mx-4">
                  <h3>0</h3>
                  <p>Low</p>
                </div>
                <div className="text-center mx-4">
                  <h3>0</h3>
                  <p>Medium</p>
                </div>
                <div className="text-center mx-4">
                  <h3>0</h3>
                  <p>High</p>
                </div>
                <div className="text-center mx-4">
                  <h3>0</h3>
                  <p>Extreme</p>
                </div>
              </>
            )}
          </div>
        )}
      </div>
      <ThirdBlock
        all={all}
        Icon={LockClosed}
        title="% Safe"
        single={roundIfNotZero(compliance || 0, 0)}
        site={roundIfNotZero(compliance_all || 0, 0)}
      />
    </div>
  );
};

export default AuditScore;

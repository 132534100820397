import { createAction } from 'typesafe-actions';
import { Certificate, PermissionsError } from '../types';
import {
  CERTIFICATES_ADD,
  CERTIFICATES_FETCH_FAILURE,
  CERTIFICATES_FETCH_REQUEST,
  CERTIFICATES_FETCH_SUCCESS,
  CERTIFICATES_REMOVE,
  CERTIFICATES_SET,
  CERTIFICATES_UPDATE,
} from '../constants/actionTypes';

export const certificatesFetchRequest = createAction(
  CERTIFICATES_FETCH_REQUEST,
  resolve => (companyUid: string) => resolve(companyUid),
);

export const certificatesFetchSuccess = createAction(
  CERTIFICATES_FETCH_SUCCESS,
  resolve => (certificates: Certificate[]) => resolve(certificates),
);

export const certificatesFetchFailure = createAction(
  CERTIFICATES_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const addCertificate = createAction(
  CERTIFICATES_ADD,
  resolve => (certificate: Certificate) => resolve(certificate),
);

export const setCertificates = createAction(
  CERTIFICATES_SET,
  resolve => (certificates: Certificate[]) => resolve(certificates),
);

export const updateCertificate = createAction(
  CERTIFICATES_UPDATE,
  resolve => (certificate: Certificate) => resolve(certificate),
);

export const removeCertificate = createAction(
  CERTIFICATES_REMOVE,
  resolve => (certificateUid: string) => resolve(certificateUid),
);

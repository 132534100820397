import * as React from 'react';
import Page from '../../components/Page';
import PageContent from '../../components/PageContent';
import Tabs from '../../components/Tabs';
import Requirements from './roles/Requirements';
import Certificates from './roles/Certificates';
import { Company, WorkRole, Certificate } from '../../types';
import Loading from '../../components/Loading';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import {
  certificatesFetchRequest,
  setCertificates,
} from '../../actions/certificates';
import { CertificatesState } from '../../reducers/certificates';
import { needsToBeFetched } from '../../helpers/globalState';
import { setWorkRoles, workRolesFetchRequest } from '../../actions/workRoles';
import { WorkRolesState } from '../../reducers/workRoles';
import WorkRoles from './roles/WorkRoles';
import { RoleCertificateMatrixState } from '../../reducers/roleCertificateMatrix';
import { roleCertificateMatrixFetchRequest } from '../../actions/roleCertificateMatrix';
import InlineAlert from '../../components/InlineAlert';

interface Props {
  company: Company;
  certificates: CertificatesState;
  setCertificates: (certs: Certificate[]) => any;
  getCertificates: (companyUid: string) => any;
  workRoles: WorkRolesState;
  setWorkRoles: (roles: WorkRole[]) => any;
  getWorkRoles: (companyUid: string) => any;
  roleCertificateMatrix: RoleCertificateMatrixState;
  getRoleCertificateMatrix: (companyUid: string) => any;
}

class Roles extends React.Component<Props> {
  public componentDidMount(): void {
    if (needsToBeFetched(this.props.workRoles)) {
      this.props.getWorkRoles(this.props.company.uid);
    }
    if (needsToBeFetched(this.props.certificates)) {
      this.props.getCertificates(this.props.company.uid);
    }
    if (needsToBeFetched(this.props.roleCertificateMatrix)) {
      this.props.getRoleCertificateMatrix(this.props.company.uid);
    }
  }

  public componentDidUpdate(prevProps: Props): void {
    if (needsToBeFetched(this.props.certificates)) {
      this.props.getCertificates(this.props.company.uid);
    }
    if (needsToBeFetched(this.props.workRoles)) {
      this.props.getWorkRoles(this.props.company.uid);
    }
    if (needsToBeFetched(this.props.roleCertificateMatrix)) {
      this.props.getRoleCertificateMatrix(this.props.company.uid);
    }
  }

  private loading = () =>
    this.props.certificates.loading ||
    this.props.workRoles.loading ||
    this.props.roleCertificateMatrix.loading;

  public render() {
    const {
      certificates,
      company,
      workRoles,
      roleCertificateMatrix,
    } = this.props;

    const tabs = [
      {
        label: 'Roles & Certificates',
        component: (
          <Requirements
            roles={workRoles.data}
            certificates={certificates.data}
            matrix={roleCertificateMatrix.data}
            loaded={roleCertificateMatrix.loaded}
          />
        ),
      },
      {
        label: 'Certificates List',
        component: (
          <Certificates
            certificates={certificates.data}
            companyUid={company.uid}
          />
        ),
      },
      {
        label: 'Roles List',
        component: (
          <WorkRoles companyUid={company.uid} workRoles={workRoles.data} />
        ),
      },
    ];

    return (
      <Page>
        <PageContent>
          <Loading loading={this.loading()} />
          <Tabs tabs={tabs} raised={true} />
        </PageContent>
      </Page>
    );
  }
}

const mapStateToProps = (state: StoreState) => ({
  company: state.me.company,
  certificates: state.certificates,
  workRoles: state.workRoles,
  roleCertificateMatrix: state.roleCertificateMatrix,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getCertificates: (companyUid: string) =>
    dispatch(certificatesFetchRequest(companyUid)),
  setCertificates: (certs: Certificate[]) => dispatch(setCertificates(certs)),
  getWorkRoles: (companyUid: string) =>
    dispatch(workRolesFetchRequest(companyUid)),
  setWorkRoles: (roles: WorkRole[]) => dispatch(setWorkRoles(roles)),
  getRoleCertificateMatrix: (companyUid: string) =>
    dispatch(roleCertificateMatrixFetchRequest(companyUid)),
});

export const _Roles = connect(mapStateToProps, mapDispatchToProps)(Roles);

export default function RolesTemporarilyDisabled() {
  return (
    <Page>
      <PageContent>
        <div className="p-8 text-center">
          <InlineAlert
            type={'warning'}
            text={`This feature is currently disabled while we work to improve it. Thanks
          for understanding!`}
          />
        </div>
      </PageContent>
    </Page>
  );
}

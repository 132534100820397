import { createAction } from 'typesafe-actions';
import { PermissionsError, Crew } from '../../types';
import {
  COMPANY_GROUP_CREWS_FETCH_REQUEST,
  COMPANY_GROUP_CREWS_FETCH_SUCCESS,
  COMPANY_GROUP_CREWS_FETCH_FAILURE,
  COMPANY_GROUP_CREWS_ADD,
  COMPANY_GROUP_CREWS_REMOVE,
} from '../../constants/actionTypes';

export const companyGroupCrewsFetchRequest = createAction(
  COMPANY_GROUP_CREWS_FETCH_REQUEST,
  resolve => (companyGroupUid: string) => resolve(companyGroupUid),
);

export const companyGroupCrewsFetchSuccess = createAction(
  COMPANY_GROUP_CREWS_FETCH_SUCCESS,
  resolve => (crews: Crew[]) => resolve(crews),
);

export const companyGroupCrewsFetchFailure = createAction(
  COMPANY_GROUP_CREWS_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const companyGroupAddCrew = createAction(
  COMPANY_GROUP_CREWS_ADD,
  resolve => (crew: Crew) => resolve(crew),
);

export const companyGroupRemoveCrew = createAction(
  COMPANY_GROUP_CREWS_REMOVE,
  resolve => (crewUid: string) => resolve(crewUid),
);

import * as React from 'react';
import InlineInput from '../../components/InlineInput';
import InlineDatePicker from '../../components/InlineDatePicker';
import {
  useTextInput,
  useOptionalDateInput,
  useMountVisibility,
} from '../../helpers/hooks';
import Button from '../../components/Button';
import { Certificate, Storage, User } from '../../types';
import * as moment from 'moment';
import certApi from '../../api/cert.api';
import ConfirmationDialogue from '../../components/ConfirmationDialogue';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import PrivateImage from '../../components/PrivateImage';
import cloudinaryService from '../../services/cloudinary.service';
import { StoreState } from '../../store';
import Loading from '../../components/Loading';
import { connect } from 'react-redux';

const ImageHoverContainer = styled.div`
  position: absolute;
  bottom: 4px;
  left: 0;
  background: rgba(0, 0, 0, 0.25);
  padding: 4px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
`;

const ImageButton = styled.button`
  background: transparent;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  border: 0;
  flex: 1;
  font-size: 9px;

  &:hover {
    background: white;
    color: black;
  }
`;

interface Props {
  cert: Certificate;
  onUpdate?: (cert: Certificate) => void;
  onDelete?: (cert: Certificate) => void;
  image?: Storage;
  user: User;
}

const UserCertificate = ({ cert, onUpdate, onDelete, image, user }: Props) => {
  const name = useTextInput(cert.name);
  const expiry = useOptionalDateInput(
    cert.exp_date ? moment.unix(cert.exp_date) : null,
  );
  const [loading, setLoading] = React.useState(false);
  const deleteState = useMountVisibility();

  const update = async () => {
    setLoading(true);
    await certApi.update(cert.uid, {
      name: name.value,
      expiry: expiry.value ? expiry.value.unix() : null,
    });
    setLoading(false);
    if (onUpdate) {
      onUpdate(cert);
    }
    toast.success('Certificate updated');
  };

  const destroy = async () => {
    await certApi.delete(cert.uid);
    deleteState.close();
    if (onDelete) {
      onDelete(cert);
    }
  };

  const removeImage = async () => {
    setLoading(true);
    await certApi.removeImage(cert.uid);
    if (onUpdate) {
      onUpdate(cert);
    }
    setLoading(false);
  };

  const onFileChange = async (e: React.FormEvent<HTMLInputElement>) => {
    setLoading(true);

    const { files } = e.currentTarget;
    if (files && files.length > 0) {
      const formData = new FormData();
      const img = await cloudinaryService.ensureImg({
        userUid: user.uid,
        file: files[0],
      });
      if (!img) {
        setLoading(false);
        toast.error('Failed to convert to image!');
        return;
      }
      formData.append('image', img);
      const { errors } = await certApi.changeImage(cert.uid, formData);
      setLoading(false);
      if (errors || !formData) {
        toast.error('Failed to upload!');
        return;
      }
      toast.success('Successfully uploaded certificate!');
      if (onUpdate) {
        onUpdate(cert);
      }
    }
  };

  const openFileSelect = () => {
    const el = document.getElementById(`cert-${cert.uid}-img-upload`);
    if (el) {
      el.click();
    }
  };

  return (
    <div className="flex flex-col p-4 border-0 border-b-1 border-solid border-border">
      <div className="flex flex-row items-center mb-4">
        <div className={'mr-4 relative flex-1'}>
          <Loading loading={loading} />
          {image ? (
            <PrivateImage storage={image} />
          ) : (
            <img src="http://placehold.it/100x100" alt="" />
          )}
          <ImageHoverContainer>
            <ImageButton onClick={removeImage}>Delete</ImageButton>
            <ImageButton onClick={openFileSelect}>Change</ImageButton>
            <input
              type="file"
              accept="*"
              style={{ display: 'none' }}
              id={`cert-${cert.uid}-img-upload`}
              onChange={onFileChange}
              value={''}
            />
          </ImageHoverContainer>
        </div>
        <div className={'flex flex-col w-full'}>
          <InlineInput label={'Name'} {...name} />
          {expiry.value && (
            <InlineDatePicker
              label={'Expires'}
              margin={false}
              {...expiry}
              onClear={() => expiry.onChange(null)}
            />
          )}
        </div>
      </div>
      <div className="flex flex-row">
        <Button
          type="default"
          className="mr-4"
          loading={loading}
          onClick={update}
        >
          Update
        </Button>
        <Button type="primary" loading={loading} onClick={deleteState.open}>
          Delete
        </Button>
      </div>

      {deleteState.mounted && (
        <ConfirmationDialogue
          isOpen={deleteState.visible}
          handleClose={deleteState.close}
          title="Delete certificate?"
          body={'This action cannot be undone'}
          contentLabel={'Delete certificate'}
          onConfirm={destroy}
        />
      )}
    </div>
  );
};

const mapState = (state: StoreState) => ({
  user: state.me.user,
});

export default connect(mapState)(UserCertificate);

import * as React from 'react';
import UserActivity from '../../../containers/dashboard/UserActivity';
import Actions from '../../../containers/dashboard/Actions';
import Checklist from '../../../containers/dashboard/Checklist';
import { Company, Crew, Form, Site, User as IUser } from '../../../types';
import Summaries from './statistics/Summaries';
import SubmissionStatistics from '../../../containers/SubmissionStatistics';

interface Props {
  company: Company;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
}

export default class Statistics extends React.Component<Props> {
  public render() {
    const { users, forms, sites, crews, company } = this.props;

    return (
      <div>
        <Summaries
          crewCount={crews.length}
          formCount={forms.length}
          siteCount={sites.length}
          userCount={users.length}
        />

        <SubmissionStatistics
          company={company}
          users={users}
          sites={sites}
          forms={forms}
          crews={crews}
        />

        <div className="flex flex-col md:flex-row mb-4">
          <div className="flex-1 md:ml-4">
            <UserActivity company={company} />
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="flex-1 mb-4 md:mb-0">
            <Actions
              sites={sites}
              users={users}
              company={company}
              forms={forms}
            />
          </div>
          <div className="flex-1 md:ml-4">
            <Checklist users={users} company={company} />
          </div>
        </div>
      </div>
    );
  }
}

import * as React from 'react';
import SideNavigation from '../components/SideNavigation';
import User from '../svg/User';
import styled from 'styled-components';
import { biggerThanMD } from '../helpers/style';
import Location from '../svg/Location';
import UserGroup from '../svg/UserGroup';
import Trophy from '../svg/Trophy';
import Trash from '../svg/Trash';
import Desktop from '../svg/Desktop';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import store, { StoreState } from '../store';
import { Company, Site as ISite } from '../types';
import { withNamespaces } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import { listenPrivate } from '../helpers/socket';
import { addSite } from '../actions/sites';
import Loading from '../components/Loading';
import File from '../svg/File';
import Book from '../svg/Book';
import { Fab, FabAction } from '../components/Fab';
import { cssVars } from '../constants/index';
import Menu from '../svg/Menu';
import { History } from 'history';
import Dashboard from './super/Dashboard';
import Forms from './super/Forms';
import Form from './super/Form';
import Sites from './super/Sites';
import Site from './super/Site';
import Crews from './super/Crews';
import Users from './super/Users';
import Roles from './super/Roles';
import TrashScreen from './super/Trash';
import FormFabricator from './super/FormFabricator';
import { getSiteLabelPlural, getCrewLabelPlural, unidashUrl } from '../helpers';

const DashboardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${biggerThanMD(`
    flex-direction: row;
  `)};
`;

const DashboardScrollingContent = styled.div`
  flex: 1;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: visible;

  ${biggerThanMD(`
    overflow: scroll;
  `)};
`;

interface Props {
  company: Company;
  t: TranslationFunction;
  history: History;
}

class Super extends React.Component<Props> {
  private listeners: any[] = [];

  public componentDidMount() {
    this.listeners = [
      listenPrivate(
        `super.companies.${this.props.company.uid}`,
        'siteCreated',
        (data: ISite) => {
          store.dispatch(addSite(data));
        },
      ),
    ];
  }

  public componentWillUnmount() {
    this.listeners.forEach(unsub => unsub());
  }

  public render() {
    if (!this.props.company.uid) {
      return null;
    }

    const { t, company } = this.props;

    const routes = [
      {
        label: t('common:dashboard'),
        icon: <Desktop />,
        path: '/dashboard/super/dashboard',
      },
      {
        label: t('common:forms'),
        icon: <File />,
        path: '/dashboard/super/forms',
      },
      {
        label: `${getSiteLabelPlural()}`,
        icon: <Location />,
        path: '/dashboard/super/sites',
      },
      ...(company.document_management
        ? [
            {
              label: t('common:documents'),
              icon: <Book />,
              path: unidashUrl(`/documents`) || '',
              badge: <span>New</span>,
              external: true,
            },
          ]
        : []),
      {
        label: `${getCrewLabelPlural()}`,
        icon: <UserGroup />,
        path: '/dashboard/super/crews',
      },
      {
        label: t('common:roles'),
        icon: <Trophy />,
        path: '/dashboard/super/roles',
      },
      {
        label: t('common:users'),
        icon: <User />,
        path: '/dashboard/super/users',
      },
      {
        label: t('common:trash'),
        icon: <Trash />,
        path: '/dashboard/super/trash',
      },
    ];

    return (
      <DashboardContainer>
        <SideNavigation routes={routes} />
        <DashboardScrollingContent>
          <React.Suspense fallback={<Loading loading={true} />}>
            <Route path={'/dashboard/super/dashboard'} component={Dashboard} />
            <Route
              exact={true}
              path={'/dashboard/super/forms'}
              component={Forms}
            />
            <Route path={'/dashboard/super/forms/:formUid'} component={Form} />
            <Route
              path={'/dashboard/super/form-builder/:formUid'}
              component={FormFabricator}
            />
            <Route
              exact={true}
              path={'/dashboard/super/sites'}
              component={Sites}
            />
            <Route
              exact={true}
              path={'/dashboard/super/sites/:siteUid'}
              component={Site}
            />
            <Route
              exact={true}
              path={'/dashboard/super/crews'}
              component={Crews}
            />
            <Route
              exact={true}
              path={'/dashboard/super/users'}
              component={Users}
            />
            <Route
              exact={true}
              path={'/dashboard/super/roles'}
              component={Roles}
            />
            <Route
              exact={true}
              path={'/dashboard/super/trash'}
              component={TrashScreen}
            />
          </React.Suspense>
          <div className={'md:hidden'}>
            <Fab
              event={'click'}
              mainButtonStyles={{
                backgroundColor: cssVars.colors.red,
              }}
              position={{
                bottom: 0,
                right: 0,
              }}
              icon={<Menu className="w-6 h-6" />}
            >
              {routes.map(x => (
                <FabAction
                  key={x.path}
                  style={{
                    backgroundColor: cssVars.colors.grey,
                  }}
                  text={x.label}
                  onClick={() => this.props.history.push(x.path)}
                >
                  {React.cloneElement(x.icon, {
                    className: 'w-6 h-6 text-black',
                  })}
                </FabAction>
              ))}
            </Fab>
          </div>
        </DashboardScrollingContent>
      </DashboardContainer>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
  };
}

export default withNamespaces()<any>(connect(mapStateToProps)(Super));

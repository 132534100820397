import * as React from 'react';
import { User } from '../types';
import Avatar from './Avatar';
import Trash from '../svg/Trash';
import styled from 'styled-components';
import { cssVars } from '../constants/index';
import { useState } from 'react';
import CloseOutline from '../svg/CloseOutline';

const TrashButton = styled.button`
  background: transparent;
  border: 1px solid ${cssVars.colors.grey};
  padding: ${cssVars.padding[3]};
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background: grey;
    color: white;
  }
`;

const AvatarDeleteButton = styled.button`
  background: rgba(255, 255, 255, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

interface Props {
  tags: any[];
  accessor?: string;
  onClick: (tag: any) => any | void;
}

const Tags = ({ tags, accessor, onClick }: Props) => {
  return (
    <div className="flex flex-row">
      {tags.map(tag => (
        <div className="relative py-2 px-4 bg-grey-darkest text-white flex flex-row items-center justify-between mr-3">
          {accessor ? tag[accessor] : tag}

          <CloseOutline
            onClick={() => onClick(tag)}
            className={'ml-2 hover:text-red cursor-pointer w-4 h-4'}
          />
        </div>
      ))}
    </div>
  );
};

export default Tags;

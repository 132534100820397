import $ from 'jquery';

(function($, window, document, undefined) {
  var Sketch = function(obj, options) {
    this.obj = obj;
    this.options = options;
    this.ctx = obj[0].getContext('2d');
    this.init();
  };

  Sketch.prototype = {
    defaults: {
      color: '#000000',
      size: 2,
      background: '#ffffff',
      onStop: function() {},
      onLoadImg: function() {},
      onErase: function() {},
      scale: 1,
    },

    init: function() {
      this.cfg = $.extend({}, this.defaults, this.options, this.obj.data());
      if (!this.cfg.origWidth) {
        this.cfg.origWidth = this.obj[0].width;
      }
      if (!this.cfg.origHeight) {
        this.cfg.origHeight = this.obj[0].height;
      }
      this.ctx.strokeStyle = this.cfg.color;
      this.ctx.lineWidth = this.cfg.size;
      this.ctx.lineCap = 'round';
      this.ctx.fillStyle = this.cfg.background;
      this.reset();
      this.drawTouch();
      this.drawMouse();
      if (this.cfg.img) {
        this.loadImg(this.cfg.img);
      }
      return this;
    },
    reset: function() {
      this.ctx.fillRect(0, 0, this.obj[0].width, this.obj[0].height);
    },
    drawTouch: function() {
      var _this = this;
      var start = function(e) {
        e.preventDefault();
        _this.resize();
        _this.ctx.strokeStyle = _this.cfg.color;
        _this.ctx.lineWidth = _this.cfg.size;
        _this.ctx.beginPath();
        var x =
          (e.changedTouches[0].pageX - _this.obj.offset().left) /
          _this.cfg.scale;
        var y =
          (e.changedTouches[0].pageY - _this.obj.offset().top) /
          _this.cfg.scale;
        _this.ctx.moveTo(x, y);
      };
      var move = function(e) {
        e.preventDefault();
        _this.resize();
        var x =
          (e.changedTouches[0].pageX - _this.obj.offset().left) /
          _this.cfg.scale;
        var y =
          (e.changedTouches[0].pageY - _this.obj.offset().top) /
          _this.cfg.scale;
        _this.ctx.lineTo(x, y);
        _this.ctx.stroke();
        _this._onDraw();
      };
      var end = function(e) {
        _this._onStop();
      };
      this.obj[0].addEventListener('touchstart', start, false);
      this.obj[0].addEventListener('touchmove', move, false);
      this.obj[0].addEventListener('touchend', end, false);
    },
    drawMouse: function() {
      var _this = this;
      var clicked = 0;
      var start = function(e) {
        e.preventDefault();
        _this.resize();
        clicked = 1;
        _this.ctx.strokeStyle = _this.cfg.color;
        _this.ctx.lineWidth = _this.cfg.size;
        _this.ctx.beginPath();
        var x = (e.pageX - _this.obj.offset().left) / _this.cfg.scale;
        var y = (e.pageY - _this.obj.offset().top) / _this.cfg.scale;
        _this.ctx.moveTo(x, y);
      };
      var move = function(e) {
        e.preventDefault();
        _this.resize();
        if (clicked) {
          var x = (e.pageX - _this.obj.offset().left) / _this.cfg.scale;
          var y = (e.pageY - _this.obj.offset().top) / _this.cfg.scale;
          _this.ctx.lineTo(x, y);
          _this.ctx.stroke();
          _this._onDraw();
        }
      };
      var stop = function(e) {
        clicked = 0;
      };
      var end = function(e) {
        _this._onStop();
      };
      this.obj[0].addEventListener('mousedown', start, false);
      this.obj[0].addEventListener('mousemove', move, false);
      this.obj[0].addEventListener('mouseup', end, false);
      document.addEventListener('mouseup', stop, false);
    },
    loadImg: function(src) {
      var _this = this;
      var img = new Image();
      img.setAttribute('crossOrigin', 'anonymous');
      img.onload = function() {
        _this.ctx.drawImage(
          img,
          0,
          0,
          img.width,
          img.height,
          0,
          0,
          _this.obj[0].width,
          _this.obj[0].height,
        );
        if (typeof _this.cfg.onLoadImg === 'function') {
          _this.cfg.onLoadImg(_this.obj, src);
        }
      };
      var t = new Date();
      img.src = src;
    },
    getImageData: function() {
      return this.ctx.getImageData(0, 0, this.obj[0].width, this.obj[0].height);
    },
    toDataURL: function() {
      return this.obj[0].toDataURL('image/jpeg', 1.0);
    },
    erase: function(invoke_callback) {
      this.ctx.clearRect(0, 0, this.obj[0].height, this.obj[0].height);
      this.reset();
      if (typeof this.cfg.onErase === 'function' && invoke_callback !== false) {
        this.cfg.onErase(this.obj);
      }
    },
    resize: function() {
      var scale = this.obj.width() / this.cfg.origWidth;
      this.cfg.scale = scale;
    },
    option: function(opt, value) {
      if (typeof value !== 'undefined') {
        this.cfg[opt] = value;
      }
    },
    _onDraw: function() {
      if (typeof this.cfg.onDraw === 'function') {
        this.cfg.onDraw(this.obj);
      }
    },
    _onStop: function() {
      if (typeof this.cfg.onStop === 'function') {
        this.cfg.onStop(this.obj);
      }
    },
  };

  Sketch.defaults = Sketch.prototype.defaults;

  $.fn.sketch = function(option, params, extra) {
    return this.each(function() {
      var instance = $(this).data('sketch');
      var _this = $(this);
      if (instance) {
        if (typeof instance[option] === 'function') {
          instance[option](params, extra);
        }
      } else {
        $(this).data('sketch', new Sketch($(this), option));
        $(window).resize(function() {
          setTimeout(function() {
            _this.data('sketch').resize();
          }, 500);
        });
      }
    });
  };

  window.Sketch = Sketch;
})($, window, document);

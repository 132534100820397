import * as React from 'react';
import AuditDashboardContainer from '../../../containers/AuditDashboard';
import { Company, Crew, Form, Site, User } from '../../../types';

interface Props {
  company: Company;
  users: User[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
}

const AuditDashboard = (props: Props) => {
  return (
    <div>
      <AuditDashboardContainer {...props} />
    </div>
  );
};

export default AuditDashboard;

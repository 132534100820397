import { createAction } from 'typesafe-actions';
import { Site, PermissionsError } from '../../types';
import {
  COMPANY_GROUP_SITES_FETCH_SUCCESS,
  COMPANY_GROUP_SITES_ADD,
  COMPANY_GROUP_SITES_REMOVE,
  COMPANY_GROUP_SITES_FETCH_FAILURE,
  COMPANY_GROUP_SITES_FETCH_REQUEST,
} from '../../constants/actionTypes';

export const companyGroupSitesFetchRequest = createAction(
  COMPANY_GROUP_SITES_FETCH_REQUEST,
  resolve => (companyGroupUid: string) => resolve(companyGroupUid),
);

export const companyGroupSitesFetchSuccess = createAction(
  COMPANY_GROUP_SITES_FETCH_SUCCESS,
  resolve => (sites: Site[]) => resolve(sites),
);

export const companyGroupSitesFetchFailure = createAction(
  COMPANY_GROUP_SITES_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const companyGroupAddSite = createAction(
  COMPANY_GROUP_SITES_ADD,
  resolve => (site: Site) => resolve(site),
);

export const companyGroupRemoveSite = createAction(
  COMPANY_GROUP_SITES_REMOVE,
  resolve => (siteUid: string) => resolve(siteUid),
);

import * as React from 'react';
import Tabs from '../components/Tabs';
import Sidebox, { SideboxContent } from '../components/Sidebox';
import SideboxHeader from '../components/sidebox/SideboxHeader';
import { Crew, User, Company } from '../types';
import CrewOverview from './crewSidebox/CrewOverview';
import CrewSites from './crewSidebox/CrewSites';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import CrewCompanies from './crewSidebox/CrewCompanies';
import InlineToggle from '../components/InlineToggle';
import Trash from '../svg/Trash';
import { useMountVisibility } from '../helpers/hooks';
import ConfirmationDialogue from '../components/ConfirmationDialogue';
import crewApi from '../api/crew.api';
import {
  getCrewLabel,
  getSiteLabelPlural,
  getDivisionLabelPlural,
} from '../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  crew: Crew;
  handleClose: () => void;
  users: User[];
  companies?: Company[];
  tabs: string[];
  onDelete?: () => void;
  buttons?: any[];
}

const CrewSidebox = ({
  isOpen,
  crew,
  handleClose,
  t,
  users,
  tabs,
  companies,
  onDelete,
  buttons,
}: Props) => {
  const visibleTabs = [
    ...(tabs.indexOf('overview') !== -1
      ? [
          {
            label: t('super:screens.crews.overview'),
            component: <CrewOverview crew={crew} users={users} />,
          },
        ]
      : []),
    ...(tabs.indexOf('sites') !== -1
      ? [
          {
            label: `${getSiteLabelPlural()}`,
            component: <CrewSites crew={crew} />,
          },
        ]
      : []),
    ...(tabs.indexOf('companies') !== -1 && companies
      ? [
          {
            label: `${getDivisionLabelPlural()}`,
            component: <CrewCompanies crew={crew} companies={companies} />,
          },
        ]
      : []),
  ];

  const trashModal = useMountVisibility();
  const [deleting, setDeleting] = React.useState(false);

  const onTrash = async () => {
    setDeleting(true);
    const { data } = await crewApi.deactivate(crew.uid);
    if (data && onDelete) {
      onDelete();
    }
    setDeleting(false);
  };

  const actionButtons = [
    ...(buttons ? buttons : []),
    ...(onDelete
      ? [
          {
            Icon: Trash,
            label: `Trash ${getCrewLabel()}`,
            onClick: trashModal.open,
          },
        ]
      : []),
  ];

  return (
    <Sidebox
      isOpen={isOpen}
      contentLabel={`${getCrewLabel()} Detail`}
      handleClose={handleClose}
    >
      {crew && (
        <div>
          <SideboxHeader handleClose={handleClose} buttons={actionButtons}>
            <h3>{crew ? crew.name : '-'}</h3>
          </SideboxHeader>
          <SideboxContent>
            <Tabs tabs={visibleTabs} />
          </SideboxContent>
          <ConfirmationDialogue
            contentLabel={`Trash ${getCrewLabel()}`}
            title={`Trash ${getCrewLabel()}?`}
            body={`Are you sure you wish to trash this ${getCrewLabel()}? You will be able to restore it from the trash at a later date.`}
            onConfirm={onTrash}
            isOpen={trashModal.visible}
            handleClose={trashModal.close}
            loading={deleting}
          />
        </div>
      )}
    </Sidebox>
  );
};

export default withNamespaces()(CrewSidebox);

import * as formActions from '../../actions/companyGroup/forms';
import { ActionType, getType } from 'typesafe-actions';
import { Form } from '../../types';
import { unique } from '../../helpers/index';

export type CompanyGroupFormAction = ActionType<typeof formActions>;

export interface CompanyGroupFormsState {
  data: Form[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyGroupUid: string;
}

const DEFAULT_STATE: CompanyGroupFormsState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  companyGroupUid: '',
};

export default function companyGroupFormsReducer(
  state: CompanyGroupFormsState = DEFAULT_STATE,
  action: CompanyGroupFormAction,
) {
  switch (action.type) {
    case getType(formActions.companyGroupFormsFetchRequest):
      return {
        ...state,
        loading: true,
        companyGroupUid: action.payload,
      };
    case getType(formActions.companyGroupFormsFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...unique('name', action.payload)],
      };
    case getType(formActions.companyGroupFormsFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
      };
    case getType(formActions.companyGroupAddForm):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(formActions.companyGroupRemoveForm):
      return {
        ...state,
        data: state.data.filter(form => form.uid !== action.payload),
      };
    default:
      return state;
  }
}

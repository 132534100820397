import { createAction } from 'typesafe-actions';
import { PermissionsError, User } from '../../types';
import {
  COMPANY_GROUP_USERS_FETCH_SUCCESS,
  COMPANY_GROUP_USERS_FETCH_REQUEST,
  COMPANY_GROUP_USERS_FETCH_FAILURE,
  COMPANY_GROUP_USERS_ADD,
  COMPANY_GROUP_USERS_REMOVE,
} from '../../constants/actionTypes';

export const companyGroupUsersFetchRequest = createAction(
  COMPANY_GROUP_USERS_FETCH_REQUEST,
  resolve => (companyGroupUid: string) => resolve(companyGroupUid),
);

export const companyGroupUsersFetchSuccess = createAction(
  COMPANY_GROUP_USERS_FETCH_SUCCESS,
  resolve => (users: User[]) => resolve(users),
);

export const companyGroupUsersFetchFailure = createAction(
  COMPANY_GROUP_USERS_FETCH_FAILURE,
  resolve => (errors: PermissionsError) => resolve(errors),
);

export const companyGroupAddUser = createAction(
  COMPANY_GROUP_USERS_ADD,
  resolve => (user: User) => resolve(user),
);

export const companyGroupRemoveUser = createAction(
  COMPANY_GROUP_USERS_REMOVE,
  resolve => (userUid: string) => resolve(userUid),
);

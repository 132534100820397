import SummaryCard from '../../../../components/SummaryCard';
import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../../../../constants';
import { biggerThanMD } from '../../../../helpers/style';
import User from '../../../../svg/User';
import UserGroup from '../../../../svg/UserGroup';
import Location from '../../../../svg/Location';
import File from '../../../../svg/File';
import { getCrewLabelPlural, getSiteLabelPlural } from '../../../../helpers';

const CardWrapper = styled.div`
  flex: 1;
  margin-bottom: ${cssVars.margin[4]};

  ${biggerThanMD(`
    margin-bottom: 0;
    margin-right: ${cssVars.margin[4]};
    
    &:last-of-type {
      margin-right: 0;
    }
  `)};
`;

interface Props {
  userCount: number;
  formCount: number;
  crewCount: number;
  siteCount: number;
}

const Summaries = ({ userCount, formCount, crewCount, siteCount }: Props) => (
  <div className="flex flex-col md:flex-row">
    <CardWrapper>
      <SummaryCard
        className={'bg-default'}
        icon={User}
        title={'Active Users'}
        stat={userCount}
      />
    </CardWrapper>
    <CardWrapper>
      <SummaryCard
        className={'bg-primary'}
        icon={UserGroup}
        title={`Active ${getCrewLabelPlural()}`}
        stat={crewCount}
      />
    </CardWrapper>
    <CardWrapper>
      <SummaryCard
        className={'bg-default'}
        icon={Location}
        title={`Active ${getSiteLabelPlural()}`}
        stat={siteCount}
      />
    </CardWrapper>
    <CardWrapper>
      <SummaryCard
        className={'bg-primary'}
        icon={File}
        title={'Active Forms'}
        stat={formCount}
      />
    </CardWrapper>
  </div>
);

export default Summaries;

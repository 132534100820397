import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../constants';
import { NavLink, Route } from 'react-router-dom';

const HeaderWrapper = styled.div`
  width: 100%;
  overflow: scroll;
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  margin-bottom: 12px;
`;

interface TabTriggerProps {
  active?: boolean;
}

const TabTrigger = styled(NavLink)<TabTriggerProps>`
  background: transparent;
  border: none;
  padding: 12px;
  cursor: pointer;
  opacity: 0.8;
  flex: 1;
  text-align: center;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  font-size: 1.25em;
  text-decoration: none;
  color: inherit;

  border-bottom: 2px solid transparent;

  &.active {
    border-bottom: 2px solid ${cssVars.colors.red};
  }

  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
`;

const TabContentWrapper = styled.div``;

export interface Tab {
  label: string;
  component: JSX.Element;
  href: string;
  exact?: boolean;
}

interface Props {
  tabs: Tab[];
}

export default class TabRoutes extends React.Component<Props> {
  public static defaultProps = {
    activeTab: 0,
  };

  public render() {
    const { tabs } = this.props;

    return (
      <div>
        <HeaderWrapper>
          {tabs.map(({ label, href, exact }, i) => (
            <TabTrigger key={i} to={href} exact={exact}>
              {label}
            </TabTrigger>
          ))}
        </HeaderWrapper>
        <TabContentWrapper>
          {tabs.map(({ href, component, exact }, i) => (
            <Route key={i} path={href} render={() => component} exact={exact} />
          ))}
        </TabContentWrapper>
      </div>
    );
  }
}

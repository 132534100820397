import * as React from 'react';
import { Crew, Site } from '../../types';
import crewApi from '../../api/crew.api';
import { toast } from 'react-toastify';
import Loading from '../../components/Loading';
import SideboxSectionContent from '../../components/sidebox/SideboxSectionContent';
import CategoryList from '../../components/CategoryList';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { getCrewLabel, getSiteLabelPlural } from '../../helpers';

interface State {
  sites: Site[];
  loading: boolean;
}

interface Props extends WithNamespaces {
  crew: Crew;
}

class CrewSites extends React.Component<Props, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      sites: [],
      loading: false,
    };
  }

  public componentDidMount() {
    this.getSites();
  }

  private getSites = async () => {
    const { crew, t } = this.props;

    this.setState({
      loading: true,
    });
    try {
      const { sites } = await crewApi.sites(crew.uid);

      this.setState({
        sites,
      });
    } catch (e) {
      toast.error(
        t(
          `Failed to fetch ${getCrewLabel().toLowerCase()}'s ${getSiteLabelPlural().toLowerCase()}`,
        ),
      );
    }

    this.setState({
      loading: false,
    });
  };

  public render() {
    const { loading, sites } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <Loading loading={loading} />
        <SideboxSectionContent>
          <CategoryList items={sites} itemLabelAccessor={'name'} />
        </SideboxSectionContent>
      </div>
    );
  }
}

export default withNamespaces()(CrewSites);

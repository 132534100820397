import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption } from '../../types';
import OptionTitle from './OptionTitle';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionText, getOptionTitle } from '../../helpers/formOption';
import WidgetContainer from './WidgetContainer';
import WidgetContent from './WidgetContent';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
}

const Instructions = ({ option, t, live }: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live }) || '';

  const text = getOptionText(option, { live });

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent>
        <div className="p-4 bg-yellow text-yellow-darkest">{text}</div>
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(Instructions));

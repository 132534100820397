import * as React from 'react';

interface Props {
  form: string;
  site: string;
  deployee: string;
  deployeeType?: string;
  user: string;
}

const NewSubmissionText = ({
  site,
  form,
  deployee,
  user,
  deployeeType,
}: Props) => {
  const forText =
    deployeeType !== 'site' ? (
      <>
        {' '}
        for the {deployeeType && deployeeType.toLowerCase()}{' '}
        <strong>{deployee}</strong>
      </>
    ) : null;

  return (
    <>
      <strong>{user}</strong> submitted a <strong>{form}</strong> at{' '}
      <strong>{site}</strong>
      {forText}.
    </>
  );
};

export default NewSubmissionText;

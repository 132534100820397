import * as React from 'react';
import * as R from 'ramda';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';
import styled from 'styled-components';
import {
  Company,
  Crew,
  User,
  UsersCrews,
  WorkRoleLabels,
  CompanyGroup,
} from '../../types';
import userApi from '../../api/user.api';
import { StoreState } from '../../store';
import Select from '../../components/Select';
import Label from '../../components/Label';
import Loading from '../../components/Loading';
import SideboxSectionContent from '../../components/sidebox/SideboxSectionContent';
import CategoryList from '../../components/CategoryList';
import crewApi from '../../api/crew.api';
import { Dispatch } from 'redux';
import { crewsFetchRequest } from '../../actions/crews';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import companyApi from '../../api/company.api';
import companyGroupApi from '../../api/companyGroup.api';
import { getCrewLabel } from '../../helpers';

const AddToCrewContainer = styled.div`
  margin-bottom: 25px;
`;

interface State {
  crews: UsersCrews[];
  allCrews: Crew[];
  selectedCrew: { label: string; value: string } | null;
  loadingMyCrews: boolean;
  loadingAllCrews: boolean;
}

interface Props extends WithNamespaces {
  user: User;
  company?: Company;
  companyGroup?: CompanyGroup;
}

class UserCrews extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);

    this.state = {
      crews: [],
      allCrews: [],
      loadingAllCrews: false,
      loadingMyCrews: false,
      selectedCrew: null,
    };
  }

  public componentDidMount() {
    this.getCrewsForUser();
    this.getAllCrews();
  }

  private loading = () =>
    this.state.loadingAllCrews && this.state.loadingMyCrews;

  private getCrewsForUser = async () => {
    const { company, companyGroup } = this.props;

    this.setState({
      loadingMyCrews: true,
    });

    if (company) {
      await this.getUserCrewsInCompany(company);
    } else if (companyGroup) {
      await this.getUserCrewsInCompanyGroup(companyGroup);
    }

    this.setState({
      loadingMyCrews: false,
    });
  };

  private getUserCrewsInCompany = async (company: Company) => {
    const { user } = this.props;
    const { data } = await userApi.crews(user.uid, company.uid);
    if (data) {
      this.setState({
        crews: data.crews,
      });
    }
  };

  private getUserCrewsInCompanyGroup = async (group: CompanyGroup) => {
    const { user } = this.props;
    const { data } = await userApi.crewsInCompanyGroup(user.uid, group.uid);
    if (data) {
      this.setState({
        crews: data.crews,
      });
    }
  };

  private getAllCrews = async () => {
    const { company, companyGroup } = this.props;

    this.setState({
      loadingAllCrews: true,
    });

    if (company) {
      await this.getCompanyCrews(company);
    } else if (companyGroup) {
      await this.getCompanyGroupCrews(companyGroup);
    }

    this.setState({
      loadingAllCrews: false,
    });
  };

  private getCompanyCrews = async (company: Company) => {
    const { data } = await companyApi.crews(company.uid);
    if (data) {
      this.setState({
        allCrews: data.crews,
      });
    }
  };

  private getCompanyGroupCrews = async (group: CompanyGroup) => {
    const { data } = await companyGroupApi.crews(group.uid);
    if (data) {
      this.setState({
        allCrews: data.crews,
      });
    }
  };

  private groupCrewsByRole = (crews: UsersCrews[]) =>
    R.groupBy(R.prop('role'), crews);

  private removeUserFromCrew = async (item: Crew) => {
    const { user, t } = this.props;
    const { crews } = this.state;

    this.setState({
      crews: crews.filter(i => i.uid !== item.uid),
    });

    try {
      await crewApi.removeUser(item.uid, user.uid);

      toast.success(`User removed from ${getCrewLabel().toLowerCase()}`);
    } catch (e) {
      toast.error(`Failed to remove user from ${getCrewLabel().toLowerCase()}`);
    }
  };

  private changeUserRoleInCrew = async (
    crew: Crew,
    newRole: WorkRoleLabels,
  ) => {
    const { crews } = this.state;
    const { user, t } = this.props;

    this.setState({
      crews: crews.map(c => {
        if (c.uid === crew.uid) {
          return {
            ...crew,
            role: newRole,
          };
        }
        return c;
      }),
    });

    try {
      await crewApi.attachUser(crew.uid, user.uid, newRole);

      toast.success(`Updated user role in ${getCrewLabel().toLowerCase()}`);
    } catch (e) {
      toast.error(t('super:screens.users.userCrewRoleUpdateErrorMessage'));
    }
  };

  private onCrewSelect = async (e: any) => {
    const { user, t } = this.props;

    this.setState({
      selectedCrew: e,
    });

    try {
      await crewApi.attachUser(e.value, user.uid);

      toast.success(`User added to ${getCrewLabel().toLowerCase()}`);

      this.getCrewsForUser();
    } catch (e) {
      toast.error(`Could not add user to ${getCrewLabel().toLowerCase()}`);
    }

    this.setState({
      selectedCrew: null,
    });
  };

  public render() {
    const { t, user } = this.props;
    const {
      selectedCrew,
      crews: usersCrews,
      allCrews: companyCrews,
    } = this.state;

    const crews = this.groupCrewsByRole(usersCrews);
    const myCrewUids = R.map(R.prop('uid'), usersCrews);
    const allCrews = R.filter<any>(
      (crew: any) => !R.contains(crew.value, myCrewUids),
      R.map(crew => ({ label: crew.name, value: crew.uid }), companyCrews),
    );

    return (
      <div style={{ position: 'relative' }}>
        <Loading loading={this.loading()} />
        <SideboxSectionContent>
          <AddToCrewContainer>
            <Label htmlFor={'addToCrewSelect'}>
              {t('super:screens.users.addUserToCrewTitle', {
                username: user.username,
                crew: getCrewLabel(),
              })}
            </Label>
            <Select
              id={'addToCrewSelect'}
              options={allCrews}
              tabIndex={this.loading() ? '-1' : undefined}
              onChange={this.onCrewSelect}
              value={selectedCrew}
            />
          </AddToCrewContainer>
          <CategoryList
            title={t('common:crewRoles.worker')}
            items={crews.worker}
            itemLabelAccessor={'name'}
            menuItems={[
              {
                label: t('common:delete'),
                onClick: item => this.removeUserFromCrew(item),
              },
              {
                label: t('common:crewRoles.foreman'),
                onClick: item => this.changeUserRoleInCrew(item, 'foreman'),
              },
              {
                label: t('common:crewRoles.manager'),
                onClick: item => this.changeUserRoleInCrew(item, 'manager'),
              },
            ]}
          />
          <CategoryList
            title={t('common:crewRoles.foreman')}
            items={crews.foreman}
            itemLabelAccessor={'name'}
            menuItems={[
              {
                label: t('common:delete'),
                onClick: item => this.removeUserFromCrew(item),
              },
              {
                label: t('common:crewRoles.worker'),
                onClick: item => this.changeUserRoleInCrew(item, 'worker'),
              },
              {
                label: t('common:crewRoles.manager'),
                onClick: item => this.changeUserRoleInCrew(item, 'manager'),
              },
            ]}
          />
          <CategoryList
            title={t('common:crewRoles.manager')}
            items={crews.manager}
            itemLabelAccessor={'name'}
            menuItems={[
              {
                label: t('common:delete'),
                onClick: item => this.removeUserFromCrew(item),
              },
              {
                label: t('common:crewRoles.worker'),
                onClick: item => this.changeUserRoleInCrew(item, 'worker'),
              },
              {
                label: t('common:crewRoles.foreman'),
                onClick: item => this.changeUserRoleInCrew(item, 'foreman'),
              },
            ]}
          />
        </SideboxSectionContent>
      </div>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    crewsLoading: state.crews.loading,
    crewsLoaded: state.crews.loaded,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    getCrews: (companyUid: string) => dispatch(crewsFetchRequest(companyUid)),
  };
}

export default withNamespaces()(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(UserCrews),
);

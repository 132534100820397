import * as React from 'react';
import Modal from '../../../components/Modal';
import ModalHeader from '../../../components/modal/ModalHeader';
import ModalBody from '../../../components/modal/ModalBody';
import InlineSelect from '../../../components/InlineSelect';
import InlineInput from '../../../components/InlineInput';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import Cog from '../../../svg/Cog';
import CheckCircle from '../../../svg/CheckCircle';
import File from '../../../svg/File';
import {
  useRequiredSelectInput,
  useSimpleFetch,
  useTextInput,
  useOptionalSelectInput,
} from '../../../helpers/hooks';
import companyApi from '../../../api/company.api';
import { Company, Checklist } from '../../../types';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { History } from 'history';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  history: History;
}

const ChecklistCreate = ({ isOpen, handleClose, company, history }: Props) => {
  const templates = useSimpleFetch<Checklist[]>({
    initial: [],
    accessor: 'checklistTemplates',
    fetch: () => companyApi.checklistTemplates(company.uid),
    change: [company.uid],
  });
  const getTypeOptions = () => [
    { label: 'New', value: 'new' },
    ...(templates.value.length > 0
      ? [{ label: 'From Template', value: 'template' }]
      : []),
  ];
  const type = useRequiredSelectInput(getTypeOptions()[0]);
  const template = useOptionalSelectInput();
  const name = useTextInput();
  const [errors, setErrors] = React.useState<any>({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(
    () => {
      if (template.value) {
        name.setValue(template.value.label);
      }
    },
    [template.selected],
  );

  const onSubmit = async () => {
    setLoading(true);
    const res = await companyApi.createChecklist(company.uid, {
      name: name.value,
      ...(template.selected ? { templateUid: template.selected } : {}),
    });

    if (res.data) {
      history.push(`/dashboard/worker/checklists/${res.data.checklist.uid}`);
    }
    if (res.errors) {
      setErrors(res.errors);
    }
    setLoading(false);
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel={'Create Checklist'}
    >
      <ModalHeader handleClose={handleClose}>Create Checklist</ModalHeader>

      <ModalBody>
        <InlineSelect
          icon={<Cog />}
          options={getTypeOptions()}
          label={'Type'}
          menuPosition={'fixed'}
          menuPortalTarget={document.body}
          {...type}
        />
        {type.value.value === 'new' && (
          <InlineInput
            label={'Name'}
            icon={<CheckCircle />}
            {...name}
            errorMessage={errors.name}
          />
        )}
        {type.value.value === 'template' && (
          <InlineSelect
            label={'Template'}
            options={templates.value.map(x => ({
              label: x.name,
              value: x.uid,
            }))}
            menuPosition={'fixed'}
            menuPortalTarget={document.body}
            icon={<File />}
            errorMessage={errors.name}
            {...template}
          />
        )}
      </ModalBody>

      <ModalFooter right={true}>
        <Button
          type={'primary'}
          children={'Submit'}
          loading={loading}
          onClick={onSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default connect((state: StoreState) => ({
  company: state.me.company,
}))(ChecklistCreate);

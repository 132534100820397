import * as React from 'react';
import {
  useContentEditable,
  handleContentLengthErrors,
} from '../../helpers/hooks';
import {
  FormFabricatorProps,
  useWidgetChange,
  useWidgetMandatory,
  BasicLabel,
} from './common';
import BuilderButtons from './BuilderButtons';
import { useState, useEffect } from 'react';
import { SelectOptionObject } from '../../components/Select';
import { Creatable } from 'react-select';
import WidgetContent from '../form/WidgetContent';
import InlineToggle from '../../components/InlineToggle';
import { SelectType } from '../../types';
import { toast } from 'react-toastify';
import LengthErrorLabel from './LengthErrorLabel';
import { MAX_NAME_LENGTH } from '../../constants/index';
import { onContentEditablePaste } from '../../helpers';

type Props = FormFabricatorProps;

function Selection({
  widget,
  setWidgets,
  mandatory,
  formRevisionUid,
  openDelete,
  editErrors,
  setEditErrors,
}: Props) {
  const [isTooLong, setIsTooLong] = useState<boolean>(false);
  const [isTooShort, setIsTooShort] = useState<boolean>(false);
  const [value, setValue] = useState<SelectOptionObject[]>(
    widget.options
      ? widget.options.map(o => ({ value: o.toLowerCase(), label: o }))
      : [],
  );
  const [inputValue, setInputValue] = useState<string>('');

  const [selectType, setSelectType] = useState<SelectType>(
    widget.select_type ? widget.select_type : 'multi',
  );
  const { handleWidgetChange } = useWidgetChange({
    formRevisionUid,
    setWidgets,
    widget,
  });

  const { handleDone, handleBlurEvent } = useContentEditable(
    'title',
    handleWidgetChange,
  );

  const { checked, handleMandatoryChange } = useWidgetMandatory({
    widget,
    formRevisionUid,
    mandatory,
  });

  const title = widget.title;

  // https://react-select.com/creatable
  const components = {
    DropdownIndicator: null,
  };

  const createOption = (label: string) => ({
    label,
    value: label,
  });

  function handleChange(changedValue: any) {
    setValue(changedValue);
  }

  function handleInputChange(input: string) {
    setInputValue(input);
  }

  function handleKeyDown(event: any) {
    if (!inputValue) {
      return;
    }
    switch (event.key) {
      case 'Enter':
      case 'Tab':
        if (inputValue.length >= 64) {
          toast.error(
            'Could not add select option. Maximum character length is 64.',
          );
          return;
        }
        if (value.length >= 128) {
          toast.error(
            'Could not add select option. Maximum amount of options is 128.',
          );
          return;
        }
        setInputValue('');
        setValue([...value, createOption(inputValue)]);
        event.preventDefault();
    }
  }

  useEffect(
    () => {
      handleWidgetChange({
        options: value.map(v => v.label),
        select_type: selectType,
      });
    },
    [value, selectType],
  );

  useEffect(
    () => {
      if (isTooShort || isTooLong) {
        setEditErrors([...editErrors, widget.uid]);
        return;
      }
      setEditErrors(editErrors.filter(uid => uid !== widget.uid));
    },
    [isTooLong, isTooShort],
  );

  return (
    <WidgetContent>
      <div className="mb-4">
        <LengthErrorLabel
          isTooLong={isTooLong}
          isTooShort={isTooShort}
          maxCharLength={MAX_NAME_LENGTH}
        />
        <div className={'mb-2 flex flex-row space-between'}>
          <div className="w-full">
            <BasicLabel
              className={
                isTooShort || isTooLong
                  ? 'border-2 border-red border-solid rounded'
                  : ''
              }
              contentEditable={true}
              onInput={(event: React.KeyboardEvent<any>) =>
                handleContentLengthErrors(
                  event,
                  MAX_NAME_LENGTH,
                  isTooLong,
                  isTooShort,
                  setIsTooLong,
                  setIsTooShort,
                  handleDone,
                )
              }
              onPaste={(e: any) => onContentEditablePaste(e)}
              onBlur={handleBlurEvent}
            >
              {title}
            </BasicLabel>
          </div>
          <InlineToggle
            label={'Select Multiple?'}
            className="flex border-none items-center ml-2"
            checked={selectType === 'multi' ? true : false}
            onChange={() =>
              setSelectType(selectType === 'multi' ? 'single' : 'multi')
            }
          />
        </div>
        <p
          className={
            inputValue.length >= 64
              ? 'flex justify-end text-red mr-8 mb-4'
              : 'hidden'
          }
        >
          Maximum 64 characters
        </p>
        <Creatable
          components={components}
          inputValue={inputValue}
          isClearable={true}
          isMulti={true}
          menuIsOpen={false}
          onChange={handleChange}
          onInputChange={(input: string) => handleInputChange(input)}
          onKeyDown={handleKeyDown}
          placeholder="Type something and press enter..."
          value={value}
        />
      </div>
      <BuilderButtons
        handleMandatoryChange={handleMandatoryChange}
        checked={checked}
        openDelete={openDelete}
        id={widget.uid}
      />
    </WidgetContent>
  );
}

export default Selection;

import * as React from 'react';
import { useState, useEffect } from 'react';
import { useTextInput } from '../../helpers/hooks';
import { toast } from 'react-toastify';
import InlineInput from '../../components/InlineInput';
import User from '../../svg/User';
import Key from '../../svg/Key';
import formService from '../../services/forms.service';
import Loading from '../../components/Loading';

interface SignOffErrors {
  username?: string | string[];
  pin?: string | string[];
}

interface Props {
  instanceRevisionUid: string;
  setSubmitFunc: (func: any) => void;
  handleClose: () => void;
  getSignOffs: () => void;
}

function SafetyTekSignOff({
  instanceRevisionUid,
  setSubmitFunc,
  handleClose,
  getSignOffs,
}: Props) {
  const username = useTextInput();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState<SignOffErrors>({});
  const [pin, setPin] = useState<string>('');

  async function handleSubmit() {
    if (!username.value || !pin) {
      toast.error('Username and pin required');
      return;
    }
    setLoading(true);
    const { errors: resErrors } = await formService.userSignOff(
      instanceRevisionUid,
      { pin, username: username.value },
    );
    setLoading(false);
    if (resErrors) {
      if (resErrors.errorCode === '28') {
        toast.error('User is missing signature on their profile!');
        return;
      }
      setErrors(resErrors);
      return;
    }
    toast.success('Sign off successful!');
    getSignOffs();
    handleClose();
  }

  function handlePinInput(e: any) {
    const numberRegex = /^[0-9]+$/;

    if (
      e.currentTarget.value.match(numberRegex) ||
      e.currentTarget.value === ''
    ) {
      setErrors({ username: '', pin: '' });
      setPin(e.currentTarget.value);
    } else {
      setErrors({ username: '', pin: 'Pin must only contain numbers.' });
    }
  }

  useEffect(
    () => {
      setSubmitFunc(() => handleSubmit);
    },
    [instanceRevisionUid, username.value, pin],
  );

  return (
    <div>
      <Loading loading={loading} />
      <InlineInput
        icon={<User />}
        type={'username'}
        name={'username'}
        label={'Username'}
        errorMessage={errors.username}
        {...username}
      />
      <div>
        <InlineInput
          icon={<Key />}
          label={'PIN'}
          type={'password'}
          name={'PIN'}
          value={pin || undefined}
          onChange={(e: any) => handlePinInput(e)}
          errorMessage={errors.pin}
          maxLength={4}
        />
      </div>
    </div>
  );
}

export default SafetyTekSignOff;

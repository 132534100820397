import * as React from 'react';
import Sidebox from '../components/Sidebox';
import SideboxSectionHeader from '../components/sidebox/SideboxSectionHeader';
import Announcement from '../svg/Announcement';
import { Link } from 'react-router-dom';
import userApi from '../api/user.api';
import { User, NotifType, Company, CompanyGroup } from '../types';
import { StoreState } from '../store';
import { connect } from 'react-redux';
import moment from 'moment';
import UserGroup from '../svg/UserGroup';
import Checkmark from '../svg/Checkmark';
import { useEffect } from 'react';
import { unidashUrl } from '../helpers';

const getNotificationParams = (notification: NotifType) => {
  switch (notification.notificationType) {
    case 'document':
      return {
        text: (
          <>
            <strong>{notification.senderName}</strong> made the document{' '}
            <strong>{notification.documentName}</strong> available to you
          </>
        ),
        Icon: Announcement,
        background: 'green',
        link: unidashUrl(`/documents/${notification.documentUid}`),
        external: true,
      };
    case 'action_assigned':
      return {
        text: (
          <>
            <strong>{notification.assignerName}</strong> assigned an action to
            you
          </>
        ),
        Icon: Announcement,
        link: `/dashboard/worker/actions/${notification.actionUid}`,
        background: 'red',
      };
    case 'action_completed':
      return {
        text: (
          <>
            <strong>{notification.completerName}</strong> completed an action
          </>
        ),
        Icon: Announcement,
        link: `/dashboard/worker/actions/${notification.actionUid}`,
        background: 'green',
      };
    case 'crew_invite_accepted':
      return {
        text: (
          <>
            <strong>{notification.accepterName}</strong> accepted your
            invitation to join <strong>{notification.crewName}</strong>
          </>
        ),
        Icon: UserGroup,
        link: `/dashboard/worker/crew-manager`,
        background: 'green',
      };
    case 'checklist_shared':
      return {
        text: (
          <>
            <strong>{notification.sharerName}</strong> shared the checklist{' '}
            <strong>{notification.checklistName} with you</strong>
          </>
        ),
        Icon: Checkmark,
        link: `/dashboard/worker/checklists/${notification.checklistUid}`,
        background: 'green',
      };
    case 'reminder2':
      return {
        text: (
          <>
            Reminder to fill out the form{' '}
            <strong>{notification.form_name}</strong> for{' '}
            <strong>{notification.deployee_name}</strong> in{' '}
            <strong>{notification.company_name}</strong>
          </>
        ),
        Icon: Announcement,
        link: unidashUrl('/forms'),
        background: 'red',
      };
    default:
      return null;
  }
};

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  user: User;
  notificationCount: number;
  company: Company;
  companyGroup: CompanyGroup;
}

const Notifications = ({
  isOpen,
  handleClose,
  user,
  notificationCount,
}: Props) => {
  const [notifications, setNotifications] = React.useState<NotifType[]>([]);

  const getNotifications = async () => {
    const { data } = await userApi.notifications(user.uid);
    if (data) {
      setNotifications(data.notifications);
    }
  };

  useEffect(() => {
    if (isOpen) {
      getNotifications();
    }
  }, [notificationCount, isOpen]);

  return (
    <Sidebox
      isOpen={isOpen}
      contentLabel={'Notifications'}
      handleClose={handleClose}
    >
      <div className="flex flex-col h-full">
        <div className="flex-1 overflow-scroll">
          <SideboxSectionHeader title={'Notifications'} />
          {notifications.length === 0 ? (
            <div className="flex flex-col items-center">
              <p>You have no new notifications</p>
            </div>
          ) : (
            <div className="flex flex-col">
              {notifications.map(x => {
                const notif = getNotificationParams(x);
                if (notif === null) {
                  return null;
                }
                const { text, Icon, link, background, external } = notif;
                const El = external ? 'a' : Link;
                const className =
                  'flex flex-row items-center p-4 mb-4 cursor-pointer hover:bg-grey-light text-black no-underline';
                const props = external
                  ? {
                      href: link || '/',
                      target: '_blank',
                      className,
                      style: { ...(link ? {} : { pointerEvents: 'none' }) },
                    }
                  : {
                      href: link || '/',
                      to: link || '/',
                      className,
                      style: { ...(link ? {} : { pointerEvents: 'none' }) },
                    };
                return React.createElement(El, props, [
                  <div
                    className={`bg-${background} w-10 h-10 rounded-full flex items-center justify-center mr-4`}
                  >
                    <Icon className="w-5 h-5 text-white" />
                  </div>,
                  <div>
                    <h4 className="font-normal">{text}</h4>
                    <p className="text-sm text-grey-darker">
                      {moment.unix(x.created_at).fromNow()}
                    </p>
                  </div>,
                ]);
              })}
            </div>
          )}
        </div>
      </div>
    </Sidebox>
  );
};

export default connect((state: StoreState) => ({
  user: state.me.user,
  company: state.me.company,
  companyGroup: state.me.companyGroup,
}))(Notifications);

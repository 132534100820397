import * as React from 'react';
import ChevRightOutline from '../svg/ChevRightOutline';

interface Props {
  title: string;
  subtitle?: string;
  Icon: React.ComponentType<any>;
  onClick?: () => any | void;
}

export default class ResourceListItem extends React.Component<Props> {
  public render() {
    const { title, subtitle, Icon, onClick } = this.props;
    return (
      <div
        className="bg-grey-light hover:bg-grey-lighter flex flex-row items-center justify-between px-4 py-2 cursor-pointer mb-4"
        onClick={onClick}
        tabIndex={onClick ? 0 : -1}
      >
        <div className="flex flex-row items-center">
          <Icon className={'w-6 h-6 mr-4'} />
          <div>
            <h3 className={'font-normal'}>{title}</h3>
            <p className={'mt-1 text-sm text-grey-darkest'}>{subtitle}</p>
          </div>
        </div>
        <ChevRightOutline className={'w-6 h-6'} />
      </div>
    );
  }
}

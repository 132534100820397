import { createStore, combineReducers, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import meReducer, { MeState } from './reducers/me';
import superSiteFormsReducer, {
  SuperSiteFormState,
} from './reducers/super/siteForms';
import superSiteCrewsReducer, {
  SuperSiteCrewState,
} from './reducers/super/siteCrews';
import superSiteManagersReducer, {
  SuperSiteManagerState,
} from './reducers/super/siteManagers';
import sitesReducer, { SitesState } from './reducers/sites';
import createSagaMiddleware from 'redux-saga';
import rootSaga from './sagas/root';
import usersReducer, { UsersState } from './reducers/users';
import crewsReducer, { CrewsState } from './reducers/crews';
import formsReducer, { FormsState } from './reducers/forms';
import formOptionsReducer, { FormOptionsState } from './reducers/formOptions';
import { AUTH_USER_LOGOUT } from './constants/actionTypes';
import certificatesReducer, {
  CertificatesState,
} from './reducers/certificates';
import workRolesReducer, { WorkRolesState } from './reducers/workRoles';
import roleCertificateMatrixReducer, {
  RoleCertificateMatrixState,
} from './reducers/roleCertificateMatrix';
import companyGroupFormsReducer, {
  CompanyGroupFormsState,
} from './reducers/companyGroup/forms';
import companyGroupSitesReducer, {
  CompanyGroupSitesState,
} from './reducers/companyGroup/sites';
import companyGroupCrewsReducer, {
  CompanyGroupCrewsState,
} from './reducers/companyGroup/crews';
import companyGroupUsersReducer, {
  CompanyGroupUsersState,
} from './reducers/companyGroup/users';
import companyGroupCompaniesReducer, {
  CompanyGroupCompanyState,
} from './reducers/companyGroup/companies';
import submDataReducer, { SubmDataState } from './reducers/submData';
import uiReducer, { UiState } from './reducers/ui';
import formReducer, { FormState } from './reducers/form';
import siteFormsReducer, { SiteFormsState } from './reducers/siteForms';

export interface StoreState {
  me: MeState;
  ui: UiState;
  sites: SitesState;
  users: UsersState;
  crews: CrewsState;
  forms: FormsState;
  form: FormState;
  formOptions: FormOptionsState;
  siteForms: SiteFormsState;
  submData: SubmDataState;
  certificates: CertificatesState;
  workRoles: WorkRolesState;
  roleCertificateMatrix: RoleCertificateMatrixState;
  super: {
    siteForms: SuperSiteFormState;
    siteCrews: SuperSiteCrewState;
    siteManagers: SuperSiteManagerState;
  };
  companyGroup: {
    forms: CompanyGroupFormsState;
    sites: CompanyGroupSitesState;
    crews: CompanyGroupCrewsState;
    users: CompanyGroupUsersState;
    companies: CompanyGroupCompanyState;
  };
}

const sagaMiddleware = createSagaMiddleware();

const appReducer = combineReducers({
  me: meReducer,
  ui: uiReducer,
  sites: sitesReducer,
  users: usersReducer,
  crews: crewsReducer,
  forms: formsReducer,
  form: formReducer,
  formOptions: formOptionsReducer,
  siteForms: siteFormsReducer,
  submData: submDataReducer,
  certificates: certificatesReducer,
  workRoles: workRolesReducer,
  roleCertificateMatrix: roleCertificateMatrixReducer,
  super: combineReducers({
    siteForms: superSiteFormsReducer,
    siteCrews: superSiteCrewsReducer,
    siteManagers: superSiteManagersReducer,
  }),
  companyGroup: combineReducers({
    forms: companyGroupFormsReducer,
    sites: companyGroupSitesReducer,
    crews: companyGroupCrewsReducer,
    users: companyGroupUsersReducer,
    companies: companyGroupCompaniesReducer,
  }),
});

const rootReducer = (state: any, action: any) => {
  if (action.type === AUTH_USER_LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(sagaMiddleware)),
);

sagaMiddleware.run(rootSaga);

export default store;

import * as React from 'react';
import Sidebox from '../components/Sidebox';
import SideboxHeader from '../components/sidebox/SideboxHeader';
import Folder from '../svg/Folder';
import Trash from '../svg/Trash';
import Download from '../svg/Download';
import { colors } from '../tailwind';
import ReactLoading from 'react-loading';
import { useSimpleFetch, useMountVisibility } from '../helpers/hooks';
import userApi from '../api/user.api';
import { connect } from 'react-redux';
import { StoreState } from '../store';
import { User, Download as IDownload } from '../types';
import { localStorageKeys } from '../constants/index';
import downloadApi from '../api/download.api';
import ConfirmationDialogue from '../components/ConfirmationDialogue';
import File from '../svg/File';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  user: User;
}

const Downloads = ({ isOpen, handleClose, user }: Props) => {
  const downloads = useSimpleFetch<IDownload[]>({
    initial: [],
    change: [user.uid],
    accessor: 'downloads',
    fetch: () => userApi.downloads(user.uid),
  });
  const deleteAllState = useMountVisibility();
  const [deleting, setDeleting] = React.useState(false);
  const fetchInterval = React.useRef<any>(null);

  React.useEffect(
    () => {
      if (isOpen) {
        downloads.performFetch();
      }
    },
    [isOpen],
  );

  const token = localStorage.getItem(localStorageKeys.USER_TOKEN);

  const deleteSingle = async (uid: string) => {
    downloads.setValue(downloads.value.filter(x => x.uid !== uid));
    downloadApi.delete(uid);
  };

  const deleteAll = async () => {
    setDeleting(true);
    await userApi.deleteAllDownloads(user.uid);
    downloads.setValue([]);
    deleteAllState.close();
    setDeleting(false);
  };

  React.useEffect(
    () => {
      if (isOpen) {
        fetchInterval.current = setInterval(() => {
          downloads.performFetch();
        }, 5000);
      } else {
        clearInterval(fetchInterval.current);
      }

      return () => {
        clearInterval(fetchInterval.current);
      };
    },
    [isOpen],
  );

  return (
    <Sidebox
      isOpen={isOpen}
      contentLabel={'Downloads'}
      handleClose={handleClose}
    >
      <SideboxHeader
        handleClose={handleClose}
        children={'My Downloads'}
        buttons={[
          { label: 'Delete All', Icon: Trash, onClick: deleteAllState.open },
        ]}
      />

      <div className="flex flex-col">
        {downloads.value.map(d => (
          <div
            key={d.uid}
            className="flex flex-row items-stretch border-0 border-b-1 border-border border-solid"
          >
            <div className="p-4 flex-1 flex flex-row items-center overflow-hidden">
              {d.zip_name.endsWith('zip') ? (
                <Folder
                  className={`w-8 h-8 mr-4 ${
                    d.status === 2 ? 'text-grey-dark' : ''
                  }`}
                />
              ) : (
                <File
                  className={`w-8 h-8 mr-4 ${
                    d.status === 2 ? 'text-grey-dark' : ''
                  }`}
                />
              )}
              <div className={'overflow-hidden'}>
                <h3
                  className={`font-normal break-words ${
                    d.status === 2 ? 'text-grey-dark' : ''
                  }`}
                  style={{
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {d.zip_name}
                </h3>
                <p className={'text-grey-darker'}>
                  {d.size ? `${(d.size / 1024).toFixed(0)} KB` : '-'}
                </p>
              </div>
            </div>
            {d.status === 2 ? (
              <div className={'flex flex-row'}>
                <div
                  className={'flex flex-col items-center justify-center p-4'}
                >
                  <ReactLoading
                    type={'spin'}
                    color={colors.primary}
                    width={'1em'}
                    height={'1em'}
                  />
                </div>
                <button
                  className={
                    'bg-primary text-white border-0 h-full p-4 cursor-pointer hover:bg-red-light'
                  }
                  onClick={() => deleteSingle(d.uid)}
                >
                  <Trash className={'w-4 h-4'} />
                </button>
              </div>
            ) : (
              <div className="flex flex-start">
                <a
                  href={`${process.env.API_URL}/v1/download-url/${
                    d.uid
                  }?token=${token}`}
                  className={
                    'bg-grey no-underline border-0 h-full p-4 cursor-pointer flex items-center hover:bg-grey-lighter'
                  }
                  style={{ color: 'inherit' }}
                  target="_blank"
                >
                  <Download className={'w-4 h-4'} />
                </a>
                <button
                  className={
                    'bg-primary text-white border-0 h-full p-4 cursor-pointer hover:bg-red-light'
                  }
                  onClick={() => deleteSingle(d.uid)}
                >
                  <Trash className={'w-4 h-4'} />
                </button>
              </div>
            )}
          </div>
        ))}
      </div>
      <ConfirmationDialogue
        contentLabel="Confirm delete all downloads"
        title="Delete all downloads?"
        body="This will clear our all of your downloads"
        onConfirm={deleteAll}
        isOpen={deleteAllState.visible}
        handleClose={deleteAllState.close}
        loading={deleting}
      />
    </Sidebox>
  );
};

export default connect((store: StoreState) => ({
  user: store.me.user,
}))(Downloads);

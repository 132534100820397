import * as React from 'react';

interface Props {
  emptyTitle?: string;
  emptyMessage?: string;
}

const EmptyListMessage = ({ emptyMessage, emptyTitle }: Props) => {
  return (
    <div className="flex flex-col bg-white justify-center items-center p-4 py-10">
      <div className="md:w-1/2 text-center">
        <h3 className={'mb-2'}>
          {emptyTitle ? emptyTitle : `Looks like there's nothing here`}
        </h3>
        <p>
          {emptyMessage
            ? emptyMessage
            : 'If you think this is an error please contact an administrator'}
        </p>
      </div>
    </div>
  );
};

export default EmptyListMessage;

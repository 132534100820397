import * as React from 'react';
import SideNavigation from '../components/SideNavigation';
import User from '../svg/User';
import styled from 'styled-components';
import { biggerThanMD } from '../helpers/style';
import NewsPaper from '../svg/NewsPaper';
import UserGroup from '../svg/UserGroup';
import Desktop from '../svg/Desktop';
import { Route } from 'react-router';
import { connect } from 'react-redux';
import { StoreState } from '../store';
import { Company as ICompany } from '../types';
import { withNamespaces } from 'react-i18next';
import { TranslationFunction } from 'i18next';
import Loading from '../components/Loading';
import Suitcase from '../svg/Suitcase';
import { FabAction, Fab } from '../components/Fab';
import Menu from '../svg/Menu';
import { cssVars } from '../constants/index';
import { History } from 'history';
import Dashboard from './companyAdmin/Dashboard';
import Companies from './companyAdmin/Companies';
import Forms from './companyAdmin/Forms';
import Crews from './companyAdmin/Crews';
import Users from './companyAdmin/Users';
import { getCrewLabelPlural, getDivisionLabelPlural } from '../helpers';

const DashboardContainer = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;

  ${biggerThanMD(`
    flex-direction: row;
  `)};
`;

const DashboardScrollingContent = styled.div`
  flex: 1;
  position: relative;
  -webkit-overflow-scrolling: touch;
  overflow: visible;

  ${biggerThanMD(`
    overflow: scroll;
  `)};
`;

interface Props {
  company: ICompany;
  t: TranslationFunction;
  history: History;
}

class CompanyAdmin extends React.Component<Props> {
  public render() {
    if (!this.props.company.uid) {
      return null;
    }

    const { t } = this.props;

    const routes = [
      {
        label: t('common:dashboard'),
        icon: <Desktop />,
        path: '/dashboard/company-admin/dashboard',
      },
      {
        label: `${getDivisionLabelPlural()}`,
        icon: <Suitcase />,
        path: '/dashboard/company-admin/divisions',
      },
      {
        label: t('common:forms'),
        icon: <NewsPaper />,
        path: '/dashboard/company-admin/forms',
      },
      {
        label: `${getCrewLabelPlural()}`,
        icon: <UserGroup />,
        path: '/dashboard/company-admin/crews',
      },
      {
        label: t('common:users'),
        icon: <User />,
        path: '/dashboard/company-admin/users',
      },
    ];

    return (
      <DashboardContainer>
        <SideNavigation routes={routes} />
        <DashboardScrollingContent>
          <React.Suspense fallback={<Loading loading={true} />}>
            <Route
              path={'/dashboard/company-admin/dashboard'}
              component={Dashboard}
            />
            <Route path={'/dashboard/company-admin/forms'} component={Forms} />
            <Route
              path={'/dashboard/company-admin/divisions'}
              component={Companies}
            />
            <Route path={'/dashboard/company-admin/crews'} component={Crews} />
            <Route path={'/dashboard/company-admin/users'} component={Users} />
          </React.Suspense>
          <div className={'md:hidden'}>
            <Fab
              event={'click'}
              mainButtonStyles={{
                backgroundColor: cssVars.colors.red,
              }}
              position={{
                bottom: 0,
                right: 0,
              }}
              icon={<Menu className="w-6 h-6" />}
            >
              {routes.map(x => (
                <FabAction
                  key={x.path}
                  style={{
                    backgroundColor: cssVars.colors.grey,
                  }}
                  text={x.label}
                  onClick={() => this.props.history.push(x.path)}
                >
                  {React.cloneElement(x.icon, {
                    className: 'w-6 h-6 text-black',
                  })}
                </FabAction>
              ))}
            </Fab>
          </div>
        </DashboardScrollingContent>
      </DashboardContainer>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
  };
}

export default withNamespaces()<any>(connect(mapStateToProps)(CompanyAdmin));

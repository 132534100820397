import * as React from 'react';
import WidgetContent from '../form/WidgetContent';
import WidgetContainer from '../form/WidgetContainer';
import {
  Widget,
  ResponseComment,
  ResponseFile,
  SelectionResponse,
  UserResponse,
  Company,
  User as IUser,
} from '../../types';
import { mandatoryErrorStyles, biggerThanMD } from '../../helpers/style';
import WidgetMetaButtons from './WidgetMetaButtons';
import Select, { SelectOptionObject } from '../../components/Select';
import { useState, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { debounce } from 'lodash';
import formService from '../../services/forms.service';
import { selectTypes } from '../../constants';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { Dispatch } from 'redux';
import { usersFetchRequest } from '../../actions/users';
import { UsersState } from '../../reducers/users';
import { useEntityRefresh } from '../../helpers/hooks';
import { getFullName } from '../../helpers';
import RequiredWidget from './RequiredWidget';
import Avatar from '../../components/Avatar';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

interface Props {
  widget: Widget;
  response: UserResponse;
  comments: ResponseComment[];
  files: ResponseFile[];
  editable?: boolean;
  instanceRevUid: string;
  error?: boolean;
  company: Company;
  users: UsersState;
  getUsers: (companyUid: string) => any;
}

function User({
  widget,
  response,
  comments,
  files,
  editable,
  instanceRevUid,
  error,
  company,
  users,
  getUsers,
}: Props) {
  useEntityRefresh(company.uid, users, getUsers);
  const [selectedUserUids, setSelectedUserUids] = useState<string[]>(
    response && response.user_selections ? response.user_selections : [],
  );
  const [userUidUserLookup, setUserUidUserLookup] = useState<{
    [key: string]: IUser;
  }>({});

  const updateServer = useCallback(
    debounce(
      async (selections: string[]) =>
        await formService.setWidgetResponse(instanceRevUid, widget.uid, {
          user_selections: selections,
        }),
      800,
    ),
    [instanceRevUid, widget.uid],
  );

  useEffect(
    () => {
      updateServer(selectedUserUids);
    },
    [selectedUserUids],
  );

  useEffect(
    () => {
      if (response === null) {
        return;
      }
      setSelectedUserUids(response.user_selections);
    },
    [response],
  );

  useEffect(
    () => {
      if (users.data.length === 0) {
        return;
      }
      const userObj: { [key: string]: IUser } = {};
      for (const user of users.data) {
        userObj[user.uid] = user;
      }
      setUserUidUserLookup(userObj);
    },
    [users.data],
  );

  return (
    <WidgetContainer>
      <WidgetContent
        style={
          error && selectedUserUids.length === 0 ? mandatoryErrorStyles : {}
        }
        className="bg-white mt-4 rounded"
      >
        <div className={'mb-4'}>
          <Wrapper>
            <label>
              {widget.title}
              {widget.mandatory && <RequiredWidget />}
            </label>
          </Wrapper>
          <Select
            options={users.data.map((option: any) => ({
              value: option.uid,
              label: getFullName(option),
            }))}
            multi={widget.select_type === 'multi'}
            onChange={(selection: SelectOptionObject | SelectOptionObject[]) =>
              widget.select_type === selectTypes.SINGLE
                ? setSelectedUserUids([(selection as SelectOptionObject).value])
                : setSelectedUserUids(
                    (selection as SelectOptionObject[]).map(
                      (selected: SelectOptionObject) => selected.value,
                    ),
                  )
            }
            value={selectedUserUids.map((val: string) => ({
              value: val.toLowerCase(),
              label: userUidUserLookup[val]
                ? getFullName(userUidUserLookup[val])
                : val,
            }))}
            disabled={!editable}
          />
          {selectedUserUids.length !== 0 &&
            selectedUserUids.map(uid => {
              const user = userUidUserLookup[uid];
              if (!user) {
                return null;
              }
              const fullName = getFullName(user);
              return (
                <div
                  key={uid}
                  className="flex flex-col p-4 border-solid border-grey border-1 mt-4"
                >
                  <div className="flex flex-row justify-between items-center">
                    <div className="flex items-center">
                      <Avatar
                        fallbackName={fullName}
                        bgColor={user.profile_color[0]}
                        fgColor={user.profile_color[1]}
                        size={'MD'}
                        user={user}
                      />
                      <h3 className={'font-normal'}>{fullName}</h3>
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
        <WidgetMetaButtons
          editable={editable}
          files={files}
          widget={widget}
          instanceRevUid={instanceRevUid}
          comments={comments}
        />
      </WidgetContent>
    </WidgetContainer>
  );
}

const mapState = (state: StoreState) => ({
  company: state.me.company,
  users: state.users,
});

const mapDispatch = (dispatch: Dispatch) => ({
  getUsers: (companyUid: string) => dispatch(usersFetchRequest(companyUid)),
});

export default connect(
  mapState,
  mapDispatch,
)(User);

import * as React from 'react';
import { makeGetChildren, makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, ParsedSubmData } from '../../types';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import WidgetContainer from './WidgetContainer';
import WidgetContent from './WidgetContent';
import styled from 'styled-components';
import { cssVars } from '../../constants/index';
import { biggerThanMD } from '../../helpers/style';
import Comment from '../../svg/Comment';
import Announcement from '../../svg/Announcement';
import Camera from '../../svg/Camera';
import TextArea from '../../components/TextArea';
import FormActionCreate from './FormActionCreate';
import { useMountVisibility } from '../../helpers/hooks';
import { useState } from 'react';
import InspectionImageModal from './InspectionImageModal';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { attempt } from '../../helpers/index';
import submissionApi from '../../api/submission.api';
import { updateSubmData } from '../../actions/submData';
import { Dispatch } from 'redux';
import { debounce } from 'lodash';
import ActionPreview from './ActionPreview';
import { UsersState } from '../../reducers/users';
import { FormState } from '../../reducers/form';
import { setFormMeta } from '../../actions/form';
import { get } from 'lodash';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${cssVars.colors.border};

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

const Label = styled.label`
  padding: ${cssVars.padding[4]};
  ${biggerThanMD(`
    padding: 0 ${cssVars.padding[4]};
  `)};
`;

const ButtonWrapper = styled.div`
  width: 100%;
  ${biggerThanMD(`
    width: auto;
  `)};
`;

const AnswerButton = styled.button<{ bg?: string }>`
  background: ${props => (props.bg ? props.bg : cssVars.colors.border)};
  border: none;
  border-left: 1px solid black;
  padding: ${cssVars.padding[4]};
  cursor: pointer;
  flex: 1 1 0%;

  ${biggerThanMD(`
    flex: 1;
  `)} &:first-of-type {
    border-left: 0;
  }
`;

const InspectionButton = styled.button<{ bg?: string }>`
  background: transparent;
  border: none;
  padding: 0px;
  padding-right: 5px;
  cursor: pointer;
  flex: 1 1 0%;
  position: relative;

  ${biggerThanMD(`
    flex: 0;
  `)} &:first-of-type {
    border-left: 0;
  }

  svg {
    padding: 1px;
    fill: ${props => (props.bg ? props.bg : cssVars.colors['grey-darker'])};
  }
`;

const ImageCounter = styled.div`
  background: ${cssVars.colors.red};
  border-radius: 50%;
  border-color: ${cssVars.colors.red};
  border-width: 2px;
  border-style: solid;
  color: #fff;
  width: 15px;
  height: 15px;
  position: absolute;
  top: 0%;
  right: -13%;
  line-height: 12px;
`;

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  live?: boolean;
  submData?: ParsedSubmData;
  submUid?: string;
  editable?: boolean;
  updateSubmData: (submData: ParsedSubmData) => any;
  users?: UsersState;
  setFormMeta: (meta: Partial<FormState>) => any;
}

const DEFAULT_VALUE = {
  answer: '',
  comment: '',
  images: {},
};

const Inspection = ({
  option,
  editable,
  submData,
  submUid,
  live,
  setFormMeta: pSetFormMeta,
  updateSubmData: pUpdateSubmData,
}: Props) => {
  if (!option) {
    return null;
  }

  const text = attempt(() => JSON.parse(option.text || '')) || {};
  const title = getOptionTitle(option);

  const [value, setValue] = useState(DEFAULT_VALUE);
  const comment = useMountVisibility();
  const action = useMountVisibility();
  const image = useMountVisibility();

  React.useEffect(() => {
    if (submData && submData.parsed) {
      setValue({
        ...DEFAULT_VALUE,
        ...submData.parsed,
      });
    }
  }, []);

  const updateServer = React.useCallback(
    debounce(async ({ comment = '', answer = '' }: any) => {
      const res = await submissionApi.updateFormOption(submUid!, option.id, {
        comment,
        answer,
        // images: Object.keys(val.images).map(key => ({
        //   key,
        //   comment: val.images[key].comment,
        // })),
      });
      if (!submData && res.data) {
        pUpdateSubmData(res.data.submData);
      }
    }, 500),
    [],
  );

  const setAndSyncValue = (val: any) => {
    setValue(val);
    updateServer(val);
  };

  const onActionCreate = async () => {
    action.close();
    if (!submUid) {
      return;
    }
    const { data } = await submissionApi.actions(submUid);
    if (data) {
      pSetFormMeta({ actions: data.actions });
    }
  };

  const imageCount = Object.keys(get(submData, 'parsed.images', {})).length;

  return (
    <WidgetContainer>
      <WidgetContent>
        <div className={'mb-4'}>
          <Wrapper>
            <Label>{title}</Label>
            <ButtonWrapper className="flex flex-nowrap">
              <AnswerButton
                bg={value.answer === 'y' ? cssVars.colors.green : ''}
                disabled={!editable}
                onClick={() => setAndSyncValue({ ...value, answer: 'y' })}
              >
                {text.y || 'Yes'}
              </AnswerButton>
              <AnswerButton
                bg={value.answer === 'n' ? cssVars.colors.red : ''}
                disabled={!editable}
                onClick={() => setAndSyncValue({ ...value, answer: 'n' })}
              >
                {text.n || 'No'}
              </AnswerButton>
              <AnswerButton
                bg={value.answer === 'na' ? cssVars.colors.default : ''}
                disabled={!editable}
                onClick={() => setAndSyncValue({ ...value, answer: 'na' })}
              >
                {text.na || 'N/A'}
              </AnswerButton>
            </ButtonWrapper>
          </Wrapper>
        </div>
        <div>
          <InspectionButton
            onClick={comment.visible ? comment.close : comment.open}
            type={'transparent'}
            className="mb-2 border-l-0"
            bg={value.comment !== '' ? cssVars.colors.red : ''}
          >
            <Comment className={'w-8 h-8'} />
          </InspectionButton>
          <InspectionButton
            onClick={action.open}
            type={'transparent'}
            className="mb-2 border-l-0"
            disabled={!live || !editable}
          >
            <Announcement className={'w-8 h-8'} />
          </InspectionButton>
          <InspectionButton
            onClick={image.open}
            type={'transparent'}
            className="mb-2 border-l-0"
            bg={imageCount !== 0 ? cssVars.colors.red : ''}
          >
            <Camera className={'w-8 h-8'} />
            <ImageCounter className={imageCount !== 0 ? '' : 'hidden'}>
              {value.images ? imageCount : ''}
            </ImageCounter>
          </InspectionButton>
        </div>
        <div className="mt-2">
          <TextArea
            className={comment.visible ? '' : 'hidden'}
            value={value.comment}
            onChange={e =>
              setAndSyncValue({ ...value, comment: e.currentTarget.value })
            }
            disabled={!live || !editable}
          />
          {action.mounted && (
            <FormActionCreate
              isOpen={action.visible}
              handleClose={action.close}
              formOptionId={option.id}
              onCreate={onActionCreate}
            />
          )}
          {image.mounted && (
            <InspectionImageModal
              optionId={option.id}
              isOpen={image.visible}
              contentLabel={'Image Modal'}
              handleClose={image.close}
              option={option}
              submData={submData}
              live={live}
              editable={editable}
              submUid={submUid}
            />
          )}
          <ActionPreview optionId={option.id} />
        </div>
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getChildren = makeGetChildren();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    childOptions: getChildren(state, ownProps),
    submData: getSubmData(state, ownProps),
    editable: state.form.editable,
    submUid: state.form.submissionUid,
    users: state.users,
  });
}

function mapDispatch(dispatch: Dispatch) {
  return {
    updateSubmData: (submData: ParsedSubmData) =>
      dispatch(updateSubmData(submData)),
    setFormMeta: (meta: Partial<FormState>) => dispatch(setFormMeta(meta)),
  };
}

export default withNamespaces()(
  connect(
    makeMapStateToProps,
    mapDispatch,
  )(Inspection),
);

import { call, takeLatest, put } from 'redux-saga/effects';
import companyApi from '../api/company.api';
import { CERTIFICATES_FETCH_REQUEST } from '../constants/actionTypes';
import {
  certificatesFetchFailure,
  certificatesFetchSuccess,
} from '../actions/certificates';

export function* getCertificates(params: any) {
  try {
    const { certificates } = yield call(
      companyApi.certificates,
      params.payload,
    );
    yield put(certificatesFetchSuccess(certificates));
  } catch (e) {
    yield put(certificatesFetchFailure(e.errors));
  }
}

export function* watchGetCertificates() {
  yield takeLatest(CERTIFICATES_FETCH_REQUEST, getCertificates);
}

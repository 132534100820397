import styled from 'styled-components';
import { cssVars } from '../../constants';

const WidgetContainer = styled.div`
  margin-bottom: ${cssVars.margin[4]};

  &:last-of-type {
    margin-bottom: 0;
  }
`;

export default WidgetContainer;

import * as React from 'react';
import styled from 'styled-components';
import { cssVars, mediaQueries } from '../constants';
import { ReactNode } from 'react';
import { biggerThanSM, boxShadow, biggerThanMD } from '../helpers/style';
import { Colors } from '../types';
import { Link } from 'react-router-dom';
import { throttle } from 'lodash';

const Container = styled.div<{ stuck?: boolean }>`
  background: white;
  display: block;
  width: 100%;
  margin-bottom: 12px;

  ${boxShadow}

  @media screen and (max-width: ${mediaQueries.MD}px) {
    ${props =>
      props.stuck
        ? `
      position: fixed;
      width: 100%;
      top: 0;
      left: 0;
      z-index: 9;
    `
        : ''};
  }

  ${biggerThanSM(`
    flex-direction: row;
  `)};
`;

const Title = styled.h4`
  font-weight: normal;
  font-size: 1.2em;
`;

const LeftWrapper = styled.div`
  padding: 12px 16px;
  border-bottom: 1px solid #ebebeb;
  width: 100%;
  text-align: center;
  display: inline-flex;

  ${biggerThanSM(`
    border: none;
    text-align: left;
    width: auto;
  `)};
`;

const ActionButton = styled.button<{ type?: string }>`
  border: none;
  border-left: 1px solid #ebebeb;
  padding: 11px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;

  background: ${props => (props.type ? cssVars.colors[props.type] : 'white')};
  color: ${props => (props.type ? 'white' : 'black')};

  ${biggerThanSM(`
    padding: 13px;
  `)} ${biggerThanMD(`
    flex: none;
    padding: 16px;
  `)} &:hover {
    background: ${cssVars.colors.default};
    color: white;

    svg path {
      fill: white;
    }
  }

  & svg {
    width: 1em;
    height: auto;
    display: inline-block;
  }

  & svg + span {
    margin-left: 6px;
  }
`;

const RightWrapper = styled.div`
  display: inline-flex;
  float: right;
  align-items: stretch;
  max-width: 100%;
  overflow-x: scroll;

  ${biggerThanMD(`
    width: auto;
    overflow: visible;
  `)};
`;

interface Props {
  title: string;
  actions?: Array<{
    label?: string;
    icon?: ReactNode;
    type?: Colors;
    onClick?: () => void;
    disabled?: boolean;
    href?: string;
    external?: boolean;
  }>;
  sticky?: boolean;
}

interface State {
  stuck: boolean;
}

export default class PageToolbar extends React.Component<Props, State> {
  private container: any;
  private offsetTop: number;

  constructor(props: Props) {
    super(props);

    this.container = React.createRef();
    this.offsetTop = 0;
    this.state = {
      stuck: false,
    };
  }

  public componentDidMount() {
    if (this.props.sticky) {
      this.offsetTop = this.container.current.getBoundingClientRect().top;
      document.addEventListener('scroll', this.scrollListener);
    }
  }

  private scrollListener = throttle(() => {
    this.setState({
      stuck: window.pageYOffset > this.offsetTop,
    });
  }, 100);

  public componentWillUnmount() {
    document.removeEventListener('scroll', this.scrollListener);
  }

  public render() {
    const { title, actions } = this.props;

    return (
      <Container ref={this.container} stuck={this.state.stuck}>
        <LeftWrapper>
          <Title>{title}</Title>
        </LeftWrapper>

        {actions && (
          <RightWrapper>
            {actions.map(
              ({ label, icon, type, onClick, disabled, href, external }, i) => (
                <ActionButton
                  key={i}
                  type={type}
                  onClick={onClick}
                  disabled={disabled}
                  {...(href
                    ? {
                        as: external ? 'a' : Link,
                        to: external ? undefined : href,
                        href,
                        target: external ? '_blank' : undefined,
                      }
                    : {})}
                >
                  {icon}
                  {label && <span>{label}</span>}
                </ActionButton>
              ),
            )}
          </RightWrapper>
        )}
      </Container>
    );
  }
}

import * as React from 'react';
import Sidebox, { SideboxContent } from '../components/Sidebox';
import SideboxHeader from '../components/sidebox/SideboxHeader';
import Button from '../components/Button';
import CertificateCreate from './CertificateCreate';
import userApi from '../api/user.api';
import { Certificate, User, Storage } from '../types';
import UserCertificates from './UserCertificates';

interface Props {
  isOpen: boolean;
  handleClose: () => void;
  user: User;
}

const UserCertificatesSidebox = ({ isOpen, handleClose, user }: Props) => {
  const [createOpen, setCreateOpen] = React.useState(false);
  const [certs, setCerts] = React.useState<
    Array<{ cert: Certificate; image?: Storage }>
  >([]);

  const fetchCerts = async () => {
    const res = await userApi.certs(user.uid);
    if (res.data) {
      setCerts(res.data.certificates);
    }
  };

  React.useEffect(
    () => {
      if (user.uid !== '') {
        fetchCerts();
      }
    },
    [user.uid],
  );

  const onDelete = (cert: Certificate) => {
    setCerts(certs.filter(c => c.cert.uid !== cert.uid));
  };

  const onUpdate = () => {
    fetchCerts();
  };

  const onCertCreate = () => {
    setCreateOpen(false);
    fetchCerts();
  };

  return (
    <Sidebox
      isOpen={isOpen}
      contentLabel={'Viewing a users certificates'}
      handleClose={handleClose}
    >
      <SideboxHeader handleClose={handleClose}>
        {user.username}
        's Certificates
      </SideboxHeader>
      <SideboxContent>
        <div className="p-4">
          <Button
            type={'primary'}
            block={true}
            children={'Add Certificate'}
            className={'my-4'}
            onClick={() => setCreateOpen(true)}
          />

          <UserCertificates
            certs={certs}
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        </div>
      </SideboxContent>

      <CertificateCreate
        userUid={user.uid}
        isOpen={createOpen}
        handleClose={() => setCreateOpen(false)}
        onCreate={onCertCreate}
      />
    </Sidebox>
  );
};

export default UserCertificatesSidebox;

import * as React from 'react';
import { formFeatureIds, widgetsForDroppable } from '../../constants';
import QuestionAnswer from './QuestionAnswer';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { makeGetChildren, makeGetOption } from '../../selectors/formOptions';
import { FormOption } from '../../types';
import Selection from './Selection';
import YesNo from './YesNo';
import SignOff from './SignOff';
import ImportImage from './ImportImage';
import Instructions from './Instructions';
import Signature from './Signature';
import Sketch from './Sketch';
import Table from './Table';
import UserInput from './UserInput';
import Users from './Users';
import Audit from './Audit';
import Actions from './Actions';
import Button from '../../components/Button';
import { Droppable } from 'react-beautiful-dnd';
import { contains } from 'ramda';
import Inspection from './Inspection';

interface Props {
  optionId: number;
  childOptions?: FormOption[];
  openAddOption?: (optionId: number) => void;
  openDeleteOption?: (optionId: number) => void;
  formUid: string;
  canBeDeleted?: boolean;
  fid?: number;
  option?: FormOption;
}

export const FieldMap = {
  [formFeatureIds.QUESTION_ANSWER]: QuestionAnswer,
  [formFeatureIds.QUESTION]: YesNo,
  [formFeatureIds.IMAGE]: ImportImage,
  [formFeatureIds.INSTRUCTIONS]: Instructions,
  [formFeatureIds.SIGNATURE]: Signature,
  [formFeatureIds.SKETCH]: Sketch,
  [formFeatureIds.TABLE]: Table,
  [formFeatureIds.USER_INPUT]: UserInput,
  [formFeatureIds.USERS]: Users,
  [formFeatureIds.SIGN_OFF]: SignOff,
  [formFeatureIds.SELECTION]: Selection,
  [formFeatureIds.AUDIT]: Audit,
  [formFeatureIds.ACTIONS]: Actions,
  [formFeatureIds.INSPECTION]: Inspection,
};

class Column extends React.Component<Props> {
  private openAddWidget = () => {
    if (this.props.openAddOption) {
      this.props.openAddOption(this.props.optionId);
    }
  };

  private openDeleteOption = () => {
    if (this.props.openDeleteOption) {
      this.props.openDeleteOption(this.props.optionId);
    }
  };

  public render() {
    const {
      childOptions,
      openAddOption,
      openDeleteOption,
      formUid,
      canBeDeleted,
      optionId,
      fid,
      option,
    } = this.props;

    if (!option) {
      return;
    }

    return (
      <Droppable
        droppableId={`${optionId}`}
        isDropDisabled={!contains(fid, widgetsForDroppable[option.f_id])}
      >
        {provided => (
          <div ref={provided.innerRef}>
            {canBeDeleted && (
              <div className="flex justify-end p-4">
                <Button type={'primary'} onClick={this.openDeleteOption}>
                  Delete Column
                </Button>
              </div>
            )}
            {childOptions && childOptions.length > 0
              ? childOptions.map((i, x) => {
                  const Component = FieldMap[i.f_id];
                  if (!Component) {
                    return null;
                  }

                  return (
                    <Component
                      key={i.id}
                      optionId={i.id}
                      openDeleteOption={openDeleteOption}
                      formUid={formUid}
                      index={x}
                    />
                  );
                })
              : null}

            {openAddOption && (
              <div
                className="flex justify-center p-4 border-1 border-dashed border-default cursor-pointer"
                onClick={this.openAddWidget}
              >
                Add Widget
              </div>
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    );
  }
}

function makeMapStateToProps() {
  const getChildren = makeGetChildren();
  const getOption = makeGetOption();
  return (state: StoreState, ownProps: Props) => ({
    childOptions: getChildren(state, ownProps),
    option: getOption(state, ownProps),
    fid: state.formOptions.draggingFid,
  });
}

export default connect(makeMapStateToProps)(Column);

import * as React from 'react';
import Modal from '../../components/Modal';
import ModalHeader from '../../components/modal/ModalHeader';
import ModalBody from '../../components/modal/ModalBody';
import InlineInput from '../../components/InlineInput';
import Envelope from '../../svg/Envelope';
import InlineSelect from '../../components/InlineSelect';
import Cog from '../../svg/Cog';
import ModalFooter from '../../components/modal/ModalFooter';
import Button from '../../components/Button';
import Trash from '../../svg/Trash';
import { SelectOptionObject } from '../../components/Select';
import companyApi from '../../api/company.api';
import InlineAlert from '../../components/InlineAlert';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
  crewUid: string;
  companyUid: string;
  onSuccess?: () => void;
}

const CrewInvite = ({
  isOpen,
  handleClose,
  crewUid,
  companyUid,
  onSuccess,
}: Props) => {
  const roleOptions = [
    { label: 'Worker', value: 'worker' },
    { label: 'Foreman', value: 'foreman' },
    { label: 'Manager', value: 'manager' },
  ];
  const defaultInvite = { email: '', role: roleOptions[0] };
  const [invites, setInvites] = React.useState<
    Array<{ email: string; role: SelectOptionObject }>
  >([{ ...defaultInvite }]);
  const [loading, setLoading] = React.useState(false);
  const [hasError, setHasError] = React.useState(false);

  const addInvite = () => {
    setInvites([...invites, { ...defaultInvite }]);
  };

  const removeInvite = (idx: number) => {
    setInvites(invites.filter((x, i) => i !== idx));
  };

  const setInviteRole = (idx: number, role: SelectOptionObject) => {
    setInvites(invites.map((x, i) => (i === idx ? { ...x, role } : x)));
  };

  const setInviteEmail = (idx: number, email: string) => {
    setInvites(invites.map((x, i) => (i === idx ? { ...x, email } : x)));
  };

  const onSubmit = async () => {
    setLoading(true);
    const res = await companyApi.createCrewInvite(
      companyUid,
      crewUid,
      invites.map(x => ({ ...x, role: x.role.value })),
    );
    setLoading(false);
    if (res.data) {
      setInvites([]);
      setHasError(false);
      if (onSuccess) {
        onSuccess();
      }
    }
    if (res.errors) {
      setHasError(true);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      handleClose={handleClose}
      contentLabel="Invite External User to Crew"
    >
      <ModalHeader handleClose={handleClose}>Invite External User</ModalHeader>
      <ModalBody>
        {hasError && (
          <InlineAlert
            title={'Uh oh'}
            text={'One or more emails are invalid'}
            type={'danger'}
          />
        )}
        {invites.map((x, i) => (
          <div className="flex flex-row items-start">
            <div className="flex-1 flex flex-col" key={i}>
              <InlineInput
                label="email"
                icon={<Envelope />}
                value={x.email}
                onChange={e => setInviteEmail(i, e.currentTarget.value)}
              />
              <InlineSelect
                label="Role"
                icon={<Cog />}
                options={roleOptions}
                menuPortalTarget={document.body}
                menuPosition={'fixed'}
                value={x.role}
                onChange={e => setInviteRole(i, e as any)}
              />
            </div>
            {invites.length > 1 && (
              <Trash
                className={
                  'w-6 h-6 ml-4 text-red hover:text-default cursor-pointer'
                }
                onClick={() => removeInvite(i)}
              />
            )}
          </div>
        ))}
        <Button
          type={'primary'}
          children={'Add Another User'}
          onClick={addInvite}
        />
      </ModalBody>
      <ModalFooter right={true}>
        <Button
          type="default"
          children="Submit"
          loading={loading}
          onClick={onSubmit}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CrewInvite;

import * as React from 'react';
import Modal from '../../components/Modal';
import ModalHeader from '../../components/modal/ModalHeader';
import ModalBody from '../../components/modal/ModalBody';
import Tabs from '../../components/Tabs';
import SafetyTekSignOff from './SafetyTekSignOff';
import ThirdPartySignOff from './ThirdPartySignOff';
import { useState } from 'react';
import ModalFooter from '../../components/modal/ModalFooter';
import Button from '../../components/Button';

interface Props {
  title: string;
  contentLabel: string;
  handleClose: () => void;
  onConfirm: () => void;
  isOpen: boolean;
  loading?: boolean;
  instanceRevisionUid: string;
  getSignOffs: () => void;
}

const SignOffModal = ({
  contentLabel,
  handleClose,
  isOpen,
  title,
  onConfirm,
  instanceRevisionUid,
  getSignOffs,
}: Props) => {
  /**
   * todo, make safetyteksignoff/thirdpartysignoff have their own submit buttons
   * this is such an antipattern, am v sorry
   */
  const [submitFunc, setSubmitFunc] = useState<any>(null);

  return (
    <Modal
      contentLabel={contentLabel}
      handleClose={handleClose}
      isOpen={isOpen}
    >
      <ModalHeader handleClose={handleClose}>{title}</ModalHeader>
      <ModalBody>
        <div>
          <Tabs
            tabs={[
              {
                label: 'SafetyTek Account',
                component: (
                  <SafetyTekSignOff
                    instanceRevisionUid={instanceRevisionUid}
                    handleClose={handleClose}
                    setSubmitFunc={setSubmitFunc}
                    getSignOffs={getSignOffs}
                  />
                ),
              },
              {
                label: 'Third Party',
                component: (
                  <ThirdPartySignOff
                    instanceRevisionUid={instanceRevisionUid}
                    setSubmitFunc={setSubmitFunc}
                    handleClose={handleClose}
                    getSignOffs={getSignOffs}
                  />
                ),
              },
            ]}
          />
        </div>
      </ModalBody>
      {submitFunc && (
        <ModalFooter right={true}>
          <Button onClick={submitFunc} children="Sign Off" />
        </ModalFooter>
      )}
    </Modal>
  );
};

export default SignOffModal;

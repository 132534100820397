import * as React from 'react';
import { Company, Site, User, CompanyGroup } from '../../types';
import userApi from '../../api/user.api';
import Loading from '../../components/Loading';
import SideboxSectionContent from '../../components/sidebox/SideboxSectionContent';
import CategoryList from '../../components/CategoryList';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface State {
  sites: Site[];
  loadingSites: boolean;
}

interface Props extends WithNamespaces {
  user: User;
  company?: Company;
  companyGroup?: CompanyGroup;
}

class UserSites extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      sites: [],
      loadingSites: false,
    };
  }

  public componentDidMount() {
    this.getSites();
  }

  private getSites = async () => {
    const { company, companyGroup } = this.props;

    this.setState({
      loadingSites: true,
    });

    if (company) {
      await this.getCompanySites(company);
    } else if (companyGroup) {
      await this.getCompanyGroupSites(companyGroup);
    }

    this.setState({
      loadingSites: false,
    });
  };

  private getCompanySites = async (company: Company) => {
    const { user } = this.props;
    const { data } = await userApi.sites(user.uid, company.uid);
    if (data) {
      this.setState({
        sites: data.sites,
      });
    }
  };

  private getCompanyGroupSites = async (group: CompanyGroup) => {
    const { user } = this.props;
    const { data } = await userApi.sitesInCompanyGroup(user.uid, group.uid);
    if (data) {
      this.setState({
        sites: data.sites,
      });
    }
  };

  public render() {
    const { loadingSites, sites } = this.state;

    return (
      <div style={{ position: 'relative' }}>
        <Loading loading={loadingSites} />
        <SideboxSectionContent>
          <CategoryList items={sites} itemLabelAccessor={'name'} />
        </SideboxSectionContent>
      </div>
    );
  }
}

export default withNamespaces()(UserSites);

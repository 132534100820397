import * as React from 'react';
import {
  GoogleMap,
  withGoogleMap,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import { Site } from '../../../types';
import { Link } from 'react-router-dom';
import { getLocation } from '../../../helpers/location';
import { getSiteLabelPlural } from '../../../helpers';

const SiteMarker = ({
  position,
  onClick,
  onClose,
  site,
  isOpen,
}: {
  position: { lat: number; lng: number };
  onClick: (uid: string) => void;
  onClose: () => void;
  site: Site;
  isOpen: boolean;
}) => (
  <Marker position={position} onClick={() => onClick(site.uid)}>
    {isOpen && (
      <InfoWindow onCloseClick={onClose}>
        <div>
          <Link
            className="no-underline text-red"
            to={`/dashboard/worker/sites/${site.uid}`}
          >
            {site.name}
          </Link>
        </div>
      </InfoWindow>
    )}
  </Marker>
);

const Map = withGoogleMap(({ sites }: { sites: Site[] }) => {
  const map = React.useRef<any>(null);
  const [openMarker, setOpenMarker] = React.useState<null | string>(null);
  const [center, setCenter] = React.useState<any>(null);

  const setLocation = async () => {
    const location = await getLocation();
    if (location) {
      setCenter({
        lat: location.location.lat,
        lng: location.location.lng,
      });
    }
  };

  React.useEffect(() => {
    setLocation();
  }, []);

  return (
    <GoogleMap
      ref={map}
      defaultCenter={{ lat: 0, lng: 0 }}
      zoom={center ? 13 : 1}
      {...(center ? { center } : {})}
    >
      {sites.map(site => {
        const [lat, lng] = (site.location || '').split(', ');
        if (!lat || !lng) {
          return null;
        }
        return (
          <SiteMarker
            key={site.uid}
            position={{ lat: +lat, lng: +lng }}
            onClick={setOpenMarker}
            onClose={() => setOpenMarker(null)}
            isOpen={site.uid === openMarker}
            site={site}
          />
        );
      })}
    </GoogleMap>
  );
});

/**
 *
 */

const SiteMap = ({ sites }: { sites: Site[] }) => {
  return (
    <div className={'bg-white'}>
      <div className="p-4">
        <h3>My {getSiteLabelPlural()}</h3>
      </div>
      <div style={{ height: '300px' }}>
        <Map
          containerElement={<div className={'h-full'} />}
          mapElement={<div className={'h-full'} />}
          sites={sites}
        />
      </div>
    </div>
  );
};

export default SiteMap;

import * as React from 'react';
import TextArea from '../../components/TextArea';
import { useTextInput } from '../../helpers/hooks';
import Button from '../../components/Button';
import formService from '../../services/forms.service';
import { useState } from 'react';
import { ResponseComment } from '../../types';
import { toast } from 'react-toastify';
import LengthErrorLabel from '../formFabricator/LengthErrorLabel';
import { MAX_INPUT_FIELD_LENGTH } from '../../constants';

interface Props {
  widgetUid: string;
  instanceRevisionUid: string;
  onCreate: (comment: ResponseComment) => void;
  editable: boolean;
}

function CommentCreate({
  instanceRevisionUid,
  widgetUid,
  onCreate,
  editable,
}: Props) {
  const comment = useTextInput();
  const [loading, setLoading] = useState(false);
  const isTooLong = comment.value.length >= MAX_INPUT_FIELD_LENGTH;

  async function submit() {
    if (!comment.value || !editable) {
      return;
    }
    if (isTooLong) {
      toast.error(
        `Comment has exceeded the maximum character amount of ${MAX_INPUT_FIELD_LENGTH}.`,
      );
      return;
    }
    setLoading(true);
    const { data } = await formService.createComment(
      instanceRevisionUid,
      widgetUid,
      comment.value,
    );
    if (data) {
      comment.setValue('');
      onCreate({ comment: data.comment, creator: data.creator });
    }
    setLoading(false);
  }

  if (!editable) {
    return null;
  }

  return (
    <div>
      <LengthErrorLabel
        maxCharLength={MAX_INPUT_FIELD_LENGTH}
        isTooLong={isTooLong}
      />
      <TextArea
        placeholder={'Leave a note about this question...'}
        disabled={!editable}
        {...comment}
        maxLength={MAX_INPUT_FIELD_LENGTH}
      />
      <div className="my-4">
        <Button
          type="primary"
          loading={loading}
          onClick={submit}
          disabled={!editable}
        >
          Submit
        </Button>
      </div>
    </div>
  );
}

export default CommentCreate;

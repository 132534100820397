import { baseApi } from '../api/api';
import { get } from 'lodash';

export const entitiesApi = async <T>(
  method: string,
  url: string,
  data: any = {},
  options: any = {},
): Promise<{ data: T | undefined; errors: any | undefined }> => {
  try {
    const res = await baseApi(
      method,
      `${process.env.ENTITIES_URL}/${url}`,
      data,
      options,
    );
    return { data: res, errors: undefined };
  } catch (e) {
    return { data: undefined, errors: get(e, 'errors', e) };
  }
};

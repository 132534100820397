import * as React from 'react';
import authService from '../services/auth.service';
import qs from 'qs';
import { History } from 'history';

interface Props {
  history: History;
}

export default function VerifyEmail({ history }: Props) {
  const [message, setMessage] = React.useState('Validating email...');

  async function verify() {
    const query = qs.parse((window.location.search || '').replace('?', ''));
    if (!query.code) {
      return history.replace('/dashboard/worker/dashboard');
    }
    const { errors } = await authService.verifyEmailCode(query.code);
    if (errors !== undefined) {
      setMessage('Something went wrong verifying this code. Try again later.');
    } else {
      setMessage(
        'Thanks! We have verified your email. You can now close this window.',
      );
    }
  }

  React.useEffect(() => {
    verify();
  }, []);

  return (
    <div className="w-full h-full flex flex-col justify-center items-center">
      <h1>{message}</h1>
    </div>
  );
}

import { api2 } from './api';
import { Checklist, Task, User, Crew } from '../types';

const checklistApi = {
  show: (checklistUid: string) =>
    api2<{ checklist: Checklist }>('GET', `checklists/${checklistUid}`),
  shared: (checklistUid: string) =>
    api2<{ users: User[]; crews: Crew[] }>(
      'GET',
      `checklists/${checklistUid}/shared`,
    ),
  shareWithUser: (checklistUid: string, userUid: string) =>
    api2('POST', `checklists/${checklistUid}/shared`, { userUids: [userUid] }),
  shareWithCrew: (checklistUid: string, crewUid: string) =>
    api2('POST', `checklists/${checklistUid}/shared`, { crewUids: [crewUid] }),
  unshare: (checklistUid: string, nodeUid: string) =>
    api2('DELETE', `checklists/${checklistUid}/shared/${nodeUid}`),
  tasks: (checklistUid: string) =>
    api2<{ tasks: Array<{ task: Task; user?: User }> }>(
      'GET',
      `checklists/${checklistUid}/tasks`,
    ),
  createTask: (checklistUid: string, data: { name: string }) =>
    api2<{ task: Task }>('POST', `checklists/${checklistUid}/tasks`, data),
  saveAsTemplate: (checklistUid: string) =>
    api2('POST', `checklists/${checklistUid}/templates`),
};

export default checklistApi;

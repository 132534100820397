import * as siteActions from '../../actions/companyGroup/sites';
import { ActionType, getType } from 'typesafe-actions';
import { Site } from '../../types';

export type CompanyGroupSiteAction = ActionType<typeof siteActions>;

export interface CompanyGroupSitesState {
  data: Site[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyGroupUid: string;
}

const DEFAULT_STATE: CompanyGroupSitesState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  companyGroupUid: '',
};

export default function companyGroupSitesReducer(
  state: CompanyGroupSitesState = DEFAULT_STATE,
  action: CompanyGroupSiteAction,
) {
  switch (action.type) {
    case getType(siteActions.companyGroupSitesFetchRequest):
      return {
        ...state,
        loading: true,
        companyGroupUid: action.payload,
      };
    case getType(siteActions.companyGroupSitesFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...action.payload],
      };
    case getType(siteActions.companyGroupSitesFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
      };
    case getType(siteActions.companyGroupAddSite):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(siteActions.companyGroupRemoveSite):
      return {
        ...state,
        data: state.data.filter(site => site.uid !== action.payload),
      };
    default:
      return state;
  }
}

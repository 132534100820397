import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, SubmData, ParsedSubmData } from '../../types';
import OptionTitle from './OptionTitle';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle, getOptionText } from '../../helpers/formOption';
import styled from 'styled-components';
import { cssVars } from '../../constants';
import WidgetContent from './WidgetContent';
import WidgetContainer from './WidgetContainer';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { useParsedSubmDataLocal } from '../../helpers/hooks';

const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${cssVars.colors.border};
  padding: ${cssVars.padding[4]};
  min-height: 150px;
`;

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  childOptions?: FormOption[];
  live?: boolean;
  submData?: ParsedSubmData;
  editable?: boolean;
  submUid?: string;
}

const UserInput = ({ option, t, live, submData, editable, submUid }: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live }) || '';
  const text = getOptionText(option, { live }) || '';

  const [value, setValue] = useParsedSubmDataLocal({
    submData,
    option,
    initial: '',
    submUid: submUid!,
  });

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent>
        {text && <p>{text}</p>}
        <TextArea
          value={value}
          onChange={e => setValue(e.currentTarget.value)}
          disabled={!editable}
        />
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    editable: state.form.editable,
    submUid: state.form.submissionUid,
  });
}

export default withNamespaces()(connect(makeMapStateToProps)(UserInput));

import api, { api2 } from './api';
import { LatLng } from '../components/InlineLocationInput';
import { Company, Deployment, Form, Crew, User, FC } from '../types';

interface SiteCreateData {
  name: string;
  location?: LatLng;
}

interface AttachFormData {
  storageUid: string;
  reminders?: {
    type: string;
    days?: number[];
    weekdays?: string[];
    time: string;
    weekend: boolean;
  };
  emailAlert?: {
    userUids: string[];
    pdf: boolean;
  };
  deployment: {
    approval: boolean;
    type: string;
    all: boolean;
    customUids?: string[];
  };
}

export interface FormOnSite {
  form: Form;
  deployment: Deployment;
  fc?: FC;
}

// the response for V2 Forms needs to be consistent with these
export interface CrewFormOnSite {
  crew: Crew;
  forms: FormOnSite[];
}

export interface UserFormOnSite {
  user: User;
  forms: FormOnSite[];
}

export interface FormsOnSite {
  site: FormOnSite[];
  crews: CrewFormOnSite[];
  users: UserFormOnSite[];
}

const siteApi = {
  create: (companyUid: string, data: SiteCreateData) =>
    api('POST', `companies/${companyUid}/sites`, data),
  duplicate: (siteUid: string, data: SiteCreateData) =>
    api('POST', `sites/${siteUid}/duplicate`, data),
  update: (siteUid: string, data: SiteCreateData) =>
    api('PATCH', `sites/${siteUid}`, data),
  show: (siteUid: string) => api2('GET', `sites/${siteUid}`),
  crews: (siteUid: string) =>
    api2<{ crews: Crew[] }>('GET', `sites/${siteUid}/crews`),
  managers: (siteUid: string) => api2('GET', `sites/${siteUid}/managers`),
  attachManager: (siteUid: string, userUid: string) =>
    api('PUT', `sites/${siteUid}/managers/${userUid}`),
  attachCrew: (siteUid: string, crewUid: string) =>
    api('PUT', `sites/${siteUid}/crews/${crewUid}`),
  forms: (siteUid: string) => api2('GET', `sites/${siteUid}/forms`),
  formsForUser: (siteUid: string, userUid: string) =>
    api2<FormsOnSite>('GET', `sites/${siteUid}/users/${userUid}/forms`),
  attachForm: (siteUid: string, formUid: string, data: AttachFormData) =>
    api2('post', `sites/${siteUid}/forms/${formUid}/deployment`, data),
  activate: (siteUid: string) => api2('POST', `sites/${siteUid}/activate`),
  company: (siteUid: string) =>
    api2<{ company: Company }>('GET', `sites/${siteUid}/company`),
  deactivate: (siteUid: string) => api2('POST', `sites/${siteUid}/deactivate`),
  deployment: (siteUid: string, formUid: string) =>
    api('GET', `sites/${siteUid}/forms/${formUid}/deployment`),
  removeCrew: (siteUid: string, crewUid: string) =>
    api2('DELETE', `sites/${siteUid}/crews/${crewUid}`),
  removeForm: (siteUid: string, formUid: string) =>
    api2('DELETE', `sites/${siteUid}/forms/${formUid}`),
  removeManager: (siteUid: string, userUid: string) =>
    api2('DELETE', `sites/${siteUid}/managers/${userUid}`),
};

export default siteApi;

import * as React from 'react';
import Tags from '../../components/Tags';
import DateFilterWidget from '../../components/DateFilterWidget';
import SortableTable from '../../components/SortableTable';
import File from '../../svg/File';
import CheckCircle from '../../svg/CheckCircle';

class Documents extends React.Component {
  public render() {
    const fileIcon = <File className={'w-6 h-6'} />;
    const checkIcon = <CheckCircle className={'w-6 h-6 text-green'} />;
    return (
      <div className="p-4">
        <div className="p-4 bg-grey-lightest">
          <h3 className="mb-4">Filter By Tags</h3>
          <Tags
            tags={[
              { label: 'safety Forms' },
              { label: 'safety plan' },
              { label: 'Document' },
            ]}
            accessor="label"
            onClick={() => {}}
          />
        </div>
        <DateFilterWidget
          views={['all', 'thisYear', 'pastYear', 'custom']}
          defaultView={'all'}
        >
          {({ Widget, getWidgetProps }) => (
            <Widget {...getWidgetProps()}>
              {() => (
                <SortableTable
                  columns={[
                    { label: '', key: 'icon' },
                    { label: 'Name', key: 'name' },
                    { label: 'Completed', key: 'completed' },
                  ]}
                  data={[
                    {
                      name: 'Document 01',
                      icon: fileIcon,
                      completed: checkIcon,
                    },
                    {
                      name: 'Document 02',
                      icon: fileIcon,
                      completed: checkIcon,
                    },
                    {
                      name: 'Document 03',
                      icon: fileIcon,
                      completed: checkIcon,
                    },
                  ]}
                />
              )}
            </Widget>
          )}
        </DateFilterWidget>
      </div>
    );
  }
}

export default Documents;

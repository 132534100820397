import * as React from 'react';
import styled from 'styled-components';
import moment from 'moment';
import throttle from 'lodash/throttle';
import Clock from '../../svg/Clock';
import Calendar from '../../svg/Calendar';
import Location from '../../svg/Location';
import Cloud from '../../svg/Cloud';
import { biggerThanMD } from '../../helpers/style';
import { localStorageKeys } from '../../constants';
import { getLocation } from '../../helpers/location';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { Company } from '../../types';

const Container = styled.div<{ bg: string }>`
  display: none;
  background: ${props => props.bg};

  ${biggerThanMD(`
    display: flex;
    justify-content: space-between;
    width: 100%;
    color: white;
    padding: 8px 12px;
    font-size: 12px;
  `)};
`;

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

const IconItem = styled.span`
  display: flex;
  align-items: center;
  margin-right: 10px;

  & svg {
    width: 12px;
    margin-right: 10px;
    path {
      fill: white;
    }
  }
`;

interface State {
  time: moment.Moment;
  address: string;
  temp: number | null;
  unit: string;
}

interface Props {
  company: Company;
  bg: string;
}

class LocalHeader extends React.Component<Props, State> {
  private timeInterval: number | null;

  constructor(props: any) {
    super(props);

    this.state = {
      time: moment(),
      address: '',
      temp: null,
      unit: 'C',
    };

    this.timeInterval = null;
  }

  public componentDidMount() {
    this.timeInterval = setInterval(
      throttle(() => {
        this.setState({
          time: moment(),
        });
      }, 5000),
    );

    this.getLocation();
  }

  public componentDidUpdate(prevProps: Props) {
    if (prevProps.company.uid !== this.props.company.uid) {
      localStorage.removeItem(localStorageKeys.LOCATION);
      this.getLocation();
    }
  }

  public componentWillUnmount() {
    window.clearInterval(this.timeInterval!);
  }

  private getLocation = () => {
    getLocation().then(res => {
      if (!res) {
        return;
      }
      this.setState({
        address: res.location.address,
        temp: res.weather.temp,
        unit: res.weather.unit,
      });
    });
  };

  public render() {
    const { time, temp, address, unit } = this.state;

    const hours = time.format('hh:mm a');
    const day = time.format('DD MMM');

    return (
      <Container bg={this.props.bg}>
        <ItemWrapper>
          <IconItem>
            <Clock />
            {hours}
          </IconItem>
          <IconItem>
            <Calendar />
            {day}
          </IconItem>
        </ItemWrapper>
        <ItemWrapper>
          {address && (
            <IconItem>
              <Location />
              {address}
            </IconItem>
          )}
          {temp && (
            <IconItem>
              <Cloud /> {temp}°{unit}
            </IconItem>
          )}
        </ItemWrapper>
      </Container>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
  };
}

export default connect(mapStateToProps)(LocalHeader);

import * as React from 'react';
import BaseWidget from './BaseWidget';
import { withNamespaces, WithNamespaces } from 'react-i18next';

interface Props extends WithNamespaces {
  optionId: number;
  formUid: string;
  openDeleteOption?: (optionId: number) => any;
  index: number;
}

class ImportImage extends React.Component<Props> {
  public render() {
    const { optionId, t, openDeleteOption, index } = this.props;

    return (
      <BaseWidget
        optionId={optionId}
        defaultTitle={t('common:formWidgets.importImage')}
        openDeleteOption={openDeleteOption}
        index={index}
      />
    );
  }
}

export default withNamespaces()(ImportImage);

import * as React from 'react';
import ReactModal from 'react-modal';
import './sidebox/sidebox.scss';
import styled from 'styled-components';

export const SideboxContent = styled.div``;

interface Props {
  isOpen: boolean;
  contentLabel: string;
  handleClose: () => void;
}

if (process.env.NODE_ENV !== 'testing') {
  ReactModal.setAppElement('#root');
}

export default class Sidebox extends React.Component<Props> {
  public render() {
    const { isOpen, contentLabel, handleClose } = this.props;

    return (
      <ReactModal
        isOpen={isOpen}
        contentLabel={contentLabel}
        shouldCloseOnOverlayClick={true}
        onRequestClose={handleClose}
        className={{
          base: 'st-sidebox',
          afterOpen: 'st-sidebox--after-open',
          beforeClose: 'st-sidebox--before-close',
        }}
        closeTimeoutMS={150}
      >
        {this.props.children}
      </ReactModal>
    );
  }
}

import * as React from 'react';
import FormItem from './FormItem';

interface Props {
  forms: any[];
  deployee: any;
}

const Forms = ({ forms, deployee }: Props) => {
  return (
    <div className="flex flex-row flex-wrap">
      {forms.map(form => (
        <FormItem
          key={form.form.uid}
          form={form.form}
          fc={form.fc}
          deployment={form.deployment}
          deployee={deployee}
        />
      ))}
    </div>
  );
};

export default Forms;

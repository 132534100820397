import * as React from 'react';
import BaseWidget from './BaseWidget';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import styled from 'styled-components';
import { cssVars } from '../../constants';

const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${cssVars.colors.border};
  padding: ${cssVars.padding[4]};
`;

interface Props extends WithNamespaces {
  optionId: number;
  formUid: string;
  openDeleteOption?: (optionId: number) => any;
  index: number;
}

class Instructions extends React.Component<Props> {
  public render() {
    const { optionId, t, openDeleteOption, index } = this.props;

    return (
      <BaseWidget
        optionId={optionId}
        defaultTitle={t('common:formWidgets.instructions')}
        openDeleteOption={openDeleteOption}
        index={index}
        renderChildren={({ text, editing, onTextChange, optionText }) => (
          <div>
            {editing ? (
              <TextArea
                placeholder={t('super:screens.form.instructionTextPlaceholder')}
                value={text}
                onChange={onTextChange}
              />
            ) : (
              optionText || t('super:screens.form.instructionTextPlaceholder')
            )}
          </div>
        )}
      />
    );
  }
}

export default withNamespaces()(Instructions);

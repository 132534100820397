import * as React from 'react';
import ModalBody from '../../../components/modal/ModalBody';
import ModalHeader from '../../../components/modal/ModalHeader';
import Modal from '../../../components/Modal';
import FolderOpen from '../../../svg/FolderOpen';
import companyApi from '../../../api/company.api';
import { toast } from 'react-toastify';
import storageApi from '../../../api/storage.api';
import { Company, Storage } from '../../../types';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { padding, colors } from '../../../tailwind';
import styled from 'styled-components';
import ModalFooter from '../../../components/modal/ModalFooter';
import Button from '../../../components/Button';
import Loading from '../../../components/Loading';
import * as R from 'ramda';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import FolderCreate from '../../worker/files/FolderCreate';
import last from 'ramda/es/last';

const FolderRowItem = styled.div<{ active?: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;

  padding: ${padding['2']};
  border: 0;
  border-bottom: 1px solid ${colors['grey-light']};
  cursor: pointer;

  background: ${props => (props.active ? colors['grey-darkest'] : 'white')};
  color: ${props => (props.active ? colors.white : 'black')};

  &:hover {
    background: ${props =>
      props.active ? colors['grey-darkest'] : colors['grey-lightest']};
  }
`;

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  company: Company;
  onSelect: (folder: Storage) => void;
}

interface State {
  children: any;
  selectedFolder: Storage | null;
  path: Storage[];
  loading: boolean;
  newFolderOpen: boolean;
}

class SelectFolderModal extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      children: [],
      selectedFolder: null,
      loading: false,
      path: [],
      newFolderOpen: false,
    };
  }

  public componentDidMount() {
    this.getFolders();
  }

  private getFolders = async (storage?: Storage) => {
    this.setState({
      loading: true,
    });

    try {
      let folderToFetch = storage;

      if (!folderToFetch) {
        const { rootFolder } = await companyApi.rootStorageDir(
          this.props.company.uid,
        );

        folderToFetch = rootFolder;

        this.setState({
          path: [rootFolder],
        });
      }

      if (!folderToFetch) {
        toast.error('could not fetch folder contents');
        return;
      }

      const { data } = await storageApi.children(folderToFetch!.uid);

      if (data) {
        const folders = data.children.filter(
          (child: any) => child.type === 'Dir',
        );

        this.setState({
          children: folders.map(x => x.storage),
        });
      }
    } catch (e) {
      toast.error('Could not fetch child folders');
    }

    this.setState({
      loading: false,
    });
  };

  private onCreate = () => {
    this.getFolders(last(this.state.path));
    this.closeNewFolder();
  };

  private openNewFolder = () => {
    this.setState({
      newFolderOpen: true,
    });
  };

  private closeNewFolder = () => {
    this.setState({
      newFolderOpen: false,
    });
  };

  private onSubmit = () => {
    if (!this.state.selectedFolder) {
      return;
    }

    this.props.onSelect(this.state.selectedFolder);
  };

  private goToParentFolder = () => {
    const path = this.state.path.slice(0, this.state.path.length - 1);

    this.getFolders(R.last(path));

    this.setState({
      path,
      selectedFolder: null,
    });
  };

  private goToFolder = (folder: Storage) => {
    const path = [...this.state.path, folder];

    this.getFolders(R.last(path));

    this.setState({
      path,
      selectedFolder: null,
    });
  };

  private onBreadcrumbClick = (index: number) => {
    const path = this.state.path.slice(0, index + 1);

    this.getFolders(R.last(path));

    this.setState({
      path,
      selectedFolder: null,
    });
  };

  public render() {
    const { isOpen, handleClose, t } = this.props;
    const { selectedFolder, loading, path } = this.state;

    const currentDirectory = last(path);

    return (
      <Modal
        isOpen={isOpen}
        handleClose={handleClose}
        contentLabel={t('super:screens.site.chooseFolderModalTitle')}
      >
        <ModalHeader handleClose={handleClose}>
          {t('super:screens.site.chooseFolder')}
        </ModalHeader>
        <ModalBody>
          <Loading loading={loading} />
          <div className={'pb-4'}>
            {path.map((folder, i) => (
              <span
                key={folder.uid}
                onClick={() => this.onBreadcrumbClick(i)}
                className={'cursor-pointer hover:text-grey'}
              >
                {folder.name}
                {i !== path.length - 1 && <strong> > </strong>}
              </span>
            ))}
          </div>
          <div className="flex flex-col w-full">
            {path.length > 1 && (
              <FolderRowItem onDoubleClick={this.goToParentFolder}>
                <div className={'w-10'}>
                  <FolderOpen />
                </div>
                <div className={'flex-1 ml-6'}>
                  {t('super:screens.site.goUpFolder')}
                </div>
                <div className={'text-grey-dark'}>{t('common:select')}</div>
              </FolderRowItem>
            )}
            {this.state.children.map((child: any) => (
              <FolderRowItem
                key={child.uid}
                onClick={() => this.setState({ selectedFolder: child })}
                onDoubleClick={() => this.goToFolder(child)}
                active={
                  selectedFolder ? selectedFolder.uid === child.uid : false
                }
              >
                <div className={'w-10'}>
                  <FolderOpen />
                </div>
                <div className={'flex-1 ml-6'}>{child.name}</div>
                <div className={'text-grey-dark'}>{t('common:select')}</div>
              </FolderRowItem>
            ))}
          </div>

          {currentDirectory && (
            <FolderCreate
              isOpen={this.state.newFolderOpen}
              handleClose={this.closeNewFolder}
              storageUid={currentDirectory.uid}
              onCreate={this.onCreate}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <div className="flex flex-row justify-between items-center w-full">
            <Button type="default" onClick={this.openNewFolder}>
              New Folder
            </Button>
            <div>
              <Button type={'default'} onClick={handleClose}>
                {t('common:cancel')}
              </Button>
              <Button
                type={'primary'}
                className={'ml-2'}
                disabled={!this.state.selectedFolder}
                onClick={this.onSubmit}
              >
                {t('common:select')}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
  };
}

export default withNamespaces()(connect(mapStateToProps)(SelectFolderModal));

import * as React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';
import { cssVars } from '../../constants';

const Header = styled.header`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: ${cssVars.padding[5]};
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
  margin: 15px 0;
`;

const LeftIcon = styled.div`
  flex: 0.1;
  display: flex;
  align-items: center;

  & svg {
    width: 15px;
  }
`;

const RightIcon = styled.div`
  flex: 0.25;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  & svg {
    width: 15px;
  }
`;

const Title = styled.h3`
  flex: 1;
  font-weight: 400;
`;

interface Props {
  leftIcon?: ReactNode;
  title: string;
  rightIcon?: ReactNode;
}

export default class SideboxSectionHeader extends React.Component<Props> {
  public render() {
    const { leftIcon, title, rightIcon } = this.props;

    return (
      <Header>
        {leftIcon && <LeftIcon>{leftIcon}</LeftIcon>}
        <Title>{title}</Title>
        {rightIcon && <RightIcon>{rightIcon}</RightIcon>}
      </Header>
    );
  }
}

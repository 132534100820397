import { call, takeLatest, put } from 'redux-saga/effects';
import companyGroupApi from '../../api/companyGroup.api';
import { COMPANY_GROUP_USERS_FETCH_REQUEST } from '../../constants/actionTypes';
import {
  companyGroupUsersFetchFailure,
  companyGroupUsersFetchSuccess,
} from '../../actions/companyGroup/users';

export function* getUsers(params: any) {
  const { data, errors } = yield call(companyGroupApi.users, params.payload);
  if (errors) {
    yield put(companyGroupUsersFetchFailure(errors));
    return;
  }

  yield put(companyGroupUsersFetchSuccess(data.users));
}

export function* watchGetCompanyGroupUsers() {
  yield takeLatest(COMPANY_GROUP_USERS_FETCH_REQUEST, getUsers);
}

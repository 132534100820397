import { createSelector } from 'reselect';
import store, { StoreState } from '../store';

interface Props {
  optionId: number;
}

const getByOptionId = (state: StoreState, props: Props) =>
  state.submData.byOptionId[props.optionId];

export const makeGetSubmDataByOptionId = () =>
  createSelector([getByOptionId], submData => submData);

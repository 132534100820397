import * as React from 'react';
import { formWidgetTypes } from '../constants/index';
import Inspection from './fillableDocument/Inspection';
import Title from './fillableDocument/Title';
import { WidgetsAndResponse } from '../services/forms.service';
import Question from './fillableDocument/Question';
import FileUpload from './fillableDocument/FileUpload';
import Selection from './fillableDocument/Selection';
import User from './fillableDocument/User';
import Content from './fillableDocument/Content';
import { useState } from 'react';

export interface FormErrorWidgets {
  [key: string]: string[];
}

// TODO extend withNamespaces
interface Props {
  widgetsAndResponses: WidgetsAndResponse[];
  instanceRevUid: string;
  editable: boolean;
  emptyMandatoryWidgets: { [key: string]: boolean };
  setEmptyMandatoryWidgets: (widgets: { [key: string]: boolean }) => void;
}

const widgetLookup = {
  [formWidgetTypes.INSPECTION]: Inspection,
  [formWidgetTypes.TITLE]: Title,
  [formWidgetTypes.QUESTION]: Question,
  [formWidgetTypes.FILE]: FileUpload,
  [formWidgetTypes.SELECTION]: Selection,
  [formWidgetTypes.USER]: User,
  [formWidgetTypes.CONTENT]: Content,
};

const FormView = ({
  widgetsAndResponses,
  instanceRevUid,
  editable,
  emptyMandatoryWidgets,
  setEmptyMandatoryWidgets,
}: Props) => {
  const [formErrorWidgets, setFormErrorWidgets] = useState<FormErrorWidgets>(
    {},
  );

  function hasError(widgetUid: string) {
    const widgetErrLookup = formErrorWidgets[widgetUid];
    return (
      emptyMandatoryWidgets[widgetUid] ||
      (widgetErrLookup && widgetErrLookup.length > 0)
    );
  }

  function clearMandatoryError(widgetUid: string) {
    setEmptyMandatoryWidgets({ ...emptyMandatoryWidgets, [widgetUid]: false });
  }

  function setFormError(widgetUid: string, formErrors: string[]) {
    setFormErrorWidgets({
      ...formErrorWidgets,
      [widgetUid]: formErrors,
    });
  }

  return (
    <div style={{ marginTop: '-1rem' }}>
      {widgetsAndResponses.map(
        ({ widget, response, comments, files }: WidgetsAndResponse) => (
          <div>
            {(() => {
              const WidgetComponent = widgetLookup[widget.type];
              if (!WidgetComponent) {
                return null;
              }
              return (
                <WidgetComponent
                  widget={widget}
                  response={response}
                  comments={comments}
                  files={files}
                  instanceRevUid={instanceRevUid}
                  editable={editable}
                  error={hasError(widget.uid)}
                  setFormError={setFormError}
                  clearMandatoryError={clearMandatoryError}
                />
              );
            })()}
          </div>
        ),
      )}
    </div>
  );
};

export default FormView;

import styled from 'styled-components';
import { cssVars } from '../constants/index';

const TextArea = styled.textarea`
  width: 100%;
  border: 1px solid ${cssVars.colors.grey};
  padding: ${cssVars.padding[4]};
  color: ${cssVars.colors['almost-black']};
`;

export default TextArea;

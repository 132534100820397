import { localStorageKeys } from '../constants/index';
import * as React from 'react';
import { last, head } from 'ramda';

interface Props {
  storage?: { uid: string };
  fn?: string;
  square?: number | string;
}

export const getPrivateUrlFromUid = (
  uid: string,
  { thumb }: { thumb?: boolean } = {},
) => {
  const token = localStorage.getItem(localStorageKeys.USER_TOKEN);
  const url = `${process.env.API_URL}/v1/private-storage/${uid}?token=${token}${
    thumb ? '&thumb=true' : ''
  }`;
  return url;
};

export const getPrivateUrlFromPath = (
  fnPath: string,
  { thumb }: { thumb?: boolean } = {},
) => {
  const token = localStorage.getItem(localStorageKeys.USER_TOKEN);
  const fn = head(last(fnPath.split('/'))!.split('.'));
  const url = `${
    process.env.API_URL
  }/v1/private-storage-fn/${fn}?token=${token}${thumb ? '&thumb=true' : ''}`;
  return url;
};

const PrivateImage = ({ storage, fn, square = 100 }: Props) => {
  const param = storage ? storage.uid : fn ? fn : '';
  const route = storage ? 'private-storage' : fn ? 'private-storage-fn' : '';
  const url = `${process.env.API_URL}/v1/${route}/${param}`;
  const token = localStorage.getItem(localStorageKeys.USER_TOKEN);

  return (
    <a href={`${url}?token=${token}`} target="_blank">
      <div
        style={{
          width: square,
          height: square,
          background: `url(${url}?thumb=true&token=${token}) no-repeat`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
        }}
      />
    </a>
  );
};

export default PrivateImage;

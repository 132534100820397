import * as React from 'react';
import PageContent from '../../components/PageContent';
import Page from '../../components/Page';
import Tabs from '../../components/Tabs';
import SiteForms from './site/SiteForms';
import CrewForms from './site/CrewForms';
import UserForms from './site/UserForms';
import { match } from 'react-router';
import { User, Site as ISite, Crew } from '../../types';
import { connect } from 'react-redux';
import { StoreState } from '../../store';
import { useSimpleFetch } from '../../helpers/hooks';
import Loading from '../../components/Loading';
import PageToolbar from '../../components/PageToolbar';
import { Dispatch } from 'redux';
import { siteFormsFetchRequest } from '../../actions/siteForms';
import { SiteFormsState } from '../../reducers/siteForms';
import formService, { v2FormOnSite } from '../../services/forms.service';
import { useState } from 'react';
import siteApi from '../../api/site.api';
import { getSiteLabel, getCrewLabel } from '../../helpers';
import { toast } from 'react-toastify';

interface Props {
  match: match<{ siteUid: string }>;
  user: User;
  requestSiteForms: (siteUid: string, userUid: string) => any;
  siteFormsState: SiteFormsState;
}

const Site = ({
  match: pMatch,
  user,
  siteFormsState,
  requestSiteForms,
}: Props) => {
  const { siteUid } = pMatch.params;

  const site = useSimpleFetch<ISite | null>({
    initial: null,
    accessor: 'site',
    fetch: () => siteApi.show(siteUid),
    change: [siteUid],
  });

  React.useEffect(
    () => {
      requestSiteForms(siteUid, user.uid);
    },
    [siteUid, user.uid],
  );

  const {
    site: siteForms,
    crews: crewForms,
    users: userForms,
  } = siteFormsState.data;

  const siteCount = siteForms.length;
  const crewCount = crewForms.length;
  const userCount = userForms.length;

  if (!site.value) {
    return <Loading loading={true} />;
  }

  const tabs = [
    {
      label: `${getSiteLabel()} Forms (${siteCount})`,
      component: <SiteForms forms={siteForms} site={site.value} />,
    },
    {
      label: `${getCrewLabel()} Forms (${crewCount})`,
      component: <CrewForms forms={crewForms} />,
    },
    {
      label: `User Forms (${userCount})`,
      component: <UserForms forms={userForms} />,
    },
  ];

  return (
    <Page>
      <PageToolbar title={site.value.name} />
      <Loading loading={site.loading || siteFormsState.loading} />
      <PageContent>
        <Tabs tabs={tabs} raised={true} />
      </PageContent>
    </Page>
  );
};

export default connect(
  (state: StoreState) => ({
    user: state.me.user,
    siteFormsState: state.siteForms,
  }),
  (dispatch: Dispatch) => ({
    requestSiteForms: (siteUid: string, userUid: string) =>
      dispatch(siteFormsFetchRequest({ siteUid, userUid })),
  }),
)(Site);

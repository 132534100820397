import { createAction } from 'typesafe-actions';
import { ParsedSubmData } from '../types';
import {
  SUBM_DATA_SET,
  SUBM_DATA_UPDATE,
  SUBM_DATA_ADD,
} from '../constants/actionTypes';

export const setSubmData = createAction(
  SUBM_DATA_SET,
  resolve => (submData: ParsedSubmData[]) => resolve(submData),
);

export const updateSubmData = createAction(
  SUBM_DATA_UPDATE,
  resolve => (submData: ParsedSubmData) => resolve(submData),
);

export const addSubmData = createAction(
  SUBM_DATA_ADD,
  resolve => (submData: ParsedSubmData) => resolve(submData),
);

import * as formActions from '../actions/forms';
import { ActionType, getType } from 'typesafe-actions';
import { Form } from '../types';

export type FormAction = ActionType<typeof formActions>;

export interface FormsState {
  data: Form[];
  loading: boolean;
  loaded: boolean;
  errors?: any;
  companyUid: string;
  invalid: boolean;
}

const DEFAULT_STATE: FormsState = {
  data: [],
  loading: false,
  loaded: false,
  errors: {},
  companyUid: '',
  invalid: false,
};

export default function formsReducer(
  state: FormsState = DEFAULT_STATE,
  action: FormAction,
) {
  switch (action.type) {
    case getType(formActions.formsFetchRequest):
      return {
        ...state,
        loading: true,
        companyUid: action.payload,
      };
    case getType(formActions.formsFetchSuccess):
      return {
        ...state,
        loaded: true,
        loading: false,
        data: [...action.payload],
        invalid: false,
      };
    case getType(formActions.formsFetchFailure):
      return {
        ...state,
        loaded: true,
        loading: false,
        errors: { ...action.payload },
        invalid: true,
      };
    case getType(formActions.addForm):
      return {
        ...state,
        data: [...state.data, { ...action.payload }],
      };
    case getType(formActions.updateForm):
      return {
        ...state,
        data: state.data.map(form => {
          if (form.uid === action.payload.uid) {
            return action.payload;
          }
          return form;
        }),
      };
    case getType(formActions.removeForm):
      return {
        ...state,
        data: state.data.filter(form => form.uid !== action.payload),
      };
    case getType(formActions.formsInvalidate):
      return {
        ...state,
        invalid: true,
      };
    default:
      return state;
  }
}

import * as React from 'react';
import Sidebox, { SideboxContent } from '../../components/Sidebox';
import SideboxHeader from '../../components/sidebox/SideboxHeader';
import styled from 'styled-components';
import { cssVars } from '../../constants';
import Search from '../../svg/Search';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { WidgetType } from '../../types';
import Loading from '../../components/Loading';
import Quesiton from '../../svg/Question';
import Information from '../../svg/Information';
import File from '../../svg/File';
import Pencil from '../../svg/Pencil';
import Checkmark from '../../svg/Checkmark';
import User from '../../svg/User';

const CardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: ${cssVars.padding[4]};
`;

const WidgetCard = styled.button`
  width: 45%;
  margin-left: 10%;
  margin-bottom: ${cssVars.margin[4]};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: ${cssVars.padding[4]};
  border: 1px solid ${cssVars.colors.border};
  background: ${cssVars.colors['border-light']};
  cursor: pointer;

  &:hover {
    background: white;
  }

  & svg {
    width: ${cssVars.width[8]};
    height: ${cssVars.width[8]};
    margin-bottom: ${cssVars.margin[4]};
  }

  &:nth-child(odd) {
    margin-left: 0;
  }
`;

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  onCreate: (type: WidgetType) => Promise<any>;
}

interface State {
  loading: boolean;
}

class WidgetAdd extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
    };
  }

  private onClick = async (type: WidgetType) => {
    const { onCreate } = this.props;

    this.setState({
      loading: true,
    });

    onCreate(type);

    this.setState({
      loading: false,
    });
  };

  public render() {
    const { isOpen, handleClose, t } = this.props;
    const { loading } = this.state;

    return (
      <Sidebox
        isOpen={isOpen}
        contentLabel={'User detail'}
        handleClose={handleClose}
      >
        <SideboxHeader handleClose={handleClose}>
          <h3>{t('super:screens.form.addWidget')}</h3>
        </SideboxHeader>
        <SideboxContent>
          <Loading loading={loading} />
          <CardWrapper>
            <WidgetCard onClick={() => this.onClick('inspection')}>
              <Search />
              {t('common:formWidgets.inspection')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick('title')}>
              <Information />
              {t('common:formWidgets.title')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick('question')}>
              <Quesiton />
              {t('common:formWidgets.question')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick('file')}>
              <File />
              {t('common:formWidgets.file')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick('content')}>
              <Pencil />
              {'Content'}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick('selection')}>
              <Checkmark />
              {t('common:formWidgets.selection')}
            </WidgetCard>
            <WidgetCard onClick={() => this.onClick('user')}>
              <User />
              {'User'}
            </WidgetCard>
          </CardWrapper>
        </SideboxContent>
      </Sidebox>
    );
  }
}

export default withNamespaces()(WidgetAdd);

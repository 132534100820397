import * as React from 'react';
import styled from 'styled-components';
import { ReactNode } from 'react';
import VerticalMenu from '../svg/VerticalMenu';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { cssVars } from '../constants';
import { boxShadow } from '../helpers/style';

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
`;

const ListItem = styled.li`
  padding: ${cssVars.padding[4]};
  background: #f6f6f6;
  font-size: 1em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;

  &:nth-of-type(even) {
    background: #ffffff;
  }

  & svg {
    width: 1.25em;
    height: auto;
  }
`;

const CategoryContainer = styled.div`
  margin-bottom: ${cssVars.margin[6]};
`;

const CategoryTitle = styled.h3`
  font-weight: 600;
  border-bottom: 1px solid ${cssVars.colors.border};
  padding: 0 ${cssVars.padding[4]};
  padding-bottom: ${cssVars.padding[3]};
  color: ${cssVars.colors.black};
`;

const Left = styled.div``;

const Right = styled.div`
  position: relative;
`;

const Center = styled.div`
  margin: 0 ${cssVars.margin[4]};
`;

export const DropdownMenu = styled.ul`
  ${boxShadow} position: absolute;
  top: 25px;
  right: 0;
  background: white;
  list-style: none;
  min-width: 90px;
  z-index: 9;

  &:before {
    content: '';
    position: absolute;
    top: -5px;
    right: 3px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 6px 6px;
    border-color: transparent transparent white;
  }
`;

const MenuItem = styled.li`
  font-size: ${cssVars.textSizes.xs};
  border-bottom: 1px solid ${cssVars.colors['border-light']};
  cursor: pointer;

  &:last-of-type {
    border-bottom: 0;
  }

  &:hover {
    background: ${cssVars.colors['border-light']};
  }
`;

export const MenuButton = styled.button`
  cursor: pointer;
  background: none;
  border: none;
`;

export const MenuItemButton = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  width: 100%;
  text-align: left;
`;

interface RenderItemProps {
  item: any;
  ListItem: typeof ListItem;
  Left: typeof Left;
  Right: typeof Right;
  Center: typeof Center;
}

interface RenderEmptyProps {
  ListItem: typeof ListItem;
  Left: typeof Left;
  Right: typeof Right;
  Center: typeof Center;
}

interface Props extends WithNamespaces {
  title?: string;
  items: any[];
  itemLabelAccessor?: string;
  renderItem?: (obj: RenderItemProps) => ReactNode;
  renderEmpty?: (obj: RenderEmptyProps) => ReactNode;
  menuItems?: Array<{ label: string; onClick: (item: any) => any }>;
}

interface State {
  menuOpen: number | null;
}

export class CategoryList extends React.Component<Props, State> {
  private blurTimeoutIds: any = {};

  constructor(props: Props) {
    super(props);

    this.state = {
      menuOpen: null,
    };

    this.blurTimeoutIds = {};
  }

  private openMenu = (e: any, index: number) => {
    e.stopPropagation();
    if (this.state.menuOpen === index) {
      this.closeMenu();
      return;
    }

    this.setState({
      menuOpen: index,
    });
  };

  private closeMenu = () => {
    this.setState({
      menuOpen: null,
    });
  };

  private onMenuFocus = (e: any, id: any) => {
    e.stopPropagation();
    clearTimeout(this.blurTimeoutIds[id]);
  };

  private onMenuBlur = (e: any, id: any) => {
    e.stopPropagation();
    this.blurTimeoutIds[id] = setTimeout(() => this.closeMenu());
  };

  private renderListItem = (item: any, i: number) => (
    <ListItem key={i}>
      <Left>
        {this.props.itemLabelAccessor
          ? item[this.props.itemLabelAccessor]
          : item}
      </Left>
      {this.props.menuItems && (
        <Right
          onFocus={(e: any) => this.onMenuFocus(e, i)}
          onBlur={(e: any) => this.onMenuBlur(e, i)}
        >
          <MenuButton
            onClick={(e: any) => this.openMenu(e, i)}
            aria-haspopup="true"
            aria-expanded={this.state.menuOpen === i}
          >
            <VerticalMenu />
          </MenuButton>
          {this.state.menuOpen === i && (
            <DropdownMenu>
              {this.props.menuItems.map((menuItem, buttonIdx) => (
                <MenuItem key={menuItem.label}>
                  <MenuItemButton
                    aria-setsize={this.props.menuItems!.length}
                    aria-posinset={buttonIdx}
                    onClick={() => {
                      this.closeMenu();
                      menuItem.onClick(item);
                    }}
                    tabIndex={0}
                  >
                    {menuItem.label}
                  </MenuItemButton>
                </MenuItem>
              ))}
            </DropdownMenu>
          )}
        </Right>
      )}
    </ListItem>
  );

  public render() {
    const { title, items, renderItem } = this.props;

    return (
      <CategoryContainer>
        {title && <CategoryTitle>{title}</CategoryTitle>}

        <List>
          {items && items.length > 0 ? (
            items.map(
              renderItem
                ? item => renderItem({ item, ListItem, Left, Right, Center })
                : this.renderListItem,
            )
          ) : (
            <ListItem>{this.props.t('common:emptyListItem')}</ListItem>
          )}
        </List>
      </CategoryContainer>
    );
  }
}

export default withNamespaces()(CategoryList);

import { createAction } from 'typesafe-actions';
import { OPEN_DOWNLOADS, CLOSE_DOWNLOADS } from '../constants/actionTypes';

export const openDownloads = createAction(OPEN_DOWNLOADS, resolve => () =>
  resolve(),
);

export const closeDownloads = createAction(CLOSE_DOWNLOADS, resolve => () =>
  resolve(),
);

import * as React from 'react';
import SideboxSectionContent from '../../components/sidebox/SideboxSectionContent';
import InlineSelect from '../../components/InlineSelect';
import User from '../../svg/User';
import { useSimpleFetch, useCompanyUserOptions } from '../../helpers/hooks';
import storageApi from '../../api/storage.api';
import RemovableAvatars from '../../components/RemovableAvatars';
import { User as IUser } from '../../types';
import { SelectOptionObject } from '../../components/Select';
import StackedSelect from '../../components/StackedSelect';

interface Props {
  storageUid: string;
}

const ShareUsers = ({ storageUid }: Props) => {
  const [selectedUsers, setSelectedUsers] = React.useState<IUser[]>([]);
  const users = useSimpleFetch({
    initial: [],
    change: [storageUid],
    fetch: () => storageApi.shared(storageUid),
    accessor: 'users',
  });
  const allUsers = useCompanyUserOptions();

  const userOptions = allUsers.filter(function(objFromAll: any) {
    return !selectedUsers.find(function(objFormSelected) {
      return objFromAll.value === objFormSelected.uid;
    });
  });


  React.useEffect(
    () => {
      setSelectedUsers(users.value);
    },
    [users.value],
  );

  const share = async (opt: SelectOptionObject) => {
    await storageApi.shareWithUser(storageUid, opt.value);
    users.performFetch();
  };

  const unshare = async (user: IUser) => {
    storageApi.unshare(storageUid, user.uid);
    setSelectedUsers(selectedUsers.filter(u => u.uid !== user.uid));
  };

  return (
    <SideboxSectionContent>
      {selectedUsers.length > 0 && (
        <div className="mb-4">
          <RemovableAvatars users={selectedUsers} onClick={unshare} />
        </div>
      )}
      <StackedSelect
        label="Share with users"
        options={userOptions}
        value={null}
        onChange={share}
      />
    </SideboxSectionContent>
  );
};

export default ShareUsers;

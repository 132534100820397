import * as React from 'react';
import { useMountVisibility, useMultiSelectInput } from '../helpers/hooks';
import companyApi from '../api/company.api';
import CloseOutline from '../svg/CloseOutline';
import moment from 'moment';
import Avatar from '../components/Avatar';
import InlineSelect from '../components/InlineSelect';
import Trophy from '../svg/Trophy';
import Button from '../components/Button';
import ResourceTable from '../components/ResourceTable';
import { Certificate, CertType, User, WorkRole } from '../types';
import { StoreState } from '../store';
import { connect } from 'react-redux';
import Loading from '../components/Loading';
import { Dispatch } from 'redux';
import { openDownloads } from '../actions/ui';
import UserCertificatesSidebox from './UserCertificatesSidebox';
import Infinity from '../svg/Infinity';
import InlineAlert from '../components/InlineAlert';

interface UserMatrixObject {
  user: User;
  workRole: null | WorkRole;
  certs: Certificate[];
}

interface Props {
  companyUid: string;
  view: 'site' | 'crew' | 'user' | 'company';
  entityUid?: string;
  user: User;
  showDownloads: () => any;
}

const TrainingMatrix = ({
  companyUid,
  view,
  entityUid,
  user,
  showDownloads,
}: Props) => {
  const [matrix, setMatrix] = React.useState<UserMatrixObject[]>([]);
  const certTypeSelection = useMultiSelectInput();
  const [loadingMatrix, setLoadingMatrix] = React.useState(false);
  const [loadingCertTypes, setLoadingCertTypes] = React.useState(false);
  const [loadingRoleMatrix, setLoadingRoleMatrix] = React.useState(false);
  const [loadingPdfExport, setLoadingPdfExport] = React.useState(false);
  const [loadingCsvExport, setLoadingCsvExport] = React.useState(false);

  const [certificates, setCertificates] = React.useState<CertType[]>([]);
  const [roleCertMatrix, setRoleCertMatrix] = React.useState<any>({});
  const userSidebox = useMountVisibility();
  const [selectedUser, setSelectedUser] = React.useState<User | null>(null);

  const getFilters = () => {
    if (!entityUid) {
      return {};
    }
    return view === 'site'
      ? { siteUids: [entityUid] }
      : view === 'crew'
      ? { crewUids: [entityUid] }
      : view === 'user'
      ? { userUids: [entityUid] }
      : {};
  };

  const fetchCertTypes = React.useMemo(
    () => async () => {
      setLoadingCertTypes(true);
      try {
        const res = await companyApi.certificates(companyUid);
        setCertificates(res.certificates);
        certTypeSelection.onChange(
          res.certificates.map((c: CertType) => ({
            label: c.name,
            value: c.uid,
          })),
        );
      } catch (e) {
        //
      }
      setLoadingCertTypes(false);
    },
    [companyUid],
  );

  const fetchRoleCertMatrix = React.useMemo(
    () => async () => {
      setLoadingRoleMatrix(true);
      try {
        const res = await companyApi.roleCertificateMatrix(companyUid);
        setRoleCertMatrix(res.matrix);
      } catch (e) {
        //
      }
      setLoadingRoleMatrix(false);
    },
    [companyUid],
  );

  const fetchData = async () => {
    setLoadingMatrix(true);

    const filters = getFilters();

    try {
      const res = await companyApi.trainingMatrix(companyUid, filters);
      setMatrix(res.matrix);
    } catch (e) {
      //
    }

    setLoadingMatrix(false);
  };

  React.useEffect(() => {
    setMatrix([]);
    if (entityUid) {
      fetchData();
    }
  }, [entityUid]);

  React.useEffect(() => {
    setMatrix([]);
    certTypeSelection.onChange([]);
    fetchCertTypes();
    fetchRoleCertMatrix();
  }, [companyUid]);

  const onExportPdfClick = async () => {
    setLoadingPdfExport(true);

    const filters = getFilters();

    await companyApi.exportTrainingMatrixAsPdf(companyUid, {
      ...filters,
      certTypeUids: certTypeSelection.value.map(c => c.value),
    });

    showDownloads();

    setLoadingPdfExport(false);
  };

  const onExportCsvClick = async () => {
    setLoadingCsvExport(true);

    const filters = getFilters();

    await companyApi.exportTrainingMatrixAsCsv(companyUid, {
      ...filters,
      certTypeUids: certTypeSelection.value.map(c => c.value),
    });

    showDownloads();

    setLoadingCsvExport(false);
  };

  const getCertificateOptions = () =>
    certificates.map(cert => ({
      label: cert.name,
      value: cert.uid,
    }));

  const selectAllCertificates = () => {
    certTypeSelection.onChange(getCertificateOptions());
  };

  const calculateUserStrength = (item: any) => {
    const info: any = {
      missingCertificates: [],
      expiredCertificates: [],
      completionPercentage: 0,
    };

    if (!item.workRole) {
      return {
        ...info,
        completionPercentage: 100,
      };
    }

    const requiredCerts = roleCertMatrix[item.workRole.uid];

    if (!requiredCerts) {
      return {
        ...info,
        completionPercentage: 100,
      };
    }

    const currentDate = moment().unix();

    const totalMatches = requiredCerts.reduce(
      (carry: number, certTypeUid: string) => {
        const certType = certificates.find(cert => cert.uid === certTypeUid);

        if (!certType) {
          return carry;
        }

        const userCert = item.certs.find((cert: any) => {
          return cert.name.toLowerCase() === certType.name.toLowerCase();
        });

        if (userCert && userCert.exp_date > currentDate) {
          return carry + 1;
        } else if (userCert && userCert.exp_date < currentDate) {
          info.expiredCertificates.push(certType);
          return carry;
        }

        info.missingCertificates.push(certType);

        return carry;
      },
      0,
    );

    return {
      ...info,
      completionPercentage: +(
        (totalMatches / requiredCerts.length) *
        100
      ).toFixed(0),
    };
  };

  const onUserClick = (u: User) => {
    userSidebox.open();
    setSelectedUser(u);
  };

  const columns = [
    {
      Header: 'User',
      accessor: (r: any) => r,
      id: 'user',
      minWidth: certTypeSelection.value.length === 0 ? 150 : 300,
      Cell: (row: any) => {
        const {
          completionPercentage,
          expiredCertificates,
          missingCertificates,
        } = calculateUserStrength(row.original);
        return (
          <div className={'flex flex-col w-full'}>
            <p
              className={'mb-1 cursor-pointer'}
              onClick={() => onUserClick(row.original.user)}
            >
              {row.original.user.username}
            </p>
            <div className={'w-full flex flex-row items-center'}>
              <div style={{ width: '90%' }} className={'mr-2'}>
                <div
                  style={{ width: '100%', height: '5px' }}
                  className={'bg-grey'}
                >
                  <div
                    style={{
                      width: `${completionPercentage}%`,
                      height: '100%',
                    }}
                    className={'bg-grey-darker'}
                  />
                </div>
              </div>
              <div>{completionPercentage}%</div>
            </div>
            {missingCertificates.length > 0 && (
              <p
                className={'text-sm text-grey-darker'}
                style={{ whiteSpace: 'normal' }}
              >
                Missing:{' '}
                {missingCertificates.map((cert: any) => cert.name).join(', ')}
              </p>
            )}
            {expiredCertificates.length > 0 && (
              <p
                className={'text-sm text-grey-darker'}
                style={{ whiteSpace: 'normal' }}
              >
                Expired:{' '}
                {expiredCertificates.map((cert: any) => cert.name).join(', ')}
              </p>
            )}
          </div>
        );
      },
    },
    {
      Header: 'User Role',
      accessor: (r: any) => (r.workRole ? r.workRole.name : ''),
      id: 'workRole',
    },
    ...certTypeSelection.value.map(opt => ({
      Header: opt.label,
      accessor: (r: any) => '',
      id: opt.value,
      Cell: (row: any) => {
        const cert = row.original.certs.find(
          (c: any) => c.name.toLowerCase() === opt.label.toLowerCase(),
        );
        if (!cert) {
          // check if users role requires this cert
          const { workRole } = row.original;

          if (workRole) {
            const requiredCerts = roleCertMatrix[workRole.uid];
            if (requiredCerts && requiredCerts.indexOf(opt.value) !== -1) {
              return <CloseOutline className={'w-4 h-4 text-red'} />;
            }
          }

          return null;
        }

        if (cert.exp_date) {
          const expDate = moment.unix(cert.exp_date);
          const format = 'MMM Do YYYY';

          if (expDate.isBefore(moment())) {
            return (
              <span className={'text-red'}> {expDate.format(format)}</span>
            );
          }
          return (
            <span className={'text-green'}> {expDate.format(format)}</span>
          );
        }
        return <Infinity className={'w-4 h-4 text-green'} />;
      },
    })),
  ];

  const loading = loadingMatrix || loadingCertTypes || loadingRoleMatrix;

  return (
    <>
      <Loading loading={loading} />
      {matrix.length > 0 && (
        <div className="flex flex-wrap p-4 bg-grey-light border-1 border-solid border-grey mb-4">
          {matrix.map(item => (
            <Avatar
              fallbackName={item.user.username}
              margin={true}
              bgColor={item.user.profile_color[0]}
              fgColor={item.user.profile_color[1]}
              user={item.user}
            />
          ))}
        </div>
      )}
      {matrix.length > 0 && (
        <>
          <div className="flex flex-col md:flex-row items-stretch">
            <div className="flex-1">
              <InlineSelect
                options={getCertificateOptions()}
                label={'Certificates'}
                icon={<Trophy />}
                {...certTypeSelection}
                isMulti={true}
              />
            </div>
            <div className="flex-1 md:ml-4">
              <Button type={'default'} onClick={selectAllCertificates}>
                Select All
              </Button>
              <Button
                type={'primary'}
                className={'mx-4'}
                onClick={onExportPdfClick}
                loading={loadingPdfExport}
              >
                Download Matrix
              </Button>
              <Button
                type={'primary'}
                onClick={onExportCsvClick}
                loading={loadingCsvExport}
              >
                Export to CSV
              </Button>
            </div>
          </div>
        </>
      )}

      {matrix.length > 0 && (
        <div>
          <ResourceTable
            data={matrix}
            columns={columns}
            defaultSortKey={'user'}
          />
        </div>
      )}
      {userSidebox.mounted && (
        <UserCertificatesSidebox
          isOpen={userSidebox.visible}
          handleClose={userSidebox.close}
          user={selectedUser!}
        />
      )}
    </>
  );
};

const mapStateToProps = (state: StoreState) => ({
  user: state.me.user,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  showDownloads: () => dispatch(openDownloads()),
});

export const _TrainingMatrix = connect(
  mapStateToProps,
  mapDispatchToProps,
)(TrainingMatrix);

export default function TrainingMatrixTemporarilyDisabled() {
  return (
    <div className="p-8 text-center">
      <InlineAlert
        type={'warning'}
        text={`This feature is currently disabled while we work to improve it. Thanks
    for understanding!`}
      />
    </div>
  );
}

import * as React from 'react';
import { connect } from 'react-redux';
import { StoreState } from '../../../store';
import { Company, Site } from '../../../types';
import siteApi from '../../../api/site.api';
import SiteModifyModal, { SubmitData } from '../sites/SiteModifyModal';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import { get } from 'lodash';
import formService from '../../../services/forms.service';
import { getSiteLabel } from '../../../helpers';

interface Props extends WithNamespaces {
  isOpen: boolean;
  handleClose: () => void;
  handleUpdate: (site: Site) => void;
  company: Company;
  site: Site;
}

interface State {
  loading: boolean;
  errors: {
    name?: string;
  };
}

class SiteDuplicate extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      loading: false,
      errors: {
        name: '',
      },
    };
  }

  private submit = async (data: SubmitData) => {
    const { name, latlng } = data;

    this.setState({
      loading: true,
    });

    try {
      const { site } = await siteApi.duplicate(this.props.site.uid, {
        name,
        location: latlng || undefined,
      });
      await formService.copyDeployments(this.props.site.uid, site.uid);
      this.setState({
        errors: {},
      });
      this.props.handleUpdate(site);
    } catch (e) {
      this.setState({
        errors: get(e, 'response.data.errors', {}),
      });
    }

    this.setState({
      loading: false,
    });
  };

  public render() {
    const { isOpen, handleClose, site, t } = this.props;
    const { errors, loading } = this.state;

    return (
      <SiteModifyModal
        isOpen={isOpen}
        contentLabel={`Duplicate ${getSiteLabel()}`}
        title={`Duplicate ${getSiteLabel()}`}
        handleClose={handleClose}
        onSubmit={this.submit}
        errors={errors}
        loading={loading}
        defaultName={site.name}
        defaultLocation={site.location}
      />
    );
  }
}

function mapStateToProps(state: StoreState) {
  return {
    company: state.me.company,
  };
}

export default withNamespaces()(connect(mapStateToProps)(SiteDuplicate));

import { call, takeLatest, put } from 'redux-saga/effects';
import { WORKER_SITE_FORMS_FETCH_REQUEST } from '../constants/actionTypes';
import siteApi from '../api/site.api';
import {
  siteFormsFetchSuccess,
  siteFormsFetchFailure,
} from '../actions/siteForms';

export function* getSiteForms(params: any) {
  try {
    const { data } = yield call(
      siteApi.formsForUser,
      params.payload.siteUid,
      params.payload.userUid,
    );
    const { crews, site, users } = data;
    yield put(siteFormsFetchSuccess({ crews, site, users }));
  } catch (e) {
    yield put(siteFormsFetchFailure(e.errors));
  }
}

export function* watchGetSiteForms() {
  yield takeLatest(WORKER_SITE_FORMS_FETCH_REQUEST, getSiteForms);
}

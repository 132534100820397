import * as React from 'react';
import User from '../../svg/User';
import BaseWidget, { RenderMenuProps } from './BaseWidget';
import InlineSelect from '../../components/InlineSelect';
import Avatar from '../../components/Avatar';
import Button from '../../components/Button';
import moment from 'moment';
import { useRequiredSelectInput } from '../../helpers/hooks';
import { CompanyGroup, Company } from '../../types';
import companyApi from '../../api/company.api';
import companyGroupApi from '../../api/companyGroup.api';
import { useState } from 'react';
import { orderBy } from 'natural-orderby';
import { getFullName } from '../../helpers/index';
import ChevronDown from '../../svg/ChevronDown';
import ChevronUp from '../../svg/ChevronUp';
import { cssVars } from '../../constants';

interface Props {
  companyGroup?: CompanyGroup;
  company?: Company;
}

interface IndicatorProps {
  sortOptions: { type: string; desc: boolean };
  name: string;
  type: string;
}

export const SortIndicator = ({ sortOptions, name, type }: IndicatorProps) => {
  return (
    <div className="flex items-center">
      {name}
      {sortOptions.type === type ? (
        sortOptions.desc ? (
          <ChevronDown width={16} height={16} className="ml-2" />
        ) : (
          <ChevronUp width={16} height={16} className="ml-2" />
        )
      ) : (
        <div
          style={{
            width: '16px',
            height: '16px',
            marginLeft: '.5rem',
          }}
        />
      )}
    </div>
  );
};

const UserActivity = ({ company, companyGroup }: Props) => {
  const getViewOptions = () => [
    { label: 'Today', value: 'day' },
    { label: 'Past 7 Days', value: 'week' },
    { label: 'Past 30 Days', value: 'month' },
  ];

  const getFilterFromView = () => {
    const { value } = view;

    if (value.value === 'day') {
      return {
        start: moment()
          .startOf('day')
          .unix(),
        end: moment().unix(),
      };
    }
    if (value.value === 'week') {
      return {
        start: moment()
          .subtract(7, 'days')
          .unix(),
        end: moment().unix(),
      };
    }
    if (value.value === 'month') {
      return {
        start: moment()
          .subtract(30, 'days')
          .unix(),
        end: moment().unix(),
      };
    }
  };

  const [activity, setActivity] = useState([]);
  const view = useRequiredSelectInput(getViewOptions()[0]);

  const [sortOptions, setSortOptions] = useState({
    type: 'user',
    desc: false,
  });

  const query = async () => {
    try {
      if (company) {
        const res = await companyApi.userActivity(
          company.uid,
          getFilterFromView(),
        );
        setActivity(res.userActivity);
      }
      if (companyGroup) {
        const res = await companyGroupApi.userActivity(
          companyGroup.uid,
          getFilterFromView(),
        );
        setActivity(res.userActivity);
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(() => {
    query();
  }, [
    view.value,
    company ? company.uid : null,
    companyGroup ? companyGroup.uid : null,
  ]);

  const renderMenu = ({ closeMenu }: RenderMenuProps) => {
    return (
      <div className="h-full w-full p-4 flex items-center">
        <div className="flex-1">
          <InlineSelect
            options={getViewOptions()}
            label={'View'}
            icon={<User className={'w-4 h-4'} />}
            {...view}
          />
          <Button
            type={'default'}
            block={true}
            children={'Done'}
            onClick={closeMenu}
          />
        </div>
      </div>
    );
  };

  const sorts = {
    submissions: (x: any) => x.submissions,
    user: (x: any) => getFullName(x.user),
    signOffs: (x: any) => x.signOffs,
  };

  let data = orderBy(
    activity.map((item: any) => ({
      user: item.user,
      submissions: item.submissions,
      signOffs: item.signOffs,
    })),
    [sorts[sortOptions.type]],
    [sortOptions.desc ? 'desc' : 'asc'],
  );

  return (
    <BaseWidget title={'User Activity'} renderMenu={renderMenu}>
      <div className="flex flex-col h-full" style={{ overflow: 'scroll' }}>
        <table
          className={'w-full'}
          style={{ borderCollapse: 'collapse', borderSpacing: 0 }}
        >
          <thead>
            <tr>
              <th
                className={
                  'p-4 border-0 border-b-1 border-solid border-border text-left cursor-pointer'
                }
                style={{ width: '45%' }}
                onClick={() =>
                  sortOptions.type !== 'user'
                    ? setSortOptions({ type: 'user', desc: true })
                    : setSortOptions({ type: 'user', desc: !sortOptions.desc })
                }
              >
                <SortIndicator
                  sortOptions={sortOptions}
                  name={'User'}
                  type={'user'}
                />
              </th>
              <th
                className={
                  'p-4 border-0 border-b-1 border-solid border-border text-left cursor-pointer'
                }
                onClick={() =>
                  sortOptions.type !== 'submissions'
                    ? setSortOptions({ type: 'submissions', desc: true })
                    : setSortOptions({
                        type: 'submissions',
                        desc: !sortOptions.desc,
                      })
                }
              >
                <SortIndicator
                  sortOptions={sortOptions}
                  name={'Submissions'}
                  type={'submissions'}
                />
              </th>
              <th
                className={
                  'p-4 border-0 border-b-1 border-solid border-border text-left cursor-pointer'
                }
                onClick={() =>
                  sortOptions.type !== 'signOffs'
                    ? setSortOptions({ type: 'signOffs', desc: true })
                    : setSortOptions({
                        type: 'signOffs',
                        desc: !sortOptions.desc,
                      })
                }
              >
                <SortIndicator
                  sortOptions={sortOptions}
                  name={'Sign Offs'}
                  type={'signOffs'}
                />
              </th>
            </tr>
          </thead>
          <tbody>
            {data.map((o, i) => (
              <tr
                key={i}
                style={
                  o.submissions === 0 && o.signOffs === 0
                    ? {
                        backgroundColor: cssVars.colors['red-light'],
                        borderCollapse: 'collapse',
                        borderSpacing: 0,
                      }
                    : {}
                }
                title={
                  o.submissions === 0 && o.signOffs === 0
                    ? 'This user has not submitted or signed off on any forms.'
                    : ''
                }
              >
                <td
                  className={
                    'p-4 border-0 border-b-1 border-solid border-border text-left'
                  }
                >
                  <div className={'flex flex-row items-center'}>
                    <Avatar
                      fallbackName={o.user.username}
                      fgColor={
                        o.user.profile_color
                          ? o.user.profile_color[1]
                          : undefined
                      }
                      bgColor={
                        o.user.profile_color
                          ? o.user.profile_color[0]
                          : undefined
                      }
                      user={o.user}
                    />
                    {o.user.username}
                  </div>
                </td>
                <td
                  className={
                    'p-4 border-0 border-b-1 border-solid border-border text-left'
                  }
                >
                  {o.submissions}
                </td>
                <td
                  className={
                    'p-4 border-0 border-b-1 border-solid border-border text-left'
                  }
                >
                  {o.signOffs}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </BaseWidget>
  );
};

export default UserActivity;

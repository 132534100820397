import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../constants';
import { RouteComponentProps } from 'react-router';

const Card = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: ${cssVars.padding[4]};
`;

const Stat = styled.h3`
  color: white;
  font-size: ${cssVars.textSizes['3xl']};
`;

const Title = styled.h4`
  color: ${cssVars.colors['grey-dark']};
  font-size: ${cssVars.textSizes.xl};
  font-weight: normal;
`;

const Details = styled.div`
  text-align: right;
`;

interface Props {
  icon:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
  stat: number | string;
  title: string;
  className?: string;
}

const SummaryCard = ({ icon, title, stat, className }: Props) => {
  const Icon = icon;
  return (
    <Card className={className}>
      <Icon className={'w-8 h-8 text-grey-lighter'} />
      <Details>
        <Stat>{stat}</Stat>
        <Title>{title}</Title>
      </Details>
    </Card>
  );
};

export default SummaryCard;

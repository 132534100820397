import * as React from 'react';
import BaseSVG, { SVGProps } from './BaseSVG';

const Information = (props: SVGProps) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" {...props}>
    <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zM9 11v4h2V9H9v2zm0-6v2h2V5H9z" />
  </svg>
);

export default BaseSVG(Information);

import { createSelector } from 'reselect';
import { StoreState } from '../store';

const getForms = (state: StoreState, props: any) => state.forms.data;

const getFormUidFromUrl = (state: StoreState, props: any) =>
  props.match.params.formUid;

export const makeGetFormFromUrl = () =>
  createSelector([getForms, getFormUidFromUrl], (forms, formUid) =>
    forms.find(f => f.uid === formUid),
  );

import { createAction } from 'typesafe-actions';
import { Form } from '../../types';
import {
  SUPER_SITE_FORMS_ADD,
  SUPER_SITE_FORMS_SET,
  SUPER_SITE_FORMS_REMOVE,
} from '../../constants/actionTypes';

export const setSiteForms = createAction(
  SUPER_SITE_FORMS_SET,
  resolve => (forms: Form[]) => resolve(forms),
);

export const addSiteForm = createAction(
  SUPER_SITE_FORMS_ADD,
  resolve => (form: Form) => resolve(form),
);

export const removeSiteForm = createAction(
  SUPER_SITE_FORMS_REMOVE,
  resolve => (formUid: string) => resolve(formUid),
);

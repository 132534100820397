import * as React from 'react';
import { makeGetOption } from '../../selectors/formOptions';
import { StoreState } from '../../store';
import { connect } from 'react-redux';
import { FormOption, SubmData, ParsedSubmData } from '../../types';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import { getOptionTitle } from '../../helpers/formOption';
import StackedInput from '../../components/StackedInput';
import OptionTitle from './OptionTitle';
import WidgetContainer from './WidgetContainer';
import WidgetContent from './WidgetContent';
import { makeGetSubmDataByOptionId } from '../../selectors/submData';
import { attempt } from '../../helpers/index';
import { trim, last, head } from 'lodash';
import PrivateImage from '../../components/PrivateImage';
import moment from 'moment';
import { useTextInput } from '../../helpers/hooks';
import Button from '../../components/Button';
import submissionApi from '../../api/submission.api';
import { Dispatch } from 'redux';
import { updateSubmData } from '../../actions/submData';

interface Props extends WithNamespaces {
  optionId: number;
  option?: FormOption;
  live?: boolean;
  submData?: ParsedSubmData;
  updateSubm: (submData: ParsedSubmData) => any;
  editable?: boolean;
  submUid?: string;
}

const trimLegacyUploadStr = (str: string) => {
  if (!str) {
    return '';
  }
  return trim(str.replace(/^uploads/g, '').replace(/\\\//g, '/'), '/');
};

const getFn = (str: string) => {
  return head(last(str.split('/'))!.split('.'));
};

const SignOff = ({
  option,
  t,
  live,
  submData,
  updateSubm,
  submUid,
  editable,
}: Props) => {
  if (!option) {
    return null;
  }

  const title = getOptionTitle(option, { live });
  const data = submData
    ? attempt(() => JSON.parse(submData.data), {}) || {}
    : {};

  const users = Object.keys(data).map(a => ({
    name: data[a].name,
    signature: data[a].signature,
    uid: a,
    date: data[a].date,
  }));

  const [loading, setLoading] = React.useState(false);
  const username = useTextInput();
  const password = useTextInput();
  const [errors, setErrors] = React.useState<any>({});

  const onSubmit = async () => {
    setLoading(true);
    const res = await submissionApi.updateFormOption(submUid!, option.id, {
      username: username.value,
      password: password.value,
    });
    if (res.errors) {
      setErrors(res.errors.data || {});
    }
    if (res.data) {
      updateSubm(res.data.submData);
      username.setValue('');
      password.setValue('');
      setErrors({});
    }
    setLoading(false);
  };

  return (
    <WidgetContainer>
      <OptionTitle title={title} />
      <WidgetContent>
        <div className="flex flex-col sm:flex-row sm:items-start">
          <div className="flex-1">
            <StackedInput
              id={`option-${option.id}-username`}
              label={t('common:username')}
              disabled={!editable}
              {...username}
              errorMessage={errors.username}
            />
          </div>
          <div className="flex-1 sm:ml-4">
            <StackedInput
              id={`option-${option.id}-password`}
              label={t('common:password')}
              type={'password'}
              disabled={!editable}
              {...password}
              errorMessage={errors.password}
            />
          </div>
          <div
            className="ml-4"
            style={{
              alignSelf: Object.keys(errors).length > 0 ? 'center' : 'flex-end',
            }}
          >
            <Button type="default" onClick={onSubmit} loading={loading}>
              Sign Off
            </Button>
          </div>
        </div>
        {users.map(u => (
          <div
            key={u.uid}
            className="flex items-center justify-between p-4 border-solid border-grey border-1 mt-4"
          >
            <div className={'flex items-center'}>
              <div className="mr-4">
                <PrivateImage
                  square={25}
                  fn={getFn(trimLegacyUploadStr(u.signature))}
                />
              </div>
              <h3 className={'font-normal'}>{u.name}</h3>
            </div>
            {u.date && (
              <p>{moment.unix(u.date).format('MMMM Do, YYYY hh:mm a')}</p>
            )}
          </div>
        ))}
      </WidgetContent>
    </WidgetContainer>
  );
};

function makeMapStateToProps() {
  const getOption = makeGetOption();
  const getSubmData = makeGetSubmDataByOptionId();
  return (state: StoreState, ownProps: Props) => ({
    option: getOption(state, ownProps),
    submData: getSubmData(state, ownProps),
    submUid: state.form.submissionUid,
    editable: state.form.editable,
  });
}

function mapDispatchToProps(dispatch: Dispatch) {
  return {
    updateSubm: (submData: ParsedSubmData) =>
      dispatch(updateSubmData(submData)),
  };
}

export default withNamespaces()(
  connect(
    makeMapStateToProps,
    mapDispatchToProps,
  )(SignOff),
);

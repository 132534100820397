import { api2 } from './api';
import { Action, Company, Site, User, Storage } from '../types';
import { CreateAction } from './company.api';

const actionApi = {
  show: (actionUid: string) =>
    api2<{ action: Action; company: Company; site?: Site; assignedUser: User }>(
      'GET',
      `actions/${actionUid}`,
    ),
  update: (
    actionUid: string,
    data: { notes?: string } & Partial<CreateAction>,
  ) => api2<{ action: Action }>('PATCH', `actions/${actionUid}`, data),
  complete: (actionUid: string) => api2('PUT', `actions/${actionUid}/complete`),
  uncomplete: (actionUid: string) =>
    api2('PUT', `actions/${actionUid}/uncomplete`),
  uploads: (actionUid: string) =>
    api2<{ uploads: Storage[] }>('GET', `actions/${actionUid}/uploads`),
  upload: (actionUid: string, data: any) =>
    api2('POST', `actions/${actionUid}/uploads`, data),
  removeUpload: (actionUid: string, storageUid: string) =>
    api2('DELETE', `actions/${actionUid}/uploads/${storageUid}`),
};

export default actionApi;

import * as React from 'react';
import BaseWidget, { RenderMenuProps } from './BaseWidget';
import InlineSelect from '../../components/InlineSelect';
import Button from '../../components/Button';
import Location from '../../svg/Location';
import Calendar from '../../svg/Calendar';
import User from '../../svg/User';
import { Company, Site, User as IUser, CompanyGroup, Form } from '../../types';
import { withNamespaces, WithNamespaces } from 'react-i18next';
import moment, { Moment } from 'moment';
import styled from 'styled-components';
import { biggerThanMD } from '../../helpers/style';
import companyApi from '../../api/company.api';
import InlineDatePicker from '../../components/InlineDatePicker';
import {
  useOptionalSelectInput,
  useRequiredSelectInput,
  useRequiredDateInput,
  useMountVisibility,
} from '../../helpers/hooks';
import companyGroupApi from '../../api/companyGroup.api';
import {
  PieChart,
  Pie,
  Cell,
  ResponsiveContainer,
  Legend,
  Label,
} from 'recharts';
import ActionsSidebox from './actions/ActionsSidebox';
// @ts-ignore
import ReactTooltip from 'react-tooltip';
import File from '../../svg/File';
import { getSiteLabel } from '../../helpers';

const ChartWrapper = styled.div`
  position: relative;
  height: 292px;

  ${biggerThanMD(`
    height: 100%;
  `)};
`;

interface Props extends WithNamespaces {
  company?: Company;
  companyGroup?: CompanyGroup;
  users: IUser[];
  sites: Site[];
  forms: Form[];
}

const Actions = ({ company, companyGroup, users, sites, forms, t }: Props) => {
  const getPeriodOptions = () => [
    { label: t('widgets:actionsStats.allTime'), value: 'allTime' },
    {
      label: t('widgets:actionsStats.createdThisMonth'),
      value: 'createdThisMonth',
    },
    {
      label: t('widgets:actionsStats.createdLastMonth'),
      value: 'createdLastMonth',
    },
    {
      label: t('widgets:actionsStats.createdBetween'),
      value: 'createdBetween',
    },
    { label: t('widgets:actionsStats.dueThisMonth'), value: 'dueThisMonth' },
    { label: t('widgets:actionsStats.dueLastMonth'), value: 'dueLastMonth' },
    { label: t('widgets:actionsStats.dueBetween'), value: 'dueBetween' },
  ];

  const site = useOptionalSelectInput();
  const user = useOptionalSelectInput();
  const form = useOptionalSelectInput();
  const period = useRequiredSelectInput(getPeriodOptions()[0]);
  const start = useRequiredDateInput(moment());
  const end = useRequiredDateInput(moment());
  const [stats, setStats] = React.useState({
    all: 0,
    overdueUrgent: 0,
    urgent: 0,
    overdue: 0,
    opened: 0,
    avgDuration: null,
  });
  const sideboxState = useMountVisibility();
  const [typeFilter, setTypeFilter] = React.useState('all');

  const parentUid = company
    ? company.uid
    : companyGroup
      ? companyGroup.uid
      : null;

  const getDatePeriod = () => {
    switch (period.selected) {
      case 'createdThisMonth':
        return {
          createdStart: moment()
            .startOf('month')
            .unix(),
          createdEnd: moment().unix(),
        };
      case 'createdLastMonth':
        return {
          createdStart: moment()
            .subtract(1, 'month')
            .startOf('month')
            .unix(),
          createdEnd: moment()
            .subtract(1, 'month')
            .endOf('month')
            .unix(),
        };
      case 'createdBetween':
        return {
          createdStart: start.value.startOf('day').unix(),
          createdEnd: end.value.endOf('day').unix(),
        };
      case 'dueThisMonth':
        return {
          dueStart: moment()
            .startOf('month')
            .unix(),
          dueEnd: moment().unix(),
        };
      case 'dueLastMonth':
        return {
          dueStart: moment()
            .subtract(1, 'month')
            .startOf('month')
            .unix(),
          dueEnd: moment()
            .subtract(1, 'month')
            .endOf('month')
            .unix(),
        };
      case 'dueBetween':
        return {
          dueStart: start.value.startOf('day').unix(),
          dueEnd: end.value.endOf('day').unix(),
        };
      case 'allTime':
      default:
        return {};
    }
  };

  const formatFilters = () => ({
    siteUids: site.selected ? [site.selected] : [],
    userUids: user.selected ? [user.selected] : [],
    formUids: form.selected ? [form.selected] : [],
    ...getDatePeriod(),
  });

  const getActionStats = async () => {
    try {
      if (company) {
        const { actionStats } = await companyApi.actionStats(
          company.uid,
          formatFilters(),
        );
        setStats(actionStats);
      }
      if (companyGroup) {
        const { actionStats } = await companyGroupApi.actionStats(
          companyGroup.uid,
          formatFilters(),
        );
        setStats(actionStats);
      }
    } catch (e) {
      //
    }
  };

  React.useEffect(
    () => {
      getActionStats();
    },
    [
      parentUid,
      site.selected,
      user.selected,
      form.selected,
      period.selected,
      start.unix(),
      end.unix(),
    ],
  );

  const onStartChange = (newStart: Moment) => {
    let newEnd = end.value.clone();
    if (newStart.isAfter(end.value)) {
      newEnd = newStart.clone();
    }

    start.onChange(newStart);
    end.onChange(newEnd);
  };

  const onEndChange = (newEnd: Moment) => {
    let newStart = start.value.clone();

    if (newEnd.isBefore(start.value)) {
      newStart = newEnd.clone();
    }

    start.onChange(newStart);
    end.onChange(newEnd);
  };

  const renderMenu = (state: RenderMenuProps) => {
    const siteOptions = sites.map(s => ({ label: s.name, value: s.uid }));
    const userOptions = users.map(s => ({ label: s.username, value: s.uid }));
    const formsOptions = forms.map(s => ({ label: s.name, value: s.uid }));

    const periodOptions = getPeriodOptions();

    return (
      <div className="h-full w-full flex flex-col items-center justify-center p-4">
        <div className={'w-full'}>
          <div className="flex-1 w-full flex flex-col md:flex-row">
            <div className="flex-1 mb-4 md:mb-0">
              <InlineSelect
                options={siteOptions}
                label={`${getSiteLabel()}`}
                icon={<Location />}
                menuPosition={'fixed'}
                isClearable={true}
                {...site}
              />
            </div>
            <div className="flex-1 md:ml-4">
              <InlineSelect
                options={formsOptions}
                label={t('common:form')}
                icon={<File />}
                menuPosition={'fixed'}
                isClearable={true}
                {...form}
              />
            </div>
          </div>
          <div className="flex-1 w-full flex flex-col md:flex-row">
            <div className="flex-1 mb-4 md:mb-0">
              <InlineSelect
                options={userOptions}
                label={t('common:user')}
                icon={<User />}
                menuPosition={'fixed'}
                isClearable={true}
                {...user}
              />
            </div>
            <div className="flex-1 md:ml-4">
              <InlineSelect
                options={periodOptions}
                label={t('widgets:actionsStats.period')}
                icon={<Calendar />}
                menuPosition={'fixed'}
                {...period}
              />
            </div>
          </div>

          {(period.selected === 'createdBetween' ||
            period.selected === 'dueBetween') && (
            <div className="flex-1 w-full flex flex-col md:flex-row">
              <div className="flex-1 mb-4 md:mb-0">
                <InlineDatePicker
                  label={'Start'}
                  value={start.value}
                  onChange={onStartChange}
                />
              </div>
              <div className="flex-1 md:ml-4">
                <InlineDatePicker
                  label={'End'}
                  value={end.value}
                  onChange={onEndChange}
                />
              </div>
            </div>
          )}

          <Button block={true} type={'default'} onClick={state.closeMenu}>
            {t('common:done')}
          </Button>
        </div>
      </div>
    );
  };

  const labels = {
    opened: 'Open',
    overdueUrgent: 'Urgent & Overdue',
    overdue: 'Overdue',
    urgent: 'Urgent',
  };

  const data = Object.keys(stats)
    .filter(key => key !== 'all' && key !== 'avgDuration')
    .map(key => ({
      id: key,
      name: labels[key],
      value: stats[key],
    }));

  const openSidebox = (type: string) => {
    setTypeFilter(type);
    sideboxState.open();
  };

  const colors = {
    opened: '#00aced',
    overdueUrgent: '#000000',
    overdue: '#FF4245',
    urgent: '#FFC238',
  };

  return (
    <BaseWidget title={'Actions Statistics'} renderMenu={renderMenu}>
      <ChartWrapper>
        {stats.avgDuration && (
          <h3
            data-tip="This is the average amount of days, from open until closed, that an action item stays active."
            style={{
              position: 'absolute',
              right: '1rem',
              top: '1rem',
              zIndex: 9,
              cursor: 'help',
            }}
          >
            Avg. Days Alive: {stats.avgDuration}{' '}
            <ReactTooltip effect={'solid'} />
          </h3>
        )}
        <ResponsiveContainer width={'99%'} height="99%">
          <PieChart>
            <Pie
              data={data}
              dataKey={'value'}
              innerRadius={60}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={5}
              label={true}
            >
              <Label value={`Total: ${stats.all}`} position="center" />
              {data.map((entry, index) => (
                <Cell key={`cell-${index}`} fill={colors[entry.id]} />
              ))}
            </Pie>
            <Legend
              layout="vertical"
              align="right"
              verticalAlign="middle"
              height={36}
              onClick={e => openSidebox(e.value)}
            />
          </PieChart>
        </ResponsiveContainer>
        {sideboxState.mounted && (
          <ActionsSidebox
            isOpen={sideboxState.visible}
            handleClose={sideboxState.close}
            type={typeFilter}
            company={company}
            companyGroup={companyGroup}
            filters={formatFilters()}
          />
        )}
      </ChartWrapper>
    </BaseWidget>
  );
};

export default withNamespaces()(Actions);

import * as React from 'react';
import styled from 'styled-components';
import { biggerThanMD, mandatoryErrorStyles } from '../../helpers/style';
import WidgetContainer from '../form/WidgetContainer';
import WidgetContent from '../form/WidgetContent';
import {
  Widget,
  ResponseFile,
  ResponseComment,
  ContentResponse,
} from '../../types';
import WidgetMetaButtons from './WidgetMetaButtons';
import { useState, useEffect } from 'react';
import marked from 'marked';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  ${biggerThanMD(`
    flex-direction: row;
  `)} &:last-of-type {
    margin-bottom: 0;
  }
`;

interface Props {
  widget: Widget;
  response: ContentResponse;
  comments: ResponseComment[];
  files: ResponseFile[];
  editable?: boolean;
  instanceRevUid: string;
  error?: boolean;
}

const Content = ({
  widget,
  editable,
  instanceRevUid,
  response,
  error,
  files,
  comments,
}: Props) => {
  const [content, setContent] = useState<string>(
    widget && widget.content ? marked(widget.content) : '',
  );

  useEffect(
    () => {
      if (widget.content === undefined) {
        return;
      }
      setContent(marked(widget.content, { sanitize: true }));
    },
    [widget.content],
  );

  return (
    <WidgetContainer>
      <WidgetContent
        style={error ? mandatoryErrorStyles : {}}
        className="bg-white mt-4 rounded"
      >
        <div className={'mb-4'}>
          <div dangerouslySetInnerHTML={{ __html: content }} />
        </div>
        <WidgetMetaButtons
          editable={editable}
          files={files}
          widget={widget}
          instanceRevUid={instanceRevUid}
          comments={comments}
        />
      </WidgetContent>
    </WidgetContainer>
  );
};

export default Content;

import * as React from 'react';
import styled from 'styled-components';
import { cssVars } from '../../../constants';
import Loading from '../../../components/Loading';
import { useSimpleFetch, useMountVisibility } from '../../../helpers/hooks';
import companyUserApi, {
  FavouriteFormResponse,
} from '../../../api/companyUser.api';
import { Company, User, Form, Site } from '../../../types';
import formService, { TopForms } from '../../../services/forms.service';
import { useState, useEffect } from 'react';
import Star from '../../../svg/Star';
import File from '../../../svg/File';
import { History } from 'history';
import PickDraft from '../site/PickDraft';
import { toast } from 'react-toastify';
import userApi from '../../../api/user.api';
import StarFull from '../../../svg/StarFull';

const V2Badge = styled.div`
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 6px;
  color: #fff;
  background-color: ${cssVars.colors.primary};
`;

const GreenBadge = styled.div`
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 6px;
  color: #fff;
  background-color: ${cssVars.colors.green};
  position: absolute;
  right: 1rem;
`;

interface Favorite {
  fcBased: boolean;
  form: Form;
  site?: Site;
  favoriteUid: string;
}

interface Props {
  company: Company;
  user: User;
  history: History;
}

function Favorites({ company, user, history }: Props) {
  const draftModal = useMountVisibility();
  const favouriteForms = useSimpleFetch<FavouriteFormResponse[]>({
    initial: [],
    fetch: () => companyUserApi.favouriteForms(company.uid, user.uid),
    accessor: 'favouriteForms',
    change: [company.uid, user.uid],
  });
  const favouriteV2Forms = useSimpleFetch<TopForms | null>({
    initial: null,
    fetch: () => formService.getTopForms(company.uid, user.uid),
    accessor: 'topForms',
    change: [company.uid, user.uid],
  });

  const [displayedFavorites, setDisplayedFavorites] = useState<Array<Favorite>>(
    [],
  );
  const [selectedFormName, setSelectedFormName] = useState<string>('');
  const [selectedFormUid, setSelectedFormUid] = useState<string>('');

  function getDisplayedFavorites() {
    const aggregatedFavourites: Favorite[] = [];
    for (const { fc, site, form } of favouriteForms.value) {
      aggregatedFavourites.push({
        favoriteUid: fc.uid,
        site,
        form,
        fcBased: true,
      });
    }
    if (favouriteV2Forms.value) {
      for (const favoriteForm of favouriteV2Forms.value.favorites) {
        aggregatedFavourites.push({
          favoriteUid: favoriteForm.uid,
          form: favoriteForm as Form,
          fcBased: false,
        });
      }
    }
    setDisplayedFavorites(aggregatedFavourites);
  }

  async function removeFavouriteV2Form(formUid: string) {
    if (!formUid) {
      return;
    }
    await formService.removefavoriteForm(user.uid, formUid);
    toast.success('Form removed from favorites!');
    favouriteV2Forms.performFetch();
  }

  async function removeFavouriteForm(fcUid: string) {
    if (!fcUid) {
      return;
    }
    await userApi.unfavouriteForm(user.uid, fcUid);
    toast.success('Form removed from favourites!');
    favouriteForms.performFetch();
  }

  function setFormOpenModal(formName: string, formUid: string) {
    setSelectedFormUid(formUid);
    setSelectedFormName(formName);
    draftModal.open();
  }

  useEffect(
    () => {
      getDisplayedFavorites();
    },
    [favouriteForms.value.length, favouriteV2Forms.value],
  );

  return (
    <div>
      <div className={'bg-white my-4'}>
        <div className="flex flex-row items-center p-4 border-0 border-b-2 border-solid border-grey-lighter">
          <File
            style={{
              width: '26px',
              height: 'auto',
              alignSelf: 'center',
            }}
            className="text-grey-darker mr-2"
          />
          <h3>Favourites</h3>
        </div>
        <div
          className="p-4 relative flex flex-row flex-wrap overflow-y-scroll"
          style={{ maxHeight: '150px' }}
        >
          {favouriteV2Forms.loading && <Loading loading={true} />}
          {displayedFavorites.length === 0 && (
            <p>
              To favourite a form, click the <Star className="w-4 h-4" /> next
              to its name when you are filling it out.
            </p>
          )}
          {displayedFavorites.map(({ fcBased, form, site, favoriteUid }) => (
            <div key={favoriteUid} className="w-1/2 cursor-pointer p-1">
              <div className="flex flex-row h-full items-center justify-between relative rounded no-underline text-black border-1 border-solid border-grey hover:bg-grey-lighter p-4">
                <div
                  className="w-full"
                  onClick={() => {
                    if (fcBased) {
                      history.push(`/dashboard/worker/forms/${favoriteUid}`);
                      return;
                    }
                    setFormOpenModal(form.name, form.uid);
                  }}
                >
                  <h4 className="text-black">{form.name}</h4>
                  {site && (
                    <p className="text-grey-darker text-sm">{site.name}</p>
                  )}
                </div>
                <div className="flex flex-row items-center">
                  {!fcBased && <V2Badge className="mr-4">V2</V2Badge>}
                  <StarFull
                    className={`w-6 h-6 text-yellow hover:text-red cursor-pointer`}
                    onClick={() => {
                      if (fcBased) {
                        removeFavouriteForm(favoriteUid);
                        return;
                      }
                      removeFavouriteV2Form(form.uid);
                    }}
                  />
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {company.forms2 && (
        <div className={'bg-white my-4'}>
          <div className="flex flex-col p-4 relative border-0 border-b-2 border-solid border-grey-lighter">
            <div className="flex flex-row items-center relative">
              <File
                style={{
                  width: '26px',
                  height: 'auto',
                  alignSelf: 'center',
                }}
                className="text-grey-darker mr-2"
              />
              <h3>Top Forms</h3>
              <V2Badge className="ml-4">V2</V2Badge>
            </div>
            <p className="mt-2 italic text-grey-darker">
              These are the forms that you submit the most.
            </p>
          </div>
          <div
            className="p-4 relative flex flex-row flex-wrap overflow-y-scroll"
            style={{ maxHeight: '150px' }}
          >
            {favouriteV2Forms.loading && <Loading loading={true} />}
            {favouriteV2Forms.value &&
              favouriteV2Forms.value.forms.length === 0 && (
                <p>
                  Your most accessed forms will be displayed here. Start filling
                  out forms to populate this area!
                </p>
              )}
            {favouriteV2Forms.value &&
              favouriteV2Forms.value.forms.map(
                ({ name, times_submitted, uid }) => (
                  <div
                    onClick={() => setFormOpenModal(name, uid)}
                    key={uid}
                    className="w-1/2 cursor-pointer p-1"
                  >
                    <div className="flex flex-row h-full items-center relative rounded no-underline text-black border-1 border-solid border-grey hover:bg-grey-lighter p-4">
                      <h4>{name}</h4>
                      <GreenBadge>{times_submitted}</GreenBadge>
                    </div>
                  </div>
                ),
              )}
          </div>
        </div>
      )}
      {draftModal.mounted &&
        selectedFormName && (
          <PickDraft
            isOpen={draftModal.mounted}
            handleClose={draftModal.close}
            history={history}
            formName={selectedFormName}
            formUid={selectedFormUid}
          />
        )}
    </div>
  );
}

export default Favorites;

import * as React from 'react';
import Sidebox, { SideboxContent } from '../../../components/Sidebox';
import SideboxHeader from '../../../components/sidebox/SideboxHeader';
import companyApi from '../../../api/company.api';
import { Company, CompanyGroup, Action } from '../../../types';
import companyGroupApi from '../../../api/companyGroup.api';
import moment from 'moment';
import ActionItem from '../../../components/ActionItem';
import Loading from '../../../components/Loading';

interface Props {
  type: string;
  isOpen: boolean;
  handleClose: () => void;
  company?: Company;
  companyGroup?: CompanyGroup;
  filters?: {
    siteUids?: string[];
    userUids?: string[];
    dueStart?: number;
    dueEnd?: number;
    createdStart?: number;
    createdEnd?: number;
  };
}

const typeFilter = (type: string) => {
  switch (type) {
    case 'completed':
      // filter by status = 1
      return { statuses: [1] };
    case 'urgent':
      // filter by urgent = true
      return { statuses: [2, 3], urgent: true };
    case 'opened':
      // filter by status = 2 or status = 3
      return { statuses: [2, 3] };
    case 'overdue':
      // filter by dueEnd <= moment.now
      return { statuses: [2, 3], dueEnd: moment().unix() };
    case 'overdueUrgent':
      // filter by dueEnd <= moment.now
      return { statuses: [2, 3], dueEnd: moment().unix(), urgent: true };
    default:
      return {};
  }
};

const ActionsSidebox = ({
  company,
  companyGroup,
  type,
  isOpen,
  handleClose,
  filters,
}: Props) => {
  const [actions, setActions] = React.useState<Action[]>([]);
  const isCompany = company ? true : false;
  const scope = isCompany ? company : companyGroup;
  const [loading, setLoading] = React.useState(false);

  if (!scope) {
    return null;
  }

  const fetchActions = async () => {
    setLoading(true);
    const endpoint = isCompany ? companyApi.actions : companyGroupApi.actions;
    const { data } = await endpoint(scope.uid, {
      ...filters,
      ...typeFilter(type.toLowerCase()),
    });
    if (data) {
      setActions(data.actions);
    }
    setLoading(false);
  };

  React.useEffect(
    () => {
      fetchActions();
    },
    [type, companyGroup, company],
  );

  return (
    <Sidebox isOpen={isOpen} handleClose={handleClose} contentLabel="Actions">
      <SideboxHeader handleClose={handleClose}>Actions</SideboxHeader>
      <SideboxContent>
        <div className="p-4 relative">
          <Loading loading={loading} />
          {actions.map(action => (
            <ActionItem action={action} key={action.uid} />
          ))}
        </div>
      </SideboxContent>
    </Sidebox>
  );
};

export default ActionsSidebox;

import * as React from 'react';
import { WithNamespaces, withNamespaces } from 'react-i18next';
import {
  CompanyGroup,
  Crew,
  Form,
  Site,
  Company,
  User as IUser,
} from '../types';
import DateFilterWidget from '../components/DateFilterWidget';
import AuditDashboardMain from './auditDashboard/Main';

interface Props extends WithNamespaces {
  companyGroup?: CompanyGroup;
  company?: Company;
  users: IUser[];
  crews: Crew[];
  forms: Form[];
  sites: Site[];
  companies?: Company[];
}

const AuditDashboard = (props: Props) => {
  if (props.company && !props.company.audit_forms) {
    return (
      <div className="flex flex-col bg-white justify-center items-center p-4 py-10">
        <div className="md:w-1/2 text-center">
          <h3>Welcome to the Audit Dashboard</h3>
          <p>
            When you subscribe to the Auditing module you will be able to track
            % compliance for any auditing form that you build!
          </p>
          <p>To find out more talk to your SafetyTek Account Representative.</p>
          <p>
            Contact <a href="mailto:sales@safetytek.ca">sales@safetytek.ca</a>{' '}
            to enable this feature.
          </p>
        </div>
      </div>
    );
  }

  return (
    <div>
      <DateFilterWidget
        views={[
          'all',
          'thisYear',
          'pastYear',
          'lastWeek',
          'lastQuarter',
          'custom',
        ]}
        defaultView={'thisYear'}
      >
        {({ Widget, getWidgetProps, start, end }) => (
          <AuditDashboardMain
            {...props}
            start={start}
            end={end}
            Widget={Widget}
            getWidgetProps={getWidgetProps}
          />
        )}
      </DateFilterWidget>
    </div>
  );
};

export default withNamespaces()(AuditDashboard);

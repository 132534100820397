import * as React from 'react';
import { ChildrenResponse } from '../../../api/storage.api';
import Folder from './Folder';
import File from './File';
import { contains } from 'ramda';
import FolderOpen from '../../../svg/FolderOpen';

interface Props {
  storage: ChildrenResponse[];
  toggleSelected: (uid: string) => void;
  selected: string[];
  onMenuClick: (uid: string) => void;
  openFolder: (uid: string) => void;
  openParent?: () => void;
  onDrop: any;
  parentUid: string;
}

const GridView = ({
  storage,
  selected,
  toggleSelected,
  onMenuClick,
  openFolder,
  openParent,
  onDrop,
  parentUid,
}: Props) => {
  const folders = storage.filter(s => s.type === 'Dir');
  const files = storage.filter(s => s.type === 'File');

  return (
    <div>
      <div className="flex flex-row flex-wrap">
        {openParent && (
          <div className="my-4 mx-2" onDoubleClick={openParent}>
            <Folder
              onClick={toggleSelected}
              onMenuClick={onMenuClick}
              drop={true}
              onDrop={(dragUid: string) => onDrop(dragUid, parentUid)}
              name={'Go to parent...'}
            />
          </div>
        )}
        {folders.map(s => (
          <div
            key={s.storage.uid}
            className="my-4 mx-2"
            onDoubleClick={() => openFolder(s.storage.uid)}
          >
            <Folder
              key={s.storage.uid}
              storage={s.storage}
              onClick={toggleSelected}
              onMenuClick={onMenuClick}
              selected={contains(s.storage.uid, selected)}
              locked={s.locked}
              drop={true}
              drag={true}
              onDrop={onDrop}
            />
          </div>
        ))}
      </div>

      {files.length > 0 && <hr className={'my-4 '} />}

      <div className="flex flex-row flex-wrap">
        {files.map(s => (
          <File
            key={s.storage.uid}
            storage={s.storage}
            onClick={toggleSelected}
            onMenuClick={onMenuClick}
            selected={contains(s.storage.uid, selected)}
            drag={true}
          />
        ))}
      </div>
    </div>
  );
};

export default GridView;
